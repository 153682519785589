import { hasIntegrationFox } from 'core/core.selectors';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const integrationTypes = ['embedded-container'];

const useVerifyEmbeddedIntegration = () => {
  const { typeIntegration } = useParams();
  if (!typeIntegration) return false;

  return integrationTypes.includes(typeIntegration);
};

const useDiseaseRiskReadOnly = () => {
  const hasIntegrationFoxEntitlement = useSelector(hasIntegrationFox);
  const isIntegrationRoute = useVerifyEmbeddedIntegration();
  return hasIntegrationFoxEntitlement && isIntegrationRoute;
};

export { useDiseaseRiskReadOnly, useVerifyEmbeddedIntegration };
