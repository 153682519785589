import CropAcerola from './CropAcerola';
import CropAlfalfa from './CropAlfalfa';
import CropApple from './CropApple';
import CropAsparagus from './CropAsparagus';
import CropAtemoya from './CropAtemoya';
import CropAvocado from './CropAvocado';
import CropBanana from './CropBanana';
import CropBarley from './CropBarley';
import CropBean from './CropBean';
import CropBlueberry from './CropBlueberry';
import CropBrachiaria from './CropBrachiaria';
import CropCherry from './CropCherry';
import CropCoffeeBean from './CropCoffeeBean';
import CropCorn from './CropCorn';
import CropCotton from './CropCotton';
import CropGrape from './CropGrape';
import CropLemon from './CropLemon';
import CropOilseedRape from './CropOilseedRape';
import CropOnion from './CropOnion';
import CropOrange from './CropOrange';
import CropPotato from './CropPotato';
import CropRice from './CropRice';
import CropSoybeans from './CropSoybeans';
import CropSugarcane from './CropSugarcane';
import CropSunflower from './CropSunflower';
import CropTomato from './CropTomato';
import CropTurnip from './CropTurnip';
import CropWatermelon from './CropWatermelon';
import CropWheat from './CropWheat';

const CropsIcons = {
  CropOilseedRape,
  CropWatermelon,
  CropBrachiaria,
  CropCoffeeBean,
  CropSunflower,
  CropAsparagus,
  CropSugarcane,
  CropBlueberry,
  CropSoybeans,
  CropAcerola,
  CropAlfalfa,
  CropAtemoya,
  CropAvocado,
  CropOrange,
  CropCotton,
  CropPotato,
  CropCherry,
  CropTomato,
  CropTurnip,
  CropBarley,
  CropBanana,
  CropLemon,
  CropOnion,
  CropGrape,
  CropApple,
  CropWheat,
  CropRice,
  CropCorn,
  CropBean
};

export default CropsIcons;
