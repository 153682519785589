import { Collapse } from 'antd';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { reportTranslationKey } from '../functions/report-generate.functions';
import '../report.styles.less';
import type { ReportsTypes } from '../reports-types.enum';

const { Panel } = Collapse;

interface IExplicativeText {
  type: ReportsTypes | null;
  hideCollapse?: boolean;
}

const customPanelStyle = {
  background: '#fff',
  border: 0,
  overflow: 'hidden'
};

export const ExplicativeText: React.FC<IExplicativeText> = ({ type, hideCollapse }: IExplicativeText) => {
  const [t] = useTranslation();

  function getTextByType(sufix: string) {
    return type ? t(`report.${reportTranslationKey[type]}.${sufix}`) : '';
  }

  return (
    <div className='st-report-drawer__subtitle' data-testid='report-spray-subtitle'>
      <STTypo type={TypeOptions.P1} color='#14151C'>
        {getTextByType(`epigraph`)}
      </STTypo>
      {!hideCollapse && (
        <div>
          <Collapse bordered={false}>
            <Panel
              header={
                <div className='st-report-drawer__subtitle__howUse'>
                  <STTypo type={TypeOptions.H6} fontWeight={500} color='#14151C'>
                    {t('report.howUse')}
                  </STTypo>
                </div>
              }
              key='1'
              style={customPanelStyle}>
              <STTypo type={TypeOptions.P1} color='#14151C'>
                {getTextByType(`explicative_text`)}
              </STTypo>
            </Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};
