import { COLOR_OPTIONS } from 'config/style';
import type { Crop } from 'core/utils/basic.models';
import * as CropDomain from 'entities/crop/crop.domain';
import CropsIcons from 'st-protector-icons/Major';
import { getSFCropIconName } from './crop-icon.helpers';

interface CropIconUnitProps {
  cropInfo: Crop | undefined;
  customIcon: React.ReactElement | undefined;
  background: boolean | undefined;
}

const CropIconUnit = ({ cropInfo, customIcon, background }: CropIconUnitProps) => {
  if (cropInfo && !customIcon) {
    const color = background ? COLOR_OPTIONS.WHITE : undefined;

    switch (true) {
      case CropDomain.isAnyWheatCrop(cropInfo):
        return <CropsIcons.CropWheat height={14} color={color} />;

      case CropDomain.isPotatoCrop(cropInfo):
        return <CropsIcons.CropPotato height={14} fill={color} />;

      case CropDomain.isAnyBarleyCrop(cropInfo, true):
        return <CropsIcons.CropBarley height={14} color={color} />;

      case CropDomain.isGrapeCrop(cropInfo):
        return <CropsIcons.CropGrape height={14} color={color} />;

      case CropDomain.isSoybeanCrop(cropInfo):
        return <CropsIcons.CropSoybeans height={14} color={color} />;

      case CropDomain.isBrachiariaCrop(cropInfo):
        return <CropsIcons.CropBrachiaria height={14} color={color} />;

      case CropDomain.isAcerolaCrop(cropInfo):
        return <CropsIcons.CropAcerola height={14} color={color} />;

      case CropDomain.isAlfalfaCrop(cropInfo):
        return <CropsIcons.CropAlfalfa height={14} color={color} />;

      case CropDomain.isAppleCrop(cropInfo):
        return <CropsIcons.CropApple height={14} color={color} />;

      case CropDomain.isAsparagusCrop(cropInfo):
        return <CropsIcons.CropAsparagus height={14} color={color} />;

      case CropDomain.isAtemoyaCrop(cropInfo):
        return <CropsIcons.CropAtemoya height={14} color={color} />;

      case CropDomain.isAvocadoCrop(cropInfo):
        return <CropsIcons.CropAvocado height={14} color={color} />;

      case CropDomain.isBananaCrop(cropInfo):
        return <CropsIcons.CropBanana height={14} color={color} />;

      case CropDomain.isBeanCrop(cropInfo):
        return <CropsIcons.CropBean height={14} color={color} />;

      case CropDomain.isBlueberryCrop(cropInfo):
        return <CropsIcons.CropBlueberry height={14} color={color} />;

      case CropDomain.isCherryCrop(cropInfo):
        return <CropsIcons.CropCherry height={14} color={color} />;

      case CropDomain.isCoffeeBeanCrop(cropInfo):
        return <CropsIcons.CropCoffeeBean height={14} color={color} />;

      case CropDomain.isCornCrop(cropInfo):
        return <CropsIcons.CropCorn height={14} color={color} />;

      case CropDomain.isCottonCrop(cropInfo):
        return <CropsIcons.CropCotton height={14} color={color} />;

      case CropDomain.isLemonCrop(cropInfo):
        return <CropsIcons.CropLemon height={14} color={color} />;

      case CropDomain.isOilseedRapeCrop(cropInfo):
        return <CropsIcons.CropOilseedRape height={14} color={color} />;

      case CropDomain.isOnionCrop(cropInfo):
        return <CropsIcons.CropOnion height={14} color={color} />;

      case CropDomain.isOrangeCrop(cropInfo):
        return <CropsIcons.CropOrange height={14} color={color} />;

      case CropDomain.isRiceCrop(cropInfo):
        return <CropsIcons.CropRice height={14} color={color} />;

      case CropDomain.isSugarcaneCrop(cropInfo):
        return <CropsIcons.CropSugarcane height={14} color={color} />;

      case CropDomain.isSunflowerCrop(cropInfo):
        return <CropsIcons.CropSunflower height={14} color={color} />;

      case CropDomain.isTomatoCrop(cropInfo):
        return <CropsIcons.CropTomato height={14} color={color} />;

      case CropDomain.isTurnipCrop(cropInfo):
        return <CropsIcons.CropTurnip height={14} color={color} />;

      case CropDomain.isWatermelonCrop(cropInfo):
        return <CropsIcons.CropWatermelon height={14} color={color} />;

      default:
    }
  }

  const cropIcon = getSFCropIconName(cropInfo);

  return <i data-testid='crop-icon' className={`sf sf-${cropIcon}`} />;
};

export default CropIconUnit;
