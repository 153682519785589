import { getLicensingStatus, getSystemFlags } from 'core/core.selectors';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import { validatePlansAndEntitlements } from 'core/utils/functions';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

const NotesContainer = React.lazy(() => import('pages/notes'));

const Notes = () => {
  const licensingStatus = useSelector(getLicensingStatus);
  const flags = useSelector(getSystemFlags);

  const hasAccessNotes = useMemo(() => {
    const hasEntitlement = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.NOTES_PAGE]);
    return !!flags && !!(flags?.P40_24023_notes_module && hasEntitlement);
  }, [licensingStatus, flags]);

  if (!licensingStatus || !flags) {
    return null;
  }

  if (!hasAccessNotes) {
    return <Navigate to='/hall-of-companies' />;
  }

  return <NotesContainer />;
};

export default Notes;
