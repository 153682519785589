import classNames from 'classnames';
import STTypo, { STTypoModels } from 'core/shared/typo';
import { AppVersion } from 'core/shared/version/version.component';
import { useTranslation } from 'react-i18next';
import SvgMenuExpand from 'st-protector-icons/Minor/MenuExpand';
import './trigger.styles.less';

const STTrigger = ({ onClick, collapsed }) => {
  const [t] = useTranslation();
  const classes = classNames({
    'st-trigger': true,
    'st-trigger--collapsed': collapsed
  });

  return (
    <div className={classes} onClick={onClick} data-testid='trigger'>
      {!collapsed && <AppVersion />}
      <div className='st-trigger__item'>
        <SvgMenuExpand />
        <STTypo type={STTypoModels.TypeOptions.C2} fontWeight={500} color='inherit'>
          {collapsed ? t('layout.expand_sider') : t('layout.collapse_sider')}
        </STTypo>
      </div>
    </div>
  );
};

export default STTrigger;
