/**
 * Using the logical OR operator (||) can cause issues with SonarCloud's TypeScript rule S6606,
 * as zero is considered a falsy value. This can lead to unintended behavior.
 *
 * For more details, refer to:
 * https://sonarcloud.io/organizations/syngenta-digital/rules?open=typescript%3AS6606&rule_key=typescript%3AS6606
 *
 * If the default value is zero, consider using the nullish coalescing operator (??) instead.
 */
export const getNumberOrDefault = (value: number | undefined | null, defaultValue: number): number => {
  if (value) {
    return value;
  }

  return defaultValue;
};

/**
 * Using the logical OR operator (||) can cause issues with SonarCloud's TypeScript rule S6606,
 * if value exists or equal 0 and return boolean
 *
 * For more details, refer to:
 * https://sonarcloud.io/organizations/syngenta-digital/rules?open=typescript%3AS6606&rule_key=typescript%3AS6606
 *
 * If you need a comparison if value exists or is equal zero, consider using the nullish coalescing operator (??) instead.
 */
export const validateNumberOrZero = (value: number | undefined | null): boolean => {
  if (value === 0) return true;
  return !!value;
};
