const SvgWeatherDailyMixedWithSnowShowers = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M16.2914 9.66316L15.5694 7.96116C16.2134 7.68816 16.6284 7.06116 16.6284 6.36716C16.6284 5.41116 15.8504 4.63416 14.8954 4.63416C14.0474 4.63416 13.3134 5.26816 13.1864 6.10316C13.1724 6.19216 13.1664 6.27916 13.1664 6.36816H11.3174C11.3174 6.18816 11.3304 6.00416 11.3584 5.82616C11.6204 4.09516 13.1414 2.78516 14.8954 2.78516C16.8714 2.78516 18.4784 4.39216 18.4784 6.36816C18.4784 7.80816 17.6194 9.10316 16.2914 9.66316Z'
      fill='#F0C355'
    />
    <path d='M12.917 4.41253L10.2158 3.35156L9.53946 5.07354L12.2406 6.13451L12.917 4.41253Z' fill='#F0C355' />
    <path d='M14.7301 3.55324L13.4629 0.941406L11.7948 1.75068L13.062 4.36251L14.7301 3.55324Z' fill='#F0C355' />
    <path d='M17.4793 1.54155L15.7178 0.976562L14.8752 3.60368L16.6367 4.16867L17.4793 1.54155Z' fill='#F0C355' />
    <path d='M20.1457 4.41274L19.208 2.82031L16.7056 4.2938L17.6433 5.88622L20.1457 4.41274Z' fill='#F0C355' />
    <path d='M20.4318 6.65043L17.5928 6.03516L17.2003 7.84609L20.0394 8.46137L20.4318 6.65043Z' fill='#F0C355' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.025 8.48837C15.048 6.79737 13.243 5.73438 11.254 5.73438C8.499 5.73438 6.208 7.77337 5.811 10.4204C4.2 10.8094 3 12.2644 3 13.9904C3 16.0164 4.649 17.6664 6.674 17.6664L15.838 17.6684C18.368 17.6684 20.428 15.6074 20.428 13.0774C20.427 10.6064 18.468 8.58737 16.025 8.48837ZM15.838 15.8144H6.676C5.67 15.8144 4.851 14.9964 4.851 13.9904C4.851 12.9864 5.671 12.1664 6.676 12.1664H7.6V11.2434C7.6 9.22936 9.241 7.58636 11.256 7.58636C12.725 7.58636 14.043 8.45736 14.621 9.80836L14.889 10.4344L15.838 10.3334C17.349 10.3334 18.578 11.5644 18.578 13.0764C18.578 14.5864 17.349 15.8144 15.838 15.8144Z'
      fill='#868CA2'
    />
    <path
      d='M4.09883 18.9336L3.04883 19.9826L4.02383 20.9576L3.04883 21.9356L4.09883 22.9856L5.07483 22.0086L6.04783 22.9856L7.09883 21.9356L6.12483 20.9576L7.09883 19.9826L6.04783 18.9336L5.07483 19.9086L4.09883 18.9336Z'
      fill='#82CFFF'
    />
    <path
      d='M10.6876 18.9336L9.63965 19.9826L10.6136 20.9576L9.63965 21.9356L10.6876 22.9856L11.6636 22.0086L12.6396 22.9856L13.6896 21.9356L12.7146 20.9576L13.6896 19.9826L12.6396 18.9336L11.6636 19.9086L10.6876 18.9336Z'
      fill='#82CFFF'
    />
    <path
      d='M17.2795 18.9336L16.2285 19.9826L17.2065 20.9576L16.2285 21.9356L17.2795 22.9856L18.2555 22.0086L19.2295 22.9856L20.2795 21.9356L19.3045 20.9576L20.2795 19.9826L19.2295 18.9336L18.2555 19.9086L17.2795 18.9336Z'
      fill='#82CFFF'
    />
  </svg>
);

export default SvgWeatherDailyMixedWithSnowShowers;
