import { Popover } from 'antd';
import { ReactComponent as ExportSamplingSvg } from 'assets/images/reports/export-sampling.svg';
import { ReactComponent as ImportSamplingSvg } from 'assets/images/reports/import-sampling.svg';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import type { ReportsTypes } from './reports-types.enum';

interface ReportOptionsProps {
  children: ReactNode;
  type?: ReportsTypes;
  importFunction: () => void;
  exportFunction: () => void;
  importText: string;
  exportText: string;
  openPopConfirm?: ReportsTypes;
}

/**
 * Generic FC to add option on Report Button with import and export Drawers
 */
export const ReportOptions = ({
  children,
  type,
  importFunction,
  exportFunction,
  importText,
  exportText,
  openPopConfirm
}: ReportOptionsProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!openPopConfirm || !type) return;
    setVisible(Boolean(openPopConfirm === type));
  }, [openPopConfirm, type]);

  const handleExportFunction = () => {
    setVisible(old => !old);
    exportFunction();
  };

  const handleImportFunction = () => {
    setVisible(old => !old);
    importFunction();
  };

  const handleVisibleChange = (isVisible: boolean) => {
    setVisible(isVisible);
  };

  return (
    <Popover
      content={
        <div className='report-options'>
          <div className='report-options__export' onClick={handleExportFunction}>
            <div className='report-options__export-icon'>
              <ExportSamplingSvg />
            </div>
            <span>{exportText}</span>
          </div>
          <div className='report-options__import' onClick={handleImportFunction}>
            <div className='report-options__import-icon'>
              <ImportSamplingSvg />
            </div>
            <span>{importText}</span>
          </div>
        </div>
      }
      onVisibleChange={handleVisibleChange}
      placement='bottom'
      visible={visible}>
      <div onClick={() => setVisible(old => !old)}>{children}</div>
    </Popover>
  );
};
