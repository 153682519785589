import App from 'app';
import AntdProvider from 'config/antd-provider/antd-provider';
import 'config/i18n';
import 'config/interceptors';
import 'config/moment-import';
import 'config/numeral-import';
import GlobalStyles from 'config/style/global.styles';
import 'main.less';
import ErrorBoundary from 'pages/error-boundary/error-boundary.component';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from './redux/config';

const store = configureStore();

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <AntdProvider>
        <GlobalStyles />
        <App />
      </AntdProvider>
    </ErrorBoundary>
  </Provider>
);
