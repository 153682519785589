import type { AxiosResponse } from 'axios';
import axios from 'axios-observable';
import { CORE_SERVICES_API_URL } from 'config/constants';
import type { User } from 'core/services/auth/auth.models';
import type { UUID } from 'core/utils/basic.models';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

const coreServicesApiUrl = CORE_SERVICES_API_URL;

export const getUserById = (userId?: UUID): Observable<AxiosResponse<User> | Record<string, unknown>> => {
  if (userId) {
    return axios.get<User>(`${coreServicesApiUrl}/v2/accounts/${userId}`).pipe(catchError(() => of({})));
  }
  return of({});
};
