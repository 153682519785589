import { QueryClient } from 'core/utils/data-store/tools';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: Infinity
    },
    mutations: {
      retry: false
    }
  }
});