import type { Action } from 'core/utils/basic.models';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AlertsActionsTypes, LoadAlertsFailure, LoadAlertsSuccess } from './alerts.actions';
import type { AlertBody } from './alerts.models';
import { getAllAlerts } from './alerts.service';

const handleLoadAlerts = action$ =>
  action$.pipe(
    ofType(AlertsActionsTypes.LOAD_ALERTS),
    map((action: Action) => action.payload),
    concatMap(() => getAllAlerts()),
    map((alerts: AlertBody[]) => {
      return LoadAlertsSuccess(alerts);
    }),
    catchError((error: string) => of(LoadAlertsFailure(error)))
  );

export default handleLoadAlerts;
