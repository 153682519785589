import { stringify } from 'qs';

/**
 * Serializes an array to array-repeat format.
 * Use this with Axios "paramsSerializer" to serialize query params with this format:
 * test=['foo', 'bar'] => ?test=foo&test=bar
 */
export const serializeParamsArrayRepeat = (params: unknown[]) => {
  return stringify(params, { arrayFormat: 'repeat' });
};
