import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import type { SelectEntityType } from './core.models';
import type { SegmentTrackingHookReturn } from './utils/segment/useSegmentTracking';

export const CoreActionsTypes = {
  CHANGE_APP_LOCATION: '[Global Ui] Change Location',
  SELECT_COMPANY: '[Global Ui] Select Company',
  SELECT_PROPERTY: '[Global Ui] Select Property',
  SAVE_FLAGS: '[Global Ui] Save Flags',
  UPDATE_FLAG: '[Global Ui] Update Flag',
  SELECT_SEASONS: '[Global Ui] Select Seasons',
  SELECT_REGION: '[Global Ui] Select Region',
  SELECT_AREA: '[Global Ui] Select Area',
  CHANGE_DASHBOARD_STATE: '[Global Ui] Change Dashboard State',
  TOGGLE_HEADER: '[Global Ui] Toggle Header',
  TOGGLE_SIDER: '[Global Ui] Toggle Sider',
  TOGGLE_MONITORING_DRAWER: '[Global Ui] Toggle Monitoring Drawer',
  CHANGE_CURRENCY: '[Global Ui] Change currency',
  CHANGE_UNIT_SYSTEM: '[Global Ui] Change unit system',
  CHANGE_INTERFACE_LANGUAGE: '[Global Ui] Change interface language',
  LOAD_APPS_PERMISSIONS: '[Apps] Load Apps Permission',
  LOAD_APPS_PERMISSIONS_SUCCESS: '[Apps] Load Apps Permission Success',
  LOAD_APPS_PERMISSIONS_FAILURE: '[Apps] Load Apps Permission Failure'
};

export const SetCurrency = currency => {
  return {
    type: CoreActionsTypes.CHANGE_CURRENCY,
    payload: currency
  };
};

export const SetSystemFlags = flags => {
  return {
    type: CoreActionsTypes.SAVE_FLAGS,
    payload: flags
  };
};

export const UpdateSystemFlag = (flag: string, value: boolean) => {
  return {
    type: CoreActionsTypes.UPDATE_FLAG,
    payload: {
      flag,
      value
    }
  };
};

export const SetUnitSystem = (unitSystem, segmentTracking: SegmentTrackingHookReturn) => {
  const payload: AnalyticsInfo = {
    unitSystem
  };
  segmentTracking.track('User Settings - Changed Unit System', payload);
  return {
    type: CoreActionsTypes.CHANGE_UNIT_SYSTEM,
    payload: unitSystem
  };
};

export const SetCurrentLocation = currentLocation => {
  return {
    type: CoreActionsTypes.CHANGE_APP_LOCATION,
    payload: currentLocation
  };
};

export const SetCurrentCompany: SelectEntityType = company_id => {
  return {
    type: CoreActionsTypes.SELECT_COMPANY,
    payload: company_id
  };
};

export const SetInterfaceLanguage: SelectEntityType = language => {
  return {
    type: CoreActionsTypes.CHANGE_INTERFACE_LANGUAGE,
    payload: language
  };
};

export const SetCurrentProperty: SelectEntityType = property_id => {
  return {
    type: CoreActionsTypes.SELECT_PROPERTY,
    payload: property_id
  };
};

export const SetCurrentRegion: SelectEntityType = regionId => {
  return {
    type: CoreActionsTypes.SELECT_REGION,
    payload: regionId
  };
};

export const SetCurrentArea: SelectEntityType = areaId => {
  return {
    type: CoreActionsTypes.SELECT_AREA,
    payload: areaId
  };
};

export const SetCurrentSeasons: SelectEntityType = seasonsIds => {
  return {
    type: CoreActionsTypes.SELECT_SEASONS,
    payload: seasonsIds
  };
};

export const SetDashboardState: SelectEntityType = dashboardState => {
  return {
    type: CoreActionsTypes.CHANGE_DASHBOARD_STATE,
    payload: dashboardState
  };
};

export const ToggleHeader = () => {
  return {
    type: CoreActionsTypes.TOGGLE_HEADER
  };
};

export const ToggleSider = () => {
  return {
    type: CoreActionsTypes.TOGGLE_SIDER
  };
};

export const ToggleMonitoringDrawer = () => {
  return {
    type: CoreActionsTypes.TOGGLE_MONITORING_DRAWER
  };
};

export const AppsPermission = () => ({
  type: CoreActionsTypes.LOAD_APPS_PERMISSIONS
});

export const AppsPermissionSuccess = payload => ({
  type: CoreActionsTypes.LOAD_APPS_PERMISSIONS_SUCCESS,
  payload
});

export const AppsPermissionFailure = error => ({
  type: CoreActionsTypes.LOAD_APPS_PERMISSIONS_FAILURE,
  error
});
