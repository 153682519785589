import CustomCard from 'components/SD-custom-card/custom-card';
import Search from 'components/search/search.component';
import { COLOR_OPTIONS } from 'config/style';
import STAvatarList from 'core/shared/avatar/avatar-list.component';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { filterEntitiesByName, mapEntitiesByAttr } from 'core/utils/functions';
import { includes } from 'lodash';
import type React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCheckbox, StyledCheckboxGroup, StyledContent, StyledCounter } from './multi-select-card.styles';

export interface Option {
  id: string | number;
  name: string;
  [x: string]: any;
  disabled?: boolean;
}

export interface MultiSelectCardProps {
  options: Option[];
  handleSelectedOptions: (selectedOptions: UUID[], checked?: boolean, id?: string) => void;
  title?: string;
  tooltip?: string;
  hasPrefixIcon?: boolean;
  noOptionsFeedback?: React.ReactElement;
  id?: string;
  preSelectedOptions?: any[];
  defaultSelectedOptions?: string[];
  isError?: boolean;
  allowSelectAll?: boolean;
  selectBackgroundColor?: string;
  hasBoxShadow?: boolean;
  placeholder?: string;
  height?: string;
}

const MultiSelectCard: React.FC<MultiSelectCardProps> = ({
  title,
  tooltip,
  options,
  preSelectedOptions,
  hasPrefixIcon,
  handleSelectedOptions,
  noOptionsFeedback,
  id,
  isError,
  selectBackgroundColor,
  allowSelectAll = true,
  hasBoxShadow,
  placeholder,
  defaultSelectedOptions = [],
  height
}) => {
  const [t] = useTranslation();
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedOptions, setSelectedOptions] = useState<UUID[]>(defaultSelectedOptions);
  const hasOptions = !!options?.length;
  const isAllSelected = selectedOptions?.length === options?.length;
  const disableSelectAll = options?.some(option => option.disabled);

  const onSelect = e => {
    const optionsSelect: string[] = e?.target?.checked
      ? [...selectedOptions, e.target.id]
      : selectedOptions.filter(optionId => optionId !== e.target.id);

    handleSelectedOptions(optionsSelect, e?.target?.checked, e?.target?.id);
    setSelectedOptions(optionsSelect);
  };

  const onSelectAll = e => {
    const optionsSelect = e?.target?.checked ? mapEntitiesByAttr(options, 'id') : [];
    setSelectedOptions(optionsSelect);
    handleSelectedOptions(optionsSelect);
  };

  const isChecked = useCallback(
    optionId => {
      return (
        !!selectedOptions.find(selectedOptionId => selectedOptionId === optionId) || includes(defaultSelectedOptions, optionId.toString())
      );
    },
    [selectedOptions, defaultSelectedOptions]
  );

  const handleSearch = useCallback(
    (searchString: string) => {
      const searchOptions = filterEntitiesByName(options, searchString);
      setFilteredOptions(searchOptions);
    },
    [options]
  );

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    const preSelectedList = mapEntitiesByAttr(preSelectedOptions, 'id').filter(optionId => options.some(option => option.id === optionId));
    if (preSelectedList.length && !selectedOptions.length) {
      setSelectedOptions(preSelectedList);
    } else if (selectedOptions.length) {
      const selectedOptionsInOptions = selectedOptions.filter(optionId => options.some(option => option.id === optionId));
      setSelectedOptions(selectedOptionsInOptions);
    }
  }, [preSelectedOptions, options]);

  return (
    <CustomCard
      hasBoxShadow={hasBoxShadow}
      title={title ?? ''}
      titleCustomStyle={{ type: TypeOptions.H5, color: COLOR_OPTIONS.NEUTRAL_100 }}
      subtitle={
        <StyledCounter>
          <STTypo color={COLOR_OPTIONS.NEUTRAL_60}>
            {hasOptions ? t('general.selected_options', { count: selectedOptions.length }) : ''}
          </STTypo>
        </StyledCounter>
      }
      tooltip={tooltip}
      tooltipColor={COLOR_OPTIONS.NEUTRAL_60}
      isError={isError}>
      <StyledContent id={id} isEmpty={!hasOptions} height={height}>
        {hasOptions && (
          <>
            <Search
              blockStyle={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}
              placeholder={placeholder ?? t('pages.tasks.task_create.search')}
              handleSearch={handleSearch}
            />
            <StyledCheckboxGroup>
              {allowSelectAll && (
                <StyledCheckbox
                  data-testid='select_all'
                  id='checkbox-0'
                  onChange={onSelectAll}
                  checked={isAllSelected}
                  disabled={disableSelectAll}>
                  {t('pages.tasks.task_create.all')}
                </StyledCheckbox>
              )}
              {filteredOptions.map(option => (
                <div key={option.id}>
                  <StyledCheckbox
                    selectBackgroundColor={selectBackgroundColor}
                    data-testid={`multiselect-item_${option.id}`}
                    id={String(option.id)}
                    disabled={option.disabled}
                    value={option}
                    onChange={onSelect}
                    checked={isChecked(option.id)}>
                    {hasPrefixIcon ? <STAvatarList disabled names={[option.name]} /> : option.name}
                  </StyledCheckbox>
                </div>
              ))}
            </StyledCheckboxGroup>
          </>
        )}

        {!hasOptions && noOptionsFeedback}
      </StyledContent>
    </CustomCard>
  );
};

export default memo(MultiSelectCard);
