import type { GlobalUiState } from 'core/core.models';
import type { PermissionFlags } from 'core/services/auth/auth.models';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { SvgBlocked } from 'st-protector-icons/Minor/Blocked';
import { RainReportSvgCard } from 'st-protector-icons/Minor/RainReportCard';
import SvgStaticPointReportCard from 'st-protector-icons/Minor/StaticPointReportCard';
import { useRulesToShowReports, useShowOnlyReportsForAllOrg } from '../report-drawer.component.hook';
import { ReportsTypes } from '../reports-types.enum';
import ReportButton from './report-button.component';

interface ReportDrawerFixedPointsButtonsTypes {
  setReportToOpen: (reportType: ReportsTypes) => void;
  systemFlags: GlobalUiState['systemFlags'];
  permissionFlags: PermissionFlags;
}

export const ReportDrawerFixedPointsButtons: React.FC<ReportDrawerFixedPointsButtonsTypes> = ({
  setReportToOpen,
  permissionFlags,
  systemFlags
}) => {
  const showOnlyReportsForAllOrg = useShowOnlyReportsForAllOrg();
  const [t] = useTranslation();
  const reportsRules = useRulesToShowReports();

  if (showOnlyReportsForAllOrg) {
    return null;
  }
  const { hideReportRainFall, hideReportTraps } = permissionFlags;

  const blockedFieldsEnabled = reportsRules[ReportsTypes.BLOCKED_FIELDS];
  const rainFallReportEnabled = !hideReportRainFall?.value && !!systemFlags?.P40_13021_rainGaugeReport;
  const trapReportEnabled = !hideReportTraps?.value;

  const hasAnyReport = rainFallReportEnabled || trapReportEnabled || blockedFieldsEnabled;

  return (
    <>
      {hasAnyReport && (
        <div className='st-report-drawer__subtitle-group' data-testid='report-subtitle'>
          <STTypo type={TypeOptions.P2} color='#696F88' uppercase>
            <STTypo>{t('report.title.group.fixed_points')}</STTypo>
          </STTypo>
        </div>
      )}
      {/* Rainfall recordings */}
      {systemFlags?.P40_13021_rainGaugeReport && !hideReportRainFall.value && (
        <ReportButton
          className='st-report-drawer__item-sample'
          setReportToOpen={setReportToOpen}
          text={t('report.rain_gauge.name')}
          type={ReportsTypes.RAIN_GAUGE_REPORT}
          icon={<RainReportSvgCard />}
        />
      )}
      {/* Trap statistics */}
      {!hideReportTraps?.value && (
        <ReportButton
          className='st-report-drawer__item-sample'
          setReportToOpen={setReportToOpen}
          text={t('report.trap.name')}
          type={ReportsTypes.TRAP}
          icon={<SvgStaticPointReportCard fill='#D9800A' />}
        />
      )}
      {reportsRules[ReportsTypes.BLOCKED_FIELDS] && (
        <ReportButton
          className='st-report-drawer__item-sample'
          setReportToOpen={setReportToOpen}
          text={t('report.blocked_fields.name')}
          type={ReportsTypes.BLOCKED_FIELDS}
          icon={<SvgBlocked />}
        />
      )}
    </>
  );
};
