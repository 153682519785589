import UnstyledButton from 'components/unstyled-button';
import { getPermissionFlags } from 'core/core.selectors';
import STTypo, { STTypoModels } from 'core/shared/typo';
import type { AlertArea, AlertBody } from 'entities/alerts/alerts.models';
import { useGetRBACAuthorityUser } from 'querys/rbac/rbac.query';
import { RBACActions, RBACLevels, RBACPermissionTypesEnum } from 'querys/rbac/rbac.query.model';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SvgSettings32 from 'st-protector-icons/Major/Settings32';
import { getPropertie } from './alert.functions';
import './alert.styles.less';
import AlertsTypeEnum from './alerts.enum';
import type { Generic, OpenDrawer, SeasonsAreas } from './alerts.types';
import StateAreasEnum from './state-areas.enum';
import STAlertUnMonitored from './types/alert-unmonitored.component';
import STAlertWorse from './types/alert-worse.component';

interface AlertListProps {
  listAlerts: AlertBody[];
  listProperties: any[];
  openDrawer?: React.Dispatch<React.SetStateAction<OpenDrawer>>;
}

interface SeasonPeopertie {
  id: string;
  property: Generic;
  seasonAreas: SeasonsAreas[];
}

const STAlertList: React.FC<AlertListProps> = ({ listAlerts, listProperties, openDrawer }: AlertListProps) => {
  const [t] = useTranslation();
  const permissionFlags = useSelector(getPermissionFlags);
  const {
    hideAlertsMonitoring,
    hideAlertsRiskChange,
    hideAlertsPrescription,
    hideAlertsWorsenedStatus,
    hideAlertsWorsenedStatusByProblem
  } = permissionFlags;

  const worsenedStatusAlertPermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.WORSENED_STATUS_ALERT,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.NOT_READ]
  });

  const delayedMonitoringAlertPermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.DELAYED_MONITORING_ALERT,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.NOT_READ]
  });

  const newMonitoringInPropertyAlertPermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.NEW_MONITORING_IN_PROPERTY_ALERT,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.NOT_READ]
  });

  const worsenedStatusByProblemAlertPermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.WORSENED_STATUS_BY_PROBLEM_ALERT,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.NOT_READ]
  });

  const openEdit = (item: AlertBody) => {
    if (openDrawer) openDrawer({ open: true, itemEdit: item });
  };
  const getHeaderContent = (item: AlertBody) => {
    return (
      <div className='st-alert-block__header'>
        <p>
          <STTypo type={STTypoModels.TypeOptions.H5}>{item.name}</STTypo>
        </p>
        <p>
          {item.type === AlertsTypeEnum.AREA_NOT_MONITORED && item.extra_parameters && (
            <STTypo type={STTypoModels.TypeOptions.H5} fontWeight={500}>
              {t('alert.header.unmonitored', {
                count: item.extra_parameters.revisit_time
              })}
            </STTypo>
          )}

          {item.type === AlertsTypeEnum.AREA_STATUS_GOT_WORSE && (
            <STTypo type={STTypoModels.TypeOptions.H5} fontWeight={500}>
              {t('alert.header.worst')}
            </STTypo>
          )}
        </p>
        <UnstyledButton className='st-alert-block__header-edit' data-testid='alert-edit' onClick={() => openEdit(item)}>
          <SvgSettings32 />
        </UnstyledButton>
      </div>
    );
  };

  const returnItemUnDispared = (item: AlertBody) => {
    return (
      <div className='st-alert-block unmonitored'>
        {getHeaderContent(item)}
        <div className='st-alert-block__propertie center'>
          <STTypo type={STTypoModels.TypeOptions.P1}>{t('alert.unmonitored')}</STTypo>
        </div>
      </div>
    );
  };

  const returnItemDispared = (item: AlertBody) => {
    const actualPropertie: SeasonPeopertie = getPropertie(item.property_id, listProperties);

    return (
      <div className='st-alert-block'>
        {getHeaderContent(item)}
        {actualPropertie && (
          <>
            <div className='st-alert-block__propertie'>
              <span className={`st-alert-block__alert ${item.hasNewAlert ? 'hasAlert' : ''}`} />
              <STTypo type={STTypoModels.TypeOptions.P1} fontWeight={500}>
                {actualPropertie.property.name}
              </STTypo>
            </div>
            <div className='st-alert-block__list'>
              <div className='st-alert-table'>
                {item.areas.map((area, index) => {
                  if (area.state === StateAreasEnum.FIRED) {
                    if (item.type === AlertsTypeEnum.AREA_NOT_MONITORED) {
                      return (
                        <STAlertUnMonitored alert={item} areaData={area} key={`area-alert-${index}`} listProperties={listProperties} />
                      );
                    }
                    if (item.type === AlertsTypeEnum.AREA_STATUS_GOT_WORSE) {
                      return <STAlertWorse alert={item} areaData={area} key={`area-alert-${index}`} listProperties={listProperties} />;
                    }
                  }
                  return <></>;
                })}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const haveAlertDispared = (areas: AlertArea[]) => {
    const dispared = areas.reduce<number>((acc, area) => {
      if (area.state !== StateAreasEnum.NOT_DISPARED && area.value) return acc + +area.value;
      return acc;
    }, 0);
    return dispared !== 0;
  };

  const returnList = () => {
    const alertHideMapping = {
      [AlertsTypeEnum.DELAYED_MONITORING]: hideAlertsMonitoring.value && !delayedMonitoringAlertPermission?.length,
      [AlertsTypeEnum.PRESCRIPTION]: hideAlertsPrescription.value && !newMonitoringInPropertyAlertPermission?.length,
      [AlertsTypeEnum.DISEASE_RISK_CHANGE]: hideAlertsRiskChange.value,
      [AlertsTypeEnum.AREA_WORSENED]: hideAlertsWorsenedStatus.value && !worsenedStatusAlertPermission?.length,
      [AlertsTypeEnum.AREA_WORSENED_BY_STATUS]: hideAlertsWorsenedStatusByProblem.value && !worsenedStatusByProblemAlertPermission?.length,
      [AlertsTypeEnum.PROTECTOR_BBCH_PREDICTION]: true
    };

    return listAlerts.map(alert => {
      if (alertHideMapping[alert.type]) {
        return null;
      }
      return haveAlertDispared(alert.areas) ? returnItemDispared(alert) : returnItemUnDispared(alert);
    });
  };

  return <div className='st-alert-list'>{returnList()}</div>;
};

export default STAlertList;
