export enum TypePermissionEnum {
  WRITE = 'WRITE',
  READ = 'READ',
  NONE = 'NONE'
}

export enum PermissionFlagsEnum {
  SIMPLE_COMPANY_DASHBOARD = 'simple_company_dashboard',
  SIMPLE_TIMELINE = 'simple_timeline',
  HIDE_PROPERTY_DASHBOARD = 'hide_property_dashboard',
  HIDE_WAREHOUSE = 'hide_warehouse',
  HIDE_FINANCIAL = 'hide_financial',
  HIDE_SMART_SPRAY = 'hide_smart_spray',
  HIDE_SMART_PLAN = 'hide_smart_plan',
  HIDE_INTEGRATIONS = 'hide_integrations',
  HIDE_TRAPS_MANAGEMENT = 'hide_traps_management',
  SCOUTING_REPRESENTATIVENESS = 'scouting.representativiness',
  HIDE_PROPERTY_HALL = 'hide_property_hall',
  TASKS_NOTIFICATION_EMAIL = 'tasks.notification.email',
  SHOW_NOTES = 'notes.page',
  SHOW_SCOUTING_SCORE = 'show_scouting_score',
  HIDE_ALERTS_MONITORING = 'hide_alerts.monitoring',
  HIDE_ALERTS_RISK_CHANGE = 'hide_alerts.risk_change',
  HIDE_ALERTS_PRESCRIPTION = 'hide_alerts.prescription',
  HIDE_ALERTS_WORSENED_STATUS = 'hide_alerts.worsened_status',
  HIDE_ALERTS_WORSENED_STATUS_BY_PROBLEM = 'hide_alerts.worsened_status_by_problem',
  HIDE_REPORT_APLICATION_STATISTICS = 'hide_report.aplication_statistics',
  HIDE_REPORT_FARM = 'hide_report.property',
  HIDE_REPORT_TRACKING = 'hide_report.tracking',
  HIDE_REPORT_SAMPLE_POINT_AND_AGRCULTURAL_RESOURCES = 'hide_report.sample_point_and_agrcultural_resources',
  HIDE_REPORT_TRAPS = 'hide_report.traps',
  HIDE_MENU_INVENTORY = 'hide_menu.inventory',
  HIDE_REPORT_RAINFALL = 'hide_report.rainfall',
  HIDE_REPORT_MONITORING_STATISTICS = 'hide_report.monitoring_statistics',
  HIDE_REPORT_DETAILED_CONSOLIDATED = 'hide_report.detailed_consolidated',
  HIDE_REPORT_FARM_SCOUTING = 'hide_report.farm_scouting',
  HIDE_SAMPLING_REPORT = 'hide_sampling_report',
  VISIT_REPORT = 'report.visit',
  DISEASE_RISK_REPORT = 'report.diseaserisk',
  /**
   * @description
   * This entitlement key `*does not*` really exist in Staff Console, it is being created only in the context of the User Client project to use the `PermissionFlag` entity of the auth redux.
   * @summary
   * `Nemadigital` does not check an entitlement, as it is a separate app from Protector, we only check if there is a valid and active contract linked to that app
   */
  NEMADIGITAL_APP = 'nemadigital.app'
}
