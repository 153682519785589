const SvgWeatherSunnyFewClouds = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M17.4 12.4L16.7 10.7C17.6 10.3 18.2 9.4 18.2 8.4C18.2 7 17.1 5.9 15.8 5.9C14.6 5.9 13.6 6.8 13.4 8C13.4 8.1 13.4 8.2 13.4 8.4H11.6C11.6 8.2 11.6 8 11.6 7.8C11.9 5.7 13.7 4.2 15.8 4.2C18.1 4.1 20 6 20 8.4C20 10.1 19 11.7 17.4 12.4Z'
      fill='#DFA701'
    />
    <path
      d='M12.3123 5.85473L10.5441 5.15914C10.0816 4.97717 9.55907 5.20463 9.3771 5.66719C9.19513 6.12975 9.42259 6.65225 9.88515 6.83422L11.6533 7.52981C12.1158 7.71178 12.6383 7.48432 12.8203 7.02176C13.0023 6.5592 12.7748 6.0367 12.3123 5.85473Z'
      fill='#DFA701'
    />
    <path
      d='M14.9478 4.19623L14.1183 2.4868C13.9013 2.0396 13.3628 1.853 12.9156 2.07001C12.4684 2.28703 12.2818 2.82548 12.4988 3.27268L13.3284 4.98211C13.5454 5.42931 14.0838 5.61592 14.531 5.3989C14.9782 5.18189 15.1649 4.64343 14.9478 4.19623Z'
      fill='#DFA701'
    />
    <path
      d='M17.9752 4.67448C17.8216 5.13509 17.331 5.39147 16.8652 5.25447C16.3874 5.11393 16.1139 4.61265 16.2545 4.13483L16.7275 2.52639C16.8764 2.0202 17.4155 1.73851 17.9161 1.90536C18.4042 2.06809 18.6681 2.59576 18.5054 3.08394L17.9752 4.67448Z'
      fill='#DFA701'
    />
    <path
      d='M20.5797 4.53978L18.9415 5.50213C18.5129 5.75389 18.3696 6.3054 18.6214 6.73396C18.8731 7.16252 19.4246 7.30585 19.8532 7.05409L21.4914 6.09174C21.9199 5.83998 22.0633 5.28847 21.8115 4.85991C21.5598 4.43135 21.0082 4.28802 20.5797 4.53978Z'
      fill='#DFA701'
    />
    <path
      d='M21.8592 8.87664L20.0027 8.4727C19.5171 8.36703 19.0377 8.67508 18.932 9.16076C18.8263 9.64643 19.1344 10.1258 19.6201 10.2315L21.4765 10.6354C21.9622 10.7411 22.4416 10.433 22.5473 9.94738C22.6529 9.4617 22.3449 8.98232 21.8592 8.87664Z'
      fill='#DFA701'
    />
    <path
      d='M17.2 22.5H5.49998C3.09998 22.5 1.09998 20.5 1.09998 18.1C1.09998 16 2.59998 14.2 4.59998 13.8C4.99998 10.5 7.89998 7.89999 11.3 7.89999C13.8 7.89999 16 9.29999 17.2 11.4C20.2 11.4 22.7 13.9 22.7 17C22.7 20 20.2 22.5 17.2 22.5ZM5.49998 15.5C4.09998 15.5 2.89998 16.7 2.89998 18.1C2.89998 19.5 4.09998 20.7 5.49998 20.7H17.1C19.2 20.7 20.8 19 20.8 17C20.8 15 19.1 13.3 17.1 13.3C17.1 13.3 17 13.3 16.9 13.3C16.8 13.3 16.8 13.3 16.7 13.3L16 13.4L15.7 12.8C14.9 11 13.2 9.79999 11.2 9.79999C8.49998 9.79999 6.29998 12 6.29998 14.7V15.6L5.49998 15.5Z'
      fill='#BDC4D1'
    />
  </svg>
);

export default SvgWeatherSunnyFewClouds;
