import moment from 'moment';
import SvgWeatherDailyFog from 'st-protector-icons/Weather/Daily/DailyFog';
import SvgWeatherDailyMixedWithShowers from 'st-protector-icons/Weather/Daily/DailyMixedWithShowers';
import SvgWeatherDailyMixedWithSnowShowers from 'st-protector-icons/Weather/Daily/DailyMixedWithSnowShowers';
import SvgWeatherDailyMostlyCloudMixtureSnowRain from 'st-protector-icons/Weather/Daily/DailyMostlyCloudMixtureSnowRain';
import SvgWeatherDailyMostlyCloudWithLightRain from 'st-protector-icons/Weather/Daily/DailyMostlyCloudWithLightRain';
import SvgWeatherDailyMostlyCloudWithLightSnow from 'st-protector-icons/Weather/Daily/DailyMostlyCloudWithLightSnow';
import SvgWeatherDailyMostlyCloudWithRain from 'st-protector-icons/Weather/Daily/DailyMostlyCloudWithRain';
import SvgWeatherDailyMostlyCloudWithSnow from 'st-protector-icons/Weather/Daily/DailyMostlyCloudWithSnow';
import SvgWeatherDailyOvercast from 'st-protector-icons/Weather/Daily/DailyOvercast';
import SvgWeatherDailyOvercastWithLightRain from 'st-protector-icons/Weather/Daily/DailyOvercastWithLightRain';
import SvgWeatherDailyOvercastWithLightSnow from 'st-protector-icons/Weather/Daily/DailyOvercastWithLightSnow';
import SvgWeatherDailyOvercastWithRain from 'st-protector-icons/Weather/Daily/DailyOvercastWithRain';
import SvgWeatherDailyOvercastWithSnow from 'st-protector-icons/Weather/Daily/DailyOvercastWithSnow';
import SvgWeatherDailyPartlyCloud from 'st-protector-icons/Weather/Daily/DailyPartlyCloud';
import SvgWeatherDailyShowersThunderstormsLikely from 'st-protector-icons/Weather/Daily/DailyShowersThunderstormsLikely';
import SvgWeatherDailySunny from 'st-protector-icons/Weather/Daily/DailySunny';
import SvgWeatherDailySunnyFewClouds from 'st-protector-icons/Weather/Daily/DailySunnyFewClouds';
import SvgWeatherCloudyLightRain from 'st-protector-icons/Weather/Hourly/CloudyLightRain';
import SvgWeatherCloudyLightSnow from 'st-protector-icons/Weather/Hourly/CloudyLightSnow';
import SvgWeatherCloudyRain from 'st-protector-icons/Weather/Hourly/CloudyRain';
import SvgWeatherCloudyRainSnow from 'st-protector-icons/Weather/Hourly/CloudyRainSnow';
import SvgWeatherCloudySnow from 'st-protector-icons/Weather/Hourly/CloudySnow';
import SvgWeatherFog from 'st-protector-icons/Weather/Hourly/Fog';
import SvgWeatherGeneric from 'st-protector-icons/Weather/Hourly/Generic';
import SvgWeatherHourlyClearCloudless from 'st-protector-icons/Weather/Hourly/HourlyClearCloudless';
import SvgWeatherHourlyOvercast from 'st-protector-icons/Weather/Hourly/HourlyOvercast';
import SvgWeatherPartlyCloud from 'st-protector-icons/Weather/Hourly/PartlyCloud';
import SvgWeatherRain from 'st-protector-icons/Weather/Hourly/Rain';
import SvgWeatherRainLight from 'st-protector-icons/Weather/Hourly/RainLight';
import SvgWeatherRainSunny from 'st-protector-icons/Weather/Hourly/RainSunny';
import SvgWeatherSnow from 'st-protector-icons/Weather/Hourly/Snow';
import SvgWeatherSnowLight from 'st-protector-icons/Weather/Hourly/SnowLight';
import SvgWeatherSnowSunny from 'st-protector-icons/Weather/Hourly/SnowSunny';
import SvgWeatherStorm from 'st-protector-icons/Weather/Hourly/Storm';
import SvgWeatherSunnyFewClouds from 'st-protector-icons/Weather/Hourly/SunnyFewClouds';
import WeatherDataSourceEnum from './weather-data-source.enum';
import { WeatherMeteoBlueDailyEnum, WeatherMeteoBlueEnum } from './weather-meteoblue.enum';
import type { WeatherBody, WeatherData, WeatherScreenData } from './weather.models';

export const connectDataSources = (dataSourceName: string, value: any): WeatherMeteoBlueDailyEnum => {
  if (dataSourceName === WeatherDataSourceEnum.CLIMATEMPO) {
    switch (value) {
      case 1:
      case '1n':
        return WeatherMeteoBlueDailyEnum.SUNNY;
      case 2:
      case '2n':
        return WeatherMeteoBlueDailyEnum.SUNNY_FEW_CLOUDS;
      case '2r':
      case '2rn':
        return WeatherMeteoBlueDailyEnum.PARTLY_CLOUD;
      case 3:
      case '3n':
        return WeatherMeteoBlueDailyEnum.RAIN_LIGHT;
      case '3TM':
        return WeatherMeteoBlueDailyEnum.CLOUDY;
      case 4:
      case '4n':
      case '4r':
      case '4rn':
        return WeatherMeteoBlueDailyEnum.RAIN_SUNNY;
      case '4t':
      case '4tn':
      case 6:
      case '6n':
        return WeatherMeteoBlueDailyEnum.STORM;
      case 5:
      case '5n':
        return WeatherMeteoBlueDailyEnum.RAIN;
      case 7:
      case '7n':
      case 8:
      case '8n':
        return WeatherMeteoBlueDailyEnum.SNOW_LIGHT;
      case 9:
      case '9n':
        return WeatherMeteoBlueDailyEnum.FOG;
    }
  }

  return value;
};

export const getImageWeather = (code: WeatherMeteoBlueEnum | WeatherMeteoBlueDailyEnum, isHourly = true): JSX.Element => {
  if (isHourly) {
    switch (code) {
      case WeatherMeteoBlueEnum.SUNNY:
        return <SvgWeatherHourlyClearCloudless />;
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_2:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_3:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_4:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_5:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_6:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_7:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_8:
        return <SvgWeatherSunnyFewClouds />;
      case WeatherMeteoBlueEnum.PARTLY_CLOUD:
      case WeatherMeteoBlueEnum.PARTLY_CLOUD_2:
      case WeatherMeteoBlueEnum.PARTLY_CLOUD_3:
      case WeatherMeteoBlueEnum.PARTLY_CLOUD_4:
      case WeatherMeteoBlueEnum.PARTLY_CLOUD_5:
      case WeatherMeteoBlueEnum.PARTLY_CLOUD_6:
        return <SvgWeatherPartlyCloud />;
      case WeatherMeteoBlueEnum.CLOUDY:
      case WeatherMeteoBlueEnum.CLOUDY_2:
      case WeatherMeteoBlueEnum.CLOUDY_3:
      case WeatherMeteoBlueEnum.CLOUDY_4:
        return <SvgWeatherHourlyOvercast />;
      case WeatherMeteoBlueEnum.FOG:
      case WeatherMeteoBlueEnum.FOG_2:
      case WeatherMeteoBlueEnum.FOG_3:
        return <SvgWeatherFog />;
      case WeatherMeteoBlueEnum.RAIN:
      case WeatherMeteoBlueEnum.RAIN_2:
        return <SvgWeatherRain />;
      case WeatherMeteoBlueEnum.RAIN_SUNNY:
        return <SvgWeatherRainSunny />;
      case WeatherMeteoBlueEnum.STORM:
      case WeatherMeteoBlueEnum.STORM_2:
      case WeatherMeteoBlueEnum.STORM_3:
        return <SvgWeatherStorm />;
      case WeatherMeteoBlueEnum.SNOW:
      case WeatherMeteoBlueEnum.SNOW_2:
      case WeatherMeteoBlueEnum.SNOW_3:
        return <SvgWeatherSnow />;
      case WeatherMeteoBlueEnum.SNOW_SUNNY:
        return <SvgWeatherSnowSunny />;
      case WeatherMeteoBlueEnum.CLOUDY_RAIN_SNOW:
        return <SvgWeatherCloudyRainSnow />;
      case WeatherMeteoBlueEnum.RAIN_LIGHT:
        return <SvgWeatherRainLight />;
      case WeatherMeteoBlueEnum.SNOW_LIGHT:
        return <SvgWeatherSnowLight />;
      case WeatherMeteoBlueEnum.CLOUDY_RAIN:
        return <SvgWeatherCloudyRain />;
      case WeatherMeteoBlueEnum.CLOUDY_SNOW:
        return <SvgWeatherCloudySnow />;
      case WeatherMeteoBlueEnum.CLOUDY_LIGHT_RAIN:
        return <SvgWeatherCloudyLightRain />;
      case WeatherMeteoBlueEnum.CLOUDY_LIGHT_SNOW:
        return <SvgWeatherCloudyLightSnow />;
    }
  } else {
    switch (code) {
      case WeatherMeteoBlueDailyEnum.SUNNY:
        return <SvgWeatherDailySunny />;
      case WeatherMeteoBlueDailyEnum.SUNNY_FEW_CLOUDS:
        return <SvgWeatherDailySunnyFewClouds />;
      case WeatherMeteoBlueDailyEnum.PARTLY_CLOUD:
        return <SvgWeatherDailyPartlyCloud />;
      case WeatherMeteoBlueDailyEnum.CLOUDY:
        return <SvgWeatherDailyOvercast />;
      case WeatherMeteoBlueDailyEnum.FOG:
        return <SvgWeatherDailyFog />;
      case WeatherMeteoBlueDailyEnum.RAIN:
        return <SvgWeatherDailyOvercastWithRain />;
      case WeatherMeteoBlueDailyEnum.RAIN_SUNNY:
        return <SvgWeatherDailyMixedWithShowers />;
      case WeatherMeteoBlueDailyEnum.STORM:
        return <SvgWeatherDailyShowersThunderstormsLikely />;
      case WeatherMeteoBlueDailyEnum.SNOW:
        return <SvgWeatherDailyOvercastWithSnow />;
      case WeatherMeteoBlueDailyEnum.SNOW_SUNNY:
        return <SvgWeatherDailyMixedWithSnowShowers />;
      case WeatherMeteoBlueDailyEnum.CLOUDY_RAIN_SNOW:
        return <SvgWeatherDailyMostlyCloudMixtureSnowRain />;
      case WeatherMeteoBlueDailyEnum.RAIN_LIGHT:
        return <SvgWeatherDailyOvercastWithLightRain />;
      case WeatherMeteoBlueDailyEnum.SNOW_LIGHT:
        return <SvgWeatherDailyOvercastWithLightSnow />;
      case WeatherMeteoBlueDailyEnum.CLOUDY_RAIN:
        return <SvgWeatherDailyMostlyCloudWithRain />;
      case WeatherMeteoBlueDailyEnum.CLOUDY_SNOW:
        return <SvgWeatherDailyMostlyCloudWithSnow />;
      case WeatherMeteoBlueDailyEnum.CLOUDY_LIGHT_RAIN:
        return <SvgWeatherDailyMostlyCloudWithLightRain />;
      case WeatherMeteoBlueDailyEnum.CLOUDY_LIGHT_SNOW:
        return <SvgWeatherDailyMostlyCloudWithLightSnow />;
    }
  }

  return <SvgWeatherGeneric />;
};

export const getImageCardDaily = (code: WeatherMeteoBlueEnum | WeatherMeteoBlueDailyEnum): string => {
  switch (code) {
    case WeatherMeteoBlueDailyEnum.SUNNY:
      return 'WeatherSun';
    case WeatherMeteoBlueDailyEnum.SUNNY_FEW_CLOUDS:
    case WeatherMeteoBlueDailyEnum.FOG:
      return 'WeatherGeneric';
    case WeatherMeteoBlueDailyEnum.RAIN:
    case WeatherMeteoBlueDailyEnum.RAIN_SUNNY:
      return 'WeatherRain';
    case WeatherMeteoBlueDailyEnum.RAIN_LIGHT:
    case WeatherMeteoBlueDailyEnum.PARTLY_CLOUD:
      return 'WeatherPartlyCloudy';
    case WeatherMeteoBlueDailyEnum.CLOUDY:
    case WeatherMeteoBlueDailyEnum.CLOUDY_RAIN:
    case WeatherMeteoBlueDailyEnum.CLOUDY_SNOW:
    case WeatherMeteoBlueDailyEnum.CLOUDY_LIGHT_RAIN:
    case WeatherMeteoBlueDailyEnum.CLOUDY_LIGHT_SNOW:
      return 'WeatherCloudy';
    case WeatherMeteoBlueDailyEnum.STORM:
      return 'WeatherStorm';
    case WeatherMeteoBlueDailyEnum.SNOW_SUNNY:
    case WeatherMeteoBlueDailyEnum.SNOW:
    case WeatherMeteoBlueDailyEnum.CLOUDY_RAIN_SNOW:
    case WeatherMeteoBlueDailyEnum.SNOW_LIGHT:
      return 'WeatherSnow';
    default:
      return 'WeatherGeneric';
  }
};

export const getConditionName = (code: WeatherMeteoBlueEnum | WeatherMeteoBlueDailyEnum, isHourly = true): string => {
  if (isHourly) {
    switch (code) {
      case WeatherMeteoBlueEnum.SUNNY:
        return 'SUNNY';
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_2:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_3:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_4:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_5:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_6:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_7:
      case WeatherMeteoBlueEnum.SUNNY_FEW_CLOUDS_8:
        return 'SUNNY_FEW_CLOUDS';
      case WeatherMeteoBlueEnum.PARTLY_CLOUD:
      case WeatherMeteoBlueEnum.PARTLY_CLOUD_2:
      case WeatherMeteoBlueEnum.PARTLY_CLOUD_3:
      case WeatherMeteoBlueEnum.PARTLY_CLOUD_4:
      case WeatherMeteoBlueEnum.PARTLY_CLOUD_5:
      case WeatherMeteoBlueEnum.PARTLY_CLOUD_6:
        return 'PARTLY_CLOUD';
      case WeatherMeteoBlueEnum.CLOUDY:
      case WeatherMeteoBlueEnum.CLOUDY_2:
      case WeatherMeteoBlueEnum.CLOUDY_3:
      case WeatherMeteoBlueEnum.CLOUDY_4:
        return 'CLOUDY';
      case WeatherMeteoBlueEnum.FOG:
      case WeatherMeteoBlueEnum.FOG_2:
      case WeatherMeteoBlueEnum.FOG_3:
        return 'FOG';
      case WeatherMeteoBlueEnum.RAIN:
      case WeatherMeteoBlueEnum.RAIN_2:
        return 'RAIN';
      case WeatherMeteoBlueEnum.RAIN_SUNNY:
        return 'RAIN_SUNNY';
      case WeatherMeteoBlueEnum.STORM:
      case WeatherMeteoBlueEnum.STORM_2:
      case WeatherMeteoBlueEnum.STORM_3:
        return 'STORM';
      case WeatherMeteoBlueEnum.SNOW:
      case WeatherMeteoBlueEnum.SNOW_2:
      case WeatherMeteoBlueEnum.SNOW_3:
        return 'SNOW';
      case WeatherMeteoBlueEnum.SNOW_SUNNY:
        return 'SNOW_SUNNY';
      case WeatherMeteoBlueEnum.CLOUDY_RAIN_SNOW:
        return 'CLOUDY_RAIN_SNOW';
      case WeatherMeteoBlueEnum.RAIN_LIGHT:
        return 'RAIN_LIGHT';
      case WeatherMeteoBlueEnum.SNOW_LIGHT:
        return 'SNOW_LIGHT';
      default:
        return 'WeatherGeneric';
    }
  } else {
    switch (code) {
      case WeatherMeteoBlueDailyEnum.SUNNY:
        return 'SUNNY';
      case WeatherMeteoBlueDailyEnum.SUNNY_FEW_CLOUDS:
        return 'SUNNY_FEW_CLOUDS';
      case WeatherMeteoBlueDailyEnum.PARTLY_CLOUD:
        return 'PARTLY_CLOUD';
      case WeatherMeteoBlueDailyEnum.CLOUDY:
        return 'CLOUDY';
      case WeatherMeteoBlueDailyEnum.FOG:
        return 'FOG';
      case WeatherMeteoBlueDailyEnum.RAIN:
        return 'RAIN';
      case WeatherMeteoBlueDailyEnum.RAIN_SUNNY:
        return 'RAIN_SUNNY';
      case WeatherMeteoBlueDailyEnum.STORM:
        return 'STORM';
      case WeatherMeteoBlueDailyEnum.SNOW:
        return 'SNOW';
      case WeatherMeteoBlueDailyEnum.SNOW_SUNNY:
        return 'SNOW_SUNNY';
      case WeatherMeteoBlueDailyEnum.CLOUDY_RAIN_SNOW:
        return 'CLOUDY_RAIN_SNOW';
      case WeatherMeteoBlueDailyEnum.RAIN_LIGHT:
        return 'RAIN_LIGHT';
      case WeatherMeteoBlueDailyEnum.SNOW_LIGHT:
        return 'SNOW_LIGHT';
      case WeatherMeteoBlueDailyEnum.CLOUDY_RAIN:
        return 'CLOUDY_RAIN';
      case WeatherMeteoBlueDailyEnum.CLOUDY_SNOW:
        return 'CLOUDY_SNOW';
      case WeatherMeteoBlueDailyEnum.CLOUDY_LIGHT_RAIN:
        return 'CLOUDY_LIGHT_RAIN';
      case WeatherMeteoBlueDailyEnum.CLOUDY_LIGHT_SNOW:
        return 'CLOUDY_LIGHT_SNOW';
      default:
        return '';
    }
  }
};

export const orderWeatherData = (listData: WeatherBody[]): WeatherBody[] => {
  const listWeather = [...listData].sort((itemA, itemB) => moment(itemA.date).valueOf() - moment(itemB.date).valueOf());
  return listWeather;
};

const findPropertyValue = (listData, propertyName, dateToVerify, dateFormat) => {
  return listData.content?.find(
    data => moment(data.date).format(dateFormat) === moment(dateToVerify).format(dateFormat) && data.property === propertyName
  );
};

const findPropertyPerSlice = (listData, dateToVerify, propertyName) => {
  return listData.content?.find(data => data.date.slice(0, 10) === dateToVerify.format().slice(0, 10) && data.property === propertyName);
};

export const handleParseWindDirectionByCurrentLanguage = (windDirection: string | undefined, t: (value: string) => string) => {
  return windDirection ? t(`wind_direction_unity.${windDirection?.toLowerCase()}`) : '';
};

export const parserWeatherData = (
  listData: WeatherData,
  totalDays = 1,
  listDataHourly: WeatherData = {} as WeatherData,
  propertyTime = '',
  daily = false
): WeatherScreenData[] => {
  let returnData: WeatherScreenData[] = [];
  let mutable_totalLoop = Array(totalDays);
  const initialDate = propertyTime ? moment(propertyTime) : moment();
  mutable_totalLoop = mutable_totalLoop.fill('');
  mutable_totalLoop.forEach((value, index) => {
    const dateToVerify = moment(initialDate).add(index, 'days');
    let windDirection;

    if (!daily && listDataHourly && listDataHourly.content && listDataHourly.content.length) {
      windDirection = findPropertyValue(listDataHourly, 'wind_direction_avg', dateToVerify, 'DD/MM/YYYY HH');
    } else if (daily && listData && listData.content && listData.content.length) {
      windDirection = findPropertyValue(listData, 'wind_direction_avg', dateToVerify, 'DD/MM/YYYY');
    }

    const windySpeed = findPropertyValue(listDataHourly, 'wind_speed_avg', dateToVerify, 'DD/MM/YYYY HH');
    const temp_air = findPropertyValue(listDataHourly, 'temp_air_avg', dateToVerify, 'DD/MM/YYYY HH');
    const temp_air_max = listData.content.find(data => {
      return !!(data.date.slice(0, 10) === dateToVerify.format().slice(0, 10) && data.property === 'temp_air_max');
    });
    const temp_air_min: any = findPropertyPerSlice(listData, dateToVerify, 'temp_air_min');
    const rainPercent: any = findPropertyPerSlice(listData, dateToVerify, 'precip_probability');
    const rainQuantity: any = findPropertyPerSlice(listData, dateToVerify, 'precip_sum');

    let relativeHumidity;
    let condition;

    if (listData.dataSource === WeatherDataSourceEnum.METEOBLUE) {
      if (!daily && listDataHourly && listDataHourly.content && listDataHourly.content.length) {
        relativeHumidity = findPropertyValue(listDataHourly, 'humidity_rel_avg', dateToVerify, 'DD/MM/YYYY HH');
        condition = findPropertyValue(listDataHourly, 'picto_code', dateToVerify, 'DD/MM/YYYY HH');
      } else if (daily && listData && listData.content && listData.content.length) {
        relativeHumidity = findPropertyValue(listData, 'humidity_rel_avg', dateToVerify, 'DD/MM/YYYY');
        condition = findPropertyValue(listData, 'picto_code', dateToVerify, 'DD/MM/YYYY');
      }
    } else {
      condition = findPropertyPerSlice(listData, dateToVerify, 'picto_code');
      const relativeHumidityMax = findPropertyPerSlice(listData, dateToVerify, 'humidity_rel_max');
      const relativeHumidityMin = findPropertyPerSlice(listData, dateToVerify, 'humidity_rel_min');
      if (relativeHumidityMax && relativeHumidityMin) {
        relativeHumidity = {
          details: {
            amount: (+relativeHumidityMax.details.amount + +relativeHumidityMin.details.amount) / 2
          }
        };
      }
    }
    const fillData: WeatherScreenData = {
      propertyName: listData.propertyName,
      date: dateToVerify.toString(),
      temperature: temp_air ? +temp_air.details.amount : 0,
      temperatureMax: temp_air_max ? +temp_air_max.details.amount : 0,
      temperatureMin: temp_air_min ? +temp_air_min.details.amount : 0,
      condition: condition ? +condition.details.amount : 0,
      rainPercent: rainPercent ? +rainPercent.details.amount : 0,
      rainQuantity: rainQuantity ? +rainQuantity.details.amount : 0,
      relativeHumidity: relativeHumidity ? +relativeHumidity.details.amount : 0,
      windySpeed: windySpeed ? +windySpeed.details.amount : 0,
      windDirection: windDirection ? windDirection.details.amount : ''
    };
    returnData = [...returnData, ...[fillData]];
  });
  return returnData;
};
