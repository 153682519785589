import type { Company } from 'entities/company/company.models';
import type { Property } from 'entities/property/property.models';
import type { Season } from 'entities/season/season.models';
import type { ReactNode } from 'react';
import type { ReportsTypes } from '../reports-types.enum';

export interface ISTReportPropertyDrawer {
  visible: ReportsTypes | null;
  callbackDrawer: () => void;
  // Redux state
  company?: Company;
  property?: Property;
  seasons: Season[];
  icon?: ReactNode;
}

export interface IReportPropertyWidget {
  pages: number;
}

export enum TypeSamplingError {
  SAMPLING_NOT_FOUND = 'SAMPLING_NOT_FOUND',
  NO_MEASURES = 'NO_MEASURES',
  ALREADY_IMPORTED_SAMPLING = 'ALREADY_IMPORTED_SAMPLING',
  INVALID_CHARACTERISTICS = 'INVALID_CHARACTERISTICS',
  NO_SEASON_AREA = 'NO_SEASON_AREA'
}

export enum TypeColumnSamplingError {
  HEADER_OUT_OF_PATTERN = 'HEADER_OUT_OF_PATTERN',
  CONTENT_OUT_OF_PATTERN = 'CONTENT_OUT_OF_PATTERN',
  MISSING_COLUMNS = 'MISSING_COLUMNS'
}

export interface LineErrorInterface {
  lineNumber: number;
  errorType: TypeSamplingError;
}

export interface ColumnErrorInterface {
  columnNumber: number;
  errorType: TypeColumnSamplingError;
}

export interface LineErrorPayloadInterface {
  response: {
    data: {
      error?: string;
      line_errors?: {
        line: number;
        error_type: TypeSamplingError;
      }[];
      column_errors?: {
        column: number;
        error_type: TypeColumnSamplingError;
        message: string;
      }[];
      status?: number;
      timestamp?: string;
    };
  };
}
