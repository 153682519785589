import { applyMiddleware, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic, rootReducer } from './root';

const epicMiddleware = createEpicMiddleware();

const configureStore = () => {
  const middlewares: any[] = [epicMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers: any[] = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(enableBatching(rootReducer()), undefined, composedEnhancers);

  epicMiddleware.run(rootEpic);

  return store;
};

export default configureStore;
