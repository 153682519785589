import { create } from 'zustand';

interface VersionSlice {
  version?: string;
  setVersion: (version: string) => void;
}

const useVersionStore = create<VersionSlice>(set => ({
  version: undefined,
  setVersion: version => set(() => ({ version }))
}));

export { useVersionStore as default };
