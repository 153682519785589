import { initialV4Page } from 'core/utils/basic.models';
import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { ProductActionsTypes } from './product.actions';
import type { Product, ProductState } from './product.models';

function selectProductUuid(product: Product): string {
  return product.id;
}

const entity: EntityAdapter<Product> = createEntityAdapter<Product>({
  selectId: selectProductUuid
});

const initialState = entity.getInitialState<ProductState>({
  ids: [],
  entities: {},
  page: initialV4Page,
  isLoading: false,
  productLoaded: false,
  error: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case ProductActionsTypes.LOAD_PRODUCT:
      return {
        ...state,
        isLoading: true,
        productLoaded: false
      };
    case ProductActionsTypes.LOAD_PRODUCT_BY_IDS:
      return {
        ...state,
        isLoading: true
      };
    case ProductActionsTypes.LOAD_PRODUCT_SUCCESS:
      return entity.addAll(action.payload.content, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        productLoaded: true,
        error: null
      });
    case ProductActionsTypes.LOAD_PRODUCT_FAILURE:
      return {
        ...state,
        isLoading: false,
        productLoaded: false,
        error: action.payload
      };
    case ProductActionsTypes.LOAD_PRODUCT_BY_IDS_SUCCESS:
      return entity.upsertMany(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    default:
      return state;
  }
};

const { selectEntities, selectAll } = entity.getSelectors();

export const selectProductEntities = selectEntities;
export const selectAllProducts = selectAll;
