const SvgWarehouse = props => (
  <svg width='24' height='24' viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.6585 7.93979C23.1776 8.12853 23.7513 7.86078 23.9401 7.34174C24.1288 6.82271 23.861 6.24895 23.342 6.06021L12.342 2.06021C12.1213 1.97993 11.8793 1.97993 11.6585 2.06021L0.658529 6.06021C0.139496 6.24895 -0.128261 6.82271 0.0604788 7.34174C0.249218 7.86078 0.822982 8.12853 1.34202 7.93979L2.00027 7.70043V20C2.00027 21.1046 2.8957 22 4.00027 22H7.00027H11.0003H13.0003H17.0003H20.0003C21.1048 22 22.0003 21.1046 22.0003 20V7.70043L22.6585 7.93979ZM4.00027 20V6.97315L12.0003 4.06406L20.0003 6.97315V20H19.0003V16C19.0003 14.8954 18.1048 14 17.0003 14H16.0003V10C16.0003 8.89543 15.1048 8 14.0003 8H10.0003C8.8957 8 8.00027 8.89543 8.00027 10V14H7.00027C5.8957 14 5.00027 14.8954 5.00027 16V20H4.00027ZM10.0003 14H11.0003H13.0003H14.0003V10H10.0003V14ZM10.0003 16H7.00027V20H11.0003V16H10.0003ZM13.0003 16V20H17.0003V16H14.0003H13.0003Z'
    />
  </svg>
);

export default SvgWarehouse;
