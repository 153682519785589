import { COLOR_OPTIONS } from 'config/style';

interface CropCherryProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropCherry = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropCherryProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.6551 9.22153C10.4301 9.22153 10.2125 9.25406 10.0063 9.31462L8.09424 5.04386H9.83618C10.8647 5.04386 11.6985 4.19432 11.6985 3.14635V2H9.51668C8.48817 2 7.65441 2.84954 7.65441 3.8975V4.22261C7.65441 3.47185 7.0571 2.86323 6.32027 2.86323H4.65943V3.68446C4.65943 4.43521 5.25673 5.04383 5.99357 5.04383H7.33573L5.99366 8C5.78754 7.93944 5.56989 7.90691 5.34488 7.90691C4.0519 7.90691 3 8.97871 3 10.2962C3 11.6136 4.0519 12.6854 5.34488 12.6854C6.63784 12.6854 7.68974 11.6136 7.68974 10.2962C7.68974 9.45796 7.26385 8.7193 6.62108 8.29273L7.715 5.89091L9.37892 9.60736C8.73614 10.0339 8.31025 10.7726 8.31025 11.6108C8.31025 12.9282 9.36215 14 10.6551 14C11.9481 14 13 12.9282 13 11.6108C13 10.2934 11.9481 9.22153 10.6551 9.22153Z'
      fill={color}
    />
  </svg>
);

export default CropCherry;
