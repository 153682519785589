import axios from 'axios';
import axiosObservable from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import type { GenerateShapefileRequest, LoadHeatmapsRequest } from './heatmap.models';

const protectorApiUrl = PROTECTOR_API_URL;
const heatmapUrl = `${protectorApiUrl}/v1/heatmaps/request`;

const previewHeatmapUrl = `${protectorApiUrl}/v1/heatmaps/export.geojson`;
const exportHeatmapUrl = `${protectorApiUrl}/v1/heatmaps/export.shp.zip`;
const generateShapefileUrl = `${protectorApiUrl}/v1/heatmaps/generate-shapefile`;

export const getHeatmaps = (request: Partial<LoadHeatmapsRequest>) => {
  return axiosObservable.post<{ url: string }>(`${heatmapUrl}`, request).pipe(catchError(() => of({ data: { url: undefined } })));
};

export const getHeatmapGeoJson = (request: Partial<LoadHeatmapsRequest>) => {
  return axiosObservable.post<any>(`${previewHeatmapUrl}`, request);
};

export const getHeatmapExport = (request: Partial<LoadHeatmapsRequest>) => {
  return axiosObservable.post<any>(`${exportHeatmapUrl}`, request, { responseType: 'arraybuffer' });
};

export const getHeatmapShapefile = (request: GenerateShapefileRequest) => {
  return axiosObservable.post<any>(`${generateShapefileUrl}`, request, { responseType: 'arraybuffer' });
};

export interface Heatmap {
  aoi: string | null;
  color_scale: string;
  extra_stats: null | string;
  sampling_points: null | number;
  type: string;
  url: string;
  version: string;
}

export interface HeatMapResponse {
  indicator?: string;
  area_id?: string;
  date?: string;
  url: string;
}

export const getHeatMap = async (request: Partial<LoadHeatmapsRequest>): Promise<HeatMapResponse | null> => {
  const response = await axios.post<Heatmap>(`${heatmapUrl}`, request);
  return response?.data?.url
    ? {
        indicator: request?.indicators?.[0],
        area_id: request?.area_id,
        date: request.days?.[0],
        url: response.data.url
      }
    : null;
};
