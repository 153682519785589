const SvgWeatherFog = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M21.5 9.2H21.4H2.7H2.6C2 9.2 1.5 9.7 1.5 10.3C1.5 10.9 2 11.4 2.6 11.4H2.7H21.4H21.5C22.1 11.4 22.6 10.9 22.6 10.3C22.5 9.7 22 9.2 21.5 9.2Z'
      fill='#BDC4D1'
    />
    <path
      d='M21.5 13.6H21.4H2.7H2.6C2 13.6 1.5 14.1 1.5 14.7C1.5 15.3 2 15.8 2.6 15.8H2.7H21.4H21.5C22.1 15.8 22.6 15.3 22.6 14.7C22.5 14.1 22 13.6 21.5 13.6Z'
      fill='#BDC4D1'
    />
  </svg>
);

export default SvgWeatherFog;
