import axios from 'axios-observable';
import { CHUNK_PAGE, CORE_SERVICES_API_URL } from 'config/constants';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import type { Nullable } from '../../core/core.models';
import { getObservables } from '../../core/rxjs-utils';
import type { UUID, V4Page } from '../../core/utils/basic.models';
import type { FieldSeason } from '../property/property.models';
import type { Field, SeasonField } from '../region/region.models';

const fieldsByIdsUrl = (length: number, geometryDate: Nullable<string> = null, includeGeometry = true) =>
  `${CORE_SERVICES_API_URL}/v2/fields/ids?page=0&include_extended=true&size=${length}${includeGeometry ? '&attributes=geometry' : ''}${
    geometryDate ? `&reference_date=${geometryDate}` : ''
  }`;

const buildFieldByIdsRequest = (ids: UUID[]) => ({ field_ids: ids });

export const getFieldsByIds = (ids: UUID[], geometryDate: Nullable<string> = null, includeGeometry = true) => {
  return axios
    .post<V4Page<Field>>(fieldsByIdsUrl(ids.length, geometryDate, includeGeometry), buildFieldByIdsRequest(ids), {
      headers: { 'client-id': 'strix-ui' }
    })
    .pipe(concatMap(r => of(r.data)));
};

export type FieldIdsByEndDateInSeasonField = Record<string, string[]>;

export const getAllFieldsByIds = (
  ids: UUID[],
  useAsync = true,
  geometryDate: Nullable<string> = null,
  includeGeometry = true,
  fieldIdsByEndDateInSeasonField?: FieldIdsByEndDateInSeasonField
): Observable<Field[]> => {
  let observables;

  const endDates = Object.keys(fieldIdsByEndDateInSeasonField || {});
  const splitRequestByEndDate = fieldIdsByEndDateInSeasonField && endDates.length;
  if (splitRequestByEndDate) {
    observables = endDates
      .map(endDate => {
        const fieldIds = fieldIdsByEndDateInSeasonField[endDate];
        return getFieldsByIds(fieldIds, endDate, includeGeometry).pipe(concatMap(p => of(p.content)));
      })
      .flat();
  }

  if (includeGeometry && !splitRequestByEndDate) {
    const totalChunkedPages = Math.floor(ids.length / CHUNK_PAGE + 1);
    const pages = [...Array(totalChunkedPages).keys()];

    observables = pages.map(page =>
      getFieldsByIds(ids.slice(page * CHUNK_PAGE, (page + 1) * CHUNK_PAGE), geometryDate, includeGeometry).pipe(
        concatMap(p => of(p.content))
      )
    );
  }

  if (!includeGeometry && !splitRequestByEndDate) {
    observables = [getFieldsByIds(ids, geometryDate, includeGeometry).pipe(concatMap(p => of(p.content)))];
  }

  return getObservables(observables, useAsync);
};

export const mapSeasonFieldToFieldSeason = (seasonField: SeasonField): FieldSeason => {
  return {
    id: seasonField.id,
    areaId: seasonField.id,
    varieties: seasonField.varieties_ids,
    seasonId: seasonField.season_id
  };
};
