import { AppsPermissionFailure, AppsPermissionSuccess, CoreActionsTypes } from 'core/core.actions';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getAppPermission } from './apps.service';

export const handleLoadAppsPermissions = action$ =>
  action$.pipe(
    ofType(CoreActionsTypes.LOAD_APPS_PERMISSIONS),
    mergeMap(() =>
      getAppPermission().pipe(
        map(response => response.data.content),
        map(permissions => AppsPermissionSuccess(permissions))
      )
    ),
    catchError(err => of(AppsPermissionFailure(err)))
  );
