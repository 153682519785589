const SvgDownloadGrayGlyph = ({ fill, width, height }) => (
  <svg width={width} height={height} viewBox={`5 5 ${width - 10} ${height - 10}`}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      fill={fill}
      d='M16.5 14.25V16.5H7.5V14.25H6V16.5C6 17.325 6.675 18 7.5 18H16.5C17.325 18 18 17.325 18 16.5V14.25H16.5ZM15.75 11.25L14.6925 10.1925L12.75 12.1275V6H11.25V12.1275L9.3075 10.1925L8.25 11.25L12 15L15.75 11.25Z'
    />
  </svg>
);

export default SvgDownloadGrayGlyph;
