import { Checkbox } from 'antd';
import styled from 'styled-components';

export const StyledCheckboxGroup = styled.div`
  display: grid;
  grid-row-gap: 12px;
`;

export const StyledCheckbox = styled(Checkbox)<{ selectBackgroundColor?: string }>`
  align-items: flex-end;

  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${({ selectBackgroundColor }) => selectBackgroundColor ?? '#14803C'};

    border-color: ${({ selectBackgroundColor }) => selectBackgroundColor ?? '#14803C'};
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background: #f3f4f6;
  }
`;

export const StyledContent = styled.div<{ height?: string; isEmpty: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  max-height: ${({ height }) => height ?? '432px'};
  overflow: auto;
  justify-content: ${({ isEmpty }) => (isEmpty ? 'center' : 'flex-start')};
`;

export const StyledCounter = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;
