const SvgGridOutline = props => (
  <svg width='24' height='24' viewBox='0 0 24 24' {...props} xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 3H11V13H3V3ZM13 3H21V9H13V3ZM9 11V5H5V11H9ZM19 19V13H15V19H19ZM9 17V19H5V17H9ZM19 5V7H15V5H19ZM21 11H13V21H21V11ZM3 15H11V21H3V15Z'
      fill='#696F88'
    />
  </svg>
);

export default SvgGridOutline;
