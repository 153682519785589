import type { Action, UUID } from 'core/utils/basic.models';
import type {
  LoadMethodologiesType,
  LoadPhenomenonByMethodologySuccessType,
  MethodologyResponse,
  MethodolyArea
} from './phenomenon.models';

export const PhenomenonActionsTypes = {
  LOAD_PHENOMENON: '[Phenomenon] Load Phenomenon',
  LOAD_PHENOMENON_SUCCESS: '[Phenomenon] Load Phenomenon Success',
  LOAD_PHENOMENON_FAILURE: '[Phenomenon] Load Phenomenon Failure',

  LOAD_PHENOMENON_BY_METHODOLOGY: '[Phenomenon] Load Phenomenon by Methodology',
  LOAD_PHENOMENON_BY_METHODOLOGY_SUCCESS: '[Phenomenon] Load Phenomenon by Methodology Success',
  LOAD_PHENOMENON_BY_METHODOLOGY_FAILURE: '[Phenomenon] Load Phenomenon by Methodology Failure',

  LOAD_METHODOLOGIES: '[Phenomenon] Load Methodologies',
  LOAD_METHODOLOGIES_SUCCESS: '[Phenomenon] Load Methodologies Success',
  LOAD_METHODOLOGIES_FAILURE: '[Phenomenon] Load Methodologies Failure',

  LOAD_METHODOLOGY_BY_AREA: '[Phenomenon] Load Methodology By Area',
  LOAD_METHODOLOGY_BY_AREA_SUCCESS: '[Phenomenon] Load Methodology By Area Success',
  LOAD_METHODOLOGY_BY_AREA_FAILURE: '[Phenomenon] Load Methodology By Area Failure',

  LOAD_METHODOLOGY: '[Phenomenon] Load Methodology',
  LOAD_METHODOLOGY_SUCCESS: '[Phenomenon] Load Methodology Success',
  LOAD_METHODOLOGY_FAILURE: '[Phenomenon] Load Methodology Failure',

  LOAD_PHENOMENON_BY_COMPANY: '[Phenomenon] Load Phenomenon by Company',
  LOAD_PHENOMENON_BY_COMPANY_SUCCESS: '[Phenomenon] Load Phenomenon by Company Success',
  LOAD_PHENOMENON_BY_COMPANY_FAILURE: '[Phenomenon] Load Phenomenon by Company Failure'
};

export const LoadMethodologies: LoadMethodologiesType = propertyId => {
  return {
    type: PhenomenonActionsTypes.LOAD_METHODOLOGIES,
    payload: propertyId
  };
};

export const LoadPhenomenonByMethodologyFailure = err => {
  return {
    type: PhenomenonActionsTypes.LOAD_PHENOMENON_BY_METHODOLOGY_FAILURE,
    payload: err
  };
};

export const LoadPhenomenonByMethodology: any = methodologyIds => {
  return {
    type: PhenomenonActionsTypes.LOAD_PHENOMENON_BY_METHODOLOGY,
    payload: methodologyIds
  };
};

export const LoadPhenomenonByMethodologySuccess: LoadPhenomenonByMethodologySuccessType = phenomena => {
  return {
    type: PhenomenonActionsTypes.LOAD_PHENOMENON_BY_METHODOLOGY_SUCCESS,
    payload: phenomena
  };
};

export const LoadMethodolyByArea = (id: UUID) => {
  return {
    type: PhenomenonActionsTypes.LOAD_METHODOLOGY_BY_AREA,
    payload: id
  };
};

export const LoadMethodolyByAreaSuccess = (response: MethodolyArea[]) => {
  return {
    type: PhenomenonActionsTypes.LOAD_METHODOLOGY_BY_AREA_SUCCESS,
    payload: response
  };
};

export const LoadMethodolyByAreaFailure = (error: string): Action<string> => {
  return {
    type: PhenomenonActionsTypes.LOAD_METHODOLOGY_BY_AREA_FAILURE,
    payload: error
  };
};

export const LoadMethodoly = (id: UUID) => {
  return {
    type: PhenomenonActionsTypes.LOAD_METHODOLOGY,
    payload: id
  };
};

export const LoadMethodolySuccess = (response: MethodologyResponse) => {
  return {
    type: PhenomenonActionsTypes.LOAD_METHODOLOGY_SUCCESS,
    payload: response
  };
};

export const LoadMethodolyFailure = (error: string): Action<string> => {
  return {
    type: PhenomenonActionsTypes.LOAD_METHODOLOGY_FAILURE,
    payload: error
  };
};
