import classNames from 'classnames';
import STTypo, { STTypoModels } from 'core/shared/typo';
import moment from 'moment';
import { EventType } from 'pages/timeline/timeline.models';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { selectMarkerImg } from './sd-popup-monitoring.functions';
import type { Popup } from './sd-popup.models';

export interface SdPopupClusterItemProps {
  marker: Popup<EventType.MONITORING> | Popup<EventType.PHENOLOGY> | Popup<EventType.ANNOTATION>;
  eventType: EventType;
  enableMultiplePhotos?: boolean;
}

export const SdPopupClusterItem: React.FC<SdPopupClusterItemProps> = ({ marker, eventType, enableMultiplePhotos }) => {
  const [t] = useTranslation();

  const customStyle = classNames({
    'st-popup-cluster__text': eventType !== EventType.PHENOLOGY,
    'st-popup-cluster__text-phenology': eventType === EventType.PHENOLOGY
  });

  return (
    <>
      <div className='st-popup-cluster__img'>
        <img data-testid='st-popup-cluster-img' src={selectMarkerImg(marker, enableMultiplePhotos)} alt='' />
      </div>
      <div data-testid='st-popup-cluster-text-container' className={customStyle}>
        <STTypo type={STTypoModels.TypeOptions.P1} fontWeight={500}>
          {t(`general.map.clustered.${eventType.toLowerCase()}`)}
        </STTypo>
        <br />
        <STTypo type={STTypoModels.TypeOptions.C1}>
          {moment(marker.popupProps.date, 'YYYY-MM-DDTHH:mm').format('DD MMM, YY • HH:mm')}
        </STTypo>
      </div>
    </>
  );
};
