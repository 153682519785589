import axios from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import type { IndicatorDTO } from 'entities/phenomenon/phenomenon.models';
import moment from 'moment';
import type { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import IndicatorsUtil from './indicators-util';
import type { AllIndicators, FlagsDaysIndicatorsEnum, PayloadDaysIndicators, ResultIndicators } from './indicators.models';

const protectorApiUrl = PROTECTOR_API_URL;
const indicatorsUrl = `${protectorApiUrl}/v1/timeline/days-indicators`;
const indicatorsByIdsUrl = `${protectorApiUrl}/api/v1/indicators/ids`;

const getDaysIndicatorsFlags = (value?: FlagsDaysIndicatorsEnum) => {
  if (value) {
    return `&index_option=${value}`;
  }
  return '';
};

export const getDaysIndicators = (
  request: PayloadDaysIndicators,
  flagSplitRequestDaysIndicators: boolean
): Observable<ResultIndicators> => {
  const { end_date, propertyId, season_ids, start_date, flags } = request;
  const timeIntervals = IndicatorsUtil.divideDatesIntoPeriods(moment(start_date), moment(end_date));
  const requests$: Observable<AllIndicators[]>[] = [];
  const seasonIds = season_ids.join(',');

  if (!flagSplitRequestDaysIndicators) {
    const params = `uuid=${propertyId}&start=${start_date}&end=${end_date}&season_ids=${seasonIds}`;

    return axios
      .get(`${indicatorsUrl}?${params}${getDaysIndicatorsFlags(flags)}`)
      .pipe(map(response => ({ propertyId, indicator: response.data })));
  }

  timeIntervals.forEach(timeInterval => {
    const url = `${indicatorsUrl}?season_ids=${seasonIds}&uuid=${propertyId}${getDaysIndicatorsFlags(
      flags
    )}&start=${timeInterval.startDate.format('YYYY-MM-DD')}&end=${timeInterval.endDate.format('YYYY-MM-DD')}`;

    requests$.push(axios.get<AllIndicators[]>(url).pipe(map(response => response.data)));
  });

  return forkJoin(requests$).pipe(
    map(response => IndicatorsUtil.mergeAllIndicators(response.flatMap(item => item))),
    map(response => ({
      propertyId,
      indicator: IndicatorsUtil.sortAllIndicatorsIndividualDays(response)
    }))
  );
};

export const getIndicatorsByIds = (ids: string[]) => {
  return axios.post<IndicatorDTO[]>(indicatorsByIdsUrl, { ids }).pipe(map(response => response.data));
};
