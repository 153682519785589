import axios from 'axios';
import axiosObservable from 'axios-observable';
import { CORE_SERVICES_API_URL } from 'config/constants';
import { queryClient } from 'config/react-query';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SEASON_FIELDS, type SeasonField, type SeasonFieldPageable, type SeasonFieldServiceParams } from './season-field.models';

const coreServicesApiUrl = CORE_SERVICES_API_URL;

export const getSeasonFieldFromPropertyBySeasonIds = async ({
  fieldIds,
  propertyIds,
  seasonIds,
  include_deleted = false
}: SeasonFieldServiceParams) => {
  const response = await axios.post<SeasonFieldPageable>(`${coreServicesApiUrl}/v2/season-fields/list`, {
    property_ids: propertyIds ?? [],
    season_ids: seasonIds ?? [],
    field_ids: fieldIds ?? [],
    include_deleted
  });
  return response?.data?.content;
};

/**
 * TODO: The parameter *update* is temporally and is used to disable the use of cache
 * @Link https://digitial-product-engineering.atlassian.net/browse/P40-35281
 */
export const getSeasonFieldFromPropertyBySeasonIdsObservable = ({ fieldIds, propertyIds, seasonIds, update }: SeasonFieldServiceParams) => {
  if (!update) {
    const data = queryClient.getQueryData<SeasonField[] | undefined>([SEASON_FIELDS, propertyIds, seasonIds]);

    if (data) {
      return of(data);
    }
  }

  return axiosObservable
    .post<SeasonFieldPageable>(`${coreServicesApiUrl}/v2/season-fields/list`, {
      property_ids: propertyIds ?? [],
      season_ids: seasonIds ?? [],
      field_ids: fieldIds ?? []
    })
    .pipe(
      map(response => {
        queryClient.setQueryData([SEASON_FIELDS, propertyIds, seasonIds], response.data.content);

        return response.data.content;
      })
    );
};
