const SvgRegionalRisk = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM4 11.9987C4 11.3888 4.08 10.7887 4.21 10.2188L8.99 14.9988V15.9988C8.99 17.0988 9.89 17.9988 10.99 17.9988V19.9287C7.06 19.4287 4 16.0688 4 11.9987ZM15.99 16C16.89 16 17.63 16.59 17.89 17.4C19.19 15.98 20 14.08 20 12C20 8.65 17.92 5.77 14.99 4.59V5C14.99 6.1 14.09 7 12.99 7H10.99V9C10.99 9.55 10.54 10 9.99 10H7.99V12H13.99C14.54 12 14.99 12.45 14.99 13V16H15.99Z'
    />
  </svg>
);

export default SvgRegionalRisk;
