const SvgTableChart = props => (
  <svg width={props.size || 24} height={props.size || 24} viewBox='0 0 20 20' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.2497 2.5H3.74967C2.83301 2.5 2.08301 3.25 2.08301 4.16667V15.8333C2.08301 16.75 2.83301 17.5 3.74967 17.5H16.2497C17.1663 17.5 17.9163 16.75 17.9163 15.8333V4.16667C17.9163 3.25 17.1663 2.5 16.2497 2.5ZM16.2497 4.16667V6.66667H3.74967V4.16667H16.2497ZM7.91634 15.8333H12.083V8.33333H7.91634V15.8333ZM3.74967 8.33333H6.24967V15.8333H3.74967V8.33333ZM13.7497 8.33333V15.8333H16.2497V8.33333H13.7497Z'
    />
  </svg>
);

export default SvgTableChart;
