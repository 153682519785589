import { COLOR_OPTIONS } from 'config/style';

interface CropOnionProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropOnion = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropOnionProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.64885 4.86202C5.74567 4.06576 6.7665 3.32465 6.7665 2H9.2335C9.2335 3.32465 10.2543 4.06576 11.3512 4.86202C12.6244 5.78636 14 6.78502 14 8.85714C14 11.2138 12.1391 13.0456 9.80662 13.1391C9.92948 13.3921 9.99997 13.6862 10.0003 14H9.42885C9.42777 13.6474 9.27692 13.328 9.03947 13.1429H8.84308C8.8519 13.1993 8.8567 13.2567 8.85742 13.3143V14H8.28599V13.3143C8.28599 13.2518 8.27208 13.1933 8.24777 13.1429H7.75279C7.72848 13.1933 7.71456 13.2518 7.71456 13.3143V14H7.14314V13.3143C7.14386 13.2567 7.14866 13.1993 7.15748 13.1429H6.96108C6.72364 13.328 6.57279 13.6474 6.57171 14H6.00028C6.00059 13.6862 6.07108 13.3921 6.19393 13.1391C3.86117 13.0459 2 11.214 2 8.85714C2 6.78502 3.37562 5.78636 4.64885 4.86202Z'
      fill={color}
    />
  </svg>
);

export default CropOnion;
