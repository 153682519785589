import LoginChecker from 'authentication/login-checker.component';
import { getIsLogged } from 'core/services/auth/auth.selectors';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';

const ProtectedRoute = () => {
  const isLogged = useSelector(getIsLogged);

  if (!isLogged) return <LoginChecker />;

  return <Outlet />;
};

export default ProtectedRoute;
