const SvgWeatherSnowSunny = props => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M16.291 9.663L15.569 7.961C16.213 7.688 16.628 7.061 16.628 6.367C16.628 5.411 15.85 4.634 14.895 4.634C14.047 4.634 13.313 5.268 13.186 6.103C13.172 6.192 13.166 6.279 13.166 6.368H11.317C11.317 6.188 11.33 6.004 11.358 5.826C11.62 4.095 13.141 2.785 14.895 2.785C16.871 2.785 18.478 4.392 18.478 6.368C18.478 7.808 17.619 9.103 16.291 9.663Z'
      fill='#DFA701'
    />
    <path
      d='M12.0562 4.07539L11.077 3.69078C10.6015 3.50401 10.0646 3.73808 9.87782 4.21359C9.69105 4.6891 9.92512 5.22599 10.4006 5.41276L11.3798 5.79737C11.8553 5.98414 12.3922 5.75007 12.579 5.27456C12.7658 4.79905 12.5317 4.26216 12.0562 4.07539Z'
      fill='#DFA701'
    />
    <path
      d='M14.3254 2.71872L13.8676 1.77494C13.6441 1.31432 13.0895 1.12208 12.6289 1.34555C12.1683 1.56903 11.976 2.12359 12.1995 2.58421L12.6574 3.528C12.8809 3.98861 13.4354 4.18086 13.8961 3.95738C14.3567 3.73391 14.5489 3.17934 14.3254 2.71872Z'
      fill='#DFA701'
    />
    <path
      d='M16.9192 3.28742L17.1968 2.42186C17.3529 1.93542 17.085 1.4146 16.5986 1.25858C16.1121 1.10257 15.5913 1.37043 15.4353 1.85687L15.1577 2.72242C15.0017 3.20887 15.2695 3.72969 15.756 3.8857C16.2424 4.04172 16.7632 3.77386 16.9192 3.28742Z'
      fill='#DFA701'
    />
    <path
      d='M18.4397 5.41897L19.3497 4.88316C19.7894 4.62423 19.936 4.05785 19.677 3.61811C19.4181 3.17838 18.8517 3.03181 18.412 3.29074L17.502 3.82655C17.0623 4.08548 16.9157 4.65187 17.1747 5.0916C17.4336 5.53134 18 5.67791 18.4397 5.41897Z'
      fill='#DFA701'
    />
    <path
      d='M19.5266 6.45335L18.4985 6.23053C17.9984 6.12216 17.5052 6.43969 17.3968 6.93977C17.2884 7.43985 17.606 7.93309 18.1061 8.04147L19.1342 8.26428C19.6342 8.37266 20.1275 8.05513 20.2359 7.55505C20.3442 7.05497 20.0267 6.56172 19.5266 6.45335Z'
      fill='#DFA701'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.025 8.48801C15.048 6.79701 13.243 5.73401 11.254 5.73401C8.499 5.73401 6.208 7.77301 5.811 10.42C4.2 10.809 3 12.264 3 13.99C3 16.016 4.649 17.666 6.674 17.666L15.838 17.668C18.368 17.668 20.428 15.607 20.428 13.077C20.427 10.606 18.468 8.58701 16.025 8.48801ZM15.838 15.814H6.676C5.67 15.814 4.851 14.996 4.851 13.99C4.851 12.986 5.671 12.166 6.676 12.166H7.6V11.243C7.6 9.229 9.241 7.586 11.256 7.586C12.725 7.586 14.043 8.457 14.621 9.808L14.889 10.434L15.838 10.333C17.349 10.333 18.578 11.564 18.578 13.076C18.578 14.586 17.349 15.814 15.838 15.814Z'
      fill='#828B99'
    />
    <path
      d='M4.62363 19.4561C4.3338 19.1666 3.8642 19.1666 3.57438 19.4561C3.28426 19.746 3.28415 20.2162 3.57413 20.5061L4.024 20.956L3.57265 21.4087C3.28331 21.699 3.28368 22.1687 3.57346 22.4585C3.86366 22.7487 4.33421 22.7486 4.62426 22.4582L5.075 22.007L5.523 22.4569C5.81281 22.7479 6.28377 22.7485 6.57432 22.4582C6.86423 22.1686 6.86482 21.6989 6.57565 21.4085L6.125 20.956L6.57417 20.5064C6.86399 20.2163 6.86364 19.7461 6.5734 19.4564C6.28323 19.1668 5.81322 19.1673 5.52363 19.4575L5.075 19.907L4.62363 19.4561Z'
      fill='#92B0ED'
    />
    <path
      d='M11.2128 19.4562C10.9229 19.1667 10.4533 19.1669 10.1637 19.4568C9.87441 19.7464 9.8744 20.2156 10.1637 20.5053L10.614 20.956L10.1623 21.4096C9.87357 21.6995 9.87382 22.1683 10.1628 22.4578C10.4527 22.7483 10.9234 22.7484 11.2134 22.4581L11.664 22.007L12.1147 22.4582C12.4048 22.7486 12.8753 22.7487 13.1655 22.4585C13.4553 22.1687 13.4557 21.699 13.1663 21.4087L12.715 20.956L13.1649 20.5061C13.4548 20.2162 13.4547 19.746 13.1646 19.4561C12.8748 19.1666 12.4052 19.1666 12.1154 19.4561L11.664 19.907L11.2128 19.4562Z'
      fill='#92B0ED'
    />
    <path
      d='M17.8042 19.4557C17.5146 19.1663 17.0453 19.1662 16.7555 19.4555C16.465 19.7455 16.4651 20.2164 16.7559 20.5062L17.207 20.956L16.7544 21.4086C16.4643 21.6988 16.4644 22.1692 16.7546 22.4591C17.0448 22.749 17.515 22.7488 17.8049 22.4586L18.256 22.007L18.705 22.4574C18.9948 22.7481 19.4655 22.7485 19.7558 22.4582C20.0454 22.1686 20.0458 21.6991 19.7566 21.409L19.305 20.956L19.7551 20.5059C20.045 20.216 20.0449 19.7461 19.7549 19.4564C19.465 19.1668 18.9952 19.167 18.7056 19.4569L18.256 19.907L17.8042 19.4557Z'
      fill='#92B0ED'
    />
  </svg>
);

export default SvgWeatherSnowSunny;
