import { Drawer, Form, Select } from 'antd';
import { SelectMulti } from 'components/select-multi/select-multi';
import { getDiseaseTranslationKey } from 'pages/disease-risk/utils/disease-risk.utils';
import { useTranslation } from 'react-i18next';
import '../report.styles.less';
import { ReportsTypes } from '../reports-types.enum';
import FooterDrawer from './components/footer-drawer.component';
import SelectSeason from './components/season-select.component';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';
import { useReportDiseaseRisk } from './report-disease-risk.bloc';

export interface ReportDiseaseRiskProps {
  callbackDrawer: () => void;
  visible?: boolean;
  icon?: JSX.Element;
}

export const ReportDiseaseRisk = ({ visible, callbackDrawer, icon }: ReportDiseaseRiskProps) => {
  const {
    selectedSeasonId,
    handleSelectSeason,
    availableFields,
    selectedFieldIds,
    handleSelectFields,
    handleSelectAllFields,
    availableDiseases,
    selectedDiseases,
    handleSelectDiseases,
    handleSelectAllDiseases,
    isFieldsDisabled,
    isDiseasesDisabled,
    isSubmitDisabled,
    handleSubmit
  } = useReportDiseaseRisk({
    onSubmitted: callbackDrawer
  });

  const [t] = useTranslation();

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible}
      width={400}
      title={<HeaderTitle icon={icon} type={ReportsTypes.DISEASE_RISK} />}
      onClose={callbackDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='report-disease-risk-drawer'>
        <ExplicativeText type={ReportsTypes.DISEASE_RISK} />
        <div className='st-report-drawer__form' data-testid='report-disease-risk-form'>
          <Form layout='vertical'>
            <Form.Item data-testid='report-disease-risk-season' label={t('report.disease_risk.form.season_label')}>
              <SelectSeason
                selectedSeason={selectedSeasonId}
                setSelectedSeason={handleSelectSeason}
                placeholder={t('modals.timeline_xray_report.placeholder.crop_cycle')}
              />
            </Form.Item>
            <Form.Item data-testid='report-disease-risk-fields' label={t('report.disease_risk.form.fields_label')}>
              <SelectMulti
                onChange={handleSelectFields}
                onSelectAll={handleSelectAllFields}
                value={selectedFieldIds}
                disabled={isFieldsDisabled}
                size='large'
                selectAllLabel={t('report.disease_risk.form.options_select_all')}
                placeholder={t('report.disease_risk.form.fields_placeholder')}>
                {availableFields.map(field => (
                  <Select.Option key={field.id} value={field.id}>
                    {field.name}
                  </Select.Option>
                ))}
              </SelectMulti>
            </Form.Item>
            <Form.Item data-testid='report-disease-risk-diseases' label={t('report.disease_risk.form.diseases_label')}>
              <SelectMulti
                onChange={handleSelectDiseases}
                onSelectAll={handleSelectAllDiseases}
                value={selectedDiseases}
                size='large'
                disabled={isDiseasesDisabled}
                selectAllLabel={t('report.disease_risk.form.options_select_all')}
                placeholder={t('report.disease_risk.form.diseases_placeholder')}>
                {availableDiseases.map(disease => (
                  <Select.Option key={disease} value={disease}>
                    {t(getDiseaseTranslationKey(disease))}
                  </Select.Option>
                ))}
              </SelectMulti>
            </Form.Item>
          </Form>
        </div>
        <FooterDrawer
          onPressConfirm={handleSubmit}
          isLoading={false}
          disableConfirmButton={isSubmitDisabled}
          onPressCancel={callbackDrawer}
        />
      </div>
    </Drawer>
  );
};
