import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { MethodologyActionTypes } from './methodology.actions';
import type { MethodologyEntity, MethodologyState } from './methodology.models';

function selectMethodologyUUID(entity: MethodologyEntity) {
  return entity.id;
}

const entity: EntityAdapter<MethodologyEntity> = createEntityAdapter<MethodologyEntity>({
  selectId: selectMethodologyUUID
});

const initialPage = {
  empty: false,
  first: false,
  last: false,
  number: 0,
  number_of_elements: 0,
  size: 0,
  total_elements: 0,
  total_pages: 0
};

const initialState = entity.getInitialState<MethodologyState>({
  ids: [],
  entities: {},
  page: {
    ...initialPage
  },
  isLoading: false,
  error: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case MethodologyActionTypes.LOAD_METHODOLOGIES_BY_COMPANY:
      return {
        ...state,
        isLoading: true
      };

    case MethodologyActionTypes.LOAD_METHODOLOGIES_BY_COMPANY_SUCCESS:
      return entity.addMany(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });

    case MethodologyActionTypes.LOAD_METHODOLOGIES_BY_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case MethodologyActionTypes.UPDATE_METHODOLOGY:
      return {
        ...state,
        isLoading: true
      };

    case MethodologyActionTypes.UPDATE_METHODOLOGY_SUCCESS:
      return entity.updateOne(
        {
          id: action.payload.id,
          changes: {
            ...action.payload
          }
        },
        {
          ...state,
          isLoading: false,
          error: null
        }
      );

    case MethodologyActionTypes.UPDATE_METHODOLOGY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
