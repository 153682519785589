import { Select } from 'antd';
import type { SelectProps, SelectValue } from 'antd/lib/select';
import type React from 'react';
import { useCallback, type ReactElement, type ReactNode } from 'react';
import SelectMultiDDRender from './select-multi-dd-render/select-multi-dd-render';

export type SelectMultiProps<T = SelectValue> = React.PropsWithChildren<{
  value?: T;
  disabled?: boolean;
  placeholder?: string;
  selectAllLabel?: string;
  size?: SelectProps['size'];
  showArrow?: boolean;
  className?: string;
  onChange?: (value: T, option: ReactElement<T> | ReactElement<T>[]) => void;
  onSelectAll?: () => void;
}>;

export const SelectMulti = <T extends SelectValue>({
  value,
  disabled,
  placeholder,
  selectAllLabel,
  size = 'large',
  showArrow = false,
  className,
  onChange,
  onSelectAll,
  children
}: SelectMultiProps<T>) => {
  const renderDropdown = useCallback(
    (menu: ReactNode) => <SelectMultiDDRender onSelectAll={onSelectAll} selectAllLabel={selectAllLabel} menu={menu} />,
    [onSelectAll, selectAllLabel]
  );

  return (
    // @ts-expect-error Generic compatibility issue with Ant Design Select
    <Select
      onChange={onChange}
      value={value}
      disabled={disabled}
      mode='multiple'
      size={size}
      showArrow={showArrow}
      placeholder={placeholder}
      className={className}
      dropdownRender={renderDropdown}>
      {children}
    </Select>
  );
};
