import { COLOR_OPTIONS } from 'config/style';

interface CropWatermelonProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropWatermelon = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropWatermelonProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.3273 1C12.4361 1.00003 12.5405 1.04326 12.6174 1.1202C14.1538 2.6573 15 4.69884 15 6.86871C15 11.3601 11.345 15.0081 6.85365 15C4.68822 14.9961 2.65256 14.1503 1.12008 12.6173C0.959952 12.4571 0.959979 12.1975 1.12013 12.0373L2.07295 11.0845L2.36329 11.3748C3.56658 12.5794 5.16665 13.2427 6.86874 13.2427C10.3834 13.2427 13.2427 10.3834 13.2427 6.86876C13.2427 5.16645 12.579 3.56607 11.3741 2.36257L11.0845 2.07297L12.0373 1.12015C12.1142 1.04323 12.2186 1 12.3273 1Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.86872 12.4224C9.93103 12.4224 12.4224 9.93104 12.4224 6.86873C12.4224 5.38572 11.8441 3.99143 10.7942 2.94275L10.5044 2.65298L2.65299 10.5044L2.94347 10.7949C3.99194 11.8445 5.38592 12.4224 6.86872 12.4224ZM10.8722 8.09551C11.0324 8.25569 11.0324 8.51538 10.8722 8.67556C10.712 8.83576 10.4523 8.83571 10.2921 8.67556L9.8528 8.23625C9.69262 8.07607 9.69262 7.81639 9.8528 7.65621C10.0129 7.49603 10.2727 7.49603 10.4328 7.65621L10.8722 8.09551ZM10.2132 5.23992C10.053 5.07974 9.79329 5.07974 9.63314 5.23992C9.47294 5.4001 9.47294 5.65981 9.63314 5.81996L10.0725 6.2593C10.2326 6.41945 10.4923 6.4195 10.6525 6.2593C10.8127 6.09912 10.8127 5.83943 10.6525 5.67925L10.2132 5.23992ZM8.67554 10.8722C8.51536 11.0324 8.25562 11.0323 8.09549 10.8722L7.65619 10.4329C7.49601 10.2727 7.49601 10.013 7.65619 9.85284C7.81634 9.69266 8.07605 9.69266 8.23623 9.85284L8.67554 10.2921C8.83574 10.4523 8.83574 10.712 8.67554 10.8722ZM8.01658 7.43653C7.85643 7.27637 7.59672 7.27637 7.43654 7.43653C7.27633 7.59671 7.27633 7.85642 7.43654 8.01657L7.87584 8.4559C8.03597 8.61603 8.29565 8.61611 8.45589 8.4559C8.61607 8.29572 8.61607 8.03604 8.45589 7.87586L8.01658 7.43653ZM5.23993 9.63316C5.40008 9.47298 5.6598 9.47298 5.81997 9.63316L6.25928 10.0725C6.41946 10.2326 6.41946 10.4923 6.25928 10.6525C6.0991 10.8127 5.83936 10.8127 5.67924 10.6525L5.23993 10.2132C5.07973 10.053 5.07973 9.79334 5.23993 9.63316Z'
      fill={color}
    />
  </svg>
);

export default CropWatermelon;
