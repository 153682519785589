import { createSelector } from 'reselect';
import { getLicensingStatus, getSystemFlags } from 'core/core.selectors';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { validatePlansAndEntitlements } from 'core/utils/functions';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import { isAnyWheatCrop, isAnyBarleyCrop } from 'entities/crop/crop.domain';

export const showDiseaseRiskReport = createSelector(
  getLicensingStatus,
  getSystemFlags,
  getSelectedProperty,
  (licensingStatus, systemFlags, property) => {
    if (!systemFlags || !property) return false;
    if (!validatePlansAndEntitlements(licensingStatus, null, [Entitlements.DISEASE_RISK_REPORT])) return false;

    return property.seasons.some(season => isAnyWheatCrop(season.crop) || isAnyBarleyCrop(season.crop));
  }
);
