import type { ReactNode } from 'react';

export const parseMaxlengthString = (data: string | ReactNode, maxlength: number, textIsBig: boolean): string | ReactNode => {
  if (typeof data === 'string' && textIsBig) {
    // 3 is "..." length
    const firstPart = (2 / 3) * maxlength;
    const lastPart = maxlength - firstPart;
    return `${data.substring(0, firstPart)}...${data.substring(data.length - lastPart, data.length)}`;
  }
  return data;
};
