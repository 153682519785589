import { COLOR_OPTIONS } from 'config/style';

interface CropBeanProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropBean = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropBeanProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.1434 1.04682C9.76873 0.84483 8.36803 1.30414 7.39658 2.27559C7.15564 2.51653 6.94903 2.78058 6.77702 3.06276C7.04766 3.19653 7.30194 3.37543 7.52738 3.60087C8.62495 4.69843 8.63716 6.47205 7.5546 7.5546C6.47205 8.63716 4.69843 8.62495 3.60087 7.52738C3.37543 7.30194 3.19656 7.04768 3.06277 6.77701C2.78055 6.949 2.51653 7.15563 2.27562 7.39655C1.3042 8.36797 0.844807 9.76869 1.04682 11.1434C1.21507 12.2884 1.84039 13.3075 2.8076 14.0129C3.71312 14.6733 4.85966 15.0235 6.03603 14.9988C8.10806 14.9554 10.2582 13.9224 12.0903 12.0903C13.9224 10.2582 14.9554 8.10806 14.9988 6.03609C15.0235 4.85971 14.6734 3.71311 14.0129 2.8076C13.3075 1.84042 12.2884 1.21507 11.1434 1.04682ZM4.47346 6.65485C5.08319 7.26459 6.06852 7.27137 6.66995 6.66994C7.27137 6.06852 7.26459 5.08319 6.65483 4.47342C6.54699 4.36559 6.42724 4.27702 6.30013 4.20692C6.25314 4.39674 6.21852 4.5909 6.19824 4.78917C6.14131 5.33384 6.00372 5.56686 5.78529 5.78529C5.56686 6.00372 5.33385 6.14131 4.79043 6.1981C4.59173 6.21846 4.39716 6.25314 4.20701 6.30021C4.27708 6.4273 4.36565 6.54704 4.47346 6.65485Z'
      fill={color}
    />
  </svg>
);

export default CropBean;
