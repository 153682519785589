import { COLOR_OPTIONS } from 'config/style';

interface CropAppleProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropApple = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropAppleProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.8 15C7.39676 15 7.35477 14.7501 8 14.7221C8.64523 14.694 8.79805 15.0374 9.30223 15C9.78257 14.9644 11.5 14.5 13 12C13.569 11.0516 14 9.90428 14 8.41359C14 7.16182 13.6156 5.56073 11.6861 4.9038C10.5 4.49999 9.5 4.49999 8.39564 5.00138C8.3898 4.85625 8.38115 4.7112 8.3697 4.56631C8.72814 4.29962 9.44684 3.86486 10.5961 3.59132C12.3477 3.17437 13.0202 1.13267 13.0202 1.13267C13.0202 1.13267 10.886 0.684265 9.57519 1.43619C8.82967 1.86385 8.43427 2.77907 8.23181 3.48898C8.14126 2.97854 8.01524 2.4739 7.85411 1.98021C7.82543 1.83974 7.79226 1.71934 7.75384 1.62939C7.48074 0.989733 6.69716 1.30078 6.69716 1.62939C6.69716 1.84759 6.82454 1.95764 6.9658 2.07518C7.14337 2.59732 7.28098 3.13093 7.37795 3.67222C7.391 3.74507 7.40298 3.81809 7.41453 3.89117C7.41947 3.92249 7.42414 3.95384 7.4288 3.98521C7.42954 3.99028 7.43014 3.9946 7.4307 3.99846C7.43215 4.00922 7.43365 4.01999 7.43507 4.03077C7.4547 4.17739 7.47062 4.32445 7.48416 4.47169C7.50013 4.64524 7.51127 4.81918 7.51832 4.99328C6.5 4.49999 5.71129 4.26432 4.31285 4.8064C2.71628 5.42529 2 7.17235 2 8.41362C2 9.83035 2.4 11 3 12C4.13807 13.8968 6.34858 15 6.8 15Z'
      fill={color}
    />
  </svg>
);

export default CropApple;
