import { COLOR_OPTIONS } from 'config/style';

interface CropBlueberryProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropBlueberry = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropBlueberryProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.481 5.34378C6.86064 5.12483 6.19531 5.00163 5.49998 5.00163C5.19177 5.00163 4.89011 5.02812 4.59484 5.07407C4.48314 5.09146 4.36957 5.04835 4.30318 4.95681C3.66521 4.07706 3.26458 3.1531 3.07196 2.3488C3.02861 2.16779 3.17091 1.99961 3.3568 2.00669C5.80114 2.09977 7.22982 3.15947 8.02906 4.80867C8.09207 4.93871 8.04624 5.09352 7.93051 5.18C7.88107 5.21695 7.83223 5.25466 7.78395 5.29306C7.69851 5.36102 7.58394 5.38011 7.481 5.34378Z'
      fill={color}
    />
    <path
      d='M12.3996 5.12715C12.3644 5.21064 12.2765 5.25811 12.1867 5.24624C12.0011 5.22172 11.8134 5.20604 11.6224 5.20607C10.874 5.20607 10.1606 5.38535 9.51007 5.70986C9.33915 5.79512 9.1395 5.62974 9.21818 5.45563C9.8761 3.99973 10.9759 3.07679 12.7712 3.00505C12.8921 3.00022 12.9859 3.10717 12.9657 3.22654C12.8666 3.81171 12.6781 4.46754 12.3996 5.12715Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 14.0065C8.20914 14.0065 10 12.2146 10 10.0043C10 7.79399 8.20914 6.00216 6 6.00216C3.79086 6.00216 2 7.79399 2 10.0043C2 12.2146 3.79086 14.0065 6 14.0065ZM5 9.00378C5.55228 9.00378 6 8.55582 6 8.00324C6 7.45065 5.55228 7.0027 5 7.0027C4.44772 7.0027 4 7.45065 4 8.00324C4 8.55582 4.44772 9.00378 5 9.00378Z'
      fill={color}
    />
    <path
      d='M11.3978 10.9899C11.2215 10.9694 11.1288 10.7832 11.1773 10.6123C11.2704 10.2842 11.3204 9.93608 11.3204 9.57551C11.3204 8.536 10.9044 7.60016 10.2402 6.94719C10.116 6.82502 10.1007 6.62241 10.2359 6.51252C10.6299 6.19229 11.1218 6.00216 11.6554 6.00216C12.9503 6.00216 14 7.12205 14 8.50351C14 9.88496 12.9503 11.0049 11.6554 11.0049C11.5683 11.0049 11.4824 10.9998 11.3978 10.9899Z'
      fill={color}
    />
  </svg>
);

export default CropBlueberry;
