import { Button, Modal, Radio, Skeleton } from 'antd';
import type { RadioChangeEvent } from 'antdV5';
import { usePreviousValue } from 'beautiful-react-hooks';
import { COLOR_OPTIONS } from 'config/style';
import type { Dictionary } from 'config/types';
import { SetCurrentSeasons } from 'core/core.actions';
import { CropTypesEnum } from 'core/core.models';
import STSeasonList from 'core/shared/season-list/season-list.component';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { compareStrings } from 'core/utils/strings';
import type { Company } from 'entities/company/company.models';
import { getSelectedCompany } from 'entities/company/company.reducer';
import type { Property } from 'entities/property/property.models';
import { getSelectedProperty, selectAllProperties } from 'entities/property/property.reducer';
import { ReloadRegion } from 'entities/region/region.actions';
import type { ReloadRegionData } from 'entities/region/region.models';
import { SetSeasonsFromProperties, SetSeasonsFromProperty } from 'entities/season/season.actions';
import { isActive } from 'entities/season/season.functions';
import type { Season } from 'entities/season/season.models';
import { getSeasonsGroupedByYear, selectAllSeasons } from 'entities/season/season.reducer';
import { Set as ImmutableSet } from 'immutable';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import type { AppState } from 'redux/app-state';
import BreadcrumbSeasonList from './breadcrumb-radios.component';
import { BreadcrumbSeasonsGroup } from './breadcrumb-seasons.enum';
import './breadcrumb-seasons.styles.less';
import { skeletonParagraph, skeletonTitle } from './constants';
import { sortedSeasonsByYear } from './functions/seasons.functions';

interface IBackupData {
  currentSelected: UUID | null;
  seasonsSelected: UUID | null;
  inactiveSelected: UUID | undefined;
  groupSelected: BreadcrumbSeasonsGroup;
}

interface STBreadcrumbSeasonsProps {
  forceActiveSeasons?: boolean;
}

const STBreadcrumbSeasons: React.FC<STBreadcrumbSeasonsProps> = ({ forceActiveSeasons = false }) => {
  const [t] = useTranslation();
  const [inactiveSortedGroupKeys, setInactiveSortedGroupKeys] = useState<ImmutableSet<UUID>>();
  const [activeSortedGroupKeys, setActiveSortedGroupKeys] = useState<ImmutableSet<UUID>>();
  const [selectedSeasonsFrom, setSelectedSeasonsFrom] = useState<UUID | undefined>();
  const [inactiveSelected, setInactiveSelected] = useState<UUID>();
  const [currentSelected, setCurrentSelected] = useState<UUID | null>(null);
  const [seasonsSelected, setSeasonsSelected] = useState<UUID | null>(null);
  const [sortedGroupKeys, setSortedGroupKeys] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [groupSelected, setGroupSelected] = useState<BreadcrumbSeasonsGroup>(BreadcrumbSeasonsGroup.ALL_ACTIVES);
  const [activeSeasons, setActiveSeasons] = useState<string[]>([]);
  const [filterApply, setFilterApply] = useState<UUID | null>(null);
  const [backupData, setBackupData] = useState<IBackupData | null>(null);
  const [filteredSeasonGroups, setFilteredSeasonGroups] = useState<Dictionary<Season[]>>({});
  const [filteredSeasons, setFilteredSeasons] = useState<Season[]>([]);
  const seasonsSelectedPrevious = usePreviousValue(seasonsSelected);
  const lastActiveSeasons = usePreviousValue(activeSeasons);

  const seasonsGroups = useSelector<AppState, Dictionary<Season[]>>(state => getSeasonsGroupedByYear(state));
  const isLoading = useSelector<AppState, boolean>(state => state.entities.company.isLoading || state.entities.property.isLoading);
  const property = useSelector<AppState, Property | undefined>(state => getSelectedProperty(state));
  const company = useSelector<AppState, Company | undefined>(state => getSelectedCompany(state));
  const seasons = useSelector<AppState, Season[]>(state => selectAllSeasons(state.entities.season));

  const properties = useSelector<AppState, Property[]>(state => selectAllProperties(state));
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (property && filterApply && activeSeasons !== lastActiveSeasons) {
      if (!filteredSeasons.filter(seas => filterApply === seas.id).length) {
        setGroupSelected(BreadcrumbSeasonsGroup.ALL_ACTIVES);
        return;
      }
      setGroupSelected(BreadcrumbSeasonsGroup.BY_SEASON);
      setSeasonsSelected(filterApply);
      setFilterApply(null);
      reloadRegionData([filterApply]);
    }
  }, [property, filterApply, activeSeasons, lastActiveSeasons, filteredSeasons]);

  useEffect(() => {
    if (property) {
      if (selectedSeasonsFrom !== property.id) {
        dispatch(SetSeasonsFromProperty());
        setSelectedSeasonsFrom(property.id);
      }
    } else if (company) {
      if (selectedSeasonsFrom !== company.id && properties.length) {
        dispatch(SetSeasonsFromProperties(properties));
        setSelectedSeasonsFrom(company.id);
      }
    }
  }, [company, property, dispatch, selectedSeasonsFrom, properties]);

  useEffect(() => {
    if (pathname && pathname.includes('cotton')) {
      const newSeasonGroups = Object.keys(seasonsGroups).reduce((filteredGroups, seasonGroupYear) => {
        return {
          ...filteredGroups,
          [seasonGroupYear]: seasonsGroups[seasonGroupYear].filter(
            season => season.crop.wk_slug.toLowerCase() === CropTypesEnum.COTTON.toLowerCase()
          )
        };
      }, {});
      setFilteredSeasonGroups(newSeasonGroups);
      setFilteredSeasons(seasons.filter(season => season.crop.wk_slug.toLowerCase() === CropTypesEnum.COTTON.toLowerCase()));
    } else {
      setFilteredSeasonGroups(seasonsGroups);
      setFilteredSeasons(seasons);
    }
  }, [seasonsGroups, pathname, seasons]);

  useEffect(() => {
    setSeasonsSelected(null);
    const activeSeasonsArray = filteredSeasons.filter(seas => isActive(seas));
    setActiveSeasons(activeSeasonsArray.map(seas => seas.id));
    setGroupSelected(BreadcrumbSeasonsGroup.ALL_ACTIVES);

    const inactiveSeasonsArray = filteredSeasons.filter(season => !activeSeasonsArray.includes(season));
    if (!activeSeasonsArray.length && inactiveSeasonsArray.length) {
      setGroupSelected(BreadcrumbSeasonsGroup.BY_SEASON);
      setInactiveSelected(inactiveSeasonsArray[0].id);
      setCurrentSelected(null);
      setSeasonsSelected(inactiveSeasonsArray[0].id);
      reloadRegionData([inactiveSeasonsArray[0].id]);
    }
  }, [filteredSeasons]);

  useEffect(() => {
    const tempArray = Object.keys(filteredSeasonGroups).sort(compareStrings).reverse();
    setSortedGroupKeys(tempArray);
  }, [filteredSeasonGroups]);

  useEffect(() => {
    const [tempActiveSeasonsGroups, tempInactiveSeasonsGroups] = sortedSeasonsByYear(sortedGroupKeys, filteredSeasonGroups) as [
      UUID[],
      UUID[]
    ];
    setActiveSortedGroupKeys(ImmutableSet(tempActiveSeasonsGroups));
    setInactiveSortedGroupKeys(ImmutableSet(tempInactiveSeasonsGroups));
  }, [sortedGroupKeys, filteredSeasonGroups]);

  const closeModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsModalVisible(false);
    resetData();
  };

  const segmentTracking = useSegmentTracking();

  const handleReset = () => {
    segmentTracking.track('Season Selector - Reseted Selection');
    resetData();
  };

  const resetData = () => {
    if (backupData) {
      setCurrentSelected(backupData.currentSelected);
      setInactiveSelected(backupData.inactiveSelected);
      setSeasonsSelected(backupData.seasonsSelected);
      setGroupSelected(backupData.groupSelected);
    }
  };

  const openModal = () => {
    if (forceActiveSeasons) return;
    segmentTracking.track('Season Selector - Tapped to see options');
    setIsModalVisible(!!filteredSeasons.length);
  };

  useEffect(() => {
    if (isModalVisible) {
      setBackupData({
        currentSelected,
        seasonsSelected,
        inactiveSelected,
        groupSelected
      });
    } else {
      setBackupData(null);
    }
  }, [isModalVisible]);

  const handleCheckChange = (eventSeason: RadioChangeEvent) => {
    setCurrentSelected((eventSeason?.target.value as UUID) || null);
    setSeasonsSelected((eventSeason?.target.value as UUID) || null);
    setInactiveSelected('');
  };

  const onSelectInactive = (val: RadioChangeEvent) => {
    setInactiveSelected(val.target.value as UUID);
    setCurrentSelected(null);
    setSeasonsSelected(val.target.value as UUID);
  };

  const onChangeGroup = (value: RadioChangeEvent) => {
    setGroupSelected(value.target.value as BreadcrumbSeasonsGroup);
    if (value.target.value === BreadcrumbSeasonsGroup.ALL_ACTIVES) {
      setCurrentSelected(null);
      setSeasonsSelected(null);
      setInactiveSelected('');
    }
  };

  const reloadRegionData = useCallback(
    (seasonIds: UUID[]) => {
      dispatch(SetCurrentSeasons(seasonIds));
      if (property) {
        const reloadRegionDataPayload: ReloadRegionData = {
          regionId: property.root_region_id,
          seasonIds
        };
        dispatch(ReloadRegion(reloadRegionDataPayload));
      }
    },
    [dispatch, property]
  );

  const applySelectedSeasons = useCallback(() => {
    if (!property && seasonsSelected) {
      setFilterApply(seasonsSelected);
    }
    if (seasonsSelectedPrevious !== seasonsSelected) {
      segmentTracking.track('Season Selector - Changed season filter', { selectedSeasonId: seasonsSelected });

      setIsModalVisible(false);
      reloadRegionData(seasonsSelected ? [seasonsSelected] : [...activeSeasons]);
    }
  }, [activeSeasons, company, property, reloadRegionData, seasonsSelected, seasonsSelectedPrevious]);

  useEffect(() => {
    if (forceActiveSeasons && !!activeSeasons.length) {
      onChangeGroup({ target: { value: BreadcrumbSeasonsGroup.ALL_ACTIVES } } as RadioChangeEvent);
      applySelectedSeasons();
    }
  }, [forceActiveSeasons, applySelectedSeasons, activeSeasons]);

  const getSTSeasonListCurrentSelect = () => {
    return seasonsSelected ? [seasonsSelected] : activeSeasons;
  };

  return (
    <Skeleton title={skeletonTitle} paragraph={skeletonParagraph} active loading={isLoading}>
      <div
        onClick={openModal}
        className={`sd-header__breadcrumb__item${forceActiveSeasons ? '__disabled' : ''}`}
        data-testid='breadcrumb-open'>
        <div className='st-breadcrumb__text'>
          <div className='sd-header__breadcrumb__item__title'>
            <STTypo type={TypeOptions.C1} color={COLOR_OPTIONS.SECONDARY}>
              {t('components.breadcrumb.season_title')}
            </STTypo>
          </div>
          <div className='sd-header__breadcrumb__item__name'>
            <STSeasonList seasons={filteredSeasons} currentSelected={getSTSeasonListCurrentSelect()} />
            <i className='sf sf-chevron-down' />
          </div>
        </div>
      </div>

      <Modal
        className='st-seasons-modal'
        title={t('general.context_select.season_select.show_seasons')}
        onCancel={closeModal}
        visible={isModalVisible}
        destroyOnClose
        wrapClassName='st-context-select-season-modal'
        footer={
          <div className='st-seasons-footer' key='season-section-footer'>
            <div className='st-seasons-reset' key='season-section-reset'>
              <Button type='link' size='large' className='cancel' key='reset' onClick={handleReset}>
                <STTypo color={COLOR_OPTIONS.SUCCESS}>{t('general.reset')}</STTypo>
              </Button>
            </div>
            <div className='st-seasons-actions' key='season-section-actions'>
              <Button type='link' size='large' className='cancel' key='cancel' onClick={closeModal}>
                <STTypo color={COLOR_OPTIONS.SECONDARY}>{t('modals.general.cancel')}</STTypo>
              </Button>
              <Button
                type='primary'
                size='large'
                key='apply'
                data-testid='breadcrumb-seasons-apply'
                disabled={groupSelected === BreadcrumbSeasonsGroup.BY_SEASON && !seasonsSelected && !inactiveSelected}
                onClick={applySelectedSeasons}>
                {t('modals.general.apply')}
              </Button>
            </div>
          </div>
        }>
        <div className='st-seasons'>
          <div className='st-seasons-radios'>
            <Radio.Group onChange={onChangeGroup} value={groupSelected}>
              <Radio disabled={!activeSeasons.length} value={BreadcrumbSeasonsGroup.ALL_ACTIVES} data-testid='breadcrumb-change-actives'>
                {t('general.context_select.season_select.all_active')}
              </Radio>
              <Radio value={BreadcrumbSeasonsGroup.BY_SEASON} data-testid='breadcrumb-change-season'>
                {t('general.context_select.season_select.by_season')}
              </Radio>
            </Radio.Group>
          </div>
          <BreadcrumbSeasonList
            handleCheckChange={handleCheckChange}
            seasonsSelected={seasonsSelected}
            groupSelected={groupSelected}
            activeSortedGroupKeys={activeSortedGroupKeys}
            inactiveSortedGroupKeys={inactiveSortedGroupKeys}
            seasonsGroups={filteredSeasonGroups}
            onSelectInactive={onSelectInactive}
            inactiveSelected={inactiveSelected}
          />
        </div>
      </Modal>
    </Skeleton>
  );
};

export default STBreadcrumbSeasons;
