import { useMemo } from 'react';

export const useReportSampleUI = ({
  selectedPhenomena,
  selectedAreas,
  selectedTargets
}: {
  selectedPhenomena: string[];
  selectedAreas: string[];
  selectedTargets: string[];
}) => {
  const submitDisabled = useMemo(() => {
    const willDisableByPhenomena = selectedPhenomena.length === 0 && selectedTargets.length === 0;
    return !selectedAreas.length || willDisableByPhenomena;
  }, [selectedAreas, selectedPhenomena, selectedTargets]);

  return useMemo(() => ({ submitDisabled }), [submitDisabled]);
};
