import { COLOR_OPTIONS } from 'config/style';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  border: 1px solid #c6e6ff;
  border-radius: 4px;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  height: 36px;
  background-color: ${COLOR_OPTIONS.BLUE_20};
  justify-content: space-between;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  background-color: ${COLOR_OPTIONS.BLUE_30};
  cursor: pointer;
`;

export const StyledInputDataWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: hidden;
  background-color: ${COLOR_OPTIONS.WHITE};
  row-gap: 14px;
  border-radius: 0px 0px 4px 4px;

  > div {
    box-shadow: none;
  }
`;
