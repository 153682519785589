import type { Action, UUID } from 'core/utils/basic.models';
import { LoadCompanies } from 'entities/company/company.actions';
import type { Epic } from 'redux-observable';
import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { catchError, exhaustMap, flatMap, map, mergeMap } from 'rxjs/operators';
import {
  AuthActionsTypes,
  LoadOrganizationInfoFailure,
  LoadOrganizationInfoSuccess,
  LoadWorkspaceApps,
  LoadWorkspaceAppsFailure,
  LoadWorkspaceAppsSuccess,
  LoadWorkspaceInfoAccessFailure,
  LoadWorkspaceInfoAccessSuccess,
  LoadWorkspaceInfoFailure,
  LoadWorkspaceInfoSuccess,
  LoadWorkspaceName,
  LoadWorkspaceNameFailure,
  LoadWorkspaceNameSuccess,
  LoginFailure,
  LoginSuccess,
  LogoutFailure,
  LogoutSuccess
} from './auth.actions';
import type { LoginError } from './auth.models';
import {
  getOrganizationInfo,
  getWorkspaceApps,
  getWorkspaceInfo,
  getWorkspaceInfoAccess,
  getWorkspaceName,
  requestAccessToken,
  requestLogout
} from './auth.service';

export const handleLoginRequest = action$ =>
  action$.pipe(
    ofType(AuthActionsTypes.LOGIN_REQUEST),
    map((action: Action<string>) => action.payload),
    exhaustMap((code: string) =>
      requestAccessToken(code).pipe(
        map(response => {
          const token = response.data;
          localStorage.setItem('access_token', token.access_token);
          localStorage.setItem('refresh_token', token.refresh_token);

          return LoginSuccess(token);
        }),
        catchError((error: LoginError) => of(LoginFailure(error)))
      )
    )
  );

export const handleLogoutRequest = action$ =>
  action$.pipe(
    ofType(AuthActionsTypes.LOGOUT_REQUEST),
    exhaustMap(() =>
      requestLogout().pipe(
        map(() => {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          sessionStorage.removeItem('access_token');
          sessionStorage.removeItem('refresh_token');
          sessionStorage.removeItem('company_id');

          return LogoutSuccess();
        })
      )
    ),
    catchError((error: LoginError) => of(LogoutFailure(error)))
  );

export const handleLoadWorkspaceInfo: Epic = action$ =>
  action$.pipe(
    ofType(AuthActionsTypes.LOAD_WORKSPACE_INFO),
    map(action => action.payload),
    mergeMap((id: UUID) =>
      getWorkspaceInfo(id).pipe(
        map(response => response.data),
        flatMap(data => {
          return concat([LoadWorkspaceInfoSuccess(data.default_licensing_account_id)]);
        })
      )
    ),
    catchError(err => of(LoadWorkspaceInfoFailure(err)))
  );

export const handleLoadWorkspaceInfoAccess: Epic = action$ =>
  action$.pipe(
    ofType(AuthActionsTypes.LOAD_WORKSPACE_INFO_ACCESS),
    map(action => action.payload),
    mergeMap((id: UUID) =>
      getWorkspaceInfoAccess(id).pipe(
        map(response => response.data),
        flatMap(data => {
          const workspaceId = data.workspaces?.[0]?.workspace_id ? data.workspaces[0]?.workspace_id : '';
          return concat([
            LoadWorkspaceInfoAccessSuccess(data.workspaces[0]?.workspace_id),
            LoadWorkspaceName(workspaceId),
            LoadWorkspaceApps(workspaceId)
          ]);
        })
      )
    ),
    catchError(err => of(LoadWorkspaceInfoAccessFailure(err)))
  );

export const handleLoadWorkspaceName: Epic = action$ =>
  action$.pipe(
    ofType(AuthActionsTypes.LOAD_WORKSPACE_NAME),
    map(action => action.payload),
    mergeMap((id: UUID) => {
      return id !== ''
        ? getWorkspaceName(id).pipe(
            map(response => response.data),
            mergeMap(data => of(LoadWorkspaceNameSuccess(data.name)))
          )
        : [];
    }),
    catchError(err => of(LoadWorkspaceNameFailure(err)))
  );

export const handleLoadWorkspaceApps: Epic = action$ =>
  action$.pipe(
    ofType(AuthActionsTypes.LOAD_WORKSPACE_APPS),
    mergeMap((id: UUID) => {
      return id === ''
        ? getWorkspaceApps().pipe(
            map(response => response.data),
            flatMap(data => {
              return of(LoadWorkspaceAppsSuccess(data));
            })
          )
        : [];
    }),
    catchError(err => of(LoadWorkspaceAppsFailure(err)))
  );

export const handleLoadOrganizationInfo = action$ =>
  action$.pipe(
    ofType(AuthActionsTypes.LOAD_ORGANIZATION_INFO),
    map((action: Action<string>) => action.payload),
    mergeMap((id: UUID) =>
      getOrganizationInfo(id).pipe(
        map(response => response.data),
        flatMap(info => {
          if (info.licensing_account_id) {
            return of(LoadOrganizationInfoSuccess(info));
          }
          return of(LoadOrganizationInfoSuccess(info));
        })
      )
    ),
    catchError(err => of(LoadOrganizationInfoFailure(err)))
  );
export const handleLoginSuccess = action$ =>
  action$.pipe(
    ofType(AuthActionsTypes.LOGIN_SUCCESS),
    mergeMap(() =>
      concat([
        LoadCompanies({
          pageable: {
            page: 0,
            size: 10
          }
        })
      ])
    )
  );
