const SvgReport = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' fill='none' viewBox='9 9 14 14'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.075 9.25H9.925C9.55 9.25 9.25 9.55 9.25 9.925V22.075C9.25 22.375 9.55 22.75 9.925 22.75H22.075C22.375 22.75 22.75 22.375 22.75 22.075V9.925C22.75 9.55 22.375 9.25 22.075 9.25ZM13.75 12.25H12.25V13.75H13.75V12.25ZM19.75 12.25H15.25V13.75H19.75V12.25ZM19.75 15.25H15.25V16.75H19.75V15.25ZM15.25 18.25H19.75V19.75H15.25V18.25ZM12.25 15.25H13.75V16.75H12.25V15.25ZM13.75 18.25H12.25V19.75H13.75V18.25ZM10.75 21.25H21.25V10.75H10.75V21.25Z'
      fill='#9A6412'
    />
  </svg>
);

export default SvgReport;
