import { Tooltip } from 'antd';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import SvgResourceCenter from 'st-protector-icons/Minor/ResourceCenter';
import './resource-center.styles.less';

export interface ResourceCenterProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const SDResourceCenter = ({ onClick }: ResourceCenterProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <div className='sd-resource-center-button' tabIndex={0} role='button' data-testid='header-resource-center' onClick={onClick}>
      <Tooltip title={t('header.resource_center')}>
        <SvgResourceCenter />
      </Tooltip>
    </div>
  );
};

export { SDResourceCenter, SDResourceCenter as default };
