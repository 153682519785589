import axios from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import type { AlertBody } from './alerts.models';

const protectorApiUrl = PROTECTOR_API_URL;
const alertsUrl = `${protectorApiUrl}/v1/alerts`;

export const getAllAlerts = (): Observable<AlertBody[]> => {
  return axios.get<AlertBody[]>(alertsUrl).pipe(
    map(response => {
      return response.data;
    })
  );
};
