const SvgWarehouseReport = props => (
  <svg width={24} height={24} viewBox='7 7 18 18' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.75 15.55L16 9.25L24.25 15.55L23.3425 16.735L22 15.715V22.75H10V15.715L8.6575 16.7425L7.75 15.55ZM20.5 21.25H19.75V18.25H18.25V15.25H13.75V18.25H12.25V21.25H11.5V14.575L16 11.14L20.5 14.575V21.25ZM13.75 19.75V21.25H15.25V19.75H13.75ZM16.75 19.75V21.25H18.25V19.75H16.75ZM16.75 18.25V16.75H15.25V18.25H16.75Z'
      fill='#8354D6'
    />
  </svg>
);

export default SvgWarehouseReport;
