import { notification } from 'antd';
import type { Action, UUID } from 'core/utils/basic.models';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { CreateStationSuccess } from 'entities/stations/stations.actions';
import StaticPointsMapper from 'pages/static-points/map/static-points-mapper';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  LoadStaticPointsFailure,
  LoadStaticPointsSuccess,
  LoadStaticPointsTemplatesFailure,
  LoadStaticPointsTemplatesSuccess,
  StaticPointsActionsTypes
} from './static-points.actions';
import type { CreateStaticPointType, StaticPointActionPayload, StaticPointCreation, StaticPointTemplate } from './static-points.models';
import { StaticPointFieldEnum, StaticPointType } from './static-points.models';
import { StaticPointService } from './static-points.service';

const { getStaticPoints, createStaticPoint } = StaticPointService;
const { mapStaticPointToStation } = StaticPointsMapper;

export const handleLoadStaticPoint = (action$, state$) =>
  action$.pipe(
    ofType(StaticPointsActionsTypes.LOAD_STATIC_POINTS),
    map((action: Action<StaticPointActionPayload>) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => getSelectedProperty(state)))),
    exhaustMap<any, any>(([payload, selectedProperty]) => {
      const propertyId = payload.propertyId || selectedProperty.id;
      return getStaticPoints(propertyId, payload.fields).pipe(
        takeUntil(action$.pipe(ofType(StaticPointsActionsTypes.LOAD_STATIC_POINTS))),
        map(staticPoints => LoadStaticPointsSuccess(staticPoints)),
        catchError(error => of(LoadStaticPointsFailure(error)))
      );
    })
  );

export const handleLoadStaticPointTemplate = (action$, state$) =>
  action$.pipe(
    ofType(StaticPointsActionsTypes.LOAD_STATIC_POINTS_TEMPLATES),
    map((action: Action<UUID>) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => getSelectedProperty(state)))),
    exhaustMap<any, any>(([payload, selectedProperty]) => {
      const propertyId = payload.propertyId || selectedProperty.id;
      return getStaticPoints(propertyId, [StaticPointFieldEnum.ALL, StaticPointFieldEnum.CATALOGUE]).pipe(
        map(staticPoints => {
          const staticPointsTemplate = staticPoints.map(staticPoint => staticPoint as StaticPointTemplate);
          return LoadStaticPointsTemplatesSuccess(staticPointsTemplate);
        }),
        catchError(error => of(LoadStaticPointsTemplatesFailure(error)))
      );
    })
  );

export const handleCreateStaticPoint = (action$, state$) =>
  action$.pipe(
    ofType(StaticPointsActionsTypes.CREATE_STATIC_POINT),
    map((action: Action<CreateStaticPointType>) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => state.uiState.auth.user))),
    concatMap(([payload, user]) => {
      if (payload.type === StaticPointType.TRAP) {
        return createStaticPoint({ id: user.id, name: user.name }, payload.staticPoint).pipe(
          map(response => response.data),
          map((staticPoint: StaticPointCreation) => {
            const station = mapStaticPointToStation(payload.type, staticPoint);
            return CreateStationSuccess(station, payload.callback);
          }),
          catchError(() => {
            notification.error(payload.callback.notification.error);
            return EMPTY;
          })
        );
      }
      return EMPTY;
    })
  );
