import type { Action } from 'core/utils/basic.models';
import type {
  CreateStaticPointType,
  LoadStaticPointSuccessType,
  LoadStaticPointTemplateSuccessType,
  LoadStaticPointTemplateType,
  LoadStaticPointType
} from './static-points.models';
import { StaticPointFieldEnum } from './static-points.models';

export const StaticPointsActionsTypes = {
  LOAD_STATIC_POINTS: '[Static Point] Load Static Points',
  LOAD_STATIC_POINTS_SUCCESS: '[Static Point] Load Static Points Success',
  LOAD_STATIC_POINTS_FAILURE: '[Static Point] Load Static Points Failure',
  LOAD_STATIC_POINTS_TEMPLATES: '[Static Point] Load Static Points Templates',
  LOAD_STATIC_POINTS_TEMPLATES_SUCCESS: '[Static Point] Load Static Points Templates Success',
  LOAD_STATIC_POINTS_TEMPLATES_FAILURE: '[Static Point] Load Static Points Templates Failure',
  CREATE_STATIC_POINT: '[Static Point] Create Static Point',
  DELETE_STATIC_POINT: '[Static Point] Delete Static Point'
};

export const LoadStaticPoints: LoadStaticPointType = (propertyId, fields: StaticPointFieldEnum[] = [StaticPointFieldEnum.ALL]) => {
  return {
    type: StaticPointsActionsTypes.LOAD_STATIC_POINTS,
    payload: { propertyId, fields }
  };
};

export const LoadStaticPointsSuccess: LoadStaticPointSuccessType = staticPoints => {
  return {
    type: StaticPointsActionsTypes.LOAD_STATIC_POINTS_SUCCESS,
    payload: staticPoints
  };
};

export const LoadStaticPointsFailure = (error: string): Action<string> => {
  return {
    type: StaticPointsActionsTypes.LOAD_STATIC_POINTS_FAILURE,
    payload: error
  };
};

export const LoadStaticPointsTemplates: LoadStaticPointTemplateType = propertyId => {
  return {
    type: StaticPointsActionsTypes.LOAD_STATIC_POINTS_TEMPLATES,
    payload: { propertyId }
  };
};

export const LoadStaticPointsTemplatesSuccess: LoadStaticPointTemplateSuccessType = templates => {
  return {
    type: StaticPointsActionsTypes.LOAD_STATIC_POINTS_TEMPLATES_SUCCESS,
    payload: templates
  };
};

export const LoadStaticPointsTemplatesFailure = (error: string): Action<string> => {
  return {
    type: StaticPointsActionsTypes.LOAD_STATIC_POINTS_TEMPLATES_FAILURE,
    payload: error
  };
};

export const CreateStaticPoint: CreateStaticPointType = (type, staticPoint, callback) => {
  return {
    type: StaticPointsActionsTypes.CREATE_STATIC_POINT,
    payload: { type, staticPoint, callback }
  };
};
