import type { Action } from 'core/utils/basic.models';
import type { LoadWeatherParams, WeatherData } from './weather.models';

export const WeatherActionsTypes = {
  LOAD_WEATHER: '[WEATHER] Load Weather',
  LOAD_WEATHER_SUCCESS: '[WEATHER] Load Weather Success',
  LOAD_WEATHER_FAILURE: '[WEATHER] Load Weather Failure',
  LOAD_WEATHER_HOURLY: '[WEATHER] Load Weather Hourly',
  LOAD_WEATHER_HOURLY_SUCCESS: '[WEATHER] Load Weather Hourly Success',
  LOAD_WEATHER_HOURLY_FAILURE: '[WEATHER] Load Weather Hourly Failure',
  CLEAR_WEATHER: '[WEATHER] Clear Weather'
};

export const LoadWeather = (request: LoadWeatherParams): Action<LoadWeatherParams> => ({
  type: WeatherActionsTypes.LOAD_WEATHER,
  payload: request
});

export const LoadWeatherSuccess = (weather: WeatherData): Action<WeatherData> => {
  return {
    type: WeatherActionsTypes.LOAD_WEATHER_SUCCESS,
    payload: weather
  };
};

export const LoadWeatherFailure = (error: string): Action<string> => {
  return {
    type: WeatherActionsTypes.LOAD_WEATHER_FAILURE,
    payload: error
  };
};

export const LoadWeatherHourly = (request: LoadWeatherParams): Action<LoadWeatherParams> => ({
  type: WeatherActionsTypes.LOAD_WEATHER_HOURLY,
  payload: request
});

export const LoadWeatherHourlySuccess = (weather: WeatherData): Action<WeatherData> => {
  return {
    type: WeatherActionsTypes.LOAD_WEATHER_HOURLY_SUCCESS,
    payload: weather
  };
};

export const LoadWeatherHourlyFailure = (error: string): Action<string> => {
  return {
    type: WeatherActionsTypes.LOAD_WEATHER_HOURLY_FAILURE,
    payload: error
  };
};
