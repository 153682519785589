import type { PestRiskRules } from 'pages/scouting-plan/scouting-plan.models';
import type { UUID } from '../../../core/utils/basic.models';
import type { AssigneeGroupConfig, RoutingPlanConfiguration } from '../routing-configuration.model';

export interface LeafhopperPriorities {
  id: PrioritiesIds;
  days: number;
  enabled: boolean;
  blocking: boolean;
  field_cluster_percentage: number;
}

export enum FieldType {
  COMPONENT = 'component',
  TITLE = 'title'
}

export interface LeafhopperPrioritiesFieldTitle {
  title: string;
  type: FieldType;
  enabled: boolean;
}

export interface LeafhopperPrioritiesFieldBox extends LeafhopperPrioritiesFieldTitle {
  id: PrioritiesIds;
  textBeforeInput?: string;
  inputType?: string;
  textAfterInput?: string;
  info: string;
}

export interface ProductValues {
  id: string;
  name: string;
}

export interface ProductResidualInfo {
  id?: UUID;
  name?: string;
  residual_days?: string;
}

export interface ProductResidualConfig {
  id: string;
  residual_days: string;
}

export const productsSugarCaneType = ['INSECTICIDE', 'PESTICIDE', 'BIOLOGICAL_CONTROL_AGENT'];

export interface LeafhopperConfiguration extends RoutingPlanConfiguration {
  products: ProductResidualConfig[];
  days_after_planting_or_harvest: number;
  days_after_monitoring: number;
  days_before_harvest: number;
  assignee_groups: AssigneeGroupConfig[];
  field_cluster_phenomenons: UUID[];
  inspection_groups_ids: UUID[];
  sampling_points: {
    type: string;
    points_factor: number;
    minimum_ratio: number;
  };
  risk_parameters: {
    blocking: boolean;
    field_cluster_percentage?: number;
  };
  pest_risk_rules: PestRiskRules;
}

export enum PrioritiesIds {
  RISK_PARAMETERS = 'risk_parameters',
  DAYS_AFTER_PLANTING_OR_HARVEST = 'days_after_planting_or_harvest',
  DAYS_AFTER_MONITORING = 'days_after_monitoring',
  DAYS_BEFORE_HARVEST = 'days_before_harvest'
}
