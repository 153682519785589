import type { IndicatorDiagnostic } from 'entities/indicators/indicators.models';
import type { Phenomenon } from 'entities/phenomenon/phenomenon.models';
import type { UUID } from '../../core/utils/basic.models';
import type { ControlStrategyProduct } from './create-control-strategy/create-control-strategy.models';

export interface ContextControlStrategy {
  loadingControlStrategyEvents: boolean;
  setLoadingControlStrategyEvents: (field: boolean) => void;
  controlStrategyForceSuggestion: ControlStrategyOptionsEnum;
  setControlStrategyForceSuggestion: (forceSuggestion: ControlStrategyOptionsEnum) => void;
  cancelAckControlStrategyId: string | undefined;
  setCancelAckControlStrategyId: (value: string | undefined) => void;
  hideForceSuggestion: boolean;
  setHideForceSuggestion: (value: boolean) => void;
  eventsRegionsLoading: boolean;
  handleUpdateSelectedSuggestion: (regionKey: string, strategyId: string, suggestionsIds: UUID[], forcedTargetsIds?: UUID[]) => void;
  clearRegionSelectedSuggestions: (regionKey: string, strategyId: string) => void;
  controlStrategyPhenomena?: Phenomenon[];
  ackedSuggestions: UUID[];
  setAckedSuggestions: (value: UUID[]) => void;
  listMarkedAllViewed: ControlStrategyRegionCard[];
  setListMarkedAllViewed: (list: ControlStrategyRegionCard[]) => void;
}

interface SuggestionsAndForcedTargets {
  suggestionsIds: UUID[];
  forcedTargetsIds: UUID[];
}
export type SelectedSuggestionsPerStrategy = Record<string, SuggestionsAndForcedTargets>;
export interface SimpleControlStrategy {
  enabled: boolean;
  name: string;
  id: string;
  org_id: string;
  description: string;
  type: string;
  property_id: string;
  products: ControlStrategyProduct[];
  status: string;
}

interface ControlStrategyResponse<T> {
  content: T[];
  cursor: string;
  is_last: boolean;
  size: number;
  empty: boolean;
}

export type ControlStrategyEventsResponse = ControlStrategyResponse<ControlStrategyEventDay>;
export type ControlStrategiesSimpleResponse = ControlStrategyResponse<SimpleControlStrategy>;
export type ControlStrategiesSuggestionsResponse = ControlStrategyResponse<ControlStrategySuggestion>;

export interface ControlStrategyEventDay {
  alert_id;
  id: string;
  group_id: string;
  event_name: string;
  value: number;
  event_day: string;
  created_at: string;
  target: ControlStrategyEventDayTarget;
  acknowledged: boolean;
  highlighted?: boolean;
  forced: boolean;
}

interface ControlStrategyEventDayTarget {
  area_id: string;
  indicator_id: string;
}

export interface ControlStrategyAreasCard {
  area_id: string;
  indicator_id: string;
  indicator_name: string;
  value: number;
  unit_of_measure: string;
  event_day: string;
  diagnostics: IndicatorDiagnostic[];
  event_name: string;
}

export interface ControlStrategyRegionCard {
  event_areas: ControlStrategyAreasCard[];
  path: string;
  region_id: string;
  name: string;
  event_date: string;
  acknowledged: boolean;
  highlighted?: boolean;
}

export interface ControlStrategySuggestion {
  selected: boolean;
  id: string;
  target_id: string;
  control_strategy_id: string;
  group_id: string;
  deleted_at: string;
  valid_start_date: string;
  valid_end_date: string;
  products: ControlStrategyProduct[];
  acknowledged: boolean;
  created_at: string;
  updated_at: string;
  updated_by: string;
  events_day: string;
  highlighted?: boolean;
  forced: boolean;
}

export interface ControlStrategySuggestionCard {
  suggestions_id: string[];
  targets: string[];
  selectedTargets: string[];
  control_strategy_id: string;
  group_id: string;
  deleted_at?: string;
  valid_start_date: string;
  valid_end_date: string;
  products: ControlStrategyProduct[];
  acknowledged: boolean;
  selected: boolean;
  created_at: string;
  updated_at?: string;
  updated_by?: string;
  events_day: string;
  highlighted?: boolean;
}

export type SuggestionsPerRegion = Record<string, Record<string, ControlStrategySuggestionCard>>;

export type SuggestionPerControlStrategy = Record<string, { selected: boolean; suggestionId?: UUID }>;
export type AreasSuggestionsPerControlStrategy = Record<string, SuggestionPerControlStrategy>;

export enum ControlStrategyFrameColorDisabled {
  FILL = '#DFE2E7',
  STROKE = '#A3A9B9'
}

export enum ControlStrategyNameColor {
  ENABLED = '#363948',
  DISABLED = '#C2C7D0'
}

export enum ControlStrategyStatus {
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  PARTIALLY_CREATED = 'PARTIALLY_CREATED',
  PROCESSED = 'PROCESSED'
}

export enum ControlStrategyStatusColor {
  ERROR = '#EE5B3A',
  PROCESSING = '#0071CD'
}

export enum ControlStrategyQuery {
  PHENOMENA = 'getControlStrategyPhenomena',
  SPRAY = 'getFieldsSprays',
  PESTS_RISK_DATA = 'getParametersData'
}

export enum ControlStrategyOptionsEnum {
  ALL_AREAS = 'ALL_AREAS',
  SUGGESTED_AREAS = 'SUGGESTED_AREAS',
  ALWAYS_ASK = 'ALWAYS_ASK',
  SELECT_INDIVIDUALLY = 'SELECT_INDIVIDUALLY'
}

interface EvaluationTimes {
  range: string;
  min_value: string;
  max_value: string;
}

interface AlertConfigurationReportQuery {
  audience_type: string;
  audience_id: string;
}
interface AlertConfigurationReport {
  query: AlertConfigurationReportQuery;
  count: number;
  evaluation_times: EvaluationTimes;
  group_ids: string[];
  simplified_predicates: unknown;
}

interface AlertEventsReportQuery {
  start_date: string;
  end_date: string;
  target_events: string[];
}

interface AlertEventAgg {
  type: string;
  target_id: string;
  value: number;
  cardinality: number;
  first_occurrence: string;
  last_occurrence: string;
}
interface AlertEventsReport {
  query: AlertEventsReportQuery;
  count: number;
  alert_events_by_target: Map<string, AlertEventAgg>;
}
export interface AlertsDebugReport {
  alerts_configuration_report: AlertConfigurationReport;
  alert_events_report: AlertEventsReport;
}
