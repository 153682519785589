import axios from 'axios-observable';
import { CORE_SERVICES_API_URL, PROTECTOR_API_URL } from 'config/constants';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import type { IndexedName, UUID, V4Page } from '../../core/utils/basic.models';
import type {
  WarehouseActivityResponse,
  WarehouseDTO,
  WarehouseInputDTO,
  WarehouseResponse,
  WarehouseStockResponse,
  WarehouseTransactionResponse,
  WarehouseTransferDTO,
  WarehouseVendorResponse
} from './warehouse.models';

const protectorApiUrl = PROTECTOR_API_URL;
const warehouseUrl = id => `${protectorApiUrl}/v1/companies/${id}/warehouses?size=9999`;
const vendorUrl = id => `${protectorApiUrl}/v1/companies/${id}/vendors`;
const stockCompanyUrl = id => `${protectorApiUrl}/v1/companies/${id}/stock`;
const stockUrl = id => `${protectorApiUrl}/v1/warehouses/${id}/stock`;
const coreServicesApiUrl = CORE_SERVICES_API_URL;
const newVendorUrl = `${coreServicesApiUrl}/v1/vendors`;
const getVendorUrl = id => `${coreServicesApiUrl}/v1/vendors/${id}`;
const warehousesUrlBase = `${coreServicesApiUrl}/v1/warehouses`;
const individualWarehouseUrlBase = id => `${warehousesUrlBase}/${id}`;
const inputsWarehousesUrl = id => `${individualWarehouseUrlBase(id)}/inputs`;
const individialInputWarehousesUrl = (warehouseId, inputId) => `${inputsWarehousesUrl(warehouseId)}/${inputId}`;
const transferWarehousesUrl = id => `${individualWarehouseUrlBase(id)}/transfer`;
const individualTransfer = id => `${warehousesUrlBase}/transfer/${id}`;
const transactionsWarehousesUrl = id => `${individualWarehouseUrlBase(id)}/transactions?page=1&size=99999&sort=date,desc`;
const activitiesActual = id => `${coreServicesApiUrl}/v1/activities/actual/${id}/info`;

interface StockResponse {
  product: IndexedName;
  balance: number;
}

export const createWarehouses = (payload: WarehouseDTO): Observable<any> => {
  return axios.post(warehousesUrlBase, payload).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const transferWarehouses = (payload: WarehouseTransferDTO): Observable<any> => {
  return axios.post(transferWarehousesUrl(payload.originId), payload).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const deleteIndividualTransfer = (transferId: UUID): Observable<any> => {
  return axios.delete<any>(individualTransfer(transferId)).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const updateWarehouses = (payload: WarehouseDTO): Observable<any> => {
  return axios.put(individualWarehouseUrlBase(payload.id), payload).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const deleteWarehouses = (payload: UUID): Observable<any> => {
  return axios.delete(individualWarehouseUrlBase(payload)).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const getWarehouses = (companyId: UUID): Observable<WarehouseResponse[]> => {
  return axios.get<V4Page<WarehouseResponse>>(warehouseUrl(companyId)).pipe(
    map(response => {
      return response.data.content;
    })
  );
};

export const getStockByWarehouses = (warehouse_id: UUID): Observable<StockResponse[]> => {
  return axios.get<StockResponse[]>(stockUrl(warehouse_id)).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const getTransactions = (warehouse_id: UUID): Observable<V4Page<WarehouseTransactionResponse>> => {
  return axios.get<V4Page<WarehouseTransactionResponse>>(transactionsWarehousesUrl(warehouse_id)).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const getIndividualStockByWarehouses = (warehouse_id: UUID): Observable<WarehouseStockResponse[]> => {
  return axios.get<WarehouseStockResponse[]>(stockUrl(warehouse_id)).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const getCompanyStockByWarehouses = (companyId: UUID): Observable<WarehouseStockResponse[]> => {
  return axios.get<WarehouseStockResponse[]>(stockCompanyUrl(companyId)).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const getInfoActivitiesActual = (idActivity: UUID): Observable<WarehouseActivityResponse> => {
  return axios.get<WarehouseActivityResponse>(activitiesActual(idActivity)).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const getIndividialInputWarehousesUrl = (warehouseId: UUID, inputId: UUID): Observable<WarehouseInputDTO> => {
  return axios.get<WarehouseInputDTO>(individialInputWarehousesUrl(warehouseId, inputId)).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const deleteIndividialInputWarehousesUrl = (warehouseId: UUID, inputId: UUID): Observable<WarehouseInputDTO> => {
  return axios.delete<WarehouseInputDTO>(individialInputWarehousesUrl(warehouseId, inputId)).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const postIndividialInputWarehousesUrl = (warehouseId: UUID, payload: WarehouseInputDTO): Observable<WarehouseInputDTO> => {
  return axios.post<WarehouseInputDTO>(inputsWarehousesUrl(warehouseId), payload).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const putIndividialInputWarehousesUrl = (warehouseId: UUID, payload: WarehouseInputDTO): Observable<WarehouseInputDTO> => {
  return axios.put<WarehouseInputDTO>(individialInputWarehousesUrl(warehouseId, payload.id), payload).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const getVendors = (companyId: UUID): Observable<{ content: WarehouseVendorResponse[] }> => {
  return axios.get<{ content: WarehouseVendorResponse[] }>(vendorUrl(companyId)).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const postVendor = (payload: WarehouseVendorResponse): Observable<WarehouseVendorResponse> => {
  return axios.post<WarehouseVendorResponse>(newVendorUrl, payload).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const updateVendor = (payload: WarehouseVendorResponse): Observable<WarehouseVendorResponse> => {
  return axios.put<WarehouseVendorResponse>(getVendorUrl(payload.id), payload).pipe(
    map(response => {
      return response.data;
    })
  );
};
