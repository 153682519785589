import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ControlStrategyCard from 'st-protector-icons/Minor/ControlStrategyCard';
import SvgDiseaseRisk from 'st-protector-icons/Minor/DiseaseRisk';
import SvgMapExportCard from 'st-protector-icons/Minor/ExportMapCard';
import FieldMonitoringCard from 'st-protector-icons/Minor/FieldMonitoringCard';
import SvgReportCard from 'st-protector-icons/Minor/ReportCard';
import SvgReportConsolidatedCard from 'st-protector-icons/Minor/ReportConsolidatedCard';
import SvgReportDetailedCard from 'st-protector-icons/Minor/ReportDetailedCard';
import SvgSampleCard from 'st-protector-icons/Minor/SampleCard';
import SvgPinSampleCard from 'st-protector-icons/Minor/SvgPinSampleCard';
import SvgPinSamplePointsCard from 'st-protector-icons/Minor/SvgPinSamplePointsCard';
import { useRulesToShowReports } from '../report-drawer.component.hook';
import { ReportOptions } from '../report-options.component';
import { ReportsTypes } from '../reports-types.enum';
import ReportButton from './report-button.component';

interface ReportDrawerMonitoringAndSamplingButtons {
  setReportToOpen: (reportType: ReportsTypes) => void;
}

export const ReportDrawerMonitoringAndSamplingButtons = ({ setReportToOpen }: ReportDrawerMonitoringAndSamplingButtons) => {
  const [openPopConfirm, setOpenPopConfirm] = useState<ReportsTypes>();

  const [t] = useTranslation();
  const reportsRules = useRulesToShowReports();

  return (
    <>
      <div className='st-report-drawer__subtitle-group' data-testid='report-subtitle'>
        <STTypo type={TypeOptions.P2} color='#696F88' uppercase>
          <STTypo>{t('report.title.group.monitoring')}</STTypo>
        </STTypo>
      </div>

      {/* Disease risk */}
      {reportsRules[ReportsTypes.DISEASE_RISK] && (
        <ReportButton
          className='st-report-drawer__item-sample'
          setReportToOpen={setReportToOpen}
          text={t('report.disease_risk.name')}
          type={ReportsTypes.DISEASE_RISK}
          icon={<SvgDiseaseRisk fill='#EE5B3A' />}
          testId='btn-disease-risk-report'
        />
      )}

      {/* Farm scouting */}
      {reportsRules[ReportsTypes.SCOUTING] && (
        <ReportButton
          className='st-report-drawer__item-scouting'
          setReportToOpen={setReportToOpen}
          text={t('report.scouting.name')}
          type={ReportsTypes.SCOUTING}
          icon={<SvgSampleCard />}
        />
      )}

      {/* Organization scouting */}
      {reportsRules[ReportsTypes.MONITORING_CONSOLIDATED] && (
        <ReportButton
          className='st-report-drawer__report-consolidated'
          setReportToOpen={setReportToOpen}
          text={t('report.report_consolidated.name')}
          type={ReportsTypes.MONITORING_CONSOLIDATED}
          icon={<SvgReportConsolidatedCard />}
        />
      )}

      {/* Farm scouting points */}
      {reportsRules[ReportsTypes.SAMPLE] && (
        <ReportButton
          className='st-report-drawer__item-sample'
          setReportToOpen={setReportToOpen}
          text={t('report.sample.name')}
          type={ReportsTypes.SAMPLE}
          icon={<SvgPinSampleCard fill='#705BC1' />}
        />
      )}

      {/* Organization scouting points */}
      {reportsRules[ReportsTypes.MONITORING_DETAILED] && (
        <ReportButton
          className='st-report-drawer__report-detailed'
          setReportToOpen={setReportToOpen}
          text={t('report.report_detailed.name')}
          type={ReportsTypes.MONITORING_DETAILED}
          icon={<SvgReportDetailedCard />}
        />
      )}

      {/* Farm dashboard */}
      {reportsRules[ReportsTypes.PROPERTY] && (
        <ReportButton
          className='st-report-drawer__item-property'
          setReportToOpen={setReportToOpen}
          text={t('report.property.name')}
          type={ReportsTypes.PROPERTY}
          icon={<SvgReportCard />}
        />
      )}

      {/* Scouting routes */}
      {reportsRules[ReportsTypes.TRACKING] && (
        <ReportButton
          className='st-report-drawer__item-property'
          setReportToOpen={setReportToOpen}
          text={t('report.tracking.name')}
          type={ReportsTypes.TRACKING}
          icon={<SvgMapExportCard />}
        />
      )}

      {/* Sampling points import export */}
      {reportsRules[ReportsTypes.SAMPLING_IMPORT] && (
        <ReportOptions
          openPopConfirm={openPopConfirm}
          importFunction={() => setReportToOpen(ReportsTypes.SAMPLING_IMPORT)}
          importText={`${t('report.import.sampling.name')}`}
          exportFunction={() => setReportToOpen(ReportsTypes.SAMPLING)}
          exportText={`${t('report.export.sampling')}`}>
          <ReportButton
            className='st-report-drawer__item-sample_points'
            setReportToOpen={setOpenPopConfirm}
            text={t('report.sample_points.name')}
            type={ReportsTypes.SAMPLING}
            icon={<SvgPinSamplePointsCard />}
          />
        </ReportOptions>
      )}

      {/* Sampling points */}
      {reportsRules[ReportsTypes.SAMPLING] && !reportsRules[ReportsTypes.SAMPLING_IMPORT] && (
        <ReportButton
          className='st-report-drawer__item-sample_points'
          setReportToOpen={setReportToOpen}
          text={t('report.sample_points.name')}
          type={ReportsTypes.SAMPLING}
          icon={<SvgPinSamplePointsCard />}
        />
      )}

      {/* Visit report */}
      {reportsRules[ReportsTypes.VISIT] && (
        <ReportButton
          className='st-report-drawer__item-visit'
          setReportToOpen={setReportToOpen}
          text={t('report.visit.name')}
          type={ReportsTypes.VISIT}
          icon={<FieldMonitoringCard />}
        />
      )}

      {/* Control Strategy report */}
      {reportsRules[ReportsTypes.CONTROL_STRATEGIES] && (
        <ReportButton
          className='st-report-drawer__item-control_strategy '
          setReportToOpen={setReportToOpen}
          text={t('report.types.CONTROL_STRATEGIES')}
          type={ReportsTypes.CONTROL_STRATEGIES}
          icon={<ControlStrategyCard />}
        />
      )}
    </>
  );
};
