import { generateUUID } from 'core/utils/functions';
import type { Assignee } from 'entities/assignee/assignee.models';
import { useParams } from 'react-router';
import { useRoutingPlanConfigurationStore } from '../routes-configuration.zustand';
import type { PostHarvestConfiguration } from './post-harvest.models';

export const useParsePostHarvestStore = (assignees: Assignee[]) => {
  const { selectedInspectionGroups, priorities, selectedDays, assigneesGroups, assigneesGroupsProductivity, sampling } =
    useRoutingPlanConfigurationStore();

  const { companyId } = useParams();

  const assigneesGroupsList = assigneesGroups?.map(group => ({
    id: group.groupId,
    name: group.groupName,
    monitoring_per_day: assigneesGroupsProductivity.value,
    assignees: group.assigneesIds.map(assigneeId => ({
      id: assigneeId,
      name: assignees?.find(assignee => assignee?.id === assigneeId)?.name
    }))
  }));

  const parsePostHarvestStore: PostHarvestConfiguration = {
    id: generateUUID(),
    company_id: companyId ?? '',
    days: selectedDays,
    assignee_groups: assigneesGroupsList,
    inspection_groups_ids: selectedInspectionGroups,
    last_cut_days_interval: {
      min: priorities?.rangeDays?.min,
      max: priorities?.rangeDays?.max
    },
    days_within_next_activity: priorities?.postHarvestDays,
    prioritize_renovation_areas: priorities?.withRenovationArea,
    sampling_points: {
      type: sampling?.samplingType,
      points_factor: sampling?.quantityByArea,
      minimum_ratio: sampling?.percentToCompleted
    }
  };

  return parsePostHarvestStore;
};
