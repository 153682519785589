import type { SetMiddleOrClickedImageIndexProps } from './protector-photoswipe.models';

export const setMiddleOrClickedImageIndex = ({
  clickedImageIndex,
  middle,
  photosLength
}: SetMiddleOrClickedImageIndexProps): number | undefined => {
  if (typeof clickedImageIndex === 'number') {
    if (photosLength && photosLength > 5) {
      if (clickedImageIndex <= 3) return clickedImageIndex;

      return middle;
    }

    return clickedImageIndex;
  }

  if (typeof middle === 'number') {
    return middle;
  }

  return undefined;
};
