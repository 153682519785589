import type { Action, UUID } from 'core/utils/basic.models';
import type { CreateStaticPointCallback } from 'pages/static-points/static-points-drawer/redux/static-points-drawer.models';
import type {
  AreasDiffResponse,
  IntegratePerfectFlightPayload,
  JDOCAuthentication,
  JDOCFarmsResponse,
  JDOCOrganizationsResponse,
  JDOCPayloadDiffResponse,
  JDOCPaylodAreasDiff,
  JDOCPaylodDiffAreas,
  JDOCPaylodFarmsOrganization,
  JDOCPaylodPostAreasDiff,
  JDOCPermissions,
  PerfectFlightPayloadAplications,
  PerfectFlightPayloadAreasDiff,
  PerfectFlightProperty,
  TrapViewAccessProperty,
  TrapViewIntegrateExternal,
  TrapViewIntegratePayload,
  TrapViewProperty,
  TrapviewDevice,
  TrapviewInstallDevice,
  TrapviewInstallPayload
} from './integrations.models';

export const IntegrationsActionsTypes = {
  GET_AUTHENTICATE_JDOC: '[Integrations] Get Authenticate JDOC',
  GET_AUTHENTICATE_JDOC_SUCCESS: '[Integrations] Get Authenticate JDOC Success',
  GET_AUTHENTICATE_JDOC_FAILURE: '[Integrations] Get Authenticate JDOC Failure',

  GET_AUTHENTICATE_PERFECT_FLIGHT: '[Integrations] Get Authenticate Perfect Flight',
  GET_AUTHENTICATE_PERFECT_FLIGHT_SUCCESS: '[Integrations] Get Authenticate Perfect Flight Success',
  GET_AUTHENTICATE_PERFECT_FLIGHT_FAILURE: '[Integrations] Get Authenticate Perfect Flight Failure',

  INTEGRATE_PERFECT_FLIGHT: '[Integrations] Integrate Perfect Flight',
  INTEGRATE_PERFECT_FLIGHT_SUCCESS: '[Integrations] Integrate Perfect Flight Success',
  INTEGRATE_PERFECT_FLIGHT_FAILURE: '[Integrations] Integrate Perfect Flight Failure',

  REVOKE_PERFECT_FLIGHT: '[Integrations] Revoke Perfect Flight',
  REVOKE_PERFECT_FLIGHT_SUCCESS: '[Integrations] Revoke Perfect Flight Success',
  REVOKE_PERFECT_FLIGHT_FAILURE: '[Integrations] Revoke Perfect Flight Failure',

  LOAD_PERFECT_FLIGHT_PROPERTY_LIST: '[Integrations] Load Perfect Flight Property List',
  LOAD_PERFECT_FLIGHT_PROPERTY_LIST_SUCCESS: '[Integrations] Load Perfect Flight Property List Success',
  LOAD_PERFECT_FLIGHT_PROPERTY_LIST_FAILURE: '[Integrations] Load Perfect Flight Property List Failure',

  AUTHENTICATE_JDOC: '[Integrations] Authenticate JDOC',
  AUTHENTICATE_JDOC_SUCCESS: '[Integrations] Authenticate JDOC Success',
  AUTHENTICATE_JDOC_FAILURE: '[Integrations] Authenticate JDOC Failure',

  REVOKE_ACCESS_JDOC: '[Integrations] Revoke Access JDOC ',
  REVOKE_ACCESS_JDOC_SUCCESS: '[Integrations] Revoke Access JDOC Success',
  REVOKE_ACCESS_JDOC_FAILURE: '[Integrations] Revoke Access JDOC Failure',

  GET_PERMISSIONS_JDOC: '[Integrations] Get Permissions JDOC',
  GET_PERMISSIONS_JDOC_SUCCESS: '[Integrations] Get Permissions JDOC Success',
  GET_PERMISSIONS_JDOC_FAILURE: '[Integrations] Get Permissions JDOC Failure',

  POST_PERMISSIONS_JDOC: '[Integrations] Post Permissions JDOC',
  POST_PERMISSIONS_JDOC_SUCCESS: '[Integrations] Post Permissions JDOC Success',
  POST_PERMISSIONS_JDOC_FAILURE: '[Integrations] Post Permissions JDOC Failure',

  GET_ORGANIZATIONS_JDOC: '[Integrations] Get Organizations JDOC',
  GET_ORGANIZATIONS_JDOC_SUCCESS: '[Integrations] Get Organizations JDOC Success',
  GET_ORGANIZATIONS_JDOC_FAILURE: '[Integrations] Get Organizations JDOC Failure',

  GET_FARMS_BY_ORGANIZATIONS_JDOC: '[Integrations] Get Farms By Organizations JDOC',
  GET_FARMS_BY_ORGANIZATIONS_JDOC_SUCCESS: '[Integrations] Get Farms By Organizations JDOC Success',
  GET_FARMS_BY_ORGANIZATIONS_JDOC_FAILURE: '[Integrations] Get Farms By Organizations JDOC Failure',

  GET_AREAS_DIFF_JDOC: '[Integrations] Get Areas Diff JDOC',
  GET_AREAS_DIFF_JDOC_SUCCESS: '[Integrations] Get Areas Diff JDOC Success',
  GET_AREAS_DIFF_JDOC_FAILURE: '[Integrations] Get Areas Diff JDOC Failure',

  POST_AREAS_DIFF_JDOC: '[Integrations] Post Areas Diff JDOC',
  POST_AREAS_DIFF_JDOC_SUCCESS: '[Integrations] Post Areas Diff JDOC Success',
  POST_AREAS_DIFF_JDOC_FAILURE: '[Integrations] Post Areas Diff JDOC Failure',

  GET_AREAS_DIFF_PERFECT_FLIGHT: '[Integrations] Get Areas Diff Perfect Flight',
  GET_AREAS_DIFF_PERFECT_FLIGHT_SUCCESS: '[Integrations] Get Areas Diff Perfect Flight Success',
  GET_AREAS_DIFF_PERFECT_FLIGHT_FAILURE: '[Integrations] Get Areas Diff Perfect Flight Failure',

  GET_UPDATE_DIFF: '[Integrations] Get Update Diff JDOC',
  GET_UPDATE_DIFF_SUCCESS: '[Integrations] Get Update Diff JDOC Success',
  GET_UPDATE_DIFF_FAILURE: '[Integrations] Get Update Diff JDOC Failure',

  RESET_INTEGRATION_ERRORS: '[Integrations] Reset Integration Errors',

  GET_PERFECT_FLIGHT_APPLICATIONS: '[Integrations] Get Perfect Flight Applications',
  GET_PERFECT_FLIGHT_APPLICATIONS_SUCCESS: '[Integrations] Get Perfect Flight Applications Success',
  GET_PERFECT_FLIGHT_APPLICATIONS_FAILURE: '[Integrations] Get Perfect Flight Applications Failure',

  AUTHENTICATE_TRAP_VIEW_EXTERNAL: '[Integrations] Authenticate Trap View External',
  AUTHENTICATE_TRAP_VIEW_EXTERNAL_SUCCESS: '[Integrations] Authenticate Trap View External Success',
  AUTHENTICATE_TRAP_VIEW_EXTERNAL_FAILURE: '[Integrations] Authenticate Trap View External Failure',

  GET_AUTHENTICATE_TRAP_VIEW: '[Integrations] Get Authenticate Trap View',
  GET_AUTHENTICATE_TRAP_VIEW_SUCCESS: '[Integrations] Get Authenticate Trap View Success',
  GET_AUTHENTICATE_TRAP_VIEW_FAILURE: '[Integrations] Get Authenticate Trap View Failure',

  GET_TRAP_VIEW_ACCESS: '[Integrations] Get Access Trap View',
  GET_TRAP_VIEW_ACCESS_SUCCESS: '[Integrations] Get Access Trap View Success',
  GET_TRAP_VIEW_ACCESS_FAILURE: '[Integrations] Get Access Trap View Failure',

  AUTHENTICATE_TRAP_VIEW: '[Integrations] Authenticate Trap View',
  AUTHENTICATE_TRAP_VIEW_SUCCESS: '[Integrations] Authenticate Trap View Success',
  AUTHENTICATE_TRAP_VIEW_FAILURE: '[Integrations] Authenticate Trap View Failure',

  REVOKE_TRAP_VIEW: '[Integrations] Revoke Trap View',
  REVOKE_TRAP_VIEW_SUCCESS: '[Integrations] Revoke Trap View Success',
  REVOKE_TRAP_VIEW_FAILURE: '[Integrations] Revoke Trap View Failure',

  GET_TRAP_VIEW_DEVICES: '[Integrations] Get Trap View Devices',
  GET_TRAP_VIEW_DEVICES_SUCCESS: '[Integrations] Get Trap View Devices Success',
  GET_TRAP_VIEW_DEVICES_FAILURE: '[Integrations] Get Trap View Devices Failure',

  GET_TRAP_VIEW_BINDING: '[Integrations] Get Trap View Binding',
  GET_TRAP_VIEW_BINDING_SUCCESS: '[Integrations] Get Trap View Binding Success',
  GET_TRAP_VIEW_BINDING_FAILURE: '[Integrations] Get Trap View Binding Failure',

  INSTALL_TRAP_VIEW: '[Integrations] Install Trap View Devices',
  INSTALL_TRAP_VIEW_SUCCESS: '[Integrations] Install Trap View Devices Success',
  INSTALL_TRAP_VIEW_FAILURE: '[Integrations] Install Trap View Devices Failure'
};

export const LoadPerfectFlightPropertyList = (propertyId: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.LOAD_PERFECT_FLIGHT_PROPERTY_LIST,
    payload: propertyId
  };
};

export const LoadPerfectFlightPropertyListSuccess = (payload: PerfectFlightProperty[]): Action<PerfectFlightProperty[]> => {
  return {
    type: IntegrationsActionsTypes.LOAD_PERFECT_FLIGHT_PROPERTY_LIST_SUCCESS,
    payload
  };
};

export const LoadPerfectFlightPropertyListFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.LOAD_PERFECT_FLIGHT_PROPERTY_LIST_FAILURE,
    payload: error
  };
};

export const GetAuthenticatePerfectFlight = (id: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.GET_AUTHENTICATE_PERFECT_FLIGHT,
    payload: id
  };
};

export const GetAuthenticatePerfectFlightSuccess = (authenticated: boolean): Action<boolean> => {
  return {
    type: IntegrationsActionsTypes.GET_AUTHENTICATE_PERFECT_FLIGHT_SUCCESS,
    payload: authenticated
  };
};

export const GetAuthenticatePerfectFlightFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.GET_AUTHENTICATE_PERFECT_FLIGHT_FAILURE,
    payload: error
  };
};

export const IntegratePerfectFlight = (payload: IntegratePerfectFlightPayload): Action<IntegratePerfectFlightPayload> => {
  return {
    type: IntegrationsActionsTypes.INTEGRATE_PERFECT_FLIGHT,
    payload
  };
};

export const IntegratePerfectFlightSuccess = () => {
  return {
    type: IntegrationsActionsTypes.INTEGRATE_PERFECT_FLIGHT_SUCCESS
  };
};

export const IntegratePerfectFlightFailure = (error: string) => {
  return {
    type: IntegrationsActionsTypes.INTEGRATE_PERFECT_FLIGHT_FAILURE,
    payload: error
  };
};

export const RevokePerfectFlight = (propertyId: UUID) => {
  return {
    type: IntegrationsActionsTypes.REVOKE_PERFECT_FLIGHT,
    payload: propertyId
  };
};

export const RevokePerfectFlightSuccess = () => {
  return {
    type: IntegrationsActionsTypes.REVOKE_PERFECT_FLIGHT_SUCCESS
  };
};

export const RevokePerfectFlightFailure = (error: string) => {
  return {
    type: IntegrationsActionsTypes.REVOKE_PERFECT_FLIGHT_FAILURE,
    payload: error
  };
};

export const GetAuthenticateJDOC = (id: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.GET_AUTHENTICATE_JDOC,
    payload: id
  };
};

export const GetAuthenticateJDOCSuccess = (result: JDOCAuthentication): Action<JDOCAuthentication> => {
  return {
    type: IntegrationsActionsTypes.GET_AUTHENTICATE_JDOC_SUCCESS,
    payload: result
  };
};

export const GetAuthenticateJDOCFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.GET_AUTHENTICATE_JDOC_FAILURE,
    payload: error
  };
};

export const AuthenticateJDOC = (url: string, propertyId: UUID): Action<{ url: string; propertyId: UUID }> => {
  return {
    type: IntegrationsActionsTypes.AUTHENTICATE_JDOC,
    payload: { url, propertyId }
  };
};

export const AuthenticateJDOCSuccess = (result: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.AUTHENTICATE_JDOC_SUCCESS,
    payload: result
  };
};

export const AuthenticateJDOCFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.AUTHENTICATE_JDOC_FAILURE,
    payload: error
  };
};

export const RevokeAccessJDOC = (data: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.REVOKE_ACCESS_JDOC,
    payload: data
  };
};

export const RevokeAccessJDOCSuccess = (): Action => {
  return {
    type: IntegrationsActionsTypes.REVOKE_ACCESS_JDOC_SUCCESS
  };
};

export const RevokeAccessJDOCFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.REVOKE_ACCESS_JDOC_FAILURE,
    payload: error
  };
};

export const GetPermissionsJDOC = (id: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.GET_PERMISSIONS_JDOC,
    payload: id
  };
};

export const GetPermissionsJDOCSuccess = (result: JDOCPermissions): Action<JDOCPermissions> => {
  return {
    type: IntegrationsActionsTypes.GET_PERMISSIONS_JDOC_SUCCESS,
    payload: result
  };
};

export const GetPermissionsJDOCFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.GET_PERMISSIONS_JDOC_FAILURE,
    payload: error
  };
};

export const PostPermissionsJDOC = (id: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.POST_PERMISSIONS_JDOC,
    payload: id
  };
};

export const PostPermissionsJDOCSuccess = (): Action => {
  return {
    type: IntegrationsActionsTypes.POST_PERMISSIONS_JDOC_SUCCESS
  };
};

export const PostPermissionsJDOCFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.POST_PERMISSIONS_JDOC_FAILURE,
    payload: error
  };
};

export const GetOrganizationsJDOC = (id: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.GET_ORGANIZATIONS_JDOC,
    payload: id
  };
};

export const GetOrganizationsJDOCSuccess = (result: JDOCOrganizationsResponse[]): Action<JDOCOrganizationsResponse[]> => {
  return {
    type: IntegrationsActionsTypes.GET_ORGANIZATIONS_JDOC_SUCCESS,
    payload: result
  };
};

export const GetOrganizationsJDOCFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.GET_ORGANIZATIONS_JDOC_FAILURE,
    payload: error
  };
};

export const GetFarmsByOrganizationJDOC = (ids: JDOCPaylodFarmsOrganization): Action<JDOCPaylodFarmsOrganization> => {
  return {
    type: IntegrationsActionsTypes.GET_FARMS_BY_ORGANIZATIONS_JDOC,
    payload: ids
  };
};

export const GetFarmsByOrganizationJDOCSuccess = (result: JDOCFarmsResponse[]): Action<JDOCFarmsResponse[]> => {
  return {
    type: IntegrationsActionsTypes.GET_FARMS_BY_ORGANIZATIONS_JDOC_SUCCESS,
    payload: result
  };
};

export const GetFarmsByOrganizationJDOCFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.GET_FARMS_BY_ORGANIZATIONS_JDOC_FAILURE,
    payload: error
  };
};

export const GetAreasDiffJDOC = (ids: JDOCPaylodAreasDiff): Action<JDOCPaylodAreasDiff> => {
  return {
    type: IntegrationsActionsTypes.GET_AREAS_DIFF_JDOC,
    payload: ids
  };
};

export const GetAreasDiffJDOCSuccess = (result: AreasDiffResponse[]): Action<AreasDiffResponse[]> => {
  return {
    type: IntegrationsActionsTypes.GET_AREAS_DIFF_JDOC_SUCCESS,
    payload: result
  };
};

export const GetAreasDiffJDOCFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.GET_AREAS_DIFF_JDOC_FAILURE,
    payload: error
  };
};

export const PostAreasDiffJDOC = (ids: JDOCPaylodPostAreasDiff): Action<JDOCPaylodPostAreasDiff> => {
  return {
    type: IntegrationsActionsTypes.POST_AREAS_DIFF_JDOC,
    payload: ids
  };
};

export const PostAreasDiffJDOCSuccess = (): Action => {
  return {
    type: IntegrationsActionsTypes.POST_AREAS_DIFF_JDOC_SUCCESS
  };
};

export const PostAreasDiffJDOCFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.POST_AREAS_DIFF_JDOC_FAILURE,
    payload: error
  };
};

export const GetUpdateDiffJDOC = (diff: JDOCPaylodDiffAreas): Action<JDOCPaylodDiffAreas> => {
  return {
    type: IntegrationsActionsTypes.GET_UPDATE_DIFF,
    payload: diff
  };
};

export const GetUpdateDiffJDOCSuccess = (result: JDOCPayloadDiffResponse): Action<JDOCPayloadDiffResponse> => {
  return {
    type: IntegrationsActionsTypes.GET_UPDATE_DIFF_SUCCESS,
    payload: result
  };
};

export const GetUpdateDiffJDOCFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.GET_UPDATE_DIFF_FAILURE,
    payload: error
  };
};

export const ResetIntegrationErrors = () => {
  return {
    type: IntegrationsActionsTypes.RESET_INTEGRATION_ERRORS
  };
};

export const GetPerfectFlightAreasDiff = (payload: PerfectFlightPayloadAreasDiff) => {
  return {
    type: IntegrationsActionsTypes.GET_AREAS_DIFF_PERFECT_FLIGHT,
    payload
  };
};

export const GetPerfectFlightAreasDiffSuccess = (payload: any[]) => {
  return {
    type: IntegrationsActionsTypes.GET_AREAS_DIFF_PERFECT_FLIGHT_SUCCESS,
    payload
  };
};

export const GetPerfectFlightAreasDiffFailure = (error: string) => {
  return {
    type: IntegrationsActionsTypes.GET_AREAS_DIFF_PERFECT_FLIGHT_FAILURE,
    payload: error
  };
};

export const GetPerfectFlightApplications = (payload: PerfectFlightPayloadAplications) => {
  return {
    type: IntegrationsActionsTypes.GET_PERFECT_FLIGHT_APPLICATIONS,
    payload
  };
};

export const GetPerfectFlightApplicationsSuccess = (payload: any[]) => {
  return {
    type: IntegrationsActionsTypes.GET_PERFECT_FLIGHT_APPLICATIONS_SUCCESS,
    payload
  };
};

export const GetPerfectFlightApplicationsFailure = (error: string) => {
  return {
    type: IntegrationsActionsTypes.GET_PERFECT_FLIGHT_APPLICATIONS_FAILURE,
    payload: error
  };
};

export const AuthenticateTrapViewExternal = (payload: TrapViewIntegrateExternal): Action<TrapViewIntegrateExternal> => {
  return {
    type: IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_EXTERNAL,
    payload
  };
};

export const AuthenticateTrapViewExternalSuccess = (payload: TrapViewProperty): Action<TrapViewProperty> => {
  return {
    type: IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_EXTERNAL_SUCCESS,
    payload
  };
};

export const AuthenticateTrapViewExternalFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_EXTERNAL_FAILURE,
    payload: error
  };
};

export const GetAuthenticateTrapView = (payload: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.GET_AUTHENTICATE_TRAP_VIEW,
    payload
  };
};

export const GetAuthenticateTrapViewSuccess = (payload: TrapViewProperty): Action<TrapViewProperty> => {
  return {
    type: IntegrationsActionsTypes.GET_AUTHENTICATE_TRAP_VIEW_SUCCESS,
    payload
  };
};

export const GetAuthenticateTrapViewFailure = (error: string) => {
  return {
    type: IntegrationsActionsTypes.GET_AUTHENTICATE_TRAP_VIEW_FAILURE,
    payload: error
  };
};

export const GetAccessTrapView = (payload: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.GET_TRAP_VIEW_ACCESS,
    payload
  };
};

export const GetAccessTrapViewSuccess = (payload: TrapViewAccessProperty): Action<TrapViewAccessProperty> => {
  return {
    type: IntegrationsActionsTypes.GET_TRAP_VIEW_ACCESS_SUCCESS,
    payload
  };
};

export const GetAccessTrapViewFailure = (error: string) => {
  return {
    type: IntegrationsActionsTypes.GET_TRAP_VIEW_ACCESS_FAILURE,
    payload: error
  };
};

export const AuthenticateTrapView = (payload: TrapViewIntegratePayload): Action<TrapViewIntegratePayload> => {
  return {
    type: IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW,
    payload
  };
};

export const AuthenticateTrapViewSuccess = (payload: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_SUCCESS,
    payload
  };
};

export const AuthenticateTrapViewFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_FAILURE,
    payload: error
  };
};

export const RevokeTrapView = (payload: string) => {
  return {
    type: IntegrationsActionsTypes.REVOKE_TRAP_VIEW,
    payload
  };
};

export const RevokeTrapViewSuccess = (payload: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.REVOKE_TRAP_VIEW_SUCCESS,
    payload
  };
};

export const RevokeTrapViewFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.REVOKE_TRAP_VIEW_FAILURE,
    payload: error
  };
};

export const GetTrapViewDevices = (payload: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.GET_TRAP_VIEW_DEVICES,
    payload
  };
};

export const GetTrapViewDevicesSuccess = (payload: TrapviewDevice[]): Action<TrapviewDevice[]> => {
  return {
    type: IntegrationsActionsTypes.GET_TRAP_VIEW_DEVICES_SUCCESS,
    payload
  };
};

export const GetTrapViewDevicesFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.GET_TRAP_VIEW_DEVICES_FAILURE,
    payload: error
  };
};

export const GetTrapViewBinding = (payload: UUID): Action<UUID> => {
  return {
    type: IntegrationsActionsTypes.GET_TRAP_VIEW_BINDING,
    payload
  };
};

export const GetTrapViewBindingSuccess = (payload: string[]): Action<string[]> => {
  return {
    type: IntegrationsActionsTypes.GET_TRAP_VIEW_BINDING_SUCCESS,
    payload
  };
};

export const GetTrapViewBindingFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.GET_TRAP_VIEW_BINDING_FAILURE,
    payload: error
  };
};

export const InstallTrapView = (
  trapviewDevice: TrapviewInstallDevice,
  callback: CreateStaticPointCallback
): Action<TrapviewInstallPayload> => {
  return {
    type: IntegrationsActionsTypes.INSTALL_TRAP_VIEW,
    payload: { trapviewDevice, callback }
  };
};

export const InstallTrapViewSuccess = (): Action => {
  return {
    type: IntegrationsActionsTypes.INSTALL_TRAP_VIEW_SUCCESS
  };
};

export const InstallTrapViewFailure = (error: string): Action<string> => {
  return {
    type: IntegrationsActionsTypes.INSTALL_TRAP_VIEW_FAILURE,
    payload: error
  };
};
