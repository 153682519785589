import type { Action } from 'core/utils/basic.models';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
  LoadConfigReportsFailure,
  LoadConfigReportsSuccess,
  LoadReportsFailure,
  LoadReportsSuccess,
  PowerBIReportsActionsTypes
} from './powerbi-reports.actions';
import type { ConfigReportsRequestType, ReportsRequestType } from './powerbi-reports.models';
import { getConfigsReports, getReports } from './powerbi-reports.service';

export const handleLoadPowerBIReports = action$ =>
  action$.pipe(
    ofType(PowerBIReportsActionsTypes.LOAD_REPORTS),
    map((action: Action<ReportsRequestType>) => action.payload),
    concatMap((payload: ReportsRequestType) =>
      getReports(payload.organizationId, payload.configID).pipe(
        map(response => {
          return LoadReportsSuccess(response);
        }),
        catchError(err => of(LoadReportsFailure(err)))
      )
    )
  );

export const handleLoadConfigPowerBIReports = action$ =>
  action$.pipe(
    ofType(PowerBIReportsActionsTypes.LOAD_CONFIG_REPORTS),
    map((action: Action<ConfigReportsRequestType>) => action.payload),
    concatMap((payload: ConfigReportsRequestType) =>
      getConfigsReports(payload.organizationId).pipe(
        map(response => {
          return LoadConfigReportsSuccess(response);
        }),
        catchError(err => of(LoadConfigReportsFailure(err)))
      )
    )
  );
