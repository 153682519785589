import type { Dictionary } from 'config/types';
import type { Action, IndexedName, Page, PageableQuery, UUID } from 'core/utils/basic.models';
import type { MethodologyEntity } from 'entities/methodology/methodology.models';
import type { IndicatorDTO, Phenomenon } from 'entities/phenomenon/phenomenon.models';
import type { Season } from 'entities/season/season.models';
import type { SoilType } from 'pages/disease-risk/disease-risk.models';
import type { EntityState } from 'redux-ngrx-entity';
import type { Nullable } from '../../core/core.models';

export interface PropertyDiagnosticBreakdown {
  propertyId: UUID;
  diagnosticBreakdown: DiagnosticBreakdown;
}

export type PropertyPage = Page<Property>;
export type LoadPropertiesType = (pageableQuery: PageableQuery) => Action<PageableQuery>;
export type LoadPropertiesSuccessType = (propertyPage: PropertyPage) => Action<PropertyPage>;

export type LoadPropertyDiagnosticBreakdownSuccessType = (
  propertyDiagnosticBreakdown: PropertyDiagnosticBreakdown
) => Action<PropertyDiagnosticBreakdown>;
export interface LoadPropertyPhenomenaPayload {
  seasonIds: UUID[];
  propertyId: UUID;
  areaIds: UUID[];
}
export type LoadPropertyDiagnosticBreakdownFailureType = (propertyPage: PropertyPage) => Action<PropertyPage>;

export type LoadPropertyType = (propertyId: UUID, companyId: UUID) => Action<{ propertyId: UUID; companyId: UUID }>;

export type LoadPropertySuccessType = (property: Property) => Action<Property>;

export enum BreakdownType {
  REGION = 'REGION',
  AREA = 'AREA_COUNT'
}

export interface DiagnosticBreakdown {
  acceptance: number;
  control: number;
  damage: number;
  undetermined: number;
  spray: number;
  valid_on: string;
  breakdown_type: BreakdownType;
}

export interface Property extends IndexedName {
  id: UUID;
  name: string;
  company_id: UUID;
  root_region_id: UUID;
  seasons: Season[];
  last_visit: number;
  total_area: number;
  area_count: number;
  diagnostic_breakdown?: DiagnosticBreakdown;
  country?: string;
}

export interface SeasonAreasInDate {
  date: Nullable<string>;
  content: CurrentSeasonArea[];
}

export interface PropertyState extends EntityState<Property> {
  page: PropertyPage;
  isLoading: boolean;
  error: string | null;
  isLoaded: boolean;
  loadingDiagnostic: boolean;
  propertyTime: string;
  isLoadingTime: boolean;
  currentAreaSeasons: CurrentSeasonArea[];
  seasonAreasInDate: SeasonAreasInDate;
  seasonAreasFromActiveSeasons: Dictionary<CurrentSeasonArea[]>;
  isLoadingCurrentAreaSeasons: boolean;
  isLoadedSeasonAreas: boolean;
  isLoadingPropertyData: boolean;
  isLoadingPropertyPhenomena: boolean;
  isLoadedPropertyAreaInfo: boolean;
  propertyPhenomenons: Phenomenon[];
  propertyIndicators: IndicatorDTO[];
  propertyMethodologies: MethodologyEntity[];
  isIndicatorPressureLoading: boolean;
  propertyData: PropertyInfoAddress | null;
}

export interface PropertyTime {
  currentDateTimeUTC: string;
  timeZone: string;
  referencePoint: {
    type: string;
    coordinates: number[];
  };
}

export interface PropertyInfoAddressPoints {
  type: string;
  coordinates: number[];
}

export interface PropertyInfoAddressDTO {
  address: string | null;
  city: string;
  country: string;
  created_at: string;
  created_by_id: string;
  crn: string;
  deleted: boolean;
  fields_updated_at: string;
  id: string;
  last_updated_by_id: string;
  name: string;
  org_id: string;
  place_id: string | null;
  reference_point: PropertyInfoAddressPoints;
  regulatory_zone: string;
  root_region_id: string;
  state: string;
  time_zone: string;
  updated_at: string;
  wait_response: boolean;
  zip_code: string | null;
}

export interface PropertyInfoAddress {
  address: string | null;
  city: string;
  companyId: string;
  country: string;
  createdAt: string;
  createdById: string;
  crn: string;
  deleted: boolean;
  fieldsUpdatedAt: string;
  id: string;
  lastUpdatedById: string;
  name: string;
  placeId: string | null;
  referencePoint: PropertyInfoAddressPoints;
  regulatoryZone: string;
  rootRegionId: string;
  state: string;
  timeZone: string;
  updatedAt: string;
  waitResponse: boolean;
  zipCode: string | null;
}

export interface CurrentSeasonArea {
  areaId: UUID;
  areaInHectares: number;
  cropId: UUID;
  emergencyDate: string;
  id: UUID;
  methodologyId: UUID;
  plantingDate: string;
  seasonId: UUID;
  varieties: UUID[];
  varietyName: string;
  endsAt: string;
  startsAt: string;
  harvestingDate?: string;
}

export interface DataAvailabityResponse {
  unavailable_scouting_days: string[];
}

export interface GetCurrentSeasonAreaPayload {
  propertyId: UUID;
  allowPastSeasons?: boolean;
  date?: string;
}

export interface FieldSeason {
  id: UUID;
  areaId: UUID;
  seasonId: UUID;
  varieties: UUID[];
  endsAt?: string;
}

export interface IndicatorPressureResponse {
  area_id: string;
  indexes: Index[];
}

interface Index {
  indicator: IndicatorDTO;
  result: number;
  liss: number | null;
  severity: Severity | null;
}

export interface Severity {
  label: string;
  color: string;
  agronomic_severity: string;
  type: string;
}

export interface IndicatorPressurePayload {
  propertyId: UUID;
  indicatorIds: UUID[];
  methodologyIds: UUID[];
}

export interface AdvancedConfigurationValues {
  soilType: SoilType[];
  antePreviousCrop: string | null;
  yieldPotential: number | undefined;
  nitrogenLevel: number | undefined;
}
