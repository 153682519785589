import GroupInputList from 'components/SD-group-input-list/group-input-list';
import GroupInput from 'components/SD-group-input/group-input';
import RangeInput from 'components/SD-range-input/range-input';
import { map, size } from 'lodash';
import type { ConfigurationParameterDTO } from 'pages/borer-risk/borer-risk-map/borer-risk.models';
import { ConfigurationParameterType } from 'pages/borer-risk/borer-risk-map/borer-risk.models';
import { useFieldClusterConfigurationStore } from 'pages/field-clustering/configuration/field-clustering-configuration.zustand';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRangeByParameter, removeGroup } from '../../parameters.utils';

interface StripTypeParameterProps {
  parameter: ConfigurationParameterDTO;
}

interface RangeInputValue {
  min: number;
  max: number;
}

const StripTypeParameter = ({ parameter }: StripTypeParameterProps): JSX.Element => {
  const [t] = useTranslation();
  const { rangeUnit, max } = getRangeByParameter(parameter);
  const { riskParameters, setRiskParameters } = useFieldClusterConfigurationStore();
  const { id } = parameter;
  const defaultGroups = riskParameters?.[id]?.value;
  const emptyState = { '0': { min: undefined, max: undefined } };
  const [groups, setGroups] = useState<Record<string, RangeInputValue>>(defaultGroups || emptyState);

  const onAdd = () => {
    const index = size(groups);
    setGroups({
      ...groups,
      [index]: { min: undefined, max: undefined }
    });
  };

  const handleOnChangeValue = (groupId: string, limit: string, value?: number) => {
    setRiskParameters({
      ...riskParameters,
      [id]: {
        type: ConfigurationParameterType.STRIP,
        value: {
          ...riskParameters?.[id]?.value,
          [groupId]: {
            ...riskParameters?.[id]?.value?.[groupId],
            [limit]: value?.toString()
          }
        }
      }
    });
  };

  const handleOnClose = (groupId: string) => {
    const newGroups = removeGroup(groups, groupId);
    setGroups(newGroups);

    setRiskParameters({
      ...riskParameters,
      [id]: {
        type: ConfigurationParameterType.STRIP,
        value: newGroups
      }
    });
  };

  return (
    <GroupInputList onAdd={onAdd} containerWidth='400px'>
      {map(groups, (group, index) => (
        <GroupInput
          key={index}
          title={t('pages.field_clustering.steps.parameters.group', { index: +index + 1 })}
          hasCloseButton={size(groups) > 1}
          onClose={() => handleOnClose(index)}>
          <RangeInput
            defaultMinValue={group.min}
            defaultMaxValue={group.max}
            rangeUnit={rangeUnit}
            max={max}
            onChangeMinValue={value => handleOnChangeValue(index, 'min', value)}
            onChangeMaxValue={value => handleOnChangeValue(index, 'max', value)}
          />
        </GroupInput>
      ))}
    </GroupInputList>
  );
};

export default StripTypeParameter;
