import type { STMarker } from 'core/shared/map/map.models';
import type { DateISOString, FeatureFlags, Scouter, UUID } from 'core/utils/basic.models';
import type { MonitoringDetailPhenomenon } from 'pages/timeline/monitoring.models';
import type { Spray } from 'pages/timeline/timeline.models';
import type { Dictionary } from 'config/types';

export interface PluviometerData {
  id: UUID;
  reads: PluviometerRead[];
  correspondingAreas: UUID[];
}

interface PluviometerRead {
  date: DateISOString;
  value: number;
}

export interface FixedPointOutputData {
  id: UUID;
  company_id?: UUID;
  templateId?: UUID;
  type: FixedPointType;
  last_event_type?: FixedPointActionType;
  last_event_date?: DateISOString;
  name: string;
  fixed_point_marker: STMarker;
  corresponding_areas?: UUID[];
  short_description: string;
  created_at?: DateISOString;
  analytic_results_histogram: AnalyticResultsHistogram;
  analytic_context?: AnalyticContext;
  inspection_layout?: InspectionLayout;
  deleted?: boolean;
  time_for_renewal?: number;
  phenomenon_name?: string;
  phenomenon_id?: UUID;
}

export interface FixedPointGroup {
  id: UUID;
  name: string;
  samples: FixedPointOutputData[];
  phenomena?: MonitoringDetailPhenomenon;
  groupType: FixedPointType;
}
export enum SamplesLoadState {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  EMPTY = 'EMPTY'
}

export enum FixedPointActionType {
  INSTALL = 'INSTALL',
  UNINSTALL = 'UNINSTALL',
  REINSTALL = 'REINSTALL',
  READ = 'READ',
  RENEWAL = 'RENEWAL',
  DELETE = 'DELETE'
}

type LocalizedStrings = Record<'localized_strings', Record<string, string>>;

export interface GetPointResponse {
  analytic_context_dto?: AnalyticContext;
  area_ids: UUID[];
  class_name: string;
  clazz: string;
  cohese: boolean;
  company_id: UUID;
  created_at?: DateISOString;
  description: LocalizedStrings;
  icon_type: FixedPointIconType;
  id: UUID;
  inspection_layout_dto?: InspectionLayout;
  instalation_steps: string[];
  instaled_at: DateISOString;
  location: Record<'lat' | 'lng', number>;
  modified_at: DateISOString;
  name: LocalizedStrings;
  parent_id: string | undefined;
  property_id: UUID | undefined;
  sampling_behaviour?: SamplingBehaviour;
  time_for_renewal?: number;
  inspection_layout?: InspectionLayout;
}
export interface EditParams {
  account: {
    name: string;
    id: UUID;
  };
  payload: GetPointResponse;
  type: string;
  name?: string;
}

export enum FixedPointType {
  GENERIC = 'GENERIC',
  PLUVIOMETER = 'PLUVIOMETER',
  TRAP = 'TRAP'
}

export enum FixedPointIconType {
  BUS_STOP = 'BUS_STOP',
  DANGER = 'DANGER',
  CAMERA = 'CAMERA',
  PLUVIOMETER = 'PLUVIOMETER',
  GARAGE = 'GARAGE',
  HQ = 'HQ',
  AIRPORT = 'AIRPORT',
  FLAG = 'FLAG',
  GAS_STATION = 'GAS_STATION',
  POLE = 'POLE',
  WARNING = 'WARNING',
  RESTING_AREA = 'RESTING_AREA',
  BRIDGE = 'BRIDGE',
  GATE = 'GATE',
  WEATHER_STATION = 'WEATHER_STATION'
}

export enum SamplingBehaviour {
  ACCUMULATIVE = 'ACCUMULATIVE',
  ALWAYSRESET = 'ALWAYSRESET',
  SINGLE_USE = 'SINGLE_USE'
}

export interface FixedPointPluviometerTemplate {
  id: UUID;
  name: string;
  description?: string;
}

export enum DiagnosticTypes {
  AGRONOMIC = 'AGRONOMIC'
}

export enum Accessory {
  EDIT = 'edit',
  DELETE = 'delete',
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export enum ActionTypes {
  EDIT = 'edit',
  DELETE = 'delete',
  ENABLE = 'enable',
  DISABLE = 'disable',
  CREATE = 'create'
}

enum DevelopmentPhase {}

interface Label {
  localized_strings: Dictionary<string>;
}

export interface Threshold {
  color: string;
  start_value: number;
  end_value: number;
  label: Label;
}

export interface Diagnostic {
  development_phases: DevelopmentPhase[] | null;
  label: Label | null;
  thresholds: Threshold[];
  type: DiagnosticTypes;
}

export interface CustomIndicator {
  diagnostics: Diagnostic[];
  feature_flags?: FeatureFlags;
  indicator_id: UUID;
  id?: UUID;
}

export interface AnalyticContext {
  custom_indicator_dtos?: CustomIndicator[];
  id: UUID;
}

interface FixedPointComponent {
  class_name: string;
  id: UUID;
  name: string;
  priority: number;
}

export interface FixedPointPhenomenon extends FixedPointComponent {
  ordered_components: FixedPointComponent[];
}

export interface FixedPointCategory extends FixedPointComponent {
  ordered_phenomenons: FixedPointPhenomenon[];
}

interface InspectionGroup {
  name: string;
  ordered_categories: FixedPointCategory[];
  priority: number;
}

export interface InspectionLayout {
  ordered_inspection_groups: InspectionGroup[];
}

export interface FixedPointInfo {
  analytic_context?: AnalyticContext;
  icon_type: FixedPointIconType;
  id: UUID;
  inspection_layout?: InspectionLayout;
  property_id?: UUID;
  company_id?: UUID;
  image?: string;
  template_id?: UUID;
  location: {
    lat: number;
    lng: number;
  };
  name: string;
  sampling_behaviour?: SamplingBehaviour;
  single_use?: boolean;
  short_description: string;
  time_for_renewal?: number;
  created_at: DateISOString;
  area_ids?: UUID[];
  class_name: string;
  deleted?: boolean;
}

export interface HistogramIndicators {
  id: UUID;
  liss_value: number;
  name: string;
  value: number;
}

export interface HistogramInstance {
  indicators: HistogramIndicators[];
  local_date: DateISOString;
  phenological_stage: string | null;
  reset: boolean;
  sampling_behaviour: SamplingBehaviour | null;
  sprays: Spray[];
  count?: number;
}

export interface AnalyticResultsHistogram {
  last_event?: DateISOString;
  result_histogram: HistogramInstance[];
}

export interface FixedPoint {
  analytic_results_histogram: AnalyticResultsHistogram;
  installed_at?: DateISOString;
  last_event?: DateISOString;
  scouter?: Scouter;
  static_point: FixedPointInfo;
  type?: FixedPointActionType;
  template_id?: UUID;
  last_renewal?: DateISOString;
  validity?: number;
  uninstalled_at?: string;
}

export enum FixedPointsFieldEnum {
  CATALOGUE = 'CATALOGUE',
  GENERIC = 'GENERIC',
  PLUVIOMETER = 'PLUVIOMETER',
  UNNINSTALLED = 'UNNINSTALLED',
  ALL = 'ALL'
}

export enum PluviometerName {
  DEFAULT = 'pluviometer.default.template.name'
}
export interface FixedPointCreation {
  account_name: string;
  account_id: UUID;
  static_point_id: UUID;
  company_id: UUID;
  name: string;
  property_id: UUID;
  type?: FixedPointType;
  icon_type: FixedPointIconType;
  location: {
    lat: number;
    lng: number;
  };
  cohese?: boolean;
  description: string;
  area_id?: UUID;
  template_id?: UUID;
  parent_id?: UUID;
  language?: string;
  instaled_at?: string;
  sampling_behavior?: SamplingBehaviour;
  timeForRenewal?: number;
  area_ids?: UUID[];
}

export interface RainGaugeCreation {
  id: UUID;
  template_id: UUID;
  company_id: UUID;
  name: string;
  language: string;
  property_id: UUID;
  icon_type: FixedPointIconType.PLUVIOMETER;
  location: Location;
  instaled_at: string;
  area_ids: UUID[];
}

export interface GenericPointCreation {
  id: string;
  company_id: string;
  name: string;
  language: string;
  description: string;
  property_id: string;
  icon_type: FixedPointIconType;
  location: Location;
  instaled_at: string;
}

interface Location {
  lat: number;
  lng: number;
}

export interface ToggleStaticPointRequestParams {
  account: {
    name: string;
    id: UUID;
  };
  payload: {
    id: UUID;
    type?: FixedPointType;
    company_id: UUID;
    property_id: UUID;
    action_type: FixedPointActionType;
  };
}

export interface ParsedFixedPointData {
  groups: FixedPointGroup[];
  count: number;
}

export interface GetSamplingPhotoProps {
  propertyId?: string;
  photoId: string;
}
