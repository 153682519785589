import { useQueries, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { CORE_SERVICES_API_URL } from '../../../config/constants';
import type { Crop } from '../../utils/basic.models.ts';
import { getLocalizedCropName } from '../../utils/language.ts';

const catalogURL = `${CORE_SERVICES_API_URL}/v2/catalog/crops?country=`;

const getPropertiesInfo = async (orgId: string) => {
  const response = await axios.get<PropertiesInfoList>(`${CORE_SERVICES_API_URL}/v2/orgs/${orgId}/properties`);
  return response.data.content;
};

const getCropInfo = async (country: string) => {
  const response = await axios.get<CropList>(`${catalogURL}${country}`);
  return response.data.content;
};

export interface CropInfo {
  id: string;
  name: {
    language: string;
    name: string;
  }[];
  image_url: string;
  label: string | null;
  localized_crops: {
    id: string;
    name: string;
  }[];
  parent_id: string;
  specialization_type: string | null;
}

export interface CropList {
  content: CropInfo[];
}

export interface PropertiesInfo {
  id: string;
  regulatory_zone: string;
}

export interface PropertiesInfoList {
  content: PropertiesInfo[];
}

export const useGetCropInfo = () => {
  const { companyId } = useParams();
  const regulatoryZones = useGetPropertiesInfo(companyId ?? '');

  const results = useQueries({
    queries: regulatoryZones.map(regulatoryZone => ({
      queryKey: ['useGetCropInfo', regulatoryZone],
      queryFn: () => getCropInfo(regulatoryZone),
      cacheTime: 1000 * 60
    }))
  });

  return results.flatMap(result => result.data ?? []);
};

export const useGetPropertiesInfo = (orgId: string) => {
  const { data } = useQuery({
    queryKey: ['useGetPropertiesInfo', orgId],
    queryFn: () => getPropertiesInfo(orgId),
    cacheTime: 1000 * 60
  });

  const regulatoryZones = Array.from(new Set(data?.map(property => property.regulatory_zone))).filter(zone => zone !== null);
  return regulatoryZones ?? [];
};

export const findCropLocalizedName = (cropList: CropInfo[], cropInfo?: Crop) => {
  if (!cropInfo) return '';

  const currentCrop = cropList?.find(cropValue => cropValue.localized_crops[0]?.id === cropInfo?.id);

  let localizetCrop = '';
  if (currentCrop?.name) {
    localizetCrop = getLocalizedCropName(currentCrop);
  }

  return localizetCrop;
};
