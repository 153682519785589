import { notification } from 'antd';
import type { Action, UUID } from 'core/utils/basic.models';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap } from 'rxjs/operators';
import { IntegrationKey } from '../../pages/integrations/shared/models';
import {
  AuthenticateJDOCFailure,
  AuthenticateJDOCSuccess,
  AuthenticateTrapViewExternalFailure,
  AuthenticateTrapViewExternalSuccess,
  AuthenticateTrapViewFailure,
  AuthenticateTrapViewSuccess,
  GetAccessTrapViewFailure,
  GetAccessTrapViewSuccess,
  GetAreasDiffJDOCFailure,
  GetAreasDiffJDOCSuccess,
  GetAuthenticateJDOCFailure,
  GetAuthenticateJDOCSuccess,
  GetAuthenticatePerfectFlightFailure,
  GetAuthenticatePerfectFlightSuccess,
  GetAuthenticateTrapViewFailure,
  GetAuthenticateTrapViewSuccess,
  GetFarmsByOrganizationJDOCFailure,
  GetFarmsByOrganizationJDOCSuccess,
  GetOrganizationsJDOCFailure,
  GetOrganizationsJDOCSuccess,
  GetPerfectFlightApplicationsFailure,
  GetPerfectFlightApplicationsSuccess,
  GetPerfectFlightAreasDiffFailure,
  GetPerfectFlightAreasDiffSuccess,
  GetPermissionsJDOCFailure,
  GetPermissionsJDOCSuccess,
  GetTrapViewBindingFailure,
  GetTrapViewBindingSuccess,
  GetTrapViewDevicesFailure,
  GetTrapViewDevicesSuccess,
  GetUpdateDiffJDOCFailure,
  GetUpdateDiffJDOCSuccess,
  InstallTrapViewFailure,
  InstallTrapViewSuccess,
  IntegratePerfectFlightFailure,
  IntegratePerfectFlightSuccess,
  IntegrationsActionsTypes,
  LoadPerfectFlightPropertyListFailure,
  LoadPerfectFlightPropertyListSuccess,
  PostAreasDiffJDOCFailure,
  PostAreasDiffJDOCSuccess,
  PostPermissionsJDOCFailure,
  PostPermissionsJDOCSuccess,
  RevokeAccessJDOCFailure,
  RevokeAccessJDOCSuccess,
  RevokePerfectFlightFailure,
  RevokePerfectFlightSuccess,
  RevokeTrapViewFailure,
  RevokeTrapViewSuccess
} from './integrations.actions';
import type {
  IntegratePerfectFlightPayload,
  JDOCPaylodAreasDiff,
  JDOCPaylodDiffAreas,
  JDOCPaylodFarmsOrganization,
  JDOCPaylodPostAreasDiff,
  PerfectFlightPayloadAplications,
  PerfectFlightPayloadAreasDiff,
  TrapViewIntegrateExternal,
  TrapViewIntegratePayload,
  TrapviewInstallPayload
} from './integrations.models';
import { TrapviewInstallationError } from './integrations.models';
import {
  authenticateJDOC,
  getAccessTrapView,
  getAreasDiffJDOC,
  getAuthenticateJDOC,
  getAuthenticateStatusPerfectFlight,
  getAuthenticateTrapView,
  getFarmsByOrganizationsJDOC,
  getOrganizationsJDOC,
  getPerfectFlightApplications,
  getPerfectFlightAreasDiff,
  getPerfectFlightProperties,
  getPermissionsJDOC,
  getTrapViewBinding,
  getTrapViewDevices,
  postAreasDiffJDOC,
  postInstallTrapView,
  postIntegratePerfectFlight,
  postIntegrateTrapView,
  postIntegrateTrapViewExternal,
  postPermissionsJDOC,
  postUpdateDiffJDOC,
  revokeAccessJDOC,
  revokePerfectFlightAccess,
  revokeTrapView
} from './integrations.service';
import { mapResponseToAreaDiffResponse } from './integrations.utils';

export const handleAuthenticateJDOC = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.AUTHENTICATE_JDOC),
    map((action: Action) => action.payload),
    exhaustMap(({ url, propertyId }) => authenticateJDOC(url, propertyId)),
    map((result: any) => {
      return AuthenticateJDOCSuccess(result.data.url);
    }),
    catchError((error: string) => of(AuthenticateJDOCFailure(error)))
  );

export const handleRevokeAccessJDOC = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.REVOKE_ACCESS_JDOC),
    map((action: Action) => action.payload),
    exhaustMap((payload: UUID) => revokeAccessJDOC(payload)),
    map(() => {
      return RevokeAccessJDOCSuccess();
    }),
    catchError((error: string) => of(RevokeAccessJDOCFailure(error)))
  );

export const handleLoadPerfectFlightPropertyList = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.LOAD_PERFECT_FLIGHT_PROPERTY_LIST),
    map((action: Action) => action.payload),
    switchMap((payload: UUID) =>
      getPerfectFlightProperties(payload).pipe(
        map((result: any) => {
          return LoadPerfectFlightPropertyListSuccess(result.data.filter(d => d.plots_count > 0));
        }),
        catchError(err => {
          let errorMessage: string;

          if (err?.message?.includes('400')) {
            errorMessage = 'perfectFlight.get_failed';
          } else {
            errorMessage = 'perfectFlight.integrate_failed';
          }
          return of(LoadPerfectFlightPropertyListFailure(errorMessage));
        })
      )
    )
  );

export const handleIntegratePerfectFlight = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.INTEGRATE_PERFECT_FLIGHT),
    map((action: Action) => action.payload),
    switchMap((payload: IntegratePerfectFlightPayload) =>
      postIntegratePerfectFlight(payload).pipe(
        map(() => {
          return IntegratePerfectFlightSuccess();
        }),
        catchError(error => of(IntegratePerfectFlightFailure(error)))
      )
    )
  );

export const handleGetAuthenticatePerfectFlight = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_AUTHENTICATE_PERFECT_FLIGHT),
    map((action: Action) => action.payload),
    switchMap((payload: UUID) =>
      getAuthenticateStatusPerfectFlight(payload).pipe(
        map((result: any) => {
          return GetAuthenticatePerfectFlightSuccess(result.data);
        }),
        catchError(error => of(GetAuthenticatePerfectFlightFailure(error)))
      )
    )
  );

export const handleGetAuthenticateJDOC = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_AUTHENTICATE_JDOC),
    map((action: Action) => action.payload),
    exhaustMap((payload: UUID) => getAuthenticateJDOC(payload)),
    map((result: any) => {
      return GetAuthenticateJDOCSuccess(result.data);
    }),
    catchError((error: string) => of(GetAuthenticateJDOCFailure(error)))
  );

export const handleGetPermissionsJDOC = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_PERMISSIONS_JDOC),
    map((action: Action) => action.payload),
    exhaustMap((payload: UUID) => getPermissionsJDOC(payload)),
    map((result: any) => {
      return GetPermissionsJDOCSuccess(result.data);
    }),
    catchError((error: string) => of(GetPermissionsJDOCFailure(error)))
  );

export const handlePostPermissionsJDOC = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.POST_PERMISSIONS_JDOC),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return postPermissionsJDOC(payload).pipe(
        map(() => {
          return PostPermissionsJDOCSuccess();
        }),
        catchError(error => of(PostPermissionsJDOCFailure(error)))
      );
    })
  );

export const handleGetOrganizationsJDOC = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_ORGANIZATIONS_JDOC),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return getOrganizationsJDOC(payload).pipe(
        map(result => {
          return GetOrganizationsJDOCSuccess(result);
        }),
        catchError(error => of(GetOrganizationsJDOCFailure(error)))
      );
    })
  );

export const handleGetFarmsByOrganizationsJDOC = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_FARMS_BY_ORGANIZATIONS_JDOC),
    map((action: Action) => action.payload),
    concatMap((payload: JDOCPaylodFarmsOrganization) => {
      return getFarmsByOrganizationsJDOC(payload.organizationId, payload.propertyId).pipe(
        map(result => {
          return GetFarmsByOrganizationJDOCSuccess(result);
        }),
        catchError(error => of(GetFarmsByOrganizationJDOCFailure(error)))
      );
    })
  );

export const handleRevokePerfectFlight = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.REVOKE_PERFECT_FLIGHT),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return revokePerfectFlightAccess(payload).pipe(
        map(() => {
          return RevokePerfectFlightSuccess();
        }),
        catchError(error => of(RevokePerfectFlightFailure(error)))
      );
    })
  );
export const handleGetAreasDiffPerfectFlight = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_AREAS_DIFF_PERFECT_FLIGHT),
    map((action: Action) => action.payload),
    concatMap((payload: PerfectFlightPayloadAreasDiff) => {
      return getPerfectFlightAreasDiff(payload.propertyId, payload.pfFarmId).pipe(
        map(result => {
          return GetPerfectFlightAreasDiffSuccess(mapResponseToAreaDiffResponse(result.data, IntegrationKey.PERFECT_FLIGHT));
        }),
        catchError(error => of(GetPerfectFlightAreasDiffFailure(error)))
      );
    })
  );

export const handleGetAreasDiffJDOC = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_AREAS_DIFF_JDOC),
    map((action: Action) => action.payload),
    concatMap((payload: JDOCPaylodAreasDiff) => {
      return getAreasDiffJDOC(payload.organizationId, payload.farmId, payload.propertyId).pipe(
        map(result => {
          return GetAreasDiffJDOCSuccess(mapResponseToAreaDiffResponse(result, IntegrationKey.JOHN_DEERE));
        }),
        catchError(error => of(GetAreasDiffJDOCFailure(error)))
      );
    })
  );

export const handlePostAreasDiffJDOC = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.POST_AREAS_DIFF_JDOC),
    map((action: Action) => action.payload),
    concatMap((payload: JDOCPaylodPostAreasDiff) => {
      return postAreasDiffJDOC(payload.organizationId, payload.farmId, payload.propertyId, payload.data).pipe(
        map(() => {
          return PostAreasDiffJDOCSuccess();
        }),
        catchError(error => of(PostAreasDiffJDOCFailure(error)))
      );
    })
  );

export const handleGetUpdateDiffJDOC = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_UPDATE_DIFF),
    map((action: Action) => action.payload),
    concatMap((payload: JDOCPaylodDiffAreas) => {
      return postUpdateDiffJDOC(payload).pipe(
        map(result => {
          return GetUpdateDiffJDOCSuccess(result);
        }),
        catchError(error => of(GetUpdateDiffJDOCFailure(error)))
      );
    })
  );

export const handleGetPerfectFlightApplications = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_PERFECT_FLIGHT_APPLICATIONS),
    map((action: Action) => action.payload),
    concatMap((payload: PerfectFlightPayloadAplications) => {
      return getPerfectFlightApplications(payload.propertyId, payload.startDate, payload.endDate).pipe(
        map(result => {
          return GetPerfectFlightApplicationsSuccess(result.data);
        }),
        catchError(error => of(GetPerfectFlightApplicationsFailure(error)))
      );
    })
  );

export const handleGetAuthenticateTrapView = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_AUTHENTICATE_TRAP_VIEW),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return getAuthenticateTrapView(payload).pipe(
        map(result => {
          return GetAuthenticateTrapViewSuccess(result.data);
        }),
        catchError(error => of(GetAuthenticateTrapViewFailure(error)))
      );
    })
  );

export const handleGetAccessTrapView = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_TRAP_VIEW_ACCESS),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return getAccessTrapView(payload).pipe(
        map(result => {
          return GetAccessTrapViewSuccess(result.data);
        }),
        catchError(error => of(GetAccessTrapViewFailure(error)))
      );
    })
  );

export const handlePostIntegrateTrapViewExternal = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_EXTERNAL),
    map((action: Action) => action.payload),
    concatMap((payload: TrapViewIntegrateExternal) => {
      return postIntegrateTrapViewExternal(payload).pipe(
        map(result => {
          return AuthenticateTrapViewExternalSuccess(result.data);
        }),
        catchError(error => of(AuthenticateTrapViewExternalFailure(error)))
      );
    })
  );

export const handlePostIntegrateTrapView = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW),
    map((action: Action) => action.payload),
    concatMap((payload: TrapViewIntegratePayload) => {
      return postIntegrateTrapView(payload).pipe(
        map(result => {
          return AuthenticateTrapViewSuccess(result.data);
        }),
        catchError(error => of(AuthenticateTrapViewFailure(error)))
      );
    })
  );

export const handleRevokeTrapView = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.REVOKE_TRAP_VIEW),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return revokeTrapView(payload).pipe(
        map(result => {
          return RevokeTrapViewSuccess(result.data);
        }),
        catchError(error => of(RevokeTrapViewFailure(error)))
      );
    })
  );

export const handleGetTrapViewDevices = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_TRAP_VIEW_DEVICES),
    map((action: Action) => action.payload),
    concatMap((payload: string) => {
      return getTrapViewDevices(payload).pipe(
        map(result => {
          return GetTrapViewDevicesSuccess(result.data);
        }),
        catchError(error => of(GetTrapViewDevicesFailure(error)))
      );
    })
  );

export const handleGetTrapViewBinding = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.GET_TRAP_VIEW_BINDING),
    map((action: Action) => action.payload),
    concatMap((payload: string) => {
      return getTrapViewBinding(payload).pipe(
        map(result => {
          return GetTrapViewBindingSuccess(result.data);
        }),
        catchError(error => of(GetTrapViewBindingFailure(error)))
      );
    })
  );

export const handlePostInstallTrapView = action$ =>
  action$.pipe(
    ofType(IntegrationsActionsTypes.INSTALL_TRAP_VIEW),
    map((action: Action) => action.payload),
    concatMap((payload: TrapviewInstallPayload) => {
      return postInstallTrapView(payload.trapviewDevice).pipe(
        map(() => {
          notification.success(payload.callback.notification.success);
          payload.callback.tracking?.integratedTrapview();
          payload.callback.clearDrawerAndSelectStation();
          return InstallTrapViewSuccess();
        }),
        catchError(error => {
          if (error.response.data.includes(TrapviewInstallationError.NOT_FOUND_PEST_CODE))
            notification.error(payload.callback.notification.pestCodeError);
          else notification.error(payload.callback.notification.error);
          return of(InstallTrapViewFailure(error));
        })
      );
    })
  );
