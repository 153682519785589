import { filterProperties } from 'core/components/header/components/context-select/context-select.selectors';
import { selectSelectedCompany } from 'core/core.reducer';
import type { Selector } from 'core/core.selectors';
import { getSelectedPropertyId } from 'core/core.selectors';
import { isGrapeCrop } from 'entities/crop/crop.domain';
import type { MethodologyEntity } from 'entities/methodology/methodology.models';
import type { IndicatorDTO, Phenomenon } from 'entities/phenomenon/phenomenon.models';
import type { EntityState } from 'redux-ngrx-entity';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import type { Property, PropertyInfoAddress } from './property.models';

export const getPropertyPhenomenons = (state: AppState): Phenomenon[] => state.entities.property.propertyPhenomenons;
export const getPropertyIndicators = (state: AppState): IndicatorDTO[] => state.entities.property.propertyIndicators;
export const getPropertyMethodologies = (state: AppState): MethodologyEntity[] => state.entities.property.propertyMethodologies;
export const isPropertyPhenomenonsLoading = (state: AppState): boolean => state.entities.property.isLoadingPropertyPhenomena;
export const isIndicatorPressureLoading: Selector<boolean> = state => state.entities.property.isIndicatorPressureLoading;
export const getPropertyEntities: Selector<EntityState<Property>['entities']> = state => state.entities.property.entities;

export const getPropertyReferencePoint: Selector<PropertyInfoAddress['referencePoint'] | undefined> = state =>
  state.entities.property.propertyData?.referencePoint;

export const selectCurrentProperty = createSelector([getSelectedPropertyId, getPropertyEntities], (currentPropertyId, propertyEntities) =>
  currentPropertyId ? propertyEntities[currentPropertyId] : null
);

export const selectHasGrapeCrop = createSelector(selectCurrentProperty, currentProperty => {
  if (typeof currentProperty === 'string') {
    return false;
  }

  return currentProperty?.seasons?.some(s => isGrapeCrop(s.crop));
});

export const selectsTheFirstPropertyIdOfTheEntities = createSelector(getPropertyEntities, propertyEntities => {
  const propertyIds = Object.keys(propertyEntities);
  return propertyIds?.[0];
});

export const getIsLoadedPropertyAreaInfo: Selector<boolean> = state => state.entities.property.isLoadedPropertyAreaInfo;

export const filterPropertiesIds = createSelector(
  (state: AppState) => filterProperties(state),
  (state: AppState) => selectSelectedCompany(state.uiState.global),
  (properties, selectedCompanyId) => properties.filter(property => property.company_id === selectedCompanyId).map(p => p.id)
);
