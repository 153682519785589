import { Icon, Spin } from 'antd';
import { ALLOWED_REDIRECTS, C_LEVEL_REPORT, UNUSUAL_REDIRECT_LOCAL_STORAGE_KEY } from 'authentication/contants';
import { useDidMount } from 'beautiful-react-hooks';
import { LoginRequest } from 'core/services/auth/auth.actions';
import type React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import type { AppState } from 'redux/app-state';

interface ILoginOauth2 {
  // Redux State
  isLogged: boolean;
  // Redux Dispath
  LoginRequest$;
}

const LoginOauth2: React.FC<ILoginOauth2> = ({ isLogged, LoginRequest$ }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useDidMount(() => {
    if (!searchParams.get('code')) return;

    LoginRequest$(searchParams.get('code'));
  });

  useEffect(() => {
    if (isLogged || (localStorage.getItem('access_token') && localStorage.getItem('refresh_token'))) {
      const unusualRedirect = localStorage.getItem(UNUSUAL_REDIRECT_LOCAL_STORAGE_KEY);
      const allowedRedirect = ALLOWED_REDIRECTS.some(redirect => unusualRedirect?.includes(redirect));

      if (unusualRedirect && allowedRedirect) {
        navigate(unusualRedirect);
        localStorage.removeItem(UNUSUAL_REDIRECT_LOCAL_STORAGE_KEY);

        return;
      }

      const redirectToCLevelReport = sessionStorage.getItem(C_LEVEL_REPORT);

      if (redirectToCLevelReport) {
        navigate(redirectToCLevelReport);
        return;
      }

      navigate('/hall-of-companies');
    }
  }, [isLogged, navigate]);

  return (
    <div className='login-oauth2'>
      <Spin
        indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
        tip={t('pages.smart_spray.loading_info') !== 'pages.smart_spray.loading_info' ? t('pages.smart_spray.loading_info') : ''}
        spinning
        delay={0}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isLogged: state.uiState.auth.isLogged
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      LoginRequest$: LoginRequest
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginOauth2);
