const SvgCalendarGlyph = props => (
  <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2C12 1.44772 11.5523 1 11 1C10.4477 1 10 1.44772 10 2H6C6 1.44772 5.55228 1 5 1C4.44772 1 4 1.44772 4 2C2.34315 2 1 3.34315 1 5V12C1 13.6569 2.34315 15 4 15H12C13.6569 15 15 13.6569 15 12V5C15 3.34315 13.6569 2 12 2ZM4 4C4 4.55228 4.44772 5 5 5C5.55228 5 6 4.55228 6 4H10C10 4.55228 10.4477 5 11 5C11.5523 5 12 4.55228 12 4C12.5523 4 13 4.44772 13 5V7H3V5C3 4.44772 3.44772 4 4 4ZM4 13H12C12.5523 13 13 12.5523 13 12V9H3V12C3 12.5523 3.44772 13 4 13Z'
    />
  </svg>
);

export default SvgCalendarGlyph;
