const SvgResourceCenter = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M3.00007 17.7287C5.03014 21.2857 7.46624 21.2857 8.68428 21.2857H10.7144L10.3083 19.8629C8.68428 15.9874 4.75947 16.8988 3.00007 17.7287Z'
      fill='#696F88'
      stroke='#696F88'
      strokeWidth='2'
    />
    <path
      d='M20.9999 17.7287C18.9699 21.2857 16.5338 21.2857 15.3157 21.2857H13.2856L13.6917 19.8629C15.3157 15.9874 19.2405 16.8988 20.9999 17.7287Z'
      fill='#696F88'
      stroke='#696F88'
      strokeWidth='2'
    />
    <path d='M9 15L12.75 15H15' stroke='#696F88' strokeWidth='2' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 11.8293C14.1652 11.4175 15 10.3062 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.3062 9.83481 11.4175 11 11.8293V13.9C8.71776 13.4367 7 11.419 7 9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9C17 11.419 15.2822 13.4367 13 13.9V11.8293Z'
      fill='#696F88'
    />
    <mask id='path-5-inside-1_596_52500' fill='white'>
      <path fillRule='evenodd' clipRule='evenodd' d='M11 13H9V14H11V13ZM13 13V14H15V13H13Z' />
    </mask>
    <path
      d='M9 13V11H7V13H9ZM11 13H13V11H11V13ZM9 14H7V16H9V14ZM11 14V16H13V14H11ZM13 13V11H11V13H13ZM13 14H11V16H13V14ZM15 14V16H17V14H15ZM15 13H17V11H15V13ZM9 15H11V11H9V15ZM11 14V13H7V14H11ZM11 12H9V16H11V12ZM13 14V13H9V14H13ZM11 13V14H15V13H11ZM15 12H13V16H15V12ZM13 13V14H17V13H13ZM13 15H15V11H13V15Z'
      fill='#696F88'
      mask='url(#path-5-inside-1_596_52500)'
    />
    <path d='M4 10H6M12 1V3M7 5L6 4L5.5 3.5M17 5L18 4L18.5 3.5M18 10H20' stroke='#696F88' strokeWidth='2' />
  </svg>
);

export default SvgResourceCenter;
