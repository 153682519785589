import styled from 'styled-components';

export const StyledRangeInputContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
`;

export const StyledInputWrapper = styled.div`
  height: 100%;
  margin: 0px 8px;
`;

export const StyledNumericInput = styled.div<{ addAfter?: boolean }>`
  width: 80px;
  margin-right: ${({ addAfter }) => (addAfter ? '10px' : '0px')};
`;
