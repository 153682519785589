import { SidebarAccordionActionsTypes } from './sidebar-accordion.actions';
import type { SidebarAccordionState } from './sidebar-accordion.models';

const initialState: SidebarAccordionState = {
  currentScroll: 0
};

export default (state = initialState, action): SidebarAccordionState => {
  switch (action.type) {
    case SidebarAccordionActionsTypes.SET_CURRENT_SCROLL:
      return {
        ...state,
        currentScroll: action.payload
      };
    default:
      return state;
  }
};
