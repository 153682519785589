import type { QueryObserverResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import axiosObservable from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import { getSystemFlags } from 'core/core.selectors';
import { useQuery } from 'core/utils/data-store/tools';
import { chunk } from 'lodash';
import type { LeafhopperConfiguration } from 'pages/routes-configuration/leafhopper/leafhopper.models';
import { RoutersTypes } from 'pages/routes-configuration/post-harvest/post-harvest.models';
import { useGetStatesRoutesConfiguration } from 'pages/routes-configuration/queries/queries';
import { getAllSeasonAreasByProperty } from 'querys/season/season.query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { AppState } from 'redux/app-state';
import type { Observable } from 'rxjs';
import { getClusterByPhenonenaGroup } from './fields-clustering.functions';
import type { BatchDeleteInterface, FieldClusterInfoResult, FieldClusterProps } from './fields-clustering.query.models';
import { FieldClusterQueryType } from './fields-clustering.query.models';
import { getTrapDrawerIsVisible } from './fields-clustering.selectors';

const protectorApiUrl = PROTECTOR_API_URL ?? 'http://localhost:8080';
const ONE_HOUR_IN_MILLISECONDS = 3600000;
const INTERVAL_POOLING_SECONDS = 5000;

const getAllFieldsClusters = async (propertyId: string | null): Promise<FieldClusterProps[]> => {
  try {
    const response = await axios.get<FieldClusterProps[]>(`${protectorApiUrl}/v1/properties/${propertyId}/field-clusters`);
    return response.data;
  } catch {
    return [];
  }
};

export const useGetAllFieldsClusters = (
  propertyId: string | null,
  enabled = false,
  intervalPooling = INTERVAL_POOLING_SECONDS
): QueryObserverResult<FieldClusterProps[], unknown> => {
  const enableFieldClustering = !!useSelector(getSystemFlags)?.P40_22549_enableFieldClustering;
  const isTrapDrawer = useSelector(getTrapDrawerIsVisible);
  const [refetchInterval, setRefetchInterval] = useState(intervalPooling);

  return useQuery({
    queryKey: [FieldClusterQueryType.GET_ALL_FIELDS_CLUSTERS, propertyId, enableFieldClustering],
    queryFn: () => getAllFieldsClusters(propertyId),
    enabled: (isTrapDrawer && !!enableFieldClustering && !!propertyId) || (enabled && !!propertyId),
    staleTime: ONE_HOUR_IN_MILLISECONDS,
    refetchOnWindowFocus: 'always',
    refetchInterval,
    retry: (_count, error: AxiosError) => {
      setRefetchInterval(0);
      return error.request.status !== 404;
    }
  });
};

const createFieldCluster = async (props: {
  name: string;
  phenomenon_ids: string[];
  season_areas_ids: string[];
  property_id?: string;
  season_id: string;
  force_create?: boolean;
}): Promise<FieldClusterProps[]> => {
  const { force_create = false, ...body } = props;
  const response = await axios.post<FieldClusterProps[]>(`${protectorApiUrl}/v1/field-clusters?force_create=${force_create}`, body);

  return response.data;
};

export const useCreateFieldCluster = ({ force_create }: { force_create?: boolean }) => {
  const [t] = useTranslation();
  const { propertyId } = useParams();
  const season_id = useSelector((state: AppState) => state.uiState.global.selectedSeasons)?.[0];

  const {
    isLoading,
    mutate: postFieldCluster,
    isSuccess,
    error,
    reset
  } = useMutation(
    (body: { name: string; phenomenon_ids: string[]; season_areas_ids: string[] }) =>
      createFieldCluster({ ...body, property_id: propertyId!, season_id, force_create }),
    {
      onError: () => {
        notification.error({
          message: t('pages.field_clustering.map_result.notification.error.title'),
          description: t('pages.field_clustering.map_result.notification.error.message')
        });
      }
    }
  );
  return { isLoading, postFieldCluster, isSuccess, error, reset };
};

const getPhenomenaByClusters = async (propertyId?: string): Promise<Record<string, string[]>> => {
  const response = await axios.get<Record<string, string[]>>(`${protectorApiUrl}/v1/properties/${propertyId}/field-clusters/phenomenons`);

  return response.data;
};

export const useGetPhenomenaByClusters = ({
  enabled = false
}: {
  enabled: boolean;
}): QueryObserverResult<Record<string, string[]>, unknown> => {
  const { propertyId } = useParams();

  return useQuery({
    queryKey: [FieldClusterQueryType.GET_PHENOMENA_BY_CLUSTERS, propertyId],
    queryFn: () => getPhenomenaByClusters(propertyId),
    enabled: !!propertyId && enabled
  });
};

// band-aid solution
export const createPromiseFieldCluster = (props: {
  name: string;
  phenomenon_ids: string[];
  season_areas_ids: string[];
  property_id?: string;
  season_id: string;
  force_create?: boolean;
}): Observable<any> => {
  const { force_create = false, ...body } = props;

  return axiosObservable.post<FieldClusterProps[]>(`${protectorApiUrl}/v1/field-clusters?force_create=${force_create}`, body);
};

const getAllFieldClustersWithAreaIds = async (propertyId: string) => {
  const allFieldClusters = await getAllFieldsClusters(propertyId);
  const seasonAreas = await getAllSeasonAreasByProperty(propertyId);
  const fieldIdBySeasonAreaId: Record<string, string> = seasonAreas.reduce<Record<string, string>>((fieldBySeasonArea, seasonArea) => {
    return {
      ...fieldBySeasonArea,
      [seasonArea.id]: seasonArea.areaId
    };
  }, {});
  if (!allFieldClusters.length) {
    return seasonAreas.map(seasonArea => ({
      id: seasonArea.areaId,
      name: '',
      season_id: seasonArea.seasonId,
      property_id: propertyId,
      areas_ids: [seasonArea.areaId],
      phenomenon_ids: [],
      season_area_ids: [seasonArea.id]
    }));
  }
  return allFieldClusters.map(fieldCluster => {
    return {
      ...fieldCluster,
      areas_ids: fieldCluster.season_area_ids.map(seasonAreaId => fieldIdBySeasonAreaId[seasonAreaId]).filter(areaId => areaId)
    };
  });
};

export const useGetFieldClusterInfoByPhenomenaGroup = (
  propertyId: string | undefined,
  planType: RoutersTypes
): { fieldClusterInfoForPhenomena: FieldClusterInfoResult; isLoaded: boolean } => {
  const [data, setData] = useState<FieldClusterInfoResult>({
    allFieldClusters: [],
    fieldClusterInfo: {}
  });

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [planData, planStatus, __, planId, isPlanError, isLoadingPlan] = useGetStatesRoutesConfiguration(!isLoaded, planType);

  const isLeafhopper = planType === RoutersTypes.LEAFHOPPER;
  const planPhenomenaIds = (planData as LeafhopperConfiguration)?.field_cluster_phenomenons;
  const phenomenaIds = isLeafhopper ? planPhenomenaIds : [];

  const planIsLoaded = !!planData && !isLoadingPlan;
  const result = useQuery({
    queryKey: [FieldClusterQueryType.GET_CLUSTERS_BY_INSPECTION_GROUP, propertyId, planType],
    queryFn: async () => {
      const allFieldClusters = await getAllFieldClustersWithAreaIds(propertyId ?? '');
      return allFieldClusters;
    },
    enabled: !!propertyId && planType !== RoutersTypes.POST_HARVEST && planIsLoaded
  });

  useEffect(() => {
    if (result.data && !isLoaded) {
      const fieldClusterInfo = getClusterByPhenonenaGroup(phenomenaIds, result.data);
      const fieldClusterResult: FieldClusterInfoResult = {
        allFieldClusters: result.data,
        fieldClusterInfo
      };
      setData(fieldClusterResult);
      setIsLoaded(true);
    }
  }, [result, isLoaded]);

  return { fieldClusterInfoForPhenomena: data, isLoaded };
};

export const useGetAllFieldClusters = (propertyId: string | undefined, enabled: boolean) => {
  return useQuery({
    queryKey: [FieldClusterQueryType.GET_CLUSTERS_BY_INSPECTION_GROUP, propertyId],
    queryFn: async () => {
      const allFieldClusters = await getAllFieldClustersWithAreaIds(propertyId ?? '');
      return allFieldClusters;
    },
    enabled: !!propertyId && enabled
  });
};

const batchDeleteClusters = async (clusterIds: string[]): Promise<FieldClusterProps[]> => {
  try {
    const clusterChunks = chunk(clusterIds, 1000);
    const promises = clusterChunks.map(async clusters => {
      const response = await axios.post<FieldClusterProps[]>(`${protectorApiUrl}/v1/field-clusters/batch-delete`, {
        cluster_ids: clusters
      });
      return response.data;
    });

    const results = await Promise.all(promises);
    return results.flat();
  } catch {
    return [] as FieldClusterProps[];
  }
};

export const useDeleteBatchFieldClusters = (onSuccess: () => void, onError: () => void) => {
  const { mutate: handleBatchDeleteClusters, isLoading } = useMutation(
    ({ clusterIds }: BatchDeleteInterface) => batchDeleteClusters(clusterIds),
    {
      onSuccess,
      onError
    }
  );

  return {
    handleBatchDeleteClusters,
    isLoadingDelete: isLoading
  };
};
