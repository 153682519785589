const SvgCloseMinGlyph = props => (
  <svg width={props.size || 24} height={props.size || 24} viewBox='0 0 24 24' fill='none'>
    <path
      d='M19.6818 18.1454C20.1061 18.5697 20.1061 19.2576 19.6818 19.6818C19.2576 20.1061 18.5697 20.1061 18.1454 19.6818L12 13.5364L5.85455 19.6818C5.4303 20.1061 4.74245 20.1061 4.31819 19.6818C3.89394 19.2576 3.89394 18.5697 4.31819 18.1454L10.4636 12L4.31819 5.85455C3.89394 5.4303 3.89394 4.74245 4.31819 4.31819C4.74245 3.89394 5.4303 3.89394 5.85455 4.31819L12 10.4636L18.1454 4.31819C18.5697 3.89394 19.2576 3.89394 19.6818 4.31819C20.1061 4.74245 20.1061 5.4303 19.6818 5.85455L13.5364 12L19.6818 18.1454Z'
      fill={props.fill || '#c1c5c8'}
    />
  </svg>
);

export default SvgCloseMinGlyph;
