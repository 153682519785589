import { getCurrentLanguage } from 'core/utils/functions';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useUpdateMomentLocale = () => {
  const [t] = useTranslation();

  useEffect(() => {
    const currentLanguage = getCurrentLanguage();
    moment.locale(currentLanguage);
  }, [t]);
};

export default useUpdateMomentLocale;
