import type { IProfileDropdownProps } from '@cw-elements/profile-dropdown';
import { ProfileDropdown } from '@cw-elements/profile-dropdown';
import i18n from 'config/i18n';
import { SetInterfaceLanguage, SetUnitSystem } from 'core/core.actions';
import { Languages, getCurrentLanguage } from 'core/utils/functions';
import type { SegmentTrackingHookReturn } from 'core/utils/segment/useSegmentTracking';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type React from 'react';
import { Suspense, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { AppState } from 'redux/app-state';

interface ProfileDropdownComponent {
  handleLogout: () => void;
  SetUnitSystem$: (
    unitSystem: any,
    segmentTracking: SegmentTrackingHookReturn
  ) => {
    type: string;
    payload: any;
  };
  selectedCompanyName?: string;
  selectedCompanyId?: string;
  selectedPropertyId?: string;
  selectedPropertyName?: string;
}

const initialLanguage = Languages()[getCurrentLanguage().toUpperCase()] ? getCurrentLanguage().toUpperCase() : 'EN';

const supportedLanguages = [
  'en',
  'en-GB',
  'pt-BR',
  'es',
  'es-AR',
  'pl',
  'de',
  'fr',
  'hu',
  'it'
] as IProfileDropdownProps['availableLanguages'];

const metricSystem = ['metric', 'imperial'] as IProfileDropdownProps['availableUnitSystems'];

const ProfileDropdownComponent: React.FC<ProfileDropdownComponent> = ({
  handleLogout,
  SetUnitSystem$,
  selectedCompanyName,
  selectedCompanyId,
  selectedPropertyId,
  selectedPropertyName
}) => {
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

  const dispatch = useDispatch();

  const handleCurrentLanguage = key => {
    let languageKey = key;

    if (languageKey === 'en') languageKey = 'en-US';
    if (languageKey === 'en-GB') languageKey = 'en';

    setCurrentLanguage(languageKey);
    changeLanguage(languageKey.toLocaleLowerCase());
    localStorage.setItem('currentLanguage', languageKey);
  };

  const segmentTracking = useSegmentTracking();

  const handleCurrentUnitSystem = key => {
    SetUnitSystem$(key, segmentTracking);
    localStorage.setItem('currentUnitSystem', key);
  };

  const changeLanguage = lang => {
    if (lang.toUpperCase() !== currentLanguage) {
      const payload = {
        language: lang.toUpperCase(),
        previousLanguage: currentLanguage
      };
      segmentTracking.track(`User Settings - Changed Language`, payload);

      dispatch(SetInterfaceLanguage(lang.toLowerCase()));
    }

    i18n.changeLanguage(lang.toLowerCase());
  };

  return (
    <Suspense fallback=''>
      <ProfileDropdown
        availableLanguages={supportedLanguages}
        availableUnitSystems={metricSystem}
        onUnitSystemChange={handleCurrentUnitSystem}
        onLanguageChange={handleCurrentLanguage}
        onLogout={handleLogout}
      />
    </Suspense>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentUnitSystem: state.uiState.global.currentUnitSystem
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      SetUnitSystem$: SetUnitSystem
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdownComponent);
