import { Alert, Form, Tooltip, Typography } from 'antd';
import { ReactComponent as AddIcon } from 'assets/images/icon_add.svg';
import readFileAsDataURL from 'core/components/header/components/report/drawers/components/report-visit-add-logo/report-visit-add-logo.util';
import { generateUUID } from 'core/utils/functions';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../report.styles.less';

const imageMimeType = /image\/(png|jpg|jpeg)/i;
const imageMaxSize = 2010495;
const imageHeightMax = 401;
const imageWidthMax = 401;
interface Props {
  imageUrl: string | undefined;
  file: File | undefined;
  setFile: (file: File | undefined) => void;
  setRemoveDefaultLogo: (valid: boolean) => void;
  selectedLogo: boolean;
  setSelectedLogo: (isValid: boolean) => void;
  messageError: string | undefined;
  setMessageError: (value: string) => void;
}

const AddLogoVisitReport: React.FC<Props> = ({
  imageUrl,
  file,
  setFile,
  setRemoveDefaultLogo,
  selectedLogo,
  setSelectedLogo,
  messageError,
  setMessageError
}) => {
  const [t] = useTranslation();

  const [fileDataURL, setFileDataURL] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (imageUrl) {
      setFileDataURL(`${imageUrl}?${generateUUID()}`);
      setSelectedLogo(true);
    }

    return () => {
      if (imageUrl) {
        const uuid = generateUUID();
        setFileDataURL(imageUrl + uuid);
      }
    };
  }, [imageUrl, setFileDataURL, setSelectedLogo]);

  const onRemoveLogoHandler = useCallback(() => {
    setRemoveDefaultLogo(true);
    setSelectedLogo(false);
    setFile(undefined);
    setFileDataURL(undefined);
    setMessageError('');
  }, [setRemoveDefaultLogo, setSelectedLogo, setFile, setMessageError]);

  const changeLogoHandler = useCallback(
    ({ target }) => {
      if (messageError) {
        onRemoveLogoHandler();
      }

      if (!target.files?.length) {
        return;
      }

      async function doFileRead() {
        const result = await readFileAsDataURL(target.files[0]);
        if (result) {
          const currentFile = target.files[0];

          const img = new Image();

          img.src = window.URL.createObjectURL(currentFile);

          if (!currentFile.type.match(imageMimeType)) {
            setMessageError(t('modals.timeline_xray_report.select_file_error_format'));
            return;
          }

          img.onload = () => {
            if (currentFile.size > imageMaxSize) {
              setMessageError(t('modals.timeline_xray_report.select_file_error_size_mb'));
              return;
            }

            if (img.height > imageHeightMax || img.width > imageWidthMax) {
              setMessageError(t('modals.timeline_xray_report.select_file_error_size'));
              return;
            }

            setFile(currentFile);
            setFileDataURL(result);
            setSelectedLogo(true);
          };
        }
      }

      doFileRead();
    },
    [setFile, messageError, onRemoveLogoHandler, t, setSelectedLogo, setMessageError]
  );

  useEffect(() => {
    if (messageError) {
      setTimeout(() => {
        setMessageError('');
      }, 7000);
    }
  }, [messageError, setMessageError]);

  return (
    <Form.Item data-testid='report-container-select-logo' label={`${t('modals.timeline_xray_report.fields.advisor_company_logo')}`}>
      <>
        <div className='st-report-drawer__container_select_logo'>
          {selectedLogo ? (
            <div className='st-report-drawer__file_selected_container'>
              <img
                data-testid='st-file-selected-logo'
                className='file_selected_logo'
                src={fileDataURL}
                alt='preview'
                onError={onRemoveLogoHandler}
              />
              <Typography.Text className='file_selected_text'>{file?.name}</Typography.Text>
              <Tooltip placement='topRight' title={`${t('modals.timeline_xray_report.remove_logo_from_report')}`}>
                <AddIcon
                  data-testid='remove_file_selected_icon'
                  className='file_selected_icon'
                  width={15}
                  height={15}
                  onClick={onRemoveLogoHandler}
                />
              </Tooltip>
            </div>
          ) : (
            <div className='st-report-drawer__select_file_container'>
              <input
                type='file'
                className='select_file_input'
                id='selectFileUpload'
                accept='image/*'
                onChange={changeLogoHandler}
                data-testid='st-select-file-input'
              />

              <AddIcon className='select_file_add_icon' width={18} height={18} />
              <Typography.Text>{`${t('modals.timeline_xray_report.select_file')}`}</Typography.Text>
            </div>
          )}
        </div>
        {!!messageError && (
          <div data-testid='select-file-error-container' id='select-file-error'>
            <div className='st-report-drawer__file_selected_container_error_separator' />
            <Alert
              message={<div className='st-report-drawer__file_selected_container_error'>{messageError}</div>}
              type='error'
              showIcon
              closable
            />
          </div>
        )}
      </>
    </Form.Item>
  );
};

export default AddLogoVisitReport;
