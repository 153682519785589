import type { Action } from 'core/utils/basic.models';
import type {
  DeleteStationType,
  EditStationType,
  LoadSeveritiesSuccessType,
  LoadSeveritiesType,
  LoadStationSuccessType,
  LoadStationType,
  NoSeveritiesDataToLoadType,
  Station,
  ToggleStationType,
  UpsertStationSuccessType
} from './stations.models';

export const StationsActionsTypes = {
  LOAD_STATIONS: '[Stations] Load Stations',
  LOAD_STATIONS_SUCCESS: '[Stations] Load Stations Success',
  LOAD_STATIONS_FAILURE: '[Stations] Load Stations Failure',
  LOAD_SEVERITIES: '[Stations Severities] Load Stations Severities',
  NO_SEVERITIES_TO_LOAD: '[Stations Severities] No Stations Severities To Load',
  LOAD_SEVERITIES_SUCCESS: '[Stations Severities] Load Stations Success',
  LOAD_SEVERITIES_FAILURE: '[Stations Severities] Load Stations Failure',
  CREATE_STATION_SUCCESS: '[Stations] Create Station Success',
  DELETE_STATION: '[Stations] Delete Station',
  DELETE_STATION_SUCCESS: '[Stations] Delete Stations Success',
  TOGGLE_STATION: '[Stations] Toggle (enable/disable) Station',
  TOGGLE_STATION_SUCCESS: '[Stations] Toggle (enable/disable) Station Success',
  EDIT_STATION: '[Stations] Edit Station',
  EDIT_STATION_SUCCESS: '[Stations] Edit Stations Success',
  EDIT_STATION_FAILED: '[Stations] Edit Stations Failed',
  DELETE_TRAPVIEW_DEVICE: '[Stations] Delete Trapview Device'
};

export const LoadStations: LoadStationType = propertyId => {
  return {
    type: StationsActionsTypes.LOAD_STATIONS,
    payload: { propertyId }
  };
};

export const LoadStationsSuccess: LoadStationSuccessType = stations => {
  return {
    type: StationsActionsTypes.LOAD_STATIONS_SUCCESS,
    payload: stations
  };
};

export const LoadStationsFailure = (error: string): Action<string> => {
  return {
    type: StationsActionsTypes.LOAD_STATIONS_FAILURE,
    payload: error
  };
};

export const LoadAnalyticResultsHistogram: LoadSeveritiesType = ({ startDate, endDate, templateId, ids, allActions, clearCache }) => {
  return {
    type: StationsActionsTypes.LOAD_SEVERITIES,
    payload: { startDate, endDate, templateId, ids, allActions, clearCache }
  };
};

export const LoadAnalyticResultsHistogramSuccess: LoadSeveritiesSuccessType = ({ severities, allActions, allIds, clearCache }) => {
  return {
    type: StationsActionsTypes.LOAD_SEVERITIES_SUCCESS,
    payload: { severities, allActions, allIds, clearCache }
  };
};

export const NoAnalyticResultsHistogramToLoad: NoSeveritiesDataToLoadType = allActions => {
  return {
    type: StationsActionsTypes.NO_SEVERITIES_TO_LOAD,
    payload: allActions
  };
};

export const LoadAnalyticResultsHistogramFailure = (error: string): Action<string> => {
  return {
    type: StationsActionsTypes.LOAD_SEVERITIES_FAILURE,
    payload: error
  };
};

export const CreateStationSuccess: UpsertStationSuccessType = (station: Station, callback) => {
  return {
    type: StationsActionsTypes.CREATE_STATION_SUCCESS,
    payload: { station, callback }
  };
};

export const DeleteStation: DeleteStationType = (stationType, stationId, integrationSource, callback) => {
  return {
    type: StationsActionsTypes.DELETE_STATION,
    payload: { stationType, stationId, integrationSource, callback }
  };
};

export const DeleteStationSuccess: DeleteStationType = (stationType, stationId, integrationSource, callback) => {
  return {
    type: StationsActionsTypes.DELETE_STATION_SUCCESS,
    payload: { stationType, stationId, integrationSource, callback }
  };
};

export const ToggleStation: ToggleStationType = (stationType, stationId, actionType, autoCreateTask, callback) => {
  return {
    type: StationsActionsTypes.TOGGLE_STATION,
    payload: { stationType, stationId, actionType, autoCreateTask, callback }
  };
};

export const ToggleStationSuccess = (station: Station): Action<Station> => {
  return {
    type: StationsActionsTypes.TOGGLE_STATION_SUCCESS,
    payload: station
  };
};

export const EditStation: EditStationType = (stationType, station, callback) => {
  return {
    type: StationsActionsTypes.EDIT_STATION,
    payload: { stationType, station, callback }
  };
};

export const EditStationSuccess: UpsertStationSuccessType = (station: Station, callback) => {
  return {
    type: StationsActionsTypes.EDIT_STATION_SUCCESS,
    payload: { station, callback }
  };
};

export const EditStationFailed = () => {
  return {
    type: StationsActionsTypes.EDIT_STATION_FAILED
  };
};

export const DeleteTrapViewDevice = (): Action => {
  return {
    type: StationsActionsTypes.DELETE_TRAPVIEW_DEVICE
  };
};
