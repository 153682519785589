import { COLOR_OPTIONS } from 'config/style';

interface CropCoffeeBeanProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropCoffeeBean = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropCoffeeBeanProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.1317 11.2265C1.14703 11.2969 1.16806 11.3782 1.1948 11.4704C1.21478 11.5392 1.25704 11.5995 1.31503 11.6417C1.45944 11.7468 1.66169 11.715 1.76677 11.5706C2.74824 10.2219 4.47865 8.76738 7.53014 7.80319C10.717 6.79683 12.4658 5.10333 13.4206 3.63968C13.46 3.57929 13.5024 3.51109 13.5477 3.43509C13.7826 3.04111 13.716 2.53738 13.3869 2.21793C13.2929 2.12672 13.2256 2.06519 13.1849 2.03341C10.7858 0.160054 6.71714 0.883352 3.80025 3.80025C1.57751 6.02298 0.628546 8.91453 1.1317 11.2265ZM2.5041 12.3269C2.47149 12.3738 2.43708 12.4258 2.40089 12.4828C2.15541 12.8697 2.20753 13.3745 2.52688 13.703C2.61684 13.7956 2.67698 13.8545 2.70737 13.8798C5.0908 15.8613 9.23873 15.1608 12.1998 12.1998C14.4029 9.99659 15.3546 7.13635 14.8813 4.83475C14.8623 4.74234 14.8344 4.63466 14.7978 4.51171C14.7742 4.43241 14.724 4.36363 14.6557 4.31692C14.4913 4.20446 14.2668 4.24661 14.1543 4.41107C13.8425 4.867 13.462 5.33788 12.9977 5.80216C11.8664 6.93395 10.2383 8.02526 7.9005 8.76388C4.95999 9.69259 3.36711 11.0862 2.5041 12.3269Z'
      fill={color}
    />
  </svg>
);

export default CropCoffeeBean;
