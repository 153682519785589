import UnstyledButton from 'components/unstyled-button';
import type { ISTMarkerProps } from 'core/shared/map/map.models';
import STTypo, { STTypoModels } from 'core/shared/typo';
import type { SegmentTrackingHookReturn } from 'core/utils/segment/useSegmentTracking';
import { loadPopup } from 'pages/timeline/timeline.functions';
import { EventType } from 'pages/timeline/timeline.models';
import { useTranslation } from 'react-i18next';
import SvgCloseMinGlyph from 'st-protector-icons/Glyph/CloseMinGlyph';
import { SdPopupClusterItem } from './sd-popup-cluster-item';
import type { Popup } from './sd-popup.models';

interface ISDPopupCluster {
  segmentTracking: SegmentTrackingHookReturn;
  productsAvailable?: string | boolean;
  enableMultiplePhotos?: boolean;
  isGalleryV2?: string | boolean;
  mapboxPopup: mapboxgl.Popup;
  fieldName?: string;
  data: ISTMarkerProps[];
}

const SDPopupCluster = ({
  enableMultiplePhotos,
  productsAvailable,
  segmentTracking,
  mapboxPopup,
  isGalleryV2,
  fieldName,
  data
}: ISDPopupCluster) => {
  const [t] = useTranslation();

  const renderPopup = (marker: ISTMarkerProps) => {
    if (marker.markerProps.type === EventType.MONITORING) {
      return <SdPopupClusterItem marker={marker as unknown as Popup<EventType.MONITORING>} eventType={EventType.MONITORING} />;
    } else if (marker.markerProps.type === EventType.PHENOLOGY) {
      return <SdPopupClusterItem marker={marker as unknown as Popup<EventType.PHENOLOGY>} eventType={EventType.PHENOLOGY} />;
    } else if (marker.markerProps.type === EventType.ANNOTATION) {
      return (
        <SdPopupClusterItem
          marker={marker as unknown as Popup<EventType.ANNOTATION>}
          eventType={EventType.ANNOTATION}
          enableMultiplePhotos={enableMultiplePhotos}
        />
      );
    }
  };

  return (
    <>
      <div className='st-popup-cluster__header'>
        <STTypo type={STTypoModels.TypeOptions.P1} fontWeight={500}>
          {data?.length} {t('general.map.clustered.grouped_points')}
        </STTypo>
        <UnstyledButton className='st-popup-cluster__header__close-button' onClick={() => mapboxPopup.remove()}>
          <SvgCloseMinGlyph />
        </UnstyledButton>
      </div>
      <div className='st-popup-cluster__container'>
        {data?.map(marker => (
          <UnstyledButton
            key={marker.id}
            data-testid={`st-popup-cluster-list-${marker.id}`}
            className='st-popup-cluster__list'
            onClick={() => {
              loadPopup({
                popup: mapboxPopup,
                marker,
                enableMultiplePhotos,
                productsAvailable,
                segmentTracking,
                isGalleryV2,
                fieldName
              });
            }}>
            <div className='st-popup-cluster__item'>{renderPopup(marker)}</div>
          </UnstyledButton>
        ))}
      </div>
    </>
  );
};

export default SDPopupCluster;
