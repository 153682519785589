import type { Action, UUID } from 'core/utils/basic.models';
import type { LoadProductSuccessType, LoadProductType, Product } from './product.models';

export const ProductActionsTypes = {
  LOAD_PRODUCT: '[Product] Load Products',
  LOAD_PRODUCT_SUCCESS: '[Product] Load Products Success',
  LOAD_PRODUCT_FAILURE: '[Product] Load Products Failure',

  LOAD_PRODUCT_BY_IDS: '[Product] Load Products by IDS',
  LOAD_PRODUCT_BY_IDS_SUCCESS: '[Product] Load Products by IDS Success',
  LOAD_PRODUCT_BY_IDS_FAILURE: '[Product] Load Products by IDS Failure'
};

export const LoadProducts: LoadProductType = (companyId, propertyId, pageSize) => {
  return {
    type: ProductActionsTypes.LOAD_PRODUCT,
    payload: { companyId, propertyId, pageSize }
  };
};

export const LoadProductsSuccess: LoadProductSuccessType = productsPage => {
  return {
    type: ProductActionsTypes.LOAD_PRODUCT_SUCCESS,
    payload: productsPage
  };
};

export const LoadProductsFailure = (error: string): Action<string> => {
  return {
    type: ProductActionsTypes.LOAD_PRODUCT_FAILURE,
    payload: error
  };
};

export const LoadProductsByIds: (ids: UUID[]) => Action<UUID[]> = ids => {
  return {
    type: ProductActionsTypes.LOAD_PRODUCT_BY_IDS,
    payload: ids
  };
};

export const LoadProductsByIdsSuccess: (products: Product[]) => Action<Product[]> = products => {
  return {
    type: ProductActionsTypes.LOAD_PRODUCT_BY_IDS_SUCCESS,
    payload: products
  };
};
