import { ConfigurationParameterType } from 'pages/borer-risk/borer-risk-map/borer-risk.models';
import type { RiskParametersType } from './field-clustering-configuration.models';
import type { ListParameterGroups } from './steps/parameters/parameters.utils';

export const mapRiskParameters = (storeRiskParameters: RiskParametersType) => {
  return Object.keys(storeRiskParameters).reduce((riskParameters, parameterId) => {
    const parameter = storeRiskParameters?.[parameterId];
    if (
      (parameter?.type === ConfigurationParameterType.CUSTOMIZED || parameter?.type === ConfigurationParameterType.LIST) &&
      parameter?.value
    ) {
      const values = parameter.value as ListParameterGroups;
      const newValues = Object.keys(values).reduce((options, group) => {
        options[group] = values[group].options;
        return options;
      }, {});
      riskParameters[parameterId] = {
        type: storeRiskParameters[parameterId].type,
        value: newValues
      };
    } else {
      riskParameters[parameterId] = storeRiskParameters[parameterId];
    }
    return riskParameters;
  }, {});
};
