import styled from 'styled-components';

export const StyledImportErrorContainer = styled.div`
  margin-top: 20px;
`;

export const StyledImportErrorItem = styled.div`
  margin-top: 2px;
`;

export const StyledImportErrorDescription = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;
