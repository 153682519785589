const trustedDomains = ['.strider.ag', '.cropwise.com', '.syngentadigital.co.uk', '.us-west-2.amazonaws.com'];

export const isTrustedDomain = (url: string): boolean => {
  try {
    const { hostname } = new URL(url);

    return trustedDomains.some(domain => hostname.endsWith(domain));
  } catch {
    return false;
  }
};
