import { selectSelectedCompany } from 'core/core.reducer';
import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import { StaticPointsActionsTypes } from './static-points.actions';
import type { StaticPoint, StaticPointState, StaticPointTemplate } from './static-points.models';

function selectStatiPointUuid(region: StaticPoint): string {
  return region.static_point.id;
}

function sortByName(a: StaticPoint, b: StaticPoint): number {
  return a.static_point.name.localeCompare(b.static_point.name);
}

const entity: EntityAdapter<StaticPoint> = createEntityAdapter<StaticPoint>({
  selectId: selectStatiPointUuid,
  sortComparer: sortByName
});

export const initialState: StaticPointState = entity.getInitialState({
  isStaticPointsLoading: false,
  isTemplatesLoading: false,
  error: null
});

export default (state = initialState, action): StaticPointState => {
  switch (action.type) {
    case StaticPointsActionsTypes.LOAD_STATIC_POINTS:
      return {
        ...state,
        isStaticPointsLoading: true
      };
    case StaticPointsActionsTypes.LOAD_STATIC_POINTS_SUCCESS:
      return entity.addMany(action.payload, {
        ...state,
        isStaticPointsLoading: false,
        error: null
      });
    case StaticPointsActionsTypes.LOAD_STATIC_POINTS_FAILURE:
      return {
        ...state,
        isStaticPointsLoading: false,
        error: action.payload
      };
    case StaticPointsActionsTypes.LOAD_STATIC_POINTS_TEMPLATES:
      return {
        ...state,
        isTemplatesLoading: true
      };
    case StaticPointsActionsTypes.LOAD_STATIC_POINTS_TEMPLATES_SUCCESS:
      return entity.upsertMany(action.payload, {
        ...state,
        isTemplatesLoading: false,
        error: null
      });
    case StaticPointsActionsTypes.LOAD_STATIC_POINTS_TEMPLATES_FAILURE:
      return {
        ...state,
        isTemplatesLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectStaticPointsIds = selectIds;
export const selectStaticPointsEntities = selectEntities;
export const selectAllStaticPoints = selectAll;
export const selectStaticPointsTotal = selectTotal;

export const selectStaticPointsChildren = createSelector(
  (state: AppState) => selectAll(state.entities.staticPoints),
  staticPoints => {
    return staticPoints.filter(staticPoint => !!staticPoint?.static_point?.template_id);
  }
);

export const selectStaticPointsTemplate = createSelector(
  (state: AppState) => selectAll(state.entities.staticPoints),
  staticPoints => {
    return staticPoints
      .filter(staticPoint => !staticPoint?.static_point?.template_id)
      .map(template => ({ ...template, isTemplate: true } as StaticPointTemplate));
  }
);

export const selectStaticPointsTemplateBySelectedCompany = createSelector(
  (state: AppState) => selectAll(state.entities.staticPoints),
  (state: AppState) => selectSelectedCompany(state.uiState.global),
  (staticPoints, selectCompany) => {
    return staticPoints
      .filter(staticPoint => !staticPoint?.static_point?.template_id && staticPoint?.static_point?.company_id === selectCompany)
      .map(template => ({ ...template, isTemplate: true } as StaticPointTemplate));
  }
);
