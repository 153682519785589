import * as Sentry from '@sentry/react';
import axios from 'axios';

type Primitive = string | number | boolean | null | undefined;

interface SentryContext {
  fileName?: Primitive;
  method?: Primitive;
  [key: string]: Primitive | Omit<SentryContext, 'fileName' | 'method'>;
}

interface HandledError {
  error: unknown;
  extraErrorInformation: SentryContext;
}

const handleException = (error: unknown, fullError = false): HandledError => {
  const handledError: HandledError = {
    error,
    extraErrorInformation: {}
  };

  if (axios.isAxiosError(error)) {
    if (error.config?.url) {
      handledError.extraErrorInformation.url = error.config.url;
      handledError.extraErrorInformation.httpMethod = error.config.method;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const headersContentType = error.response?.headers?.['content-type'];
    if (headersContentType && headersContentType == 'application/xml') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      handledError.extraErrorInformation.response = error.response?.data;
    } else {
      let errorMessage = error.response?.statusText;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (error.response?.data?.message) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        errorMessage += ` - ${error.response.data.message}`;
      }
      handledError.error = new Error(errorMessage, { cause: error });
    }
  } else {
    handledError.extraErrorInformation.url = 'N/A';
  }

  if (fullError) {
    const serializedError = JSON.stringify(error, Object.getOwnPropertyNames(error));
    handledError.extraErrorInformation.fullError = serializedError;
  }

  return handledError;
};

const sentryService = {
  captureException: (error: unknown, context: SentryContext, fullError = false): void => {
    let handledError = error;

    const handledException = handleException(error, fullError);
    handledError = handledException.error;
    const errorContext = { ...context, ...handledException.extraErrorInformation };

    Sentry.setContext('Context of the error', errorContext);

    Sentry.captureException(handledError, {
      tags: { fileName: context?.fileName, method: context?.method }
    });
  },

  globalEventProcessor: (event: Sentry.Event, information: SentryContext): Sentry.Event => {
    const { exception = {} } = event;
    Sentry.setContext('other information', information);
    if (exception) {
      const lastFunction = exception?.values?.[0]?.stacktrace?.frames ?? [];
      const stackTrace = lastFunction[lastFunction.length - 1];

      if (stackTrace?.function) {
        Sentry.setTag('functionName', stackTrace.function);
      }
    }
    return event;
  }
};

export default sentryService;
