import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { BorerRiskActionTypes } from './borer-risk.actions';
import type { BorerRiskEntity, BorerRiskState } from './borer-risk.models';

const initialPage = {
  empty: false,
  first: false,
  last: false,
  number: 0,
  number_of_elements: 0,
  size: 0,
  total_elements: 0,
  total_pages: 0,
  needCanonical: false
};

function selectDiseaseRiskDataId(entity: BorerRiskEntity) {
  return entity.propertyId;
}

const entity: EntityAdapter<BorerRiskEntity> = createEntityAdapter<BorerRiskEntity>({
  selectId: selectDiseaseRiskDataId
});

const initialState = entity.getInitialState<BorerRiskState>({
  ids: [],
  entities: {},
  emptyPage: false,
  page: {
    ...initialPage
  },
  risks: null,
  limitValues: null,
  parameters: {},
  isLoadingRisks: false,
  isLoading: false,
  isEmpty: false,
  areaRiskError: null,
  error: null,
  propertyId: null,
  activeParameter: 'total',
  needCanonical: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case BorerRiskActionTypes.SET_ACTIVE_PARAMETER:
      return {
        ...state,
        activeParameter: action.payload
      };
    case BorerRiskActionTypes.SET_EMPTY_PAGE:
      return {
        ...state,
        emptyPage: action.payload
      };
    case BorerRiskActionTypes.LOAD_CONFIGURATION_PARAMETERS:
      return {
        ...state,
        isLoading: true,
        error: null,
        parameters: {},
        propertyId: action.payload.propertyId
      };
    case BorerRiskActionTypes.LOAD_CONFIGURATION_PARAMETERS_SUCCESS:
      return {
        ...state,
        page: {
          ...action.payload
        },
        parameters: action.payload,
        isLoading: false,
        isEmpty: false,
        error: null,
        emptyPage: false,
        needCanonical: true
      };
    case BorerRiskActionTypes.LOAD_CONFIGURATION_PARAMETERS_FAILURE:
      return {
        ...state,
        ids: [],
        entities: {},
        page: {
          ...initialPage
        },
        parameters: {},
        limitValues: null,
        isLoading: false,
        isEmpty: true,
        error: action.payload,
        propertyId: null,
        needCanonical: true
      };
    case BorerRiskActionTypes.LOAD_BORER_RISKS:
      return {
        ...state,
        isLoadingRisks: true,
        areaRiskError: null
      };
    case BorerRiskActionTypes.LOAD_BORER_RISKS_SUCCESS:
      return {
        ...state,
        risks: action.payload.risks,
        limitValues: action.payload.limitValues,
        isLoadingRisks: false,
        areaRiskError: null
      };
    case BorerRiskActionTypes.RELOAD_BORER_RISKS:
      return {
        ...state,
        risks: action.payload.risks,
        limitValues: action.payload.limitValues,
        isLoadingRisks: true,
        areaRiskError: null
      };
    case BorerRiskActionTypes.LOAD_BORER_RISKS_FAILURE:
      return {
        ...state,
        isLoadingRisks: false,
        limitValues: null,
        areaRiskError: action.payload
      };
    default:
      return {
        ...state,
        needCanonical: false
      };
  }
};
