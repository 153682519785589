import { RegionsSideMenuActionsTypes } from './regions-side-menu.actions';
import type { RegionsSideMenuState } from './regions-side-menu.models';

const initialState: RegionsSideMenuState = {
  hoveredRegion: null
};

export default (state = initialState, action): RegionsSideMenuState => {
  switch (action.type) {
    case RegionsSideMenuActionsTypes.SET_HOVERED_REGION:
      return {
        ...state,
        hoveredRegion: action.payload
      };
    default:
      return state;
  }
};
