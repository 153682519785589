import { getSystemFlags } from 'core/core.selectors';
import type { Assignee } from 'entities/assignee/assignee.models';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { generateUUID } from '../../../core/utils/functions';
import { RoutersTypes } from '../post-harvest/post-harvest.models';
import { useRoutingPlanConfigurationStore } from '../routes-configuration.zustand';
import type { LeafhopperConfiguration, LeafhopperPrioritiesFieldBox, LeafhopperPrioritiesFieldTitle } from './leafhopper.models';
import { FieldType, PrioritiesIds } from './leafhopper.models';

export const getFieldPrioritiesValues = (planType: RoutersTypes): (LeafhopperPrioritiesFieldBox | LeafhopperPrioritiesFieldTitle)[] => {
  const isLeafhopper = planType === RoutersTypes.LEAFHOPPER;
  const isTrapRouting = planType === RoutersTypes.TRAPS;
  return [
    {
      id: PrioritiesIds.RISK_PARAMETERS,
      title: 'post_harvest.leafhopper_priorities.cards.range.title',
      textBeforeInput: 'post_harvest.leafhopper_priorities.cards.range.phrase',
      inputType: 'post_harvest.leafhopper_priorities.cards.range.type',
      textAfterInput: 'post_harvest.leafhopper_priorities.cards.range.phrase2',
      info: 'post_harvest.leafhopper_priorities.cards.range.info',
      type: FieldType.COMPONENT,
      enabled: isLeafhopper
    },
    { title: 'post_harvest.leafhopper_priorities.second_title', type: FieldType.TITLE, enabled: isLeafhopper },
    {
      id: PrioritiesIds.DAYS_AFTER_PLANTING_OR_HARVEST,
      title: 'post_harvest.leafhopper_priorities.cards.after_planting_harvest.title',
      textBeforeInput: 'post_harvest.leafhopper_priorities.cards.after_planting_harvest.phrase',
      inputType: 'post_harvest.leafhopper_priorities.cards.after_planting_harvest.type',
      textAfterInput: 'post_harvest.leafhopper_priorities.cards.after_planting_harvest.phrase2',
      info: 'post_harvest.leafhopper_priorities.cards.after_planting_harvest.info',
      type: FieldType.COMPONENT,
      enabled: isLeafhopper || isTrapRouting
    },
    {
      id: PrioritiesIds.DAYS_BEFORE_HARVEST,
      title: 'post_harvest.leafhopper_priorities.cards.before_harvest.title',
      textBeforeInput: 'post_harvest.leafhopper_priorities.cards.before_harvest.phrase',
      inputType: 'post_harvest.leafhopper_priorities.cards.before_harvest.type',
      textAfterInput: 'post_harvest.leafhopper_priorities.cards.before_harvest.phrase2',
      info: 'post_harvest.leafhopper_priorities.cards.before_harvest.info',
      type: FieldType.COMPONENT,
      enabled: isLeafhopper || isTrapRouting
    },
    {
      id: PrioritiesIds.DAYS_AFTER_MONITORING,
      title: 'post_harvest.leafhopper_priorities.cards.monitoring_done.title',
      textBeforeInput: 'post_harvest.leafhopper_priorities.cards.monitoring_done.phrase',
      inputType: 'post_harvest.leafhopper_priorities.cards.monitoring_done.type',
      textAfterInput: 'post_harvest.leafhopper_priorities.cards.monitoring_done.phrase2',
      info: 'post_harvest.leafhopper_priorities.cards.monitoring_done.info',
      type: FieldType.COMPONENT,
      enabled: isLeafhopper || isTrapRouting
    }
  ];
};

export const useParseLeafhopperStore = (assignees: Assignee[]) => {
  const {
    selectedInspectionGroups,
    selectedDays,
    assigneesGroups,
    assigneesGroupsProductivity,
    fieldPriorities,
    productsResidual,
    sampling,
    fieldClusterPhenomenaIds,
    pestRiskRules
  } = useRoutingPlanConfigurationStore();

  const { companyId } = useParams();
  const flags = useSelector(getSystemFlags);

  const assigneesGroupsList = assigneesGroups?.map(group => ({
    id: group.groupId,
    name: group.groupName,
    monitoring_per_day: assigneesGroupsProductivity.value,
    assignees: group.assigneesIds.map(assigneeId => ({
      id: assigneeId,
      name: assignees?.find(assignee => assignee?.id === assigneeId)?.name
    }))
  }));

  const parseLeafhopperStore: LeafhopperConfiguration = {
    id: generateUUID(),
    company_id: companyId ?? '',
    days: selectedDays,

    products: productsResidual?.reduce((acc, product) => {
      if (product?.id && product?.residual_days) {
        return [...acc, { id: product.id, residual_days: Number(product.residual_days) }];
      }
      return acc;
    }, []),

    days_after_planting_or_harvest: fieldPriorities?.days_after_planting_or_harvest?.days ?? null,
    days_after_monitoring: fieldPriorities?.days_after_monitoring?.days ?? null,
    days_before_harvest: fieldPriorities?.days_before_harvest?.days ?? null,

    assignee_groups: assigneesGroupsList,
    inspection_groups_ids: selectedInspectionGroups,
    field_cluster_phenomenons: fieldClusterPhenomenaIds,

    sampling_points: {
      type: sampling?.samplingType,
      points_factor: sampling?.quantityByArea,
      minimum_ratio: sampling?.percentToCompleted
    },
    risk_parameters: {
      blocking: fieldPriorities?.risk_parameters?.blocking ?? null,
      field_cluster_percentage: flags?.P40_30249_clusteringInputLeafhopper ? fieldPriorities?.risk_parameters?.field_cluster_percentage : 40
    },
    pest_risk_rules: pestRiskRules
  };

  return parseLeafhopperStore;
};
