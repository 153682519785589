import axios from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import type { CommandRequest, UUID } from 'core/utils/basic.models';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import type {
  MethodologiesForPropertyResponse,
  MethodologyByCompanyResponse,
  MethodologyEntity,
  MethodologyPayload
} from './methodology.models';

const protectorApiUrl = PROTECTOR_API_URL;
const demeterMethodologyUrl = `${protectorApiUrl}/api/v1/methodologies`;

type getMethodologyByCompanyServiceType = (companyId: UUID) => Observable<MethodologyByCompanyResponse>;

export const getMethodologyByCompany: getMethodologyByCompanyServiceType = (companyId: UUID) => {
  const url = `${protectorApiUrl}/api/v1/methodologies/by-company/${companyId}?size=9999`;
  return axios
    .get<MethodologyByCompanyResponse>(url, {
      headers: {
        'X-Company-Id': companyId
      }
    })
    .pipe(map(response => response.data));
};

export const updateMethodology = (methodology: CommandRequest<MethodologyPayload>): Observable<MethodologyEntity> => {
  return axios.put<MethodologyEntity>(`${demeterMethodologyUrl}`, methodology).pipe(map(response => response.data));
};

export const getMethodologiesForProperty = (propertyId?: UUID) => {
  if (!propertyId) return of([]);
  return axios
    .get<MethodologiesForPropertyResponse[]>(`${protectorApiUrl}/v1/methodologies/property/${propertyId}`)
    .pipe(map(meth => meth.data));
};

export const getMethodologiesForPropertySeason = (propertyId: UUID, seasonId: UUID) => {
  return axios
    .get<MethodologiesForPropertyResponse[]>(`${protectorApiUrl}/v1/methodologies/property/${propertyId}/season/${seasonId}`)
    .pipe(map(meth => meth.data));
};
