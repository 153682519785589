import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type React from 'react';

interface Props {
  title: string;
  message: string;
}

const FooterMessageAboutImage: React.FC<Props> = ({ title, message }) => {
  return (
    <div data-testid='footer-message-about-logo' className='st-report-drawer__message_about_logo'>
      <div className='st-report-drawer__message_about_logo_container'>
        <div className='header'>
          <STTypo type={TypeOptions.H5} fontWeight={500} data-testid='footer-message-about-logo-title'>
            {title}
          </STTypo>
        </div>

        <div>
          <STTypo data-testid='footer-message-about-logo-message'>{message}</STTypo>
        </div>
      </div>
    </div>
  );
};

export default FooterMessageAboutImage;
