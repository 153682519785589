import { setHostAppId } from '@cw-elements/config';
import { ConfigProvider } from 'antd';
import { PROTECTOR_APP_ID } from 'config/constants';
import i18n from 'config/i18n';
import STTypo from 'core/shared/typo';
import Routes from 'infra/routes/routes.container';
import useInfra from 'infra/useInfra.hook';

PROTECTOR_APP_ID && setHostAppId(PROTECTOR_APP_ID);

const Infra = () => {
  const { locale } = useInfra();

  if (!i18n.isInitialized) {
    return <STTypo>&nbsp;</STTypo>;
  }

  return (
    <ConfigProvider locale={locale}>
      <Routes />
    </ConfigProvider>
  );
};

export default Infra;
