import { ConfigProvider } from 'antdV5';
import { PREFIX_CLS, THEME } from './antd-provider.constants';
import { useLocale } from './antd-provider.use-locale';

interface AntdProviderProps {
  children: React.ReactNode;
}

const AntdProvider = ({ children }: AntdProviderProps) => {
  const locale = useLocale();
  return (
    <ConfigProvider locale={locale} prefixCls={PREFIX_CLS} theme={THEME}>
      {children}
    </ConfigProvider>
  );
};

export default AntdProvider;
