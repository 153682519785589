import { generateUUID } from 'core/utils/functions';
import type { Assignee } from 'entities/assignee/assignee.models';
import { useParams } from 'react-router';
import { useRoutingPlanConfigurationStore } from '../routes-configuration.zustand';
import type { ProductsData, TrapRoutingConfiguration } from './trap-routing.models';

export const useParseTrapRoutingStore = (assignees: Assignee[]) => {
  const { pestRiskRules, fieldPriorities, selectedDays, assigneesGroups, assigneesGroupsProductivity, trapDistribution, productsResidual } =
    useRoutingPlanConfigurationStore();

  const { companyId } = useParams();

  const assigneesGroupsList = assigneesGroups
    ?.flatMap(group => group.assigneesIds)
    .map(assigneeId => ({
      id: assigneeId,
      name: assignees?.find(assignee => assignee?.id === assigneeId)?.name ?? '',
      traps_per_day: assigneesGroupsProductivity.value
    }));

  const parseTrapRoutingConfiguration: TrapRoutingConfiguration = {
    id: generateUUID(),
    company_id: companyId ?? '',
    assignees: assigneesGroupsList,
    days_after_planting_or_harvest: fieldPriorities.days_after_planting_or_harvest?.days ?? null,
    days_before_harvest: fieldPriorities.days_before_harvest?.days ?? null,
    days_after_monitoring: fieldPriorities.days_after_monitoring?.days ?? null,
    trap_density: trapDistribution.trapDensity!,
    min_traps_per_cluster: trapDistribution.minimumTrapsPerCluster!,
    days: selectedDays,
    pest_risk_rules: pestRiskRules,
    products: productsResidual?.reduce<ProductsData[]>((acc, product) => {
      if (product?.id && product?.residual_days) {
        return [...acc, { id: product.id, residual_days: Number(product.residual_days) }];
      }
      return acc;
    }, []),
    version: 'v2'
  };

  return parseTrapRoutingConfiguration;
};
