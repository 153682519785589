import { COLOR_OPTIONS } from 'config/style';
import { selectSystemFlags } from 'core/core.reducer';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import moment from 'moment';
import type { AnnotationStage } from 'pages/timeline/timeline.models';
import { FILE_TYPE } from 'pages/timeline/timeline.models';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import { Provider } from 'syngenta-digital-react-cropwise-notes';
import './area-day-info.events-annotations.less';
import { InfoAnnotation } from './info-annotation';
import { InfoPhotos } from './info-photos';
import { InfoScouter } from './info-scouter';
import { InfoTags } from './info-tags';

const AudioPlayer = React.lazy(() => import('syngenta-digital-react-cropwise-notes').then(module => ({ default: module.AudioPlayer })));

interface EventsAnnotationProps {
  annotation: AnnotationStage;
  index: number;
  fieldName?: string;
}

export const CLASS_NAME_PREFIX = 'area-day-info-events-annotations';

export const EventsAnnotation: React.FC<EventsAnnotationProps> = ({ annotation, index, fieldName = '' }) => {
  const [t] = useTranslation();
  const [audioDuration, setAudioDuration] = useState<string>('');
  const systemFlags = useSelector(selectSystemFlags);
  const propertyTimezone = useSelector<AppState, string | undefined>(state => state.entities.property.propertyData?.timeZone) ?? '';

  const audioItem = (annotation.medias || [])?.find(media => media.type === FILE_TYPE.AUDIO);

  const audio = audioItem?.url ? new Audio(audioItem?.url) : null;

  const segmentTracking = useSegmentTracking();

  const handleAudio = useCallback(async () => {
    if (!audio) return;

    audio.preload = 'metadata';

    const convertSecondsToFormatedString = await import('syngenta-digital-react-cropwise-notes').then(
      module => module.convertSecondsToFormatedString
    );

    if (audio.duration) {
      setAudioDuration(convertSecondsToFormatedString(audio.duration));
    } else {
      audio.onloadedmetadata = () => {
        if (audio.duration) {
          const convertedDuration = convertSecondsToFormatedString(audio.duration);
          setAudioDuration(convertedDuration);
        }
      };
    }
  }, [audio]);

  useEffect(() => {
    if (audio) {
      handleAudio();
    }
  }, [audio, handleAudio]);

  const segmentAudioEvents = useMemo(
    () => ({
      clickToPauseAudio: context => segmentTracking.track('Notes - Click to pause audio', context),
      clickToPlayAudio: context => segmentTracking.track('Notes - Click to play audio', context)
    }),
    [segmentTracking]
  );

  const dateToScreen = useMemo(
    () => (propertyTimezone ? moment.tz(annotation.date, propertyTimezone) : moment(annotation.date)),
    [annotation.date, propertyTimezone]
  );

  const photos = useMemo(() => annotation?.medias?.filter(media => media.type === FILE_TYPE.PICTURES), [annotation]);

  const isGaleryV2 = (systemFlags?.P40_26233_notes_midia_viewer as boolean) || false;

  return (
    <Provider theme='light'>
      <div data-testid='events-annotation-container' className={`${CLASS_NAME_PREFIX}__container`}>
        <STTypo
          data-testid={`events-annotation-note-${index}`}
          type={TypeOptions.H5}
          color={COLOR_OPTIONS.PRIMARY}
          maxlength={50}
          fontWeight={500}>
          {t('pages.timeline.notes.note', { index: index + 1 })}
        </STTypo>
        <InfoPhotos noteId={annotation.id} image={annotation?.image} photos={photos} annotation={annotation} fieldName={fieldName} />
        {audio && audioDuration && (
          <Suspense fallback=''>
            <AudioPlayer
              customAudioStyles={{ marginBottom: '14px' }}
              duration={audioDuration}
              events={segmentAudioEvents}
              audio={audio}
              isGaleryV2={isGaleryV2}
              audioUrl={audioItem?.url}
            />
          </Suspense>
        )}
        <InfoAnnotation iconType='property' text={annotation.point} />
        <InfoAnnotation iconType='task' text={dateToScreen.format('DD MMM, YY • HH:mm[h]')} />
        <InfoAnnotation iconType='comment' text={annotation.comments} />
        <InfoTags tags={annotation?.tags} />
        <InfoScouter name={annotation?.scouter?.name} />
      </div>
    </Provider>
  );
};
