import { Button, Icon, Modal } from 'antd';
import svgBlocked from 'assets/images/expiration-plan-blocked.svg';
import svgNotBlocked from 'assets/images/expiration-plan-not-blocked.svg';
import type { LicensingStatusAppsContractResponse } from 'entities/company/company.models';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvgRenew from 'st-protector-icons/Glyph/SvgRenew';
import { getRemainingContractDays, handleInitialModalVisibility } from './licence-expiration.functions';
import './licente-expiration.styles.less';

function dateTimeToDate(dt: Date) {
  return new Date(`${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`);
}
interface LicenceExpirationProps {
  contract: LicensingStatusAppsContractResponse;
  baseUrl: string;
}

const LicenceExpiration: React.FC<LicenceExpirationProps> = ({ contract, baseUrl }) => {
  const [showModal, setShowModal] = useState(false);
  const [closedModal, setClosedModal] = useState(false);

  const [t] = useTranslation();

  const remainingDays = useMemo(() => getRemainingContractDays(contract), [contract]);

  useEffect(() => {
    handleInitialModalVisibility(remainingDays, contract, setClosedModal, setShowModal);
  }, [contract, remainingDays]);

  const closeModal = () => {
    if (remainingDays < 0) return;
    setShowModal(false);
    if (contract) {
      localStorage.setItem(`last-closed-modal-date-${contract.id}`, dateTimeToDate(new Date()).toISOString());
    }
    setClosedModal(true);
  };

  const handleImage = () => {
    if (remainingDays < 0) {
      return svgBlocked;
    }
    return svgNotBlocked;
  };

  const handleDaysLeft = () => {
    if (remainingDays < 0) return t('components.licesing_expiration_modal.plan_expired_title');
    if (remainingDays === 0) return t('components.licesing_expiration_modal.expiration_title_end_today');

    const prefix = t('components.licesing_expiration_modal.expiration_title_init');
    const suffix = t('components.licesing_expiration_modal.expiration_title_end', { count: remainingDays });

    return `${prefix} ${remainingDays} ${suffix}`;
  };

  const handleMainText = () => {
    if (remainingDays < 0) {
      return t('components.licesing_expiration_modal.block_text');
    }
    return t('components.licesing_expiration_modal.expiration_avoid_text');
  };

  return (
    <>
      {remainingDays < 16 && (
        <Button className='licence-expiration-header__button' onClick={() => window.open(baseUrl, '_blank')}>
          <SvgRenew /> {t('components.licesing_expiration_button.button_text')}
        </Button>
      )}
      {showModal && !closedModal && (
        <div className='modal'>
          <Modal
            title={
              <div className='modal__header'>
                <Icon className='modal__header__icon' type='exclamation-circle' />
                <span>{t('components.licesing_expiration_modal.attention')}</span>
              </div>
            }
            visible
            footer={null}
            maskClosable={false}
            closable={remainingDays >= 0}
            onCancel={closeModal}
            centered>
            <div className='modal__body'>
              <span className='modal__body__title'>{handleDaysLeft()}</span>
              <img className='modal__body__img' src={handleImage().toString()} alt='' />
              <div className='modal__body__mainText'>
                <span>{handleMainText()}</span>
              </div>
            </div>
            <div className='modal__footer'>
              <button
                type='button'
                className='ant-btn ant-btn-primary modal__footer__button '
                onClick={() => window.open(`${baseUrl}`, '_blank')}>
                <span>{t('components.licesing_expiration_modal.button_text')}</span>
              </button>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default LicenceExpiration;
