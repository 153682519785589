import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Action, CommandRequest, UUID } from 'core/utils/basic.models';
import { UpdateMethodologyDeep } from 'entities/methodology-deep/methodology-deep.actions';
import type { MethodologyResponse } from 'entities/phenomenon/phenomenon.models';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import sentryService from 'sentry/service';
import {
  LoadMethodologiesByCompanyFailure,
  LoadMethodologiesByCompanySuccess,
  MethodologyActionTypes,
  UpdateMethodologyFailure,
  UpdateMethodologySuccess
} from './methodology.actions';
import type { MethodologyPayload } from './methodology.models';
import { getMethodologyByCompany, updateMethodology } from './methodology.service';

export const handleLoadMethodologyByCompany: (action$: any) => any = action$ =>
  action$.pipe(
    ofType(MethodologyActionTypes.LOAD_METHODOLOGIES_BY_COMPANY),
    map((action: Action<MethodologyResponse[]>) => action.payload),
    concatMap((companyId: UUID) =>
      getMethodologyByCompany(companyId).pipe(
        map(response => {
          const methodologyServiceArray = response.content;
          const availableMethodologyArray = methodologyServiceArray.filter(m => !m.deleted_at);

          return LoadMethodologiesByCompanySuccess(availableMethodologyArray);
        })
      )
    ),
    catchError(err => of(LoadMethodologiesByCompanyFailure(err)))
  );

export const handleUpdateMethodology: (action$: any, state$) => any = action$ =>
  action$.pipe(
    ofType(MethodologyActionTypes.UPDATE_METHODOLOGY),
    map((action: Action<CommandRequest<MethodologyPayload>>) => action.payload),
    concatMap((methodologyPayload: CommandRequest<MethodologyPayload>) =>
      updateMethodology(methodologyPayload).pipe(
        concatMap(() => {
          return [
            UpdateMethodologySuccess(methodologyPayload.payload.methodology_dto),
            UpdateMethodologyDeep(methodologyPayload.payload.methodology_deep)
          ];
        })
      )
    ),
    catchError((err: Error | AxiosError) => {
      let errorObject: Error = err;
      if (axios.isAxiosError(err)) {
        errorObject = new Error(err.response?.statusText);
      }
      sentryService.captureException(errorObject, {
        fileName: 'methodology.epics',
        method: 'handleUpdateMethodology',
        description: err
      });

      return of(UpdateMethodologyFailure(err.message));
    })
  );
