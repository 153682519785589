export interface SetMiddleOrClickedImageIndexProps {
  clickedImageIndex?: number;
  middle?: number;
  photosLength?: number;
}

export enum ShowHideAnimationType {
  FADE = 'fade',
  SLIDE = 'slide',
  NONE = 'none'
}

export interface ProtectorPhotoswipeProps {
  photos: string[];
  testId: string;
  clickedImageIndex?: number;
  imageRef?: React.RefObject<HTMLAnchorElement>;
  showHideAnimationType?: ShowHideAnimationType;
  galleryId: string;
}
