export enum SeasonQueryType {
  GET_SEASON_AREAS_FROM_SELECTED_SEASONS = 'getSeasonAreasFromSelectedSeasons'
}

export interface SeasonCompany {
  created_at: string;
  updated_at: string;
  created_by_id: string;
  last_updated_by_id: string;
  name: string;
  start_date: string;
  end_date: string;
  crop_id: string;
  org_id: string;
  active: boolean;
  deleted: boolean;
  id: string;
}
