import { TEN_MINUTES } from 'config/constants';
import { getSelectedPropertyId, getSystemFlags } from 'core/core.selectors';
import { useQuery } from 'core/utils/data-store/tools';
import { useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';

import type { UUID } from 'core/utils/basic.models';
import type { GetAllCharacteristicsAndPhenomenaParams } from './methodology.query.models';
import {
  ALL_CHARACTERISTICS_BY_METHODOLOGY_ID,
  ALL_METHODOLOGIES_IDS,
  ALL_PHENOMENA_BY_METHODOLOGY_ID,
  GET_ALL_METHODOLOGIES_BY_SEASON_AREA
} from './methodology.query.models';
import {
  getAllMethodologies,
  getCharacteristicsByMethodologies,
  getMethodologiesIdsByPropertyAndSeasons,
  getPhenomenaByMethodologyId
} from './methodology.services';

export const useGetAllMethodologiesBySeasons = (seasonIds?: (string | undefined)[], enabled = true) => {
  const propertyId = useSelector(getSelectedPropertyId);
  const systemFlags = useSelector(getSystemFlags);

  return useQuery(
    [GET_ALL_METHODOLOGIES_BY_SEASON_AREA, seasonIds, propertyId],
    async () => {
      if (!seasonIds?.length || !propertyId) return;
      const filteredSeasons = seasonIds.filter(season => !!season) as string[];
      const allMethodologies = await getAllMethodologies(
        propertyId,
        filteredSeasons,
        !!systemFlags?.P40_34839_DISABLE_NEW_SEASON_FIELD_TO_METHODOLOGY
      );
      return allMethodologies?.filter(m => !m.deleted_at);
    },
    {
      staleTime: TEN_MINUTES,
      enabled: enabled && !!propertyId && !!seasonIds
    }
  );
};

export const useGetAllMethodologiesFromSelectedSeasons = (enabled: boolean) => {
  const currentSeasons = useSelector((state: AppState) => state.uiState.global.selectedSeasons);

  return useGetAllMethodologiesBySeasons(currentSeasons, enabled);
};

export const useGetFirstMethodologyIdByPropertyAndSeason = (propertyId?: string, seasonId?: string[], enabled = false) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [ALL_METHODOLOGIES_IDS, propertyId, seasonId],
    queryFn: () => {
      if (!enabled || !propertyId || !seasonId?.length) return;
      return getMethodologiesIdsByPropertyAndSeasons(propertyId, seasonId);
    },
    staleTime: TEN_MINUTES,
    enabled: enabled && !!propertyId && !!seasonId?.length,
    select: data => data?.find(dataId => dataId)
  });

  return {
    methodologyId: data,
    loadingMethodologyId: isLoading,
    methodologyError: isError
  };
};

export const useGetCharacteristicsByMethodologyId = (methodologyId?: string) => {
  const { status, fetchStatus, data } = useQuery(
    [ALL_CHARACTERISTICS_BY_METHODOLOGY_ID, methodologyId],
    () => {
      if (!methodologyId) return;
      return getCharacteristicsByMethodologies(methodologyId);
    },
    {
      staleTime: TEN_MINUTES,
      enabled: !!methodologyId
    }
  );

  return {
    characteristics: data,
    loadingCharacteristics: fetchStatus === 'fetching' || status === 'loading'
  };
};

export const useGetPhenomenaByMethodologyId = (methodologyId?: string) => {
  const { status, fetchStatus, data } = useQuery(
    [ALL_PHENOMENA_BY_METHODOLOGY_ID, methodologyId],
    () => {
      if (!methodologyId) return;
      return getPhenomenaByMethodologyId(methodologyId);
    },
    {
      staleTime: TEN_MINUTES,
      enabled: !!methodologyId
    }
  );

  return {
    phenomena: data,
    loadingPhenomena: fetchStatus === 'fetching' || status === 'loading'
  };
};

export const useGetAllCharacteristicsAndPhenomenaFromMethodologyId = ({
  propertyId,
  seasonId,
  enabled
}: GetAllCharacteristicsAndPhenomenaParams) => {
  const { loadingMethodologyId, methodologyId, methodologyError } = useGetFirstMethodologyIdByPropertyAndSeason(
    propertyId,
    seasonId,
    enabled
  );

  const { characteristics, loadingCharacteristics } = useGetCharacteristicsByMethodologyId(methodologyId);
  const { phenomena, loadingPhenomena } = useGetPhenomenaByMethodologyId(methodologyId);

  return {
    loadingAll: loadingCharacteristics || loadingPhenomena || loadingMethodologyId,
    characteristics,
    phenomena,
    methodologyError
  };
};

export const useGetCharacteristicsByMethodologies = (methodologyId: UUID, isEnabled = true) =>
  useQuery({
    queryKey: ['useGetCharacteristicsByMethodologies', methodologyId],
    queryFn: () => getCharacteristicsByMethodologies(methodologyId),
    enabled: !!methodologyId && isEnabled
  });
