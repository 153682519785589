import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { CORE_SERVICES_API_URL } from 'config/constants';
import type { UUID } from 'core/utils/basic.models';
import { mockPageableDataInAxiosResponse } from 'core/utils/functions';
import { map } from 'rxjs/operators';
import type { AssigneePage } from './assignee.models';

const coreServicesApiUrl = `${CORE_SERVICES_API_URL}/v2/orgs`;

export const getAssigneePage = (company_id: string, property_ids: UUID[], only_accounts_workers = false): AxiosObservable<AssigneePage> => {
  return axios
    .post<AssigneePage>(`${coreServicesApiUrl}/${company_id}/workers-by-properties?page=0&size=999&sort=name`, {
      property_ids,
      only_accounts_workers
    })
    .pipe(map(mockPageableDataInAxiosResponse));
};
