import { Tooltip } from 'antd';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import STTypo from 'core/shared/typo';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type React from 'react';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useMatch } from 'react-router-dom';

interface MenuIconProps {
  'data-testid': string;
  name: string;
  title: string;
  icon: JSX.Element;
  segmentMessage: string;
}

/**
 * This component tries to abstract the common logic between icons on the menu.component.tsx file.
 * I did not migrate every single icon to the new approach because I thought it was too agressive.
 * So if you are creating a new icon, or even refactoring the menu.component, consider using this
 * component instead of copy pasting the existing icons.
 */
const MenuIcon: FC<MenuIconProps> = ({ 'data-testid': testId, title, icon, name, segmentMessage }) => {
  const segmentTracking = useSegmentTracking();
  const user = useSelector(selectCurrentUser);
  const matchCompany = useMatch({ path: '/company/:companyId', end: false });
  const matchProperty = useMatch({ path: '/company/:companyId/property/:propertyId', end: false });

  const navigateLink = useMemo(
    () =>
      matchCompany && matchProperty
        ? `/company/${matchCompany.params.companyId}/property/${matchProperty.params.propertyId}/disease-risk`
        : '/hall-of-companies',
    [matchCompany, matchProperty]
  );

  const handleTrackSegment = (action: string) => {
    return (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.stopPropagation();
      const payload: AnalyticsInfo = {
        userId: user?.id ?? '',
        baseRole: user?.role ?? '',
        protectorRole: user?.job_title ?? '',
        from: 'Sidebar Menu'
      };
      segmentTracking.track(action, payload);
    };
  };

  return (
    <NavLink
      data-testid={testId}
      className={props => `ant-menu-item ${props.isActive ? 'ant-menu-item-selected' : ''}`}
      onClick={handleTrackSegment(segmentMessage)}
      to={navigateLink}>
      <Tooltip title={title} placement='right'>
        <div>
          <div className='st-menu-active' />
          {icon}
          <STTypo>{name}</STTypo>
        </div>
      </Tooltip>
    </NavLink>
  );
};

export default MenuIcon;
