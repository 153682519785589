const SvgSampleCard = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <g clipPath='url(#clip0_11146_51927)'>
      <rect width='32' height='32' rx='8' fill='white' />
      <rect width='32' height='32' rx='4' fill='#FFF1F1' />
      <g clipPath='url(#clip1_11146_51927)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.705 15.25C22.36 12.1225 19.8775 9.64 16.75 9.295V7.75H15.25V9.295C12.1225 9.64 9.64 12.1225 9.295 15.25H7.75V16.75H9.295C9.64 19.8775 12.1225 22.36 15.25 22.705V24.25H16.75V22.705C19.8775 22.36 22.36 19.8775 22.705 16.75H24.25V15.25H22.705ZM16 21.25C13.0975 21.25 10.75 18.9025 10.75 16C10.75 13.0975 13.0975 10.75 16 10.75C18.9025 10.75 21.25 13.0975 21.25 16C21.25 18.9025 18.9025 21.25 16 21.25ZM15.25 17.9049V15.25H16H16.75V17.9049C16.6438 17.9855 16.5155 18.0711 16.3802 18.138C16.218 18.2183 16.089 18.25 16 18.25C15.911 18.25 15.782 18.2183 15.6198 18.138C15.4845 18.0711 15.3562 17.9855 15.25 17.9049ZM17.5 15.25C17.5 14.8358 17.1642 14.5 16.75 14.5C16.75 14.4328 16.7412 14.3677 16.7246 14.3058L17.7652 13.2652L17.2349 12.7349L16.1943 13.7754C16.1323 13.7588 16.0672 13.75 16 13.75C15.9328 13.75 15.8677 13.7588 15.8057 13.7754L14.7652 12.7349L14.2349 13.2652L15.2754 14.3057C15.2588 14.3677 15.25 14.4328 15.25 14.5C14.8358 14.5 14.5 14.8358 14.5 15.25V15.625H13.9053L13.2652 14.9849L12.7348 15.5152L13.5947 16.375H14.5V17.125H13.5947L12.7348 17.9848L13.2652 18.5152L13.9053 17.875H14.5V17.9393C14.5 18.1383 14.5783 18.3303 14.7341 18.4539C15.0141 18.676 15.5071 19 16 19C16.4929 19 16.9859 18.676 17.2659 18.4539C17.4217 18.3303 17.5 18.1383 17.5 17.9393V17.875H18.0947L18.7348 18.5152L19.2652 17.9848L18.4053 17.125H17.5V16.375H18.4053L19.2652 15.5152L18.7348 14.9849L18.0947 15.625H17.5V15.25Z'
          fill='#CF3537'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_11146_51927'>
        <rect width='32' height='32' rx='8' fill='white' />
      </clipPath>
      <clipPath id='clip1_11146_51927'>
        <rect width='18' height='18' fill='white' transform='translate(7 7)' />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSampleCard;
