import type { AppState } from 'redux/app-state';
import { AuthActionsTypes } from './auth.actions';
import { PermissionFlagsEnum } from './auth.enum';
import type { AuthState, WorkspaceApps } from './auth.models';

export const initialState: AuthState = {
  user: null,
  isLoading: false,
  isLogged: false,
  error: null,
  permissionFlags: {
    simpleCompanyDashboard: {
      entitlementKey: PermissionFlagsEnum.SIMPLE_COMPANY_DASHBOARD,
      value: false
    },
    simpleTimeline: {
      entitlementKey: PermissionFlagsEnum.SIMPLE_TIMELINE,
      value: false
    },
    hidePropertyDashboard: {
      entitlementKey: PermissionFlagsEnum.HIDE_PROPERTY_DASHBOARD,
      value: false
    },
    hideWarehouse: {
      entitlementKey: PermissionFlagsEnum.HIDE_WAREHOUSE,
      value: false
    },
    hideFinancial: {
      entitlementKey: PermissionFlagsEnum.HIDE_FINANCIAL,
      value: false
    },
    hideSmartSpray: {
      entitlementKey: PermissionFlagsEnum.HIDE_SMART_SPRAY,
      value: false
    },
    hideSmartPlan: {
      entitlementKey: PermissionFlagsEnum.HIDE_SMART_PLAN,
      value: false
    },
    hideIntegrations: {
      entitlementKey: PermissionFlagsEnum.HIDE_INTEGRATIONS,
      value: false
    },
    hideTrapsManagement: {
      entitlementKey: PermissionFlagsEnum.HIDE_TRAPS_MANAGEMENT,
      value: false
    },
    showScoutingScore: {
      entitlementKey: PermissionFlagsEnum.SHOW_SCOUTING_SCORE,
      value: false
    },
    showRepresentativeness: {
      entitlementKey: PermissionFlagsEnum.SCOUTING_REPRESENTATIVENESS,
      value: false
    },
    tasksNotificationEmail: {
      entitlementKey: PermissionFlagsEnum.TASKS_NOTIFICATION_EMAIL,
      value: false
    },
    nemaDigital: {
      entitlementKey: PermissionFlagsEnum.NEMADIGITAL_APP,
      value: false
    },
    hideReportAplicationStatistics: {
      entitlementKey: PermissionFlagsEnum.HIDE_REPORT_APLICATION_STATISTICS,
      value: false
    },
    hideReportFarm: {
      entitlementKey: PermissionFlagsEnum.HIDE_REPORT_FARM,
      value: false
    },
    reportVisit: {
      entitlementKey: PermissionFlagsEnum.VISIT_REPORT,
      value: false
    },
    hideReportTracking: {
      entitlementKey: PermissionFlagsEnum.HIDE_REPORT_TRACKING,
      value: false
    },
    hideReportSampleAndAgricultural: {
      entitlementKey: PermissionFlagsEnum.HIDE_REPORT_SAMPLE_POINT_AND_AGRCULTURAL_RESOURCES,
      value: false
    },
    hideReportTraps: {
      entitlementKey: PermissionFlagsEnum.HIDE_REPORT_TRAPS,
      value: false
    },
    hideInventory: {
      entitlementKey: PermissionFlagsEnum.HIDE_MENU_INVENTORY,
      value: false
    },
    hideReportRainFall: {
      entitlementKey: PermissionFlagsEnum.HIDE_REPORT_RAINFALL,
      value: false
    },
    hideReportMonitoringStatistics: {
      entitlementKey: PermissionFlagsEnum.HIDE_REPORT_MONITORING_STATISTICS,
      value: false
    },
    reportDiseaseRisk: {
      entitlementKey: PermissionFlagsEnum.DISEASE_RISK_REPORT,
      value: false
    },
    hideReportDetailedConsolidated: {
      entitlementKey: PermissionFlagsEnum.HIDE_REPORT_DETAILED_CONSOLIDATED,
      value: false
    },
    hideSamplingReport: {
      entitlementKey: PermissionFlagsEnum.HIDE_SAMPLING_REPORT,
      value: false
    },
    hideFarmScoutingReport: {
      entitlementKey: PermissionFlagsEnum.HIDE_REPORT_FARM_SCOUTING,
      value: false
    },
    hideAlertsMonitoring: {
      entitlementKey: PermissionFlagsEnum.HIDE_ALERTS_MONITORING,
      value: false
    },
    hideAlertsRiskChange: {
      entitlementKey: PermissionFlagsEnum.HIDE_ALERTS_RISK_CHANGE,
      value: false
    },
    hideAlertsPrescription: {
      entitlementKey: PermissionFlagsEnum.HIDE_ALERTS_PRESCRIPTION,
      value: false
    },
    hideAlertsWorsenedStatus: {
      entitlementKey: PermissionFlagsEnum.HIDE_ALERTS_WORSENED_STATUS,
      value: false
    },
    hideAlertsWorsenedStatusByProblem: {
      entitlementKey: PermissionFlagsEnum.HIDE_ALERTS_WORSENED_STATUS_BY_PROBLEM,
      value: false
    }
  },
  defaultWorkspaceName: '',
  defaultWorkspaceApps: {} as WorkspaceApps
};

export default (state = initialState, action): AuthState => {
  switch (action.type) {
    case AuthActionsTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case AuthActionsTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLogged: true,
        error: null
      };
    case AuthActionsTypes.LOAD_CURRENT_USER_ACCOUNT_SERVICE:
      return {
        ...state,
        isUserAccountService: action.payload
      };
    case AuthActionsTypes.LOAD_CURRENT_USER_SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    case AuthActionsTypes.LOAD_CURRENT_USER_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case AuthActionsTypes.LOAD_WORKSPACE_INFO_SUCCESS: {
      return {
        ...state,
        defaultWorkspaceId: action.payload
      };
    }
    case AuthActionsTypes.LOAD_WORKSPACE_INFO_ACCESS_SUCCESS: {
      return {
        ...state,
        defaultWorkspaceId: action.payload
      };
    }
    case AuthActionsTypes.LOAD_WORKSPACE_NAME_SUCCESS: {
      return {
        ...state,
        defaultWorkspaceName: action.payload
      };
    }
    case AuthActionsTypes.LOAD_WORKSPACE_APPS_SUCCESS: {
      return {
        ...state,
        defaultWorkspaceApps: action.payload
      };
    }
    case AuthActionsTypes.LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isLoading: false,
        isLogged: false,
        error: action.payload
      };
    case AuthActionsTypes.LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case AuthActionsTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        isLoading: false,
        isLogged: false,
        error: null
      };
    case AuthActionsTypes.LOGOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLogged: true,
        error: action.payload
      };
    case AuthActionsTypes.SET_PERMISSION_FLAGS:
      return {
        ...state,
        permissionFlags: action.payload
      };
    default:
      return state;
  }
};

export const selectCurrentUser = (state: AppState): AuthState['user'] => state.uiState.auth.user;

export const selectServiceAccount = (state: AppState): AuthState['isUserAccountService'] => state.uiState.auth.isUserAccountService;
