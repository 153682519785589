import { COLOR_OPTIONS } from 'config/style';

interface CropTurnipProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropTurnip = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropTurnipProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.4337 1.39781C11.7437 0.833842 10.7113 0.870947 10.2087 1.50024C9.47148 1.62129 8.93886 2.15974 8.88264 2.84145C8.16495 3.23378 8.00929 4.09869 8.48709 4.77572C7.69184 5.80426 8.31188 5.80913 7.51281 4.77572C7.9913 4.09768 7.83378 3.2332 7.11726 2.84145C7.06116 2.16089 6.52973 1.62151 5.79115 1.50024C5.28693 0.868787 4.25391 0.835701 3.5662 1.39783C2.87604 1.96205 2.80113 2.90481 3.42808 3.43199C3.47587 4.10074 3.98703 4.65771 4.73626 4.78783C5.09382 5.5084 6.05396 5.73232 6.84682 5.33895C7.15477 5.76363 7.33374 6.19399 7.43614 6.52285C5.57024 6.69498 4.47575 7.56271 4.07326 8.57345C3.05535 11.1297 6.10962 13.7931 8 15C10.3726 13.4851 12.9104 10.6309 11.8657 8.43238C11.298 7.23743 9.98073 6.65353 8.56379 6.52285C8.66619 6.19399 8.84516 5.76365 9.1531 5.33895C9.94955 5.73409 10.9078 5.50476 11.2637 4.78783C11.9786 4.66364 12.5216 4.13492 12.5718 3.43199C13.2015 2.90254 13.1209 1.95959 12.4337 1.39781Z'
      fill={color}
    />
  </svg>
);

export default CropTurnip;
