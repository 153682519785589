import { SettingsMenu } from '@cw-elements/settings-menu';
import { CROPWISE_URL } from 'config/constants';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import { validatePlansAndEntitlements } from 'core/utils/functions';
import { useGetRBACAuthorityUser } from 'querys/rbac/rbac.query';
import { RBACActions, RBACLevels, RBACPermissionTypesEnum } from 'querys/rbac/rbac.query.model';
import type React from 'react';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import NewPlusIcon from 'st-protector-icons/Glyph/NewPlusIcon';
import { ProtectorIcon } from 'st-protector-icons/Minor/Protector';
import type { STOrgSettingsCardProps } from './org-settings-card.component';

interface FarmSettingsComponent {
  workspaceId?: string;
  orgId?: string;
  propertyId?: string;
  segmentKey?: string;
  assistantModeOn?: boolean;
  hideAfterSetup?: boolean;
  orgSettingsButtonAvailable: boolean;
  orgSettingsContentOptions: STOrgSettingsCardProps[];
  propertySettingsAvailable: boolean;
  propertySettingsContentOptions: STOrgSettingsCardProps[];
}

const transformProtectorSettings = (settings, available) => {
  if (settings?.length && available) {
    return settings.map(setting => ({
      key: setting.title,
      label: setting.title,
      tooltipText: setting.description,
      icon: setting.svgIcon,
      onClick: setting.onClick
    }));
  }

  return [];
};

const createOrgDeepAction = (workspaceId, callback, t) => ({
  key: 'createOrg',
  label: t('modals.organization_settings.create_org.title'),
  tooltipText: t('modals.organization_settings.create_org.description'),
  icon: <NewPlusIcon fill='#0071ce' />,
  link: `${CROPWISE_URL}/deep-actions/organization?workspace_id=${workspaceId}&callback_uri=${callback}
  `
});

const createPropertyDeepAction = (organizationId, callback, t) => ({
  key: 'createProperty',
  label: t('modals.organization_settings.create_property.title'),
  tooltipText: t('modals.organization_settings.create_property.description'),
  icon: <NewPlusIcon fill='#0071ce' />,
  link: `${CROPWISE_URL}/deep-actions/create-property?org_id=${organizationId}&callback_uri=${callback}
  `
});

const FarmSettingsComponent: React.FC<FarmSettingsComponent> = ({
  workspaceId,
  orgId,
  propertyId,
  orgSettingsButtonAvailable,
  orgSettingsContentOptions,
  propertySettingsAvailable,
  propertySettingsContentOptions
}: FarmSettingsComponent) => {
  const { host } = window.location;
  const [t] = useTranslation();
  const licensingStatus = useSelector((state: AppState) => state.entities.company.licensingStatus);

  const organizationPermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.ORGANIZATION,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.WRITE]
  });

  const propertyPermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.PROPERTY,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.WRITE]
  });

  const transformedOrgSettings = transformProtectorSettings(
    orgSettingsContentOptions,
    orgSettingsButtonAvailable || propertySettingsAvailable
  );
  const transformedPropertySettings = transformProtectorSettings(propertySettingsContentOptions, propertySettingsAvailable);

  const hasOrganizationDeepLink = organizationPermission?.length ? [createOrgDeepAction(workspaceId, host, t)] : [];
  const hasPropertyDeepLink = propertyPermission?.length && orgId ? [createPropertyDeepAction(orgId, host, t)] : [];

  const deepActions = useMemo(
    () =>
      validatePlansAndEntitlements(licensingStatus, null, [Entitlements.CREATE_ORG_AND_PROPERTY])
        ? [...hasOrganizationDeepLink, ...hasPropertyDeepLink]
        : [],
    [licensingStatus, hasOrganizationDeepLink, hasPropertyDeepLink]
  );

  const parsedPropertySettings = useMemo(
    () => [...transformedOrgSettings, ...transformedPropertySettings, ...deepActions],
    [transformedOrgSettings, transformedPropertySettings, deepActions]
  );

  return (
    <Suspense fallback=''>
      <SettingsMenu
        headerSelector='.sd-header'
        workspaceId={workspaceId}
        propertyId={propertyId}
        orgId={orgId}
        {...(parsedPropertySettings.length && {
          productSettings: {
            key: 'protector',
            title: 'Protector',
            icon: <ProtectorIcon />,
            tooltipText: '',
            items: parsedPropertySettings
          }
        })}
      />
    </Suspense>
  );
};

export default FarmSettingsComponent;
