const SvgReport = props => (
  <svg width={24} height={24} viewBox='6 6 20 20' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5 8.5H11.5C10.675 8.5 10 9.175 10 10V22C10 22.825 10.6675 23.5 11.4925 23.5H20.5C21.325 23.5 22 22.825 22 22V13L17.5 8.5ZM19 16H13V17.5H19V16ZM19 19H13V20.5H19V19ZM11.5 22H20.5V13.75H16.75V10H11.5V22Z'
      fill={props.fill || '#14803C'}
    />
  </svg>
);

export default SvgReport;
