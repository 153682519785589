export enum RBACQueryType {
  GET_WORKSPACE_OPERATIONS = 'GET_WORKSPACE_OPERATIONS',
  GET_ALL_ROLES_BY_USER = 'GET_ALL_ROLES_BY_USER'
}

export enum RBACPermissionTypesEnum {
  READ = 'read',
  WRITE = 'write',
  DELETE = 'delete',
  NEW = 'new',
  NOT_READ = 'not_read'
}

export enum RBACActions {
  FINANCIAL = 'financial',
  INVENTORY = 'inventory',
  SUGARCANE = 'sugarcane',
  ORGANIZATION = 'organization',
  PROPERTY = 'property',
  PANEL_METHODOLOGY = 'panel_methodology',
  ADMIN_DATA_MANAGEMENT = 'admin_data_management',
  TIMELINE = 'timeline',
  SATELLITE_IMAGERY = 'satellite_imagery',
  SCOUTING_TASK = 'scouting_task',
  SAMPLING_TASK = 'sampling_task',
  WORSENED_STATUS_BY_PROBLEM_ALERT = 'worsened_status_by_problem_alert',
  DELAYED_MONITORING_ALERT = 'delayed_monitoring_alert',
  WORSENED_STATUS_ALERT = 'worsened_status_alert',
  NEW_MONITORING_IN_PROPERTY_ALERT = 'new_monitoring_in_property_alert',
  REGIONAL_RISK = 'regional_risk',
  FIELDS = 'fields'
}

type RBACOperationsProps = Record<string, RBACPermissionTypesEnum[]>;

export type RBACWorkspaceOperations = Record<string, RBACOperationsProps>;

export interface RBACParsedOperations {
  allowed_operations: RBACWorkspaceOperations;
  denied_operations: RBACWorkspaceOperations;
}

export interface RBACOperations {
  action: string;
  resource: string;
}

export interface RBACGetWorkspaceOperationsResponse {
  account_id: string;
  allowed_operations: RBACOperations[];
  denied_operations: RBACOperations[];
}

export enum RBACLevels {
  WORKSPACE = 'workspace',
  PROPERTY = 'property',
  SYSTEM = 'system',
  ORG = 'org',
  APP = 'app',
  USER = 'user'
}

export interface RBACDefaultRequestProps {
  workspaceId?: string | null;
  propertyId?: string | null;
  companyId?: string | null;
  appId?: string | null;
}

export interface RBACMakeResourceByLevelProps extends RBACDefaultRequestProps {
  level: RBACLevels;
}

export interface RBACSetUpOperationsForRequestProps extends RBACDefaultRequestProps {
  operations: RBACOperations[];
}

export type RBACGetWorkspaceOperationsProps = RBACSetUpOperationsForRequestProps;

interface RBACCreateOperationProps extends RBACDefaultRequestProps {
  action: RBACActions;
  permission: RBACPermissionTypesEnum;
  level: RBACLevels;
}
export type CreateOperationByRBACLevels = RBACCreateOperationProps;

export interface GetAllRolesByUserResponse {
  empty: boolean;
  first: boolean;
  last: boolean;
  number_of_elements: number;
  pageable: {
    current_key: string;
    size: number;
    next_key: string;
  };
  content: {
    id: string;
    account_id: string;
    role_id: string;
    name: string;
    context: string;
    version: number;
    level: string;
    resource_attachment: string;
    resources: string[];
  }[];
}

interface Workspace {
  WorkspaceOwner?: Resource;
}

interface Property {
  PropertyAdministrator?: Resource;
  PropertyCollaborator?: Resource;
}

interface Org {
  OrganizationAdministrator?: Resource;
  OrganizationCollaborator?: Resource;
  OrganizationFarmCreator?: Resource;
  DevicesRead?: Resource;
  FinancialCollaborator?: Resource;
  InventoryAdministrator?: Resource;
  InventoryCollaborator?: Resource;
  MethodologyCollaborator?: Resource;
  SugarcaneAdministrator?: Resource;
  SugarcaneCollaborator?: Resource;
}

interface System {
  DevAgent?: Resource;
  SupportAgent?: Resource;
  ProtectorAdminCC?: Resource;
  ProtectorAdminETDENG?: Resource;
  ProtectorAdminSupportAgent?: Resource;
}

interface App {
  Concierge?: Resource;
}

interface Resource {
  resource: string;
}

export interface AllRolesByUserParsed {
  app: App;
  system: System;
  org: Org;
  property: Property;
  workspace: Workspace;
}

export interface RBACAvailableForUserProps {
  verifyRBACAvailableForUser: () => boolean;
}

export interface GetPermissionUserProps {
  rbacActions?: RBACActions;
  rbacLevels?: RBACLevels;
  rbacPermission?: RBACPermissionTypesEnum[];
  invalidCallback?: () => void;
}
