import { Button, DatePicker, Drawer, Form, Popconfirm, Select } from 'antd';
import RegionTreeSelectDefault from 'components/SD-region-tree-select/components/region-tree-select-default';
import type { Dictionary } from 'config/types';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import { renderStyledOption } from 'core/shared/select-options/select-options.component';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { generateUUID, getCurrentLanguage, getNameByCurrentLanguage, textContains } from 'core/utils/functions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types.ts';
import { getSelectedCompany } from 'entities/company/company.reducer';
import type { Characteristic } from 'entities/methodology-deep/methodology-deep.models';
import { LoadPropertyPhenomena } from 'entities/property/property.actions';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { selectRootRegion } from 'entities/region/region.reducer';
import { RequestReport } from 'entities/reports/report.actions';
import type { RequestReportBody } from 'entities/reports/report.models';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { useGetAllCharacteristicsAndPhenomenaFromMethodologyId } from 'querys/methodology/methodology.query';
import type React from 'react';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import SvgAlertGlyph from 'st-protector-icons/Glyph/AlertGlyph';
import '../report.styles.less';
import { ReportsTypes } from '../reports-types.enum';
import SelectSeason from './components/season-select.component.tsx';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';
import PhenomenaSelectAndLoading from './phenomena-select-and-loading';
import { useReportSampleUI } from './report-sample.hooks.ts';

const { RangePicker } = DatePicker;
const { OptGroup } = Select;
interface ISTReportSamplesDrawer {
  visible: boolean;
  callbackDrawer: () => void;
  type: ReportsTypes | null;
  icon?: ReactNode;
}

const STReportSamplesDrawer: React.FC<ISTReportSamplesDrawer> = ({ visible, callbackDrawer, type, icon }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [timeRangeFrom, setTimeRangeFrom] = useState(moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [timeRangeTo, setTimeRangeTo] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [groupedCharacteristics, setGroupedCharacteristics] = useState<Dictionary<Characteristic[]> | null>();
  const [selectedPhenomena, setSelectedPhenomena] = useState<UUID[]>([]);
  const [selectedTargets, setSelectedTargets] = useState<UUID[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<UUID[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<UUID[]>();

  const currentSeasons = useSelector((state: AppState) => state.uiState.global.selectedSeasons);
  const regions = useSelector((state: AppState) => state.entities.region.entities);
  const property = useSelector((state: AppState) => getSelectedProperty(state));
  const company = useSelector((state: AppState) => getSelectedCompany(state));
  const rootRegion = useSelector(selectRootRegion);
  const user = useSelector(selectCurrentUser);

  const { characteristics, phenomena, loadingAll } = useGetAllCharacteristicsAndPhenomenaFromMethodologyId({
    propertyId: property?.id,
    seasonId: selectedSeason,
    enabled: type === ReportsTypes.SAMPLING
  });

  useEffect(() => {
    if (property && selectedAreas.length > 0) {
      setSelectedPhenomena([]);
      dispatch(LoadPropertyPhenomena(property.id, currentSeasons, selectedAreas));
    }
  }, [dispatch, selectedAreas, property, currentSeasons]);

  const handleTimeRange = value => {
    if (value?.length) {
      setTimeRangeFrom(value[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      setTimeRangeTo(value[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };

  const handleSelectAreas = value => {
    setSelectedAreas(value);
  };

  const handleSelectTargets = value => {
    setGroupedCharacteristics(_.groupBy(characteristics, 'phenomenon_id'));
    setSelectedTargets(value);
  };

  const closeDrawer = useCallback(() => {
    callbackDrawer();
    setSelectedSeason([]);
    setSelectedTargets([]);
    setSelectedAreas([]);
  }, [callbackDrawer]);

  const disabledDate = current => {
    return current && current > moment().endOf('day');
  };

  const segmentTracking = useSegmentTracking();

  const onConfirm = () => {
    if (property && company && user && type) {
      const seasons = selectedAreas.reduce((acc: UUID[], id) => {
        if (regions[id]) {
          const regionSeasons = regions[id].seasons.map(s => s.id);
          return [...new Set([...acc, ...regionSeasons])];
        }
        return acc;
      }, []);

      const requestBody: RequestReportBody = {
        id: generateUUID(),
        property_id: property.id,
        type,
        data_request: {
          area_ids: selectedAreas,
          property_id: property.id,
          company_id: company.id,
          language: getCurrentLanguage(),
          end_date: timeRangeTo.format('YYYY-MM-DD'),
          start_date: timeRangeFrom.format('YYYY-MM-DD'),
          phenomenon_ids: selectedPhenomena,
          ...{ ...(type === ReportsTypes.SAMPLE && { season_ids: seasons }) },
          ...{ ...(type === ReportsTypes.SAMPLING && { user_id: user.id }) },
          ...{ ...(type === ReportsTypes.SAMPLING && { targets: selectedTargets }) },
          ...{ ...(type === ReportsTypes.SAMPLING && { season_ids: selectedSeason }) }
        }
      };

      const payload: AnalyticsInfo = {
        companyId: company?.id || '',
        companyName: company?.name || '',
        propertyId: property.id,
        propertyName: property.name,
        reportType: type,
        selectedAreasId: selectedAreas,
        selectedAreasName: Object.values(regions)
          .filter(r => selectedAreas.includes(r.id))
          .map(r => r.name),
        from: 'Export Report'
      };
      segmentTracking.track('Export Report - Generated Report', payload);
      dispatch(RequestReport(requestBody));
    }
    setSelectedSeason([]);
    setSelectedTargets([]);
    setSelectedPhenomena([]);
    closeDrawer();
  };

  const { submitDisabled: isConfirmButtonDisabled } = useReportSampleUI({ selectedTargets, selectedPhenomena, selectedAreas });

  useEffect(() => {
    setGroupedCharacteristics(_.groupBy(characteristics, 'phenomenon_id'));
  }, [characteristics]);

  const handleSearchCharacteristics = useCallback(
    (value: string) => {
      if (value) {
        const characteristicsFiltered = characteristics?.filter(characteristic => {
          return textContains(getNameByCurrentLanguage(characteristic.name), value);
        });

        setGroupedCharacteristics(_.groupBy(characteristicsFiltered, 'phenomenon_id'));
      } else {
        setGroupedCharacteristics(_.groupBy(characteristics, 'phenomenon_id'));
      }
    },
    [characteristics]
  );

  const changeSeasonSelected = useCallback((seasonID: string) => {
    setSelectedAreas([]);
    setSelectedSeason([seasonID]);
  }, []);

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible}
      width={400}
      title={<HeaderTitle icon={icon} type={type} />}
      onClose={closeDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='report-sample-drawer'>
        <ExplicativeText type={type} />

        <div className='st-report-drawer__form' data-testid='report-sample-form'>
          <Form layout='vertical'>
            <Form.Item data-testid='report-sample-type-timerange' label={t('modals.timeline_xray_report.fields.time_range')}>
              <RangePicker
                value={[timeRangeFrom, timeRangeTo]}
                format='L'
                size='large'
                data-testid='report-sample-type-timerange-picker'
                separator='—'
                onChange={handleTimeRange}
                allowClear={false}
                disabledDate={disabledDate}
              />
            </Form.Item>
            {type === ReportsTypes.SAMPLING && (
              <Form.Item label={t('report.seasons')}>
                <SelectSeason
                  placeholder={t('modals.timeline_xray_report.placeholder.crop_cycle')}
                  selectedSeason={selectedSeason ? selectedSeason[0] : ''}
                  setSelectedSeason={changeSeasonSelected}
                  showOnlySeasonsSelected
                />
              </Form.Item>
            )}
            {type === ReportsTypes.SAMPLING && (
              <Form.Item label={t('report.targets')}>
                <Select
                  mode='multiple'
                  size='large'
                  filterOption={false}
                  loading={loadingAll}
                  disabled={!selectedSeason}
                  data-testid='report-sampling-targets'
                  showSearch
                  onFocus={() => setGroupedCharacteristics(_.groupBy(characteristics, 'phenomenon_id'))}
                  onSearch={handleSearchCharacteristics}
                  onChange={handleSelectTargets}
                  placeholder={isEmpty(characteristics) ? t('report.no_targets') : t('report.targets_select')}>
                  {groupedCharacteristics &&
                    Object.keys(groupedCharacteristics).map(group => (
                      <OptGroup key={group} label={getNameByCurrentLanguage(phenomena?.find(c => c.id === group)?.name)}>
                        {[...groupedCharacteristics[group]].map(option =>
                          renderStyledOption(
                            { id: option.id!, name: getNameByCurrentLanguage(option.name) },
                            option.id!,
                            undefined,
                            undefined
                          )
                        )}
                      </OptGroup>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item data-testid='report-sample-type-region-select' label={t('modals.timeline_xray_report.fields.areas')}>
              {property && (
                <RegionTreeSelectDefault
                  regions={regions}
                  rootRegionId={rootRegion?.id}
                  selectedSeasonsIds={selectedSeason?.length ? selectedSeason : currentSeasons}
                  value={selectedAreas}
                  onChange={handleSelectAreas}
                />
              )}
            </Form.Item>
            {type === ReportsTypes.SAMPLE && (
              <PhenomenaSelectAndLoading selected={selectedPhenomena} handleSetSelected={setSelectedPhenomena} />
            )}
          </Form>
        </div>
        <div className='st-report-drawer__actions'>
          <Popconfirm
            placement='bottomRight'
            icon={
              <div className='st-report-drawer__confirm-icon-container'>
                <SvgAlertGlyph fill='#F74141' />
              </div>
            }
            overlayClassName='st-report-drawer__confirm'
            title={
              <div className='st-report-drawer__confirm-delete'>
                <STTypo type={TypeOptions.P1}>{`${t('report.back')}`}</STTypo>
              </div>
            }
            onConfirm={() => closeDrawer()}
            okText={t('general.complete')}
            okButtonProps={{ className: 'st-report__confirm_delete' }}
            cancelButtonProps={{ className: 'st-report__cancel_delete' }}
            cancelText={t('general.back')}>
            <Button type='ghost' size='default' className='st-report-drawer_actions-cancel' data-testid='report-spray-cancel'>
              {t('general.cancel')}
            </Button>
          </Popconfirm>
          <Button
            type='primary'
            size='default'
            className='st-report-drawer_actions-submit'
            onClick={onConfirm}
            disabled={isConfirmButtonDisabled}
            data-testid='report-sample-submit'>
            {t('report.action')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default STReportSamplesDrawer;
