import { DatePicker, Drawer, Form, Icon, Spin } from 'antd';
import RegionTreeSelectDefault from 'components/SD-region-tree-select/components/region-tree-select-default';
import { getRegionEntitiesInEntities } from 'core/core.selectors';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import type { UUID } from 'core/utils/basic.models';
import { generateUUID, getCurrentLanguage } from 'core/utils/functions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { LoadPropertyPhenomena } from 'entities/property/property.actions';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { RequestReport } from 'entities/reports/report.actions';
import type { RequestReportBody } from 'entities/reports/report.models';
import moment from 'moment';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import { selectRootRegion } from '../../../../../../entities/region/region.reducer';
import { disabledDate, isConfirmButtonDisabled, useSeasonSelect } from '../functions/report-generate.functions';
import '../report.styles.less';
import { ReportsTypes } from '../reports-types.enum';
import FooterDrawer from './components/footer-drawer.component';
import SelectSeason from './components/season-select.component';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';
import PhenomenaSelectAndLoading from './phenomena-select-and-loading';

const { RangePicker } = DatePicker;
export interface ISTReportScoutingDrawer {
  visible: ReportsTypes | null;
  callbackDrawer: () => void;
  icon?: any;
}

const STReportScoutingDrawer: React.FC<ISTReportScoutingDrawer> = ({ visible, callbackDrawer, icon }) => {
  const initialTimeRangeTo = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const initialTimeRangeFrom = moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const [t] = useTranslation();
  const [timeRangeFrom, setTimeRangeFrom] = useState(initialTimeRangeFrom);
  const [selectedAreas, setSelectedAreas] = useState<UUID[]>([]);
  const [timeRangeTo, setTimeRangeTo] = useState(initialTimeRangeTo);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<UUID>();
  const [finish, setFinish] = useState<boolean>(false);
  const [selectedPhenomena, setSelectedPhenomena] = useState<UUID[]>([]);

  const property = useSelector(getSelectedProperty);
  const regions = useSelector(getRegionEntitiesInEntities);
  const seasonSelect = useSeasonSelect(selectedSeason);
  const rootRegion = useSelector(selectRootRegion);
  const company = useSelector(getSelectedCompany);
  const isRegionsLoaded = useSelector((state: AppState) => state.entities.region.isRegionsLoaded);
  const currentSeasons = useSelector((state: AppState) => state.uiState.global.selectedSeasons);
  const user = useSelector(selectCurrentUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (property && selectedSeason && selectedSeason !== '' && selectedAreas.length > 0) {
      setSelectedPhenomena([]);
      dispatch(LoadPropertyPhenomena(property.id, [selectedSeason], selectedAreas));
    }
  }, [dispatch, selectedSeason, selectedAreas, property]);

  const handleTimeRange = value => {
    if (value?.length) {
      setTimeRangeFrom(value[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      setTimeRangeTo(value[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };

  const handleSelectAreas = value => {
    setSelectedAreas(value);
  };

  const closeDrawer = useCallback(() => {
    callbackDrawer();
    setSelectedAreas([]);
    setTimeRangeTo(initialTimeRangeTo);
    setTimeRangeFrom(initialTimeRangeFrom);
    setSelectedPhenomena([]);
  }, [callbackDrawer, initialTimeRangeTo, initialTimeRangeFrom]);

  const segmentTracking = useSegmentTracking();

  const generateReport = () => {
    if (!property || !company || !user || !selectedSeason) return;

    setIsLoading(true);

    const requestBody: RequestReportBody = {
      id: generateUUID(),
      property_id: property.id,
      type: ReportsTypes.MONITORING,
      data_request: {
        season_ids: [selectedSeason],
        area_ids: selectedAreas,
        phenomenon_ids: selectedPhenomena,
        property_id: property.id,
        company_id: company.id,
        language: getCurrentLanguage(),
        end_date: timeRangeTo.format('YYYY-MM-DD'),
        start_date: timeRangeFrom.format('YYYY-MM-DD'),
        user_id: user.id
      }
    };

    if (regions) {
      const payload: AnalyticsInfo = {
        selectedAreasId: selectedAreas,
        selectedAreasName: Object.values(regions)
          .filter(selectedRegion => selectedAreas.includes(selectedRegion.id))
          .map(region => region.name),
        reportType: requestBody.type,
        from: 'Export Report'
      };
      segmentTracking.track('Export Report - Generated Report ', payload);
    }

    dispatch(RequestReport(requestBody));

    setIsLoading(false);
    closeDrawer();
  };

  useEffect(() => {
    if (finish) {
      setFinish(false);
      closeDrawer();
    }
  }, [finish, closeDrawer]);

  const changeSeasonSelected = useCallback((seasonID: string) => {
    setSelectedAreas([]);
    setSelectedSeason(seasonID);
  }, []);

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible === ReportsTypes.SCOUTING}
      width={400}
      title={<HeaderTitle icon={icon} type={visible} />}
      onClose={closeDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='report-scouting-drawer'>
        <ExplicativeText type={visible} />
        <div className='st-report-drawer__form' data-testid='report-scouting-form'>
          <Form layout='vertical'>
            <Form.Item label={`${t('pages.tasks.task_create.season')}`}>
              <SelectSeason
                selectedSeason={selectedSeason}
                placeholder={t('modals.timeline_xray_report.placeholder.crop_cycle')}
                setSelectedSeason={changeSeasonSelected}
                showOnlySeasonsSelected
              />
            </Form.Item>
            <Form.Item data-testid='report-scouting-type-timerange' label={t('modals.timeline_xray_report.fields.time_range')}>
              <RangePicker
                value={[timeRangeFrom, timeRangeTo]}
                format='L'
                size='large'
                separator='—'
                onChange={handleTimeRange}
                allowClear={false}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item data-testid='report-scouting-type-region-select' label={t('modals.timeline_xray_report.fields.areas')}>
              {!isRegionsLoaded && (
                <div className='st-report-drawer__loading'>
                  <Spin indicator={<Icon type='loading' style={{ fontSize: 16 }} spin />} />
                </div>
              )}
              {property && (
                <RegionTreeSelectDefault
                  regions={regions}
                  rootRegionId={rootRegion?.id}
                  selectedSeasonsIds={selectedSeason?.length ? [selectedSeason] : currentSeasons}
                  value={selectedAreas}
                  onChange={handleSelectAreas}
                />
              )}
            </Form.Item>

            <PhenomenaSelectAndLoading selected={selectedPhenomena} handleSetSelected={setSelectedPhenomena} />
          </Form>
        </div>
        <FooterDrawer
          onPressConfirm={generateReport}
          isLoading={isLoading}
          disableConfirmButton={isConfirmButtonDisabled({
            selectedPhenomena,
            filterBySeasonWithTimeRange: true,
            seasonSelect,
            timeRangeFrom,
            timeRangeTo,
            selectedAreas,
            selectedSeason
          })}
          onPressCancel={closeDrawer}
        />
      </div>
    </Drawer>
  );
};

export default STReportScoutingDrawer;
