import { Button, Icon, Spin } from 'antd';
import { COLOR_OPTIONS } from 'config/style';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import type { ReportInfo } from 'entities/reports/report.models';
import { ReportStatus } from 'entities/reports/report.models';
import moment from 'moment';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import SvgDownloadGrayGlyph from 'st-protector-icons/Glyph/DownloadGrayGlyph';
import SvgSync2Glyph from 'st-protector-icons/Glyph/Sync2Glyph';
import { ReportsStatus } from './reports-status.enum';
import type { IReportTypes } from './reports-types.enum';

interface ReportDrawerListProps {
  reports: ReportInfo[];
  isLoading: boolean;
  reportTypes: IReportTypes;
  onClickReRun: (report: ReportInfo) => void;
  onClickDownload: (reportId: UUID) => void;
}

const ReportDrawerList: React.FC<ReportDrawerListProps> = ({ reports, isLoading, reportTypes, onClickReRun, onClickDownload }) => {
  const [t] = useTranslation();

  const getReportStatusClass = (status: ReportStatus) => {
    if (status === ReportStatus.PENDING || status === ReportStatus.RUNNING) {
      return ReportsStatus.PENDING;
    }
    if (status === ReportStatus.FINISHED) {
      return ReportsStatus.DONE;
    }
    return ReportsStatus.INVALID;
  };

  const getReportDescription = (report: ReportInfo) => {
    if (getReportStatusClass(report.status) === ReportsStatus.PENDING) return t(`report.loading`);
    if (getReportStatusClass(report.status) === ReportsStatus.INVALID) return t(`report.error`);

    const lastRequestedMoment = moment(report.modified_at);
    const todayMoment = moment();

    if (todayMoment.year() === lastRequestedMoment.year()) {
      switch (lastRequestedMoment.dayOfYear()) {
        case todayMoment.dayOfYear():
          return `${t('report.today')} • ${moment.utc(report.modified_at).local().format('HH:mm')}`;
        case todayMoment.dayOfYear() - 1:
          return `${t('report.yesterday')} • ${moment.utc(report.modified_at).local().format('HH:mm')}`;
        default:
          return `${lastRequestedMoment.format('L')} • ${moment.utc(report.modified_at).local().format('HH:mm')}`;
      }
    }
    return `${lastRequestedMoment.format('L')} • ${moment.utc(report.modified_at).local().format('HH:mm')}`;
  };

  const lastReports = [...reports].sort((reportA, reportB) => {
    const momentA = moment(reportA.modified_at || reportA.created_at);
    const momentB = moment(reportB.modified_at || reportB.created_at);
    return momentB.diff(momentA);
  });

  return (
    <div className='st-report-drawer__report-list' data-testid='report-list'>
      {isLoading ? (
        <Spin indicator={<Icon type='loading' style={{ fontSize: 24, marginTop: 20 }} spin />} spinning />
      ) : (
        <>
          {lastReports.map(report => {
            const reportStatus = getReportStatusClass(report.status);
            const isNew = moment().diff(moment(report.modified_at), 'm') < 15 && reportStatus === ReportsStatus.DONE;
            return (
              <div className='st-report-drawer__report-list-item' data-testid='report-list-item' key={report.id}>
                <div className='report-list-item-icon'>{reportTypes[report.type]?.icon}</div>
                <div className='st-report-drawer__report-list-item__info'>
                  <div className='st-report-drawer__report-list-item__info__text'>
                    <STTypo>{t(`report.types.${report.type}`)}</STTypo>
                    {isNew && (
                      <div className='st-report-drawer__report-list-item__info__text__new'>
                        <STTypo color='#005693' fontWeight={500} type={TypeOptions.P3}>
                          {t(`report.new`)}
                        </STTypo>
                      </div>
                    )}
                  </div>
                  <STTypo
                    color={reportStatus === ReportsStatus.INVALID ? COLOR_OPTIONS.DANGER : COLOR_OPTIONS.SECONDARY}
                    type={TypeOptions.P2}>
                    {getReportDescription(report)}
                  </STTypo>
                </div>

                <div className='st-report-drawer__report-list-item__icon'>
                  {reportStatus === ReportsStatus.PENDING && <Spin indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />} />}
                  {reportStatus === ReportsStatus.DONE && (
                    <Button
                      className='st-report-drawer__report-list-item__button'
                      data-testid={report.id}
                      onClick={() => onClickDownload(report.id)}>
                      <SvgDownloadGrayGlyph fill='#14803C' height={24} width={24} />
                    </Button>
                  )}
                  {reportStatus === ReportsStatus.INVALID && (
                    <>
                      <div className='st-report-drawer__report-list-item__icon__try-again'>
                        <STTypo color={COLOR_OPTIONS.GREEN_60} type={TypeOptions.P1}>
                          {t(`report.try_again`)}
                        </STTypo>
                      </div>
                      <Button
                        className='st-report-drawer__report-list-item__button'
                        type='link'
                        data-testid={report.id}
                        onClick={() => onClickReRun(report)}>
                        <SvgSync2Glyph height={12} width={12} />
                      </Button>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ReportDrawerList;
