import type { MonitoringModel } from 'pages/dashboard/widget-monitoring/widget-monitoring.models';
import type { MonitoringKpiModel } from './widget-monitoring-kpi.models';

export const calculateKpisRates = (previousValues: MonitoringModel, currentValues: MonitoringModel) => {
  // Calculate variation rate in percentage
  const calculateRate = (fisrtValue: number, secondValue: number) => {
    if (fisrtValue !== 0 && typeof fisrtValue === 'number' && typeof secondValue === 'number') {
      return ((secondValue - fisrtValue) / fisrtValue) * 100;
    }
    return 0;
  };
  const kpiList: MonitoringKpiModel[] = [];
  const DBVRate = calculateRate(currentValues.avg_days_between_visits, previousValues.avg_days_between_visits);
  const daysBetweenVisits: MonitoringKpiModel = {
    name: 'days_inspections',
    value: currentValues.avg_days_between_visits,
    rate: DBVRate
  };
  kpiList.push(daysBetweenVisits);

  const HPPRate = calculateRate(currentValues.avg_ha_per_point, previousValues.avg_ha_per_point);
  const haPerPoint: MonitoringKpiModel = {
    name: 'sample_density',
    value: currentValues.avg_ha_per_point,
    rate: HPPRate
  };
  kpiList.push(haPerPoint);

  const MHRate = calculateRate(currentValues.avg_monitored_ha, previousValues.avg_monitored_ha);
  const monitoredHa: MonitoringKpiModel = {
    name: 'avg_monitored_area',
    value: currentValues.avg_monitored_ha,
    rate: MHRate
  };
  kpiList.push(monitoredHa);

  const PPDRate = calculateRate(currentValues.avg_points_per_day, previousValues.avg_points_per_day);
  const pointsPerDay: MonitoringKpiModel = {
    name: 'avg_sample_day',
    value: currentValues.avg_points_per_day,
    rate: PPDRate
  };
  kpiList.push(pointsPerDay);

  const MBVSDRate = calculateRate(currentValues.avg_minutes_between_visits_same_day, previousValues.avg_minutes_between_visits_same_day);
  const minutesBetweenVisitsSameDay: MonitoringKpiModel = {
    name: 'avg_time_sample',
    value: currentValues.avg_minutes_between_visits_same_day,
    rate: MBVSDRate
  };
  kpiList.push(minutesBetweenVisitsSameDay);

  const PPARate = calculateRate(currentValues.avg_points_per_account, previousValues.avg_points_per_account);
  const pointsPerAccount: MonitoringKpiModel = {
    name: 'avg_sample_scouter',
    value: currentValues.avg_points_per_account,
    rate: PPARate
  };
  kpiList.push(pointsPerAccount);

  return kpiList;
};
