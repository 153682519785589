import * as Sentry from '@sentry/react';
import { SENTRY_URL, VITE_ENV } from 'config/constants';
import { getSystemFlags } from 'core/core.selectors';
import useVersion from 'core/shared/version/useVersion.hook';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const useInitMonitoring = () => {
  const flags = useSelector(getSystemFlags);

  const initSentry = useCallback(
    (versionApp: string) => {
      if (!Sentry.isInitialized()) {
        const browserTracing = Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
          finalTimeout: 300000
        });

        Sentry.init({
          dsn: SENTRY_URL,
          integrations: [browserTracing],
          tracesSampleRate: 0.8,
          environment: VITE_ENV,
          release: `protector4-user-panel@${versionApp?.length ? versionApp : 'staging'}`,
          enabled: window.location.hostname !== 'localhost',
          beforeSend: (event, hint) => {
            // Temporary code for P40-37408
            if (hint.originalException != null) {
              event.extra = {
                ...event.extra,
                ...hint.originalException
              };
            }

            if (flags?.P40_40442_sentry_custom_fingerprint) {
              const exception = event?.exception?.values?.[0];

              const totalFrame = exception?.stacktrace?.frames?.length;
              if (totalFrame) {
                const lastFrame = exception?.stacktrace?.frames?.[totalFrame - 1];
                event.fingerprint = [exception?.type ?? '', exception?.value ?? '', lastFrame ? JSON.stringify(lastFrame) : ''];
              }
            }

            return event;
          }
        });
      }
    },
    [flags?.P40_40442_sentry_custom_fingerprint]
  );

  const version = useVersion();

  useEffect(() => {
    version !== undefined && initSentry(version);
  }, [version, initSentry]);
};

export default useInitMonitoring;
