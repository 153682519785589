import type { User } from 'core/services/auth/auth.models';
import type {
  MethodologyAnalyticContextDTO,
  MethodologyCustomIndicator,
  MethodologyDiagnostics
} from 'entities/methodology/methodology.models';
import { IndicatorFeatureFlag } from 'entities/phenomenon/phenomenon.models';
import type { SeasonTree, SeasonTreeDeep } from 'entities/season/season-tree.models';
import { cloneDeep } from 'lodash';
import type { IEntityOption } from 'pages/edit-methodology/types/edit-methodology.model';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import type { CategoryDeep, CompleteMethodology, MethodologyDeep, Phenomenon, PhenomenonDeep } from './methodology-deep.models';

export const getNameById = (list: any, id: string): string => {
  const element = list.find(item => item.id === id);
  return element ? element.name : '';
};

export const getSeasonsTreeDeep = (methodology: CompleteMethodology, seasonsTree: SeasonTree[]): SeasonTreeDeep => {
  const seasonProperties = seasonsTree.map(seasonTree => seasonTree.seasonProperties).flat();
  const seasonAreas = seasonProperties.map(seasonProperty => seasonProperty.seasonAreas).flat();

  const seasons = seasonsTree.map(seasonTree => seasonTree.season);
  const areas = seasonAreas.map(area => ({ id: area.id, name: area.area.name }));

  return {
    seasons: methodology.season_ids.map(seasonId => {
      return { id: seasonId, name: getNameById(seasons, seasonId) };
    }),
    seasonAreas: methodology.season_area_ids.map(seasonAreaId => {
      return { id: seasonAreaId, name: getNameById(areas, seasonAreaId) };
    })
  };
};

const getCurrentIndicatorByMethodology = (indicator: MethodologyCustomIndicator[], id: string) => {
  return indicator.find(ind => ind.indicator_id === id);
};

export const getCurrentIndicatorFeatureFlag = (
  indicator: MethodologyCustomIndicator | undefined,
  toggle: IndicatorFeatureFlag
): boolean => {
  const currentFeatureFlag = indicator?.feature_flags.map(f => f.label);

  if (indicator) {
    return currentFeatureFlag ? !currentFeatureFlag.includes(toggle) : false;
  }
  return false;
};

const getCurrentDiagnosticFromIndicator = (indicator: MethodologyCustomIndicator | undefined): MethodologyDiagnostics[] => {
  return indicator ? indicator.diagnostics : [];
};

export const getPhenomenonDeep = (
  phenomenaByMethodology: Phenomenon[],
  custom_indicator_dtos: MethodologyCustomIndicator[]
): PhenomenonDeep[] => {
  const indicatorIdArray = custom_indicator_dtos.map(indicator => indicator.indicator_id);

  const phenomenonList: PhenomenonDeep[] = phenomenaByMethodology.map(phenomenon => ({
    id: phenomenon.id,
    name: phenomenon.name,
    scientificName: phenomenon.short_description,
    category: phenomenon.category,
    components:
      phenomenon.indicators
        ?.map(indicator => {
          const indicatorByMethodology = getCurrentIndicatorByMethodology(custom_indicator_dtos, indicator.id);

          return {
            indicator: {
              id: indicator.id,
              name: indicator.name,
              showOnTimeline: getCurrentIndicatorFeatureFlag(indicatorByMethodology, IndicatorFeatureFlag.NO_TIMELINE),
              showOnHeatmap: getCurrentIndicatorFeatureFlag(indicatorByMethodology, IndicatorFeatureFlag.NO_HEATMAP),
              diagnostics: getCurrentDiagnosticFromIndicator(indicatorByMethodology),
              phenomenonIds: indicator.phenomenon_ids,
              expression: indicator.expression
            }
          };
        })
        .filter(({ indicator }) => indicatorIdArray.includes(indicator.id)) ?? []
  }));

  return phenomenonList;
};

export const getCategoryDeep = (phenomenons: PhenomenonDeep[]): CategoryDeep[] => {
  const categoryArray: any = [];
  const categoryIdArray: any = [];
  phenomenons.forEach(phenomenon => {
    if (!categoryIdArray.includes(phenomenon.category.id)) {
      categoryArray.push(phenomenon.category);
      categoryIdArray.push(phenomenon.category.id);
    }

    return categoryArray;
  });

  return categoryArray.map(category => {
    return {
      ...category,
      phenomenons: phenomenons.filter(phenomenon => phenomenon.category.id === category.id)
    };
  });
};

export const convertToMethodologyDeep = (
  methodologyWithSeason: CompleteMethodology,
  phenomenaByMethodology: Phenomenon[],
  seasonsTree: SeasonTree[],
  userName: string,
  userRole: string
): MethodologyDeep => {
  const { id, name, description, modified_at, analytic_context_dto, crop_id } = methodologyWithSeason;
  const { custom_indicator_dtos } = analytic_context_dto;

  const phenomenons = getPhenomenonDeep(phenomenaByMethodology, custom_indicator_dtos);
  const seasonsTreeDeep = getSeasonsTreeDeep(methodologyWithSeason, seasonsTree);

  return {
    id,
    name,
    description,
    crop_id,
    seasonsTree: seasonsTreeDeep,
    analytic_context_dto,
    categories: getCategoryDeep(phenomenons),
    modified_at,
    last_modified_user: userName,
    last_modified_user_role: userRole
  };
};

export const updateMethodologyDeep = (
  methodologyDeep: MethodologyDeep,
  newIndicator: MethodologyCustomIndicator,
  methodologyAnalyticContextDTO: MethodologyAnalyticContextDTO,
  phenomenon: IEntityOption
): Observable<MethodologyDeep> => {
  const { complementId, id: phenomenonId } = phenomenon;
  const cloneMethodologyDeep = cloneDeep(methodologyDeep);

  const currentCategory = cloneMethodologyDeep.categories.filter(category => category.id === complementId)[0];

  const phenomenonArray = currentCategory?.phenomenons ?? [];
  const currentPhenomenon = phenomenonArray?.filter(p => p.id === phenomenonId)[0];

  const currentIndicator = currentPhenomenon?.components.filter(component => component.indicator.id === newIndicator.indicator_id)[0];

  const newIndicatorDiagnostics = newIndicator.diagnostics[0];
  currentIndicator.indicator.diagnostics = [
    {
      type: newIndicatorDiagnostics.type,
      thresholds: newIndicatorDiagnostics.thresholds
    }
  ];

  const currentPhenomenonComponentsList = currentPhenomenon?.components;
  const currentPhenomenonComponentsIndicatorIndex = currentPhenomenon?.components.findIndex(
    component => component.indicator.id === newIndicator.indicator_id
  );
  if (currentPhenomenonComponentsIndicatorIndex === -1) return of(cloneMethodologyDeep);

  currentPhenomenonComponentsList[currentPhenomenonComponentsIndicatorIndex] = currentIndicator;
  currentPhenomenon.components = currentPhenomenonComponentsList;

  const currentPhenomenonIndex = phenomenonArray.findIndex(cachePhenomenon => cachePhenomenon.id === currentPhenomenon.id);
  if (currentPhenomenonIndex === -1) return of(cloneMethodologyDeep);

  phenomenonArray[currentPhenomenonIndex] = currentPhenomenon;

  currentCategory.phenomenons = phenomenonArray;

  const currentCategoryIndex = cloneMethodologyDeep.categories.findIndex(category => category.id === currentCategory.id);
  if (currentCategoryIndex === -1) return of(cloneMethodologyDeep);

  cloneMethodologyDeep.categories[currentCategoryIndex] = currentCategory;
  cloneMethodologyDeep.analytic_context_dto = methodologyAnalyticContextDTO;

  return of(cloneMethodologyDeep);
};

export const validateUserInfo = (userIn: Record<'data', User>, key: string): string => {
  const hasUserInfo = Object.keys(userIn).length > 0;
  const hasKey = hasUserInfo && !!userIn?.data?.[key];

  return hasKey ? userIn.data[key] : '';
};
