export const SidebarAccordionActionsTypes = {
  SET_CURRENT_SCROLL: '[Sidebar Accordion] Set current scroll'
};

export const SetCurrentScroll = (currentScroll: number): { type: string; payload: number } => {
  return {
    type: SidebarAccordionActionsTypes.SET_CURRENT_SCROLL,
    payload: currentScroll
  };
};
