import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Nullable } from '../../core.models';

export type useArrayReturn<T> = [
  T[],
  {
    push: (value: T) => void;
    pop: () => void;
    remove: (value: T) => void;
    set: (value: T[]) => void;
    updateSingleItem: (value: T, mutation: CallableFunction) => void;
    updateSingleItemById: (id: string, mutation: CallableFunction, idFieldName?: string) => void;
  }
];

export function useArray<T>(initalValue: T[]): useArrayReturn<T> {
  const [array, set] = useState<T[]>(initalValue);

  const push = useCallback((value: T): void => {
    set(a => [...a, value]);
  }, []);

  const pop = useCallback((): void => {
    set(a => a.slice(0, -1));
  }, []);

  const remove = useCallback((value: T): void => {
    set(a => a.filter(v => v !== value));
  }, []);

  const updateSingleItem = useCallback((value: T, mutation: CallableFunction): void => {
    set(a => {
      const valueIndex = a.indexOf(value);
      const innerArray = a.filter((_, key) => key !== valueIndex);

      innerArray.splice(valueIndex, 0, mutation());
      return innerArray;
    });
  }, []);

  const updateSingleItemById = useCallback((id: UUID, mutation: CallableFunction, idFieldName = 'id'): void => {
    set(a => {
      const valueIndex = a.map(item => item[idFieldName]).indexOf(id);
      const innerArray = a.filter((_, key) => key !== valueIndex);

      innerArray.splice(valueIndex, 0, mutation());
      return innerArray;
    });
  }, []);

  const handlers = {
    push,
    pop,
    remove,
    set,
    updateSingleItem,
    updateSingleItemById
  };

  return [array, handlers];
}

export default function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

/**
 * Custom hook to show an error message popup based on errors passed as param on instantiation.
 * @param possibleErrors Errors to watch.
 */
export const useWatchErrors = (possibleErrors: Nullable<string>[]) => {
  const [t] = useTranslation();
  const [isSeenError, setIsSeenError] = useState(false);

  const displayError = (message: string, duration: number) => notification.error({ message, duration });

  const clear = () => {
    notification.destroy();
    setIsSeenError(false);
  };

  useEffect(() => {
    const hasError = possibleErrors.some(e => !!e);
    if (hasError && !isSeenError) {
      setIsSeenError(true);
      displayError(t('alert.error_refresh_page'), 0);
    }
  }, [...possibleErrors]);

  return [displayError, clear];
};
