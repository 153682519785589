import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import type React from 'react';
import { SomethingWentWrong } from './something-went-wrong.container';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <Sentry.ErrorBoundary
    fallback={SomethingWentWrong}
    onError={(error, componentStack) => {
      datadogRum.addError(error, {
        type: 'ErrorBoundary',
        stack: componentStack
      });
    }}
    beforeCapture={scope => {
      scope.setTag('crashScreen', true);
    }}>
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
