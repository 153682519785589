import { generateUUID } from 'core/utils/functions';
import type { AllIndicators } from 'entities/indicators/indicators.models';
import { getSeasonsByProperty } from 'entities/property/property.service';
import type { SeasonFlat } from 'entities/season/season.models';
import moment from 'moment';
import type { SetDateRangeDaysIndicatorsProps, SetDateRangeProps } from './timeline-table.models';

export const normalizeString = (value?: string): string => {
  const specialCharactersRegex = /[[\].!'@,><|://\\;&*()_+=]/g;

  if (!value) {
    return '';
  }

  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(specialCharactersRegex, '');
};

export const parseIndicators = (listIndicators: AllIndicators[]) => {
  let listCategory: any[] = [];
  let tempCategories: string[] = [];
  listIndicators.forEach(item => {
    if (!tempCategories.includes(item.category)) {
      tempCategories = [...tempCategories, item.category];
      listCategory = [...listCategory, { name: item.category, id: generateUUID() }];
    }
  });
  listCategory = [...listCategory].sort((itemA, itemB) => {
    if (normalizeString(itemA.name) > normalizeString(itemB.name)) {
      return 1;
    }
    if (normalizeString(itemA.name) < normalizeString(itemB.name)) {
      return -1;
    }
    return 0;
  });
  listCategory = listCategory.map(category => {
    const indicators = listIndicators.filter(item => item.category === category.name);
    return { ...category, indicators };
  });
  return listCategory;
};

export const generateIndicators = (categories, listIndicators) => {
  const listToWork = Object.entries(listIndicators);
  let result: any = {};
  result = {};
  listToWork.forEach(list => {
    if ((list[1] as any).monitoring_window) {
      result = { ...result, [list[0]]: { status: 'NOTRES' } };
      (list[1] as any).monitoring_window.samples.forEach(sample => {
        result = { ...result, [list[0]]: { ...result[list[0]], [sample.phenomenon.id]: 'NOTRES' } };
        sample.phenomenon.indicators.forEach(indicator => {
          result = {
            ...result,
            [list[0]]: {
              ...result[list[0]],
              [indicator.id]: {
                status: indicator.status || 'NOTRES',
                value: indicator.double_value,
                unit: indicator.unit,
                decimals: indicator.decimal_places
              }
            }
          };
          result = {
            ...result,
            [list[0]]: {
              ...result[list[0]],
              [sample.phenomenon.id]: compareStatus(result[list[0]][sample.phenomenon.id], indicator.status)
            }
          };
          result = {
            ...result,
            [list[0]]: { ...result[list[0]], ...{ status: compareStatus(result[list[0]].status, indicator.status) } }
          };
        });
      });
      result = { ...result, categories: { ...result.categories, [list[0]]: {} } };
      categories.forEach(category => {
        result = { ...result, categories: { ...result.categories, [list[0]]: { ...result.categories[list[0]], [category.id]: 'NONE' } } };
        category.indicators.forEach(indicator => {
          result = {
            ...result,
            categories: {
              ...result.categories,
              [list[0]]: {
                ...result.categories[list[0]],
                [category.id]: compareStatus(result.categories[list[0]][category.id], result[list[0]][indicator.id])
              }
            }
          };
        });
      });
    }
  });

  return result;
};

const convertStatus = status => {
  switch (status) {
    case 'NOTRES':
      return 1;
    case 'ACCEPTANCE':
      return 2;
    case 'CONTROL':
      return 3;
    case 'DAMAGE':
      return 4;
    case 'SPRAY':
      return 5;
    default:
      return 0;
  }
};

const compareStatus = (statusLatest, statusNew) => {
  if (convertStatus(statusNew) > convertStatus(statusLatest)) {
    return statusNew;
  }
  return statusLatest;
};

export const countTotalLines = list => {
  let count = 0;
  count = list.length;
  list.forEach(item => {
    if (item && item.show === false) {
      count += 0;
    } else {
      count += 1;
    }
  });
  return count;
};

export const createListToShow = (list, check) => {
  let result: any[] = [];

  list.forEach(item => {
    result = [...result, { name: item.name, id: item.id, level: 0, active: false, show: true }];
    const category1 = result[result.length - 1];

    item.indicators.forEach(level1Indicator => {
      if (check.includes(level1Indicator.id)) {
        result = [
          ...result,
          {
            name: level1Indicator.name,
            id: level1Indicator.id,
            description: level1Indicator.description,
            parentId: item.id,
            level: 1,
            show: false,
            active: false
          }
        ];

        level1Indicator.indicators.forEach(level2Indicator => {
          result = [
            ...result,
            {
              name: level2Indicator.name,
              id: level2Indicator.id,
              days: level2Indicator.days,
              parentId: level1Indicator.id,
              level: 2,
              show: false
            }
          ];
        });
      }
    });

    const category2 = result[result.length - 1];
    if (category1.id !== category2.parentId && category1.level === category2.level) {
      result = result.slice(0, -1);
    }
  });
  return result;
};
export const setDateRangeToGetDaysIndicators = async ({
  areas,
  propertyId,
  selectedSeasonsIds,
  flags
}: SetDateRangeDaysIndicatorsProps): Promise<SetDateRangeProps> => {
  let mutableStartDate: string | null = null;
  let mutableEndDate: string | null = null;

  const setDateRangeOldVersion = () => {
    areas?.forEach(area => {
      if (area.current_info?.last_visit) {
        if (!mutableStartDate || moment(area.current_info.last_visit).isBefore(mutableStartDate)) {
          mutableStartDate = moment(area.current_info.last_visit).format('YYYY-MM-DD');
        }
        if (!mutableEndDate || moment(area.current_info.last_visit).isAfter(mutableEndDate)) {
          mutableEndDate = moment(area.current_info.last_visit).format('YYYY-MM-DD');
        }
      }
    });
    return {
      startDate: mutableStartDate,
      endDate: mutableEndDate
    };
  };

  if (flags?.P40_34466_CHANGE_DATE_RANGE_TO_TABLE_MODE) {
    try {
      const seasons = await getSeasonsByProperty(propertyId).toPromise();
      const selectedSeasons: SeasonFlat[] = seasons?.filter(season => season.active && selectedSeasonsIds.includes(season.id));

      selectedSeasons?.forEach(season => {
        if (!mutableStartDate || moment(season.start_date).isBefore(mutableStartDate)) {
          mutableStartDate = moment(season.start_date).format('YYYY-MM-DD');
        }
        if (!mutableEndDate || moment(season.end_date).isAfter(mutableEndDate)) {
          mutableEndDate = moment(season.end_date).format('YYYY-MM-DD');
        }
      });

      return {
        startDate: mutableStartDate,
        endDate: mutableEndDate
      };
    } catch (error) {
      return setDateRangeOldVersion();
    }
  } else {
    return setDateRangeOldVersion();
  }
};
