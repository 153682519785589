const NewSvgAlertDialogOutline = props => (
  <svg width='15' height='15' viewBox='0 0 16 16' fill={props.fill} xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 0.5H14C14.825 0.5 15.5 1.175 15.5 2V11C15.5 11.825 14.825 12.5 14 12.5H3.5L0.5 15.5V2C0.5 1.175 1.175 0.5 2 0.5ZM2.8775 11H14V2H2V11.8775L2.8775 11ZM7.25 8H8.75V9.5H7.25V8ZM8.75 3.5H7.25V6.5H8.75V3.5Z'
      fill='#696F88'
    />
  </svg>
);

export default NewSvgAlertDialogOutline;
