enum AlertsTypeEnum {
  // Tãlhões não monitorados há X dias
  AREA_NOT_MONITORED = 'area_not_monitored_alert',
  // Talhão não monitorado X dias após aplicação
  AREA_NOT_MONITORED_AFTER_SPRAY = 'area_not_monitored_after_spray_alert',
  // Talhão piorou de estado
  AREA_STATUS_GOT_WORSE = 'protector_area_severity_level',
  // Talhão não teve aplicação ou prescrição X dias após piora de estado
  AREA_NOT_SPRAYED_AFTER_STATUS_WORSENING = 'area_not_sprayed_after_status_worsening_alert',

  DELAYED_MONITORING = 'area_not_monitored_alert',
  PRESCRIPTION = 'new_task_created',
  DISEASE_RISK_CHANGE = 'disease_risk',
  AREA_WORSENED = 'protector_area_severity_level',
  AREA_WORSENED_BY_STATUS = 'area_phenomenon_severity',

  PROTECTOR_BBCH_PREDICTION = 'protector_bbch_prediction'
}

export default AlertsTypeEnum;
