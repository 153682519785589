export enum TabTypes {
  protector_maps = 'protector_maps',
  remote_sensing = 'remote_sensing',
  machinery = 'machinery',
  map_files = 'map_files'
}

export enum TabChildrens {
  severity_level = 'severity_level',
  severity_per_indicator = 'severity_per_indicator',
  days_without_monitoring = 'days_without_monitoring',
  days_without_spraying = 'days_without_spraying',
  seeds = 'seeds',
  phenological_stage = 'phenological_stage',
  days_after_emergence = 'days_after_emergence',
  scouting_score = 'scouting_score',
  nematode = 'nematode',
  imagery = 'imagery',
  john_deere = 'john_deere',
  perfect_flight = 'perfect_flight',
  upload_files = 'upload_files',
  carbon_map = 'carbon_map'
}
