import type { AxiosResponse } from 'axios';
import axios from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import type { Moment } from 'moment';
import moment from 'moment';
import type { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { expand } from 'rxjs/operators';
import type { Nullable } from '../../core/core.models';
import type { UUID } from '../../core/utils/basic.models';
import type { Monitoring, MonitoringEditLogsResponse } from './monitoring.models';

const protectorApiUrl = PROTECTOR_API_URL;
const MONITORING_QUANTITY = 2000;
const DEFAULT_START_TIME = '00:00:00';
const DEFAULT_END_TIME = '23:59:59';

const getMonitoringAreaDay = (propertyId: UUID, fieldId: UUID, day: Moment | string): Observable<Monitoring[]> => {
  const formattedDay = moment(day).format('YYYY-MM-DD');
  const params = `?property_id=${propertyId}&field_id=${fieldId}&start=${formattedDay}&end=${formattedDay}&show_measures=false`;
  const urlEndpoint = `${protectorApiUrl}/v1/monitoring/by-property/area-day${params}`;
  return axios.get<Monitoring[]>(urlEndpoint).pipe(
    map(response => {
      if (response.status === 204) return [];
      return response.data;
    })
  );
};

interface MonitoringRequestResponse {
  monitorings: Monitoring[];
  range_key: string;
}

const getMonitoringPaginated = (
  propertyId: UUID,
  start: Moment | string,
  end: Moment | string,
  rangeKey: Nullable<string> = null
): Observable<AxiosResponse<MonitoringRequestResponse>> => {
  const formattedStart = moment(start).format('YYYY-MM-DD');
  const formattedEnd = moment(end).format('YYYY-MM-DD');
  const searchRangeKey = rangeKey ? `&range_key=${rangeKey}` : '';
  const monitoringQuantity = `&monitoring_quantity=${MONITORING_QUANTITY}`;
  const timeLimit = `&start_time=${DEFAULT_START_TIME}&end_time=${DEFAULT_END_TIME}`;
  const urlEndpoint = `${protectorApiUrl}/v1/monitoring/by-property?property_id=${propertyId}&start=${formattedStart}&end=${formattedEnd}&options=DONT_VALIDATE_CHARACTERISTIC_ALIASES${searchRangeKey}${monitoringQuantity}${timeLimit}`;
  return axios.get(urlEndpoint);
};

const getMonitoring = (propertyId: UUID, day: Moment | string): Observable<Monitoring[]> => {
  const formattedDay = moment(day).format('YYYY-MM-DD');
  return getMonitoringPaginated(propertyId, formattedDay, formattedDay).pipe(
    expand(v => (v.data.range_key ? getMonitoringPaginated(propertyId, formattedDay, formattedDay, v.data.range_key) : EMPTY)),
    map(a => a.data.monitorings)
  );
};

const getMonitoringByRange = (propertyId: UUID, start: Moment | string, end: Moment | string): Observable<Monitoring[]> => {
  const formattedStart = moment(start).format('YYYY-MM-DD');
  const formattedEnd = moment(end).format('YYYY-MM-DD');
  return getMonitoringPaginated(propertyId, formattedStart, formattedEnd).pipe(
    expand(v => (v.data.range_key ? getMonitoringPaginated(propertyId, formattedStart, formattedEnd, v.data.range_key) : EMPTY)),
    map(a => a.data.monitorings)
  );
};

const getMonitoringEditLogs = (propertyId: UUID, monitoringId: UUID, showOnlyLastUpdated = true) => {
  const urlParams = new URLSearchParams({
    property_id: propertyId,
    monitoring_id: monitoringId,
    show_only_last_updated: showOnlyLastUpdated ? 'true' : 'false'
  }).toString();

  const urlEndpoint = `${protectorApiUrl}/v1/monitoring/edit-logs?${urlParams}`;

  return axios.get<MonitoringEditLogsResponse>(urlEndpoint).pipe(map(response => response.data));
};

const updateMonitoringMeasurements = (monitoringId: UUID, propertyId: UUID, measurements: { measurement_id: string; value: number }[]) =>
  axios.patch<MonitoringEditLogsResponse>(`${protectorApiUrl}/v1/monitoring/${monitoringId}/measurements`, {
    property_id: propertyId,
    measurements
  });

const MonitoringService = {
  getMonitoring,
  getMonitoringAreaDay,
  getMonitoringEditLogs,
  updateMonitoringMeasurements,
  getMonitoringByRange
};

export default MonitoringService;
