import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { AxiosObservable } from 'axios-observable';
import axiosObservable from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import { getIsoCodeByName } from 'core/utils/isoManager/isoManager';
import type { PropertyInfoAddress, PropertyInfoAddressPoints } from 'entities/property/property.models';
import type { SprayWindowDailySummaryDatum, SprayWindowDailySummaryRequestBody } from '../../../../pages/disease-risk/disease-risk.models';
import type { DateISOString } from '../../../utils/basic.models';
import WeatherDataSourceEnum from './weather-data-source.enum';
import type { LoadWeatherParams, WeatherBody } from './weather.models';

const protectorApiUrl = PROTECTOR_API_URL;
const filtersMeteoblue =
  '?filters=temp_air_avg,temp_air_max,temp_air_min,wind_speed_avg,precip_sum,precip_probability,humidity_rel_avg,picto_code';
const filtersClimatempo =
  '?filters=temp_air_avg,temp_air_max,temp_air_min,precip_sum,precip_probability,picto_code,humidity_rel_min,humidity_rel_max';

const filtersHourly = '?filters=temp_air_avg,humidity_rel_avg,wind_speed_avg,wind_direction_avg,picto_code';
const dailyForecastsUrl = `${protectorApiUrl}/weather/daily-forecasts`;
const hourlyForecastsUrl = `${protectorApiUrl}/weather/hourly-forecasts`;

export const getWeather = (request: LoadWeatherParams) => {
  const paramsWeather = {
    geometry: {
      type: 'Point',
      coordinates: [request.lat, request.long]
    }
  };
  const filterRequest = request.dataSource === WeatherDataSourceEnum.METEOBLUE ? filtersMeteoblue : filtersClimatempo;
  return axiosObservable.post<WeatherBody[]>(`${dailyForecastsUrl}${filterRequest}&provider=${request.dataSource}`, paramsWeather, {
    timeout: 10000
  });
};

export const getWeatherHours = (request: LoadWeatherParams) => {
  const paramsWeather = {
    geometry: {
      type: 'Point',
      coordinates: [request.lat, request.long]
    }
  };
  return axiosObservable.post<WeatherBody[]>(`${hourlyForecastsUrl}${filtersHourly}&provider=${request.dataSource}`, paramsWeather, {
    timeout: 10000
  });
};

export const getDiseaseRiskWeatherDates = (
  startDate: DateISOString,
  endDate: DateISOString,
  geometry: PropertyInfoAddressPoints,
  shouldIncludeRain = false
): AxiosObservable<WeatherBody[]> => {
  const filters = `picto_code,temp_air_avg${shouldIncludeRain ? ',precip_sum' : ''}`;

  return axiosObservable.post<WeatherBody[]>(
    `${dailyForecastsUrl}?start_date=${startDate}&end_date=${endDate}&filters=${filters}`,
    {
      geometry
    },
    {
      timeout: 10000
    }
  );
};

export const getDiseaseRiskSprayWindowDailySummary = (
  propertyData: PropertyInfoAddress
): Promise<AxiosResponse<SprayWindowDailySummaryDatum[]>> => {
  const body: SprayWindowDailySummaryRequestBody = {
    location: [...propertyData.referencePoint.coordinates].reverse(),
    country_code: propertyData?.regulatoryZone || getIsoCodeByName(propertyData?.country)
  };

  return axios.post(`${protectorApiUrl}/v2/spray-status/hourly-count`, body);
};
