const SvgWeatherGeneric = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M21.5635 10.2512C21.845 9.55604 22.0001 8.79613 22.0001 8C22.0001 4.68629 19.3138 2 16.0001 2C14.2231 2 12.6265 2.77252 11.5278 4.00005C14.5277 4.01091 17.1125 5.78299 18.3021 8.33615C19.5427 8.70827 20.6566 9.37341 21.5635 10.2512Z'
      fill='#FFCA28'
    />
    <path
      d='M6 11.5C6 11.7006 6.01074 11.8987 6.03167 12.0937C3.73367 12.5447 2 14.5699 2 17C2 19.7614 4.23858 22 7 22H16C19.3137 22 22 19.3137 22 16C22 12.9603 19.7396 10.4485 16.8079 10.0539C16.173 7.71789 14.0371 6 11.5 6C8.46243 6 6 8.46243 6 11.5Z'
      fill='#8CB5C9'
    />
  </svg>
);

export default SvgWeatherGeneric;
