import type { AlertBody } from 'entities/alerts/alerts.models';

export interface OpenDrawer {
  open: boolean | null;
  itemEdit?: AlertBody;
}

export interface AlertDrawerProps {
  visible: boolean;
  callbackDrawer: React.Dispatch<React.SetStateAction<OpenDrawer>>;
  contentAlert?: AlertBody;
  form?;
}
interface AlertType {
  name: string;
  days: boolean;
}
export interface AreasTreeType {
  title: string;
  value: string;
  key: string;
}

export const alertTypes: AlertType[] = [
  { name: 'area_not_monitored_alert', days: true },
  { name: 'protector_area_severity_level', days: false }
];

export interface HandleSuccessProps {
  messageType: 'EDIT' | 'NEW' | 'DELETE';
  propertyId?: string;
  propertyName?: string;
  selectType: string;
  areasTree: AreasTreeType[];
}
export interface UseHandleDispatchSegmentMessagesProps {
  handleSuccess: ({ messageType, propertyId, propertyName, selectType, areasTree }: HandleSuccessProps) => void;
}

export interface Generic {
  name: string;
  id: string;
}

export interface Properties {
  season: Season;
  seasonProperties: SeasonProperty[];
}

interface Season {
  createdAt: string;
  updatedAt: string;
  name: string;
  startsAt: string;
  endsAt: string;
  cropId: string;
  id: string;
}

interface SeasonProperty {
  property: Property;
  seasonAreas: SeasonsAreas[];
  id: string;
}

interface Property {
  name: string;
  id: string;
}

export interface SeasonsAreas {
  area: Property;
  id: string;
  varieties: string;
  seasonPropertyId: null;
  areaInHectares: null;
}
