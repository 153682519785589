import type { Phenomenon, PhenomenonSingle } from 'entities/methodology-deep/methodology-deep.models';

export const parsePhenomenonSingleToPhenomenon = (single: PhenomenonSingle): Phenomenon => {
  return {
    category: single.category,
    cohese: single.cohese,
    company_id: single.company_id,
    created_at: single.created_at,
    description: single.description,
    id: single.id,
    isNew: single.isNew,
    is_canonical: single.is_canonical,
    modified_at: single.modified_at,
    name: single.name,
    parent_id: single.parent_id,
    short_description: single.short_description,
    characteristics: single?.characteristics_dto ? single.characteristics_dto : [],
    indicators: single?.indicators_dto ? single.indicators_dto : []
  };
};
