const SvgReportConsolidatedCard = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <g clipPath='url(#clip0_11146_102540)'>
      <rect width='32' height='32' rx='8' fill='white' />
      <rect width='32' height='32' rx='4' fill='#FFF1F1' />
      <path
        d='M17.6275 10.75L21.25 14.3725V21.25H10.75V10.75H17.6275ZM17.6275 9.25H10.75C9.925 9.25 9.25 9.925 9.25 10.75V21.25C9.25 22.075 9.925 22.75 10.75 22.75H21.25C22.075 22.75 22.75 22.075 22.75 21.25V14.3725C22.75 13.975 22.5925 13.5925 22.3075 13.315L18.685 9.6925C18.4075 9.4075 18.025 9.25 17.6275 9.25ZM12.25 18.25H19.75V19.75H12.25V18.25ZM12.25 15.25H19.75V16.75H12.25V15.25ZM12.25 12.25H17.5V13.75H12.25V12.25Z'
        fill='#CF3537'
      />
    </g>
    <defs>
      <clipPath id='clip0_11146_102540'>
        <rect width='32' height='32' rx='8' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SvgReportConsolidatedCard;
