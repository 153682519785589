const SvgWeatherDailyMostlyCloudWithLightSnow = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M7.7002 19L6.7002 20L7.7002 21L6.7002 22L7.7002 23L8.7002 22L9.7002 23L10.7002 22L9.8002 21L10.7002 20L9.7002 19L8.7002 19.9L7.7002 19Z'
      fill='#82CFFF'
    />
    <path
      d='M14.2998 19L13.2998 20L14.1998 21L13.2998 22L14.2998 23L15.2998 22L16.2998 23L17.2998 22L16.2998 21L17.2998 20L16.2998 19L15.2998 19.9L14.2998 19Z'
      fill='#82CFFF'
    />
    <path
      d='M16.5042 9.70822L15.7982 8.03322C16.4282 7.76922 16.8362 7.15222 16.8362 6.46622C16.8362 5.53122 16.0732 4.76322 15.1362 4.76322C14.3002 4.76322 13.5762 5.38722 13.4542 6.21022C13.4402 6.29522 13.4332 6.38122 13.4332 6.46622H11.6152C11.6152 6.29022 11.6292 6.11122 11.6542 5.94022C11.9122 4.23322 13.4092 2.94922 15.1362 2.94922C17.0742 2.94922 18.6542 4.52722 18.6542 6.46722C18.6542 7.88322 17.8112 9.15822 16.5042 9.70822Z'
      fill='#F0C355'
    />
    <path d='M13.1918 4.54793L10.5312 3.50391L9.86677 5.19721L12.5273 6.24124L13.1918 4.54793Z' fill='#F0C355' />
    <path d='M14.9698 3.70798L13.7266 1.13672L12.0898 1.92809L13.333 4.49935L14.9698 3.70798Z' fill='#F0C355' />
    <path d='M17.6746 1.72264L15.9434 1.16797L15.1153 3.75251L16.8466 4.30718L17.6746 1.72264Z' fill='#F0C355' />
    <path d='M20.2911 4.54999L19.373 2.98438L16.9103 4.42843L17.8284 5.99405L20.2911 4.54999Z' fill='#F0C355' />
    <path d='M20.5755 6.74952L17.7852 6.14062L17.3976 7.91681L20.1879 8.52571L20.5755 6.74952Z' fill='#F0C355' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.025 8.48837C15.048 6.79737 13.243 5.73438 11.254 5.73438C8.499 5.73438 6.208 7.77337 5.811 10.4204C4.2 10.8094 3 12.2644 3 13.9904C3 16.0164 4.649 17.6664 6.674 17.6664L15.838 17.6684C18.368 17.6684 20.428 15.6074 20.428 13.0774C20.427 10.6064 18.468 8.58737 16.025 8.48837ZM15.838 15.8144H6.676C5.67 15.8144 4.851 14.9964 4.851 13.9904C4.851 12.9864 5.671 12.1664 6.676 12.1664H7.6V11.2434C7.6 9.22936 9.241 7.58636 11.256 7.58636C12.725 7.58636 14.043 8.45736 14.621 9.80836L14.889 10.4344L15.838 10.3334C17.349 10.3334 18.578 11.5644 18.578 13.0764C18.578 14.5864 17.349 15.8144 15.838 15.8144Z'
      fill='#868CA2'
    />
  </svg>
);

export default SvgWeatherDailyMostlyCloudWithLightSnow;
