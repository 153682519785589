import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PROTECTOR_API_URL } from 'config/constants';
import type { UUID, V4Page } from 'core/utils/basic.models';
import type { PerfectFlightGeometriesEnum } from 'pages/timeline/multilayer/components/perfect-flight/perfect-flight.models';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type {
  AreasDiffResponse,
  IntegratePerfectFlightPayload,
  JDOCAreasDiffResponse,
  JDOCFarmsResponse,
  JDOCOrganizationsResponse,
  JDOCPayloadDiffResponse,
  JDOCPaylodDiffAreas,
  PerfectFlightApplication,
  PerfectFlightAreaDiff,
  PerfectFlightDetailsResponse,
  TrapViewAccessProperty,
  TrapViewIntegrateExternal,
  TrapViewIntegratePayload,
  TrapViewProperty,
  TrapviewDeletePayload,
  TrapviewDevice,
  TrapviewInstallDevice
} from './integrations.models';

const protectorApiUrl = PROTECTOR_API_URL;
const JDOCBaseUrl = `${protectorApiUrl}/api/v1/john-deere`;
const authenticateJDOCUrl = (url: string, propertyId: UUID) => `${JDOCBaseUrl}/oauth/request?callback_url=${url}&property_id=${propertyId}`;
const getAuthenticateJDOCUrl = (id: UUID) => `${JDOCBaseUrl}/oauth/authenticated?property_id=${id}`;
const revokeAccessJDOCUrl = (id: UUID) => `${JDOCBaseUrl}/oauth/revoke?property_id=${id}`;
const postPermissionsJDOCUrl = `${JDOCBaseUrl}/permissions`;
const permissionsJDOCUrl = (id: UUID) => `${postPermissionsJDOCUrl}?property_id=${id}`;
const getOrganizationsJDOCUrl = (id: UUID) => `${JDOCBaseUrl}/organizations?property_id=${id}`;
const getFarmsByOrganizationsJDOCUrl = (orgId: string, propId: UUID) => `${JDOCBaseUrl}/organizations/${orgId}/farms?property_id=${propId}`;
const getAreasDiffUrl = (orgId: string, farmId: UUID, propId: UUID) =>
  `${JDOCBaseUrl}/organizations/${orgId}/farms/${farmId}/area-diff/report?property_id=${propId}`;

const postAreasDiffUrl = (orgId: string, farmId: UUID, propId: UUID) =>
  `${JDOCBaseUrl}/organizations/${orgId}/farms/${farmId}/area-diff?property_id=${propId}`;

const perfectFlightURL = `${protectorApiUrl}/api/v1/perfect-flight`;
const perfectFlightAuthURL = `${perfectFlightURL}/oauth`;

const updateDiffUrl = `${protectorApiUrl}/api/v1/diff/likeness`;

const trapViewUrl = `${protectorApiUrl}/api/v1/trapview`;

export const authenticateJDOC = (url: string, propertyId: UUID): Observable<any> => {
  return axios.get(authenticateJDOCUrl(url, propertyId));
};

export const getAuthenticateJDOC = (id: UUID): Observable<any> => {
  return axios.get(getAuthenticateJDOCUrl(id));
};

export const getPermissionsJDOC = (id: UUID): Observable<any> => {
  return axios.get(permissionsJDOCUrl(id));
};

export const postPermissionsJDOC = (id: UUID): Observable<any> => {
  return axios
    .post(postPermissionsJDOCUrl, {
      property_id: id,
      permissions: ['IMPORT_HARVEST_MAP', 'IMPORT_SEEDING_MAP', 'IMPORT_APPLICATION_MAP']
    })
    .pipe(
      map(response => {
        return response.data.content;
      })
    );
};

export const revokeAccessJDOC = (id: UUID): Observable<any> => {
  return axios.delete(revokeAccessJDOCUrl(id));
};

export const getOrganizationsJDOC = (propertyId: UUID): Observable<JDOCOrganizationsResponse[]> => {
  return axios.get<V4Page<JDOCOrganizationsResponse>>(getOrganizationsJDOCUrl(propertyId)).pipe(
    map(response => {
      return response.data.content;
    })
  );
};

export const getFarmsByOrganizationsJDOC = (orgId: string, propId: UUID): Observable<JDOCFarmsResponse[]> => {
  return axios.get<V4Page<JDOCFarmsResponse>>(getFarmsByOrganizationsJDOCUrl(orgId, propId)).pipe(
    map(response => {
      return response.data.content;
    })
  );
};

export const getAreasDiffJDOC = (orgId: string, farmId: UUID, propId: UUID): Observable<AreasDiffResponse[]> => {
  return axios.get<V4Page<AreasDiffResponse>>(getAreasDiffUrl(orgId, farmId, propId)).pipe(
    map(response => {
      return response.data.content;
    })
  );
};

export const postAreasDiffJDOC = (orgId: string, farmId: UUID, propId: UUID, data: JDOCAreasDiffResponse[]): Observable<any> => {
  return axios.post(postAreasDiffUrl(orgId, farmId, propId), data).pipe(
    map(response => {
      return response.data.content;
    })
  );
};

export const postUpdateDiffJDOC = (data: JDOCPaylodDiffAreas): Observable<JDOCPayloadDiffResponse> => {
  return axios.post<JDOCPayloadDiffResponse>(updateDiffUrl, data).pipe(
    map(response => {
      return response.data;
    })
  );
};

export const getAuthenticateStatusPerfectFlight = (propertyId: UUID) => {
  return axios.get<boolean>(`${perfectFlightAuthURL}/authenticated?propertyId=${propertyId}`);
};

export const postIntegratePerfectFlight = (payload: IntegratePerfectFlightPayload) => {
  return axios.post(`${perfectFlightAuthURL}/authorize`, payload);
};

export const getPerfectFlightProperties = (propertyId: UUID) => {
  return axios.get(`${protectorApiUrl}/api/v1/perfect-flight/properties/${propertyId}`);
};

export const getPerfectFlightAreasDiff = (propertyId: UUID, pfPropertyId: string) => {
  return axios.get(`${protectorApiUrl}/api/v1/perfect-flight/properties/${propertyId}/area-diff/report?pfPropertyId=${pfPropertyId}`);
};

export const postPerfectFlightAreasDiff = (body: PerfectFlightAreaDiff[], propertyId, pfPropertyId) => {
  return axios.post(`${protectorApiUrl}/api/v1/perfect-flight/properties/${propertyId}/area-diff?pfPropertyId=${pfPropertyId}`, body);
};

export const revokePerfectFlightAccess = (propertyId: UUID) => {
  return axios.delete(`${perfectFlightAuthURL}/revoke?propertyId=${propertyId}`);
};

export const getPerfectFlightApplications = (propertyId: UUID, startDate: string, endDate: string) => {
  return axios.get<PerfectFlightApplication[]>(`${protectorApiUrl}/api/v1/perfect-flight/properties/${propertyId}/applications`);
};

export const getPerfectFlightApplicationDetails = (propertyId: UUID, hash: string) => {
  return axios.get<PerfectFlightDetailsResponse>(`${protectorApiUrl}/api/v1/perfect-flight/properties/${propertyId}/applications/${hash}`);
};

export const getPerfectFlightApplicationGeometry = (propertyId: UUID, hash: string, type: PerfectFlightGeometriesEnum) => {
  return axios.get<any>(`${protectorApiUrl}/api/v1/perfect-flight/properties/${propertyId}/applications/${hash}/geometries?type=${type}`);
};

export const getPerfectFlightApplicationTrack = (propertyId: UUID, hash: string) => {
  return axios.get<any[]>(`${protectorApiUrl}/api/v1/perfect-flight/properties/${propertyId}/applications/${hash}/tracking`);
};

export const getAccessTrapView = (companyId: UUID): AxiosObservable<TrapViewAccessProperty> => {
  return axios.get<TrapViewAccessProperty>(`${protectorApiUrl}/api/v1/companies/${companyId}/trapview-access`);
};

export const getAuthenticateTrapView = (propertyId: UUID): AxiosObservable<TrapViewProperty> => {
  return axios.get<TrapViewProperty>(`${trapViewUrl}/integration/${propertyId}`);
};

export const postIntegrateTrapViewExternal = (body: TrapViewIntegrateExternal): AxiosObservable<TrapViewProperty> => {
  return axios.post(`${trapViewUrl}/integration`, body);
};

export const postIntegrateTrapView = (body: TrapViewIntegratePayload): AxiosObservable<string> => {
  return axios.post(`${trapViewUrl}/integration`, body);
};

export const revokeTrapView = (propertyId: UUID) => {
  return axios.delete(`${trapViewUrl}/integration/${propertyId}`);
};

export const getTrapViewDevices = (propertyId: UUID): AxiosObservable<TrapviewDevice[]> => {
  return axios.get<any[]>(`${trapViewUrl}/devices/${propertyId}`);
};

export const getTrapViewBinding = (organizationId: UUID): AxiosObservable<string[]> => {
  return axios.get<any[]>(`${trapViewUrl}/binding/${organizationId}`);
};

export const postInstallTrapView = (body: TrapviewInstallDevice): AxiosObservable<string> => {
  const { organization_id, property_id, account_id } = body;
  return axios.post(`${trapViewUrl}/devices?organization_id=${organization_id}&property_id=${property_id}&account_id=${account_id}`, body);
};

export const deleteTrapViewDevice = (body: TrapviewDeletePayload): AxiosObservable<string> => {
  return axios.delete(`${trapViewUrl}/devices`, { data: body });
};
