const SvgWeatherHourlyClearCloudless = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M12.09 17.058C9.18098 17.058 6.81598 14.692 6.81598 11.782C6.81598 8.873 9.18098 6.506 12.09 6.506C14.999 6.506 17.365 8.873 17.365 11.782C17.365 14.691 14.999 17.058 12.09 17.058ZM12.09 8.337C10.19 8.337 8.64498 9.882 8.64498 11.781C8.64498 13.684 10.189 15.231 12.09 15.231C13.991 15.231 15.537 13.684 15.537 11.781C15.537 9.882 13.99 8.337 12.09 8.337Z'
      fill='#DFA701'
    />
    <path
      d='M3.22547 10.897H1.98347C1.47841 10.897 1.06897 11.3064 1.06897 11.8115C1.06897 12.3166 1.47841 12.726 1.98347 12.726H3.22547C3.73053 12.726 4.13997 12.3166 4.13997 11.8115C4.13997 11.3064 3.73053 10.897 3.22547 10.897Z'
      fill='#DFA701'
    />
    <path
      d='M22.0235 10.897H20.6375C20.1325 10.897 19.723 11.3064 19.723 11.8115C19.723 12.3166 20.1325 12.726 20.6375 12.726H22.0235C22.5286 12.726 22.938 12.3166 22.938 11.8115C22.938 11.3064 22.5286 10.897 22.0235 10.897Z'
      fill='#DFA701'
    />
    <path
      d='M12.958 3.3695V1.9835C12.958 1.47844 12.5486 1.069 12.0435 1.069C11.5385 1.069 11.129 1.47844 11.129 1.9835V3.3695C11.129 3.87456 11.5385 4.284 12.0435 4.284C12.5486 4.284 12.958 3.87456 12.958 3.3695Z'
      fill='#DFA701'
    />
    <path
      d='M12.958 22.0235V20.6415C12.958 20.1364 12.5486 19.727 12.0435 19.727C11.5385 19.727 11.129 20.1364 11.129 20.6415V22.0235C11.129 22.5286 11.5385 22.938 12.0435 22.938C12.5486 22.938 12.958 22.5286 12.958 22.0235Z'
      fill='#DFA701'
    />
    <path
      d='M4.51507 5.45311L5.49498 6.43342C5.85186 6.79046 6.43061 6.79058 6.78765 6.4337C7.14468 6.07681 7.14481 5.49806 6.78792 5.14103L5.80802 4.16071C5.45113 3.80367 4.87239 3.80355 4.51535 4.16043C4.15831 4.51732 4.15819 5.09607 4.51507 5.45311Z'
      fill='#DFA701'
    />
    <path
      d='M17.7635 18.7007L18.7431 19.6811C19.1001 20.0384 19.6791 20.0387 20.0364 19.6817C20.3937 19.3247 20.3939 18.7457 20.0369 18.3884L19.0573 17.4079C18.7003 17.0507 18.1213 17.0504 17.764 17.4074C17.4067 17.7644 17.4065 18.3434 17.7635 18.7007Z'
      fill='#DFA701'
    />
    <path
      d='M18.5787 4.3249L17.6007 5.30282C17.2434 5.66014 17.2434 6.23948 17.6007 6.59681C17.9581 6.95413 18.5374 6.95413 18.8947 6.59681L19.8727 5.61889C20.23 5.26156 20.23 4.68222 19.8727 4.3249C19.5153 3.96757 18.936 3.96757 18.5787 4.3249Z'
      fill='#DFA701'
    />
    <path
      d='M5.32749 17.5724L4.3514 18.5493C3.99403 18.907 3.99427 19.4866 4.35195 19.844C4.70962 20.2014 5.28928 20.2011 5.64665 19.8434L6.62274 18.8665C6.98011 18.5088 6.97986 17.9292 6.62219 17.5718C6.26452 17.2144 5.68486 17.2147 5.32749 17.5724Z'
      fill='#DFA701'
    />
  </svg>
);

export default SvgWeatherHourlyClearCloudless;
