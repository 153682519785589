const SvgWeatherDailyOvercastWithSnow = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M6.2002 10.4984H7.0002V11.2984H6.2002C4.27634 11.2984 2.7002 9.7223 2.7002 7.79844C2.7002 5.9628 4.1931 4.38506 6.02398 4.29787L6.29127 4.28514L6.42894 4.05569C7.24551 2.69474 8.58889 1.89844 10.1002 1.89844C12.2351 1.89844 14.1237 3.75792 14.3725 5.99844H13.5658C13.4559 5.19928 13.0901 4.45356 12.5722 3.87687C11.9481 3.1818 11.0704 2.69844 10.1002 2.69844C8.70575 2.69844 7.42682 3.51157 6.84501 4.79154L6.83415 4.81543L6.82585 4.84032L6.75781 5.04445L6.47091 5.00346L6.43573 4.99844H6.4002H6.3002H6.2002C4.72405 4.99844 3.5002 6.22229 3.5002 7.69844C3.5002 9.14559 4.69593 10.4984 6.2002 10.4984Z'
      fill='#C2C7D0'
      stroke='#C2C7D0'
    />
    <path
      d='M17.6002 18.2008H8.0002C5.9002 18.2008 4.2002 16.5008 4.2002 14.4008C4.2002 12.6008 5.5002 11.1008 7.1002 10.7008C7.5002 8.00078 9.9002 5.80078 12.8002 5.80078C14.9002 5.80078 16.8002 6.90078 17.8002 8.70078C20.3002 8.80078 22.4002 10.9008 22.4002 13.4008C22.3002 16.1008 20.2002 18.2008 17.6002 18.2008ZM8.0002 12.4008C6.9002 12.4008 6.0002 13.3008 6.0002 14.4008C6.0002 15.5008 6.9002 16.4008 8.0002 16.4008H17.6002C19.2002 16.4008 20.6002 15.1008 20.6002 13.4008C20.6002 11.8008 19.3002 10.4008 17.6002 10.4008H17.5002C17.4002 10.4008 17.4002 10.4008 17.3002 10.4008L16.7002 10.5008L16.4002 10.0008C15.8002 8.50078 14.4002 7.60078 12.8002 7.60078C10.6002 7.60078 8.9002 9.40078 8.9002 11.5008V12.4008H8.0002Z'
      fill='#868CA2'
    />
    <path
      d='M6.1002 19L5.2002 19.9L6.0002 20.7L5.2002 21.6L6.1002 22.5L6.9002 21.6L7.8002 22.5L8.7002 21.6L7.8002 20.7L8.7002 19.9L7.8002 19L6.9002 19.8L6.1002 19Z'
      fill='#82CFFF'
    />
    <path
      d='M11.8004 19L10.9004 19.9L11.7004 20.7L10.9004 21.6L11.8004 22.5L12.6004 21.6L13.5004 22.5L14.4004 21.6L13.5004 20.7L14.4004 19.9L13.5004 19L12.6004 19.8L11.8004 19Z'
      fill='#82CFFF'
    />
    <path
      d='M17.4996 19L16.5996 19.9L17.3996 20.7L16.5996 21.6L17.4996 22.5L18.2996 21.6L19.1996 22.5L20.0996 21.6L19.1996 20.7L20.0996 19.9L19.1996 19L18.2996 19.8L17.4996 19Z'
      fill='#82CFFF'
    />
  </svg>
);

export default SvgWeatherDailyOvercastWithSnow;
