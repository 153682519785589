import axios from 'axios';
import { PROTECTOR_API_URL } from 'config/constants';
import { handleMessages, useHandleDispatchSegmentMessages } from 'core/components/header/components/alert/alert.functions';
import type { UUID } from 'core/utils/basic.models';
import { useMutation, useQuery, useQueryClient } from 'core/utils/data-store/tools';
import type { AlertBody } from 'entities/alerts/alerts.models';
import type { Property } from 'entities/property/property.models';
import type { Region } from 'entities/region/region.models';
import { useTranslation } from 'react-i18next';
import type { CreateAndEditAlertParams, DeleteAlertParams } from './alerts.models';

const protectorApiUrl = PROTECTOR_API_URL;
const regionUrl = `${protectorApiUrl}/v1/panel/property/`;
const alertsUrl = `${protectorApiUrl}/v1/alerts`;

export const getRegionsToAlerts = async (regionId: UUID, propertyId: UUID, seasonIds: UUID[]): Promise<Region> => {
  const response = await axios.post<Region>(`${regionUrl}${propertyId}/regions/${regionId}?allowPastSeasons=true`, {
    recursive: 'full',
    fields: [],
    season_ids: seasonIds
  });

  return response.data;
};

export const useGetRegionsToAlerts = (property?: Property) => {
  const queryClient = useQueryClient();
  const rootRegionId = property?.root_region_id;
  const propertyId = property?.id;
  const seasonIds = property?.seasons.map(season => season.id);

  return useQuery(['regions-to-alerts', propertyId], async () => {
    if (!rootRegionId || !propertyId || !seasonIds) return undefined;

    const cachedRootRegion: Region | undefined = queryClient.getQueryData(['regions-to-alerts', propertyId]);
    if (cachedRootRegion) return cachedRootRegion;

    return getRegionsToAlerts(rootRegionId, propertyId, seasonIds);
  });
};

export const deleteAlert = async (payload: DeleteAlertParams) => {
  const response = await axios.delete(`${alertsUrl}/${payload.alertId}`);
  return response.data;
};

export const useDeleteAlert = (closeDrawer: () => void) => {
  const [t] = useTranslation();
  const { handleSuccess } = useHandleDispatchSegmentMessages();

  const { mutate: onDeleteAlert, ...mutation } = useMutation(deleteAlert, {
    onSuccess: (_, { propertyId, propertyName, selectType, areasTree }) => {
      handleSuccess({
        messageType: 'DELETE',
        propertyId,
        propertyName,
        selectType,
        areasTree
      });
      closeDrawer();
    },
    onError: () => {
      handleMessages('error', t('alert.messages.error'));
    }
  });

  return { ...mutation, onDeleteAlert };
};

export const editAlert = async (payload: CreateAndEditAlertParams) => {
  const { alert, daysNumberField, revisit_time } = payload;
  const { type, id, company_id, property_id, owner_id, name, areas, send_email } = alert;
  const body = {
    type,
    id,
    company_id,
    property_id,
    owner_id,
    name,
    areas,
    send_email,
    ...(!daysNumberField && { extra_parameters: { revisit_time } })
  };
  const response = await axios.put<AlertBody>(alertsUrl, body);

  return response.data;
};

export const useEditAlert = (closeDrawer: () => void) => {
  const [t] = useTranslation();
  const { handleSuccess } = useHandleDispatchSegmentMessages();

  const { mutate: onEditAlert, ...mutation } = useMutation(editAlert, {
    onSuccess: (_, { alert: { property_id, type }, propertyName, areasTree }) => {
      handleSuccess({
        messageType: 'EDIT',
        propertyId: property_id,
        propertyName,
        selectType: type,
        areasTree
      });
      closeDrawer();
    },
    onError: () => {
      handleMessages('error', t('alert.messages.error'));
    }
  });

  return { ...mutation, onEditAlert };
};

export const createAlert = async (payload: CreateAndEditAlertParams) => {
  const { alert, daysNumberField, revisit_time } = payload;
  const { type, id, company_id, property_id, owner_id, name, areas, send_email } = alert;
  const body = {
    type,
    id,
    company_id,
    property_id,
    owner_id,
    name,
    areas,
    send_email,
    ...(!daysNumberField && { extra_parameters: { revisit_time } })
  };

  const response = await axios.post<AlertBody>(alertsUrl, body);
  return response.data;
};

export const useCreateAlert = (closeDrawer: () => void) => {
  const [t] = useTranslation();
  const { handleSuccess } = useHandleDispatchSegmentMessages();

  const { mutate: onCreateAlert, ...mutation } = useMutation(createAlert, {
    onSuccess: (_, { alert: { property_id, type }, propertyName, areasTree }) => {
      handleSuccess({
        messageType: 'NEW',
        propertyId: property_id,
        propertyName,
        selectType: type,
        areasTree
      });
      closeDrawer();
    },
    onError: () => {
      handleMessages('error', t('alert.messages.error'));
    }
  });

  return { ...mutation, onCreateAlert };
};
