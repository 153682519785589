const SvgWeatherCloudyLightRain = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M18.512 11.081L17.385 9.663C17.966 9.201 18.298 8.514 18.298 7.772C18.298 6.441 17.216 5.36 15.884 5.36C14.7 5.36 13.678 6.242 13.5 7.406C13.48 7.528 13.472 7.65 13.472 7.77H11.664C11.664 7.561 11.679 7.344 11.712 7.135C12.023 5.091 13.816 3.55 15.884 3.55C18.213 3.55 20.108 5.443 20.108 7.771C20.107 9.067 19.525 10.271 18.512 11.081Z'
      fill='#DFA701'
    />
    <path
      d='M14.6307 4.76905C14.1839 4.98506 13.6465 4.79839 13.4298 4.35188L12.6428 2.73008C12.4219 2.27494 12.624 1.72744 13.0877 1.525C13.5226 1.33509 14.03 1.51955 14.2414 1.94442L15.0446 3.55831C15.2677 4.00673 15.0817 4.55106 14.6307 4.76905Z'
      fill='#DFA701'
    />
    <path
      d='M12.2188 5.93224L10.5436 5.2738C10.0809 5.09198 9.55853 5.3196 9.37671 5.78221C9.19488 6.24482 9.42251 6.76724 9.88512 6.94906L11.5604 7.6075C12.023 7.78933 12.5454 7.5617 12.7272 7.09909C12.909 6.63648 12.6814 6.11407 12.2188 5.93224Z'
      fill='#DFA701'
    />
    <path
      d='M18.0948 4.09046C17.9424 4.56526 17.4342 4.82679 16.9593 4.67475C16.4838 4.52251 16.222 4.01336 16.3748 3.53804L16.8662 2.00949C17.0143 1.54911 17.4988 1.28712 17.965 1.41534C18.4602 1.55149 18.7422 2.07266 18.5853 2.56162L18.0948 4.09046Z'
      fill='#DFA701'
    />
    <path
      d='M19.9688 6.50259L21.5385 5.58149C21.9691 5.32881 22.1134 4.77489 21.8607 4.34427C21.608 3.91366 21.0541 3.76941 20.6235 4.02209L19.0537 4.94319C18.6231 5.19587 18.4789 5.74979 18.7316 6.18041C18.9842 6.61102 19.5382 6.75527 19.9688 6.50259Z'
      fill='#DFA701'
    />
    <path
      d='M21.8607 8.18391L20.085 7.79852C19.5962 7.69245 19.1141 8.00267 19.008 8.49141C18.9019 8.98016 19.2121 9.46235 19.7009 9.56842L21.4766 9.9538C21.9654 10.0599 22.4476 9.74965 22.5536 9.26091C22.6597 8.77217 22.3495 8.28998 21.8607 8.18391Z'
      fill='#DFA701'
    />
    <path
      d='M16.904 19.538H6.30901C4.05801 19.538 2.22501 17.705 2.22501 15.455C2.22501 13.496 3.61401 11.853 5.45701 11.461C5.85701 8.427 8.46101 6.074 11.605 6.074C13.873 6.074 15.932 7.303 17.022 9.253C19.802 9.317 22.046 11.597 22.046 14.395C22.047 17.232 19.738 19.538 16.904 19.538ZM6.30901 13.183C5.05601 13.183 4.03601 14.203 4.03601 15.455C4.03601 16.71 5.05701 17.727 6.30901 17.727H16.904C18.741 17.727 20.236 16.233 20.236 14.396C20.236 12.556 18.741 11.065 16.904 11.065C16.867 11.065 16.808 11.072 16.742 11.081C16.682 11.088 16.627 11.096 16.568 11.102L15.909 11.165L15.65 10.554C14.956 8.933 13.37 7.884 11.607 7.884C9.18601 7.884 7.21501 9.856 7.21501 12.279V13.182L6.30901 13.183Z'
      fill='#828B99'
    />
    <path
      d='M9.8 21.7V21.3C9.8 20.8029 9.39706 20.4 8.9 20.4C8.40294 20.4 8 20.8029 8 21.3V21.7C8 22.1971 8.40294 22.6 8.9 22.6C9.39706 22.6 9.8 22.1971 9.8 21.7Z'
      fill='#285CC3'
    />
    <path
      d='M14.7 21.7V21.3C14.7 20.8029 14.2971 20.4 13.8 20.4C13.3029 20.4 12.9 20.8029 12.9 21.3V21.7C12.9 22.1971 13.3029 22.6 13.8 22.6C14.2971 22.6 14.7 22.1971 14.7 21.7Z'
      fill='#285CC3'
    />
  </svg>
);

export default SvgWeatherCloudyLightRain;
