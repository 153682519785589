import type { UUID } from 'core/utils/basic.models';
import { useQuery } from 'core/utils/data-store/tools';
import axios from './report.axios';
import type { QueryResponse, ResponseCreateImage } from './report.model';

const getOrganizationPicture = async (organizationID?: UUID): Promise<string | undefined> => {
  if (organizationID) {
    const response = await axios.get(`/v2/orgs/${organizationID}`);
    return response.data.picture_url;
  }
  return undefined;
};

export const useGetOrganizationPicture = (organizationID?: UUID): QueryResponse => {
  const { data, isFetching } = useQuery([], () => {
    return getOrganizationPicture(organizationID);
  });
  return { data, isFetching };
};

export const updateOrganizationPicture = async (
  file: File | undefined,
  organizationID?: UUID
): Promise<ResponseCreateImage | undefined> => {
  if (!file) return undefined;

  // eslint-disable-next-line prefer-const
  let formData = new FormData();
  formData.append('file', file);

  const response = await axios.post<ResponseCreateImage>(`/v2/orgs/${organizationID}/picture`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return response.data;
};
