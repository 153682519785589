export const SEASON_FIELDS = 'SEASON_FIELDS';

export interface SeasonField {
  created_at?: string;
  updated_at?: string;
  field_id: string;
  varieties_ids?: string[];
  season_id?: string;
  planting_date?: string;
  harvesting_date?: string;
  emergency_date?: string;
  start_date?: string;
  end_date?: string;
  geometry?: GeoJSON.Feature | GeoJSON.FeatureCollection;
  name?: string;
  declared_area?: number;
  calculated_area?: number;
  id: string;
}

export interface SeasonFieldPageable {
  content: SeasonField[];
  total_elements: number;
  total_pages: number;
  last: boolean;
  first: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  size: number;
  number: number;
  number_of_elements: number;
  empty: boolean;
}

export interface SeasonFieldParams {
  include_deleted?: boolean;
  propertyIds?: string[];
  seasonIds?: string[];
  enabled?: boolean;
}

export interface SeasonFieldServiceParams extends SeasonFieldParams {
  include_deleted?: boolean;
  fieldIds?: string[];
  update?: boolean;
}
