const SvgStaticPointReport = ({ fontSize = '24px', fill = 'currentColor', ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='18' viewBox='0 0 12 18' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 0.75L12 7.26316L6 17.25L0 7.26316L6 0.75ZM6 2.9102L1.81637 7.45165L6 14.4152L10.1836 7.45165L6 2.9102Z'
      fill='#E99921'
    />
  </svg>
);

export default SvgStaticPointReport;
