const SvgWeatherPartlyCloud = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M17.3 12.4L16.6 10.7C17.5 10.3 18.1 9.4 18.1 8.5C18.1 7.2 17 6.1 15.7 6.1C14.5 6.1 13.5 7 13.3 8.1C13.3 8.2 13.3 8.3 13.3 8.5H11.5C11.5 8.3 11.5 8.1 11.5 7.9C11.8 5.9 13.6 4.3 15.7 4.3C18 4.3 19.9 6.2 19.9 8.5C19.9 10.2 18.9 11.7 17.3 12.4Z'
      fill='#DFA701'
    />
    <path
      d='M12.2188 5.93224L10.5436 5.2738C10.0809 5.09198 9.55853 5.3196 9.37671 5.78221C9.19488 6.24482 9.42251 6.76724 9.88512 6.94906L11.5604 7.6075C12.023 7.78933 12.5454 7.5617 12.7272 7.09909C12.909 6.63648 12.6814 6.11407 12.2188 5.93224Z'
      fill='#DFA701'
    />
    <path
      d='M14.7892 4.24408L14.0043 2.62426C13.7875 2.17696 13.2492 1.99007 12.8019 2.20684C12.3546 2.4236 12.1677 2.96194 12.3844 3.40924L13.1694 5.02906C13.3862 5.47636 13.9245 5.66324 14.3718 5.44648C14.8191 5.22971 15.006 4.69138 14.7892 4.24408Z'
      fill='#DFA701'
    />
    <path
      d='M16.6421 2.68327L16.1541 4.20695C16.0025 4.6803 16.2634 5.18692 16.7367 5.33852C17.2101 5.49012 17.7167 5.2293 17.8683 4.75595L18.3563 3.23227C18.5079 2.75892 18.2471 2.2523 17.7737 2.1007C17.3004 1.9491 16.7937 2.20992 16.6421 2.68327Z'
      fill='#DFA701'
    />
    <path
      d='M19.8026 7.20809C19.3643 7.47673 18.7905 7.32761 18.5385 6.87955C18.2953 6.4473 18.4446 5.89991 18.8734 5.65089L20.4455 4.73811C20.8639 4.49514 21.3999 4.63324 21.6489 5.04816C21.8987 5.46453 21.7664 6.00444 21.3524 6.25818L19.8026 7.20809Z'
      fill='#DFA701'
    />
    <path
      d='M21.676 8.8857L19.9172 8.50284C19.4315 8.39712 18.9521 8.70513 18.8464 9.1908C18.7407 9.67647 19.0487 10.1559 19.5344 10.2616L21.2931 10.6445C21.7788 10.7502 22.2582 10.4422 22.364 9.95652C22.4697 9.47085 22.1617 8.99142 21.676 8.8857Z'
      fill='#DFA701'
    />
    <path
      d='M17.1 22.3H5.59999C3.19999 22.3 1.29999 20.4 1.29999 18C1.29999 15.9 2.79999 14.1 4.79999 13.7C5.19999 10.4 7.99999 7.9 11.4 7.9C13.8 7.9 16.1 9.2 17.2 11.3C20.2 11.3 22.6 13.8 22.6 16.8C22.6 19.9 20.1 22.3 17.1 22.3ZM5.59999 15.5C4.19999 15.5 3.09999 16.6 3.09999 18C3.09999 19.4 4.19999 20.5 5.59999 20.5H17C19 20.5 20.7 18.9 20.7 16.8C20.7 14.8 19.1 13.1 17 13.1C17 13.1 16.9 13.1 16.8 13.1C16.7 13.1 16.7 13.1 16.6 13.1L15.9 13.2L15.6 12.6C14.8 10.8 13.1 9.7 11.2 9.7C8.49999 9.7 6.39999 11.9 6.39999 14.5V15.4L5.59999 15.5Z'
      fill='#BDC4D1'
    />
  </svg>
);

export default SvgWeatherPartlyCloud;
