import type { DataPhenomenaDashboardWidget } from 'pages/dashboard-farm/widget-heatmap-over-time/widget-heatmap-over-time.models';
import type { AnyAction } from 'redux';
import type { AppState } from 'redux/app-state';
import { WidgetsActionsTypes } from './widgets.actions';
import type { WidgetId, WidgetsState } from './widgets.models';
import { WidgetStatus, WidgetType } from './widgets.models';

const defaultState = { data: [], status: WidgetStatus.EMPTY };

export const initialState: WidgetsState = {
  companyWidgets: {
    duration_damage: defaultState,
    most_common_issues: defaultState,
    sprays: defaultState,
    monitoring: defaultState,
    tasks: defaultState
  },
  propertyWidgets: {
    heatmap_over_time: defaultState,
    individual_scouter: defaultState,
    monitoring_kpi: defaultState,
    product_types: defaultState,
    scouter_performance_over_time: defaultState,
    tasks_property: defaultState,
    abandoned_areas: defaultState,
    tasks_list: defaultState,
    scouter_distance_time_avg: defaultState,
    most_common_issues_property: defaultState,
    company_weather: defaultState,
    weather: defaultState
  },
  widgetsSettings: {
    status: WidgetStatus.EMPTY,
    currentDashboard: null,
    data: {
      scouter_distance_time_avg: {
        index: 0,
        selected: true
      },
      tasks_list: {
        index: 1,
        selected: true
      },
      abandoned_areas: {
        index: 2,
        selected: true
      },
      heatmap_over_time: {
        index: 3,
        selected: true
      },
      scouter_performance_over_time: {
        index: 4,
        selected: true
      },
      individual_scouter: {
        index: 5,
        selected: true
      },
      tasks_property: {
        index: 6,
        selected: true
      },
      most_common_issues_property: {
        index: 7,
        selected: true
      },
      product_types: {
        index: 8,
        selected: true
      },
      monitoring_kpi: {
        index: 9,
        selected: true
      },
      weather: {
        index: 9,
        selected: true
      },
      company_weather: {
        index: 9,
        selected: true
      }
    }
  }
};

export const setAll = (state: WidgetsState, widgetType: string, status, loading) => {
  let result = state;
  Object.keys(result[widgetType]).forEach(widgetKey => {
    result = {
      ...result,
      [widgetType]: {
        ...result[widgetType],
        [widgetKey]: {
          ...result[widgetType[widgetKey]],
          status: loading ? WidgetStatus.LOADING : status
        }
      }
    };
  });

  return result;
};

const assign = (state: WidgetsState) => ({ ...state });

export default (state = initialState, action: AnyAction): WidgetsState => {
  switch (action.type) {
    case WidgetsActionsTypes.FAKE_LOAD_ALL:
      return setAll(state, WidgetType.COMPANY, null, true);

    case WidgetsActionsTypes.LOAD_ALL_REQUEST:
      return setAll(state, action.payload.widgetType, null, true);

    case WidgetsActionsTypes.LOAD_ALL_SUCCESS:
      return setAll(state, action.payload.widgetType, null, false);

    case WidgetsActionsTypes.LOAD_ALL_FAILURE:
      return setAll(state, action.payload.widgetType, action.payload.error, false);

    case WidgetsActionsTypes.LOAD_REQUEST:
      // @todo Remove assign, we use immutable pattern.

      state[action.payload.widgetType][action.payload.widgetId] = {
        data: [],
        status: WidgetStatus.LOADING
      };
      return assign(state);

    case WidgetsActionsTypes.LOAD_SUCCESS:
      state[action.payload.widgetType][action.payload.widgetId] = {
        data: action.payload.data,
        status: WidgetStatus.SUCCESS
      };
      return assign(state);

    case WidgetsActionsTypes.LOAD_FAILURE:
      state[action.payload.widgetType][action.payload.widgetId] = {
        data: [],
        status: WidgetStatus.ERROR
      };
      return assign(state);

    case WidgetsActionsTypes.LOAD_SETTINGS_REQUEST:
      return {
        ...state,
        widgetsSettings: {
          ...state.widgetsSettings,
          status: WidgetStatus.LOADING
        }
      };

    case WidgetsActionsTypes.LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        widgetsSettings: {
          data: action.payload.data,
          currentDashboard: action.payload.currentDashboard,
          status: WidgetStatus.SUCCESS
        }
      };

    case WidgetsActionsTypes.LOAD_SETTINGS_FAILURE:
      return {
        ...state,
        widgetsSettings: {
          ...state.widgetsSettings,
          status: WidgetStatus.ERROR
        }
      };

    default:
      return state;
  }
};

export const selectWidget = (state: WidgetsState | undefined, widgetType: WidgetType, widgetId: WidgetId) => {
  if (state) return state[widgetType][widgetId];
  return {
    data: [],
    status: WidgetStatus.ERROR
  };
};

export const selectPropertyHeatmapOverTimeWidget = (state: AppState) => {
  const { data, status } = state.analytics.widgets.propertyWidgets.heatmap_over_time;

  return {
    heatMapOvertimeData: data as DataPhenomenaDashboardWidget[],
    heatMapOvertimeStatus: status
  };
};

export const selectWidgetsSettings = (state: WidgetsState) => state.widgetsSettings;
