import { StaticPointsDrawerActions } from './static-points-drawer.actions';
import type { StaticPointsDrawerState } from './static-points-drawer.models';

const initialState: StaticPointsDrawerState = {
  type: null,
  visible: false,
  selectedTemplate: null,
  selectedStation: null,
  selectedTrapview: false
};

export default (state = initialState, action): StaticPointsDrawerState => {
  switch (action.type) {
    case StaticPointsDrawerActions.TOGGLE_DRAWER:
      return {
        ...state,
        ...action.payload
      };

    case StaticPointsDrawerActions.SELECT_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload
      };

    case StaticPointsDrawerActions.EDIT_STATION:
      return {
        ...state,
        visible: true,
        selectedStation: action.payload
      };

    case StaticPointsDrawerActions.CLEAR_SELECTED_STATION:
      return {
        ...state,
        selectedStation: null
      };

    case StaticPointsDrawerActions.SELECT_TRAPVIEW:
      return {
        ...state,
        selectedTrapview: true
      };

    case StaticPointsDrawerActions.CLEAR_SELECTED_TRAPVIEW:
      return {
        ...state,
        selectedTrapview: false
      };

    default:
      return state;
  }
};
