import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import { isActive } from '../../../../../../entities/season/season.functions.ts';
import sentryService from '../../../../../../sentry/service.ts';
import type { Dictionary } from 'config/types';
import type { Season } from '../../../../../../entities/season/season.models.ts';
import type { IDateRange } from '../../../../../core.models.ts';

export const handleSetDateRange = (
  selectedSeasons: string[],
  allSeasons: Dictionary<Season>,
  visible: boolean,
  setDateRange: React.Dispatch<React.SetStateAction<IDateRange | undefined>>,
  resetDate: (value?: string) => void
) => {
  if (!selectedSeasons?.length || isEmpty(allSeasons)) return;

  const allSeasonsKeys = Object.keys(allSeasons);
  const sentryContext = {
    fileName: 'report-trap-warehouse.functions.tsx',
    method: 'handleSetDateRange',
    description: 'Item Seasons invalid - new exception with early return',
    selectedSeasons,
    allSeasonsKeys
  };

  if (selectedSeasons.length === 1) {
    const seasonSelected = allSeasons[selectedSeasons[0]];
    if (!seasonSelected) {
      sentryService.captureException(new Error('Item Seasons invalid - new exception with early return'), { ...sentryContext });
      return;
    }
    setDateRange({ from: moment(seasonSelected?.start_date), to: moment(seasonSelected?.end_date) });
    if (visible) {
      const isActiveSeason = isActive(seasonSelected);
      resetDate(isActiveSeason ? undefined : seasonSelected?.end_date);
    }
    return;
  }

  let endDate: moment.Moment | undefined;
  let startDate: moment.Moment | undefined;

  selectedSeasons.forEach(item => {
    const isValidSeason = !!allSeasons?.[item];
    if (!isValidSeason) {
      sentryService.captureException(new Error('Item Seasons invalid - new exception with early return'), {
        ...sentryContext
      });
    }
    if (isValidSeason) {
      if (!startDate) {
        startDate = moment(allSeasons[item]?.start_date);
      } else {
        const dateToVerify = moment(allSeasons[item]?.start_date);
        startDate = startDate.isAfter(dateToVerify) ? dateToVerify : startDate;
      }
      if (!endDate) {
        endDate = moment(allSeasons[item]?.end_date);
      } else {
        const dateToVerify = moment(allSeasons[item]?.end_date);
        endDate = endDate.isBefore(dateToVerify) ? dateToVerify : endDate;
      }
    }
  });
  if (startDate && endDate) {
    setDateRange({ from: startDate, to: endDate });
  }
  if (visible) {
    resetDate();
  }
};
