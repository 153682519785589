import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { getBorerRiskDataPage, getConfigurationParameters } from 'pages/borer-risk/borer-risk-map/borer-risk-map.service';
import type { Dictionary } from 'config/types';
import { ofType } from 'redux-observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError, concatMap, map } from 'rxjs/operators';
import type { Action, UUID } from '../../core/utils/basic.models';
import type { ConfigurationParameterDTO, RiskDTO, pageRiskDTO } from '../../pages/borer-risk/borer-risk-map/borer-risk.models';
import type { Region } from '../region/region.models';
import { RegionType } from '../region/region.models';
import {
  BorerRiskActionTypes,
  LoadBorerRiskParametersFailure,
  LoadBorerRiskParametersSuccess,
  LoadBorerRisksFailure,
  LoadBorerRisksSuccess,
  ReloadBorerRisks
} from './borer-risk.actions';
import type { BorerRiskPayload } from './borer-risk.models';

/**
 * Method that checks if a region is a direct parent of areas
 * @param regionId
 * @param regions
 */
const isDirectParent = (regionId: UUID, regions: Dictionary<Region>): boolean => {
  return (regions[regionId].children as UUID[]).reduce<boolean>((acc, childId) => {
    if (regions[childId]?.type === RegionType.AREA) return acc;
    return false;
  }, true);
};

/**
 * Calculates the risk for a Region
 * @param region
 * @param areaRisks The risks for all the areas
 * @param payloadRisks The risks already computed by other endpoint calls
 */
const getRegionRisks = (region: Region, areaRisks: RiskDTO, payloadRisks: RiskDTO | null): Dictionary<number | null> => {
  // Finding the parameter keys (ids)
  const parameterKeys = Object.keys(Object.values(areaRisks).find(r => typeof r !== 'string') || Object.values(areaRisks)[0]);
  let actualAreaRisks = areaRisks;
  if (payloadRisks) {
    actualAreaRisks = {
      ...payloadRisks,
      ...areaRisks
    };
  }

  return parameterKeys.reduce<Dictionary<number | null>>((acc, key) => {
    /*
      The child Count will be determined by the number of regions with a valid risk (not null)
      And the regions that don't have an error in the calculation
     */
    let validChildCount = region.children.length;
    const value = (region.children as UUID[]).reduce<number | null>((sum, childId) => {
      if (actualAreaRisks[childId] && !!actualAreaRisks[childId][key] && typeof actualAreaRisks[childId][key] === 'number') {
        return typeof sum === 'number' ? sum + (actualAreaRisks[childId][key] as number) : actualAreaRisks[childId][key];
      }
      validChildCount--;
      return sum;
    }, null);

    // Calculates the average of the risks, utilizing the sum of valid parameters, and the children with valid parameters
    return {
      ...acc,
      [key]: value ? parseFloat((value / validChildCount).toFixed(2)) : null
    };
  }, {});
};

/**
 * Loads the parameters configured for some property
 * @param action$
 */
export const handleLoadBorerRiskParameters = action$ =>
  action$.pipe(
    ofType(BorerRiskActionTypes.LOAD_CONFIGURATION_PARAMETERS),
    map((action: Action) => action.payload),
    concatMap(payload => {
      return getConfigurationParameters((payload as any).propertyId, (payload as any).seasonId).pipe(
        map(response => {
          const parameters: Dictionary<ConfigurationParameterDTO> = (response.data.pr as ConfigurationParameterDTO[]).reduce((acc, par) => {
            return {
              ...acc,
              [par.id]: par
            };
          }, {});
          return LoadBorerRiskParametersSuccess(parameters);
        }),
        catchError(() => of(LoadBorerRiskParametersFailure('Error loading parameters')))
      );
    })
  );

/**
 * Function that choose the endpoint that will be called according to payload
 * @payload The payload of the action
 */
const getRiskRequest = (payload: BorerRiskPayload): AxiosObservable<RiskDTO | pageRiskDTO> => {
  return getBorerRiskDataPage(payload.propertyId, payload.seasonId, 1000, payload.cursor, payload?.year);
};

/**
 * Function that catch the risk's object according to the data received
 * @data Data object received (could be RiskDTO or pageRiskDTO)
 */
const getRiskDTOFromResponse = (data: RiskDTO | pageRiskDTO): RiskDTO => {
  return data?.content ? (data.content as RiskDTO) : (data as RiskDTO);
};

/**
 * Loads Borer Risks for all the areas
 * Then Calculates the Borer Risk for the regions that have these areas as children
 * @param action$
 */
export const handleLoadBorerRisks = action$ =>
  action$.pipe(
    ofType(BorerRiskActionTypes.LOAD_BORER_RISKS, BorerRiskActionTypes.RELOAD_BORER_RISKS),
    map((action: Action) => action.payload),
    concatMap((payload: BorerRiskPayload) =>
      getRiskRequest(payload).pipe(
        map(response => response.data),
        map(data => {
          // If the endpoint return an empty content for the last call return the actual risk
          if (data?.is_last && !Object.keys(data?.content).length) {
            return LoadBorerRisksSuccess(payload.risks || {}, payload.limitValues || { areas: {}, regions: {} });
          }
          const { regions } = payload;
          let totalRiskData: RiskDTO = Object.keys(regions).reduce<RiskDTO>((acc, key) => {
            // If the region is an area, simply grab the data returned from the request
            if (regions[key]?.type === RegionType.AREA && getRiskDTOFromResponse(data)[key]) {
              return {
                ...acc,
                [key]: getRiskDTOFromResponse(data)[key]
              };
            }
            // If the region is a direct parent of one of the areas, calculate the risk
            if (regions[key]?.type === RegionType.REGION && isDirectParent(key, regions)) {
              return {
                ...acc,
                [key]: getRegionRisks(regions[key], getRiskDTOFromResponse(data), payload.risks)
              };
            }
            return acc;
          }, {});
          totalRiskData = {
            ...payload.risks,
            ...totalRiskData
          };
          let limitValues = { areas: {}, regions: {} };
          Object.keys(totalRiskData).forEach(key => {
            const values = totalRiskData[key];
            if (regions[key]?.type === RegionType.REGION) {
              Object.keys(values).forEach(parameterKey => {
                const value = values[parameterKey];
                if (!value) return;
                if (!Object.keys(limitValues.regions).includes(parameterKey)) {
                  limitValues = {
                    ...limitValues,
                    regions: {
                      ...limitValues.regions,
                      [parameterKey]: {
                        min: value,
                        max: value
                      }
                    }
                  };
                } else {
                  limitValues = {
                    ...limitValues,
                    regions: {
                      ...limitValues.regions,
                      [parameterKey]: {
                        min: value < limitValues.regions[parameterKey].min ? value : limitValues.regions[parameterKey].min,
                        max: value > limitValues.regions[parameterKey].max ? value : limitValues.regions[parameterKey].max
                      }
                    }
                  };
                }
              });
            } else {
              Object.keys(values).forEach(parameterKey => {
                const value = values[parameterKey];
                if (!value) return;
                if (!Object.keys(limitValues.areas).includes(parameterKey)) {
                  limitValues = {
                    ...limitValues,
                    areas: {
                      ...limitValues.areas,
                      [parameterKey]: {
                        min: value,
                        max: value
                      }
                    }
                  };
                } else {
                  limitValues = {
                    ...limitValues,
                    areas: {
                      ...limitValues.areas,
                      [parameterKey]: {
                        min: value < limitValues.areas[parameterKey].min ? value : limitValues.areas[parameterKey].min,
                        max: value > limitValues.areas[parameterKey].max ? value : limitValues.areas[parameterKey].max
                      }
                    }
                  };
                }
              });
            }
          });
          if (!data.is_last) {
            const cursor = data?.cursor ? (data as pageRiskDTO).cursor : null;
            return ReloadBorerRisks(payload.propertyId, payload.seasonId, limitValues, regions, totalRiskData, cursor);
          }
          return LoadBorerRisksSuccess(totalRiskData, limitValues);
        }),
        catchError(() => of(LoadBorerRisksFailure('Error loading area risks')))
      )
    )
  );
