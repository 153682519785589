import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import { PowerBIReportsActionsTypes } from './powerbi-reports.actions';
import type { PowerBIReportsState } from './powerbi-reports.models';

const initialState: PowerBIReportsState = {
  reports: {
    organization_id: '',
    property_id: '',
    group_id: '',
    report_id: '',
    report_container: '',
    embed_token: '',
    embed_url: ''
  },
  config: [],
  isLoading: false,
  error: null
};

export default (state = initialState, action): PowerBIReportsState => {
  switch (action.type) {
    case PowerBIReportsActionsTypes.CLEAR_REPORTS:
      return {
        ...state,
        reports: initialState.reports,
        config: initialState.config,
        isLoading: false,
        error: null
      };
    case PowerBIReportsActionsTypes.LOAD_REPORTS:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case PowerBIReportsActionsTypes.LOAD_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload,
        isLoading: false,
        error: null
      };
    case PowerBIReportsActionsTypes.LOAD_REPORTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        reports: initialState.reports,
        config: initialState.config,
        error: action.payload
      };
    case PowerBIReportsActionsTypes.LOAD_CONFIG_REPORTS:
      return {
        ...state,
        reports: initialState.reports,
        config: initialState.config,
        isLoading: true
      };
    case PowerBIReportsActionsTypes.LOAD_CONFIG_REPORTS_SUCCESS:
      return {
        ...state,
        config: action.payload,
        isLoading: false
      };
    case PowerBIReportsActionsTypes.LOAD_CONFIG_REPORTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        reports: initialState.reports,
        config: initialState.config,
        error: action.payload
      };
    default:
      return state;
  }
};

export const selectPowerBIReports = createSelector(
  (state: AppState) => state.entities.powerBIReports,
  powerBIReports => {
    return powerBIReports.reports;
  }
);
