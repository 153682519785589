import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { PROTECTOR_API_URL } from 'config/constants';
import type { UUID } from 'core/utils/basic.models';
import { serializeParamsArrayRepeat } from 'core/utils/params-serializer';
import type {
  DiseaseRiskConfigurations,
  DiseaseRiskConfigurationsUpdatePayload,
  DiseaseRiskGrapesConfigurations,
  DiseaseRiskGrapesConfigurationsDto,
  DiseaseRiskGrapesConfigurationsPost,
  DiseaseRiskGrapesConfigurationsPostResult,
  DiseaseRiskHistoricalDiseasePressureField,
  DiseaseRisksDto,
  DiseaseRisksPotatoDto,
  EditDrawerConfig,
  FieldDiseaseRiskConfigurations,
  PatchHistoricalDiseasePressureProps
} from 'pages/disease-risk/disease-risk.models';
import type { SaveEditAreaInfoResponse } from 'pages/timeline/timeline.models';
import type { GetHistoricalDiseasePressureResponse } from './disease-risk-configuration/disease-risk-historical-disease-pressure/disease-risk-historical-disease-pressure.types';

const protectorApiUrl = PROTECTOR_API_URL;

const drawerConfigURL = `${protectorApiUrl}/v1/season-areas`;

interface GetDiseaseRisksByPropertyProps {
  propertyId: UUID;
  fieldIds?: UUID[];
  mockedData?: boolean;
}

export const getDiseaseRisksByProperty = async ({
  propertyId,
  fieldIds,
  mockedData
}: GetDiseaseRisksByPropertyProps): Promise<DiseaseRisksDto> => {
  const config: AxiosRequestConfig = {};

  if (mockedData) {
    config.headers = { 'x-demo': mockedData };
  }

  if (fieldIds?.length) {
    config.params = { fieldIds };
  }

  if (config.params) {
    config.paramsSerializer = serializeParamsArrayRepeat;
  }

  const url = `${protectorApiUrl}/v2/properties/${propertyId}/disease-risks`;

  return axios.get<DiseaseRisksDto>(url, config).then(response => response.data);
};

export const getDiseaseRiskPotatoes = async (propertyId: UUID, mockedData?: boolean): Promise<DiseaseRisksPotatoDto> => {
  const config: AxiosRequestConfig = {};
  if (mockedData) config.headers = { 'x-demo': mockedData };

  const url = `${protectorApiUrl}/v2/properties/${propertyId}/disease-risks/potato`;

  return axios.get<DiseaseRisksPotatoDto>(url, config).then(response => response.data);
};

export const getDiseaseRisksConfigurationsForCerealsByProperty = async (propertyId: UUID): Promise<FieldDiseaseRiskConfigurations[]> => {
  const url = `${protectorApiUrl}/v2/properties/${propertyId}/disease-risks/configurations/cereals`;

  return axios.get<FieldDiseaseRiskConfigurations[]>(url).then(response => response.data);
};

export const saveDiseaseRisksConfigurationsForCerealsByField = async (
  fieldId: UUID,
  payload: DiseaseRiskConfigurationsUpdatePayload
): Promise<AxiosResponse<DiseaseRiskConfigurations>> => {
  const url = `${protectorApiUrl}/v2/fields/${fieldId}/disease-risks/configurations/cereals`;

  return axios.put<DiseaseRiskConfigurations>(url, payload);
};

export const getDiseaseRisksConfigurationsForGrapesByProperty = async (propertyId: UUID): Promise<DiseaseRiskGrapesConfigurations[]> => {
  const url = `${protectorApiUrl}/v2/properties/${propertyId}/disease-risks/configurations/grapes`;

  return axios.get<DiseaseRiskGrapesConfigurationsDto[]>(url).then(response => response.data);
};

export const saveDiseaseRisksConfigurationsForGrapesByField = async (
  fieldId: UUID,
  payload: DiseaseRiskGrapesConfigurationsPost
): Promise<DiseaseRiskGrapesConfigurationsPostResult> => {
  const url = `${protectorApiUrl}/v2/fields/${fieldId}/disease-risks/configurations/grapes`;

  return axios.put(url, payload);
};

export const getHistoricalDiseasePressure = async (propertyId: UUID): Promise<GetHistoricalDiseasePressureResponse> => {
  const url = `${protectorApiUrl}/v2/properties/${propertyId}/disease-risks/historical-disease-pressure`;

  return axios.get<GetHistoricalDiseasePressureResponse>(url).then(response => response.data);
};

export const patchHistoricalDiseasePressure = async ({
  propertyId,
  fieldIds,
  historicalDiseasePressurePatch
}: PatchHistoricalDiseasePressureProps): Promise<DiseaseRiskHistoricalDiseasePressureField[]> => {
  const url = `${protectorApiUrl}/v2/properties/${propertyId}/disease-risks/historical-disease-pressure`;
  const data = { fieldIds, ...historicalDiseasePressurePatch };

  return axios.patch<DiseaseRiskHistoricalDiseasePressureField[]>(url, data).then(response => response.data);
};

export const saveEditDrawerConfig = (requestParams: EditDrawerConfig) => {
  return axios.put<SaveEditAreaInfoResponse>(`${drawerConfigURL}?update_harvesting_date=true`, requestParams);
};
