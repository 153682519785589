import type { Action, UUID } from 'core/utils/basic.models';
import type { FileModel, UploadFileItem } from './upload-file.models';

export const UploadFileActionsTypes = {
  LOAD_UPLOADFILE_LIST: '[Analytics/UploadFile] Load List',
  LOAD_UPLOADFILE_LIST_SUCCESS: '[Analytics/UploadFile] Load List Success',
  LOAD_UPLOADFILE_LIST_FAILURE: '[Analytics/UploadFile] Load List Failure',

  LOAD_UPLOADFILE_IMAGES: '[Analytics/UploadFile] Load Images',
  LOAD_UPLOADFILE_IMAGES_SUCCESS: '[Analytics/UploadFile] Load Images Success',
  LOAD_UPLOADFILE_IMAGES_FAILURE: '[Analytics/UploadFile] Load Images Failure',
  STOP_LOADING_UPLOADFILE_IMAGES: '[Analytics/UploadFile] Load Images Stop',

  DELETE_UPLOADFILE: '[Analytics/UploadFile] Delete Upload File',
  DELETE_UPLOADFILE_SUCCESS: '[Analytics/UploadFile] Delete Upload File Success',
  DELETE_UPLOADFILE_FAILURE: '[Analytics/UploadFile] Delete Upload File Failure',

  UPDATE_UPLOADFILE: '[Analytics/UploadFile] Update Upload File',
  UPDATE_UPLOADFILE_SUCCESS: '[Analytics/UploadFile] Update Upload File Success',
  UPDATE_UPLOADFILE_FAILURE: '[Analytics/UploadFile] Update Upload File Failure',

  POST_UPLOADFILE: '[Analytics/UploadFile] Post Upload File',
  POST_UPLOADFILE_SUCCESS: '[Analytics/UploadFile] Post Upload File Success',
  POST_UPLOADFILE_FAILURE: '[Analytics/UploadFile] Post Upload File Failure'
};

// LOAD UPLOAD FILE LIST
export const LoadUploadFileList = (): Action => {
  return {
    type: UploadFileActionsTypes.LOAD_UPLOADFILE_LIST
  };
};

export const LoadUploadFileListSuccess = (list: UploadFileItem[]): Action<UploadFileItem[]> => {
  return {
    type: UploadFileActionsTypes.LOAD_UPLOADFILE_LIST_SUCCESS,
    payload: list
  };
};

export const LoadUploadFileListFailure = (error: string): Action<string> => {
  return {
    type: UploadFileActionsTypes.LOAD_UPLOADFILE_LIST_FAILURE,
    payload: error
  };
};

// DELETE UPLOAD FILE
export const DeleteUploadFile = (id: UUID): Action<UUID> => {
  return {
    type: UploadFileActionsTypes.DELETE_UPLOADFILE,
    payload: id
  };
};

export const DeleteUploadFileSuccess = (): Action => {
  return {
    type: UploadFileActionsTypes.DELETE_UPLOADFILE_SUCCESS
  };
};

export const DeleteUploadFileFailure = (error: string): Action<string> => {
  return {
    type: UploadFileActionsTypes.DELETE_UPLOADFILE_FAILURE,
    payload: error
  };
};

// UPDATE UPLOAD FILE
export const UpdateUploadFile = (metadata: UploadFileItem): Action<UploadFileItem> => {
  return {
    type: UploadFileActionsTypes.UPDATE_UPLOADFILE,
    payload: metadata
  };
};

export const UpdateUploadFileSuccess = (): Action => {
  return {
    type: UploadFileActionsTypes.UPDATE_UPLOADFILE_SUCCESS
  };
};

export const UpdateUploadFileFailure = (error: string): Action<string> => {
  return {
    type: UploadFileActionsTypes.UPDATE_UPLOADFILE_FAILURE,
    payload: error
  };
};

// POST UPLOAD FILE
export const PostUploadFile = (file: File, metadata: UploadFileItem): Action<{ file: File; metadata: UploadFileItem }> => {
  return {
    type: UploadFileActionsTypes.POST_UPLOADFILE,
    payload: { file, metadata }
  };
};

export const PostUploadFileSuccess = (metadata: UploadFileItem): Action<UploadFileItem> => {
  return {
    type: UploadFileActionsTypes.POST_UPLOADFILE_SUCCESS,
    payload: metadata
  };
};

export const PostUploadFileFailure = (error: string): Action<string> => {
  return {
    type: UploadFileActionsTypes.POST_UPLOADFILE_FAILURE,
    payload: error
  };
};

// LOAD UPLOAD FILE IMAGES
export const LoadUploadFileImages = (id: UUID): Action<UUID> => {
  return {
    type: UploadFileActionsTypes.LOAD_UPLOADFILE_IMAGES,
    payload: id
  };
};

export const LoadUploadFileImagesSuccess = (item: FileModel, id: string): Action<{ item: FileModel; id: string }> => {
  return {
    type: UploadFileActionsTypes.LOAD_UPLOADFILE_IMAGES_SUCCESS,
    payload: { item, id }
  };
};

export const LoadUploadFileImagesFailure = (error: string): Action<string> => {
  return {
    type: UploadFileActionsTypes.LOAD_UPLOADFILE_IMAGES_FAILURE,
    payload: error
  };
};
