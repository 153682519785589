import type { Action, DateISOString, Notification, Scouter, UUID } from 'core/utils/basic.models';
import type { AnalyticContext, AnalyticResultsHistogram, FixedPointInfo, InspectionLayout } from 'pages/fixed-points/fixed-points.models';
import type { EntityState } from 'redux-ngrx-entity';

interface PayloadLoadStaticPointType {
  propertyId: UUID;
}

export type LoadStaticPointType = (propertyId: UUID, fields?: StaticPointFieldEnum[]) => Action<PayloadLoadStaticPointType>;
export type LoadStaticPointTemplateType = (propertyId: UUID) => Action<PayloadLoadStaticPointType>;
export type LoadStaticPointSuccessType = (staticPoint: StaticPoint[]) => Action<StaticPoint[]>;
export type LoadStaticPointTemplateSuccessType = (staticPoint: StaticPoint[]) => Action<StaticPoint[]>;

interface PayloadCreateStaticPointType {
  type: StaticPointType;
  staticPoint: StaticPointCreation;
  callback: {
    clear: () => void;
    notification: Notification | null;
  };
}

export type CreateStaticPointType = (
  type: StaticPointType,
  staticPoint: StaticPointCreation,
  callback
) => Action<PayloadCreateStaticPointType>;

export enum StaticPointFieldEnum {
  ALL = 'ALL',
  TRAP = 'TRAP',
  CATALOGUE = 'CATALOGUE',
  PLUVIOMETER = 'PLUVIOMETER'
}

export enum StaticPointType {
  GENERIC = 'GENERIC',
  TRAP = 'TRAP',
  PLUVIOMETER = 'PLUVIOMETER'
}

export enum StaticPointIconType {
  HORMONAL_TRAP = 'HORMONAL_TRAP',
  BUS_STOP = 'BUS_STOP',
  DANGER = 'DANGER',
  CAMERA = 'CAMERA',
  PLUVIOMETER = 'PLUVIOMETER',
  GARAGE = 'GARAGE',
  HQ = 'HQ',
  AIRPORT = 'AIRPORT',
  FLAG = 'FLAG',
  GAS_STATION = 'GAS_STATION',
  LIGHT_TRAP = 'LIGHT_TRAP',
  POLE = 'POLE',
  WARNING = 'WARNING',
  RESTING_AREA = 'RESTING_AREA',
  RENEWABLE_TRAP = 'RENEWABLE_TRAP',
  GATE = 'GATE',
  WEATHER_STATION = 'WEATHER_STATION'
}

export enum StaticPointEventType {
  INSTALL = 'INSTALL',
  UNINSTALL = 'UNINSTALL',
  REINSTALL = 'REINSTALL',
  READ = 'READ',
  RENEWAL = 'RENEWAL',
  DELETE = 'DELETE'
}

export enum SamplingBehaviour {
  ACCUMULATIVE = 'ACCUMULATIVE',
  ALWAYSRESET = 'ALWAYSRESET',
  SINGLE_USE = 'SINGLE_USE'
}

export enum ExternalIntegrationSource {
  PROTECTOR = 'PROTECTOR',
  TRAPVIEW = 'TRAPVIEW'
}

export interface StaticPoint {
  analytic_results_histogram: AnalyticResultsHistogram;
  installed_at?: DateISOString;
  last_event?: DateISOString;
  scouter?: Scouter;
  static_point: FixedPointInfo;
  type?: StaticPointEventType;
  last_renewal?: DateISOString;
  validity?: number;
  uninstalled_at?: string;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export interface StaticPointCreation {
  id: UUID;
  template_id: UUID;
  name: string;
  company_id: UUID;
  property_id: UUID;
  area_ids: UUID[];
  icon_type: StaticPointIconType;
  location: Coordinate;
  installed_area?: UUID;
  language?: string;
  instaled_at: string;
  last_event_type?: StaticPointEventType;
  uninstallation_date?: string;
  sampling_behaviour: SamplingBehaviour;
  time_for_renewal?: number;
  single_use: boolean;
  integration_source?: ExternalIntegrationSource;
  auto_create_task?: boolean;
}

export interface StaticPointActionPayload {
  propertyId: UUID;
  fields: StaticPointFieldEnum[];
}

export interface StaticPointTemplate extends StaticPoint {
  isTemplate: true;
}

export interface StaticPointState extends EntityState<StaticPoint> {
  isStaticPointsLoading: boolean;
  isTemplatesLoading: boolean;
  error: string | null;
}

export interface GetStaticPointResponse {
  analytic_context_dto?: AnalyticContext;
  area_ids: UUID[];
  class_name: string;
  clazz: string;
  cohese: boolean;
  company_id: UUID;
  created_at?: DateISOString;
  description: any;
  icon_type: StaticPointIconType;
  id: UUID;
  inspection_layout_dto?: InspectionLayout;
  instalation_steps: string[];
  instaled_at: DateISOString;
  location: any;
  modified_at: DateISOString;
  name: any;
  parent_id: string | undefined;
  property_id: UUID | undefined;
  sampling_behaviour?: SamplingBehaviour;
  time_for_renewal?: number;
  inspection_layout?: InspectionLayout;
}
