import { Form, Icon, Spin } from 'antd';
import { COLOR_OPTIONS } from 'config/style';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { getPropertyPhenomenons, isPropertyPhenomenonsLoading } from 'entities/property/property.selectors';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PhenomenaSelect from './phenomena-select';

interface PhenomenaSelectAndLoadingProps {
  selected: UUID[];
  handleSetSelected: (value: UUID[]) => void;
  disableChildren?: boolean;
  maxSelected?: number;
  disableOptionAll?: boolean;
  placeholder?: string;
  label?: string;
}

const PhenomenaSelectAndLoading: FC<PhenomenaSelectAndLoadingProps> = ({
  selected,
  handleSetSelected,
  disableChildren,
  maxSelected,
  disableOptionAll,
  placeholder,
  label
}) => {
  const [t] = useTranslation();
  const loadingPropertyPhenomenons = useSelector(isPropertyPhenomenonsLoading);
  const propertyPhenomenons = useSelector(getPropertyPhenomenons);

  return (
    <Form.Item data-testid='report-type-phenomena-select' label={label ?? t('modals.timeline_xray_report.fields.phenomena')}>
      {loadingPropertyPhenomenons && (
        <div className='st-report-drawer__loading' data-testid='loading-phenomena'>
          <Spin indicator={<Icon type='loading' style={{ fontSize: 16 }} spin />} />
        </div>
      )}
      <PhenomenaSelect
        phenomenons={propertyPhenomenons}
        selected={selected}
        disableOptionAll={disableOptionAll}
        handleSetSelected={handleSetSelected}
        disableChildren={disableChildren}
        placeholder={placeholder}
      />
      {maxSelected && (
        <div className='containerMaxSelected'>
          <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60} align='right'>
            {t('modals.timeline_xray_report.fields.phenomena_max_selected', { count: maxSelected })}
          </STTypo>
        </div>
      )}
    </Form.Item>
  );
};

export default PhenomenaSelectAndLoading;
