import type { Dictionary } from 'config/types';
import type { UUID } from 'core/utils/basic.models';
import type { SegmentTrackingHookReturn } from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import type { Company } from 'entities/company/company.models';
import type { Property } from 'entities/property/property.models';
import type { Region } from 'entities/region/region.models';
import { RequestReportApplicationStatistics } from 'entities/reports/report.actions';
import type { RequestReportBodyApplicationStatistics } from 'entities/reports/report.models';
import type moment from 'moment';
import type { Dispatch } from 'redux';
import { ReportsTypes } from '../reports-types.enum';

export const generateReport = (
  id: string,
  property: Property | undefined,
  company: Company | undefined,
  timeRangeTo: moment.Moment,
  timeRangeFrom: moment.Moment,
  currentSeasons: string[],
  selectedAreas: UUID[],
  getCurrentLanguage: () => string,
  getCurrentUnitSystem: () => string,
  userId: string,
  regions: Dictionary<Region>,
  closeDrawer: () => void,
  dispatch: Dispatch<any>,
  segmentTracking: SegmentTrackingHookReturn
): void => {
  if (property && company && selectedAreas) {
    const requestBody: RequestReportBodyApplicationStatistics = {
      id,
      type: ReportsTypes.APPLICATION_STATISTICS,
      property_id: property.id,
      data_request: {
        property_name: property.name,
        property_id: property.id,
        end_date: timeRangeTo.format('YYYY-MM-DD'),
        start_date: timeRangeFrom.format('YYYY-MM-DD'),
        type_static_point: 'SPRAY',
        current_seasons: currentSeasons,
        areas_id: selectedAreas,
        locale: getCurrentLanguage(),
        current_unit_system: getCurrentUnitSystem(),
        user_id: userId
      }
    };
    const payload: AnalyticsInfo = {
      propertyId: property.id,
      propertyName: property.name,
      selectedAreasId: selectedAreas,
      selectedAreasName: Object.values(regions)
        .filter(selectedRegion => selectedAreas.includes(selectedRegion.id))
        .map(region => region.name),
      reportType: requestBody.type,
      from: 'Export Report'
    };
    segmentTracking.track('Export Report - Generated Report ', payload);
    dispatch(RequestReportApplicationStatistics(requestBody));
  }
  closeDrawer();
};
