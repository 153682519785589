import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { CORE_SERVICES_API_URL, PROTECTOR_API_URL } from 'config/constants';
import { getObservables } from 'core/rxjs-utils';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import type { ProductType, UUID, V4Page } from '../../core/utils/basic.models';
import type { Crop, CropPage, LoadCropVarietiesRequestType, Variety } from './crop.models';

const protectorApiUrl = PROTECTOR_API_URL;

const cropsUrl = `${protectorApiUrl}/api/v1/crops`;
const coreServicesApiUrl = `${CORE_SERVICES_API_URL}/v1/crops`;
const varietiesUrl = `${protectorApiUrl}/v1/companies`;

export const getCrop = (): AxiosObservable<CropPage> => {
  return axios.get<CropPage>(`${cropsUrl}?page=0&size=1000&showCanonicals=true&showDeleted=true`).pipe(
    map(response => {
      return response;
    })
  );
};

export const getCropVarieties = (request: LoadCropVarietiesRequestType): Observable<V4Page<Variety>> => {
  const queryParams = `cropId=${request.cropId}&page=0&productType=VARIETY&size=9999&productVisibility=ANY`;

  return axios.get(`${varietiesUrl}/${request.companyId}/products?${queryParams}`).pipe(map(response => response.data));
};

export const getAllVarietiesPaginated = (
  companyId: UUID,
  cropId: UUID,
  totalPages: number,
  productType: ProductType,
  pageSize: number,
  skipFirst = true
): Observable<V4Page<Variety>[]> => {
  const pages = skipFirst ? [...Array(totalPages).keys()].slice(1, totalPages) : [...Array(totalPages).keys()];

  const observables = pages.map(page => getVarietiesByCompanyIdPaged(companyId, cropId, page, productType, pageSize));
  return getObservables(observables, true);
};

export function getVarietiesByCompanyIdPaged(
  companyId: UUID,
  cropId: UUID,
  page = 0,
  productType: ProductType,
  pageSize = 99999
): Observable<V4Page<Variety>> {
  const queryParams = `cropId=${cropId}&page=${page}&productType=${productType}&size=${pageSize}&productVisibility=ANY`;
  const url = `${varietiesUrl}/${companyId}/products?${queryParams}`;

  return axios.get(url).pipe(map(response => response.data));
}

/**
 * Find crops by ids.
 * @param ids of crops.
 * @return List of crops.
 */
export const getCropsByIds = (ids: UUID[]) => {
  return axios.post<Crop[]>(`${coreServicesApiUrl}/ids`, { ids: ids || [] }).pipe(map(response => response.data));
};
