import type { Action } from 'core/utils/basic.models';
import type { MethodologyEntity } from 'entities/methodology/methodology.models';
import { parsePhenomenonSingleToPhenomenon } from 'entities/phenomenon/phenomenon.helper';
import { getPhenomenaByMethodologyId } from 'entities/phenomenon/phenomenon.service';
import { getSeasonsTreeByCompany } from 'entities/season/season.service';
import { getUserById } from 'entities/user/user.service';
import { ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import sentryService from 'sentry/service';
import {
  LoadMethodologyDeepFailure,
  LoadMethodologyDeepSuccess,
  MethodologyDeepActionTypes,
  UpdateMethodologyDeepFailure,
  UpdateMethodologyDeepSuccess
} from './methodology-deep.actions';
import { convertToMethodologyDeep, updateMethodologyDeep, validateUserInfo } from './methodology-deep.helper';
import type { CompleteMethodology, MethodologyDeepPayload, Phenomenon, PhenomenonSingle } from './methodology-deep.models';
import { getMethodologyOnBase } from './methodology-deep.service';

export const handleLoadMethodologyDeep = action$ => {
  let methodology: CompleteMethodology;
  return action$.pipe(
    ofType(MethodologyDeepActionTypes.LOAD_METHODOLOGY_DEEP),
    map((action: Action<MethodologyEntity>) => action.payload),
    concatMap((methodologyAction: MethodologyEntity) =>
      forkJoin([
        of(methodologyAction),
        getMethodologyOnBase(methodologyAction.id).pipe(
          catchError(() =>
            of({
              data: {
                seasonIds: [],
                seasonAreaIds: []
              }
            })
          )
        )
      ])
    ),
    map((results: any) => {
      const [demeter, base] = results;

      methodology = {
        ...demeter,
        season_ids: base.data.seasonIds,
        season_area_ids: base.data.seasonAreaIds
      };
      return methodology;
    }),
    concatMap((results: any) => {
      return forkJoin([
        of(results),
        getPhenomenaByMethodologyId(results.id),
        getSeasonsTreeByCompany(methodology.company_id),
        getUserById(methodology.last_modified_by_user_id)
      ]);
    }),
    concatMap(([methodologyWithSeason, phenomenaByMethodology, seasonsTree, userInfo]) => {
      const phenomenonsTree = phenomenaByMethodology.data.map((phenomenon: PhenomenonSingle) =>
        parsePhenomenonSingleToPhenomenon(phenomenon)
      ) as Phenomenon[];

      const userName = validateUserInfo(userInfo, 'name');
      const userRole = validateUserInfo(userInfo, 'job_title');

      const methodologyDeep = convertToMethodologyDeep(methodologyWithSeason, phenomenonsTree, seasonsTree.data, userName, userRole);

      return of(LoadMethodologyDeepSuccess(methodologyDeep));
    }),
    catchError((error: string) => {
      return of(LoadMethodologyDeepFailure(error));
    })
  );
};

export const handleUpdateMethodologyDeep = action$ => {
  return action$.pipe(
    ofType(MethodologyDeepActionTypes.UPDATE_METHODOLOGY_DEEP),
    map((action: Action<MethodologyDeepPayload>) => action.payload),
    concatMap((methodologyPayload: MethodologyDeepPayload) =>
      updateMethodologyDeep(
        methodologyPayload.methodologyDeep,
        methodologyPayload.newIndicator,
        methodologyPayload.methodologyAnalyticContextDTO,
        methodologyPayload.phenomenon
      ).pipe(
        map(response => {
          return UpdateMethodologyDeepSuccess(response);
        })
      )
    ),
    catchError((error: Error) => {
      sentryService.captureException(error, {
        fileName: 'methodology-deep.epics',
        method: 'handleUpdateMethodologyDeep',
        description: error
      });
      return of(UpdateMethodologyDeepFailure(error.message));
    })
  );
};
