const SvgFieldBook = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.52002 10.9409V14.0054H1.06246C0.475679 14.0054 0 14.4507 0 15C0 15.5493 0.475679 15.9946 1.06246 15.9946H1.52002V18.0108C1.52002 19.1094 2.47138 20 3.64494 20H15.8751C17.0486 20 18 19.1094 18 18.0108V1.98925C18 0.890616 17.0486 0 15.8751 0H3.64494C2.47138 0 1.52002 0.890618 1.52002 1.98925V4.00569H1.06246C0.475679 4.00569 0 4.451 0 5.00032C0 5.54963 0.475679 5.99494 1.06246 5.99494H1.52002V8.95161H1.06246C0.475679 8.95161 0 9.39692 0 9.94624C0 10.4956 0.475679 10.9409 1.06246 10.9409H1.52002ZM6.37476 1.98925H3.64494L3.64494 4.00569H4.2669C4.85368 4.00569 5.32936 4.451 5.32936 5.00032C5.32936 5.54963 4.85368 5.99494 4.2669 5.99494H3.64494L3.64494 8.95161H4.2669C4.85368 8.95161 5.32936 9.39692 5.32936 9.94624C5.32936 10.4956 4.85368 10.9409 4.2669 10.9409H3.64494L3.64494 14.0054H4.2669C4.85368 14.0054 5.32936 14.4507 5.32936 15C5.32936 15.5493 4.85368 15.9946 4.2669 15.9946H3.64494L3.64494 18.0108H6.37476V1.98925ZM8.49968 1.98925V18.0108L15.8751 18.0108L15.8751 1.98925H8.49968Z'
    />
  </svg>
);

export default SvgFieldBook;
