import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import InfoOutline from 'st-protector-icons/Minor/InfoOutline';
import './alert-card-info.styles.less';

interface AlertCardInfoProps {
  description: string;
}

const AlertCardInfo = ({ description }: AlertCardInfoProps): JSX.Element => {
  return (
    <div className='alert-card-info-container' data-testid='alert-card-info-container'>
      <div className='alert-card-info-container__icon' data-testid='alert-card-info-icon'>
        <InfoOutline />
      </div>
      <STTypo type={TypeOptions.P2} lineHeight='20px' color='#0071CD' data-testid='alert-card-info-description'>
        {`${description}`}
      </STTypo>
    </div>
  );
};

export default AlertCardInfo;
