const SvgWeatherCloudyRainSnow = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M16.504 9.707L14.798 8.032C16.5 8 16.836 7.151 16.836 6.465C16.836 5.53 16.073 4.762 15.136 4.762C14.3 4.762 13.576 5.386 13.454 6.209C13.44 6.294 13.433 6.38 13.433 6.465H11.615C11.615 6.289 11.629 6.11 11.654 5.939C11.912 4.232 13.409 2.948 15.136 2.948C17.074 2.948 18.654 4.526 18.654 6.466C18.654 7.882 17.811 9.157 16.504 9.707Z'
      fill='#DFA701'
    />
    <path
      d='M12.3447 4.21597L11.3775 3.83642C10.9099 3.65293 10.3821 3.88324 10.1986 4.35083C10.0151 4.81843 10.2454 5.34624 10.713 5.52973L11.6802 5.90927C12.1478 6.09277 12.6756 5.86246 12.8591 5.39486C13.0426 4.92727 12.8123 4.39946 12.3447 4.21597Z'
      fill='#DFA701'
    />
    <path
      d='M14.5736 2.88792L14.1218 1.95341C13.9032 1.50144 13.3597 1.3122 12.9077 1.53073C12.4557 1.74926 12.2665 2.29281 12.485 2.74479L12.9369 3.6793C13.1554 4.13127 13.699 4.32052 14.1509 4.10198C14.6029 3.88345 14.7922 3.3399 14.5736 2.88792Z'
      fill='#DFA701'
    />
    <path
      d='M17.1235 3.44113L17.3968 2.58787C17.55 2.10979 17.2866 1.59806 16.8085 1.44489C16.3304 1.29172 15.8187 1.55512 15.6656 2.0332L15.3922 2.88646C15.239 3.36454 15.5024 3.87627 15.9805 4.02943C16.4586 4.1826 16.9703 3.91921 17.1235 3.44113Z'
      fill='#DFA701'
    />
    <path
      d='M18.6115 5.53568L19.5086 5.00965C19.9409 4.75614 20.0859 4.20016 19.8324 3.76783C19.5789 3.33549 19.0229 3.19052 18.5906 3.44403L17.6935 3.97006C17.2611 4.22357 17.1162 4.77955 17.3697 5.21188C17.6232 5.64422 18.1792 5.78919 18.6115 5.53568Z'
      fill='#DFA701'
    />
    <path
      d='M19.687 6.55609L18.6729 6.33479C18.1824 6.22776 17.698 6.5386 17.591 7.02908C17.4839 7.51956 17.7948 8.00394 18.2853 8.11098L19.2994 8.33228C19.7899 8.43931 20.2742 8.12846 20.3813 7.63798C20.4883 7.1475 20.1775 6.66312 19.687 6.55609Z'
      fill='#DFA701'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.025 8.48801C15.048 6.79701 13.243 5.73401 11.254 5.73401C8.499 5.73401 6.208 7.77301 5.811 10.42C4.2 10.809 3 12.264 3 13.99C3 16.016 4.649 17.666 6.674 17.666L15.838 17.668C18.368 17.668 20.428 15.607 20.428 13.077C20.427 10.606 18.468 8.58701 16.025 8.48801ZM15.838 15.814H6.676C5.67 15.814 4.851 14.996 4.851 13.99C4.851 12.986 5.671 12.166 6.676 12.166H7.6V11.243C7.6 9.229 9.241 7.586 11.256 7.586C12.725 7.586 14.043 8.457 14.621 9.808L14.889 10.434L15.838 10.333C17.349 10.333 18.578 11.564 18.578 13.076C18.578 14.586 17.349 15.814 15.838 15.814Z'
      fill='#828B99'
    />
    <path
      d='M5.09903 19.4209C4.81382 19.1369 4.35254 19.1372 4.06778 19.4217C3.78241 19.7068 3.7823 20.1693 4.06753 20.4545L4.50899 20.896L4.06713 21.3379C3.78204 21.623 3.78192 22.0852 4.06688 22.3704C4.35193 22.6557 4.8143 22.6559 5.09963 22.3709L5.54199 21.929L5.9835 22.3705C6.26871 22.6557 6.73117 22.6556 7.01624 22.3702C7.30113 22.0851 7.30101 21.623 7.01599 21.338L6.57399 20.896L7.01559 20.4544C7.30075 20.1692 7.30064 19.7069 7.01534 19.4218C6.73055 19.1373 6.26919 19.1371 5.98409 19.4213L5.54199 19.862L5.09903 19.4209Z'
      fill='#92B0ED'
    />
    <path
      d='M14.8869 19.4213C14.6018 19.137 14.1404 19.1372 13.8554 19.4216C13.5697 19.7067 13.5695 20.1695 13.8549 20.4549L14.296 20.896L13.8545 21.3375C13.5692 21.6228 13.5692 22.0852 13.8545 22.3705C14.1398 22.6558 14.6022 22.6558 14.8875 22.3705L15.329 21.929L15.7705 22.3705C16.0557 22.6557 16.5181 22.6554 16.803 22.37C17.0875 22.0849 17.0873 21.6233 16.8025 21.3385L16.36 20.896L16.8021 20.4539C17.087 20.169 17.087 19.707 16.8021 19.4221C16.5175 19.1375 16.0562 19.1371 15.7711 19.4213L15.329 19.862L14.8869 19.4213Z'
      fill='#92B0ED'
    />
    <path
      d='M11.341 21.9575V20.1505C11.341 19.6482 10.9338 19.241 10.4315 19.241C9.9292 19.241 9.522 19.6482 9.522 20.1505V21.9575C9.522 22.4598 9.9292 22.867 10.4315 22.867C10.9338 22.867 11.341 22.4598 11.341 21.9575Z'
      fill='#285CC3'
    />
    <path
      d='M21.134 21.9585V20.1705C21.134 19.6687 20.7273 19.262 20.2255 19.262C19.7238 19.262 19.317 19.6687 19.317 20.1705V21.9585C19.317 22.4602 19.7238 22.867 20.2255 22.867C20.7273 22.867 21.134 22.4602 21.134 21.9585Z'
      fill='#285CC3'
    />
  </svg>
);

export default SvgWeatherCloudyRainSnow;
