import { selectSelectedProperty } from 'core/core.reducer';
import { selectsTheFirstPropertyIdOfTheEntities } from 'entities/property/property.selectors';
import moment from 'moment';
import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import { ReportsActionTypes } from './report.actions';
import type { ReportInfo, ReportState } from './report.models';

function selectTaskUuid(report: ReportInfo): string {
  return report.id;
}

function sortByStartAt(a: ReportInfo, b: ReportInfo): number {
  const parsedA = moment(a.created_at).unix();
  const parsedB = moment(b.created_at).unix();
  return parsedB - parsedA;
}

const entity: EntityAdapter<ReportInfo> = createEntityAdapter<ReportInfo>({
  selectId: selectTaskUuid,
  sortComparer: sortByStartAt
});

const initialState = entity.getInitialState<ReportState>({
  ids: [],
  entities: {},
  isLoading: false,
  reportRequested: false,
  error: null
});

export default (state = initialState, action): ReportState => {
  switch (action.type) {
    case ReportsActionTypes.LOAD_REPORTS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ReportsActionTypes.LOAD_REPORTS_SUCCESS:
      return entity.upsertMany(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    case ReportsActionTypes.LOAD_REPORTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case ReportsActionTypes.CREATE_REPORT_REQUEST:
      return {
        ...state,
        reportRequested: true
      };
    case ReportsActionTypes.CREATE_REPORT_SUCCESS:
      return entity.addOne(action.payload, {
        ...state,
        reportRequested: false,
        error: null
      });
    case ReportsActionTypes.CREATE_REPORT_FAILURE:
      return {
        ...state,
        reportRequested: false,
        error: action.payload
      };
    case ReportsActionTypes.CLEAR_REPORTS:
      return entity.removeAll(state);

    default:
      return state;
  }
};

export const getPropertyReports = createSelector(
  (state: AppState) => Object.values(state.entities.reports.entities),
  (state: AppState) => selectSelectedProperty(state.uiState.global),
  selectsTheFirstPropertyIdOfTheEntities,
  (reports, selectedProperty, propertyId) => {
    return reports.filter(report => report.property_id === selectedProperty || report.property_id === propertyId);
  }
);

export const isLoadingReports = createSelector(
  (state: AppState) => state.entities.reports,
  reports => {
    return reports.isLoading;
  }
);

export const wasReportRequested = createSelector(
  (state: AppState) => state.entities.reports,
  reports => {
    return reports.reportRequested;
  }
);
