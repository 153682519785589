import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const useFirstLoad = () => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const { pathname } = useLocation();

  const screen = pathname.split('/').pop();

  const company = useSelector(getSelectedCompany);
  const property = useSelector(getSelectedProperty);

  const segmentTracking = useSegmentTracking();

  useEffect(() => {
    if (!firstLoad || (screen !== company?.id && screen !== property?.id && screen !== 'hall-of-companies' && (!company || !property)))
      return;

    let screenToTrack = screen;

    if (screen === company?.id) {
      screenToTrack = 'Hall of Properties';
    } else if (screen === property?.id) {
      screenToTrack = 'Property Dashboard';
    }

    segmentTracking.track(`Loaded Panel`, { screen: screenToTrack });

    setFirstLoad(false);
  }, [company, firstLoad, property, screen, segmentTracking]);
};

export default useFirstLoad;
