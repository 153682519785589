import { COLOR_OPTIONS } from 'config/style';

interface CropCornProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropCorn = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropCornProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.6913 2.49684L11.12 1.92549C11.3661 1.78892 11.6088 1.66499 11.8468 1.55465C12.5505 1.22848 13.179 1.04203 13.6841 1C13.3341 1.22184 12.9224 1.51677 12.4541 1.88177C12.2102 2.07187 11.9553 2.27752 11.6913 2.49684Z'
      fill={color}
    />
    <path
      d='M9.44602 4.4905C9.99574 3.97483 10.5321 3.49025 11.0429 3.04753L10.3696 2.37418C9.79164 2.74398 9.20196 3.17613 8.61496 3.65944L9.44602 4.4905Z'
      fill={color}
    />
    <path
      d='M12.9527 4.95733C12.51 5.4682 12.0254 6.0045 11.5097 6.5542L12.3408 7.38524C12.8241 6.79826 13.2562 6.20862 13.6261 5.63069L12.9527 4.95733Z'
      fill={color}
    />
    <path
      d='M15 2.31677C14.7782 2.66668 14.4834 3.07814 14.1185 3.54616C13.9284 3.79007 13.7228 4.04498 13.5034 4.30894L14.0748 4.88026C14.2114 4.63414 14.3353 4.39144 14.4457 4.15337C14.7718 3.44995 14.9579 2.82179 15 2.31677Z'
      fill={color}
    />
    <path
      d='M7.30569 6.58919L6.61112 5.89464C6.59436 5.78896 6.57641 5.68507 6.55747 5.58289C6.70205 5.4303 6.84973 5.27858 7.00032 5.12799C7.31909 4.80926 7.64292 4.50379 7.96923 4.21293L8.83118 5.07483C8.51629 5.37798 8.19862 5.68959 7.88028 6.00792C7.6861 6.20209 7.4945 6.39598 7.30569 6.58919Z'
      fill={color}
    />
    <path
      d='M10.9254 7.16896C10.6222 7.48385 10.3106 7.8015 9.99226 8.11987C9.79808 8.31404 9.60419 8.50564 9.41097 8.69444L10.1051 9.38851C10.209 9.40499 10.3132 9.42313 10.4175 9.44249C10.57 9.29795 10.7217 9.15033 10.8722 8.9998C11.191 8.68104 11.4965 8.3572 11.7873 8.03089L10.9254 7.16896Z'
      fill={color}
    />
    <path
      d='M8.81131 8.0948L7.90535 7.18886C8.09441 6.99528 8.28607 6.80128 8.47986 6.60751C8.79729 6.29008 9.11524 5.97833 9.43099 5.67464L10.3256 6.56921C10.0219 6.88494 9.71013 7.20288 9.39269 7.52031C9.19888 7.71411 9.0049 7.90574 8.81131 8.0948Z'
      fill={color}
    />
    <path
      d='M11.6443 3.64893C11.134 4.09006 10.5963 4.57512 10.0461 5.09063L10.9096 5.95405C11.4251 5.40387 11.9102 4.86621 12.3513 4.35594L11.6443 3.64893Z'
      fill={color}
    />
    <path
      d='M6.72392 8.43029C6.91703 8.22181 7.11485 8.01122 7.31666 7.79927L8.20091 8.68344C7.98882 8.88539 7.77805 9.08335 7.56943 9.27658C7.24037 9.30199 6.91663 9.34315 6.59931 9.40108C6.65911 9.07357 6.69942 8.74935 6.72392 8.43029Z'
      fill={color}
    />
    <path
      d='M12.6607 2.79963C12.5408 2.89595 12.4183 2.99595 12.2936 3.09914L12.9011 3.70667C13.0043 3.582 13.1043 3.4595 13.2007 3.33961C13.9555 2.39993 14.3198 1.83279 14.4956 1.50479C14.1676 1.6805 13.6004 2.04474 12.6607 2.79963Z'
      fill={color}
    />
    <path
      d='M5.47737 4.6799C5.89665 6.17038 6.21901 8.40352 5.38995 10.6101C7.59667 9.78118 9.82994 10.1035 11.3204 10.5228C11.6516 10.6159 11.8643 10.901 11.8621 11.2489C11.8598 11.63 11.5981 11.9659 11.2259 12.0657C10.4193 12.2819 9.63986 12.6679 8.90934 13.2127C8.12596 13.797 7.16813 14.3155 6.28147 14.6352C5.76988 14.8196 5.10841 15 4.47842 15C4.19643 15 3.92079 14.9639 3.66765 14.8758C3.12489 14.6869 2.75821 14.2872 2.60717 13.7199C2.56412 13.5582 2.44185 13.4359 2.28009 13.3929C1.71274 13.2418 1.31308 12.8751 1.12421 12.3324C0.839336 11.5138 1.09784 10.4592 1.36486 9.71871C1.68453 8.83205 2.20301 7.87425 2.78735 7.09087C3.33221 6.36043 3.71813 5.58106 3.93438 4.77441C4.03416 4.40215 4.37005 4.14054 4.75114 4.1382L4.75637 4.13817C5.10199 4.13817 5.38467 4.35034 5.47737 4.6799Z'
      fill={color}
    />
  </svg>
);

export default CropCorn;
