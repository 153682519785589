const SVGFieldClusterGlyph = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask id='path-1-inside-1_28483_192553' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3 2C2.44772 2 2 2.44772 2 3V13.7272C2 14.2795 2.44772 14.7272 3 14.7272H9.27295V21C9.27295 21.5523 9.72067 22 10.273 22H21C21.5523 22 22 21.5523 22 21V10.2728C22 9.72051 21.5523 9.27279 21 9.27279H14.7271V3C14.7271 2.44772 14.2793 2 13.7271 2H3Z'
        />
      </mask>
      <path
        d='M9.27295 14.7272H10.7729V13.2272H9.27295V14.7272ZM14.7271 9.27279H13.2271V10.7728H14.7271V9.27279ZM3.5 3C3.5 3.27614 3.27614 3.5 3 3.5V0.5C1.61929 0.5 0.5 1.61929 0.5 3H3.5ZM3.5 13.7272V3H0.5V13.7272H3.5ZM3 13.2272C3.27614 13.2272 3.5 13.4511 3.5 13.7272H0.5C0.5 15.1079 1.61929 16.2272 3 16.2272V13.2272ZM9.27295 13.2272H3V16.2272H9.27295V13.2272ZM10.7729 21V14.7272H7.77295V21H10.7729ZM10.273 20.5C10.5491 20.5 10.7729 20.7239 10.7729 21H7.77295C7.77295 22.3807 8.89224 23.5 10.273 23.5V20.5ZM21 20.5H10.273V23.5H21V20.5ZM20.5 21C20.5 20.7239 20.7239 20.5 21 20.5V23.5C22.3807 23.5 23.5 22.3807 23.5 21H20.5ZM20.5 10.2728V21H23.5V10.2728H20.5ZM21 10.7728C20.7239 10.7728 20.5 10.5489 20.5 10.2728H23.5C23.5 8.89208 22.3807 7.77279 21 7.77279V10.7728ZM14.7271 10.7728H21V7.77279H14.7271V10.7728ZM13.2271 3V9.27279H16.2271V3H13.2271ZM13.7271 3.5C13.4509 3.5 13.2271 3.27614 13.2271 3H16.2271C16.2271 1.61929 15.1078 0.5 13.7271 0.5V3.5ZM3 3.5H13.7271V0.5H3V3.5Z'
        fill='#0071CD'
        mask='url(#path-1-inside-1_28483_192553)'
      />
      <mask id='path-3-inside-2_28483_192553' fill='white'>
        <rect x='5.63623' y='5.63647' width='5.45445' height='5.45452' rx='1' />
      </mask>
      <rect
        x='5.63623'
        y='5.63647'
        width='5.45445'
        height='5.45452'
        rx='1'
        stroke='#0071CD'
        strokeWidth='3'
        mask='url(#path-3-inside-2_28483_192553)'
      />
      <mask id='path-4-inside-3_28483_192553' fill='white'>
        <rect x='12.9092' y='12.9094' width='5.45445' height='5.45452' rx='1' />
      </mask>
      <rect
        x='12.9092'
        y='12.9094'
        width='5.45445'
        height='5.45452'
        rx='1'
        stroke='#0071CD'
        strokeWidth='3'
        mask='url(#path-4-inside-3_28483_192553)'
      />
    </svg>
  );
};

export default SVGFieldClusterGlyph;
