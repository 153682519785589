import { Tooltip } from 'antd';
import type React from 'react';
import styled from 'styled-components';
import { getInitials } from '../../utils/functions';
import STTypo, { STTypoModels } from '../typo';
import { TypeOptions } from '../typo/typo.models';
import STAvatar from './avatar.component';
import './avatar.styles.less';

const StyledList = styled.div`
  display: flex;
`;

interface ISTAvatarListProps {
  names: string[];
  disabled?: boolean;
  showLabel?: boolean;
  maxCropsBeforeGroup?: number;
}

const STAvatarList: React.FC<ISTAvatarListProps> = ({ names, disabled, showLabel = true, maxCropsBeforeGroup = 3 }) => {
  let namesList = names;
  let otherNames = new Array<string>();

  if (names.length > maxCropsBeforeGroup + 1) {
    namesList = names.slice(0, maxCropsBeforeGroup);
    otherNames = names.slice(maxCropsBeforeGroup);
  }

  const list = (
    <StyledList>
      {namesList &&
        namesList.map((name, idx) => (
          <Tooltip key={idx} title={name} placement='top'>
            <STAvatar key={idx} small disabled={disabled} style={{ marginRight: '4px' }}>
              {getInitials(name)}
            </STAvatar>
          </Tooltip>
        ))}
      {otherNames.length > 0 && (
        <Tooltip
          title={otherNames.map((name, index) => (
            <div key={index}>
              <STTypo type={STTypoModels.TypeOptions.P2}>{name}</STTypo>
            </div>
          ))}>
          <STAvatar disabled={disabled} small>
            +{otherNames.length}
          </STAvatar>
        </Tooltip>
      )}
    </StyledList>
  );

  return showLabel && namesList && namesList.length < 2 ? (
    <STAvatar label={namesList[0]} small typo={TypeOptions.P1} disabled={disabled}>
      {getInitials(namesList[0])}
    </STAvatar>
  ) : (
    list
  );
};

export default STAvatarList;
