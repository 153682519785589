import { Divider, Tag } from 'antd';
import { COLOR_OPTIONS } from 'config/style';
import STAvatar from 'core/shared/avatar';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { getInitials } from 'core/utils/functions';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as BugNavLink } from 'react-router-dom';

export interface IBreadcrumbPopoverItems {
  id: UUID;
  name: string;
  path: string;
}

interface ISTBreadcrumbCompanyPopoverProps {
  items: IBreadcrumbPopoverItems[];
  selected?: UUID;
  header: string;
  footer: string;
  footerClick: () => void;
  itemClick: (item: UUID, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  showFooter?: boolean;
}

const NavLink = BugNavLink as React.FunctionComponent<any>;

const STBreadcrumbPopover: React.FC<ISTBreadcrumbCompanyPopoverProps> = ({
  items,
  selected,
  header,
  footer,
  footerClick,
  itemClick,
  showFooter = true
}) => {
  const [t] = useTranslation();

  return (
    <div className='st-breadcrumb__popover'>
      <STTypo>{header}</STTypo>
      <div className='st-breadcrumb__popover--list'>
        {items.map(item => (
          <NavLink
            key={item.id}
            end
            to={item.path}
            className='st-breadcrumb__popover--list-item'
            onClick={event => {
              itemClick(item.id, event);
            }}>
            <STAvatar small label={item.name}>
              {getInitials(item.name)}
            </STAvatar>
            {selected === item.id && (
              <Tag color='#EDFEF0'>
                <STTypo italic type={TypeOptions.C1} color={COLOR_OPTIONS.SUCCESS}>
                  {t('components.breadcrumb.im_here')}
                </STTypo>
              </Tag>
            )}
          </NavLink>
        ))}
      </div>
      {showFooter && (
        <div onClick={footerClick} className='st-breadcrumb__popover--footer' data-testid='breadcrumb-footer-action'>
          <Divider />
          <STTypo color={COLOR_OPTIONS.SUCCESS}>{footer}</STTypo>
        </div>
      )}
    </div>
  );
};

export default STBreadcrumbPopover;
