import type { Option } from 'components/SD-multi-select-card/multi-select-card';
import i18n from 'config/i18n';
import type { Dictionary } from 'config/types';
import { flatten, forEach, includes, isEmpty, isNaN, isNil, map, size, toArray } from 'lodash';
import type { ConfigurationParameterDTO } from 'pages/borer-risk/borer-risk-map/borer-risk.models';
import { ConfigurationParameterType } from 'pages/borer-risk/borer-risk-map/borer-risk.models';
import type { ReactNode } from 'react';
import type { RiskParametersType } from '../../field-clustering-configuration.models';
import ListTypeParameter from './components/list-type-parameter';
import StripTypeParameter from './components/strip-type-parameter';

const parseObjToArr = obj => flatten(toArray(obj));

export const filterParameters = (parameters: Dictionary<ConfigurationParameterDTO>): ConfigurationParameterDTO[] => {
  let filteredParameters: ConfigurationParameterDTO[] = [];

  forEach(parameters, parameter => {
    const except = parameter.type === ConfigurationParameterType.DATE;

    if (parameter.used && !except) {
      filteredParameters = [...filteredParameters, parameter];
    }
  });

  return filteredParameters;
};

export const populateRiskParametersValue = (filteredParameters: ConfigurationParameterDTO[]): RiskParametersType => {
  const riskParameters = {};

  forEach(filteredParameters, parameter => {
    riskParameters[parameter.id] = {
      type: parameter.type,
      value: undefined
    };
  });

  return riskParameters;
};

export const getExpandedComponentByParameter = (parameter: ConfigurationParameterDTO): ReactNode => {
  const componentDictionary = {
    [ConfigurationParameterType.LIST]: <ListTypeParameter parameter={parameter} />,
    [ConfigurationParameterType.STRIP]: <StripTypeParameter parameter={parameter} />,
    [ConfigurationParameterType.CUSTOMIZED]: <ListTypeParameter parameter={parameter} />
  };

  return componentDictionary?.[parameter?.type];
};

export const getCaneCutOptions = (parameter: ConfigurationParameterDTO, preSelectedOptions) => {
  const nameKey = 'pages.borer_risk.configuration.description.cane_cut.customization';

  const levelsLength = size(parameter?.levels);
  const caneCutByIndex = {
    0: 'no_cut',
    [levelsLength - 1]: 'last_cut_number'
  };

  return map(parameter?.levels, (_level, index: number) => ({
    id: index.toString(),
    name: i18n.t(`${nameKey}.${caneCutByIndex[index] ?? 'cut_number'}`, { cut_number: index }),
    disabled: includes(preSelectedOptions, index.toString())
  }));
};

export const getSusceptibleVarietyNumericalOptions = preSelectedOptions => [
  {
    id: '1',
    name: `1 - ${i18n.t('pages.borer_risk.spreadsheet.values.resistant')}`,
    disabled: includes(preSelectedOptions, '1')
  },
  {
    id: '2',
    name: `2 - ${i18n.t('pages.borer_risk.spreadsheet.values.intermediate')}`,
    disabled: includes(preSelectedOptions, '2')
  },
  {
    id: '3',
    name: `3 - ${i18n.t('pages.borer_risk.spreadsheet.values.susceptible')}`,
    disabled: includes(preSelectedOptions, '3')
  }
];

export const getCustomizedOptions = (parameter: ConfigurationParameterDTO, preSelectedOptions) => {
  return map(parameter.levels, (_, key) => {
    const id = key?.toString();
    return {
      id,
      name: parameter.custom ? id : i18n.t(`pages.borer_risk.spreadsheet.values.${id}`),
      disabled: includes(preSelectedOptions, id)
    };
  });
};

export type ListParameterGroups = Record<string, { id: string; options: string[] }>;

export const getOptionsByParameter = (parameter: ConfigurationParameterDTO, preSelectedOptions: ListParameterGroups): Option[] => {
  const options = parseObjToArr(Object.values(preSelectedOptions).flatMap(selectOpt => selectOpt.options));

  if (parameter.type === ConfigurationParameterType.CUSTOMIZED) {
    return getCustomizedOptions(parameter, options);
  }

  const optionsByParameterName = {
    cane_cut: getCaneCutOptions(parameter, options),
    susceptible_variety_numerical: getSusceptibleVarietyNumericalOptions(options)
  };

  return optionsByParameterName?.[parameter.name];
};

export const getRangeByParameter = (parameter: ConfigurationParameterDTO): { rangeUnit: string; max: number } => {
  const rangeByParameterName = {
    infestation_history: {
      rangeUnit: '%',
      max: 100
    },
    productivity_estimate: {
      rangeUnit: 'TCH',
      max: 250
    }
  };

  return rangeByParameterName?.[parameter?.name];
};

export const toPrecision = (value: number, precision = 2): any => {
  if (isNil(value) || isNaN(value) || isEmpty(value)) {
    return value;
  }

  return value.toFixed(precision);
};

export const invalidValues = (value?) =>
  isNil(value) || isEmpty(value) || includes(value, undefined) || includes(value, null) || includes(value, '');

export const parseAcceptanceLevelToPercent = (acceptanceLevel, amountRiskParametersSelected) => {
  if (amountRiskParametersSelected <= 0 || acceptanceLevel <= 0 || isNil(acceptanceLevel) || isNil(amountRiskParametersSelected)) {
    return 0;
  }

  return (acceptanceLevel / amountRiskParametersSelected) * 100;
};

export const parsePercentToAcceptanceLevel = (percent, amountRiskParametersSelected) => {
  if (amountRiskParametersSelected <= 0 || percent <= 0 || isNil(percent) || isNil(amountRiskParametersSelected)) {
    return 0;
  }

  return Math.round((percent * amountRiskParametersSelected) / 100);
};

export const removeGroup = (groups, groupId) => {
  let newGroups = {};
  let newIndex = -1;

  forEach(groups, (group, key) => {
    if (key !== groupId) {
      newGroups = {
        ...newGroups,
        [++newIndex]: group
      };
    }
  });
  return newGroups;
};
