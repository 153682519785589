import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { CHUNK_PAGE, CORE_SERVICES_API_URL, PROTECTOR_API_URL } from 'config/constants';
import type { PageableQuery, UUID, V4Page } from 'core/utils/basic.models';
import { mockPageableDataInAxiosResponse } from 'core/utils/functions';
import type { MethodologyEntity } from 'entities/methodology/methodology.models';
import type { IndicatorDTO, Phenomenon } from 'entities/phenomenon/phenomenon.models';
import moment from 'moment';
import type { Observable } from 'rxjs';
import { EMPTY, of } from 'rxjs';
import { concatMap, expand, map, toArray } from 'rxjs/operators';
import type { V1Region } from '../region/region.models';
import type { SeasonFlat } from '../season/season.models';
import type {
  CurrentSeasonArea,
  DataAvailabityResponse,
  DiagnosticBreakdown,
  IndicatorPressureResponse,
  Property,
  PropertyInfoAddress,
  PropertyInfoAddressDTO,
  PropertyPage,
  PropertyTime
} from './property.models';

const protectorApiUrl = PROTECTOR_API_URL;
const coreServicesApiUrl = CORE_SERVICES_API_URL;

const basicQuery = '?includeSeasons=yes&includeAreaInformation=no';
const completeQuery = '?includeSeasons=yes&includeAreaInformation=yes';
const diagnosticBreakdownQuery = '?includeSeasons=yes&includeDiagnosticBreakdown=yes';
const propertyUrl = `${protectorApiUrl}/v1/panel/properties`;
const propertiesV2 = `${coreServicesApiUrl}/v2/properties`;
const propertyDiagnosticBreakdownUrl = `${protectorApiUrl}/v1/analytics/region`;
const currentSeasonAreasUrl = (id: UUID, allowPastSeason?: boolean, date?: string) =>
  `${coreServicesApiUrl}/v1/properties/${id}/current-season-areas?date=${date}${allowPastSeason ? '&allowPastSeasons=true' : ''}`;
const dataAvailabityUrl = (id: string) => `${protectorApiUrl}/v1/properties/${id}/data-availability `;
const propertySeasonsUrl = (propertyId: UUID, page: number) =>
  `${protectorApiUrl}/v2/properties/${propertyId}/seasons?page=${page}&size=${CHUNK_PAGE}`;
const propertyRegionsUrl = (propertyId: UUID) => `${protectorApiUrl}/v1/properties/${propertyId}/regions`;
const methodologyForSeasonAreaUrl = (propertyId: UUID) => `${protectorApiUrl}/v1/methodologies/property/${propertyId}/by-season`;
const indicatorPressureUrl = `${protectorApiUrl}/v1/analytics/indicator-pressure`;
const methodologiesByIdUrl = `${protectorApiUrl}/api/v1/methodologies/ids`;
const indicatorsByIdUrl = `${protectorApiUrl}/api/v1/indicators/ids`;
const phenomenaByIdUrl = `${protectorApiUrl}/api/v1/phenomena/ids/simple`;

export const getPropertyBasicInfo = (request: PageableQuery, areaInfo: boolean): AxiosObservable<PropertyPage> => {
  return axios
    .get<PropertyPage>(`${propertyUrl}${areaInfo ? completeQuery : basicQuery}`) // @todo Mudar para basicQuery
    .pipe(map(mockPageableDataInAxiosResponse));
};

export const getPropertyDiagnosticBreakdown = (): AxiosObservable<PropertyPage> => {
  return axios.get<PropertyPage>(`${propertyUrl}${diagnosticBreakdownQuery}`).pipe(map(mockPageableDataInAxiosResponse));
};

export const getMethodologyIdsForPropertySeasonAndAreas = (propertyId: UUID, seasonIds: UUID[], areaIds: UUID[]): Observable<UUID[]> => {
  return axios.post(methodologyForSeasonAreaUrl(propertyId), { seasonIds, areaIds }).pipe(map(response => response.data));
};

export const getMethodologiesByIds = (ids: UUID[]): Observable<MethodologyEntity[]> => {
  return axios.post(methodologiesByIdUrl, { ids }).pipe(map(response => response.data));
};

export const getIndicatorsByIds = (ids: UUID[]): Observable<IndicatorDTO[]> => {
  return axios.post(indicatorsByIdUrl, { ids }).pipe(map(response => response.data));
};

export const getPhenomenaByIds = (ids: UUID[]): Observable<Phenomenon[]> => {
  return axios.post(phenomenaByIdUrl, { ids }).pipe(map(response => response.data));
};

// @TODO GET ONLY ONE PROPERTY
export const getProperty = (propertyId: UUID, companyId: UUID): Observable<Property | undefined> => {
  return axios.get<PropertyPage>(`${propertyUrl}`).pipe(
    map(mockPageableDataInAxiosResponse),
    map(response => response.data.mutableContent.find(p => p.id === propertyId))
  );
};

const transformPropertyData = (data: PropertyInfoAddressDTO): PropertyInfoAddress => ({
  address: data.address,
  city: data.city,
  companyId: data.org_id,
  country: data.country,
  createdAt: data.created_at,
  createdById: data.created_by_id,
  crn: data.crn,
  deleted: data.deleted,
  fieldsUpdatedAt: data.fields_updated_at,
  id: data.id,
  lastUpdatedById: data.last_updated_by_id,
  name: data.name,
  placeId: data.place_id,
  // reverse coords for backward compatability with old endpoint
  referencePoint: { ...data.reference_point, coordinates: [...data.reference_point.coordinates].reverse() },
  regulatoryZone: data.regulatory_zone,
  rootRegionId: data.root_region_id,
  state: data.state,
  timeZone: data.time_zone,
  updatedAt: data.updated_at,
  waitResponse: data.wait_response,
  zipCode: data.zip_code
});

export const getPropertyData = (propertyId: UUID): Observable<PropertyInfoAddress> => {
  return axios.get<PropertyInfoAddressDTO>(`${propertiesV2}/${propertyId}`).pipe(map(response => transformPropertyData(response.data)));
};

export const getCurrentSeasonAreas = (propertyId: UUID, allowPastSeason?: boolean, dataParam?: string) => {
  return axios
    .get(currentSeasonAreasUrl(propertyId, allowPastSeason, moment(dataParam).format('YYYY-MM-DD')))
    .pipe<CurrentSeasonArea[]>(map(response => response.data));
};

/** @deprecated v1/season-areas will be deprecated in the future, if possible use POST v2/season-fields/list */
export const getSeasonAreasBySeasons = (propertyId: UUID, seasonIds: UUID[] = [], allowPastSeasonAreas = true) => {
  if (seasonIds?.length) {
    return axios
      .get(
        `${protectorApiUrl}/v1/season-areas?seasonIds=${seasonIds.toString()}&propertyId=${propertyId}&allowPastSeasonAreas=${allowPastSeasonAreas}`
      )
      .pipe<CurrentSeasonArea[]>(map(response => response.data));
  }
  return of([]);
};

export const getDataAvailabity = (propertyId: UUID): Observable<DataAvailabityResponse> => {
  return axios.get<DataAvailabityResponse>(dataAvailabityUrl(propertyId)).pipe(map(response => response.data));
};

export const getSinglePropertyDiagnosticBreakdown = (
  propertyId: UUID,
  regionId: UUID,
  seasons: UUID[]
): AxiosObservable<DiagnosticBreakdown> => {
  return axios.post<DiagnosticBreakdown>(propertyDiagnosticBreakdownUrl, {
    propertyId,
    regionId,
    breakdownType: 'AREA',
    seasons
  });
};

export const getPropertyTime = (id: UUID): Observable<PropertyTime> => {
  return axios.get<PropertyTime>(`${coreServicesApiUrl}/v1/properties/${id}/date-time`).pipe(map(response => response.data));
};

const getSeasonsByPropertyPaginated = (propertyId: UUID, page = 0) => {
  return axios.get<V4Page<SeasonFlat>>(propertySeasonsUrl(propertyId, page)).pipe(map(r => r.data));
};

export const getSeasonsByProperty = (propertyId: UUID) => {
  return getSeasonsByPropertyPaginated(propertyId, 0).pipe(
    expand(({ last, number }) => (!last ? getSeasonsByPropertyPaginated(propertyId, number + 1) : EMPTY)),
    concatMap(({ content }) => content),
    toArray()
  );
};

export const getRegionsByProperty = (propertyId: UUID) => {
  return axios.get<V1Region[]>(propertyRegionsUrl(propertyId)).pipe(concatMap(r => of(r.data)));
};

export const getIndicatorPressure = (propertyId: UUID, indicatorIds: UUID[], methodologyIds: UUID[]) => {
  return axios
    .post<IndicatorPressureResponse[]>(indicatorPressureUrl, {
      property_id: propertyId,
      indexes: indicatorIds,
      methodologies: methodologyIds,
      type: 'LATEST'
    })
    .pipe(map(response => response.data));
};
