import type { Crop, UUID } from 'core/utils/basic.models';
import { generateUUID, getCurrentLanguage } from 'core/utils/functions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { isAnyBarleyCrop, isAnyWheatCrop } from 'entities/crop/crop.domain';
import type { Region } from 'entities/region/region.models';
import { RegionType } from 'entities/region/region.models';
import { selectRegionEntities } from 'entities/region/region.reducer';
import { RequestReport } from 'entities/reports/report.actions';
import type { RequestReportBody } from 'entities/reports/report.models';
import type { Season } from 'entities/season/season.models';
import { Disease } from 'pages/disease-risk/disease-risk.models';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import { ReportsTypes } from '../reports-types.enum';

export interface IReportDiseaseRiskBloc {
  allSeasons: Season[];
  selectedSeasonId?: UUID;
  handleSelectSeason: (seasonId: UUID) => void;
  availableFields: Region[];
  selectedFieldIds: UUID[];
  handleSelectFields: (fieldIds: UUID[]) => void;
  handleSelectAllFields: () => void;
  availableDiseases: Disease[];
  selectedDiseases: Disease[];
  handleSelectDiseases: (diseases: Disease[]) => void;
  handleSelectAllDiseases: () => void;
  isFieldsDisabled: boolean;
  isDiseasesDisabled: boolean;
  isSubmitDisabled: boolean;
  handleSubmit: () => void;
}

export interface ReportDiseaseRiskBlocProps {
  onSubmitted?: () => void;
}

interface ValidatedFields {
  propertyId: UUID;
  companyId: UUID;
  seasonIds: UUID[];
  fieldIds: UUID[];
  diseases: Disease[];
}

const MAX_DISEASES = 6;

export const useReportDiseaseRisk = ({ onSubmitted }: ReportDiseaseRiskBlocProps): IReportDiseaseRiskBloc => {
  const [selectedSeasonId, setSelectedSeasonId] = useState<UUID>();
  const [selectedFieldIds, setSelectedFieldIds] = useState<UUID[]>([]);
  const [selectedDiseases, setSelectedDiseases] = useState<Disease[]>([]);

  const allSeasonsEntities = useSelector<AppState, Record<string, Season>>(state => state.entities.season.entities);
  const selectedProperty = useSelector<AppState, string | null>(state => state.uiState.global.selectedProperty);
  const allFields = useSelector<AppState, Record<string, Region>>(state => selectRegionEntities(state.entities.region));
  const selectedCompany = useSelector<AppState, string | null>(state => state.uiState.global.selectedCompany);

  const segmentTracking = useSegmentTracking();

  const allSeasons = useMemo<Season[]>(() => {
    return Object.values(allSeasonsEntities);
  }, [allSeasonsEntities]);

  const availableFields = useMemo<Region[]>(() => {
    if (!selectedSeasonId) {
      return [];
    }

    return Object.values(allFields).filter(field => {
      if (field.type !== RegionType.AREA) return false;

      const crop: Crop = field.seasons[0].crop;
      if (!isAnyBarleyCrop(crop) && !isAnyWheatCrop(crop)) return false;

      return field.seasons.some(season => season.id === selectedSeasonId);
    });
  }, [selectedSeasonId, allFields]);

  const availableDiseases: Disease[] = useMemo(() => {
    if (!selectedSeasonId) {
      return [];
    }

    const selectedSeason = allSeasonsEntities[selectedSeasonId];
    if (!selectedSeason) {
      return [];
    }

    if (isAnyWheatCrop(selectedSeason.crop)) {
      return [Disease.YELLOW_RUST, Disease.BROWN_RUST, Disease.SEPTORIA, Disease.POWDERY_MILDEW, Disease.FUSARIUM, Disease.EYESPOT];
    }
    if (isAnyBarleyCrop(selectedSeason.crop)) {
      return [Disease.RHYNCHOSPORIUM, Disease.RAMULARIA, Disease.LEAF_RUST, Disease.POWDERY_MILDEW, Disease.NET_BLOTCH];
    }

    return [];
  }, [selectedSeasonId, allSeasonsEntities]);

  const validatedFields = useMemo<ValidatedFields | null>(() => {
    if (!selectedProperty) return null;
    if (!selectedSeasonId) return null;
    if (!selectedFieldIds.length) return null;
    if (!selectedDiseases.length) return null;
    if (!selectedCompany) return null;
    if (selectedDiseases.length > 6) return null;

    return {
      propertyId: selectedProperty,
      companyId: selectedCompany,
      seasonIds: [selectedSeasonId],
      fieldIds: [...selectedFieldIds],
      diseases: [...selectedDiseases]
    };
  }, [selectedProperty, selectedSeasonId, selectedFieldIds, selectedDiseases, selectedCompany]);

  const isSubmitDisabled: boolean = useMemo(() => validatedFields === null, [validatedFields]);
  const isFieldsDisabled: boolean = useMemo(() => !selectedSeasonId, [selectedSeasonId]);
  const isDiseasesDisabled: boolean = useMemo(() => !selectedSeasonId, [selectedSeasonId]);

  const dispatch = useDispatch();

  const handleSelectSeason = useCallback(
    (seasonId: UUID) => {
      const seasonToSelect: Season = allSeasonsEntities[seasonId];
      if (!seasonToSelect) {
        return;
      }
      setSelectedFieldIds([]);
      setSelectedDiseases([]);
      setSelectedSeasonId(seasonToSelect.id);
    },
    [allSeasonsEntities]
  );

  const handleSelectFields = (fieldIds: UUID[]) => {
    const fieldsToSelect = availableFields.filter(field => fieldIds.includes(field.id)).map(field => field.id);
    setSelectedFieldIds(fieldsToSelect);
  };

  const handleSelectAllFields = () => {
    setSelectedFieldIds(availableFields.map(field => field.id));
  };

  const handleSelectDiseases = (diseases: Disease[]) => {
    const diseasesToSelect = diseases
      .filter((_disease, index) => index < MAX_DISEASES)
      .filter(disease => availableDiseases.includes(disease));
    setSelectedDiseases(diseasesToSelect);
  };

  const handleSelectAllDiseases = () => {
    handleSelectDiseases(availableDiseases);
  };

  const handleSubmit = () => {
    if (!validatedFields) {
      return;
    }
    const { fieldIds, companyId, propertyId, seasonIds, diseases } = validatedFields;
    const body: RequestReportBody = {
      id: generateUUID(),
      type: ReportsTypes.DISEASE_RISK,
      property_id: propertyId,
      data_request: {
        comments: '',
        company_id: companyId,
        language: getCurrentLanguage(),
        season_ids: seasonIds,
        area_ids: fieldIds,
        diseases
      }
    };

    segmentTracking.track('Reports - Export Report - Disease risk Report created');
    dispatch(RequestReport(body));
    onSubmitted?.();
  };

  return {
    allSeasons,
    selectedSeasonId,
    handleSelectSeason,
    availableFields,
    selectedFieldIds,
    handleSelectFields,
    handleSelectAllFields,
    availableDiseases,
    selectedDiseases,
    handleSelectDiseases,
    handleSelectAllDiseases,
    isSubmitDisabled,
    isFieldsDisabled,
    isDiseasesDisabled,
    handleSubmit
  };
};
