import type React from 'react';

export const ProtectorIcon: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
      <path
        fill='#68c573'
        d='M21.52 3.217C21.307 3.127 16.29 1 12 1 7.71 1 3.008 2.99 2.469 3.217L2 3.421l.082.497c.328 1.912.422 3.733.504 5.497.14 2.738.258 5.102 1.243 6.912 1.418 2.601 3.54 3.88 6.237 5.485.234.147.48.283.726.441.27.17.563.34.845.52L12 23l.363-.226c.516-.317 1.571-.95 1.571-.95 2.685-1.607 4.807-2.885 6.237-5.486.985-1.81 1.102-4.163 1.243-6.911.082-1.765.176-3.586.504-5.497L22 3.432l-.469-.204-.012-.011ZM5 15.704c-.844-1.55-.961-3.777-1.09-6.38-.082-1.56-.164-3.325-.434-5.123a35.294 35.294 0 0 1 2.509-.86c-.082.442-.2.973-.328 1.584-.223 1.03-.481 2.194-.669 3.472-.304 2.104-.468 5.837 1.29 9.06a8.546 8.546 0 0 1-1.266-1.753H5Zm2.755 1.72C5.87 14.302 5.998 10.66 6.302 8.578c.176-1.244.434-2.398.669-3.427.187-.86.351-1.606.433-2.205.88-.227 1.782-.408 2.685-.52-.129.282-.27.587-.434.938-.527 1.142-1.195 2.568-1.793 4.242-.481 1.3-1.724 5.474-.094 9.818h-.012Zm1.618.588c-2.099-4.332-.61-9.004-.258-9.977.586-1.617 1.231-3.02 1.759-4.14.27-.588.527-1.142.715-1.594H12c.786 0 1.676.079 2.626.249A28.138 28.138 0 0 0 11.555 6.1C9.808 8.511 8.12 12.47 9.373 18.034v-.022Zm.996-1.753c-.433-3.53.305-6.663 2.216-9.343l.07-.09a27.46 27.46 0 0 1 3.541-3.971c1.114.271 2.216.588 3.294.973-.89.418-1.758.905-2.567 1.459-3.083 2.07-6.413 5.554-6.542 10.972h-.011Zm9.707-6.923c-.129 2.59-.246 4.819-1.09 6.368-1.254 2.296-3.142 3.428-5.756 4.988l-.75.453C9.984 13.487 13.91 8.872 17.674 6.35a18.968 18.968 0 0 1 2.755-1.516c-.21 1.618-.293 3.213-.352 4.502Z'
      />
    </svg>
  );
};
