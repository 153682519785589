import type { Selector } from 'core/core.selectors';
import type { PropertyState } from 'entities/property/property.models';
import { StaticPointType } from 'entities/static-points/static-points.models';
import type { StaticPointsDrawerState } from 'pages/static-points/static-points-drawer/redux/static-points-drawer.models';

export const getSelectedTemplate: Selector<StaticPointsDrawerState['selectedTemplate']> = state =>
  state.components.staticPointsDrawer.selectedTemplate;

export const getCurrentAreaSeasons: Selector<PropertyState['currentAreaSeasons']> = state => state.entities.property.currentAreaSeasons;

export const getTrapDrawerIsVisible: Selector<boolean> = state => {
  const isVisible = state.components.staticPointsDrawer.visible;
  const isTrapType = state.components.staticPointsDrawer.type === StaticPointType.TRAP;

  return isVisible && isTrapType;
};
