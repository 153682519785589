import type { GlobalUiState } from 'core/core.models';
import type { PermissionFlags } from 'core/services/auth/auth.models';
import type React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { ReportDrawerApplicationsAndInventoryButtons } from './report-button/report-applications-and-inventory-buttons';
import { ReportDrawerFixedPointsButtons } from './report-button/report-fixed-point-buttons';
import { ReportDrawerMonitoringAndSamplingButtons } from './report-button/report-monitoring-and-sampling-buttons';
import type { ReportsTypes } from './reports-types.enum';

interface ReportDrawerButtonListTypes {
  setReportToOpen: Dispatch<SetStateAction<ReportsTypes | null>>;
  systemFlags: GlobalUiState['systemFlags'];
  permissionFlags: PermissionFlags;
}

const ReportDrawerButtonList: React.FC<ReportDrawerButtonListTypes> = ({ setReportToOpen, permissionFlags, systemFlags }) => {
  return (
    <div className='st-report-drawer__list' data-testid='report-list'>
      <ReportDrawerMonitoringAndSamplingButtons setReportToOpen={setReportToOpen} />
      <ReportDrawerApplicationsAndInventoryButtons setReportToOpen={setReportToOpen} permissionFlags={permissionFlags} />
      <ReportDrawerFixedPointsButtons setReportToOpen={setReportToOpen} permissionFlags={permissionFlags} systemFlags={systemFlags} />
    </div>
  );
};

export default ReportDrawerButtonList;
