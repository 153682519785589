import type { SeasonsAction, SetSeasonsAction } from './season.models';

export const SeasonActionsTypes = {
  LOAD_SEASONS: '[Season] Load Seasons',
  SET_SEASONS_FROM_PROPERTY: '[Season] Set seasons from property',
  SET_SEASONS_FROM_PROPERTIES: '[Season] Set seasons from properties',
  LOAD_SEASONS_SUCCESS: '[Season] Load Seasons Success'
};

export const SetSeasonsFromProperties = properties => {
  return {
    type: SeasonActionsTypes.SET_SEASONS_FROM_PROPERTIES,
    payload: properties
  };
};

export const SetSeasonsFromProperty: SetSeasonsAction = () => {
  return {
    type: SeasonActionsTypes.SET_SEASONS_FROM_PROPERTY
  };
};

export const LoadSeasonsSuccess: SeasonsAction = seasons => {
  return {
    type: SeasonActionsTypes.LOAD_SEASONS_SUCCESS,
    payload: seasons
  };
};
