import { notification } from 'antd';
import i18n from 'config/i18n';
import type { Action, PageableQuery, UUID } from 'core/utils/basic.models';
import type { MethodologyEntity } from 'entities/methodology/methodology.models';
import type { IndicatorDTO } from 'entities/phenomenon/phenomenon.models';
import moment from 'moment';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { ofType } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, concatMap, delay, exhaustMap, flatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import type { Nullable } from '../../core/core.models';
import { selectSelectedSeasons } from '../../core/core.reducer';
import type { AppState } from '../../redux/app-state';
import { filterSeasonsActive } from '../season/season.functions';
import type { GetCurrentSeasonAreas } from './property.actions';
import {
  GetCurrentSeasonAreasFailure,
  GetCurrentSeasonAreasSuccess,
  GetPropertyCountryFailure,
  GetPropertyCountrySuccess,
  GetSeasonAreasByDateFailure,
  GetSeasonAreasByDateSuccess,
  LoadPropertiesAreaInfoSuccess,
  LoadPropertiesFailure,
  LoadPropertiesSuccess,
  LoadPropertyDataFailure,
  LoadPropertyDataSuccess,
  LoadPropertyDiagnosticBreakdownFailure,
  LoadPropertyDiagnosticBreakdownSuccess,
  LoadPropertyIndicatorsSuccess,
  LoadPropertyMethodologiesSuccess,
  LoadPropertyPhenomenaSuccess,
  LoadPropertySuccess,
  LoadPropertyTimeSuccess,
  LoadSeverityByIndicatorSuccess,
  PropertyActionsTypes
} from './property.actions';
import type {
  CurrentSeasonArea,
  GetCurrentSeasonAreaPayload,
  IndicatorPressurePayload,
  LoadPropertyPhenomenaPayload,
  Property,
  PropertyDiagnosticBreakdown,
  PropertyInfoAddress,
  PropertyPage,
  PropertyTime
} from './property.models';
import {
  getCurrentSeasonAreas,
  getIndicatorPressure,
  getIndicatorsByIds,
  getMethodologiesByIds,
  getMethodologyIdsForPropertySeasonAndAreas,
  getPhenomenaByIds,
  getProperty,
  getPropertyBasicInfo,
  getPropertyData,
  getPropertyTime,
  getSeasonAreasBySeasons,
  getSinglePropertyDiagnosticBreakdown
} from './property.service';

const handlePropertyError = (error: any) => {
  setTimeout(() => {
    notification.error({
      message: i18n.t('general.property_not_allowed')
    });
  }, 2000);
  return of(LoadPropertiesFailure(error)).pipe(delay(5000));
};

const handlePropertyDiagnosticBreakdownError = (error: any) => {
  notification.error({
    message: i18n.t('general.property_not_allowed')
  });
  return of(LoadPropertyDiagnosticBreakdownFailure(error)).pipe(delay(5000));
};

export const handleLoadProperties = action$ =>
  action$.pipe(
    ofType(PropertyActionsTypes.LOAD_PROPERTIES),
    map((action: Action<PageableQuery>) => action.payload),
    exhaustMap((query: PageableQuery) =>
      getPropertyBasicInfo(query, false).pipe(
        map(response => response.data),
        catchError(handlePropertyError)
      )
    ),
    mergeMap((propertyPage: PropertyPage) => {
      if (!propertyPage?.mutableContent?.length) return from([LoadPropertiesFailure('')]);
      return from([LoadPropertiesSuccess(propertyPage)]);
    })
  );

export const handleLoadPropertiesAreaInfo = action$ =>
  action$.pipe(
    ofType(PropertyActionsTypes.LOAD_PROPERTIES_AREA_INFO),
    map((action: Action<PageableQuery>) => action.payload),
    exhaustMap((query: PageableQuery) =>
      getPropertyBasicInfo(query, true).pipe(
        map(response => response.data),
        catchError(handlePropertyError)
      )
    ),
    mergeMap((propertyPage: PropertyPage) => {
      if (!propertyPage?.mutableContent?.length) return from([LoadPropertiesFailure('')]);
      return from([LoadPropertiesAreaInfoSuccess(propertyPage)]);
    })
  );

export const handleGetPropertyCountry = action$ =>
  action$.pipe(
    ofType(PropertyActionsTypes.GET_PROPERTY_COUNTRY),
    map((action: Action<any[]>) => action.payload),
    concatMap((propertyId: UUID) => getPropertyData(propertyId)),
    map((propertyDetails: any) => {
      const response = {
        id: propertyDetails.id,
        country: propertyDetails.country
      };
      return GetPropertyCountrySuccess(response);
    }),
    catchError(err => of(GetPropertyCountryFailure(err)))
  );

export const handleLoadPropertyData = (action$, state$) =>
  action$.pipe(
    ofType(PropertyActionsTypes.LOAD_PROPERTY_DATA),
    map((action: Action<UUID>) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => state.entities.property.propertyData))),
    exhaustMap(([propertyId, propertyData]: [UUID, PropertyInfoAddress | null]) => {
      if (propertyData?.id === propertyId) {
        return of(LoadPropertyDataSuccess(propertyData));
      }
      return getPropertyData(propertyId).pipe(
        map(response => response),
        map((result: PropertyInfoAddress) => {
          return LoadPropertyDataSuccess(result);
        }),
        catchError((error: string) => of(LoadPropertyDataFailure(error)))
      );
    })
  );

export const handleLoadPropertiesDiagnosticBreakdown = (action$, state$) =>
  action$.pipe(
    ofType(PropertyActionsTypes.LOAD_PROPERTY_DIAGNOSTIC_BREAKDOWN),
    map((action: Action<any[]>) => action.payload),
    concatMap((properties: any[]) => from(properties)),
    withLatestFrom(state$.pipe(map((state: AppState) => selectSelectedSeasons(state.uiState.global)))),
    mergeMap<any, any>(([{ propertyId, regionId }, seasons], i) =>
      getSinglePropertyDiagnosticBreakdown(propertyId, regionId, seasons).pipe(
        map(response => ({ propertyId, diagnosticBreakdown: response.data, idx: i }))
      )
    ),
    map((propertyDiagnosticBreakdown: PropertyDiagnosticBreakdown) => LoadPropertyDiagnosticBreakdownSuccess(propertyDiagnosticBreakdown)),
    catchError(handlePropertyDiagnosticBreakdownError)
  );

export const handleLoadProperty = action$ =>
  action$.pipe(
    ofType(PropertyActionsTypes.LOAD_PROPERTY),
    map((action: Action<UUID>) => action.payload),
    concatMap(({ propertyId, companyId }) =>
      getProperty(propertyId, companyId).pipe(
        flatMap((property: Property | undefined) => {
          if (property) {
            return concat([LoadPropertySuccess(property)]);
          }
          return of(LoadPropertiesFailure('Property not found'));
        }),
        catchError(handlePropertyError)
      )
    )
  );

export const handleLoadIndicatorPressure = action$ =>
  action$.pipe(
    ofType(PropertyActionsTypes.LOAD_SEVERITY_BY_INDICATOR),
    map((action: Action<IndicatorPressurePayload>) => action.payload),
    mergeMap(({ propertyId, methodologyIds, indicatorIds }: IndicatorPressurePayload) => {
      return getIndicatorPressure(propertyId, indicatorIds, methodologyIds).pipe(
        mergeMap(results => {
          if (results) {
            return of(LoadSeverityByIndicatorSuccess(results, indicatorIds[0]));
          }
          return of(LoadSeverityByIndicatorSuccess([], indicatorIds[0]));
        })
      );
    })
  );

export const handleLoadPropertyPhenomena = action$ =>
  action$.pipe(
    ofType(PropertyActionsTypes.LOAD_PROPERTY_PHENOMENA),
    map((action: Action<LoadPropertyPhenomenaPayload>) => action.payload),
    switchMap(({ seasonIds, areaIds, propertyId }) => {
      return getMethodologyIdsForPropertySeasonAndAreas(propertyId, seasonIds, areaIds).pipe(
        map((methodologyIds: UUID[]): UUID[] => Object.values(methodologyIds.reduce((acc, el) => ({ ...acc, [el]: el }), {}))),
        mergeMap((methodologyIds: UUID[]) => {
          return getMethodologiesByIds(methodologyIds).pipe(
            mergeMap((methodologies: MethodologyEntity[]) => {
              const customIndicatorsIds: UUID[] = Object.values(
                methodologies
                  .reduce((acc: UUID[], methodology) => {
                    const indicatorIds = methodology.analytic_context_dto.custom_indicator_dtos.map(ci => ci.indicator_id);
                    return [...acc, ...indicatorIds];
                  }, [])
                  .reduce((acc, cid) => ({ ...acc, [cid]: cid }), {})
              );
              let phenomenonIds: UUID[] = [];
              methodologies.forEach(methodology => {
                methodology.inspection_layout.ordered_inspection_groups.forEach(ordered_inspection_group => {
                  ordered_inspection_group.ordered_categories.forEach(ordered_category => {
                    ordered_category.ordered_phenomenons.forEach(ordered_phenomena => {
                      phenomenonIds = [...phenomenonIds, ordered_phenomena.component_id];
                    });
                  });
                });
              });
              return getIndicatorsByIds(customIndicatorsIds).pipe(
                mergeMap((indicators: IndicatorDTO[]) => {
                  indicators.forEach(indicator => {
                    phenomenonIds = [...phenomenonIds, ...indicator.phenomenon_ids];
                  });
                  phenomenonIds = Object.values(phenomenonIds.reduce((acc, id) => ({ ...acc, [id]: id }), {}));
                  return getPhenomenaByIds(phenomenonIds).pipe(
                    mergeMap(phenomenons => {
                      return of(
                        LoadPropertyPhenomenaSuccess(phenomenons),
                        LoadPropertyIndicatorsSuccess(indicators),
                        LoadPropertyMethodologiesSuccess(methodologies)
                      );
                    })
                  );
                })
              );
            })
          );
        })
      );
    })
  );

export const handleLoadPropertyTime = action$ =>
  action$.pipe(
    ofType(PropertyActionsTypes.LOAD_PROPERTY_TIME),
    map((action: Action<UUID>) => action.payload),
    concatMap((propertyId: string) =>
      getPropertyTime(propertyId).pipe(
        flatMap((property: PropertyTime) => {
          return of(LoadPropertyTimeSuccess(property.currentDateTimeUTC));
        }),
        catchError(error => {
          console.error(error);
          return of();
        })
      )
    )
  );

const filterCurrentSeasonAreas = (seasonAreas: CurrentSeasonArea[], date: string, timezone: string) => {
  return seasonAreas.filter(
    item => moment.tz(item.endsAt, timezone).isSameOrAfter(moment(date)) && moment.tz(item.startsAt, timezone).isSameOrBefore(moment(date))
  );
};

export const handleGetCurrentSeasonAreas = (
  action$: ActionsObservable<ReturnType<typeof GetCurrentSeasonAreas>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(PropertyActionsTypes.GET_CURRENT_SEASON_AREAS),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(state => state.entities.property.propertyData)),
      state$.pipe(map(state => state.entities.property.entities))
    ),
    exhaustMap(([payload, propertyData, properties]) => {
      const seasons = properties[payload.propertyId]?.seasons || [];
      const seasonsIds = filterSeasonsActive(seasons).map(season => season.id);
      return getSeasonAreasBySeasons(payload.propertyId, seasonsIds).pipe(
        map(response => {
          let activeSeasonAreas = response;
          if (propertyData) {
            activeSeasonAreas = filterCurrentSeasonAreas(activeSeasonAreas, payload.date!, propertyData.timeZone);
          }
          return GetCurrentSeasonAreasSuccess({
            current: activeSeasonAreas,
            all: response,
            propertyId: payload.propertyId
          });
        }),
        catchError((error: string) => of(GetCurrentSeasonAreasFailure(error)))
      );
    })
  );

export const handleGetSeasonAreasByDate = (action$, state$) =>
  action$.pipe(
    ofType(PropertyActionsTypes.GET_SEASON_AREAS_BY_DATE),
    map((action: Action) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => state.entities.property.propertyData))),
    exhaustMap(([payload, propertyData]: [GetCurrentSeasonAreaPayload, Nullable<PropertyInfoAddress>]) => {
      return getCurrentSeasonAreas(payload.propertyId, payload.allowPastSeasons, payload.date).pipe(
        map(response => {
          let seasonAreas = response;
          if (propertyData) {
            seasonAreas = filterCurrentSeasonAreas(seasonAreas, payload.date!, propertyData.timeZone);
          }
          return GetSeasonAreasByDateSuccess({ date: payload.date, content: seasonAreas });
        }),
        catchError(error => of(GetSeasonAreasByDateFailure(error)))
      );
    })
  );
