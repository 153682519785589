import axios from 'axios-observable';
import { PROTECTOR_API_URL, S3_BUCKET_NAME } from 'config/constants';
import type { PayloadGetStaticPointsSeverityByField, TrapsSeverityByField } from 'pages/static-points/static-points.models';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { UUID } from '../../core/utils/basic.models';
import StaticPointsUtil from './static-points-util';
import type { GetStaticPointResponse, StaticPoint, StaticPointCreation, StaticPointFieldEnum } from './static-points.models';

const protectorApiUrl = PROTECTOR_API_URL;
const staticPointsUrl = `${protectorApiUrl}/v1/timeline/static-points`;
const archeBaseUrl = `${protectorApiUrl}/v1`;
const trapUrl = `${archeBaseUrl}/timeline/traps`;

export const getStaticPoint = (id: UUID, companyId: UUID): Observable<GetStaticPointResponse> => {
  return axios.get<any>(`${protectorApiUrl}/api/v1/companies/${companyId}/static-point/${id}`).pipe(map(response => response.data));
};

const getStaticPoints = (property_idParam: string, fields: StaticPointFieldEnum[]): Observable<StaticPoint[]> => {
  const params = `property_id=${property_idParam}&fields=${fields.join(',')}`;
  return axios
    .get<StaticPoint[]>(`${staticPointsUrl}?${params}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .pipe(map(response => response.data));
};

const getStaticPointImage = (id: UUID) => {
  return axios.get(`https://s3.amazonaws.com/${S3_BUCKET_NAME}/staticpoint/${id}.png`).pipe(map(r => r.data));
};

const getPhenomenaFromStaticPoints = (staticPoints: StaticPoint[]) => {
  const phenomenaIds: UUID[] = [...new Set(staticPoints.flatMap(StaticPointsUtil.getPhenomenaIdsFromStaticPoint))];
  return axios.post(`${protectorApiUrl}/api/v1/phenomena/ids`, { ids: phenomenaIds }).pipe(map(r => r.data));
};

const createStaticPoint = (account: { id: UUID; name: string }, staticPoint: StaticPointCreation): Observable<any> => {
  return axios.post<any>(`${trapUrl}`, {
    account,
    payload: staticPoint
  });
};

const getTrapSeverityByField = (payload: PayloadGetStaticPointsSeverityByField): Observable<TrapsSeverityByField> => {
  const url = `${protectorApiUrl}/v1/analytics/traps-severity`;
  return axios.post<TrapsSeverityByField>(url, payload).pipe(map(response => response.data));
};

export const StaticPointService = {
  getStaticPoints,
  getStaticPointImage,
  getPhenomenaFromStaticPoints,
  getTrapSeverityByField,
  createStaticPoint
};
