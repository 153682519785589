import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { MethodologyDeepActionTypes } from './methodology-deep.actions';
import type { MethodologyDeep, MethodologyDeepState } from './methodology-deep.models';

function selectMethodologyUUID(entity: MethodologyDeep) {
  return entity.id;
}

const entity: EntityAdapter<MethodologyDeep> = createEntityAdapter<MethodologyDeep>({
  selectId: selectMethodologyUUID
});

const initialPage = {
  empty: false,
  first: false,
  last: false,
  number: 0,
  number_of_elements: 0,
  size: 0,
  total_elements: 0,
  total_pages: 0
};

const initialState = entity.getInitialState<MethodologyDeepState>({
  ids: [],
  entities: {},
  page: {
    ...initialPage
  },
  isLoading: false,
  error: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case MethodologyDeepActionTypes.LOAD_METHODOLOGY_DEEP_SUCCESS:
      return entity.addOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });

    case MethodologyDeepActionTypes.LOAD_METHODOLOGY_DEEP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case MethodologyDeepActionTypes.UPDATE_METHODOLOGY_DEEP:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case MethodologyDeepActionTypes.UPDATE_METHODOLOGY_DEEP_SUCCESS:
      return entity.updateOne(
        {
          id: action.payload.id,
          changes: {
            ...action.payload
          }
        },
        {
          ...state,
          isLoading: false,
          error: null
        }
      );

    case MethodologyDeepActionTypes.UPDATE_METHODOLOGY_DEEP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
