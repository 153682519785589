const ControlStrategyCard = () => {
   return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_16547_9602)">
<rect width="32" height="32" rx="8" fill="white"/>
<rect width="32" height="32" rx="4" fill="#EAF6FF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M21.625 9.25H10.375C9.55 9.25 8.875 9.925 8.875 10.75V21.25C8.875 22.075 9.55 22.75 10.375 22.75H21.625C22.45 22.75 23.125 22.075 23.125 21.25V10.75C23.125 9.925 22.45 9.25 21.625 9.25ZM21.625 10.75V13H10.375V10.75H21.625ZM14.125 21.25H17.875V14.5H14.125V21.25ZM10.375 14.5H12.625V21.25H10.375V14.5ZM19.375 14.5V21.25H21.625V14.5H19.375Z" fill="#0071CD"/>
</g>
<defs>
<clipPath id="clip0_16547_9602">
<rect width="32" height="32" rx="8" fill="white"/>
</clipPath>
</defs>
</svg>

   )
}
export default ControlStrategyCard