import { useWindowResize } from 'beautiful-react-hooks';
import { PROTECTOR_APP_ID } from 'config/constants';
import 'core/components/menu/menu.styles.less';
import { getInterfaceLanguage, getLicensingStatus } from 'core/core.selectors';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import type { Crop } from 'core/utils/basic.models';
import { handleInterfaceLanguage } from 'core/utils/functions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { LicensingStatusAppsResponse, LicensingStatusResponse } from 'entities/company/company.models';
import { getSelectedCompany } from 'entities/company/company.reducer';
import type { Property } from 'entities/property/property.models';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { isActive } from 'entities/season/season.functions';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const getPlanInfo = (license: LicensingStatusResponse | null, key: keyof LicensingStatusAppsResponse['plan']) => {
  const planInfo = new Set();

  return (
    license?.apps.reduce<LicensingStatusAppsResponse['plan'][keyof LicensingStatusAppsResponse['plan']][]>((acc, app) => {
      if (planInfo.has(app.plan[key])) {
        return acc;
      }

      if (moment().isBefore(app.contract.end) && app.id === PROTECTOR_APP_ID) {
        planInfo.add(app.plan[key]);
        acc.push(app.plan[key]);

        return acc;
      }
      return acc;
    }, []) ?? null
  );
};

export const getCropInfo = (farm: Property | undefined, key: keyof Crop) => {
  const cropInfo = new Set();

  return (
    farm?.seasons.reduce<Crop[keyof Crop][]>((acc, season) => {
      if (cropInfo.has(season.crop[key])) {
        return acc;
      }

      if (isActive(season)) {
        cropInfo.add(season.crop[key]);
        acc.push(season.crop[key]);

        return acc;
      }

      return acc;
    }, []) ?? null
  );
};

type Values = Record<string, unknown>;

const useIdentifyUser = () => {
  const segmentTracking = useSegmentTracking();

  const user = useSelector(selectCurrentUser);
  const interfaceLanguage = useSelector(getInterfaceLanguage);
  const selectedCompany = useSelector(getSelectedCompany);
  const selectedProperty = useSelector(getSelectedProperty);
  const licensingStatus = useSelector(getLicensingStatus);

  const [resolutionScreen, setResolutionScreen] = useState(`${window.innerWidth}x${window.innerHeight}`);
  useWindowResize(() => {
    setResolutionScreen(`${window.innerWidth}x${window.innerHeight}`);
  });

  useEffect(() => {
    const values: Values = {
      userId: null,
      baseRole: null,
      userLanguage: null,
      interfaceLanguage: handleInterfaceLanguage(interfaceLanguage),
      protectorRole: null,

      companyId: null,
      companyName: null,
      propertyId: null,
      propertyName: null,

      companyPlanId: getPlanInfo(licensingStatus, 'id'),
      companyPlanName: getPlanInfo(licensingStatus, 'name'),
      cropId: getCropInfo(selectedProperty, 'id'),
      cropName: getCropInfo(selectedProperty, 'name'),

      accountId: null,
      accountName: null,

      resolutionScreen
    };

    if (user) {
      values.userId = user.id;
      values.baseRole = user.role;
      values.userLanguage = user.locale;
      values.protectorRole = user.job_title;
    }

    if (selectedCompany) {
      values.companyId = selectedCompany.id;
      values.companyName = selectedCompany.name;
    }

    if (selectedProperty) {
      values.propertyId = selectedProperty.id;
      values.propertyName = selectedProperty.name;
    }

    if (licensingStatus) {
      values.accountId = licensingStatus.account.id;
      values.accountName = licensingStatus.account.name;
    }

    segmentTracking.identify(values.userId as string, values);
  }, [user, selectedCompany, selectedProperty, licensingStatus, interfaceLanguage, segmentTracking, resolutionScreen]);
};

export default useIdentifyUser;
