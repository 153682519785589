const SvgMapExport = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <g clipPath='url(#clip0_11107_102405)'>
      <rect width='32' height='32' rx='8' fill='white' />
      <rect width='32' height='32' rx='4' fill='#FFF1F3' />
      <path
        d='M21.25 18.385V12.25C21.25 10.5925 19.9075 9.25 18.25 9.25C16.5925 9.25 15.25 10.5925 15.25 12.25V19.75C15.25 20.575 14.575 21.25 13.75 21.25C12.925 21.25 12.25 20.575 12.25 19.75V13.615C13.12 13.3 13.75 12.475 13.75 11.5C13.75 10.255 12.745 9.25 11.5 9.25C10.255 9.25 9.25 10.255 9.25 11.5C9.25 12.475 9.88 13.3 10.75 13.615V19.75C10.75 21.4075 12.0925 22.75 13.75 22.75C15.4075 22.75 16.75 21.4075 16.75 19.75V12.25C16.75 11.425 17.425 10.75 18.25 10.75C19.075 10.75 19.75 11.425 19.75 12.25V18.385C18.88 18.6925 18.25 19.5175 18.25 20.5C18.25 21.745 19.255 22.75 20.5 22.75C21.745 22.75 22.75 21.745 22.75 20.5C22.75 19.525 22.12 18.7 21.25 18.385ZM11.5 12.25C11.0875 12.25 10.75 11.9125 10.75 11.5C10.75 11.0875 11.0875 10.75 11.5 10.75C11.9125 10.75 12.25 11.0875 12.25 11.5C12.25 11.9125 11.9125 12.25 11.5 12.25ZM20.5 21.25C20.0875 21.25 19.75 20.9125 19.75 20.5C19.75 20.0875 20.0875 19.75 20.5 19.75C20.9125 19.75 21.25 20.0875 21.25 20.5C21.25 20.9125 20.9125 21.25 20.5 21.25Z'
        fill='#BB485D'
      />
    </g>
    <defs>
      <clipPath id='clip0_11107_102405'>
        <rect width='32' height='32' rx='8' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMapExport;
