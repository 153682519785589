import { notification } from 'antd';
import type { AxiosError } from 'axios';
import i18n from 'config/i18n';
import { selectSelectedProperty } from 'core/core.reducer';
import { getLicensingStatus, getSelectedSeasons, getSystemFlags } from 'core/core.selectors';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import * as MapFunctions from 'core/shared/map/map.functions';
import * as ChoroplethUtils from 'core/utils/map/choropleth';
import { extractActiveSeasonsIds, filterSeasonsActive } from 'entities/season/season.functions';
import { getSelectedInactiveSeasonEndDate } from 'entities/season/season.reducer';
import { getSeasonEntitiesInEntities } from 'entities/season/season.selectors';
import _ from 'lodash';
import { filterCropIdsFromSeasonBySeasonFields } from 'pages/timeline/area-info/hooks/use-area-info-drawer-variety-data.functions';
import type { TimelineWindowEvents } from 'pages/timeline/timeline.models';
import { EventType } from 'pages/timeline/timeline.models';
import { deletePhenology, getWindowEvents } from 'pages/timeline/timeline.service';
import type { SeasonField } from 'querys/season-field/season-field.models';
import { getSeasonFieldFromPropertyBySeasonIdsObservable } from 'querys/season-field/season-field.service';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { combineLatest, concat, forkJoin, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, mergeMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import sentryService from 'sentry/service';
import { GetAreasDiffJDOCFailure } from '../integrations/integrations.actions';
import { getSelectedProperty } from '../property/property.reducer';
import type {
  CalculateCurrentInfo,
  CreateAreaVariablesMassively,
  DeleteAreaVariable,
  DeletePhenology,
  EditAreaVariablesMassively,
  EditSeasonAreaDatesMassively,
  EditSeasonAreaVarietiesMassively,
  GetAreaDayRepresentativeness,
  GetAreaVariable,
  GetRegionData,
  LoadCurrentInfosByFieldIds,
  LoadWindowEvents,
  PostAreaVariable,
  ReloadRegion,
  SetTimelineMapHighlightFilter,
  UpdateAreaVariable,
  UpdateRegionDaysMapsColors,
  UpdateRegionPropertyNematodeDamage,
  UpdateRegionPropertyScoutingScore,
  UpdateRegionSeedAndPhenologyColors
} from './region.actions';

import type { Action } from 'core/utils/basic.models';
import { validatePlansAndEntitlements } from 'core/utils/functions';
import { buildGeometries } from './geometries.utils';
import {
  CalculateCurrentInfoSuccess,
  CreateAreaVariablesMassivelyFailure,
  DeleteAreaVariableFailure,
  DeletePhenologyError,
  DeletePhenologySuccess,
  EditAreaVariablesMassivelyFailure,
  EditSeasonAreaDatesMassivelyFailure,
  EditSeasonAreaVarietiesMassivelyFailure,
  GetAreaDayRepresentativenessSuccess,
  GetAreaVariableFailure,
  GetAreaVariables,
  GetAreaVariablesSuccess,
  GetAreaVariableSuccess,
  GetRegionDataSuccess,
  LoadCurrentInfosByFieldsFailure,
  LoadCurrentInfosByFieldsSuccess,
  LoadRegion,
  LoadRegionDetailedInfo,
  LoadRegionDetailedInfoFailure,
  LoadRegionDetailedInfoSuccess,
  LoadRegionFailure,
  LoadRegionSeasonFields,
  LoadRegionSeasonFieldsFailed,
  LoadRegionSuccess,
  LoadWindowEventsFailure,
  LoadWindowEventsSuccess,
  PostAreaVariableFailure,
  PostAreaVariableSuccess,
  RegionActionsTypes,
  RegionAlreadyLoaded,
  ReloadRegionDetailedInfo,
  SetTimelineMapHighlightFilterSuccess,
  UpdateAreaVariableFailure,
  UpdateAreaVariableSuccess,
  UpdateRegionNematodeDemageSuccess,
  UpdateRegionScoutingScoreFailure,
  UpdateRegionScoutingScoreSuccess,
  UpdateRegionSeedAndPhenologyColorsSucess
} from './region.actions';
import { getCropsByIds, isSetAreaSeasonField, loadRegionSeasonFieldsSuccess } from './region.epics.utils';
import type { AreaVariable, ColorDictionary, Region } from './region.models';
import { SeasonAreaUpdateType } from './region.models';
import { selectRegionDictionaryByCurrentSeason, selectRegionEntities, selectRootRegion } from './region.reducer';
import { getParentRegion, getRegions } from './region.selectors';
import * as Services from './region.service';
import * as Utils from './region.utils';

export const handleLoadRegionSeasonFields = (
  action$: ActionsObservable<ReturnType<typeof LoadRegionSeasonFields>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.LOAD_REGION_SEASON_FIELD),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(getRegions)),
      state$.pipe(map(getParentRegion)),
      state$.pipe(map(getSeasonEntitiesInEntities)),
      state$.pipe(map(getSelectedSeasons))
    ),
    concatMap(([payload, regions, parentRegion, seasons, selectedSeasons]) => {
      if (!parentRegion) {
        return of(LoadRegionSeasonFieldsFailed());
      }

      return getSeasonFieldFromPropertyBySeasonIdsObservable({
        propertyIds: [payload.propertyId],
        seasonIds: selectedSeasons,
        update: payload.update
      }).pipe(
        mergeMap(seasonFields => {
          const regionChildrenMapping = Utils.getRegionChildrenMapping(regions);

          const areaSeasonFieldMap: Record<string, SeasonField> = {};
          seasonFields?.forEach(seasonField => {
            const isSetFieldInRecord = isSetAreaSeasonField(areaSeasonFieldMap, seasonField);
            if (isSetFieldInRecord) {
              areaSeasonFieldMap[seasonField.field_id] = seasonField;
            }
          });

          const cropIds = filterCropIdsFromSeasonBySeasonFields(seasons, seasonFields);

          return forkJoin(getCropsByIds(cropIds, payload.companyId)).pipe(
            mergeMap(
              loadRegionSeasonFieldsSuccess({
                regionChildrenMapping,
                region: parentRegion,
                areaSeasonFieldMap,
                selectedSeasons,
                seasons,
                cropIds
              })
            )
          );
        }),
        catchError(() => of(LoadRegionSeasonFieldsFailed()))
      );
    })
  );

export const handleEditAreaVariablesMassively = (
  action$: ActionsObservable<ReturnType<typeof EditAreaVariablesMassively>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.EDIT_AREA_VARIABLES_MASSIVELY),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(getSelectedSeasons))),
    mergeMap(([action, selectedSeasons]) => {
      const areaVariables = action.seasonAreas.map(el => ({
        ...action.areaVariable,
        season_area_id: el
      }));
      return Services.editAreaVariablesMassively(areaVariables, action.propertyId).pipe(
        tap(() => notification.success({ message: i18n.t('components.area_variable_info.save-success') })),
        map(() => GetAreaVariables(selectedSeasons)),
        catchError(() => {
          notification.error({ message: i18n.t('components.area_variable_info.error-save') });
          return of(EditAreaVariablesMassivelyFailure());
        })
      );
    })
  );

export const handleSaveAreaVariablesMassively = (
  action$: ActionsObservable<ReturnType<typeof CreateAreaVariablesMassively>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.CREATE_AREA_VARIABLES_MASSIVELY),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(getSelectedSeasons))),
    mergeMap(([action, selectedSeasons]) => {
      const areaVariables = action.seasonAreas.map(el => ({
        ...action.areaVariable,
        season_area_id: el
      }));
      return Services.createAreaVariablesMassively(areaVariables, action.propertyId).pipe(
        tap(() => notification.success({ message: i18n.t('components.area_variable_info.save-success') })),
        map(() => GetAreaVariables(selectedSeasons)),
        catchError(() => {
          notification.error({ message: i18n.t('components.area_variable_info.error-save') });
          return of(CreateAreaVariablesMassivelyFailure());
        })
      );
    })
  );

export const handleEditDatesMassively = (
  action$: ActionsObservable<ReturnType<typeof EditSeasonAreaDatesMassively>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.EDIT_SEASON_AREA_DATES_MASSIVELY),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(getSelectedProperty))),
    mergeMap(([payload, property]) => {
      const { seasonAreas, field, value, companyId } = payload;
      const formattedDate = value.format('YYYY-MM-DDTHH:mm:ssZZ');

      const payloadEditField = Utils.getDateEditPayloadFromEditField(field, formattedDate, seasonAreas);

      return Services.updateSeasonAreasMassively(payloadEditField).pipe(
        tap(() => notification.success({ message: i18n.t('components.area_variable_info.save-success') })),
        map(() => {
          return LoadRegionSeasonFields({ companyId, propertyId: property?.id ?? '' });
        }),
        catchError(() => {
          notification.error({ message: i18n.t('components.area_variable_info.error-save') });
          return of(EditSeasonAreaDatesMassivelyFailure());
        })
      );
    })
  );

export const handleEditVarietiesMassively = (
  action$: ActionsObservable<ReturnType<typeof EditSeasonAreaVarietiesMassively>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.EDIT_SEASON_AREA_VARIETIES_MASSIVELY),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(getSelectedProperty))),
    mergeMap(([payload, property]) => {
      const { seasonAreas, varieties, companyId, selectedSeasons } = payload;

      const requestPayload = {
        model: {
          varieties
        },
        seasonIds: seasonAreas,
        fieldToUpdate: SeasonAreaUpdateType.VARIETIES
      };

      return Services.updateSeasonAreasMassively(requestPayload).pipe(
        tap(() => notification.success({ message: i18n.t('components.area_variable_info.save-success') })),
        map(() => {
          if (!property || !companyId || !selectedSeasons?.length) {
            return LoadRegionSeasonFieldsFailed();
          }

          return LoadRegionSeasonFields({ propertyId: property.id, companyId, update: true });
        }),
        catchError(() => {
          notification.error({ message: i18n.t('components.area_variable_info.error-save') });
          return of(EditSeasonAreaVarietiesMassivelyFailure());
        })
      );
    })
  );

export const handleLoadRegion = (action$: ActionsObservable<ReturnType<typeof LoadRegion>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(RegionActionsTypes.LOAD_REGION),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(getSelectedProperty)),
      state$.pipe(map(state => selectRegionEntities(state.entities.region))),
      state$.pipe(map(state => state.entities.property)),
      state$.pipe(map(state => state.uiState.global)),
      state$.pipe(map(getSelectedInactiveSeasonEndDate))
    ),
    exhaustMap(
      ([
        props,
        selectedProperty,
        regionsEntities,
        { currentAreaSeasons, seasonAreasFromActiveSeasons },
        { selectedSeasons },
        inactiveSeasonEndDate
      ]) => {
        const propertyId = props.propertyId ? props.propertyId : selectedProperty?.id ?? '';
        const seasons = selectedProperty?.seasons ?? [];
        const activeSeasonIds = filterSeasonsActive(seasons).map(s => s.id);
        const seasonIds = props?.seasonIds ?? activeSeasonIds;
        const useCurrentSeasonAreas =
          _.isEqual(new Set(activeSeasonIds), new Set(selectedSeasons)) && !!seasonAreasFromActiveSeasons[propertyId]?.length;
        if (!regionsEntities[props.regionId]) {
          return Services.getRegion(
            propertyId,
            seasons.filter(s => seasonIds.includes(s.id)),
            inactiveSeasonEndDate,
            seasonAreasFromActiveSeasons[propertyId],
            useCurrentSeasonAreas
          ).pipe(
            takeUntil(action$.pipe(ofType(RegionActionsTypes.RELOAD_REGION))),
            concatMap(recursiveRegion => {
              const recursiveRegionWithGeometry = buildGeometries(recursiveRegion, currentAreaSeasons, selectedSeasons);
              const normalizdRegions = Utils.normalizeRegion(recursiveRegionWithGeometry);
              return concat([
                LoadRegionSuccess(Object.values(normalizdRegions)),
                LoadRegionDetailedInfo({ regionId: props.regionId, propertyId, seasonIds })
              ]);
            }),
            catchError((error: unknown) => {
              sentryService.captureException(error, {
                fileName: 'region.epic.ts',
                method: 'handleLoadRegion',
                description: 'Failed to load the region'
              });
              return of(LoadRegionFailure(error));
            })
          );
        }
        return of(RegionAlreadyLoaded());
      }
    )
  );

export const handleReloadRegion = (action$: ActionsObservable<ReturnType<typeof ReloadRegion>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(RegionActionsTypes.RELOAD_REGION),
    map(action => {
      return action.payload;
    }),
    withLatestFrom(
      state$.pipe(map(state => selectSelectedProperty(state.uiState.global))),
      state$.pipe(map(state => selectRegionEntities(state.entities.region))),
      state$.pipe(map(state => state.entities.property)),
      state$.pipe(map(state => state.uiState.global)),
      state$.pipe(map(getSelectedInactiveSeasonEndDate))
    ),
    concatMap(
      ([
        reloadRegionData,
        selectedPropertyId,
        regionsEntities,
        { currentAreaSeasons, seasonAreasFromActiveSeasons, entities: properties },
        { selectedSeasons, systemFlags },
        inactiveSeasonEndDate
      ]) => {
        if (!selectedPropertyId) return of();
        if (!reloadRegionData?.seasonIds) of(LoadRegion(reloadRegionData.regionId));
        if (!regionsEntities[reloadRegionData?.regionId]) {
          const { seasonIds } = reloadRegionData;
          const seasons = properties[selectedPropertyId]?.seasons || [];
          const activeSeasonIds = extractActiveSeasonsIds(seasons);
          const useSeasonAreasFromActiveSeasons =
            _.isEqual(new Set(activeSeasonIds), new Set(seasonIds)) && !!seasonAreasFromActiveSeasons[selectedPropertyId]?.length;
          const getGeometriesBySeasonFieldEndDate = !!systemFlags?.P40_23669_geometriesBySeasonFieldEndDate;

          return Services.getRegion(
            selectedPropertyId,
            seasons.filter(s => seasonIds?.includes(s.id)),
            inactiveSeasonEndDate,
            seasonAreasFromActiveSeasons[selectedPropertyId],
            useSeasonAreasFromActiveSeasons,
            getGeometriesBySeasonFieldEndDate
          ).pipe(
            takeUntil(action$.pipe(ofType(RegionActionsTypes.RELOAD_REGION))),
            concatMap(recursiveRegion => {
              const recursiveRegionWithGeometry = buildGeometries(recursiveRegion, currentAreaSeasons, selectedSeasons);
              const normalizdRegions = Utils.normalizeRegion(recursiveRegionWithGeometry);
              return !reloadRegionData.ignoreCurrentInfo
                ? concat([
                    LoadRegionSuccess(Object.values(normalizdRegions)),
                    ReloadRegionDetailedInfo({
                      regionId: reloadRegionData.regionId,
                      propertyId: selectedPropertyId,
                      seasonIds: reloadRegionData.seasonIds ?? []
                    })
                  ])
                : of(LoadRegionSuccess(Object.values(normalizdRegions)));
            }),
            catchError(error => {
              sentryService.captureException(error, {
                fileName: 'region.epic.ts',
                method: 'handleReloadRegion',
                description: 'Failed to reload the region'
              });
              return of(LoadRegionFailure(error));
            })
          );
        }
        return of(RegionAlreadyLoaded());
      }
    )
  );

export const handleCalculateCurrentInfo = (
  action$: ActionsObservable<Action<typeof CalculateCurrentInfo>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.CALCULATE_CURRENT_INFO),
    map(action => action.payload),
    withLatestFrom(
      combineLatest([
        state$.pipe(map((state: AppState) => state.entities.region)),
        state$.pipe(map((state: AppState) => state.entities.property.currentAreaSeasons)),
        state$.pipe(map((state: AppState) => state.uiState.global.selectedSeasons)),
        state$.pipe(map((state: AppState) => state.entities.property.propertyData)),
        state$.pipe(map((state: AppState) => selectRootRegion(state)?.id)),
        state$.pipe(map((state: AppState) => getLicensingStatus(state)))
      ])
    ),
    exhaustMap(
      ([
        ,
        [{ entities: regions, mapLayersColorDictionary }, currentAreaSeasons, selectedSeasons, propertyData, rootRegionId, licensingStatus]
      ]) => {
        const storageMaxDays = ChoroplethUtils.getStoregeDaysLimit();

        const showMapSeedsLayer = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.MAPS_SEEDS]);
        const showMapPhenologicalStageLayer = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.MAP_PHENOLOGICAL_STAGE]);
        const showMapDAE = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.MAP_DAE]);

        const rootRegion = Utils.rootRegionWithChildren(regions, rootRegionId!);

        const choroplethLimits = ChoroplethUtils.getLimitsChoropleth(
          rootRegion.children as Region[],
          propertyData,
          currentAreaSeasons,
          selectedSeasons,
          storageMaxDays
        );

        const optionsMultilayer = {
          showMapSeedsLayer,
          showMapPhenologicalStageLayer,
          showMapDAE
        };

        const recursiveRegionWithGeometry = buildGeometries(
          rootRegion,
          currentAreaSeasons,
          selectedSeasons,
          mapLayersColorDictionary,
          choroplethLimits,
          storageMaxDays,
          optionsMultilayer
        );

        const normalizedRegions = Object.values(Utils.normalizeRegion(recursiveRegionWithGeometry));

        return of(CalculateCurrentInfoSuccess({ regions: normalizedRegions, choroplethLimits }));
      }
    )
  );

export const handleReloadRegionDetailedInfo = (
  action$: ActionsObservable<ReturnType<typeof ReloadRegionDetailedInfo>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.RELOAD_REGION_DETAILED_INFO),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(state => state.entities.property.currentAreaSeasons)),
      state$.pipe(map(state => state.entities.property.propertyData)),
      state$.pipe(map(state => state.entities.region.entities)),
      state$.pipe(map(state => selectRootRegion(state)?.id)),
      state$.pipe(map(state => state.uiState.global))
    ),
    concatMap(
      ([
        reloadRegionData,
        currentAreaSeasons,
        propertyData,
        regions,
        rootRegionId,
        { selectedSeasons: selectedSeasonsIds, systemFlags }
      ]) => {
        return Services.getPropertyCurrentInfo(reloadRegionData.propertyId, reloadRegionData.seasonIds).pipe(
          map(currentInfoRegions => {
            return Utils.recalculateRegions(
              currentInfoRegions,
              regions,
              rootRegionId,
              currentAreaSeasons,
              selectedSeasonsIds,
              propertyData,
              systemFlags
            );
          }),
          catchError((error: string) => of(LoadRegionDetailedInfoFailure(error)))
        );
      }
    )
  );

export const handleLoadWindowEvents = (
  action$: ActionsObservable<ReturnType<typeof LoadWindowEvents>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.LOAD_LATEST_WINDOW_EVENTS),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(getSystemFlags))),
    concatMap(([request, flags]) => {
      return getWindowEvents(request.property_id, request.area_id, request.season_ids, request.start, request.end).pipe(
        map(response => {
          const enableMultiplePhotosNotes = !!flags?.P40_24588_enableMultiplePhotosNotes;

          if (!response.data) {
            return LoadWindowEventsFailure('Error loading events');
          }

          const windowData: TimelineWindowEvents = {
            startDate: request.start,
            endDate: request.end,
            annotation_window: response.data.annotation_window && {
              ...response.data.annotation_window,
              annotations: response.data.annotation_window.annotations.map(annotation =>
                Utils.parsedDataToAnnotations(annotation, enableMultiplePhotosNotes)
              ),
              type: EventType.ANNOTATION
            },
            monitoring_window: response.data.monitoring_window
              ? Utils.sortSamples({
                  ...response.data.monitoring_window,
                  type: EventType.MONITORING
                })
              : undefined,
            phenology_window: response.data.phenology_window && {
              ...response.data.phenology_window,
              type: EventType.PHENOLOGY
            },
            harvest_window: response.data.harvest_window && {
              ...response.data.harvest_window,
              type: EventType.HARVESTING
            },
            irrigation_window: response.data.irrigation_window && {
              ...response.data.irrigation_window,
              type: EventType.IRRIGATION
            },
            spray_window: response.data.spray_window && {
              ...response.data.spray_window,
              type: EventType.APPLICATION
            },
            areaId: request.area_id
          };
          return LoadWindowEventsSuccess(windowData);
        }),
        takeUntil(action$.pipe(ofType(RegionActionsTypes.CANCEL_LOAD_WINDOW_EVENTS))),
        catchError(() => of(LoadWindowEventsFailure('Error loading events')))
      );
    })
  );

export const handleLoadAreaDayRepresentativeness = (action$: ActionsObservable<ReturnType<typeof GetAreaDayRepresentativeness>>) => {
  return action$.pipe(
    ofType(RegionActionsTypes.GET_AREA_DAY_REPRESENTATIVENESS),
    map(action => action.payload),
    concatMap(({ monitoring, day, areaId, representedPointsIds, areaPointsIds }) => {
      const areasIds = new Set(
        monitoring
          .filter(m => representedPointsIds.includes(m.id) || (m.represented_by && areaPointsIds.includes(m.represented_by)))
          .map(m => m.area_id)
      );
      return of(GetAreaDayRepresentativenessSuccess({ id: areaId, day, coverageGroup: areasIds }));
    })
  );
};

export const handleLoadRegionDetailedInfo = (
  action$: ActionsObservable<ReturnType<typeof LoadRegionDetailedInfo>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.LOAD_REGION_DETAILED_INFO),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(state => state.entities.property.currentAreaSeasons)),
      state$.pipe(map(state => state.entities.property.propertyData)),
      state$.pipe(map(state => state.entities.region.entities)),
      state$.pipe(map(state => selectRootRegion(state)?.id)),
      state$.pipe(map(state => state.uiState.global))
    ),
    concatMap(([props, currentAreaSeasons, propertyData, regions, rootRegionId, { selectedSeasons: selectedSeasonsIds, systemFlags }]) => {
      const { propertyId, seasonIds } = props;
      const listSeasons = seasonIds ?? selectedSeasonsIds;

      return Services.getPropertyCurrentInfo(propertyId, listSeasons).pipe(
        map(currentInfoRegions => {
          const newRegions = Object.keys(currentInfoRegions).reduce((acc, currentRegionId) => {
            return {
              ...acc,
              [currentRegionId]: {
                ...acc[currentRegionId],
                current_info: currentInfoRegions[currentRegionId]
              }
            };
          }, regions);
          const rootRegion = Utils.rootRegionWithChildren(newRegions, rootRegionId ?? '');
          const seedsColors = systemFlags?.P40_29700_show_statistic_legend
            ? ChoroplethUtils.getSortedSeedsChoropleth(currentInfoRegions)
            : ChoroplethUtils.getSeedsChoropleth(currentInfoRegions);
          const phenologicalStageColors = systemFlags?.P40_29700_show_statistic_legend
            ? ChoroplethUtils.getSortedPhenologicalStageColor(currentInfoRegions, {})
            : ChoroplethUtils.getPhenologicalStageColor(currentInfoRegions);
          const mapLayersColorDictionary = {
            seedsColors,
            phenologicalStageColors
          } as unknown as ColorDictionary;

          const storageMaxDays = ChoroplethUtils.getStoregeDaysLimit();

          const choroplethLimits = ChoroplethUtils.getLimitsChoropleth(
            rootRegion.children as Region[],
            propertyData,
            currentAreaSeasons,
            selectedSeasonsIds,
            storageMaxDays
          );
          const recursiveRegionWithGeometry = buildGeometries(
            rootRegion,
            currentAreaSeasons,
            selectedSeasonsIds,
            mapLayersColorDictionary,
            choroplethLimits
          );
          const normalizedRegions = Utils.normalizeRegion(recursiveRegionWithGeometry);

          return LoadRegionDetailedInfoSuccess({
            regions: Object.values(normalizedRegions),
            choroplethLimits: ChoroplethUtils.getLimitsChoropleth(
              normalizedRegions,
              propertyData,
              currentAreaSeasons,
              selectedSeasonsIds,
              storageMaxDays
            ),
            mapLayersColorDictionary,
            currentInfoRegions
          });
        }),
        catchError((error: string) => of(LoadRegionDetailedInfoFailure(error)))
      );
    })
  );

export const handleLoadFieldsCurrentInfoBatch = (
  action$: ActionsObservable<ReturnType<typeof LoadCurrentInfosByFieldIds>>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(RegionActionsTypes.LOAD_CURRENT_INFOS_BY_FIELD_IDS),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(state => selectRegionDictionaryByCurrentSeason(state))),
      state$.pipe(map(state => state.entities.property.currentAreaSeasons)),
      state$.pipe(map(state => state.uiState.global.selectedSeasons)),
      state$.pipe(map(state => state.entities.property.propertyData))
    ),
    concatMap(([{ propertyId, fieldIds, seasonIds, selectedRegion }, regions, currentAreaSeasons, selectedSeasons, propertyData]) => {
      return Services.getCurrentInfosByFieldIdsPaginated(propertyId, fieldIds, seasonIds).pipe(
        map(currentInfos => {
          const currentInfoMap = Object.fromEntries(currentInfos.flat().map(c => [c.field_id, c.current_info]));
          const selectedRegionNormalized = regions[selectedRegion];
          const selectedRegionDeep = Utils.toDeepRegion(selectedRegionNormalized, currentInfoMap, regions);
          const childAreas = MapFunctions.getRegionAreasWithParents(selectedRegionDeep);
          const recursiveRegionWithGeometry = buildGeometries(
            selectedRegionDeep,
            currentAreaSeasons,
            selectedSeasons,
            {},
            ChoroplethUtils.getLimitsChoropleth(childAreas, propertyData, currentAreaSeasons, selectedSeasons)
          );
          const normalizedRegions = Utils.normalizeRegion(recursiveRegionWithGeometry);
          return LoadCurrentInfosByFieldsSuccess(Object.values(normalizedRegions));
        }),
        catchError((error: string) => of(LoadCurrentInfosByFieldsFailure(error)))
      );
    })
  );
};

export const handleUpdateRegionsProperties = (
  action$: ActionsObservable<ReturnType<typeof UpdateRegionPropertyScoutingScore>>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(RegionActionsTypes.UPDATE_REGION_SCOUTING_SCORE),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(state => state.entities.region.entities)),
      state$.pipe(map(state => state.uiState.global.selectedRegion))
    ),
    concatMap(([latestScoutingScores, regions, selectedRegionId]) => {
      const rootRegion = Utils.rootRegionWithChildren(regions, selectedRegionId ?? '');
      const newRegions = {
        ...rootRegion,
        geometry: MapFunctions.addScoutingScoreToRegionProperties(rootRegion, latestScoutingScores)
      };
      const normalizedRegions = Utils.normalizeRegion(newRegions);
      return of(UpdateRegionScoutingScoreSuccess({ regions: Object.values(normalizedRegions) }));
    }),
    catchError(() => of(UpdateRegionScoutingScoreFailure('Error updating scouting score')))
  );
};

export const handleUpdateDaysMapColors = (
  action$: ActionsObservable<ReturnType<typeof UpdateRegionDaysMapsColors>>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(RegionActionsTypes.UPDATE_REGION_DAYS_MAP_COLORS),
    withLatestFrom(
      state$.pipe(map(state => state.entities.region.entities)),
      state$.pipe(map(state => state.entities.property)),
      state$.pipe(map(state => selectRootRegion(state)?.id)),
      state$.pipe(map(state => state.entities.region.currentInfoRegions)),
      state$.pipe(map(state => state.uiState.global))
    ),
    concatMap(
      ([
        ,
        regions,
        { currentAreaSeasons, propertyData },
        rootRegionId,
        currentInfoRegions,
        { selectedSeasons: selectedSeasonsIds, systemFlags }
      ]) => {
        if (!currentInfoRegions) return of();

        return of(
          Utils.recalculateRegions(
            currentInfoRegions,
            regions,
            rootRegionId,
            currentAreaSeasons,
            selectedSeasonsIds,
            propertyData,
            systemFlags
          )
        );
      }
    )
  );
};
export const handleUpdateSeedAndPhenologyColors = (
  action$: ActionsObservable<ReturnType<typeof UpdateRegionSeedAndPhenologyColors>>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(RegionActionsTypes.UPDATE_REGION_SEED_AND_PHENOLOGY_COLORS),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(state => state.entities.region.entities)),
      state$.pipe(map(state => state.uiState.global.selectedRegion)),
      state$.pipe(map(state => selectRootRegion(state)?.id)),
      state$.pipe(map(state => state.entities.region.currentInfoRegions))
    ),
    concatMap(([phenologyStagesByCropAndName, regions, selectedRegionId, rootRegionId, currentInfoRegions]) => {
      if (!currentInfoRegions) return of();

      const rootRegion = Utils.rootRegionWithChildren(regions, rootRegionId ?? selectedRegionId ?? '');

      const seedsColors = ChoroplethUtils.getSortedSeedsChoropleth(currentInfoRegions);
      const phenologicalStageColors = ChoroplethUtils.getSortedPhenologicalStageColor(currentInfoRegions, phenologyStagesByCropAndName);
      const mapLayersColorDictionary = {
        seedsColors,
        phenologicalStageColors
      };

      const newRegions = {
        ...rootRegion,
        geometry: MapFunctions.updateSeedAndPhenologyColorsToRegionProperties(rootRegion, mapLayersColorDictionary)
      };
      const normalizedRegions = Utils.normalizeRegion(newRegions);
      return of(
        UpdateRegionSeedAndPhenologyColorsSucess({
          regions: Object.values(normalizedRegions),
          mapLayersColorDictionary
        })
      );
    })
  );
};

export const handleUpdateRegionsNematodeDamage = (
  action$: ActionsObservable<ReturnType<typeof UpdateRegionPropertyNematodeDamage>>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(RegionActionsTypes.UPDATE_REGION_NEMATODE_DAMAGE),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(state => state.entities.region.entities)),
      state$.pipe(map(state => state.uiState.global.selectedRegion))
    ),
    concatMap(([nemadigitalReport, regions, selectedRegionId]) => {
      const rootRegion = Utils.rootRegionWithChildren(regions, selectedRegionId ?? '');
      const newRegions = {
        ...rootRegion,
        geometry: MapFunctions.addNematodeDamageToRegionProperties(rootRegion, nemadigitalReport)
      };
      const normalizedRegions = Utils.normalizeRegion(newRegions);
      return of(UpdateRegionNematodeDemageSuccess({ regions: Object.values(normalizedRegions) }));
    })
  );
};

export const handleSetTimelineFilter = (
  action$: ActionsObservable<ReturnType<typeof SetTimelineMapHighlightFilter>>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(RegionActionsTypes.TIMELINE_MAP_HIGHLIGHT_FILTER),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(state => state.entities.region.entities)),
      state$.pipe(map(state => state.uiState.global.selectedRegion))
    ),
    concatMap(([highlightFilter, regions, selectedRegionId]) => {
      const rootRegion = Utils.rootRegionWithChildren(regions, selectedRegionId ?? '');
      const newRegions = {
        ...rootRegion,
        geometry: MapFunctions.updateRegionsHighlightStatus(rootRegion, highlightFilter)
      };
      const normalizedRegions = Utils.normalizeRegion(newRegions);
      return of(SetTimelineMapHighlightFilterSuccess({ regions: Object.values(normalizedRegions) }));
    })
  );
};

export const handleGetAreaVariable = (action$: ActionsObservable<ReturnType<typeof GetAreaVariable>>) =>
  action$.pipe(
    ofType(RegionActionsTypes.GET_AREA_VARIABLE),
    map(action => action.payload),
    concatMap(payload => {
      return Services.getAreaVariable(payload).pipe(
        map(response => {
          return GetAreaVariableSuccess(response);
        }),
        catchError((error: string) => of(GetAreaVariableFailure(error)))
      );
    })
  );

export const handleGetAreaVariables = (action$: ActionsObservable<ReturnType<typeof GetAreaVariables>>) =>
  action$.pipe(
    ofType(RegionActionsTypes.GET_AREA_VARIABLES),
    map(action => action.payload),
    concatMap(({ seasonIds }: { seasonIds: string[] }) => {
      return forkJoin(seasonIds.map(seasonId => Services.getAreaVariable(seasonId))).pipe(
        map(responses => {
          const areaVariablesPerSeasonArea: Record<string, AreaVariable[]> = {};
          seasonIds.forEach((seasonId, idx) => {
            areaVariablesPerSeasonArea[seasonId] = responses[idx];
          });
          return GetAreaVariablesSuccess(areaVariablesPerSeasonArea);
        })
      );
    })
  );

export const handlePostAreaVariable = (
  action$: ActionsObservable<ReturnType<typeof PostAreaVariable>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.POST_AREA_VARIABLE),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(state => selectSelectedProperty(state.uiState.global)))),
    mergeMap(([payload, selectedProperty]) => {
      return Services.postAreaVariable(selectedProperty, payload).pipe(
        map(response => {
          return PostAreaVariableSuccess(response);
        }),
        catchError((error: AxiosError<{ message?: string }>) => {
          if (error.response?.data?.message?.includes('already present')) {
            notification.error({ message: i18n.t('components.area_variable_info.error-duplicated-variable') });
          } else {
            notification.error({ message: i18n.t('components.area_variable_info.error-save') });
          }
          return of(PostAreaVariableFailure(error as unknown as string));
        })
      );
    })
  );

export const handleUpdateAreaVariable = (
  action$: ActionsObservable<ReturnType<typeof UpdateAreaVariable>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.UPDATE_AREA_VARIABLE),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(state => selectSelectedProperty(state.uiState.global)))),
    mergeMap(([payload, selectedProperty]) => {
      return Services.updateAreaVariable(selectedProperty, payload).pipe(
        map(response => {
          return UpdateAreaVariableSuccess(response);
        }),
        catchError((error: string) => of(UpdateAreaVariableFailure(error)))
      );
    })
  );

export const handleDeleteAreaVariable = (
  action$: ActionsObservable<ReturnType<typeof DeleteAreaVariable>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.DELETE_AREA_VARIABLE),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(getSelectedSeasons))),
    mergeMap(([payload, selectedSeasons]) => {
      return Services.deleteAreaVariable(payload.areaVariables, payload.companyId, payload.propertyId).pipe(
        mergeMap(() => {
          notification.success({ message: i18n.t('components.area_variable_info.delete-success') });
          return of(GetAreaVariables(selectedSeasons));
        }),
        catchError(() => {
          notification.error({ message: i18n.t('components.area_variable_info.error-delete') });
          return of(DeleteAreaVariableFailure(''));
        })
      );
    })
  );

export const handleGetRegionData = (action$: ActionsObservable<ReturnType<typeof GetRegionData>>) =>
  action$.pipe(
    ofType(RegionActionsTypes.GET_REGION_DATA),
    map(action => action.payload),
    mergeMap(payload => {
      return Services.getRegionData(payload).pipe(
        map(result => {
          return GetRegionDataSuccess(result);
        }),
        catchError((error: string) => of(GetAreasDiffJDOCFailure(error)))
      );
    })
  );

export const handleDeletePhenology = (action$: ActionsObservable<ReturnType<typeof DeletePhenology>>) =>
  action$.pipe(
    ofType(RegionActionsTypes.DELETE_PHENOLOGY),
    map(action => action.payload),
    mergeMap(payload => {
      if (!payload) {
        return of(DeletePhenologyError('Payload not provided'));
      }

      const { sample } = payload;

      return deletePhenology(sample.id).pipe(
        mergeMap(() => {
          notification.success({
            message: i18n.t('pages.timeline.phenology.delete_sample.message'),
            description: i18n.t('pages.timeline.phenology.delete_sample.success_description')
          });

          return of(DeletePhenologySuccess(sample));
        }),
        catchError((error: string) => {
          notification.error({
            message: i18n.t('pages.timeline.phenology.delete_sample.message'),
            description: i18n.t('pages.timeline.phenology.delete_sample.error_description')
          });

          return of(DeletePhenologyError(error));
        })
      );
    })
  );
