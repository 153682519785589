import { COLOR_OPTIONS } from 'config/style';

interface CropAlfalfaProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropAlfalfa = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropAlfalfaProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.96784 2L5.98434 3.66663C5.89146 3.74665 5.83892 3.85467 5.83892 3.96669V5.03732C5.83892 5.18215 5.75261 5.31658 5.61001 5.39499L4.60616 5.94471C4.46356 6.02233 4.37724 6.15755 4.37724 6.30158V7.57305C4.37724 7.71388 4.29562 7.84511 4.15959 7.92432L3.21766 8.47164C3.08162 8.55086 3 8.68208 3 8.82291C3 11.2074 4.1032 13.5427 7.23897 13.951C7.32552 13.9623 7.41206 13.9712 7.5 13.9787V8C7.5 7.72386 7.72386 7.5 8 7.5C8.27614 7.5 8.5 7.72386 8.5 8V13.982C11.6633 13.7496 13 11.3003 13 8.82291C13 8.68208 12.9193 8.55086 12.7823 8.47164L11.8404 7.92432C11.7044 7.84511 11.6228 7.71388 11.6228 7.57305V6.30158C11.6228 6.15755 11.5364 6.02233 11.3938 5.94471L10.3909 5.39499C10.2474 5.31658 10.1611 5.18215 10.1611 5.03732V3.96669C10.1611 3.85467 10.1095 3.74665 10.0157 3.66663L7.96784 2Z'
      fill={color}
    />
  </svg>
);

export default CropAlfalfa;
