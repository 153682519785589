import { Select } from 'antd';
import type React from 'react';
import styled from 'styled-components';
import STTypo, { STTypoModels } from '../typo';
import './select-option.styles.less';
import type { SelectOption, SelectOptionsProps } from './select-options.models';

const { Option } = Select;

const StyledOption = styled(Option)`
  display: flex !important;
  align-items: center;
  display: flex;
  opacity: 1;
  width: 100%;
`;

const StyledOptionLabel = styled.div`
  flex: 1;
  text-align: start;
`;

const StyledCustomOptionLabel = styled.div`
  display: inline;
`;

export const renderStyledOption = (option: SelectOption, key: string, maxLength: number | undefined, customLabels): React.ReactNode => {
  return (
    <StyledOption key={key} value={option.id} data-name={option.name} disabled={option.disabled}>
      <StyledOptionLabel>
        <STTypo type={STTypoModels.TypeOptions.P1} maxlength={maxLength}>
          {option.name}
        </STTypo>
        {option.short_description && (
          <STTypo type={STTypoModels.TypeOptions.P1} italic>
            {`(${option.short_description})`}
          </STTypo>
        )}
      </StyledOptionLabel>
      <StyledCustomOptionLabel>{customLabels?.find((_, labelKey) => key === labelKey)}</StyledCustomOptionLabel>
    </StyledOption>
  );
};

const SelectOptions: React.FC<SelectOptionsProps> = ({ options, customLabels, maxlength, ...props }): any => {
  return options?.map((option: SelectOption, key: number) => renderStyledOption(option, key.toString(), maxlength, customLabels));
};

export default SelectOptions;
