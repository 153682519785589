import { Skeleton, Tree } from 'antd';
import type { UUID } from 'core/utils/basic.models';
import { useArray } from 'core/utils/hooks/custom-hooks';
import { LoadRegionSuccess } from 'entities/region/region.actions';
import type { Region } from 'entities/region/region.models';
import { RegionType } from 'entities/region/region.models';
import { selectRegionEntities, selectRootRegion } from 'entities/region/region.reducer';
import _ from 'lodash';
import type React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { Dictionary } from 'config/types';
import type { AppState } from 'redux/app-state';
import type { STRegionTreeItem, STRegionTreeProps } from './region-tree.models';
import './region-tree.style.less';

const { TreeNode } = Tree;

export const formatRegions = (
  rootRegion: Region,
  regionsDictionary: Dictionary<Region>,
  valueProp = 'name'
): {
  key: string;
  value: any;
  title: string;
  isLeaf: boolean;
  children: any[];
}[] => {
  if (!rootRegion) return [];

  const recursiveFormat = (regions: Region[]) => {
    const groupedByParent = _.chain(Object.values(regionsDictionary)).groupBy('parent_id').value();
    return regions.map(region => {
      let formatedChild: any = {
        key: region.id,
        value: region[valueProp],
        title: region.name,
        isLeaf: region.type === RegionType.AREA
      };
      if (region.children && region.children.length) {
        if (typeof region.children[0] === 'string') {
          formatedChild = { ...formatedChild, children: recursiveFormat(groupedByParent[region.id] || []) };
        } else {
          formatedChild = { ...formatedChild, children: recursiveFormat(region.children as Region[]) };
        }
      }
      return formatedChild;
    });
  };

  const formatedRegion = {
    key: rootRegion.id,
    value: rootRegion[valueProp],
    title: rootRegion.name,
    isLeaf: false,
    children: recursiveFormat(Object.values(regionsDictionary).filter(children => children.parent_id === rootRegion.id))
  };
  return [formatedRegion];
};

const getRegionsEntities = (regions: Dictionary<Region>, ids: UUID[]) => {
  return ids.map(key => ({ ...regions[key] } || {}));
};

const STRegionTree: React.FC<STRegionTreeProps> = ({
  onChange,
  regionsSelectable = true,
  allowAreasFromDifferentRegions = true,
  hasInitialTreeData = false,
  initialTreeData,
  ...props
}) => {
  const [treeData, treeDataHandlers] = useArray<STRegionTreeItem>([]);
  const [expandedKeys, expandedKeysHandlers] = useArray<string>(props.selectedRegionsIds ?? []);
  const [checkedKeys, checkedKeysHandlers] = useArray<string>(props.selectedRegionsIds ?? []);
  const [someoneSelected, setSomeoneSelected] = useState<boolean>(false);
  useEffect(() => {
    if (props.selectedRegionsIds) {
      checkedKeysHandlers.set(props.selectedRegionsIds);
      if (props.selectedRegionsIds.length > 0) {
        setSomeoneSelected(true);
      } else {
        setSomeoneSelected(false);
      }
    } else {
      checkedKeysHandlers.set([]);
    }
  }, [props.selectedRegionsIds]);

  useEffect(() => {
    if (props?.rootRegion?.id && !hasInitialTreeData) {
      if (!treeData[0] || treeData[0].key !== props.rootRegion.id) {
        treeDataHandlers.set(formatRegions(props.rootRegion, props.regionsDictionary));
      }
    } else {
      treeDataHandlers.set([]);
    }
  }, [props.rootRegion]);

  /**
   * If we can't select areas from different regions, we follow some rules to disable a tree item
   * 1 - If someone is selected, we disable all items other than its leaf siblings
   * 2 - If someone is open, we disable all items other than its children and itself
   */
  const computeDisabled = (item: STRegionTreeItem, parent_id: string | null = null) => {
    const blockDifferentRegions = () => !allowAreasFromDifferentRegions;
    const isSomeoneSelected = () => someoneSelected === true;
    const someoneOpen = () => expandedKeys.length > 1;
    const open = () => expandedKeys[expandedKeys.length - 1];
    const openIsMyDadOrImOpen = () => someoneOpen() && (open() === parent_id || open() === item.key);
    const isLeaf = () => item.isLeaf;

    const disableSiblingRegions = () => {
      // 2
      if (someoneOpen()) {
        if (openIsMyDadOrImOpen()) {
          return false;
        }
        return true;
      }
      return false;
    };

    if (blockDifferentRegions()) {
      // 1
      if (isSomeoneSelected()) {
        if (isLeaf()) {
          return disableSiblingRegions();
        }
        return true;
      }
      return disableSiblingRegions();
    }
    return false;
  };

  const renderTreeNodes = (data: STRegionTreeItem[], parentId: string | null = null) =>
    data.map(item => {
      const p: any = {
        ...item,
        breadcrumb: parentId ? `${parentId}/${item.key}` : item.key,
        checkable: item.isLeaf || regionsSelectable,
        disableCheckbox: !(item.isLeaf || regionsSelectable),
        selectable: false,
        disabled: computeDisabled(item, parentId),
        dataRef: item,
        parent_id: parentId
      };
      if (item.children) {
        return <TreeNode {...p}>{renderTreeNodes(item.children, item.key)}</TreeNode>;
      }

      // eslint-disable-next-line react/jsx-key
      return <TreeNode {...p} />;
    });

  const onCheck = (checkedNodes: any, _info: any) => {
    checkedKeysHandlers.set(checkedNodes);

    const regions = getRegionsEntities(props.regionsDictionary, checkedNodes);

    const filteredAreas = regions.filter(region => checkedNodes.includes(region.id) && region.type === 'AREA');
    const filteredIDs = filteredAreas.map(area => area.id);

    onChange(filteredIDs);
  };

  const onExpand = (ekeys: any, _info: any) => {
    expandedKeysHandlers.set(ekeys);
  };

  const currentTreeData = hasInitialTreeData ? initialTreeData : treeData;

  return (
    <div data-testid='st-region-tree' className='st-region-tree'>
      {currentTreeData?.length && props.rootRegion ? (
        <Tree expandedKeys={expandedKeys} checkedKeys={checkedKeys} checkable onCheck={onCheck} onExpand={onExpand}>
          {renderTreeNodes(currentTreeData)}
        </Tree>
      ) : (
        <Skeleton avatar={false} paragraph={false} />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    rootRegion: selectRootRegion(state),
    regionsDictionary: selectRegionEntities(state.entities.region)
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ LoadRegionSuccess }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(STRegionTree);
