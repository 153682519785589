import { notification } from 'antd';
import type { Dictionary } from 'config/types';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import { LoadAlerts } from 'entities/alerts/alerts.actions';
import type { AlertArea, AlertBody } from 'entities/alerts/alerts.models';
import type { Region } from 'entities/region/region.models';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import type { HandleSuccessProps, UseHandleDispatchSegmentMessagesProps } from './alerts.types';
import StateAreasEnum from './state-areas.enum';

export const getPropertie = (id, listCompanies) => {
  let propertieSelect;
  listCompanies.forEach(company => {
    if (company.length) {
      company.forEach(season => {
        season.seasonProperties.forEach(seasonPropertie => {
          if (seasonPropertie.property.id === id) propertieSelect = seasonPropertie;
        });
      });
    }
  });
  return propertieSelect;
};

export const getArea = (id: string | null, listProperties: any[]) => {
  let areaSelect;
  listProperties.forEach(property => {
    property.forEach(seasons => {
      seasons.seasonProperties.forEach(season => {
        season.seasonAreas.forEach(seasonArea => {
          if (seasonArea.area.id === id) areaSelect = seasonArea.area;
        });
      });
    });
  });
  return areaSelect;
};

const verifyDiffAlerts = (alertsDiff: AlertBody[], listAlerts: AlertBody[]) => {
  let returnValue = false;
  if (alertsDiff.length) {
    alertsDiff.forEach(alert => {
      alert.areas.forEach(area => {
        if (area.state === StateAreasEnum.FIRED) {
          const lastAlert: AlertBody[] = listAlerts.filter(item => item.id === alert.id);
          let areaLastAlert: AlertArea = {} as AlertArea;
          lastAlert.forEach(itemAlert => {
            itemAlert.areas.forEach(itemArea => {
              if (itemArea.id === area.id) areaLastAlert = itemArea;
            });
          });
          if (area.extra_value && areaLastAlert.extra_value) {
            returnValue = areaLastAlert.id
              ? !(
                  area.state === areaLastAlert.state &&
                  area.trigger_datetime === areaLastAlert.trigger_datetime &&
                  area.extra_value === areaLastAlert.extra_value
                )
              : true;
          } else {
            returnValue = areaLastAlert.id
              ? !(
                  area.state === areaLastAlert.state &&
                  area.trigger_datetime === areaLastAlert.trigger_datetime &&
                  area.value === areaLastAlert.value
                )
              : true;
          }
        }
      });
    });
  }
  return returnValue;
};

export const checkAlertsUnread = (newAlerts: AlertBody[], lastAlerts: AlertBody[] | null, companyId: string): AlertBody[] => {
  const listNewAlerts = newAlerts || [];
  const listAlerts = lastAlerts ?? [];
  if (listNewAlerts.length < listAlerts.length) {
    return newAlerts;
  }
  const returnListAlerts: AlertBody[] = listNewAlerts.map(alert => {
    if (alert.company_id === companyId) {
      const lastAlert = listAlerts.filter(itemAlert => itemAlert.id === alert.id);
      if (lastAlert.length && lastAlert[0].hasNewAlert) {
        return { ...alert, ...{ hasNewAlert: true } };
      }
      if (verifyDiffAlerts([alert], listAlerts)) {
        return { ...alert, ...{ hasNewAlert: true } };
      }
      return { ...alert, ...{ hasNewAlert: false } };
    }
    return alert;
  });
  return returnListAlerts;
};

export const verifyAlert = (newAlerts: AlertBody[], lastAlerts: AlertBody[]): boolean => {
  const listNewAlerts = newAlerts || [];
  const listAlerts = lastAlerts || [];
  if (listNewAlerts.length < listAlerts.length) {
    return false;
  }
  if (listNewAlerts.length === listAlerts.length) {
    const alertsDiff = listNewAlerts.filter(alert => !listAlerts.find(item => item === alert));
    return verifyDiffAlerts(alertsDiff, listAlerts);
  }
  const alertsDiff = listNewAlerts.filter(alert => !listAlerts.find(item => item.id === alert.id));
  return verifyDiffAlerts(alertsDiff, listAlerts);
};

export const handleMessages = (type: 'error' | 'success', message: string): void => {
  return notification[type]({ message });
};

export const useParsedRegionsListToDictionary = (regions?: Region): Dictionary<Region> | undefined => {
  return useMemo(() => {
    if (!regions) return undefined;

    return (regions.children as Region[]).reduce<Dictionary<Region>>((accumulator, region) => {
      const mutableAccumulator = accumulator;

      mutableAccumulator[region.id] = region;

      return mutableAccumulator;
    }, {});
  }, [regions]);
};

export const useHandleDispatchSegmentMessages = (): UseHandleDispatchSegmentMessagesProps => {
  const segmentTracking = useSegmentTracking(true, false);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const handleSuccess = useCallback(
    ({ messageType, propertyId, propertyName, selectType, areasTree }: HandleSuccessProps) => {
      if (propertyId && propertyName) {
        const payload: AnalyticsInfo = {
          selectedPropertyId: [propertyId],
          selectedPropertyName: [propertyName],
          alertType: selectType,
          ...(messageType === 'NEW' || messageType === 'EDIT'
            ? {
                selectedAreasId: areasTree.map(a => a.key),
                selectedAreasName: areasTree.map(a => a.title)
              }
            : {})
        };

        if (messageType === 'NEW') {
          handleMessages('success', t('alert.messages.new'));
          segmentTracking.track('Alerts - Created an Alert', payload);
        }
        if (messageType === 'EDIT') {
          handleMessages('success', t('alert.messages.edit'));
          segmentTracking.track('Alerts - Edited an Alert', payload);
        }
        if (messageType === 'DELETE') {
          handleMessages('success', t('alert.messages.delete'));
          segmentTracking.track('Alerts - Deleted an Alert', payload);
        }
      }

      dispatch(LoadAlerts());
    },
    [dispatch, segmentTracking, t]
  );

  return { handleSuccess };
};

export const getAreasByIds = (children: Region[], areasId: Region['id'][]) => {
  const mutableAreasArray: Region[] = [];

  const recursive = (innerChildren: Region[]) => {
    for (const regionOrArea of innerChildren) {
      if (areasId.length === mutableAreasArray.length) break;

      if (regionOrArea.children) {
        recursive(regionOrArea.children as Region[]);
      } else if (areasId.includes(regionOrArea.id)) {
        mutableAreasArray.push(regionOrArea);
      }
    }
  };

  recursive(children);

  return mutableAreasArray;
};
