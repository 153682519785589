import type { Action } from 'core/utils/basic.models';
import type { AlertBody } from './alerts.models';

export const AlertsActionsTypes = {
  LOAD_ALERTS: '[Alerts] Load Alerts',
  LOAD_ALERTS_SUCCESS: '[Alerts] Load Alerts Success',
  LOAD_ALERTS_FAILURE: '[Alerts] Load Alerts Failure'
};

export const LoadAlerts = (): Action => {
  return {
    type: AlertsActionsTypes.LOAD_ALERTS
  };
};

export const LoadAlertsSuccess = (alerts: AlertBody[]): Action<AlertBody[]> => {
  return {
    type: AlertsActionsTypes.LOAD_ALERTS_SUCCESS,
    payload: alerts
  };
};

export const LoadAlertsFailure = (error: string): Action<string> => {
  return {
    type: AlertsActionsTypes.LOAD_ALERTS_FAILURE,
    payload: error
  };
};
