import { Button, Drawer, Form, Input, InputNumber, Select, Spin, TreeSelect } from 'antd';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import { formatRegions } from 'core/shared/region-tree/region-tree.component';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import { generateUUID } from 'core/utils/functions';
import { getSelectedCompany } from 'entities/company/company.reducer';
import type { Property } from 'entities/property/property.models';
import { getSelectedPropertyByCompany } from 'entities/property/property.reducer';
import type { Region } from 'entities/region/region.models';
import { isEmpty } from 'lodash';
import { useCreateAlert, useDeleteAlert, useEditAlert, useGetRegionsToAlerts } from 'querys/alerts/alerts.query';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './alert-drawer.styles.less';
import { getAreasByIds, handleMessages, useParsedRegionsListToDictionary } from './alert.functions';
import type { AlertDrawerProps, AreasTreeType } from './alerts.types';
import { alertTypes } from './alerts.types';

const { Option } = Select;

const AlertDrawer: React.FC<AlertDrawerProps> = ({ visible, callbackDrawer, contentAlert }) => {
  const [t] = useTranslation();

  const currentCompanyProperties = useSelector(getSelectedPropertyByCompany);
  const user = useSelector(selectCurrentUser);
  const company = useSelector(getSelectedCompany);

  const [checkboxStatus, setCheckboxStatus] = useState<boolean>(false);
  const [daysNumberField, setDaysNumberField] = useState<boolean>(true);
  const [selectType, setSelectType] = useState<string>('');
  const [alertName, setAlertName] = useState<string>('');
  const [alertDays, setAlertDays] = useState<number>();
  const [actualProperty, setActualProperty] = useState<Property>();
  const [areasTree, setAreasTree] = useState<AreasTreeType[]>([]);
  const [areasTreeValue, setAreasTreeValue] = useState<string[]>();

  const closeDrawer = useCallback(() => {
    callbackDrawer({ open: false });
  }, [callbackDrawer]);

  const { data: regions, isLoading } = useGetRegionsToAlerts(actualProperty);
  const dictionaryRegions = useParsedRegionsListToDictionary(regions);
  const { onDeleteAlert, isLoading: isLoadingDelete } = useDeleteAlert(closeDrawer);
  const { onEditAlert, isLoading: isLoadingEdit } = useEditAlert(closeDrawer);
  const { onCreateAlert, isLoading: isLoadingCreate } = useCreateAlert(closeDrawer);

  const onSelectProperty = useCallback(
    value => {
      const property = currentCompanyProperties.find(prop => prop.id === value);

      if (property && actualProperty?.id !== value) {
        setActualProperty(property);
        setAreasTreeValue(undefined);
      }
    },
    [currentCompanyProperties, actualProperty]
  );

  const onSelectAlert = useCallback(
    (value: string) => {
      if (isEmpty(value)) return;
      setSelectType(value);

      const alert = alertTypes.find(type => type.name === value);

      if (alert) {
        setDaysNumberField(!alert.days);
        if (alertDays && alertDays <= 0) {
          setAlertDays(1);
        }
      }
    },
    [alertDays]
  );

  useEffect(() => {
    if (contentAlert) {
      onSelectProperty(contentAlert.property_id);

      setCheckboxStatus(contentAlert.send_email);
      onSelectAlert(contentAlert.type);
      setAlertName(contentAlert.name);
      setSelectType(contentAlert.type);

      if (contentAlert.extra_parameters) {
        setAlertDays(contentAlert.extra_parameters.revisit_time);
      }
    }

    if (regions && dictionaryRegions) {
      setAreasTree(formatRegions(regions, dictionaryRegions, 'id'));
    }
  }, [contentAlert, onSelectProperty, dictionaryRegions, onSelectAlert, regions]);

  const handleSubmit = useCallback(() => {
    if (
      !user ||
      !alertName ||
      !actualProperty ||
      !selectType ||
      !areasTreeValue ||
      !company ||
      (selectType && selectType === 'area_not_monitored_alert' && !daysNumberField && (!alertDays || alertDays <= 0))
    ) {
      handleMessages('error', t('alert.messages.error_fileds'));
      return;
    }

    const areasArray = areasTreeValue.map(areaId => {
      return { area_id: areaId };
    });

    const alert = {
      type: selectType,
      id: contentAlert ? contentAlert.id : generateUUID(),
      company_id: company.id,
      property_id: actualProperty.id,
      owner_id: user.id,
      name: alertName,
      areas: areasArray,
      send_email: user.email ? checkboxStatus : false
    };

    const payload = {
      alert,
      daysNumberField,
      revisit_time: alertDays || 0,
      propertyName: actualProperty.name,
      areasTree
    };

    if (contentAlert) {
      onEditAlert(payload);
    } else {
      onCreateAlert(payload);
    }
  }, [
    actualProperty,
    alertDays,
    alertName,
    areasTree,
    areasTreeValue,
    checkboxStatus,
    company,
    contentAlert,
    daysNumberField,
    onCreateAlert,
    onEditAlert,
    selectType,
    user
  ]);

  const contentAlertAreasByIds = useMemo(() => {
    if (!regions || !contentAlert || areasTreeValue) return [];
    return getAreasByIds(
      regions.children as Region[],
      contentAlert.areas.map(area => area.area_id)
    );
  }, [regions, contentAlert, areasTreeValue]);

  useEffect(() => {
    const hasRegion = !!contentAlertAreasByIds.length;

    if (hasRegion) {
      const contentAreasTree: string[] = contentAlertAreasByIds.map(c => c.id);

      setAreasTreeValue(contentAreasTree);
    }
  }, [contentAlertAreasByIds]);

  const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setAlertName(e.target.value);
  }, []);

  const handleChangeDays = useCallback(value => {
    setAlertDays(value);
  }, []);

  const handleSelectedAreas = useCallback(
    (value: string[]) => {
      setAreasTreeValue(value);
    },
    [setAreasTreeValue]
  );

  const handleDeleteAlert = useCallback(() => {
    if (!contentAlert) return;
    onDeleteAlert({
      alertId: contentAlert.id,
      propertyId: actualProperty?.id ?? 'property deleted',
      propertyName: actualProperty?.name ?? 'property deleted',
      selectType,
      areasTree
    });
  }, [actualProperty, areasTree, contentAlert, onDeleteAlert, selectType]);

  return (
    <Drawer
      className={`aldr ${contentAlert ? 'aldr-edit' : ''}`}
      placement='right'
      visible={visible}
      width={400}
      data-testid='drawer-alerts'
      title={
        <div className='aldr_header'>
          <STTypo type={TypeOptions.H5}>{contentAlert ? t('alert.edit') : t('alert.create_title')}</STTypo>
          {contentAlert && (
            <Button size='large' className='aldr_button aldr_button_cancel aldr_button_delete' onClick={handleDeleteAlert}>
              <STTypo>{t('general.delete')}</STTypo>
            </Button>
          )}
        </div>
      }
      onClose={closeDrawer}
      maskClosable>
      <Form layout='vertical' className='aldr_form'>
        <div className='aldr_form_content'>
          <div className='aldr_form_box'>
            <Form.Item label={t('alert.alert_name')}>
              <Input data-testid='alert-name-item' value={alertName} onChange={handleChangeName} />
            </Form.Item>
            <Form.Item label={t('alert.property_select')}>
              <Select
                data-testid='select-actual-property'
                size='large'
                value={actualProperty ? [actualProperty.name, actualProperty.root_region_id] : ['', '']}
                onChange={onSelectProperty}>
                {currentCompanyProperties.map(property => (
                  <Option key={property.id} value={property.id}>
                    {property.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item data-testid='tree-select-items' label={t('alert.area_select')}>
              <TreeSelect
                treeData={areasTree}
                value={areasTreeValue}
                disabled={isLoading}
                onChange={handleSelectedAreas}
                treeNodeFilterProp='title'
                treeCheckable
                size='large'
              />
            </Form.Item>
            <Form.Item label={t('alert.types.label')}>
              <Select data-testid='select-alert-types' size='large' value={selectType} onChange={onSelectAlert}>
                {alertTypes.map(alert => (
                  <Option data-testid={`${alert.name}-option-alert-type`} key={alert.name} value={alert.name}>
                    {t(`alert.types.${alert.name.toLowerCase()}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('alert.days_label')}>
              <InputNumber
                min={1}
                size='large'
                disabled={daysNumberField}
                type='number'
                placeholder='00'
                value={alertDays}
                onChange={handleChangeDays}
              />
            </Form.Item>
          </div>
          <div className='aldr_form_box'>
            <div className='aldr_btns'>
              <Button size='large' className='aldr_button aldr_button_cancel' onClick={closeDrawer}>
                <STTypo>{t('general.cancel')}</STTypo>
              </Button>
              <Button
                data-testid='handle-submit-button'
                disabled={isLoading || isLoadingDelete || isLoadingCreate || isLoadingEdit}
                size='large'
                onClick={handleSubmit}
                className='aldr_button aldr_button_crate'
                type='primary'>
                {isLoadingDelete || isLoadingCreate || isLoadingEdit ? (
                  <Spin data-testid='waiting-spin-icon' />
                ) : (
                  <STTypo>{contentAlert ? t('general.save') : t('alert.create')}</STTypo>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default AlertDrawer;
