import type { UUID } from 'core/utils/basic.models';
import type { AbandonedAreasModel } from 'pages/dashboard-farm/widget-abandoned-areas/widget-abandoned-areas.models';
import type { WidgetId, WidgetLoadError, WidgetRequest, WidgetSetting, WidgetType, WidgetsSettingsData } from './widgets.models';

export const WidgetsActionsTypes = {
  FAKE_LOAD_ALL: '[Analytics/Widgets Service] Fake Load All',
  LOAD_ALL_REQUEST: '[Analytics/Widgets Service] Load All Request',
  LOAD_ALL_SUCCESS: '[Analytics/Widgets Service] Load All Success',
  LOAD_ALL_FAILURE: '[Analytics/Widgets Service] Load All Failure',

  LOAD_REQUEST: '[Analytics/Widgets Service] Load Request',
  LOAD_SUCCESS: '[Analytics/Widgets Service] Load Success',
  LOAD_FAILURE: '[Analytics/Widgets Service] Load Failure',

  LOAD_SETTINGS_REQUEST: '[Analytics/Widgets Service] Load Settings Request',
  LOAD_SETTINGS_SUCCESS: '[Analytics/Widgets Service] Load Settings Success',
  LOAD_SETTINGS_FAILURE: '[Analytics/Widgets Service] Load Settings Failure',

  POST_SETTINGS_REQUEST: '[Analytics/Widgets Service] Post Settings Request',
  POST_SETTINGS_SUCCESS: '[Analytics/Widgets Service] Post Settings Success',
  POST_SETTINGS_FAILURE: '[Analytics/Widgets Service] Post Settings Failure'
};

export const LoadAllRequest = (widgetType: WidgetType, widgetRequest: WidgetRequest) => {
  return {
    type: WidgetsActionsTypes.LOAD_ALL_REQUEST,
    payload: { widgetType, widgetRequest }
  };
};

export const FakeLoadAll = () => {
  return {
    type: WidgetsActionsTypes.FAKE_LOAD_ALL
  };
};

export const LoadAllSuccess = (widgetType: WidgetType) => {
  return {
    type: WidgetsActionsTypes.LOAD_ALL_SUCCESS,
    payload: { widgetType }
  };
};

export const LoadAllFailure = (widgetType: WidgetType, error: WidgetLoadError) => {
  return {
    type: WidgetsActionsTypes.LOAD_ALL_FAILURE,
    payload: { widgetType, error }
  };
};

export const LoadRequest = (widgetType: WidgetType, widgetId: WidgetId, widgetRequest: WidgetRequest) => {
  return {
    type: WidgetsActionsTypes.LOAD_REQUEST,
    payload: { widgetType, widgetId, widgetRequest }
  };
};

export const LoadSuccess = (widgetType: WidgetType, widgetId: WidgetId, data: AbandonedAreasModel) => {
  return {
    type: WidgetsActionsTypes.LOAD_SUCCESS,
    payload: { widgetType, widgetId, data }
  };
};

export const LoadFailure = (widgetType: WidgetType, widgetId: WidgetId, error: WidgetLoadError) => {
  return {
    type: WidgetsActionsTypes.LOAD_FAILURE,
    payload: { widgetType, widgetId, error }
  };
};

export const LoadSettingsRequest = () => {
  return { type: WidgetsActionsTypes.LOAD_SETTINGS_REQUEST };
};

export const LoadSettingsSuccess = (data: WidgetsSettingsData, currentDashboard: UUID | null) => {
  return {
    type: WidgetsActionsTypes.LOAD_SETTINGS_SUCCESS,
    payload: { data, currentDashboard }
  };
};

export const LoadSettingsFailure = (error: string) => {
  return {
    type: WidgetsActionsTypes.LOAD_SETTINGS_FAILURE,
    payload: error
  };
};

export const PostSettingsRequest = (data: WidgetsSettingsData | Record<string, WidgetSetting>) => {
  return {
    type: WidgetsActionsTypes.POST_SETTINGS_REQUEST,
    payload: data
  };
};

export const PostSettingsSuccess = () => {
  return { type: WidgetsActionsTypes.POST_SETTINGS_SUCCESS };
};

export const PostSettingsFailure = (error: string) => {
  return {
    type: WidgetsActionsTypes.POST_SETTINGS_FAILURE,
    payload: error
  };
};
