const SvgWeatherDailyOvercastWithLightSnow = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M5.62359 4.29787L5.89088 4.28514L6.02855 4.05569C6.84512 2.69474 8.1885 1.89844 9.6998 1.89844C11.8347 1.89844 13.7233 3.75792 13.9721 5.99844H13.1654C13.0555 5.19928 12.6897 4.45356 12.1718 3.87687C11.5477 3.1818 10.67 2.69844 9.6998 2.69844C8.32362 2.69844 7.02829 3.49666 6.5403 4.79942L6.41774 5.04453L6.182 5.00524L6.14119 4.99844H6.0998H5.9998H5.89981C4.42366 4.99844 3.1998 6.22229 3.1998 7.69844C3.1998 9.17458 4.42366 10.3984 5.89981 10.3984H6.6998V11.2984H5.7998C3.87595 11.2984 2.2998 9.7223 2.2998 7.79844C2.2998 5.9628 3.79271 4.38506 5.62359 4.29787Z'
      fill='#C2C7D0'
      stroke='#C2C7D0'
    />
    <path
      d='M17.1998 18.2008H7.5998C5.4998 18.2008 3.7998 16.5008 3.7998 14.4008C3.7998 12.6008 5.0998 11.1008 6.6998 10.7008C7.1998 8.00078 9.4998 5.80078 12.3998 5.80078C14.4998 5.80078 16.2998 6.90078 17.3998 8.70078C19.8998 8.80078 21.9998 10.9008 21.9998 13.4008C21.8998 16.1008 19.7998 18.2008 17.1998 18.2008ZM7.5998 12.4008C6.4998 12.4008 5.5998 13.3008 5.5998 14.4008C5.5998 15.5008 6.4998 16.4008 7.5998 16.4008H17.1998C18.7998 16.4008 20.1998 15.1008 20.1998 13.4008C20.1998 11.8008 18.8998 10.4008 17.1998 10.4008H17.0998C16.9998 10.4008 16.9998 10.4008 16.8998 10.4008L16.2998 10.5008L15.9998 10.0008C15.3998 8.60078 13.9998 7.60078 12.3998 7.60078C10.1998 7.60078 8.4998 9.40078 8.4998 11.5008V12.4008H7.5998Z'
      fill='#868CA2'
    />
    <path
      d='M8.81157 19.1598C8.52468 18.9016 8.08588 18.9131 7.81295 19.1861C7.52955 19.4695 7.52955 19.929 7.81295 20.2124L8.2998 20.6992L7.85302 21.1013C7.55231 21.372 7.54001 21.8394 7.82608 22.1255C8.09165 22.3911 8.51862 22.4023 8.79777 22.1511L9.2998 21.6992L9.6998 22.0992C9.97595 22.3754 10.4237 22.3754 10.6998 22.0992C10.9759 21.8231 10.9759 21.3754 10.6998 21.0992L10.2998 20.6992L10.7392 20.211C10.9974 19.9241 10.9859 19.4853 10.713 19.2124C10.4295 18.929 9.97006 18.929 9.68666 19.2124L9.2998 19.5992L8.81157 19.1598Z'
      fill='#82CFFF'
    />
    <path
      d='M15.1114 19.1598C14.8245 18.9016 14.3857 18.9131 14.1128 19.1861C13.8294 19.4695 13.8294 19.929 14.1128 20.2124L14.5996 20.6992L14.1528 21.1013C13.8521 21.372 13.8398 21.8394 14.1259 22.1255C14.3915 22.3911 14.8184 22.4023 15.0976 22.1511L15.5996 21.6992L15.9996 22.0992C16.2758 22.3754 16.7235 22.3754 16.9996 22.0992C17.2758 21.8231 17.2758 21.3754 16.9996 21.0992L16.5996 20.6992L17.039 20.211C17.2972 19.9241 17.2857 19.4853 17.0128 19.2124C16.7294 18.929 16.2699 18.929 15.9865 19.2124L15.5996 19.5992L15.1114 19.1598Z'
      fill='#82CFFF'
    />
  </svg>
);

export default SvgWeatherDailyOvercastWithLightSnow;
