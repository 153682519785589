import { COLOR_OPTIONS } from 'config/style';

interface CropOrangeProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropOrange = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropOrangeProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11 1.4V1.72725C11 2.98244 9.98244 4 8.72725 4H8.4C8.17909 4 8 3.82091 8 3.6V3.27275C8 2.01756 9.01756 1 10.2727 1H10.6C10.8209 1 11 1.17909 11 1.4Z'
      fill={color}
    />
    <path
      d='M7 4.5V3.44889C7 3.23511 6.7827 3.09025 6.58052 3.15973C6.5229 3.17953 6.46725 3.19734 6.41958 3.21032C3.87264 3.90394 2 6.23328 2 9C2 12.3137 4.68629 15 8 15C11.3137 15 14 12.3137 14 9C14 6.97405 12.9959 5.18263 11.4582 4.09624C11.419 4.06854 11.3687 4.03514 11.3156 4.00079C11.1228 3.87601 10.871 3.90868 10.7009 4.06299C10.6475 4.11148 10.595 4.15726 10.552 4.19101C9.90785 4.69774 9.09526 5 8.21209 5H7.5C7.22386 5 7 4.77614 7 4.5Z'
      fill={color}
    />
  </svg>
);

export default CropOrange;
