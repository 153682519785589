import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { AUTH_SECRET_ID, CLIENT_ID, CORE_SERVICES_API_URL, OAUTH_CROPWISE_URL, OAUTH_UK_URL, PROTECTOR_API_URL } from 'config/constants';
import type { UUID } from 'core/utils/basic.models';
import { isUK } from 'core/utils/functions';
import { fromFetch } from 'rxjs/fetch';
import type { Oauth2TokenResponse, OrganizationInfo, Token } from './auth.models';

const oauthUrl = isUK() ? OAUTH_UK_URL : OAUTH_CROPWISE_URL;
const coreServicesApiUrl = CORE_SERVICES_API_URL;
const protectorApiUrl = PROTECTOR_API_URL;

const organizationInfoUrl = (id: string) => `${protectorApiUrl}/v2/orgs/${id}?attributes=licensing_accounts`;

export const refreshToken = (token: string): AxiosObservable<Token> => {
  return axios.post<Token>(`${coreServicesApiUrl}/oauth/token?grant_type=refresh_token&refresh_token=${token}`, null, {
    headers: {
      Authorization: `Basic ${AUTH_SECRET_ID}`
    }
  });
};

export const getWorkspaceInfo = (id: UUID) => {
  return axios.get(`${protectorApiUrl}/v2/accounts/${id}`);
};

export const getWorkspaceInfoAccess = (id: UUID) => {
  return axios.get(`${protectorApiUrl}/v2/accounts/${id}?attributes=workspaces`);
};

export const getWorkspaceName = (id: UUID) => {
  return axios.get(`${coreServicesApiUrl}/v2/workspaces/${id}`);
};

export const getWorkspaceApps = () => {
  return axios.get(`${coreServicesApiUrl}/v2/store/workspaces/apps`);
};

export const getOrganizationInfo = (id: UUID) => {
  return axios.get<OrganizationInfo>(organizationInfoUrl(id));
};

export const requestAccessToken = (code: string) => {
  const data = new FormData();
  data.set('code', code);
  data.set('client_id', CLIENT_ID!);
  data.set('grant_type', 'authorization_code');
  data.set('redirect_uri', `${window.location.origin}/authenticate`);

  return axios.create({}).post<Oauth2TokenResponse>(`${coreServicesApiUrl}/oauth/token`, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      authorization: `Basic ${AUTH_SECRET_ID}`
    }
  });
};

export const requestLogout = () => {
  const headers = new Headers();
  headers.append('Set-Cookie', 'SameSite=None; Secure');

  return fromFetch(
    new Request(`${oauthUrl}/exit`, {
      headers,
      method: 'GET',
      mode: 'no-cors',
      credentials: 'include'
    })
  );
};
