import type { Action } from 'core/utils/basic.models';
import moment from 'moment';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { concat, of } from 'rxjs';
import { catchError, delay, flatMap, map, mergeMap, retryWhen, take, withLatestFrom } from 'rxjs/operators';
import {
  LoadWeatherFailure,
  LoadWeatherHourlyFailure,
  LoadWeatherHourlySuccess,
  LoadWeatherSuccess,
  WeatherActionsTypes
} from './weather.actions';
import { orderWeatherData } from './weather.functions';
import type { LoadWeatherParams, WeatherData } from './weather.models';
import { getWeather, getWeatherHours } from './weather.service';

export const handleLoadWeather = (action$, state$) =>
  action$.pipe(
    ofType(WeatherActionsTypes.LOAD_WEATHER),
    map((action: Action) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => state.analytics.weather.data))),
    mergeMap(([request, weatherData]: [LoadWeatherParams, WeatherData[]]) => {
      if (weatherData.length) {
        const findData = weatherData.find(
          weather => weather.propertyId === request.propertyId && weather.dataSource === request.dataSource
        );
        if (findData) {
          const differenceTime = moment.duration(moment().diff(findData.dateTimeUpdate)).asMinutes();
          if (differenceTime < 60) return of();
        }
      }
      return getWeather(request).pipe(
        retryWhen(errors => errors.pipe(delay(1000), take(3))),
        map(response => response.data),
        flatMap(weather => {
          if (typeof weather === 'object' && (weather as any).statusCode) {
            return concat([LoadWeatherFailure((weather as any).message)]);
          }
          const resultWeather: WeatherData = {
            content: orderWeatherData(weather),
            propertyName: request.propertyName,
            propertyId: request.propertyId,
            companyId: request.companyId,
            dataSource: request.dataSource,
            dateTimeUpdate: moment().toISOString()
          };
          return concat([LoadWeatherSuccess(resultWeather)]);
        }),
        catchError((error: string) => of(LoadWeatherFailure(error)))
      );
    }, 2)
  );

export const handleLoadWeatherHourly = (action$, state$) =>
  action$.pipe(
    ofType(WeatherActionsTypes.LOAD_WEATHER_HOURLY),
    map((action: Action) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => state.analytics.weather.dataHourly))),
    mergeMap(([request, weatherData]: [LoadWeatherParams, WeatherData[]]) => {
      if (weatherData.length) {
        const findData = weatherData.find(
          weather => weather.propertyId === request.propertyId && weather.dataSource === request.dataSource
        );
        if (findData) {
          const differenceTime = moment.duration(moment().diff(findData.dateTimeUpdate)).asMinutes();
          if (differenceTime < 60) return of();
        }
      }
      return getWeatherHours(request).pipe(
        retryWhen(errors => errors.pipe(delay(1000), take(3))),
        map(response => response.data),
        flatMap(weather => {
          const resultWeather: WeatherData = {
            content: orderWeatherData(weather),
            propertyName: request.propertyName,
            propertyId: request.propertyId,
            companyId: request.companyId,
            dataSource: request.dataSource,
            dateTimeUpdate: moment().toISOString()
          };
          return concat([LoadWeatherHourlySuccess(resultWeather)]);
        }),
        catchError((error: string) => of(LoadWeatherHourlyFailure(error)))
      );
    }, 2)
  );
