import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import type { Action, UUID } from '../../core/utils/basic.models';
import {
  CreateReportFailure,
  CreateReportSuccess,
  LoadReportFailure,
  LoadReportsRequest,
  LoadReportsSuccess,
  ReportsActionTypes
} from './report.actions';
import type { ReportPayload, RequestPropertyReportsPayload, RequestReportBody } from './report.models';
import { getPropertyReports, requestReport } from './report.service';

export const handleLoadReports = action$ =>
  action$.pipe(
    ofType(ReportsActionTypes.LOAD_REPORTS_REQUEST),
    map((action: Action<UUID>) => action.payload),
    concatMap((payload: RequestPropertyReportsPayload) =>
      getPropertyReports(payload.propertyId, payload.cursor).pipe(
        concatMap((reportPayload: ReportPayload) => {
          if (reportPayload.cursor) {
            return of(LoadReportsSuccess(reportPayload.content), LoadReportsRequest(payload.propertyId, reportPayload.cursor));
          }
          return of(LoadReportsSuccess(reportPayload.content));
        }),
        catchError((error: string) => of(LoadReportFailure(error)))
      )
    )
  );

export const handleRequestReport = action$ =>
  action$.pipe(
    ofType(ReportsActionTypes.CREATE_REPORT_REQUEST),
    map((action: Action<RequestReportBody>) => action.payload),
    concatMap((requestBody: RequestReportBody) =>
      requestReport(requestBody).pipe(
        map(response => CreateReportSuccess(response.data)),
        catchError((error: string) => of(CreateReportFailure(error)))
      )
    )
  );
