import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { createSelector } from 'reselect';
import type { AppState } from '../../redux/app-state';
import { AlertsActionsTypes } from './alerts.actions';
import type { AlertBody, Alerts, AlertsState } from './alerts.models';

function selectAlertUuid(alert: Alerts): string {
  return alert.id;
}

const entity: EntityAdapter<Alerts> = createEntityAdapter<Alerts>({
  selectId: selectAlertUuid
});

const initialState = entity.getInitialState<AlertsState>({
  ids: [],
  entities: {},
  content: [],
  isLoading: false,
  error: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case AlertsActionsTypes.LOAD_ALERTS:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case AlertsActionsTypes.LOAD_ALERTS_SUCCESS:
      return entity.addMany(action.payload, {
        ...state,
        content: [...action.payload],
        isLoading: false,
        error: null
      });
    case AlertsActionsTypes.LOAD_ALERTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const getAlertsUser = createSelector(
  (state: AppState) => state.entities.alerts.content,
  (alerts: AlertBody[]) => {
    return alerts;
  }
);
