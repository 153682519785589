import type { DateOnlyString, IndexedName, Indicator, UUID } from 'core/utils/basic.models';
import type { Feature } from 'geojson';
import type { Dictionary } from 'config/types';

export interface ThresholdOptions {
  control: number;
  damage: number;
  indicator_id: UUID;
}

export interface LoadHeatmapsRequest {
  mode: 'RASTER';
  version: '1.0';
  area_id: UUID;
  property_id: UUID;
  season_id: UUID;
  indicators: UUID[];
  options: {
    include_aoi: boolean;
    include_sampling_points: boolean;
    thresholds?: ThresholdOptions[];
    rates?: {
      acceptance: number;
      control: number;
      damage: number;
    };
    filename?: string;
  };
  scouting_windows: ScoutingWindowDate[];
  areas?: UUID[];
  indicator?: Indicator | IndexedName;
  phenomenon?: IndexedName;
  interpolation: {
    type: 'kriging';
    kriging_type: 'ORDINARY';
    variogram_model: 'INTEGRAL_SCALE';
    resolution: 20 | number;
  };
  spatial_aggregation: 'MAX' | 'SUM';
  temporal_aggregation: 'MAX' | 'SUM' | 'LATEST';
  days?: string[];
}

export const defaultRequest: Partial<LoadHeatmapsRequest> = {
  mode: 'RASTER',
  version: '1.0',
  options: {
    include_aoi: false,
    include_sampling_points: false
  },
  spatial_aggregation: 'MAX',
  temporal_aggregation: 'MAX',
  interpolation: {
    type: 'kriging',
    kriging_type: 'ORDINARY',
    variogram_model: 'INTEGRAL_SCALE',
    resolution: 20
  }
};

interface ScoutingWindowDate {
  start: string;
  end: string;
}

export interface LoadHeatmapParams {
  areas: UUID[];
  property_id: UUID;
  indicators: UUID[];
  phenomenon: IndexedName;
  indicator?: IndexedName;
  scouting_windows: ScoutingWindowDate[];
  interpolation?: {
    type: 'kriging';
    kriging_type: 'ORDINARY';
    variogram_model: 'INTEGRAL_SCALE';
    resolution: number;
  };
  geometry_date?: DateOnlyString;
}

export interface LoadHeatmapsSuccessType {
  currentHeatmap?: {
    phenomenon: IndexedName;
    indicator?: IndexedName;
  };
  // area_uuid: heatmap_url
  heatmaps?: Dictionary<string>;
}

export interface HeatmapState extends LoadHeatmapsSuccessType {
  loadingHeatmap: UUID | false;
}

export interface GenerateShapefileRequest {
  filename: string;
  features: Feature[];
}
