import { LOCALE_CODES, localeBR, localeDE, localeEN, localeES, localeFR, localeHU, localeIT, localePL } from './antd-provider.constants';

export const getAntdLocale = (language: string | null) => {
  switch (language) {
    case LOCALE_CODES.pt_br:
      return localeBR;

    case LOCALE_CODES.de_de:
      return localeDE;

    case LOCALE_CODES.fr_fr:
    case LOCALE_CODES.fr:
      return localeFR;

    case LOCALE_CODES.pl_pl:
      return localePL;

    case LOCALE_CODES.es:
    case LOCALE_CODES.es_ar:
    case LOCALE_CODES.es_AR:
      return localeES;

    case LOCALE_CODES.hu_hu:
      return localeHU;

    case LOCALE_CODES.it:
      return localeIT;

    default:
      return localeEN;
  }
};
