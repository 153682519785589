import type { SimplePhenomena } from 'core/utils/basic.models';
import { initialV4Page } from 'core/utils/basic.models';
import { getNameByCurrentLanguage } from 'core/utils/functions';
import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { createSelector } from 'reselect';
import type { AppState } from '../../redux/app-state';
import { PhenomenonActionsTypes } from './phenomenon.actions';
import type { Phenomenon, PhenomenonState } from './phenomenon.models';

function selectPhenomenonUuid(phenomenon: Phenomenon): string {
  return phenomenon.id;
}

// function sortByName(a: Phenomenon, b: Phenomenon): number {
//   return a.name.localeCompare(b.name);
// }

const entity: EntityAdapter<Phenomenon> = createEntityAdapter<Phenomenon>({
  selectId: selectPhenomenonUuid
  // sortComparer: sortByName,
});

const initialState = entity.getInitialState<PhenomenonState>({
  ids: [],
  entities: {},
  page: {
    ...initialV4Page
  },
  isLoading: false,
  error: null,
  isLoadingMethodology: false,
  methodology: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case PhenomenonActionsTypes.LOAD_PHENOMENON_BY_METHODOLOGY:
      return {
        ...state,
        isLoading: false
      };
    case PhenomenonActionsTypes.LOAD_PHENOMENON_BY_METHODOLOGY_SUCCESS:
      return entity.upsertMany(action.payload, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });
    case PhenomenonActionsTypes.LOAD_PHENOMENON:
      return {
        ...state,
        isLoading: true
      };
    case PhenomenonActionsTypes.LOAD_PHENOMENON_SUCCESS:
      return entity.addMany(action.payload.content, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });
    case PhenomenonActionsTypes.LOAD_PHENOMENON_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case PhenomenonActionsTypes.LOAD_METHODOLOGY:
      return {
        ...state,
        isLoadingMethodology: true,
        error: null,
        methodology: null
      };
    case PhenomenonActionsTypes.LOAD_METHODOLOGY_SUCCESS:
      return {
        ...state,
        isLoadingMethodology: false,
        methodology: action.payload
      };
    case PhenomenonActionsTypes.LOAD_METHODOLOGY_FAILURE:
      return {
        ...state,
        isLoadingMethodology: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const { selectAll } = entity.getSelectors();
export const selectAllPhenomenons = selectAll;

export const getSimplePhenomena = createSelector(
  (state: AppState) => selectAllPhenomenons(state.entities.phenomenon),
  (phenomena: Phenomenon[]) => {
    return phenomena.map<SimplePhenomena>((phenomenoun: Phenomenon) => {
      return {
        id: phenomenoun.id,
        name: phenomenoun.name,
        short_description: phenomenoun.short_description,
        category: getNameByCurrentLanguage(phenomenoun.category.name),
        methodologyId: phenomenoun.methodologyIds ? phenomenoun.methodologyIds[0] : '',
        companyId: phenomenoun.company_id
      };
    });
  }
);
