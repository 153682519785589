import STTypo, { STTypoModels } from 'core/shared/typo';
import type { AlertArea, AlertBody } from 'entities/alerts/alerts.models';
import moment from 'moment';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SvgTaskNew from 'st-protector-icons/Minor/TaskNew';
import SvgTimeline from 'st-protector-icons/Minor/Timeline';
import { getArea } from '../alert.functions';
import type { SeasonsAreas } from '../alerts.types';
import StateAreasEnum from '../state-areas.enum';

interface STAlertWorseProps {
  areaData: AlertArea;
  alert: AlertBody;
  listProperties: SeasonsAreas[];
}

const STAlertWorse: React.FC<STAlertWorseProps> = ({ areaData, alert, listProperties }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const printArea = () => {
    const area = getArea(areaData.area_id, listProperties);
    return (
      <div className='st-alert-table__row-area'>
        <STTypo type={STTypoModels.TypeOptions.P1}>{area?.name}</STTypo>
      </div>
    );
  };

  return (
    <div className={`st-alert-table__row ${areaData.state === StateAreasEnum.RESOLVED ? StateAreasEnum.RESOLVED : ''}`}>
      {areaData.trigger_datetime && (
        <div className={`st-alert-table__row-date ${moment(areaData.trigger_datetime).isSame(moment(), 'day') ? 'today' : ''}`}>
          <STTypo type={STTypoModels.TypeOptions.P1}>{t('units.date_simple', { date_simple: areaData.trigger_datetime })}</STTypo>
        </div>
      )}
      {printArea()}
      <div className='st-alert-table__row-specific'>
        <div className={`st-alert-table__row-specific-worst state-${areaData.extra_value ? areaData.extra_value : '0'}`}>
          <span className='alert-state' />
        </div>
      </div>
      <div className='st-alert-table__row-actions'>
        <SvgTaskNew data-testid='alert-task' onClick={() => navigate(`/company/${alert.company_id}/property/${alert.property_id}/tasks`)} />
        <SvgTimeline
          data-testid='alert-timeline'
          onClick={() => navigate(`/company/${alert.company_id}/property/${alert.property_id}/timeline?selected=${areaData.area_id}`)}
        />
      </div>
    </div>
  );
};

export default STAlertWorse;
