import { PROTECTOR_APP_ID } from 'config/constants';
import { isEmpty } from 'lodash';
import type {
  AllRolesByUserParsed,
  CreateOperationByRBACLevels,
  GetAllRolesByUserResponse,
  RBACGetWorkspaceOperationsResponse,
  RBACMakeResourceByLevelProps,
  RBACOperations,
  RBACParsedOperations,
  RBACPermissionTypesEnum,
  RBACWorkspaceOperations
} from './rbac.query.model';
import { RBACLevels } from './rbac.query.model';

export const processOperation = (accumulator: RBACWorkspaceOperations, operation: RBACOperations): RBACWorkspaceOperations => {
  const { action: operationAction, resource: operationResource } = operation;
  const [role, action] = operationAction.split(':') as [string, RBACPermissionTypesEnum];

  if (!accumulator?.[operationResource]) {
    accumulator[operationResource] = {};
  }

  if (!accumulator?.[operationResource]?.[role]) {
    accumulator[operationResource][role] = [];
  }

  if (!accumulator?.[operationResource]?.[role].includes(action)) {
    accumulator[operationResource][role].push(action);
  }

  return accumulator;
};

export const parseWorkspaceOperations = (operations?: RBACGetWorkspaceOperationsResponse): RBACParsedOperations | undefined => {
  if (!operations || isEmpty(operations)) return undefined;

  const { allowed_operations, denied_operations } = operations;

  const allowedOperations = allowed_operations?.reduce(processOperation, {} as RBACWorkspaceOperations);
  const deniedOperations = denied_operations?.reduce(processOperation, {} as RBACWorkspaceOperations);

  return {
    allowed_operations: allowedOperations,
    denied_operations: deniedOperations
  };
};

export const setUpResourceByLevel = ({ level, workspaceId, companyId, propertyId }: RBACMakeResourceByLevelProps): string => {
  switch (level) {
    case RBACLevels.WORKSPACE:
      return `crn:workspace:${workspaceId}`;
    case RBACLevels.ORG:
      return `crn:workspace:${workspaceId}/org/${companyId}`;
    case RBACLevels.PROPERTY:
      return `crn:workspace:${workspaceId}/org/${companyId}/property/${propertyId}`;
    case RBACLevels.SYSTEM:
      return 'crn:system';
    case RBACLevels.APP:
      return `crn:app:${PROTECTOR_APP_ID}`;
    default:
      return `crn:workspace:${workspaceId}`;
  }
};

export function createOperation({
  action,
  level,
  permission,
  workspaceId,
  companyId,
  propertyId,
  appId
}: CreateOperationByRBACLevels): RBACOperations {
  return {
    action: `${action}:${permission}`,
    resource: setUpResourceByLevel({
      level,
      workspaceId,
      companyId,
      propertyId,
      appId
    })
  };
}

export const formatAllRolesByUser = (data: GetAllRolesByUserResponse['content']): AllRolesByUserParsed => {
  return (data || []).reduce((acc, item) => {
    const level = item.level.toLowerCase();
    const role = item.role_id;
    const resource = item.resource_attachment;

    if (!acc[level]) {
      acc[level] = {};
    }

    acc[level][role] = {
      ...(acc[level][role] || {}),
      resource
    };

    return acc;
  }, {} as AllRolesByUserParsed);
};

export const enableVerifyRequest = ({
  workspaceId,
  rbacLevels,
  operations,
  companyId,
  propertyId,
  isEnabled
}: {
  operations: RBACOperations[];
  workspaceId?: string | null;
  companyId?: string | null;
  propertyId?: string | null;
  rbacLevels?: RBACLevels;
  isEnabled: boolean;
}): boolean => {
  switch (rbacLevels) {
    case RBACLevels.WORKSPACE:
      return !!workspaceId && isEnabled && !!operations?.length;
    case RBACLevels.ORG:
      return !!workspaceId && !!companyId && isEnabled && !!operations?.length;
    case RBACLevels.PROPERTY:
      return !!workspaceId && !!companyId && !!propertyId && isEnabled && !!operations?.length;
    case RBACLevels.APP:
      return isEnabled && !!operations?.length;
    default:
      return false;
  }
};
