import moment from 'moment';

/**
 * Return a date that represents the same date received as parameter, but without the time information.
 * @param dt date
 * @returns a date that represents the same date received as parameter, but without the time information.
 */
export const dateTimeToDate = (dt: Date): Date => {
  return new Date(dt.toDateString());
};

/**
 * Return the difference in days between two dates (date2 - date1)
 * @param date1 date1
 * @param date2 date2
 * @returns the difference in days between two dates
 */
export const getDifferenceInDays = (date1: Date, date2: Date): number => {
  const diffInMs = date2.getTime() - date1.getTime();
  return diffInMs / (1000 * 60 * 60 * 24);
};

/**
 * Returns whether a date has the same year as the other
 * @param date1 date1
 * @param date2 date2
 * @returns true or false year comparison
 */
export const isEqualYear = (date1: string, date2: string): boolean => {
  return date1.split('-')[0] === date2.split('-')[0];
};

/**
 * Returns date without time
 * @param date date
 * @returns date format ISO
 */
export const removeTimeOfDate = (date: string): string => {
  return moment(date).format('YYYY-MM-DD');
};

/**
 * Returns year of date
 * @param date date string
 * @param amountDigits number of digits
 * @returns year of date
 */
export const yearOfDate = (date: string, amountDigits?: number): string => {
  if (amountDigits === 2) {
    return moment(date).format('YY');
  }
  return moment(date).format('YYYY');
};

type Inclusivity = '()' | '[]' | '[)' | '(]' | undefined;

/**
 * Returns whether the date is between a range of dates
 * @param targetDate date to be checked for range
 * @param startDate initial date of range
 * @param endDate end date of range
 * @param inclusivity inclusivity of range
 */
export const isDateBetween = (targetDate: string, startDate: string, endDate: string, inclusivity: Inclusivity = '[]'): boolean => {
  const targetMoment = moment(targetDate);
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  return targetMoment.isBetween(startMoment, endMoment, undefined, inclusivity);
};
