import { Button, DatePicker, Drawer, Form, Popconfirm } from 'antd';
import RegionTreeSelectDefault from 'components/SD-region-tree-select/components/region-tree-select-default';
import { COLOR_OPTIONS } from 'config/style';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { generateUUID, getCurrentLanguage, getCurrentUnitSystem } from 'core/utils/functions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import type { Region } from 'entities/region/region.models';
import { selectRootRegion } from 'entities/region/region.reducer';
import moment from 'moment';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import SvgAlertGlyph from 'st-protector-icons/Glyph/AlertGlyph';
import { disabledDate } from '../functions/report-generate.functions';
import '../report.styles.less';
import { ReportsTypes } from '../reports-types.enum';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';
import { generateReport } from './report-spray.functions';

const { RangePicker } = DatePicker;

export interface ISTReportSprayDrawer {
  visible: ReportsTypes | null;
  callbackDrawer: () => void;
  icon?: any;
}

const STReportSprayDrawer: React.FC<ISTReportSprayDrawer> = ({ visible, callbackDrawer, icon }) => {
  const [t] = useTranslation();
  const [timeRangeFrom, setTimeRangeFrom] = useState(moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [selectedAreas, setSelectedAreas] = useState<UUID[]>([]);
  const [timeRangeTo, setTimeRangeTo] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [finish, setFinish] = useState<boolean>(false);

  const property = useSelector(getSelectedProperty);
  const currentSeasons = useSelector((state: AppState) => state.uiState.global.selectedSeasons);
  const company = useSelector((state: AppState) => getSelectedCompany(state));
  const regions = useSelector((state: AppState) => state.entities.region.entities);
  const rootRegion = useSelector<AppState, Region | null>(selectRootRegion);
  const user = useSelector(selectCurrentUser);

  const dispatch = useDispatch();

  const handleTimeRange = value => {
    if (value?.length) {
      setTimeRangeFrom(value[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      setTimeRangeTo(value[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };

  const handleSelectAreas = value => {
    setSelectedAreas(value);
  };

  const closeDrawer = useCallback(() => {
    callbackDrawer();
    setSelectedAreas([]);
  }, [callbackDrawer]);

  useEffect(() => {
    if (finish) {
      setFinish(false);
      closeDrawer();
    }
  }, [finish, closeDrawer]);

  const segmentTracking = useSegmentTracking();

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible === ReportsTypes.SPRAY}
      width={400}
      title={<HeaderTitle icon={icon} type={ReportsTypes.SPRAY} />}
      onClose={closeDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='report-spray-drawer'>
        <ExplicativeText type={ReportsTypes.SPRAY} />
        <div className='st-report-drawer__form' data-testid='report-spray-form'>
          <Form layout='vertical'>
            <Form.Item data-testid='report-spray-type-timerange' label={t('modals.timeline_xray_report.fields.time_range')}>
              <RangePicker
                value={[timeRangeFrom, timeRangeTo]}
                format='L'
                size='large'
                separator='—'
                onChange={handleTimeRange}
                allowClear={false}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item data-testid='report-spray-type-region-select' label={t('modals.timeline_xray_report.fields.areas')}>
              {property && (
                <RegionTreeSelectDefault
                  regions={regions}
                  rootRegionId={rootRegion?.id}
                  selectedSeasonsIds={currentSeasons}
                  value={selectedAreas}
                  onChange={handleSelectAreas}
                />
              )}
            </Form.Item>
          </Form>
        </div>
        <div className='st-report-drawer__actions'>
          <Popconfirm
            placement='bottomRight'
            icon={
              <div className='st-report-drawer__confirm-icon-container'>
                <SvgAlertGlyph fill='#F74141' />
              </div>
            }
            overlayClassName='st-report-drawer__confirm'
            title={
              <div className='st-report-drawer__confirm-delete'>
                <STTypo type={TypeOptions.P1}>{`${t('report.back')}`}</STTypo>
              </div>
            }
            onConfirm={() => closeDrawer()}
            okText={t('general.complete')}
            okButtonProps={{ style: { backgroundColor: '#F74141', border: 'none', borderRadius: '4px', paddingTop: '2px' } }}
            cancelButtonProps={{
              style: {
                backgroundColor: '#F2F4F6',
                border: 'none',
                borderRadius: '4px',
                paddingTop: '2px',
                color: COLOR_OPTIONS.PRIMARY
              }
            }}
            cancelText={t('general.back')}>
            <Button type='ghost' size='default' className='st-report-drawer_actions-cancel' data-testid='report-spray-cancel'>
              {t('general.cancel')}
            </Button>
          </Popconfirm>
          <Button
            type='primary'
            size='default'
            className='st-report-drawer_actions-submit'
            onClick={() => {
              if (!user) return;
              generateReport(
                generateUUID(),
                property,
                company,
                timeRangeTo,
                timeRangeFrom,
                currentSeasons,
                selectedAreas,
                getCurrentLanguage,
                getCurrentUnitSystem,
                user.id,
                regions,
                closeDrawer,
                dispatch,
                segmentTracking
              );
            }}
            disabled={!selectedAreas.length}
            data-testid='report-spray-submit'>
            {t('report.action')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default STReportSprayDrawer;
