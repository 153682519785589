import { Tooltip } from 'antd';
import STTypo from 'core/shared/typo';
import type { STTypoProps } from 'core/shared/typo/typo.models';
import type React from 'react';
import type { ReactNode } from 'react';
import SvgInfoOutlineGlyph from 'st-protector-icons/Glyph/InfoOutlineGlyph';
import { Container, Header } from './custom-card.styles';

export interface CustomCardProps {
  title?: string;
  boldTitle?: boolean;
  titleCustomStyle?: STTypoProps<any>;
  subtitle?: ReactNode;
  tooltip?: string;
  tooltipColor?: ColorOptions;
  hasBoxShadow?: boolean;
  isError?: boolean;
  className?: string;
  children?: ReactNode;
}

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  boldTitle,
  titleCustomStyle,
  subtitle,
  tooltip,
  tooltipColor,
  hasBoxShadow = false,
  children,
  isError = false,
  className
}) => {
  return (
    <Container className={className} data-testid='custom-card' hasBoxShadow={hasBoxShadow} isError={isError}>
      {title && (
        <Header>
          <STTypo fontWeight={boldTitle ? 500 : undefined} {...titleCustomStyle}>
            {title}
          </STTypo>
          {tooltip?.length && (
            <Tooltip title={tooltip}>
              <SvgInfoOutlineGlyph data-testid='description-icon' fill={tooltipColor} />
            </Tooltip>
          )}
          {subtitle}
        </Header>
      )}
      {children}
    </Container>
  );
};

export default CustomCard;
