import type { AxiosResponse } from 'axios';
import axiosRest from 'axios';
import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { CHUNK_PAGE, PROTECTOR_API_URL } from 'config/constants';
import type { UUID } from 'core/utils/basic.models';
import type { StaticPoint } from 'entities/static-points/static-points.models';
import { FixedPointsFieldEnum } from 'pages/fixed-points/fixed-points.models';
import type { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { concatMap, expand, map, reduce, toArray } from 'rxjs/operators';
import type {
  AlertsDebugReport,
  ControlStrategiesSimpleResponse,
  ControlStrategiesSuggestionsResponse,
  ControlStrategyEventDay,
  ControlStrategyEventsResponse,
  ControlStrategyRegionCard,
  ControlStrategySuggestion,
  ControlStrategySuggestionCard,
  SelectedSuggestionsPerStrategy,
  SimpleControlStrategy
} from './control-strategy-model';
import { controlStrategyFrameColors, groupSuggestionsPerZone } from './control-strategy.utils';
import type { ControlStrategyPayload, SidebarControlStrategy } from './create-control-strategy/create-control-strategy.models';

const protectorApiUrl = PROTECTOR_API_URL;
const controlStrategyUrl = `${protectorApiUrl}/properties`;
const staticPointsUrl = `${protectorApiUrl}/v1/timeline/static-points`;

export const saveControlStrategy = (body: ControlStrategyPayload): AxiosObservable<ControlStrategyPayload> => {
  const url = `${protectorApiUrl}/control-strategies`;
  return axios.post(url, body);
};
export const editControlStrategy = (body: ControlStrategyPayload, strategyId: UUID): AxiosObservable<ControlStrategyPayload> => {
  const url = `${protectorApiUrl}/control-strategies/${strategyId}`;
  return axios.put(url, body);
};

export interface AckResponse {
  data: {
    suggestions: ControlStrategySuggestion[];
  };
}

export interface UndoAckResponse {
  updated_suggestions: ControlStrategySuggestion[];
  deleted_suggestions: ControlStrategySuggestion[];
}

interface ExtendedAxiosResponse<T> extends AxiosResponse {
  data: T;
  headers: {
    'content-type': string;
  };
}

export const getDebugControlStrategy = (
  id: UUID,
  refDate: string,
  groupId: UUID,
  propertyId: UUID
): Promise<ExtendedAxiosResponse<AlertsDebugReport>> => {
  const url = `${protectorApiUrl}/properties/${propertyId}/control-strategies/${id}/alert-events?ref_date=${refDate}&group_id=${groupId}`;
  return axiosRest.get<AlertsDebugReport>(url, { responseType: 'blob' });
};

export const getSingleControlStrategy = (propertyId: UUID, id: UUID, enabled?: boolean): Observable<ControlStrategyPayload> => {
  const url = `${controlStrategyUrl}/${propertyId}/control-strategies/${id}?enabled=${typeof enabled === 'boolean' ? enabled : true}`;
  return axios.get<ControlStrategyPayload>(url).pipe(map(response => response.data));
};

export const getControlStrategiesByPropertyId = (propertyId: UUID): Observable<SidebarControlStrategy[]> => {
  return getControlStrategiesByPropertyIdPage(propertyId).pipe(
    expand(({ cursor }) => (cursor ? getControlStrategiesByPropertyIdPage(propertyId, cursor) : EMPTY)),
    concatMap(({ content }) =>
      [...content]
        .sort((strategyA, strategyB) => strategyA.name.localeCompare(strategyB.name))
        .map(mapControlStrategiesToSideBarControlStrategy)
    ),
    toArray()
  );
};

const getControlStrategiesByPropertyIdPage = (propertyId: UUID, cursor?: string): Observable<ControlStrategiesSimpleResponse> => {
  const url = `${controlStrategyUrl}/${propertyId}/control-strategies${cursor ? `?cursor=${cursor}` : ''}`;
  return axios.get<ControlStrategiesSimpleResponse>(url).pipe(map(response => response.data));
};

const mapControlStrategiesToSideBarControlStrategy = (controlStrategy: SimpleControlStrategy, index: number) => {
  const colorIndex = index % controlStrategyFrameColors.length;
  return {
    ...controlStrategy,
    ...controlStrategyFrameColors[colorIndex]
  };
};

const getControlStrategiyEventsByPage = (
  property_idParam: UUID,
  start_date = '',
  end_date = '',
  is_last = true,
  cursor = ''
): Observable<ControlStrategyEventsResponse> => {
  let params = `size=${CHUNK_PAGE}${!is_last && cursor ? `&cursor=${cursor}` : ''}`;
  params = `${params}${start_date ? `&start_day=${start_date}` : ''}${end_date ? `&end_day=${end_date}` : ''}`;
  return axios
    .get<ControlStrategyEventsResponse>(`${controlStrategyUrl}/${property_idParam}/control-strategies/events?${params}`, {
      headers: { 'Content-Type': 'application/json' }
    })
    .pipe(map(response => response.data));
};

export const getAllControlStrategyEvents = (
  property_idParam: string,
  start_date = '',
  end_date = ''
): Observable<ControlStrategyEventDay[]> => {
  return getControlStrategiyEventsByPage(property_idParam, start_date, end_date).pipe(
    expand(({ is_last, cursor }) =>
      !is_last ? getControlStrategiyEventsByPage(property_idParam, start_date, end_date, is_last, cursor) : EMPTY
    ),
    concatMap(({ content }) => content),
    toArray()
  );
};

const getControlStrategiesSuggestionsByPage = (
  property_idParam: UUID,
  start_date = '',
  end_date = '',
  is_last = true,
  cursor = ''
): Observable<ControlStrategiesSuggestionsResponse> => {
  let params = `size=${CHUNK_PAGE}${!is_last && cursor ? `&cursor=${cursor}` : ''}`;
  params = `${params}${start_date ? `&start_day=${start_date}` : ''}${end_date ? `&end_day=${end_date}` : ''}`;
  return axios
    .get<ControlStrategiesSuggestionsResponse>(`${controlStrategyUrl}/${property_idParam}/control-strategies/suggestions?${params}`)
    .pipe(map(response => response.data));
};

export const getAllControlStrategiesSuggestions = (
  propertyId: UUID,
  start_date = '',
  end_date = ''
): Observable<Record<string, Record<string, ControlStrategySuggestionCard>>> => {
  return getControlStrategiesSuggestionsByPage(propertyId, start_date, end_date).pipe(
    expand(({ is_last, cursor }) =>
      !is_last ? getControlStrategiesSuggestionsByPage(propertyId, start_date, end_date, is_last, cursor) : EMPTY
    ),
    concatMap(({ content }) => content),
    reduce(groupSuggestionsPerZone, {})
  );
};

export const ackEvents = (
  propertyId: string,
  eventDate: string,
  regionId: string,
  suggestionsPerControlStrategy: SelectedSuggestionsPerStrategy
): Promise<AckResponse> => {
  const body = {
    day: eventDate,
    region_id: regionId,
    suggestions: Object.entries(suggestionsPerControlStrategy).map(([strategyId, suggestion]) => ({
      selected_suggestions: suggestion.suggestionsIds,
      force_select_in_fields: suggestion.forcedTargetsIds,
      control_strategy_id: strategyId
    }))
  };
  return axiosRest.patch(`${controlStrategyUrl}/${propertyId}/control-strategies/events/ack/batch`, body);
};

export const undoAckEvents = (
  propertyId: string,
  eventDate: string,
  regionId: string,
  selectedSuggestions: string[]
): Observable<UndoAckResponse> => {
  const body = {
    window_start: eventDate,
    window_end: eventDate,
    region_id: regionId,
    selected_suggestions: selectedSuggestions
  };
  return axios
    .patch<UndoAckResponse>(`${controlStrategyUrl}/${propertyId}/control-strategies/events/ack/undo`, body)
    .pipe(map(response => response.data));
};

export const ackAllEvents = (propertyId: string | undefined, regions: ControlStrategyRegionCard[]): Promise<AckResponse> => {
  const body = regions.map(region => ({
    day: region.event_date,
    region_id: region.region_id
  }));
  return axiosRest.put(`${controlStrategyUrl}/${propertyId}/control-strategies/events/ack/batch`, body);
};

export const undoAckAllEvents = (
  propertyId: string | undefined,
  regions: ControlStrategyRegionCard[]
): Promise<{ data: UndoAckResponse }> => {
  const body = regions.map(region => ({
    day: region.event_date,
    region_id: region.region_id
  }));
  return axiosRest.put(`${controlStrategyUrl}/${propertyId}/control-strategies/events/ack/undo`, body);
};

export const deleteControlStrategy = (
  propertyId: string | undefined,
  controlStrategyId: string,
  controlStrategyEnabled: boolean
): Observable<number> => {
  return axios
    .delete(`${protectorApiUrl}/properties/${propertyId}/control-strategies/${controlStrategyId}?enabled=${controlStrategyEnabled}`)
    .pipe(map(response => response.status));
};

export const toggleControlStrategy = (
  propertyId: string | undefined,
  controlStrategyId: string,
  new_status: boolean
): Observable<number> => {
  return axios
    .patch(`${protectorApiUrl}/properties/${propertyId}/control-strategies/${controlStrategyId}?new_status=${new_status}`)
    .pipe(map(response => response.status));
};

export const getStaticPointTemplates = (property_idParam: string): AxiosObservable<StaticPoint[]> => {
  const params = `property_id=${property_idParam}&fields=${[FixedPointsFieldEnum.ALL, FixedPointsFieldEnum.CATALOGUE].join(',')}`;

  return axios.get(`${staticPointsUrl}?${params}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    data: []
  });
};
