import type { ReportsTypes } from 'core/components/header/components/report/reports-types.enum';
import type { Action, UUID } from 'core/utils/basic.models';
import type { Disease } from 'pages/disease-risk/disease-risk.models';
import type { EntityState } from 'redux-ngrx-entity';

export type RequestReportSucess = (report: ReportInfo) => Action<ReportInfo>;
export type LoadReportsSuccessType = (reports: ReportInfo[]) => Action<ReportInfo[]>;

export enum ReportStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  RE_RUN = 'RE_RUN'
}

interface ReportDataRequest {
  area_ids?: UUID[];
  template_ids?: UUID[];
  warehouse_ids?: UUID[];
  property_id?: UUID;
  company_id?: UUID;
  category_ids?: UUID[];
  phenomenon_ids?: UUID[];
  season_ids?: UUID[];
  property_ids?: UUID[];
  language: string;
  end_date?: string;
  start_date?: string;
  user_id?: string;
  season_layout?: boolean;
  comments?: string;
  diseases?: Disease[];
  only_selected_suggestions?: boolean;
  season_id?: string;
}

interface ReportDataRequestApplicationStatistics {
  property_name: string;
  property_id: UUID;
  end_date?: string;
  start_date?: string;
  type_static_point: string;
  current_seasons?: UUID[];
  areas_id?: UUID[];
  locale: string;
  current_unit_system: string;
  user_id?: string;
}

export interface ReportInfo {
  id: string;
  type: ReportsTypes;
  property_id: string;
  status: ReportStatus;
  data_request: ReportDataRequest;
  downloaded: boolean;
  created_by: string;
  created_at: string;
  modified_at: string;
  deleted: boolean;
}

export interface RequestReportBody {
  id?: string;
  type: ReportsTypes;
  property_id: string;
  data_request: ReportDataRequest;
}

export interface RequestReportBodyApplicationStatistics {
  id?: string;
  type: ReportsTypes;
  property_id: string;
  data_request: ReportDataRequestApplicationStatistics;
}

export interface ReportState extends EntityState<ReportInfo> {
  isLoading: boolean;
  reportRequested: boolean;
  error: string | null;
}

export interface RequestPropertyReportsPayload {
  propertyId: string;
  cursor?: string;
}

export interface ReportPayload {
  content: ReportInfo[];
  cursor?: string;
}

export interface RequestReportVisitBody {
  id?: string;
  type: ReportsTypes;
  property_id: string;
  data_request: ReportDataVisitRequest;
}

interface ReportDataVisitRequest {
  area_ids: UUID[];
  property_id: UUID;
  company_id: UUID;
  phenomenon_ids: UUID[];
  season_ids: UUID[];
  language: string;
  end_date: string;
  start_date: string;
  user_id: string;
  comments: string;
  company_logo?: string | null;
}
