import { getPermissionFlags } from 'core/core.selectors';
import type { PermissionFlags } from 'core/services/auth/auth.models';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';

interface RouteByPermissionFlagProps {
  permissionFlag: keyof PermissionFlags;
  /** If reverseCondition is true, the route will be rendered if the permission flag is false */
  reverseCondition?: boolean;
}

const RouteByPermissionFlag = ({ permissionFlag, reverseCondition }: RouteByPermissionFlagProps) => {
  const permissionFlags = useSelector(getPermissionFlags);

  if (!permissionFlags) {
    return null;
  }

  if (reverseCondition ? permissionFlags[permissionFlag].value : !permissionFlags[permissionFlag].value) {
    return <Navigate to='/hall-of-companies' />;
  }
  return <Outlet />;
};

export default RouteByPermissionFlag;
