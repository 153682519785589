import type { Dictionary } from 'config/types';
import type { UUID } from 'core/utils/basic.models';
import type { DevelopmentPhases } from 'entities/crop/crop.models';
import type { Monitoring } from 'entities/monitoring/monitoring.models';
import type { Moment } from 'moment';
import type { ScoutingScorePerField } from 'querys/scouting-score/scouting-score.model';
import type { NemadigitalCropCycleReport } from 'syngenta-digital-react-cropwise-nemadigital/dist/module/map-overlay/map-overlay.types';
import type { PhenologySample, TimelineWindowEvents } from '../../pages/timeline/timeline.models';
import type {
  AreaDayRepresentativeness,
  AreaVariable,
  CalculateCurrentInfoSuccessActionType,
  IPayloadRegionData,
  IRegionDataResponse,
  LoadRegionDetailedInfoSuccessActionType,
  LoadRegionSeasonFieldsPayload,
  LoadRegionSeasonFieldsPayloadSuccess,
  LoadWindowEventsRequest,
  Region,
  RegionDetailedInfoRequestType,
  ReloadRegionData,
  ReloadRegionDetailedInfoRequestType,
  SeasonAreaUpdateType
} from './region.models';

export const RegionActionsTypes = {
  LOAD_REGION: '[Region] Load Region',
  LOAD_REGION_SUCCESS: '[Region] Load Region Success',
  LOAD_REGION_FAILURE: '[Region] Load Region Failure',
  RELOAD_REGION: '[Region] Reload Region',

  UPDATE_REGION: '[Region] Update Region',

  REGION_ALREADY_LOADED: '[Region] Already Loaded',

  LOAD_REGION_DETAILED_INFO: '[Region] Load Region Detailed Info',
  LOAD_REGION_DETAILED_INFO_SUCCESS: '[Region] Load Region Detailed Info Success',
  LOAD_REGION_DETAILED_INFO_FAILURE: '[Region] Load Region Detailed Info Failure',
  RELOAD_REGION_DETAILED_INFO: '[Region] Reload Region Detailed Info',

  LOAD_REGION_SEASON_FIELD: '[Region] Load Region Season Field',
  LOAD_REGION_SEASON_FIELD_SUCCESS: '[Region] Load Region Season Field Success',
  LOAD_REGION_SEASON_FIELD_FAILED: '[Region] Load Region Season Field Failed',

  EDIT_AREA_VARIABLES_MASSIVELY: '[Region] Edit Area Variables Massively',
  CREATE_AREA_VARIABLES_MASSIVELY: '[Region] Create Area Variables Massively',
  EDIT_SEASON_AREA_DATES_MASSIVELY: '[Region] Edit Season Area Dates Massively',
  EDIT_SEASON_AREA_VARIETIES_MASSIVELY: '[Region] Edit Season Area Varieties Massively',

  EDIT_AREA_VARIABLES_MASSIVELY_SUCCESS: '[Region] Edit Area Variables Massively Success',
  CREATE_AREA_VARIABLES_MASSIVELY_SUCCESS: '[Region] Create Area Variables Massively Success',
  EDIT_SEASON_AREA_DATES_MASSIVELY_SUCCESS: '[Region] Edit Season Area Dates Massively Success',
  EDIT_SEASON_AREA_VARIETIES_MASSIVELY_SUCCESS: '[Region] Edit Season Area Varieties Massively Success',

  EDIT_AREA_VARIABLES_MASSIVELY_FAILURE: '[Region] Edit Area Variables Massively Failure',
  CREATE_AREA_VARIABLES_MASSIVELY_FAILURE: '[Region] Create Area Variables Massively Failure',
  EDIT_SEASON_AREA_DATES_MASSIVELY_FAILURE: '[Region] Edit Season Area Dates Massively Failure',
  EDIT_SEASON_AREA_VARIETIES_MASSIVELY_FAILURE: '[Region] Edit Season Area Varieties Massively Failure',

  LOAD_LATEST_WINDOW_EVENTS: '[Region] Load Latest Window Events',
  LOAD_LATEST_WINDOW_EVENTS_SUCCESS: '[Region] Load Latest Window Events Success',
  LOAD_LATEST_WINDOW_EVENTS_FAILURE: '[Region] Load Latest Window Events Failure',

  CANCEL_LOAD_WINDOW_EVENTS: '[Region] Cancel Load Window Events',
  CANCEL_LOAD_WINDOW_EVENTS_SUCCESS: '[Region] Cancel Load Window Events Success',
  CANCEL_LOAD_WINDOW_EVENTS_FAILURE: '[Region] Cancel Load Window Events Failure',

  LOAD_REGION_METHODOLOGIES_SUCCESS: '[Region] Load Region Methodologies Success',

  REMOVE_SPRAY_EVENT: '[Region - Timeline] RemoveSprayEvent',
  REMOVE_TASKS_EVENT: '[Region - Timeline] RemoveTasksEvent',

  POST_AREA_VARIABLE: '[Region] Post Area Variable',
  POST_AREA_VARIABLE_SUCCESS: '[Region] Post Area Variable Success',
  POST_AREA_VARIABLE_FAILURE: '[Region] Post Area Variable Failure',

  GET_AREA_VARIABLE: '[Region] Get Area Variable',
  GET_AREA_VARIABLE_SUCCESS: '[Region] Get Area Variable Success',
  GET_AREA_VARIABLE_FAILURE: '[Region] Get Area Variable Failure',

  GET_AREA_VARIABLES: '[Region] Get Area Variables',
  GET_AREA_VARIABLES_SUCCESS: '[Region] Get Area Variables Success',
  GET_AREA_VARIABLES_FAILURE: '[Region] Get Area Variables Failure',

  UPDATE_AREA_VARIABLE: '[Region] Update Area Variable',
  UPDATE_AREA_VARIABLE_SUCCESS: '[Region] Update Area Variable Success',
  UPDATE_AREA_VARIABLE_FAILURE: '[Region] Update Area Variable Failure',

  DELETE_AREA_VARIABLE: '[Region] Delete Area Variable',
  DELETE_AREA_VARIABLE_FAILURE: '[Region] Delete Area Variable Failure',

  LOAD_CURRENT_INFOS_BY_FIELD_IDS: '[Region] Load Severities to fields batch',
  LOAD_CURRENT_INFOS_BY_FIELDS_SUCCESS: '[Region] Load Severities to fields success',
  LOAD_CURRENT_INFOS_BY_FIELDS_FAILURE: '[Region] Load Severities to fields failure',

  GET_REGION_DATA: '[Region] Get Region Data',
  GET_REGION_DATA_SUCCESS: '[Region] Get Region Data Success',
  CLEAR_REGION_DATA: '[Region] Clear Region Data',

  GET_AREA_DAY_REPRESENTATIVENESS: '[Region] Get Area Day Representativeness',
  GET_AREA_DAY_REPRESENTATIVENESS_SUCCESS: '[Region] Get Area Day Representativeness Success',

  UPDATE_REGION_SCOUTING_SCORE: '[Region] Update region scouting score',
  UPDATE_REGION_SCOUTING_SCORE_SUCCESS: '[Region] Update region scouting score success',
  UPDATE_REGION_SCOUTING_SCORE_FAILURE: '[Region] Update region scouting score Failure',

  UPDATE_REGION_NEMATODE_DAMAGE: '[Region] Update region nematode damage',
  UPDATE_REGION_NEMATODE_DAMAGE_SUCCESS: '[Region] Update region nematode damage success',

  UPDATE_REGION_SEED_AND_PHENOLOGY_COLORS: '[Region] Update phenology colors',
  UPDATE_REGION_SEED_AND_PHENOLOGY_COLORS_SUCCESS: '[Region] Update phenology colors success',

  UPDATE_REGION_DAYS_MAP_COLORS: '[Region] Update days map colors',

  TIMELINE_MAP_HIGHLIGHT_FILTER: '[Region] Set highlight filter for timeline map',
  TIMELINE_MAP_HIGHLIGHT_FILTER_SUCCESS: '[Region] Set highlight filter for timeline map success',

  DELETE_PHENOLOGY: '[Region] Delete phenology',
  DELETE_PHENOLOGY_SUCCESS: '[Region] Delete phenology success',
  DELETE_PHENOLOGY_FAILURE: '[Region] Delete phenology error',

  CALCULATE_CURRENT_INFO: '[Region] Calculate current info',
  CALCULATE_CURRENT_INFO_SUCCESS: '[Region] Calculate current info success',
  CALCULATE_CURRENT_INFO_FAILURE: '[Region] Calculate current info failure'
} as const;

export const LoadRegionSeasonFields = (payload: LoadRegionSeasonFieldsPayload) => ({
  type: RegionActionsTypes.LOAD_REGION_SEASON_FIELD,
  payload
});

export const LoadRegionSeasonFieldsSuccess = (payload: LoadRegionSeasonFieldsPayloadSuccess) => ({
  type: RegionActionsTypes.LOAD_REGION_SEASON_FIELD_SUCCESS,
  payload
});

export const LoadRegionSeasonFieldsFailed = () => ({
  type: RegionActionsTypes.LOAD_REGION_SEASON_FIELD_FAILED,
  payload: { areaInfo: null, seasonFieldsCropsVarieties: null }
});

export const EditAreaVariablesMassively = (seasonId: UUID, seasonAreas: UUID[], areaVariable: AreaVariable, propertyId: UUID) => ({
  type: RegionActionsTypes.EDIT_AREA_VARIABLES_MASSIVELY,
  payload: { seasonId, seasonAreas, areaVariable, propertyId }
});

export const EditAreaVariablesMassivelyFailure = () => ({
  type: RegionActionsTypes.EDIT_AREA_VARIABLES_MASSIVELY_FAILURE
});

export const CreateAreaVariablesMassively = (seasonId: UUID, seasonAreas: UUID[], areaVariable: AreaVariable, propertyId: UUID) => ({
  type: RegionActionsTypes.CREATE_AREA_VARIABLES_MASSIVELY,
  payload: { seasonId, seasonAreas, areaVariable, propertyId }
});

export const CreateAreaVariablesMassivelyFailure = () => ({
  type: RegionActionsTypes.CREATE_AREA_VARIABLES_MASSIVELY_FAILURE
});

export const EditSeasonAreaDatesMassively = (seasonAreas: UUID[], field: SeasonAreaUpdateType, value: Moment, companyId: UUID) => ({
  type: RegionActionsTypes.EDIT_SEASON_AREA_DATES_MASSIVELY,
  payload: { seasonAreas, field, value, companyId }
});

export const EditSeasonAreaDatesMassivelyFailure = () => ({
  type: RegionActionsTypes.EDIT_SEASON_AREA_DATES_MASSIVELY_FAILURE
});

export const EditSeasonAreaVarietiesMassively = (seasonAreas: UUID[], varieties: UUID[], companyId: UUID, selectedSeasons: string[]) => ({
  type: RegionActionsTypes.EDIT_SEASON_AREA_VARIETIES_MASSIVELY,
  payload: { seasonAreas, varieties, companyId, selectedSeasons }
});

export const EditSeasonAreaVarietiesMassivelyFailure = () => ({
  type: RegionActionsTypes.EDIT_SEASON_AREA_VARIETIES_MASSIVELY_FAILURE
});

export const ReloadRegion = (data: ReloadRegionData) => {
  return {
    type: RegionActionsTypes.RELOAD_REGION,
    payload: data
  };
};

export const UpdateRegion = (updatedRegion: Region) => {
  return {
    type: RegionActionsTypes.UPDATE_REGION,
    payload: updatedRegion
  };
};

export const LoadRegion = (regionId: UUID, propertyId?: UUID, seasonIds?: UUID[]) => {
  return {
    type: RegionActionsTypes.LOAD_REGION,
    payload: { regionId, propertyId, seasonIds }
  };
};

export const LoadRegionSuccess = (regions: Region[]) => {
  return {
    type: RegionActionsTypes.LOAD_REGION_SUCCESS,
    payload: regions
  };
};

export const LoadRegionFailure = (error: unknown) => {
  return {
    type: RegionActionsTypes.LOAD_REGION_FAILURE,
    payload: error
  };
};

export const ReloadRegionDetailedInfo = ({ regionId, propertyId, seasonIds, date }: ReloadRegionDetailedInfoRequestType) => {
  return {
    type: RegionActionsTypes.RELOAD_REGION_DETAILED_INFO,
    payload: { regionId, propertyId, seasonIds, date }
  };
};

export const LoadRegionDetailedInfo = ({ regionId, propertyId, seasonIds }: RegionDetailedInfoRequestType) => {
  return {
    type: RegionActionsTypes.LOAD_REGION_DETAILED_INFO,
    payload: { regionId, propertyId, seasonIds }
  };
};

export const LoadRegionDetailedInfoSuccess = ({
  regions,
  choroplethLimits,
  mapLayersColorDictionary,
  currentInfoRegions
}: LoadRegionDetailedInfoSuccessActionType) => {
  return {
    type: RegionActionsTypes.LOAD_REGION_DETAILED_INFO_SUCCESS,
    payload: { regions, choroplethLimits, mapLayersColorDictionary, currentInfoRegions }
  };
};

export const LoadRegionDetailedInfoFailure = (error: string) => {
  return {
    type: RegionActionsTypes.LOAD_REGION_DETAILED_INFO_FAILURE,
    payload: error
  };
};

export const RegionAlreadyLoaded = () => {
  return {
    type: RegionActionsTypes.REGION_ALREADY_LOADED
  };
};

export const LoadWindowEvents = (requestInfo: LoadWindowEventsRequest) => {
  return {
    type: RegionActionsTypes.LOAD_LATEST_WINDOW_EVENTS,
    payload: requestInfo
  };
};

export const RemoveSprayEvent = (sprayId?: UUID, taskId?: string) => {
  return {
    type: RegionActionsTypes.REMOVE_SPRAY_EVENT,
    payload: { sprayId, taskId }
  };
};

export const RemoveTasksEvent = (taskId: string) => {
  return {
    type: RegionActionsTypes.REMOVE_TASKS_EVENT,
    payload: { taskId }
  };
};

export const CancelLoadWindowEvents = () => {
  return {
    type: RegionActionsTypes.CANCEL_LOAD_WINDOW_EVENTS
  };
};

export const LoadWindowEventsSuccess = (windowData: TimelineWindowEvents) => {
  return {
    type: RegionActionsTypes.LOAD_LATEST_WINDOW_EVENTS_SUCCESS,
    payload: windowData
  };
};

export const LoadWindowEventsFailure = (error: string) => {
  return {
    type: RegionActionsTypes.LOAD_LATEST_WINDOW_EVENTS_FAILURE,
    payload: error
  };
};

export const UpdateRegionScoutingScoreSuccess = ({ regions }: LoadRegionDetailedInfoSuccessActionType) => {
  return {
    type: RegionActionsTypes.UPDATE_REGION_SCOUTING_SCORE_SUCCESS,
    payload: { regions }
  };
};

export const UpdateRegionScoutingScoreFailure = (error: string) => {
  return {
    type: RegionActionsTypes.UPDATE_REGION_SCOUTING_SCORE_FAILURE,
    payload: error
  };
};

export const UpdateRegionNematodeDemageSuccess = ({ regions }: LoadRegionDetailedInfoSuccessActionType) => {
  return {
    type: RegionActionsTypes.UPDATE_REGION_NEMATODE_DAMAGE_SUCCESS,
    payload: { regions }
  };
};

export const UpdateRegionSeedAndPhenologyColorsSucess = ({
  regions,
  mapLayersColorDictionary
}: LoadRegionDetailedInfoSuccessActionType) => {
  return {
    type: RegionActionsTypes.UPDATE_REGION_SEED_AND_PHENOLOGY_COLORS_SUCCESS,
    payload: { regions, mapLayersColorDictionary }
  };
};

export const PostAreaVariable = (request: AreaVariable) => {
  return {
    type: RegionActionsTypes.POST_AREA_VARIABLE,
    payload: request
  };
};

export const PostAreaVariableSuccess = (result: unknown) => {
  return {
    type: RegionActionsTypes.POST_AREA_VARIABLE_SUCCESS,
    payload: result
  };
};

export const PostAreaVariableFailure = (error: string) => {
  return {
    type: RegionActionsTypes.POST_AREA_VARIABLE_FAILURE,
    payload: error
  };
};

export const GetAreaVariable = (seasonId: UUID) => {
  return {
    type: RegionActionsTypes.GET_AREA_VARIABLE,
    payload: seasonId
  };
};

export const GetAreaVariableSuccess = (variables: AreaVariable[]) => {
  return {
    type: RegionActionsTypes.GET_AREA_VARIABLE_SUCCESS,
    payload: variables
  };
};

export const GetAreaVariables = (seasonIds: UUID[]) => {
  return {
    type: RegionActionsTypes.GET_AREA_VARIABLES,
    payload: { seasonIds }
  };
};

export const GetAreaVariablesSuccess = (areaVariablesPerSeason: Record<string, AreaVariable[]>) => {
  return {
    type: RegionActionsTypes.GET_AREA_VARIABLES_SUCCESS,
    payload: areaVariablesPerSeason
  };
};

export const GetAreaVariableFailure = (error: string) => {
  return {
    type: RegionActionsTypes.GET_AREA_VARIABLE_FAILURE,
    payload: error
  };
};

export const UpdateAreaVariable = (request: AreaVariable) => {
  return {
    type: RegionActionsTypes.UPDATE_AREA_VARIABLE,
    payload: request
  };
};

export const UpdateAreaVariableSuccess = (result: unknown) => {
  return {
    type: RegionActionsTypes.UPDATE_AREA_VARIABLE_SUCCESS,
    payload: result
  };
};

export const UpdateAreaVariableFailure = (error: string) => {
  return {
    type: RegionActionsTypes.UPDATE_AREA_VARIABLE_FAILURE,
    payload: error
  };
};

export const DeleteAreaVariable = (areaVariables: AreaVariable[], companyId: string | undefined, propertyId?: string) => {
  return {
    type: RegionActionsTypes.DELETE_AREA_VARIABLE,
    payload: { areaVariables, companyId, propertyId }
  };
};

export const DeleteAreaVariableFailure = (error: string) => {
  return {
    type: RegionActionsTypes.DELETE_AREA_VARIABLE_FAILURE,
    payload: error
  };
};

export const LoadCurrentInfosByFieldsSuccess = (regions: Region[]) => {
  return {
    type: RegionActionsTypes.LOAD_CURRENT_INFOS_BY_FIELDS_SUCCESS,
    payload: regions
  };
};

export const LoadCurrentInfosByFieldsFailure = (error: string) => {
  return {
    type: RegionActionsTypes.LOAD_CURRENT_INFOS_BY_FIELDS_FAILURE,
    payload: error
  };
};

export const LoadCurrentInfosByFieldIds = (propertyId: UUID, fieldIds: UUID[], seasonIds: UUID[], selectedRegion: UUID) => {
  return {
    type: RegionActionsTypes.LOAD_CURRENT_INFOS_BY_FIELD_IDS,
    payload: { propertyId, fieldIds, seasonIds, selectedRegion }
  };
};

export const UpdateRegionPropertyScoutingScore = (latestScoutingScores: ScoutingScorePerField) => {
  return {
    type: RegionActionsTypes.UPDATE_REGION_SCOUTING_SCORE,
    payload: latestScoutingScores
  };
};

export const UpdateRegionPropertyNematodeDamage = (nematodeDamage: NemadigitalCropCycleReport) => {
  return {
    type: RegionActionsTypes.UPDATE_REGION_NEMATODE_DAMAGE,
    payload: nematodeDamage
  };
};

export const UpdateRegionSeedAndPhenologyColors = (phenologyStagesByCropAndName: Record<string, Record<string, DevelopmentPhases>>) => {
  return {
    type: RegionActionsTypes.UPDATE_REGION_SEED_AND_PHENOLOGY_COLORS,
    payload: phenologyStagesByCropAndName
  };
};

export const UpdateRegionDaysMapsColors = () => {
  return {
    type: RegionActionsTypes.UPDATE_REGION_DAYS_MAP_COLORS
  };
};

export const GetRegionData = (payload: IPayloadRegionData) => {
  return {
    type: RegionActionsTypes.GET_REGION_DATA,
    payload
  };
};

export const GetRegionDataSuccess = (result: IRegionDataResponse[]) => {
  return {
    type: RegionActionsTypes.GET_REGION_DATA_SUCCESS,
    payload: result
  };
};

export const ClearRegionData = () => {
  return {
    type: RegionActionsTypes.CLEAR_REGION_DATA
  };
};

export const GetAreaDayRepresentativeness = (
  monitoring: Monitoring[],
  day: string,
  areaId: UUID,
  representedPointsIds: UUID[],
  areaPointsIds: UUID[]
) => {
  return {
    type: RegionActionsTypes.GET_AREA_DAY_REPRESENTATIVENESS,
    payload: {
      monitoring,
      day,
      areaId,
      representedPointsIds,
      areaPointsIds
    }
  };
};

export const GetAreaDayRepresentativenessSuccess = (areaDayRepresentativeness: AreaDayRepresentativeness) => {
  return {
    type: RegionActionsTypes.GET_AREA_DAY_REPRESENTATIVENESS_SUCCESS,
    payload: areaDayRepresentativeness
  };
};

export const SetTimelineMapHighlightFilter = (field: string, value: string | undefined) => {
  return {
    type: RegionActionsTypes.TIMELINE_MAP_HIGHLIGHT_FILTER,
    payload: {
      field,
      value
    }
  };
};

export const SetTimelineMapHighlightFilterSuccess = ({ regions }: LoadRegionDetailedInfoSuccessActionType) => {
  return {
    type: RegionActionsTypes.TIMELINE_MAP_HIGHLIGHT_FILTER_SUCCESS,
    payload: { regions }
  };
};

export const LoadMethodologiesSuccess = (seasonAreas: Dictionary<{ seasonId?: UUID; methodologyId: UUID }>) => {
  return {
    type: RegionActionsTypes.LOAD_REGION_METHODOLOGIES_SUCCESS,
    payload: seasonAreas
  };
};

export const DeletePhenology = (sample: PhenologySample) => ({
  type: RegionActionsTypes.DELETE_PHENOLOGY,
  payload: { sample }
});

export const DeletePhenologySuccess = (sample: PhenologySample) => ({
  type: RegionActionsTypes.DELETE_PHENOLOGY_SUCCESS,
  payload: { sample }
});

export const DeletePhenologyError = (error: string) => ({
  type: RegionActionsTypes.DELETE_PHENOLOGY_FAILURE,
  payload: error
});

export const CalculateCurrentInfo = () => ({
  type: RegionActionsTypes.CALCULATE_CURRENT_INFO
});

export const CalculateCurrentInfoSuccess = ({ regions, choroplethLimits }: CalculateCurrentInfoSuccessActionType) => ({
  type: RegionActionsTypes.CALCULATE_CURRENT_INFO_SUCCESS,
  payload: { regions, choroplethLimits }
});
