import axios from 'axios';
import { CORE_SERVICES_API_URL } from 'config/constants';
import type { V4Page } from 'core/utils/basic.models';
import type { SeasonCompany } from './season.query.models';

const coreServicesApiUrl = CORE_SERVICES_API_URL;

export const getAllSeasonsByCompany = async (companyId: string) => {
  const response = await axios.get<V4Page<SeasonCompany>>(`${coreServicesApiUrl}/v2/orgs/${companyId}/seasons`);

  return response.data;
};
