import { COLOR_OPTIONS } from 'config/style';

interface CropAsparagusProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropAsparagus = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropAsparagusProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.1784 15.2324V13.0847H11.4777V15.2324H10.1784ZM7.43569 15.2324V13.0847H8.73499V15.2324H7.43569ZM4.69502 15.2324V13.0847H5.99432V15.2324H4.69502ZM4.69502 12.1787V10.9639H11.4757V12.1787H4.69502ZM10.1784 10.0579V7.77231C10.1784 5.96238 11.2677 4.32747 12.8388 3.76328C13.1394 4.60339 13.0159 5.70499 12.499 6.73865C12.3055 7.12576 12.1161 7.37697 11.9513 7.59729C11.6878 7.94733 11.4798 8.22325 11.4798 8.80391V10.0599H10.1784V10.0579ZM7.43569 10.0579V7.68789V7.68583C7.41716 7.27401 7.31627 6.92603 7.20919 6.55745C7.06711 6.06945 6.92092 5.56498 6.92092 4.80724C6.92092 4.10921 7.04446 3.40089 7.27508 2.75433C7.48305 2.17573 7.77133 1.68155 8.09461 1.35004C8.40965 1.68361 8.69381 2.17779 8.89766 2.75433C9.12828 3.40294 9.24977 4.11333 9.24977 4.80724C9.24977 5.56498 9.10357 6.06945 8.96149 6.55745C8.85442 6.92603 8.75352 7.27401 8.73499 7.68583V7.68995V10.0579H7.43569ZM4.69502 10.0579V8.80185C4.69502 8.22325 4.48705 7.94527 4.22348 7.59523C4.0567 7.37491 3.86932 7.12576 3.67576 6.73659C3.15892 5.70499 3.03538 4.60133 3.33601 3.76122C4.90711 4.32541 5.99638 5.96032 5.99638 7.77025V10.0558H4.69502V10.0579ZM5.82753 4.12362C5.3354 3.58208 4.84739 3.22175 4.2379 2.94789C4.21113 2.31163 4.33673 1.61155 4.58795 1.00206C5.27569 1.23473 5.82753 1.69391 6.22906 2.36723C6.02315 2.89023 5.88724 3.47913 5.82753 4.12362ZM10.3432 4.12362C10.2834 3.48119 10.1475 2.89023 9.94163 2.36517C10.3432 1.69185 10.895 1.23268 11.5827 1C11.834 1.60949 11.9596 2.30957 11.9328 2.94583C11.3233 3.22175 10.8353 3.58208 10.3432 4.12362Z'
      fill={color}
    />
  </svg>
);

export default CropAsparagus;
