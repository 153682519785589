import styled from 'styled-components';

const UnstyledButton = styled.button<{ hasBorder?: boolean }>`
  appearance: none;
  background: none;
  ${({ hasBorder = false }) => (hasBorder ? '' : 'border: none;')}
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-decoration: none;
  user-select: none;
`;

export default UnstyledButton;
