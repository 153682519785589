import { Tooltip } from 'antd';
import { getCurrentArea } from 'core/core.reducer';
import { getCurrentLanguage } from 'core/utils/functions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import './style.less';

const StyledZendeskButton = styled.button`
  right: 15px;
  bottom: 7px;
  position: absolute;
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 75);
  z-index: 99;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
`;

export default props => {
  const [t] = useTranslation();
  const { widgetKey } = props;
  const selectedArea = useSelector(getCurrentArea);

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=${widgetKey}`);
    script.setAttribute('id', 'ze-snippet');
    script.setAttribute('async', 'true');
    document.body.appendChild(script);
  }, []);

  const clickButton = () => {
    let lang = getCurrentLanguage().toLowerCase();
    if (lang === 'en') lang = 'en-US';
    if (lang === 'es') lang = 'es-ES';

    if ((window as any).zE) {
      (window as any).zE('webWidget', 'toggle');
      (window as any).zE('webWidget', 'setLocale', lang);
    }
  };

  return (
    <Tooltip title={t('zendesk.button.help')}>
      <StyledZendeskButton
        className={`sd-zendesk-button${selectedArea ? `__timeline-selected-area` : ''}`}
        id='zendeskButton'
        onClick={clickButton}>
        ?
      </StyledZendeskButton>
    </Tooltip>
  );
};
