import type { Action, UUID } from 'core/utils/basic.models';
import type {
  WarehouseDTO,
  WarehouseInputDTO,
  WarehouseRemoveDTO,
  WarehouseRespondeDTO,
  WarehouseResponse,
  WarehouseStockRespondeDTO,
  WarehouseStockResponse,
  WarehouseTransactionResponse,
  WarehouseTransactionsRespondeDTO,
  WarehouseTransferDTO,
  WarehouseVendorResponse
} from './warehouse.models';

export const WarehouseActionsTypes = {
  LOAD_WAREHOUSE: '[Warehouse] Load Warehouse',
  LOAD_WAREHOUSE_SUCCESS: '[Warehouse] Load Warehouse Success',
  LOAD_WAREHOUSE_FAILURE: '[Warehouse] Load Warehouse Failure',
  LOAD_TRANSACTIONS_WAREHOUSE: '[Warehouse] Load Transactions Warehouse',
  LOAD_TRANSACTIONS_WAREHOUSE_SUCCESS: '[Warehouse] Load Transactions Warehouse Success',
  LOAD_TRANSACTIONS_WAREHOUSE_FAILURE: '[Warehouse] Load Transactions Warehouse Failure',
  LOAD_ALL_TRANSACTIONS_FROM_WAREHOUSES: '[Warehouse] Load All Transactions From Warehouses',
  LOAD_ALL_TRANSACTIONS_FROM_WAREHOUSES_SUCCESS: '[Warehouse] Load All Transactions Warehouse Success',
  LOAD_ALL_TRANSACTIONS_FROM_WAREHOUSES_FAILURE: '[Warehouse] Load All Transactions Warehouse Failure',
  REMOVE_WAREHOUSE: '[Warehouse] Remove Warehouse',
  REMOVE_WAREHOUSE_SUCCESS: '[Warehouse] Remove Warehouse Success',
  REMOVE_WAREHOUSE_FAILURE: '[Warehouse] Remove Warehouse Failure',
  LOAD_STOCK_WAREHOUSE: '[Warehouse] Load Stock Warehouse',
  LOAD_STOCK_WAREHOUSE_SUCCESS: '[Warehouse] Load Stock Warehouse Success',
  LOAD_STOCK_WAREHOUSE_FAILURE: '[Warehouse] Load Stock Warehouse Failure',
  LOAD_INDIVIDUAL_STOCK_WAREHOUSE: '[Warehouse] Load Individual Stock Warehouse',
  LOAD_INDIVIDUAL_STOCK_WAREHOUSE_SUCCESS: '[Warehouse] Load Individual Stock Warehouse Success',
  LOAD_INDIVIDUAL_STOCK_WAREHOUSE_FAILURE: '[Warehouse] Load Individual Stock Warehouse Failure',
  LOAD_COMPANY_STOCK_WAREHOUSE: '[Warehouse] Load Company Stock Warehouse',
  LOAD_COMPANY_STOCK_WAREHOUSE_SUCCESS: '[Warehouse] Load Company Stock Warehouse Success',
  LOAD_COMPANY_STOCK_WAREHOUSE_FAILURE: '[Warehouse] Load Company Stock Warehouse Failure',
  NEW_WAREHOUSE: '[Warehouse] New Warehouse',
  NEW_WAREHOUSE_SUCCESS: '[Warehouse] New Warehouse Success',
  NEW_WAREHOUSE_FAILURE: '[Warehouse] New Warehouse Failure',
  UPDATE_WAREHOUSE: '[Warehouse] Update Warehouse',
  UPDATE_WAREHOUSE_SUCCESS: '[Warehouse] Update Warehouse Success',
  UPDATE_WAREHOUSE_FAILURE: '[Warehouse] Update Warehouse Failure',
  NEW_TRANSFER_WAREHOUSE: '[Warehouse] New Transfer Warehouse',
  NEW_TRANSFER_WAREHOUSE_SUCCESS: '[Warehouse] New Transfer Warehouse Success',
  NEW_TRANSFER_WAREHOUSE_FAILURE: '[Warehouse] New Transfer Warehouse Failure',
  NEW_VENDOR: '[Warehouse] New Vendor',
  NEW_VENDOR_SUCCESS: '[Warehouse] New Vendor Success',
  NEW_VENDOR_FAILURE: '[Warehouse] New Vendor Failure',
  UPDATE_VENDOR: '[Warehouse] Update Vendor',
  UPDATE_VENDOR_SUCCESS: '[Warehouse] Update Vendor Success',
  UPDATE_VENDOR_FAILURE: '[Warehouse] Update Vendor Failure',
  NEW_ENTRY_WAREHOUSE: '[Warehouse] New Entry Warehouse',
  NEW_ENTRY_WAREHOUSE_SUCCESS: '[Warehouse] New Entry Warehouse Success',
  NEW_ENTRY_WAREHOUSE_FAILURE: '[Warehouse] New Entry Warehouse Failure',
  UPDATE_ENTRY_WAREHOUSE: '[Warehouse] Update Entry Warehouse',
  UPDATE_ENTRY_WAREHOUSE_SUCCESS: '[Warehouse] Update Entry Warehouse Success',
  UPDATE_ENTRY_WAREHOUSE_FAILURE: '[Warehouse] Update Entry Warehouse Failure',
  GET_ENTRY_WAREHOUSE: '[Warehouse] Get Entry Warehouse',
  GET_ENTRY_WAREHOUSE_SUCCESS: '[Warehouse] Get Entry Warehouse Success',
  GET_ENTRY_WAREHOUSE_FAILURE: '[Warehouse] Get Entry Warehouse Failure'
};

export const LoadWarehouse = (companyId: UUID): Action<UUID> => {
  return {
    type: WarehouseActionsTypes.LOAD_WAREHOUSE,
    payload: companyId
  };
};

export const LoadWarehouseSuccess = (idCompany: UUID, warehouses: WarehouseResponse[]): Action<WarehouseRespondeDTO> => {
  return {
    type: WarehouseActionsTypes.LOAD_WAREHOUSE_SUCCESS,
    payload: { id: idCompany, warehouses }
  };
};

export const LoadWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.LOAD_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const LoadTransactionsWarehouse = (companyId: UUID): Action<UUID> => {
  return {
    type: WarehouseActionsTypes.LOAD_TRANSACTIONS_WAREHOUSE,
    payload: companyId
  };
};

export const LoadTransactionsWarehouseSuccess = (
  idCompany: UUID,
  warehouses: WarehouseTransactionResponse[]
): Action<WarehouseTransactionsRespondeDTO> => {
  return {
    type: WarehouseActionsTypes.LOAD_TRANSACTIONS_WAREHOUSE_SUCCESS,
    payload: { id: idCompany, warehouses }
  };
};

export const LoadTransactionsWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.LOAD_TRANSACTIONS_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const LoadAllTransactionsFromWarehouses = (warehouseIds: UUID[]): Action<UUID[]> => {
  return {
    type: WarehouseActionsTypes.LOAD_ALL_TRANSACTIONS_FROM_WAREHOUSES,
    payload: warehouseIds
  };
};

export const LoadAllTransactionsFromWarehousesSuccess = (
  warehouseTransactions: Record<UUID, WarehouseTransactionResponse[]>
): Action<Record<UUID, WarehouseTransactionResponse[]>> => {
  return {
    type: WarehouseActionsTypes.LOAD_ALL_TRANSACTIONS_FROM_WAREHOUSES_SUCCESS,
    payload: warehouseTransactions
  };
};

export const LoadAllTransactionsFromWarehousesFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.LOAD_ALL_TRANSACTIONS_FROM_WAREHOUSES_FAILURE,
    payload: error
  };
};

export const RemoveWarehouse = (payload: WarehouseRemoveDTO): Action<WarehouseRemoveDTO> => {
  return {
    type: WarehouseActionsTypes.REMOVE_WAREHOUSE,
    payload
  };
};

export const RemoveWarehouseSuccess = (payload: WarehouseRemoveDTO): Action<WarehouseRemoveDTO> => {
  return {
    type: WarehouseActionsTypes.REMOVE_WAREHOUSE_SUCCESS,
    payload
  };
};

export const RemoveWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.REMOVE_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const LoadStockWarehouse = (warehouseID: UUID): Action<UUID> => {
  return {
    type: WarehouseActionsTypes.LOAD_STOCK_WAREHOUSE,
    payload: warehouseID
  };
};

export const LoadStockWarehouseSuccess = (warehouses: WarehouseResponse[]): Action<WarehouseResponse[]> => {
  return {
    type: WarehouseActionsTypes.LOAD_STOCK_WAREHOUSE_SUCCESS,
    payload: warehouses
  };
};

export const LoadStockWarehouseFailure = (): Action<WarehouseResponse[]> => {
  return {
    type: WarehouseActionsTypes.LOAD_STOCK_WAREHOUSE_FAILURE
  };
};

export const LoadIndividualStockWarehouse = (warehouseId: UUID): Action<UUID> => {
  return {
    type: WarehouseActionsTypes.LOAD_INDIVIDUAL_STOCK_WAREHOUSE,
    payload: warehouseId
  };
};

export const LoadIndividualStockWarehouseSuccess = (
  warehouseId: UUID,
  warehouses: WarehouseStockResponse[]
): Action<WarehouseStockRespondeDTO> => {
  return {
    type: WarehouseActionsTypes.LOAD_INDIVIDUAL_STOCK_WAREHOUSE_SUCCESS,
    payload: { id: warehouseId, warehouses }
  };
};

export const LoadIndividualStockWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.LOAD_INDIVIDUAL_STOCK_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const LoadCompanyStockWarehouse = (companyId: UUID): Action<UUID> => {
  return {
    type: WarehouseActionsTypes.LOAD_COMPANY_STOCK_WAREHOUSE,
    payload: companyId
  };
};

export const LoadCompanyStockWarehouseSuccess = (
  companyId: UUID,
  warehouses: WarehouseStockResponse[]
): Action<WarehouseStockRespondeDTO> => {
  return {
    type: WarehouseActionsTypes.LOAD_COMPANY_STOCK_WAREHOUSE_SUCCESS,
    payload: { id: companyId, warehouses }
  };
};

export const LoadCompanyStockWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.LOAD_COMPANY_STOCK_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const NewWarehouse = (payload: WarehouseDTO): Action<WarehouseDTO> => {
  return {
    type: WarehouseActionsTypes.NEW_WAREHOUSE,
    payload
  };
};

export const NewWarehouseSuccess = (): Action => {
  return {
    type: WarehouseActionsTypes.NEW_WAREHOUSE_SUCCESS
  };
};

export const NewWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.NEW_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const UpdateWarehouse = (payload: WarehouseDTO): Action<WarehouseDTO> => {
  return {
    type: WarehouseActionsTypes.UPDATE_WAREHOUSE,
    payload
  };
};

export const UpdateWarehouseSuccess = (): Action => {
  return {
    type: WarehouseActionsTypes.UPDATE_WAREHOUSE_SUCCESS
  };
};

export const UpdateWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.UPDATE_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const NewTransferWarehouse = (payload: WarehouseTransferDTO): Action<WarehouseTransferDTO> => {
  return {
    type: WarehouseActionsTypes.NEW_TRANSFER_WAREHOUSE,
    payload
  };
};

export const NewTransferWarehouseSuccess = (): Action => {
  return {
    type: WarehouseActionsTypes.NEW_TRANSFER_WAREHOUSE_SUCCESS
  };
};

export const NewTransferWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.NEW_TRANSFER_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const NewVendor = (vendor: WarehouseVendorResponse): Action<WarehouseVendorResponse> => {
  return {
    type: WarehouseActionsTypes.NEW_VENDOR,
    payload: vendor
  };
};

export const NewVendorSuccess = (idVendor: UUID): Action<UUID> => {
  return {
    type: WarehouseActionsTypes.NEW_VENDOR_SUCCESS,
    payload: idVendor
  };
};

export const NewVendorFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.NEW_VENDOR_FAILURE,
    payload: error
  };
};

export const UpdateVendor = (vendor: WarehouseVendorResponse): Action<WarehouseVendorResponse> => {
  return {
    type: WarehouseActionsTypes.UPDATE_VENDOR,
    payload: vendor
  };
};

export const UpdateVendorSuccess = (idVendor: UUID): Action<UUID> => {
  return {
    type: WarehouseActionsTypes.UPDATE_VENDOR_SUCCESS,
    payload: idVendor
  };
};

export const UpdateVendorFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.UPDATE_VENDOR_FAILURE,
    payload: error
  };
};

export const NewEntryWarehouse = (input: WarehouseInputDTO): Action<WarehouseInputDTO> => {
  return {
    type: WarehouseActionsTypes.NEW_ENTRY_WAREHOUSE,
    payload: input
  };
};

export const NewEntryWarehouseSuccess = (): Action => {
  return {
    type: WarehouseActionsTypes.NEW_ENTRY_WAREHOUSE_SUCCESS
  };
};

export const NewEntryWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.NEW_ENTRY_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const UpdateEntryWarehouse = (input: WarehouseInputDTO): Action<WarehouseInputDTO> => {
  return {
    type: WarehouseActionsTypes.UPDATE_ENTRY_WAREHOUSE,
    payload: input
  };
};

export const UpdateEntryWarehouseSuccess = (): Action => {
  return {
    type: WarehouseActionsTypes.UPDATE_ENTRY_WAREHOUSE_SUCCESS
  };
};

export const UpdateEntryWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.UPDATE_ENTRY_WAREHOUSE_FAILURE,
    payload: error
  };
};

export const GetEntryWarehouse = (inputId: UUID, warehouseId: UUID): Action<{ inputId: UUID; warehouseId: UUID }> => {
  return {
    type: WarehouseActionsTypes.GET_ENTRY_WAREHOUSE,
    payload: { inputId, warehouseId }
  };
};

export const GetEntryWarehouseSuccess = (entry: WarehouseInputDTO): Action<WarehouseInputDTO> => {
  return {
    type: WarehouseActionsTypes.GET_ENTRY_WAREHOUSE_SUCCESS,
    payload: entry
  };
};

export const GetEntryWarehouseFailure = (error: string): Action<string> => {
  return {
    type: WarehouseActionsTypes.GET_ENTRY_WAREHOUSE_FAILURE,
    payload: error
  };
};
