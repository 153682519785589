import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type React from 'react';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { reportTranslationKey } from '../functions/report-generate.functions';
import type { ReportsTypes } from '../reports-types.enum';

interface IHeaderTitle {
  icon?: ReactNode;
  type: ReportsTypes | null;
}

const HeaderTitle: React.FC<IHeaderTitle> = ({ icon, type }: IHeaderTitle) => {
  const [t] = useTranslation();

  function getTextByType(sufix: string) {
    return type ? t(`report.${reportTranslationKey[type]}.${sufix}`) : '';
  }

  return (
    <div className='st-report-drawer__title'>
      {icon && <div className='st-report-drawer__title__icon'>{icon}</div>}
      {type && <STTypo type={TypeOptions.H5}>{getTextByType('name')}</STTypo>}
    </div>
  );
};

export default HeaderTitle;
