import { EWidgets } from 'core/services/analytics/widgets/widgets.models';
import { create } from 'zustand';

export interface DashboardWidgetStatus {
  isLoading: boolean;
}

type WidgetsStatus = Record<EWidgets, DashboardWidgetStatus>;

interface DashboardWidgetsStatus {
  widgetsStatus: WidgetsStatus;
  setWidgetStatus: (key: EWidgets, value: DashboardWidgetStatus['isLoading']) => void;
  setWidgetsStatus: (value: Partial<WidgetsStatus>) => void;
  resetWidgetsStatus: () => void;
}

export const INITIAL_STATE = Object.keys(EWidgets).reduce((accumulator, key) => {
  accumulator[EWidgets[key as keyof typeof EWidgets]] = { isLoading: true };

  return accumulator;
}, {} as WidgetsStatus);

export const useDashboardWidgetsStatusStore = create<DashboardWidgetsStatus>(set => ({
  widgetsStatus: INITIAL_STATE,
  setWidgetStatus: (key, value) => set(state => ({ widgetsStatus: { ...state.widgetsStatus, [key]: { isLoading: value } } })),
  setWidgetsStatus: value => set(state => ({ widgetsStatus: { ...state.widgetsStatus, ...value } })),
  resetWidgetsStatus: () => set({ widgetsStatus: INITIAL_STATE })
}));
