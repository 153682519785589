import type { InitCWElements } from '@cw-elements/config';
import { initCWElements, setToken, setupAxiosInterceptor } from '@cw-elements/config';
import { initMfes } from '@cw-elements/mfe-setup/init';
import axios from 'axios';
import { CROPWISE_ELEMENTS_MFE_URL, ENV_CW_COMPONENTS } from 'config/constants';
import { LoadCurrentUserAccountService, LoadCurrentUserSuccess } from 'core/services/auth/auth.actions';
import type { User } from 'core/services/auth/auth.models';
import { getIsLogged } from 'core/services/auth/auth.selectors';
import { validateUserAgainstAccessToken } from 'core/utils/user';
import { isEmpty } from 'lodash';
import { useGetRBACOperationsFullUser } from 'querys/rbac/rbac.query';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useInitPlatform = () => {
  const dispatch = useDispatch();

  const isLogged = useSelector(getIsLogged);

  const { data: fullUserPermission } = useGetRBACOperationsFullUser(isLogged);

  const token = localStorage.getItem('access_token');

  const setUserState = useCallback(
    (user: User) => {
      dispatch(LoadCurrentUserSuccess(user));
      validateUserAgainstAccessToken(user);
    },
    [dispatch]
  );

  const initializeCropwiseMFE = useCallback(async () => {
    await initMfes({
      'input-details': `${CROPWISE_ELEMENTS_MFE_URL}/input-details`
    });
  }, []);

  useEffect(() => {
    if (!fullUserPermission || !isLogged) return;
    const isUserAccountService = !isEmpty(fullUserPermission?.allowed_operations);
    dispatch(LoadCurrentUserAccountService(isUserAccountService));
  }, [dispatch, fullUserPermission, isLogged]);

  useEffect(() => {
    initCWElements({
      environment: ENV_CW_COMPONENTS,
      excludeAuthorities: true,
      getCurrentUserCallback: setUserState
    } as InitCWElements);

    void initializeCropwiseMFE();
    setupAxiosInterceptor(axios);
  }, [initializeCropwiseMFE, setUserState]);

  useEffect(() => {
    if (token) setToken(token);
  }, [token]);
};

export default useInitPlatform;
