import { Tag } from 'antd';
import SDMarkerPopup from 'components/map/sd-marker-popup.component';
import { COLOR_OPTIONS } from 'config/style';
import STAvatar from 'core/shared/avatar';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { getCurrentLanguage, getInitials } from 'core/utils/functions';
import type { mapboxgl } from 'core/utils/map/mapbox-gl';
import moment from 'moment';
import type { PhenologySample } from 'pages/timeline/timeline.models';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import SvgAnnotationsGlyph from 'st-protector-icons/Glyph/AnnotationsGlyph';
import SvgClockGlyph from 'st-protector-icons/Glyph/ClockGlyph';
import SvgPropertyGlyph from 'st-protector-icons/Glyph/PropertyGlyph';

export interface ISDPopupPhenology extends PhenologySample {
  propertyId: UUID;
  popup: any;
  mapboxPopup: mapboxgl.Popup;
}

const SDPopupPhenology: React.FC<ISDPopupPhenology> = ({ mapboxPopup, date, scouter, comments, forced_point, stage, point, popup }) => {
  const [t] = useTranslation();
  const { coordinates } = point;

  moment.locale(getCurrentLanguage());

  return (
    <SDMarkerPopup {...popup} title={stage.name} mapboxPopup={mapboxPopup} subTitle={stage.short_description}>
      <div className='st-popup__item'>
        <SvgPropertyGlyph />
        <STTypo color={forced_point ? COLOR_OPTIONS.SECONDARY : COLOR_OPTIONS.PRIMARY}>
          {`${coordinates[1].toFixed(6)}, ${coordinates[0].toFixed(6)}`}
        </STTypo>
        {forced_point && (
          <Tag className='st-tag' color='rgba(111, 160, 138, 0.1)'>
            <STTypo fontWeight={500} type={TypeOptions.C2} color='#6FA08A'>
              {t('pages.timeline.monitoring_window.point_real')}
            </STTypo>
          </Tag>
        )}
      </div>
      {forced_point && (
        <div className='st-popup__item'>
          <div className='st-width--16' />
          <STTypo>{`${forced_point.coordinates[1].toFixed(6)}, ${forced_point.coordinates[0].toFixed(6)}`}</STTypo>
          <Tag className='st-tag' color='rgba(27, 103, 107, 0.1)'>
            <STTypo fontWeight={500} type={TypeOptions.C2} color='#1B676B'>
              {t('pages.timeline.monitoring_window.point_simulated')}
            </STTypo>
          </Tag>
        </div>
      )}
      <div className='st-popup__item'>
        <SvgClockGlyph />
        <STTypo>{moment(date, 'YYYY-MM-DDTHH:mm').format('DD MMM, YY • HH:mm')}</STTypo>
      </div>
      {comments && (
        <div className='st-popup__item st-popup__item-note st-grid-column-gap--4'>
          <SvgAnnotationsGlyph />
          <STTypo>{comments}</STTypo>
        </div>
      )}
      {scouter && (
        <div className='st-popup__item st-popup__item-avatar st-grid-column-gap--4'>
          <STAvatar label={scouter.name} small disabled>
            {getInitials(scouter.name)}
          </STAvatar>
          <STTypo>{scouter.name}</STTypo>
        </div>
      )}
    </SDMarkerPopup>
  );
};

export default SDPopupPhenology;
