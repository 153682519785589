const SvgEmptyFolderGlyph = ({ fill, width, height }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='129' height='90' viewBox='0 0 129 90' fill='none'>
    <path
      d='M5.13296 1.65296V13.9395L3.84071 13.8017V0.359243C3.84071 -1.76192 5.56026 -3.48147 7.68143 -3.48147H28.8711C30.5589 -3.48147 32.0489 -2.37958 32.5433 -0.765814L34.1823 4.58402L33.8224 14.8195L31.2875 0.453106C30.7693 -1.12272 29.2979 -2.18775 27.639 -2.18775H8.97367C6.85251 -2.18775 5.13296 -0.468205 5.13296 1.65296Z'
      fill='#DFE2E7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.32236 0.359206C3.32236 -2.04832 5.27405 -4 7.68158 -4H28.8712C30.7869 -4 32.478 -2.74936 33.0392 -0.917734L35.3345 14.4354L39.5593 15.9462L29.5735 19.4283C29.1252 18.0652 27.5517 17.508 26.1168 17.508L23.8124 16.7398C21.9776 16.7398 10.754 12.4331 10.754 14.2679L5.65161 14.5161L3.32236 14.2679V0.359206ZM7.68158 -2.96301C5.84677 -2.96301 4.35936 -1.4756 4.35936 0.359206V13.3355L4.61462 13.3627V1.65292C4.61462 -0.754599 6.5663 -2.70629 8.97382 -2.70629H27.6392C29.522 -2.70629 31.192 -1.49748 31.7802 0.291089L33.0215 4.06549H33.4813L32.0477 -0.613968C31.62 -2.00988 30.3312 -2.96301 28.8712 -2.96301H7.68158Z'
      fill='#DFE2E7'
    />
    <path
      d='M118.755 8.56623V14.5699L120.048 14.6564V7.27252C120.048 5.15136 118.328 3.43181 116.207 3.43181H33.8224C33.2463 3.43181 32.9576 4.1282 33.3648 4.53581C33.4861 4.65727 33.6507 4.72552 33.8224 4.72552H114.915C117.036 4.72552 118.755 6.44507 118.755 8.56623Z'
      fill='#DFE2E7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M120.432 7.27252C120.432 4.93924 118.54 3.04774 116.207 3.04774H33.8224C32.9043 3.04774 32.4442 4.15762 33.093 4.80723C33.2864 5.00082 33.481 14.4354 33.7547 14.4354L98.3222 19.0443C100.231 19.0443 113.685 13.6786 113.685 15.5876L118.371 14.9291L120.432 15.067V7.27252ZM116.207 3.81588C118.116 3.81588 119.664 5.36347 119.664 7.27252V14.2457L119.14 14.2107V8.56623C119.14 6.23295 117.248 4.34145 114.915 4.34145H33.8224C33.7527 4.34145 33.6858 4.31373 33.6365 4.26438C33.4711 4.09879 33.5884 3.81588 33.8224 3.81588H116.207Z'
      fill='#DFE2E7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 15.9462C0 14.5497 1.41145 13.4177 3.15256 13.4177H119.75C121.491 13.4177 122.903 14.5497 122.903 15.9462L119.062 83.0945C119.062 84.491 117.651 85.623 115.909 85.623H6.99327C5.25215 85.623 3.84071 84.4909 3.84071 83.0945L0 15.9462Z'
      fill='#DFE2E7'
    />
    <path
      d='M10.2921 6.02991V18.3164L8.99989 18.1787V4.7362C8.99989 2.61503 10.7194 0.895487 12.8406 0.895487H34.0302C35.718 0.895487 37.2081 1.99737 37.7025 3.61114L39.3415 8.96098L38.9816 19.1964L36.4467 4.83006C35.9285 3.25423 34.4571 2.1892 32.7982 2.1892H14.1329C12.0117 2.1892 10.2921 3.90875 10.2921 6.02991Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.48154 4.73616C8.48154 2.32864 10.4332 0.376953 12.8408 0.376953H34.0304C35.9461 0.376953 37.6372 1.62759 38.1984 3.45922L40.4937 18.8124L44.7185 20.3231L34.7326 23.8053C34.2844 22.4422 32.7109 21.8849 31.276 21.8849L28.9716 21.1168C27.1368 21.1168 15.9132 16.81 15.9132 18.6448L10.8108 18.8931L8.48154 18.6448V4.73616ZM12.8408 1.41394C11.0059 1.41394 9.51854 2.90135 9.51854 4.73616V17.7125L9.7738 17.7397V6.02988C9.7738 3.62235 11.7255 1.67067 14.133 1.67067H32.7984C34.6812 1.67067 36.3512 2.87948 36.9394 4.66804L38.1807 8.44244H38.6405L37.2069 3.76298C36.7792 2.36707 35.4903 1.41394 34.0304 1.41394H12.8408Z'
      fill='white'
    />
    <path
      d='M123.915 12.9432V18.9468L125.207 19.0333V11.6495C125.207 9.52831 123.487 7.80876 121.366 7.80876H38.9816C38.4055 7.80876 38.1168 8.50516 38.524 8.91276C38.6453 9.03423 38.8099 9.10248 38.9816 9.10248H120.074C122.195 9.10248 123.915 10.822 123.915 12.9432Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M125.591 11.6495C125.591 9.31619 123.699 7.42469 121.366 7.42469H38.9816C38.0634 7.42469 37.6033 8.53457 38.2522 9.18419C38.4456 9.37777 38.6402 18.8124 38.9138 18.8124L103.481 23.4212C105.39 23.4212 118.844 18.0555 118.844 19.9646L123.531 19.306L125.591 19.444V11.6495ZM121.366 8.19284C123.275 8.19284 124.823 9.74043 124.823 11.6495V18.6227L124.299 18.5876V12.9432C124.299 10.6099 122.407 8.71841 120.074 8.71841H38.9816C38.9119 8.71841 38.845 8.69068 38.7957 8.64133C38.6303 8.47575 38.7476 8.19284 38.9816 8.19284H121.366Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.15918 20.3231C5.15918 18.9267 6.57063 17.7946 8.31174 17.7946H124.909C126.65 17.7946 128.062 18.9267 128.062 20.3231L124.221 87.4714C124.221 88.8679 122.81 90 121.069 90H12.1525C10.4113 90 8.99989 88.8679 8.99989 87.4714L5.15918 20.3231Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.1157 40.3203L66.6105 48.8151L75.1051 40.3205L81.2053 46.4173L72.7103 54.9149L81.2052 63.4098L75.1053 69.5097L66.6105 61.0149L58.1157 69.5097L52.0159 63.4098L60.5107 54.915L52.0159 46.4202L58.1157 40.3203ZM75.1055 43.2202L66.6105 51.7152L58.1157 43.2204L54.9159 46.4202L63.4107 54.915L54.9159 63.4098L58.1157 66.6096L66.6105 58.1148L75.1053 66.6096L78.3051 63.4098L69.8104 54.9151L78.305 46.4179L75.1055 43.2202Z'
      fill='#EB4B4B'
    />
    <path
      d='M10.2921 6.02991V18.3164L8.99989 18.1787V4.7362C8.99989 2.61503 10.7194 0.895487 12.8406 0.895487H34.0302C35.718 0.895487 37.2081 1.99737 37.7025 3.61114L39.3415 8.96098H37.8052L36.4467 4.83006C35.9285 3.25423 34.4571 2.1892 32.7982 2.1892H14.1329C12.0117 2.1892 10.2921 3.90875 10.2921 6.02991Z'
      fill='#00004B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.48154 4.73616C8.48154 2.32864 10.4332 0.376953 12.8408 0.376953H34.0304C35.9461 0.376953 37.6372 1.62759 38.1984 3.45922L40.0428 9.47944H37.4301L35.9543 4.992C35.506 3.62891 34.2333 2.70766 32.7984 2.70766H14.133C12.2982 2.70766 10.8108 4.19507 10.8108 6.02988V18.8931L8.48154 18.6448V4.73616ZM12.8408 1.41394C11.0059 1.41394 9.51854 2.90135 9.51854 4.73616V17.7125L9.7738 17.7397V6.02988C9.7738 3.62235 11.7255 1.67067 14.133 1.67067H32.7984C34.6812 1.67067 36.3512 2.87948 36.9394 4.66804L38.1807 8.44244H38.6405L37.2069 3.76298C36.7792 2.36707 35.4903 1.41394 34.0304 1.41394H12.8408Z'
      fill='#00004B'
    />
    <path
      d='M123.915 12.9432V18.9468L125.207 19.0333V11.6495C125.207 9.52831 123.487 7.80876 121.366 7.80876H38.9816C38.4055 7.80876 38.1168 8.50516 38.524 8.91276C38.6453 9.03423 38.8099 9.10248 38.9816 9.10248H120.074C122.195 9.10248 123.915 10.822 123.915 12.9432Z'
      fill='#00004B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M125.591 11.6495C125.591 9.31619 123.699 7.42469 121.366 7.42469H38.9816C38.0634 7.42469 37.6033 8.53457 38.2522 9.18419C38.4456 9.37777 38.708 9.48655 38.9816 9.48655H120.074C121.983 9.48655 123.531 11.0341 123.531 12.9432V19.306L125.591 19.444V11.6495ZM121.366 8.19284C123.275 8.19284 124.823 9.74043 124.823 11.6495V18.6227L124.299 18.5876V12.9432C124.299 10.6099 122.407 8.71841 120.074 8.71841H38.9816C38.9119 8.71841 38.845 8.69068 38.7957 8.64133C38.6303 8.47575 38.7476 8.19284 38.9816 8.19284H121.366Z'
      fill='#00004B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.31174 19.5784C7.79893 19.5784 7.38321 19.9118 7.38321 20.3231L11.2239 87.4714C11.2239 87.8828 11.6396 88.2162 12.1525 88.2162H121.069C121.581 88.2162 121.997 87.8828 121.997 87.4714L125.838 20.3231C125.838 19.9118 125.422 19.5784 124.909 19.5784H8.31174ZM5.15918 20.3231C5.15918 18.9267 6.57063 17.7946 8.31174 17.7946H124.909C126.65 17.7946 128.062 18.9267 128.062 20.3231L124.221 87.4714C124.221 88.8679 122.81 90 121.069 90H12.1525C10.4113 90 8.99989 88.8679 8.99989 87.4714L5.15918 20.3231Z'
      fill='#00004B'
    />
  </svg>
);

export default SvgEmptyFolderGlyph;
