import axios from 'axios';
import { PROTECTOR_API_URL } from 'config/constants';
import type { UUID } from 'core/utils/basic.models';
import type { PhenomenonSingle } from 'entities/methodology-deep/methodology-deep.models';
import type { MethodologyCurrentSeasonAreas, MethodologyEntity } from 'entities/methodology/methodology.models';
import { getMethodologiesForPropertySeason } from 'entities/methodology/methodology.service';
import type { CurrentSeasonArea } from 'entities/property/property.models';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import type { Characteristic, MethodologiesByPropertyResponse } from './methodology.query.models';

const protectorApiUrl = PROTECTOR_API_URL;
const methodologyUrl = `${protectorApiUrl}/api/v1/methodologies`;

export const getMethodologiesByProperty = async (propertyId?: string) => {
  const response = await axios.get<MethodologiesByPropertyResponse[]>(`${protectorApiUrl}/v1/methodologies/property/${propertyId}/by-area`);

  return response.data;
};

export const getMethodologiesIdsByPropertyAndSeasons = async (propertyId: string, seasonIds: string[]): Promise<string[]> => {
  if (!propertyId || !seasonIds?.length) return [];

  const methodologiesPromise = seasonIds.map(seasonId => getMethodologiesForPropertySeason(propertyId, seasonId).toPromise());

  const methodologies = (await Promise.all(methodologiesPromise)).flatMap(methodology => methodology);

  return uniqBy(methodologies, 'methodologyId').map(methodology => methodology.methodologyId);
};

export const getMethodologiesIdsFromSeasonsAreas = async (propertyId?: UUID, seasonIds?: UUID[], areaId?: UUID) => {
  const params = {
    seasonIds: seasonIds?.join(','),
    propertyId,
    areaId,
    allowPastSeasonAreas: true,
    includeMethodology: true
  };
  const seasonAreas = await axios.get<CurrentSeasonArea[]>(`${protectorApiUrl}/v1/season-areas`, { params });

  const uniqueMethodologies = uniqBy(seasonAreas.data, 'methodologyId');

  return uniqueMethodologies.map(({ methodologyId }) => methodologyId).filter(Boolean);
};

export const getAllMethodologies = async (propertyId?: string, seasonIds?: string[], flags: boolean) => {
  if (!propertyId || !seasonIds?.length) return;

  let uniqueMethodologiesIds;

  if (flags) {
    uniqueMethodologiesIds = await getMethodologiesIdsFromSeasonsAreas(propertyId, seasonIds);
  } else {
    uniqueMethodologiesIds = await getMethodologiesIdsByPropertyAndSeasons(propertyId, seasonIds);
  }

  const response = await axios.post<MethodologyEntity[]>(`${protectorApiUrl}/api/v1/methodologies/ids`, { ids: uniqueMethodologiesIds });
  return response.data;
};

export const getCharacteristicsByMethodologies = async (id: string) => {
  const response = await axios.get<Characteristic[]>(`${methodologyUrl}/${id}/characteristics`);
  return response.data;
};

export const getPhenomenaByMethodologyId = async (methodologyId: string) => {
  const response = await axios.get<PhenomenonSingle[]>(`${methodologyUrl}/${methodologyId}/phenomenon`);
  return response.data;
};

export const getMethodologiesByCompanyAndProperty = async (companyId: string, propertyId: string) => {
  const response = await axios.get<Record<string, string[]>>(
    `${protectorApiUrl}/v1/companies/${companyId}/properties/${propertyId}/methodologies/fields`
  );
  return response.data;
};

export const getMethodologies = async (propertyId: string) => {
  const methodologiesUrl = `${protectorApiUrl}/v1/properties/${propertyId}/current-season-areas?date=${moment().format(
    'YYYY-MM-DD'
  )}&includeMethodology=true`;

  const response = await axios.get<MethodologyCurrentSeasonAreas[]>(methodologiesUrl);

  const methodologies = response.data.flatMap(area => {
    return area;
  });

  return methodologies;
};
