import { Drawer, notification } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import type { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { ReactComponent as ExportUploadSvg } from 'assets/images/reports/export-upload.svg';
import axios from 'axios';
import { PROTECTOR_API_URL } from 'config/constants';
import { getSelectedProperty } from 'entities/property/property.reducer';
import type React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import '../report.styles.less';
import type { ReportsTypes } from '../reports-types.enum';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';
import { ImportError } from './components/import-error/import-error.component.tsx';
import type { LineErrorPayloadInterface } from './report-property.models.ts';
import { useSetImportSamplingError } from './components/import-error/import-error.functions.ts';

interface ISTImportSampling {
  visible: boolean;
  callbackDrawer: () => void;
  type: ReportsTypes | null;
  icon?: any;
}

const STImportSampling: React.FC<ISTImportSampling> = ({ visible, callbackDrawer, type, icon }) => {
  const [t] = useTranslation();
  const property = useSelector((state: AppState) => getSelectedProperty(state));
  const { handleSetErrors, handleClearErrors, errorsList } = useSetImportSamplingError();

  /**
   * Function to send file to back end and send feedback to user with success or failure
   * @param options:RcCustomRequestOptions
   */
  const uploadFile = (options: RcCustomRequestOptions) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: event => {
        onProgress({ percent: (event.loaded / event.total) * 100 }, options.file);
      }
    };
    fmData.append('file', file);
    axios
      .post(`${PROTECTOR_API_URL}/v1/property/${property?.id}/sampling/import-sampling-data`, fmData, config)
      .then(() => {
        onSuccess({ reponse: 'OK' }, options.file);
        notification.success({
          message: t('report.import.success'),
          description: t('report.import.success_description')
        });
        handleClearErrors();
      })
      .catch((err: LineErrorPayloadInterface) => {
        handleSetErrors(err);
        notification.error({
          message: t('report.import.failure'),
          description: t('report.import.failure_description'),
          duration: 8
        });
        const error = new Error(err);
        onError(error);
      });
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.xlsx',
    customRequest: uploadFile
  };

  const closeDrawer = useCallback(() => {
    callbackDrawer();
  }, [callbackDrawer]);

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible}
      width={400}
      title={<HeaderTitle icon={icon} type={type} />}
      onClose={closeDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='report-sampling-upload-drawer'>
        <ExplicativeText type={type} />
        <div className='st-report-drawer__upload' data-testid='report-sampling-drawer-upload'>
          <Dragger {...uploadProps}>
            <p className='ant-upload-drag-icon'>
              <ExportUploadSvg />
            </p>
            <p className='ant-upload-text'>{t('report.upload_text')}</p>
            <p className='ant-upload-hint'>{t('report.upload_file')} (.xlsx)</p>
          </Dragger>
          {!!errorsList?.length && <ImportError errorsList={errorsList} />}
        </div>
      </div>
    </Drawer>
  );
};

export default STImportSampling;
