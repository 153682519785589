import { images } from 'assets';
import type React from 'react';
import { useState } from 'react';
import { checkImageExists } from '../../utils/functions';

interface STImageProps {
  fallbackimage?: string;
  alt: string;
  url: string;
}

const STImage: React.FC<STImageProps & React.HTMLAttributes<HTMLImageElement>> = props => {
  const [image, setImage] = useState(props.fallbackimage || images.fallbackimage);

  checkImageExists(props.url, () => {
    setImage(props.url);
  });

  return <img src={image} {...props} alt={props.alt} />;
};

export default STImage;
