import { getPermissionFlags, getSystemFlags } from 'core/core.selectors';
import { verifyNemadigitalConfigAvailability } from 'pages/nemadigital/nemadigital.selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

const NemaDigitalConfig = React.lazy(() => import('pages/nemadigital/config/nemadigital-config.container'));

const NemaDigital = () => {
  const systemFlags = useSelector(getSystemFlags);
  const permissionFlags = useSelector(getPermissionFlags);

  const hasAccess = useSelector(verifyNemadigitalConfigAvailability);

  if (!systemFlags || !permissionFlags) {
    return null;
  }

  if (!hasAccess) {
    return <Navigate to='/hall-of-companies' />;
  }

  return <NemaDigitalConfig />;
};

export default NemaDigital;
