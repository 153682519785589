const SvgWeatherCloudyLightSnow = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M8.21176 19.4606C7.92487 19.2024 7.48607 19.2139 7.21315 19.4869C6.92974 19.7703 6.92974 20.2297 7.21315 20.5131L7.7 21L7.2 21.5C6.92385 21.7761 6.92385 22.2239 7.2 22.5C7.47614 22.7761 7.92385 22.7761 8.2 22.5L8.7 22L9.18685 22.4869C9.47025 22.7703 9.92974 22.7703 10.2131 22.4869C10.4861 22.2139 10.4976 21.7751 10.2394 21.4882L9.8 21L10.227 20.5256C10.4921 20.2309 10.4803 19.7803 10.2 19.5C9.9197 19.2197 9.46905 19.2079 9.17441 19.473L8.7 19.9L8.21176 19.4606Z'
      fill='#92B0ED'
    />
    <path
      d='M14.8256 19.473C14.531 19.2079 14.0803 19.2197 13.8 19.5C13.5197 19.7803 13.5079 20.2309 13.773 20.5256L14.2 21L13.7606 21.4882C13.5024 21.7751 13.5139 22.2139 13.7869 22.4869C14.0703 22.7703 14.5297 22.7703 14.8132 22.4869L15.3 22L15.8 22.5C16.0761 22.7761 16.5239 22.7761 16.8 22.5C17.0761 22.2239 17.0761 21.7761 16.8 21.5L16.3 21L16.7869 20.5131C17.0703 20.2297 17.0703 19.7703 16.7869 19.4869C16.5139 19.2139 16.0751 19.2024 15.7882 19.4606L15.3 19.9L14.8256 19.473Z'
      fill='#92B0ED'
    />
    <path
      d='M16.504 9.707L15.798 8.032C16.428 7.768 16.836 7.151 16.836 6.465C16.836 5.53 16.073 4.762 15.136 4.762C14.3 4.762 13.576 5.386 13.454 6.209C13.44 6.294 13.433 6.38 13.433 6.465H11.615C11.615 6.289 11.629 6.11 11.654 5.939C11.912 4.232 13.409 2.948 15.136 2.948C17.074 2.948 18.654 4.526 18.654 6.466C18.654 7.882 17.811 9.157 16.504 9.707Z'
      fill='#DFA701'
    />
    <path
      d='M12.3447 4.21597L11.3775 3.83642C10.9099 3.65293 10.3821 3.88324 10.1986 4.35083C10.0151 4.81843 10.2454 5.34624 10.713 5.52973L11.6802 5.90927C12.1478 6.09277 12.6756 5.86246 12.8591 5.39486C13.0426 4.92727 12.8123 4.39946 12.3447 4.21597Z'
      fill='#DFA701'
    />
    <path
      d='M14.5736 2.88792L14.1218 1.95341C13.9032 1.50144 13.3597 1.3122 12.9077 1.53073C12.4557 1.74926 12.2665 2.29281 12.485 2.74479L12.9369 3.6793C13.1554 4.13127 13.699 4.32052 14.1509 4.10198C14.6029 3.88345 14.7922 3.3399 14.5736 2.88792Z'
      fill='#DFA701'
    />
    <path
      d='M17.1235 3.44113L17.3968 2.58787C17.55 2.10979 17.2866 1.59806 16.8085 1.44489C16.3304 1.29172 15.8187 1.55512 15.6656 2.0332L15.3922 2.88646C15.239 3.36454 15.5024 3.87627 15.9805 4.02943C16.4586 4.1826 16.9703 3.91921 17.1235 3.44113Z'
      fill='#DFA701'
    />
    <path
      d='M18.6115 5.53568L19.5086 5.00965C19.9409 4.75614 20.0859 4.20016 19.8324 3.76783C19.5789 3.33549 19.0229 3.19052 18.5906 3.44403L17.6935 3.97006C17.2611 4.22357 17.1162 4.77955 17.3697 5.21188C17.6232 5.64422 18.1792 5.78919 18.6115 5.53568Z'
      fill='#DFA701'
    />
    <path
      d='M19.687 6.55609L18.6729 6.33479C18.1824 6.22776 17.698 6.5386 17.591 7.02908C17.4839 7.51956 17.7948 8.00394 18.2853 8.11098L19.2994 8.33228C19.7899 8.43931 20.2742 8.12846 20.3813 7.63798C20.4883 7.1475 20.1775 6.66312 19.687 6.55609Z'
      fill='#DFA701'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.025 8.48801C15.048 6.79701 13.243 5.73401 11.254 5.73401C8.499 5.73401 6.208 7.77301 5.811 10.42C4.2 10.809 3 12.264 3 13.99C3 16.016 4.649 17.666 6.674 17.666L15.838 17.668C18.368 17.668 20.428 15.607 20.428 13.077C20.427 10.606 18.468 8.58701 16.025 8.48801ZM15.838 15.814H6.676C5.67 15.814 4.851 14.996 4.851 13.99C4.851 12.986 5.671 12.166 6.676 12.166H7.6V11.243C7.6 9.229 9.241 7.586 11.256 7.586C12.725 7.586 14.043 8.457 14.621 9.808L14.889 10.434L15.838 10.333C17.349 10.333 18.578 11.564 18.578 13.076C18.578 14.586 17.349 15.814 15.838 15.814Z'
      fill='#828B99'
    />
  </svg>
);

export default SvgWeatherCloudyLightSnow;
