import { Select } from 'antd';
import { getSelectedSeasons } from 'core/core.reducer';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import type { Season } from 'entities/season/season.models';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';

export interface ISelectSeasonProps {
  selectedSeason?: UUID;
  setSelectedSeason: (value: UUID) => void;
  placeholder?: string;
  showOnlySeasonsSelected?: boolean;
}

const SelectSeason = ({ selectedSeason, setSelectedSeason, placeholder, showOnlySeasonsSelected = false }: ISelectSeasonProps) => {
  const allSeasons = useSelector((state: AppState) => state.entities.season.entities);
  const seasonGlobalSelected = useSelector(getSelectedSeasons);
  const singleSeasonSelected = seasonGlobalSelected.length === 1;
  const [seasonsToBeUsed, setSeasonsToBeUsed] = useState<Season[]>([]);
  const [t] = useTranslation();

  useEffect(() => {
    if (singleSeasonSelected && seasonGlobalSelected) {
      setSelectedSeason(seasonGlobalSelected.join());
    }
  }, [seasonGlobalSelected, setSelectedSeason, singleSeasonSelected]);

  useEffect(() => {
    let tempSeasons = Object.values(allSeasons);
    if (showOnlySeasonsSelected) {
      const idsAllSeasons = Object.keys(allSeasons);
      tempSeasons = idsAllSeasons.reduce<Season[]>((acc, season) => {
        if (seasonGlobalSelected.includes(season)) {
          acc.push(allSeasons[season]);
        }
        return acc;
      }, []);
    }
    setSeasonsToBeUsed(tempSeasons);
  }, [showOnlySeasonsSelected, allSeasons, seasonGlobalSelected]);

  return (
    <>
      <Select
        data-testid='select-seasons-reports'
        size='large'
        value={selectedSeason}
        disabled={singleSeasonSelected}
        placeholder={placeholder}
        onChange={value => setSelectedSeason(value)}>
        {seasonsToBeUsed.map(season => (
          <Select.Option key={season.id} value={season.id} data-testid='select-seasons-reports-item'>
            {season.name}
          </Select.Option>
        ))}
      </Select>
      {!!seasonsToBeUsed.length && !singleSeasonSelected && (
        <details data-testid='select-seasons-message-multiple'>
          <summary className='mb-6'>
            <STTypo fontWeight={500} type={TypeOptions.P1}>
              {t('report.whyICantSelectInactiveSeason.title')}
            </STTypo>
          </summary>
          <div className='mb-6'>
            <STTypo type={TypeOptions.P1}>
              <Trans>{t('report.whyICantSelectInactiveSeason.description')}</Trans>
            </STTypo>
          </div>
        </details>
      )}
      {singleSeasonSelected && (
        <details data-testid='select-seasons-message-single'>
          <summary className='mb-6'>
            <STTypo fontWeight={500} type={TypeOptions.P1}>
              {t('report.whyICantSelectSeason.title')}
            </STTypo>
          </summary>
          <div className='mb-6'>
            <STTypo type={TypeOptions.P1}>{t('report.whyICantSelectSeason.description')}</STTypo>
          </div>
          <div>
            <STTypo type={TypeOptions.P1}>
              <Trans>{t('report.whyICantSelectSeason.step1')}</Trans>
            </STTypo>
          </div>
          <div>
            <STTypo type={TypeOptions.P1}>
              <Trans>{t('report.whyICantSelectSeason.step2')} </Trans>
            </STTypo>
          </div>
          <div className='mb-6'>
            <STTypo type={TypeOptions.P1}>{t('report.whyICantSelectSeason.step3')}</STTypo>
          </div>
          <div>
            <STTypo type={TypeOptions.P1}>{t('report.whyICantSelectSeason.step4')}</STTypo>
          </div>
        </details>
      )}
    </>
  );
};

export default SelectSeason;
