import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PROTECTOR_API_URL } from 'config/constants';
import type { UUID } from 'core/utils/basic.models';
import type { MethodologySeasonArea } from './methodology-deep.models';

const protectorApiUrl = PROTECTOR_API_URL;

export const getMethodologyOnBase = (methodologyId: UUID): AxiosObservable<MethodologySeasonArea> => {
  return axios.get<MethodologySeasonArea>(`${protectorApiUrl}/v1/methodologies/${methodologyId}`);
};
