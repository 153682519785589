import type { TreeNode } from 'antd/lib/tree-select';
import { useLocalStorage } from 'beautiful-react-hooks';
import { getLicensingStatusApps, getRegionEntitiesInEntities } from 'core/core.selectors';
import { selectRootRegion } from 'entities/region/region.reducer';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formatRegionsToTreeNode } from './notes.functions';
import type { UserFiltersProps } from './notes.model';
import { TAGS_ENTITLEMENT_PREFIX, TAGS_KEY_TRANSLATION_PREFIX } from './notes.model';

export const useParsedRegionsTreeNode = (): TreeNode[] => {
  const regionsDictionary = useSelector(getRegionEntitiesInEntities);
  const rootRegion = useSelector(selectRootRegion);

  if (!rootRegion || !regionsDictionary) return [];

  return formatRegionsToTreeNode(rootRegion, regionsDictionary, 'id');
};

export const useValidateEntitlementsToTags = (): string[] => {
  const apps = useSelector(getLicensingStatusApps);

  return useMemo(() => {
    const availableTagsList = new Set<string>();
    apps?.forEach(item => {
      item.plan.entitlements.forEach(entitlement => {
        if (!(entitlement as any).deleted && entitlement.key.includes(TAGS_ENTITLEMENT_PREFIX)) {
          availableTagsList.add(entitlement.name);
        }
      });
    });

    return [...availableTagsList];
  }, [apps]);
};

export const useParseTagsMessages = (): Record<string, string> => {
  const [t, i18n] = useTranslation();

  const resourceBundle = i18n.getResourceBundle('en', 'translation');

  return useMemo(() => {
    if (!resourceBundle || isEmpty(resourceBundle)) return {};

    const tagsTranslatedDictionary: Record<string, string> = {};

    Object.entries(resourceBundle).forEach(([key, value]) => {
      if (key.includes(TAGS_KEY_TRANSLATION_PREFIX)) {
        tagsTranslatedDictionary[key] = t(key, { default: value });
      }
    });

    return tagsTranslatedDictionary;
  }, [resourceBundle, t]);
};

const useFilterTagsByEntitlements = (): string[][] => {
  const availableEntitlementsToTags = useValidateEntitlementsToTags();
  const tagsTranslations = useParseTagsMessages();

  return useMemo(() => {
    const filteredTags: string[][] = [];
    Object.entries(tagsTranslations).forEach(([key, value]) => {
      availableEntitlementsToTags.forEach(entitlement => {
        if (key.includes(entitlement)) {
          filteredTags.push([key, value]);
        }
      });
    });

    return filteredTags;
  }, [availableEntitlementsToTags, tagsTranslations]);
};

export const useParsedTagsTreeNode = (): TreeNode[] => {
  const tagsMessages = useFilterTagsByEntitlements();

  return tagsMessages.map(([key, title]) => ({
    key,
    value: key,
    title,
    isLeaf: true
  }));
};

const useGetUserIdByToken = (): string => {
  const accessToken = localStorage.getItem('access_token');
  return accessToken && JSON.parse(window.atob(accessToken.split('.')[1])).sub;
};

export const useSaveFiltersInLocalStorage = (): {
  filters?: UserFiltersProps[string][string];
  setFilters: (value: UserFiltersProps[string][string]) => void;
} => {
  const { propertyId } = useParams() as { propertyId: string };
  const userId = useGetUserIdByToken();

  const [filters, setFilters] = useLocalStorage<UserFiltersProps>('filters', {});

  const setFiltersWithUserId = (value: UserFiltersProps[string][string]) => {
    setFilters({ ...filters, [userId]: { ...filters[userId], [propertyId]: value } });
  };

  return {
    filters: filters?.[userId]?.[propertyId],
    setFilters: setFiltersWithUserId
  };
};
