import type { EntityAdapter, EntityState } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { MonitoringActionsTypes } from './monitoring.actions';
import type { Monitoring } from './monitoring.models';

function selectMonitoringId(monitoring: Monitoring): string {
  return monitoring.id;
}

const entity: EntityAdapter<Monitoring> = createEntityAdapter<Monitoring>({
  selectId: selectMonitoringId
});

export interface MonitoringState extends EntityState<Monitoring> {
  isLoading: boolean;
  error?: string;
}
const initialState: MonitoringState = entity.getInitialState({
  isLoading: false
});

export default (state = initialState, action): MonitoringState => {
  switch (action.type) {
    case MonitoringActionsTypes.LOAD_AREA_DAY_MONITORING:
      return entity.removeAll({
        ...state,
        isLoading: true
      });
    case MonitoringActionsTypes.LOAD_AREA_DAY_MONITORING_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        isLoading: false
      });
    case MonitoringActionsTypes.LOAD_AREA_DAY_MONITORING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const { selectAll } = entity.getSelectors();
export const selectAllMonitorings = selectAll;
