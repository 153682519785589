import { Popover, Skeleton, Tooltip } from 'antd';
import { usePreviousValue } from 'beautiful-react-hooks';
import { COLOR_OPTIONS } from 'config/style';
import type { Dictionary } from 'config/types';
import { SetCurrentProperty, SetCurrentSeasons } from 'core/core.actions';
import type { SelectEntityType } from 'core/core.models';
import { selectPropertiesFromCurrentCompany } from 'core/core.reducer';
import { selectHasDiseaseRiskLandingPage } from 'core/core.selectors';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import type { Company } from 'entities/company/company.models';
import type { Property } from 'entities/property/property.models';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { ClearReports } from 'entities/reports/report.actions';
import { getActiveSeasonsIds } from 'entities/season/season.functions';
import { find } from 'lodash';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { generatePath, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import type { AppState } from 'redux/app-state';
import type { IBreadcrumbPopoverItems } from './breadcrumb-popover';
import STBreadcrumbPopover from './breadcrumb-popover';
import { skeletonParagraph, skeletonTitle } from './constants';

interface ISTPropertyPopoverProps {
  // Redux State
  selectedPropertyId: UUID | null;
  property?: Property;
  properties: Property[];
  isLoading: boolean;
  // Redux Actions
  SetCurrentProperty$: SelectEntityType;
  SetCurrentSeasons$: SelectEntityType;
  ClearReports$: () => void;
  isEmbedded?: boolean;
}

const STBreadcrumbProperty: React.FC<ISTPropertyPopoverProps> = ({
  SetCurrentProperty$,
  selectedPropertyId,
  SetCurrentSeasons$,
  ClearReports$,
  properties,
  isEmbedded,
  isLoading,
  property
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const matchProperty = useMatch({ path: '/company/:companyId/property/:propertyId', end: false });
  const matchHallOfProperties = useMatch({ path: '/company/:companyId', end: true });
  const [isOpen, setIsOpen] = useState(false);
  const [propertyLinks, setPropertyLinks] = useState<IBreadcrumbPopoverItems[]>([]);
  const [currentRedirectPath, setCurrentRedirectPath] = useState<string>();
  const lastPropertyLinks = usePreviousValue(propertyLinks);
  const companies = useSelector<AppState, Dictionary<Company>>(state => state.entities.company.entities);
  const hasDiseaseRiskLandingPage = useSelector(selectHasDiseaseRiskLandingPage);
  const landingPage = useMemo(() => {
    return hasDiseaseRiskLandingPage ? 'disease-risk' : 'timeline';
  }, [hasDiseaseRiskLandingPage]);

  const segmentTracking = useSegmentTracking();

  const footerClick = () => {
    ClearReports$();
    SetCurrentProperty$(null);
    navigate(`/company/${properties[0]?.company_id}`);
    setIsOpen(false);

    const payload: AnalyticsInfo = {
      companyId: properties[0]?.company_id || '',
      companyName: companies[properties[0]?.company_id]?.name || '',
      propertyId: property?.id || '',
      propertyName: property?.name || ''
    };
    segmentTracking.track('Property Selector - Tapped to go to Hall of Properties', payload);
  };

  const itemClick = clickedPropertyId => {
    if (matchProperty?.params.propertyId === clickedPropertyId) {
      setIsOpen(false);
      return;
    }

    const payload: AnalyticsInfo = {
      companyId: properties[0]?.company_id || '',
      companyName: companies[properties[0]?.company_id]?.name || '',
      propertyId: property?.id || '',
      propertyName: property?.name || ''
    };
    segmentTracking.track('Property Selector - Changed Property', payload);

    const clickedProperty = find(properties, ['id', clickedPropertyId]);
    SetCurrentSeasons$(getActiveSeasonsIds(clickedProperty?.seasons));
    SetCurrentProperty$(clickedPropertyId);
    ClearReports$();
    setIsOpen(false);
  };

  const handleIsOpen = visible => {
    const payload: AnalyticsInfo = {
      companyId: properties[0]?.company_id || '',
      companyName: companies[properties[0]?.company_id]?.name || '',
      propertyId: property?.id || '',
      propertyName: property?.name || ''
    };
    if (visible) {
      segmentTracking.track('Property Selector - Tapped to see options', payload);
    } else {
      segmentTracking.track('Property Selector - Tapped to hide options', payload);
    }
    setIsOpen(!!properties.length && visible);
  };

  useEffect(() => {
    const redirectPath = matchProperty ? location.pathname.split('/').slice(5).join('/') : landingPage;
    if (redirectPath !== currentRedirectPath) {
      setCurrentRedirectPath(redirectPath);
    }
  }, [matchProperty, location, currentRedirectPath, landingPage]);

  useEffect(() => {
    if (!properties.length || (propertyLinks.length && propertyLinks !== lastPropertyLinks)) return;

    setPropertyLinks(() => {
      let path;
      return properties.map(p => {
        if (currentRedirectPath === undefined) {
          path = generatePath(`/company/:companyId/property/:propertyId/${landingPage}`, {
            companyId: properties[0]?.company_id,
            propertyId: p.id
          });
        } else {
          path = generatePath(`/company/:companyId/property/:propertyId/${currentRedirectPath}`, {
            companyId: properties[0]?.company_id,
            propertyId: p.id
          });
        }
        return {
          id: p.id,
          name: p.name,
          path
        };
      });
    });
  }, [properties, currentRedirectPath, propertyLinks, lastPropertyLinks, landingPage]);

  useEffect(() => {
    if (matchProperty?.params?.propertyId && matchProperty.params.propertyId !== selectedPropertyId) {
      SetCurrentProperty$(matchProperty.params.propertyId);
    }
  }, [matchProperty, selectedPropertyId, SetCurrentProperty$]);

  const showPopoverFooter = useMemo(() => {
    if (isEmbedded) return false;

    return !matchHallOfProperties;
  }, [isEmbedded, matchHallOfProperties]);

  return (
    <Skeleton title={skeletonTitle} paragraph={skeletonParagraph} active loading={isLoading}>
      <Popover
        placement='bottomLeft'
        content={
          <STBreadcrumbPopover
            selected={matchProperty?.params.propertyId || undefined}
            items={propertyLinks}
            showFooter={showPopoverFooter}
            header={t('components.breadcrumb.properties')}
            footer={t('components.breadcrumb.go_to_property_hall')}
            footerClick={footerClick}
            itemClick={itemClick}
          />
        }
        visible={isOpen}
        onVisibleChange={handleIsOpen}
        trigger='click'>
        <Tooltip title={property?.name}>
          <div className='sd-header__breadcrumb__item'>
            <div className='st-breadcrumb__text'>
              <div className='sd-header__breadcrumb__item__title'>
                <STTypo type={TypeOptions.C1} color={COLOR_OPTIONS.SECONDARY}>
                  {t('components.breadcrumb.property_title')}
                </STTypo>
              </div>
              <div className='sd-header__breadcrumb__item__name'>
                <STTypo fontWeight={600} maxlength={100}>
                  {(!!properties.length && property?.name) || t('components.breadcrumb.all_properties')}
                </STTypo>
                <i className='sf sf-chevron-down' />
              </div>
            </div>
          </div>
        </Tooltip>
      </Popover>
    </Skeleton>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoading: state.entities.property.isLoading,
  property: getSelectedProperty(state),
  selectedPropertyId: state.uiState.global.selectedProperty,
  properties: selectPropertiesFromCurrentCompany(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      SetCurrentProperty$: SetCurrentProperty,
      SetCurrentSeasons$: SetCurrentSeasons,
      ClearReports$: ClearReports
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(STBreadcrumbProperty);
