import { Skeleton } from 'antd';
import { images } from 'assets';
import { GALLERY_PREFIX_ID, ProtectorPhotoswipe } from 'components/protector-photoswipe/protector-photoswipe';
import { COLOR_OPTIONS } from 'config/style';
import 'core/shared/map/components/marker.popup.styles.less';
import STTypo, { STTypoModels } from 'core/shared/typo';
import { checkImageExists } from 'core/utils/functions';
import type { Popup } from 'mapbox-gl';
import { useConfigToNotesModalGallery } from 'pages/timeline/area-day-info/components/area-day-info-events-annotations/area-day-info-events-annotations.hooks';
import type { AnnotationStage } from 'pages/timeline/timeline.models';
import type React from 'react';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import SvgCloseGlyph from 'st-protector-icons/Glyph/CloseGlyph';
import SvgFullscreenArrow from 'st-protector-icons/Minor/FullscreenArrow';
import PhotoLibrary from 'st-protector-icons/Minor/PhotoLibrary';
import { Gallery, Modal, Provider } from 'syngenta-digital-react-cropwise-notes';

export interface SelectCurrentBadgeProps {
  photos?: string[];
  image?: string;
  imageLoadedSuccess?: boolean;
  openImageModal: (event: any) => void;
}

export const SelectCurrentBadge: React.FC<SelectCurrentBadgeProps> = ({ photos, image, imageLoadedSuccess, openImageModal }) => {
  if (photos && photos.length > 1 && imageLoadedSuccess) {
    return (
      <div
        data-testid='st-popup-header-multiple-image-fullscreen'
        className='st-popup__header__multiple-image-fullscreen'
        onClick={openImageModal}
        onKeyDown={openImageModal}>
        <PhotoLibrary fill={COLOR_OPTIONS.NEUTRAL_100} />
        <STTypo type={STTypoModels.TypeOptions.P3} color={COLOR_OPTIONS.NEUTRAL_100} uppercase fontWeight={500}>
          {`${photos?.length} photos`}
        </STTypo>
      </div>
    );
  }

  if (image && imageLoadedSuccess) {
    return (
      <div
        role='button'
        tabIndex={0}
        data-testid='st-popup-header-single-image-fullscreen'
        className='st-popup__header__image-fullscreen'
        onClick={openImageModal}
        onKeyDown={openImageModal}>
        <SvgFullscreenArrow fill='#ffffff' />
      </div>
    );
  }

  return null;
};

export interface ImagePhotosViewerProps {
  photos: string[];
  imageRef: React.RefObject<HTMLAnchorElement>;
}

const GALLERY_ID = 'marker-popup';

export const ImagePhotosViewer: React.FC<ImagePhotosViewerProps> = ({ photos, imageRef }) => {
  if (!photos.length) return null;

  return <ProtectorPhotoswipe galleryId={GALLERY_ID} photos={photos} testId='photoswipe' imageRef={imageRef} />;
};

export interface ISDMarkerPopup {
  setSelectedPointId?: (value?) => void;
  mapboxPopup: Popup;
  productsAvailable?: string;
  isGalleryV2?: boolean;
  segmentTracking?: any;
  closeButton: boolean;
  isLoading: boolean;
  header?: ReactNode;
  fieldName?: string;
  photos?: string[];
  subTitle?: string;
  image?: string;
  title: string;
}

const SDMarkerPopup: React.FC<ISDMarkerPopup> = ({
  setSelectedPointId,
  closeButton = true,
  productsAvailable,
  segmentTracking,
  mapboxPopup,
  isGalleryV2,
  fieldName,
  isLoading,
  subTitle,
  children,
  header,
  photos,
  title,
  image,
  ...props
}) => {
  const imageRef = useRef<HTMLAnchorElement>(null);
  const modalRef = useRef(null);
  const annotation = props as AnnotationStage;

  const { tagsTranslations, notesMessages, formattedNote, galleryEvents, productName } = useConfigToNotesModalGallery({
    productsAvailable,
    segmentTracking,
    isGalleryV2,
    annotation
  });

  const [backgroundImage, setBackgroundImage] = useState(images.fallbackimage);
  const [imageLoadedSuccess, setImageLoadedSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (image) {
      checkImageExists(image, () => {
        setBackgroundImage(image);
        setImageLoadedSuccess(true);
      });
    }
  }, [image]);

  const openImageModal = () => {
    if (isGalleryV2) {
      (modalRef?.current as any)?.open();
    } else {
      imageRef.current?.click();
    }
  };

  const closePopUp = () => {
    if (setSelectedPointId) setSelectedPointId();
    mapboxPopup.remove();
  };

  const allPhotos = [...new Set([...(photos || []), ...(image ? [image] : [])])];

  return (
    <Provider theme='light'>
      <div className='st-popup' data-testid='sd-marker-popup'>
        <div className='st-popup__container'>
          {isLoading ? (
            <Skeleton className='st-popup__skeleton' active avatar={{ size: 'default' }} title />
          ) : (
            <div className='st-popup__layout'>
              <div className='st-popup__header'>
                <div
                  className='st-popup__header__container'
                  id={`${GALLERY_PREFIX_ID}${GALLERY_ID}`}
                  style={{ backgroundImage: `url(${backgroundImage})` }}>
                  {isGalleryV2 ? (
                    <Modal ref={modalRef}>
                      <Gallery
                        close={() => (modalRef?.current as any)?.close()}
                        tagsTranslations={tagsTranslations}
                        galleryEvents={galleryEvents}
                        messages={notesMessages}
                        product={productName}
                        fieldName={fieldName}
                        note={formattedNote}
                      />
                    </Modal>
                  ) : (
                    <ImagePhotosViewer imageRef={imageRef} photos={allPhotos} />
                  )}

                  {header}
                  <div className='st-popup__header__title'>
                    <STTypo color='#ffffff' fontWeight={500} type={STTypoModels.TypeOptions.H5} maxlength={28}>
                      {title}
                    </STTypo>
                    {subTitle && (
                      <STTypo color='#ffffff' type={STTypoModels.TypeOptions.P2} maxlength={28} italic>
                        {subTitle}
                      </STTypo>
                    )}
                  </div>

                  <SelectCurrentBadge
                    imageLoadedSuccess={imageLoadedSuccess}
                    openImageModal={openImageModal}
                    photos={allPhotos}
                    image={image}
                  />
                </div>
              </div>
              {closeButton && (
                <button
                  data-testid='sd-marker-popup-close-button'
                  type='button'
                  className='st-popup__header__close-button'
                  onClick={() => closePopUp()}>
                  <SvgCloseGlyph fill='#ffffff' />
                </button>
              )}
              <div className='st-popup__body'>{children}</div>
            </div>
          )}
        </div>
      </div>
    </Provider>
  );
};

export default SDMarkerPopup;
