import type { Moment } from 'core/utils/basic.models';
import type { Season } from 'entities/season/season.models';
import moment from 'moment';
import { useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import { ReportsTypes } from '../reports-types.enum';

export const reportTranslationKey: Record<ReportsTypes, string> = {
  [ReportsTypes.PROPERTY]: 'property',
  [ReportsTypes.SAMPLE]: 'sample',
  [ReportsTypes.SAMPLING]: 'sample_points',
  [ReportsTypes.SAMPLING_IMPORT]: 'sampling',
  [ReportsTypes.SCOUTING]: 'scouting',
  [ReportsTypes.SPRAY]: 'spray',
  [ReportsTypes.TRACKING]: 'tracking',
  [ReportsTypes.TRAP]: 'trap',
  [ReportsTypes.WAREHOUSE_INVENTORY]: 'warehouse',
  [ReportsTypes.MONITORING_DETAILED]: 'report_detailed',
  [ReportsTypes.MONITORING_CONSOLIDATED]: 'report_consolidated',
  [ReportsTypes.RAIN_GAUGE_REPORT]: 'rain_gauge',
  [ReportsTypes.VISIT]: 'visit',
  [ReportsTypes.DISEASE_RISK]: 'disease_risk',
  [ReportsTypes.CONTROL_STRATEGIES]: 'control_strategy',
  [ReportsTypes.BLOCKED_FIELDS]: 'blocked_fields',
  [ReportsTypes.MONITORING]: 'monitoring',
  [ReportsTypes.APPLICATION_STATISTICS]: 'application_statistics'
};

export const calculateDate = (season: Season[], timeFrom: moment.Moment, timeTo: moment.Moment): boolean => {
  const dateStart = new Date(season[0]?.start_date.toString());
  const dateEnd = new Date(season[0]?.end_date.toString());
  const timeRangeFromConvert = new Date(timeFrom.toDate().toString());
  const timeRangeToConvert = new Date(timeTo.toDate().toString());
  return (
    (timeRangeFromConvert >= dateStart && timeRangeFromConvert < dateEnd) ||
    (timeRangeToConvert <= dateEnd && timeRangeToConvert > dateStart)
  );
};

export const disabledDate = (current: Moment | undefined) => {
  return current && current > moment().endOf('day');
};

export interface PropsIsConfirmButtonDisabled {
  selectedPhenomena: string[];
  filterBySeasonWithTimeRange: boolean;
  seasonSelect: Season[];
  timeRangeFrom: moment.Moment;
  timeRangeTo: moment.Moment;
  selectedAreas: string[];
  selectedSeason: string | undefined;
}

export const isConfirmButtonDisabled = ({
  selectedPhenomena,
  filterBySeasonWithTimeRange,
  seasonSelect,
  timeRangeFrom,
  timeRangeTo,
  selectedAreas,
  selectedSeason
}: PropsIsConfirmButtonDisabled): boolean => {
  const willDisableByPhenomena = !selectedPhenomena.length;
  if (filterBySeasonWithTimeRange) {
    const dateActiveButton = calculateDate(seasonSelect, timeRangeFrom, timeRangeTo);
    return !selectedAreas.length || !selectedSeason || willDisableByPhenomena || !dateActiveButton;
  }
  return !selectedAreas.length || !selectedSeason || willDisableByPhenomena;
};

export const useSeasonSelect = selectedSeason => {
  const allSeasons = useSelector((state: AppState) => state.entities.season.entities);
  return Object.values(allSeasons).filter(el => {
    if (el.id === selectedSeason) return el;
    return undefined;
  });
};
