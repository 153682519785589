const NewSvgReport = () => (
  <svg width='12' height='15' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.5 0.5H1.5C0.675 0.5 0 1.175 0 2V14C0 14.825 0.6675 15.5 1.4925 15.5H10.5C11.325 15.5 12 14.825 12 14V5L7.5 0.5ZM9 8H3V9.5H9V8ZM9 11H3V12.5H9V11ZM1.5 14H10.5V5.75H6.75V2H1.5V14Z'
      fill='#696F88'
    />
  </svg>
);

export default NewSvgReport;
