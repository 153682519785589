import STTypo, { STTypoModels } from 'core/shared/typo';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import SvgInfoTriangleGlyph from 'st-protector-icons/Glyph/InfoTriangleGlyph';
import './alert.styles.less';

const STAlertCrashState: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className='st-alert-content st-alert-state__empty'>
      <div className='st-alert-content__icon'>
        <div className='st-alert-content__icon-box'>
          <SvgInfoTriangleGlyph />
        </div>
      </div>
      <div className='st-alert-content__message'>
        <STTypo type={STTypoModels.TypeOptions.H5} fontWeight={500}>
          {t('alert.crash.title')}
        </STTypo>
        <STTypo type={STTypoModels.TypeOptions.P1}>{t('alert.crash.description')}</STTypo>
        <STTypo type={STTypoModels.TypeOptions.P1}>{t('alert.crash.try_again')}</STTypo>
      </div>
    </div>
  );
};

export default STAlertCrashState;
