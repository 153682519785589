const SVGMethodologyGlyph = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
      <mask id='a' width='16' height='16' x='0' y='0' maskUnits='userSpaceOnUse'>
        <path fill='#fff' d='M16 0H0v16h16V0Z' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#0071ce'
          fillRule='evenodd'
          d='M3.333 3.333h9.334v5.334H14V3.333C14 2.6 13.4 2 12.667 2H3.333C2.6 2 2 2.6 2 3.333v9.334C2 13.4 2.6 14 3.333 14h5.334v-1.333H3.333V3.333Zm1.334 8h4.666V10H4.667v1.333Zm6.666-2.666H4.667V7.333h6.666v1.334ZM4.667 6h6.666V4.667H4.667V6Zm11.277 7.792-.659-.498c.012-.096.025-.192.025-.294 0-.102-.007-.198-.025-.294l.653-.498a.153.153 0 0 0 .037-.192l-.616-1.038c-.037-.066-.117-.09-.19-.066l-.764.3a2.122 2.122 0 0 0-.524-.294l-.117-.792c-.006-.072-.074-.126-.147-.126h-1.232a.157.157 0 0 0-.154.126l-.117.792a2.478 2.478 0 0 0-.523.294l-.764-.3a.163.163 0 0 0-.19.066l-.616 1.038a.146.146 0 0 0 .037.192l.652.498a2.307 2.307 0 0 0 0 .588l-.652.498a.153.153 0 0 0-.037.192l.615 1.038c.037.066.118.09.191.066l.764-.3c.16.12.332.222.523.294l.117.792a.152.152 0 0 0 .154.126h1.232a.157.157 0 0 0 .153-.126l.117-.792a2.29 2.29 0 0 0 .518-.294l.77.3c.067.024.147 0 .19-.066l.616-1.038a.153.153 0 0 0-.037-.192ZM13 14c-.553 0-1-.447-1-1 0-.553.447-1 1-1 .553 0 1 .447 1 1 0 .553-.447 1-1 1Z'
          clipRule='evenodd'
        />
      </g>
    </svg>
  );
};

export default SVGMethodologyGlyph;
