import { getColor } from 'core/utils/generate-colors';
import type { Region } from 'entities/region/region.models';
import { forEach, map, size } from 'lodash';
import type { TeamInfo } from 'pages/post-harvest-map/post-harvest-map.models';
import type { Dictionary } from 'config/types';
import type { FieldCluster } from '../configuration/field-clustering-configuration.models';

export const parseFieldClusterToTeamsInfo = (
  insights: Record<string, FieldCluster>,
  regions: Dictionary<Region>
): { teamsInfo: TeamInfo[]; amountClustering: number; totalArea: string } => {
  const amountClustering = size(insights);
  let index = -1;
  let totalArea = 0;

  const teamsInfo = map(insights, (item: FieldCluster, key: string) => {
    return {
      id: key,
      name: key,
      color: getColor(++index),
      fieldsInfo: map(item.fields_ids, id => {
        totalArea += item.total_size;
        return {
          id,
          name: regions?.[id]?.name,
          totalArea: regions?.[id]?.total_area,
          regionTreePath: '',
          date: ''
        };
      })
    };
  });

  return { teamsInfo, amountClustering, totalArea: totalArea?.toFixed(2) };
};

export const parseAreasIdsToSeasonAreasIds = (areasIds: string[], regions: Dictionary<Region>): string[] => {
  if (!regions || !areasIds) return [];

  let seasonAreasIds: string[] = [];

  forEach(areasIds, areaId => {
    const seasonAreaId = regions?.[areaId]?.current_info?.season_area_id;

    if (seasonAreaId) {
      seasonAreasIds = [...seasonAreasIds, seasonAreaId];
    }
  });

  return seasonAreasIds;
};

export const mergeInsights = (
  previousInsights: Record<string, FieldCluster>,
  currentInsights: Record<string, FieldCluster>
): Record<string, FieldCluster> => {
  let mergedInsights = previousInsights;

  forEach(currentInsights, (fieldCluster, clusterId) => {
    const currentCluster = mergedInsights?.[clusterId];
    mergedInsights = {
      ...mergedInsights,
      [clusterId]: {
        fields_ids: [...(currentCluster?.fields_ids ?? []), ...(fieldCluster?.fields_ids ?? [])],
        total_size: (currentCluster?.total_size ?? 0) + (fieldCluster?.total_size ?? 0)
      }
    };
  });

  return mergedInsights;
};
