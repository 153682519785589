const SvgWeatherDailySunnyFewClouds = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <mask id='path-1-inside-1' fill='white'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 11.9725C12.25 11.7238 14 9.81628 14 7.5C14 5.01472 11.9853 3 9.5 3C7.54067 3 5.87381 4.25221 5.25606 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H9.5H10V11.9725Z'
      />
    </mask>
    <path
      d='M10 11.9725L9.83519 10.4816L8.5 10.6292V11.9725H10ZM5.25606 6V7.5H6.31682L6.67032 6.49987L5.25606 6ZM10 12V13.5H11.5V12H10ZM12.5 7.5C12.5 9.04318 11.3336 10.316 9.83519 10.4816L10.1648 13.4635C13.1664 13.1316 15.5 10.5894 15.5 7.5H12.5ZM9.5 4.5C11.1569 4.5 12.5 5.84315 12.5 7.5H15.5C15.5 4.18629 12.8137 1.5 9.5 1.5V4.5ZM6.67032 6.49987C7.08287 5.33265 8.19612 4.5 9.5 4.5V1.5C6.88522 1.5 4.66476 3.17177 3.8418 5.50013L6.67032 6.49987ZM5 7.5H5.25606V4.5H5V7.5ZM3.5 9C3.5 8.17157 4.17157 7.5 5 7.5V4.5C2.51472 4.5 0.5 6.51472 0.5 9H3.5ZM5 10.5C4.17157 10.5 3.5 9.82843 3.5 9H0.5C0.5 11.4853 2.51472 13.5 5 13.5V10.5ZM9.5 10.5H5V13.5H9.5V10.5ZM10 10.5H9.5V13.5H10V10.5ZM8.5 11.9725V12H11.5V11.9725H8.5Z'
      fill='#C2C7D0'
      mask='url(#path-1-inside-1)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.8996 7H14.0996V9.41875C13.4786 9.54009 12.9017 9.78459 12.3953 10.1258L10.6864 8.41691L9.41365 9.6897L11.1233 11.3994C10.7834 11.9054 10.5399 12.4815 10.4192 13.1016H8V14.9016H10.4203C10.5419 15.5217 10.7863 16.0979 11.1271 16.6036L9.41373 18.3171L10.6865 19.5898L12.401 17.8754C12.906 18.2146 13.4809 18.4578 14.0996 18.5786V21H15.8996V18.5787C16.5186 18.4577 17.0938 18.2145 17.599 17.875L19.3131 19.5892L20.5859 18.3164L18.8726 16.6031C19.2133 16.0974 19.4575 15.5215 19.5791 14.9016L22 14.9016V13.1016H19.5802C19.4595 12.4818 19.2161 11.9058 18.8764 11.4L20.586 9.69035L19.3132 8.41756L17.6046 10.1262C17.0981 9.78475 16.5209 9.54011 15.8996 9.41873V7ZM18.1663 13.9987C18.1663 15.7476 16.7486 17.1654 14.9997 17.1654C13.2508 17.1654 11.833 15.7476 11.833 13.9987C11.833 12.2498 13.2508 10.832 14.9997 10.832C16.7486 10.832 18.1663 12.2498 18.1663 13.9987Z'
      fill='#F0C355'
    />
  </svg>
);

export default SvgWeatherDailySunnyFewClouds;
