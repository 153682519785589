import type { QueryObserverResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { CORE_SERVICES_API_URL } from 'config/constants';
import { getSelectedPropertyId, getSystemFlags } from 'core/core.selectors';
import { selectCurrentUser, selectServiceAccount } from 'core/services/auth/auth.reducer';
import { getCurrentWorkspaceId, getSelectedCompanyId } from 'entities/company/company.selector';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  createOperation,
  enableVerifyRequest,
  formatAllRolesByUser,
  parseWorkspaceOperations,
  setUpResourceByLevel
} from './rbac.functions';
import type {
  GetPermissionUserProps,
  RBACActions,
  RBACAvailableForUserProps,
  RBACDefaultRequestProps,
  RBACGetWorkspaceOperationsProps,
  RBACGetWorkspaceOperationsResponse,
  RBACLevels,
  RBACOperations,
  RBACParsedOperations,
  RBACSetUpOperationsForRequestProps,
  RBACWorkspaceOperations
} from './rbac.query.model';
import { RBACPermissionTypesEnum, RBACQueryType } from './rbac.query.model';
import { getAllRolesByUser } from './rbac.service';

const coreServicesApiUrl = CORE_SERVICES_API_URL;
const TEN_MINUTES = 1000 * 60 * 10;

export const useGetAllRolesByUser = () => {
  const user = useSelector(selectCurrentUser);

  const { data, isError, isLoading } = useQuery({
    queryKey: [RBACQueryType.GET_ALL_ROLES_BY_USER, user?.id],
    queryFn: async () => {
      const response = await getAllRolesByUser(user?.id);

      return formatAllRolesByUser(response.content);
    },
    staleTime: TEN_MINUTES,
    enabled: !isEmpty(user) && !!user?.id
  });

  return {
    isLoading,
    isError,
    data
  };
};

export const useUserHasSupportAgentRoleInTheSystemContext = () => {
  const { data } = useGetAllRolesByUser();

  return !!data?.system?.SupportAgent?.resource;
};

export const getWorkspaceOperations = async ({
  operations
}: RBACGetWorkspaceOperationsProps): Promise<AxiosResponse<RBACGetWorkspaceOperationsResponse>> => {
  const url = `${coreServicesApiUrl}/v2/auth/verify`;

  return axios.post<RBACGetWorkspaceOperationsResponse>(url, {
    operations
  });
};

export const useGetRBACOperationsFullUser = (isLogged: boolean) => {
  return useQuery(
    [RBACQueryType.GET_WORKSPACE_OPERATIONS, isLogged],
    async () => {
      const response = await getWorkspaceOperations({ operations: [{ action: '*', resource: 'crn:system' }] });

      return parseWorkspaceOperations(response?.data);
    },
    {
      staleTime: TEN_MINUTES,
      enabled: isLogged
    }
  );
};

export const useGetRBACOperations = ({
  operations,
  isEnabled = false,
  rbacLevels
}: RBACSetUpOperationsForRequestProps & {
  isEnabled: boolean;
  rbacLevels?: RBACLevels;
}): QueryObserverResult<RBACParsedOperations, unknown> => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const companyId = useSelector(getSelectedCompanyId);
  const propertyId = useSelector(getSelectedPropertyId);

  return useQuery(
    [RBACQueryType.GET_WORKSPACE_OPERATIONS, operations, workspaceId, companyId],
    async () => {
      const response = await getWorkspaceOperations({ operations });

      return parseWorkspaceOperations(response?.data);
    },
    {
      staleTime: TEN_MINUTES,
      enabled: enableVerifyRequest({ companyId, propertyId, workspaceId, rbacLevels, operations, isEnabled })
    }
  );
};

export const useVerifyRBACPermissions = ({
  workspaceId,
  companyId,
  propertyId,
  appId,
  isLoading,
  isEnabled = false
}: RBACDefaultRequestProps & { isEnabled: boolean; isLoading: boolean }) => {
  const verifyPermission = useCallback(
    (operations: RBACWorkspaceOperations, level: RBACLevels, action: RBACActions, permissions: RBACPermissionTypesEnum[]) => {
      if (isLoading) return undefined;

      if (!isEnabled) {
        return [];
      }

      const resourceByLevel = setUpResourceByLevel({
        level,
        workspaceId,
        companyId,
        propertyId,
        appId
      });

      if (!operations?.[resourceByLevel]?.[action]?.length) return [];

      return permissions.filter(permission => {
        return operations[resourceByLevel][action].includes(permission);
      });
    },
    [appId, companyId, propertyId, workspaceId, isEnabled, isLoading]
  );

  return {
    verifyPermission
  };
};

export const useValidateRBACAvailableForUser = (): RBACAvailableForUserProps => {
  const user = useSelector(selectCurrentUser);
  const systemFlags = useSelector(getSystemFlags);
  const availableRBAC = systemFlags?.P40_29585_integrate_web_panel_with_RBAC;

  const verifyRBACAvailableForUser = useCallback(() => {
    if (!user || isEmpty(user) || !user?.is_using_rbac || !availableRBAC) return false;

    return !!(user.is_using_rbac && availableRBAC);
  }, [availableRBAC, user]);

  return {
    verifyRBACAvailableForUser
  };
};

export const useGetRBACAuthorityUser = ({
  rbacActions,
  rbacLevels,
  rbacPermission,
  invalidCallback
}: GetPermissionUserProps): RBACPermissionTypesEnum[] | undefined => {
  const { verifyRBACAvailableForUser } = useValidateRBACAvailableForUser();
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const propertyId = useSelector(getSelectedPropertyId);
  const companyId = useSelector(getSelectedCompanyId);
  const isUserAccountService = useSelector(selectServiceAccount);

  const operations =
    !rbacLevels || !rbacActions || !rbacPermission
      ? []
      : rbacPermission?.reduce((acc: RBACOperations[], permission: RBACPermissionTypesEnum) => {
          if (isUserAccountService && permission === RBACPermissionTypesEnum.NOT_READ) {
            return acc;
          }

          acc.push(
            createOperation({
              companyId,
              action: rbacActions,
              level: rbacLevels,
              workspaceId,
              propertyId,
              permission
            })
          );

          return acc;
        }, []);

  const { data, isLoading, isError } = useGetRBACOperations({
    operations,
    isEnabled: verifyRBACAvailableForUser(),
    rbacLevels
  });

  const { verifyPermission } = useVerifyRBACPermissions({
    workspaceId,
    companyId,
    propertyId,
    isLoading,
    isEnabled: verifyRBACAvailableForUser()
  });

  if (isEmpty(data?.allowed_operations) && !isLoading && invalidCallback) {
    invalidCallback();
  }

  if (verifyRBACAvailableForUser() && data?.allowed_operations && rbacLevels && rbacActions && rbacPermission) {
    if (!isLoading && !isError) {
      return verifyPermission(data.allowed_operations, rbacLevels, rbacActions, rbacPermission);
    }
  }
};
