const SvgWeatherRainLight = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M6 13.2C3.5 13.2 1.5 11.2 1.5 8.7C1.5 6.3 3.4 4.3 5.9 4.2C6.9 2.5 8.6 1.5 10.6 1.5C13.6 1.5 16 3.9 16 6.9L15.9 8.3L14.1 8.2L14.2 6.8C14.2 4.8 12.6 3.2 10.5 3.2C9 3.2 7.7 4.1 7.1 5.4L6.9 6H6.2C6.1 6 6.1 6 6 6H5.9C4.4 6 3.1 7.2 3.1 8.8C3.1 10.3 4.3 11.6 5.9 11.6L6 13.2Z'
      fill='#BDC4D1'
    />
    <path
      d='M9.8 21.7V21.3C9.8 20.8029 9.39706 20.4 8.9 20.4C8.40294 20.4 8 20.8029 8 21.3V21.7C8 22.1971 8.40294 22.6 8.9 22.6C9.39706 22.6 9.8 22.1971 9.8 21.7Z'
      fill='#285CC3'
    />
    <path
      d='M14.7 21.7V21.3C14.7 20.8029 14.2971 20.4 13.8 20.4C13.3029 20.4 12.9 20.8029 12.9 21.3V21.7C12.9 22.1971 13.3029 22.6 13.8 22.6C14.2971 22.6 14.7 22.1971 14.7 21.7Z'
      fill='#285CC3'
    />
    <path
      d='M17.2 19.2H6C3.4 19.2 1.8 17.8 1.8 15.6C1.8 13.6 3.2 12 5.2 11.7C5.6 8.5 8.3 6 11.6 6C14 6 16.1 7.3 17.3 9.4C20.5 9.4 22.6 11.2 22.6 13.9C22.6 16.8 20.2 19.2 17.2 19.2ZM6 13.3C4.5 13.3 3.5 14.2 3.5 15.5C3.5 17.2 5.2 17.4 6 17.4H17.2C19.2 17.4 20.8 15.8 20.8 13.8C20.8 11.4 18.3 11.1 17.2 11.1C17.2 11.1 17.1 11.1 17 11.1C16.9 11.1 16.9 11.1 16.8 11.1L16.2 11.2L16 10.6C15.3 8.9 13.6 7.7 11.7 7.7C9.1 7.7 7 9.8 7 12.4V13.3H6Z'
      fill='#828B99'
    />
  </svg>
);

export default SvgWeatherRainLight;
