import { PRODUCT_PAGE } from 'config/constants';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import type { Action, UUID, V4Page } from '../../core/utils/basic.models';
import { ProductType } from '../../core/utils/basic.models';
import {
  CropActionsTypes,
  LoadCropFailure,
  LoadCropSuccess,
  LoadCropVarietiesPaginatedFailure,
  LoadCropVarietiesPaginatedSuccess,
  LoadCropsByIdsFailure,
  LoadCropsByIdsSuccess
} from './crop.actions';
import type { Crop, CropPage, LoadCropVarietiesRequestType, Variety } from './crop.models';
import { getAllVarietiesPaginated, getCrop, getCropsByIds, getVarietiesByCompanyIdPaged } from './crop.service';

export const handleLoadCrop = action$ =>
  action$.pipe(
    ofType(CropActionsTypes.LOAD_CROP),
    concatMap(() =>
      getCrop().pipe(
        map(response => response.data),
        map((cropPage: CropPage) => {
          return LoadCropSuccess(cropPage);
        }),
        catchError((error: string) => of(LoadCropFailure(error)))
      )
    )
  );

export const handleLoadCropVarieties = action$ =>
  action$.pipe(
    ofType(CropActionsTypes.LOAD_CROP_VARIETIES),
    map((action: Action<LoadCropVarietiesRequestType[]>) => action.payload),
    concatMap(request => {
      const { cropId, companyId } = request;

      const PRODUCT_PAGE_SIZE = parseInt(PRODUCT_PAGE, 10) || 1000;
      return getVarietiesByCompanyIdPaged(companyId, cropId, 0, ProductType.VARIETY, PRODUCT_PAGE_SIZE).pipe(
        concatMap((firstPage: V4Page<Variety>) => {
          if (firstPage?.last || firstPage?.totalPages === 0) {
            const parsedCrop: Crop = {
              id: cropId,
              company_id: companyId,
              varieties: firstPage.content
            };
            return of(LoadCropVarietiesPaginatedSuccess([parsedCrop]));
          }

          return getAllVarietiesPaginated(
            companyId,
            cropId,
            firstPage?.totalPages || firstPage.total_pages,
            ProductType.VARIETY,
            PRODUCT_PAGE_SIZE,
            true
          ).pipe(
            map(response => {
              const parsedCrop: Crop = {
                id: cropId,
                company_id: companyId,
                varieties: firstPage.content.concat(response.flatMap(item => item.content))
              };

              return LoadCropVarietiesPaginatedSuccess([parsedCrop]);
            }),
            catchError((error: string) => of(LoadCropVarietiesPaginatedFailure(error)))
          );
        })
      );
    })
  );

export const handleLoadCropByIds = action$ =>
  action$.pipe(
    ofType(CropActionsTypes.LOAD_CROPS_BY_IDS),
    map((action: Action<Crop[]>) => action.payload),
    concatMap((cropIDs: UUID[]) =>
      getCropsByIds(cropIDs).pipe(
        map(response => {
          return LoadCropsByIdsSuccess(response);
        })
      )
    ),
    catchError(err => of(LoadCropsByIdsFailure(err)))
  );
