import type { Action } from 'core/utils/basic.models';
import { generateUUID } from 'core/utils/functions';
import moment from 'moment';
import { calculateKpisRates } from 'pages/dashboard-farm/widget-monitoring-kpi/widget-monitoring-kpi.functions';
import type { ProductTypesModel } from 'pages/dashboard-farm/widget-product-types/widget-product-types.models';
import { combineEpics, ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators';
import type { AbandonedAreasModel } from '../../../../pages/dashboard-farm/widget-abandoned-areas/widget-abandoned-areas.models';
import type { HeatmapOverTimeModel } from '../../../../pages/dashboard-farm/widget-heatmap-over-time/widget-heatmap-over-time.models';
import type { IndividualScouterModel } from '../../../../pages/dashboard-farm/widget-individual-scouter/widget-individual-scouter.models';
import type { MonitoringModel } from '../../../../pages/dashboard/widget-monitoring/widget-monitoring.models';
import { selectSelectedSeasons } from '../../../core.reducer';
import {
  LoadFailure,
  LoadSettingsFailure,
  LoadSettingsSuccess,
  LoadSuccess,
  PostSettingsFailure,
  PostSettingsSuccess,
  WidgetsActionsTypes
} from './widgets.actions';
import type { WidgetRequest } from './widgets.models';
import { EWidgets, WidgetType } from './widgets.models';
import { loadSettingsMock, loadWidget, postSettingsMock } from './widgets.service';

const mapper = {
  scouter_distance_time_avg: 'SCOUTER_STATS',
  tasks_list: 'PROPERTY_TASKS',
  abandoned_areas: 'PROPERTY_ABANDONED_AREAS',
  heatmap_over_time: 'DAYS_INDICATORS',
  scouter_performance_over_time: 'SCOUTER_DAILY_PERFORMANCE',
  individual_scouter: 'SCOUTER_PERFORMANCE_SUMMARY',
  tasks_property: 'PROPERTY_STATUS',
  most_common_issues_property: 'PROPERTY_MOST_COMMON_ISSUES',
  product_types: 'PROPERTY_SUMMARY',
  monitoring_kpi: 'PROPERTY_INFO'
};
const fullList = {
  scouter_distance_time_avg: {
    index: 0,
    selected: true
  },
  tasks_list: {
    index: 1,
    selected: true
  },
  abandoned_areas: {
    index: 2,
    selected: true
  },
  heatmap_over_time: {
    index: 3,
    selected: true
  },
  scouter_performance_over_time: {
    index: 4,
    selected: true
  },
  individual_scouter: {
    index: 5,
    selected: true
  },
  tasks_property: {
    index: 6,
    selected: true
  },
  most_common_issues_property: {
    index: 7,
    selected: true
  },
  product_types: {
    index: 8,
    selected: true
  },
  monitoring_kpi: {
    index: 9,
    selected: true
  },
  company_weather: {
    index: 9,
    selected: true
  },
  weather: {
    index: 9,
    selected: true
  }
};

const buildKpis = (currentValues: MonitoringModel, widgetRequest: WidgetRequest) => {
  const newStart = moment(widgetRequest.start)
    .subtract(moment(widgetRequest.end).diff(moment(widgetRequest.start), 'minutes'), 'minutes')
    .format('YYYY-MM-DD');
  const previousRequest = {
    ...widgetRequest,
    start: newStart,
    end: widgetRequest.start
  };
  return loadWidget<MonitoringModel>(EWidgets.MONITORING_KPI, previousRequest).pipe(
    map(response => response.data),
    map((previousValues: MonitoringModel) => {
      return calculateKpisRates(previousValues, currentValues);
    })
  );
};

const CreateHandle =
  <T>(type, widgetId: EWidgets) =>
  (action$, state$) =>
    action$.pipe(
      filter(
        (action: any) =>
          // Action can be a request to load all widgets or a single widget
          (action.type === WidgetsActionsTypes.LOAD_ALL_REQUEST ||
            (action.type === WidgetsActionsTypes.LOAD_REQUEST &&
              // If we are loading a single widget the widget id must match
              action.payload.widgetId === widgetId)) &&
          // The type must also match so we don't load more widgets than we need to
          action.payload.widgetType === type
      ),
      map((action: Action<any>) => (action.payload ? action.payload.widgetRequest : null)),
      withLatestFrom(
        state$.pipe(map((state: AppState) => selectSelectedSeasons(state.uiState.global))),
        state$.pipe(map((state: AppState) => state.uiState.global.systemFlags))
      ),
      exhaustMap<any, any>(([widgetRequest, selectedSeasons, flags]) => {
        return loadWidget<T>(widgetId, widgetRequest, selectedSeasons, flags?.P40_25084_splitRequestDaysIndicators).pipe(
          map((response: any) => response.data),
          concatMap((data: any) => (widgetId === 'monitoring_kpi' ? buildKpis(data as MonitoringModel, widgetRequest) : of(data))),
          map((data: T) => {
            return LoadSuccess(type, widgetId, data);
          }),
          catchError((error: string) => of(LoadFailure(type, widgetId, error)))
        );
      })
    );

const handleLoadWidgetsSettings = action$ =>
  action$.pipe(
    ofType(WidgetsActionsTypes.LOAD_SETTINGS_REQUEST),
    map((action: any) => action.payload),
    exhaustMap(() =>
      loadSettingsMock().pipe(
        map(response => response.data),
        map((settings: any) => {
          let currentDashboard = null;
          if (settings.dashboard_setups && settings.dashboard_setups.length > 0) {
            let count = Object.keys(fullList).length;
            Object.keys(fullList).forEach(key => {
              const widget = settings.dashboard_setups[0].widgets.find(w => w.name === mapper[key]);
              if (widget) {
                fullList[key].selected = true;
                fullList[key].index = widget.order;
              } else {
                fullList[key].selected = false;
                fullList[key].index = --count;
              }
            });
            currentDashboard = settings.dashboard_setups[0].id;
          }
          return LoadSettingsSuccess(fullList, currentDashboard);
        }),
        catchError((error: string) => {
          return of(LoadSettingsFailure(error));
        })
      )
    )
  );

const handlePostWidgetsSettings = (action$, state$) =>
  action$.pipe(
    ofType(WidgetsActionsTypes.POST_SETTINGS_REQUEST),
    map((action: any) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => state.analytics.widgets.widgetsSettings.currentDashboard)),
      state$.pipe(map((state: AppState) => state.uiState.global.selectedCompany))
    ),
    concatMap(([data, currentDashboard, companyId]: any) => {
      const widgetsToSave = Object.keys(data)
        .map(key => {
          if (data[key] && data[key].selected === true) {
            return {
              name: mapper[key],
              order: data[key].index
            };
          }
          return undefined;
        })
        .filter(w => w !== undefined);
      const dashboardToSave = {
        name: 'Dashboard',
        company_id: companyId,
        widgets: widgetsToSave
      };
      const request = postSettingsMock({ id: currentDashboard || generateUUID(), ...dashboardToSave });
      return request.pipe(
        map(() => {
          return PostSettingsSuccess();
        }),
        catchError((error: string) => of(PostSettingsFailure(error)))
      );
    })
  );

export default combineEpics(
  // Widgets Settings
  handleLoadWidgetsSettings,
  handlePostWidgetsSettings,

  // Widget Monitoring
  CreateHandle<MonitoringModel[]>(WidgetType.COMPANY, EWidgets.MONITORING),

  // Widget Heatmap Over Time
  CreateHandle<HeatmapOverTimeModel[]>(WidgetType.PROPERTY, EWidgets.HEATMAP_OVER_TIME),

  // Widget Individual Scouter Performance
  CreateHandle<IndividualScouterModel[]>(WidgetType.PROPERTY, EWidgets.INDIVIDUAL_SCOUTER),

  // Widget Product Types
  CreateHandle<ProductTypesModel>(WidgetType.PROPERTY, EWidgets.PRODUCT_TYPES),

  // Widget Abandoned Areas
  CreateHandle<AbandonedAreasModel>(WidgetType.PROPERTY, EWidgets.ABANDONED_AREAS)
);
