import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PROTECTOR_API_URL } from 'config/constants';
import type { UUID } from 'core/utils/basic.models';
import { map } from 'rxjs/operators';

const protectorApiUrl = PROTECTOR_API_URL;
const powerBIUrl = '/api/v1/powerbi/orgs/';

const mountUrlPowerBi = orgID => `${protectorApiUrl + powerBIUrl + orgID}/configs`;

export const getReports = (organizationId: UUID, configID: UUID): AxiosObservable<any> => {
  return axios.get(`${mountUrlPowerBi(organizationId)}/${configID}/report`).pipe(map(response => response.data));
};

export const getConfigsReports = (organizationId: UUID): AxiosObservable<any> => {
  return axios.get(`${mountUrlPowerBi(organizationId)}`).pipe(map(response => response.data));
};
