const SvgDiseaseRisk = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9244 9.16152L7.85554 2.20177C7.81926 2.13971 7.76664 2.08827 7.70315 2.05281C7.50676 1.94312 7.25658 2.00981 7.14436 2.20177L3.07555 9.16152C3.02604 9.2462 3 9.34204 3 9.43957C3 9.74909 3.25671 10 3.57337 10H11.4265C11.5263 10 11.6244 9.97455 11.711 9.92616C11.9859 9.7726 12.0815 9.43025 11.9244 9.16152ZM7 4.5C7 4.22386 7.22386 4 7.5 4C7.77614 4 8 4.22386 8 4.5V6.5C8 6.77614 7.77614 7 7.5 7C7.22386 7 7 6.77614 7 6.5V4.5ZM7.5 9C7.77614 9 8 8.77614 8 8.5C8 8.22386 7.77614 8 7.5 8C7.22386 8 7 8.22386 7 8.5C7 8.77614 7.22386 9 7.5 9Z'
    />
    <path d='M19.7575 6.02985C20.2933 5.8959 20.8362 6.22166 20.9702 6.75746L21.9702 10.7575C22.1041 11.2933 21.7784 11.8362 21.2426 11.9701C20.7068 12.1041 20.1638 11.7783 20.0299 11.2425L19.6335 9.65698L16.8683 14.4961C16.7121 14.7694 16.4364 14.9532 16.1241 14.9923L8.51562 15.9433L3.76825 21.6402C3.41468 22.0645 2.78412 22.1218 2.35984 21.7682C1.93556 21.4146 1.87824 20.7841 2.2318 20.3598L7.2318 14.3598C7.39413 14.165 7.62439 14.0392 7.87599 14.0077L15.3799 13.0697L17.9691 8.53849L16.2426 8.97013C15.7068 9.10408 15.1638 8.77832 15.0299 8.24253C14.8959 7.70673 15.2217 7.1638 15.7575 7.02985L19.7575 6.02985Z' />
    <path d='M21 16C21 15.4477 20.5523 15 20 15C19.4477 15 19 15.4477 19 16V21C19 21.5523 19.4477 22 20 22C20.5523 22 21 21.5523 21 21V16Z' />
    <path d='M16 18C16 17.4477 15.5523 17 15 17C14.4477 17 14 17.4477 14 18V21C14 21.5523 14.4477 22 15 22C15.5523 22 16 21.5523 16 21V18Z' />
    <path d='M11 20C11 19.4477 10.5523 19 10 19C9.44772 19 9 19.4477 9 20V21C9 21.5523 9.44772 22 10 22C10.5523 22 11 21.5523 11 21V20Z' />
  </svg>
);

export default SvgDiseaseRisk;
