import { VITE_ENV } from 'config/constants';
import { getCurrentUnitSystem } from 'core/core.selectors';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { getSelectedSeasonsIds } from 'entities/season/season.reducer';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const loadSmartPlan = () => import('smart-plan');
const SmartPlan = React.lazy(() => loadSmartPlan().then((module: { SmartPlan: React.FC }) => ({ default: module.SmartPlan })));

const LocalSmartPlan = ({ routeLevel = 'organization' }: { routeLevel?: 'organization' | 'property' }) => {
  const company = useSelector(getSelectedCompany);
  const property = useSelector(getSelectedProperty);
  const selectedSeasonsIds = useSelector(getSelectedSeasonsIds);
  const currentUnitSystem = useSelector(getCurrentUnitSystem);

  const config = useMemo(
    () => ({
      unitSystem: currentUnitSystem,
      org: company?.id,
      token: localStorage.getItem('access_token'),
      baseRoute:
        routeLevel === 'organization'
          ? `/company/${company?.id}/smart-plan`
          : `/company/${company?.id}/property/${property?.id}/smart-plan`,
      environment: VITE_ENV,
      cropCycle: selectedSeasonsIds,
      locale: localStorage.getItem('i18nextLng')
    }),
    [company?.id, currentUnitSystem, property?.id, routeLevel, selectedSeasonsIds]
  );

  return <SmartPlan key={property?.id} config={config} />;
};

export default LocalSmartPlan;
