import type { Action, UUID } from 'core/utils/basic.models';
import type { LoadAssigneesSuccessType, LoadAssigneesType } from './assignee.models';

export const AssigneeActionsTypes = {
  LOAD_ASSIGNEES: '[Assignee] Load Assignees',
  LOAD_ASSIGNEES_SUCCESS: '[Assignee] Load Assignees Success',
  LOAD_ASSIGNEES_FAILURE: '[Assignee] Load Assignees Failure'
};

export const LoadAssignees: LoadAssigneesType = (company_id: UUID, only_accounts_workers?: boolean) => {
  return {
    type: AssigneeActionsTypes.LOAD_ASSIGNEES,
    payload: { company_id, only_accounts_workers }
  };
};

export const LoadAssigneesSuccess: LoadAssigneesSuccessType = assigneePage => {
  return {
    type: AssigneeActionsTypes.LOAD_ASSIGNEES_SUCCESS,
    payload: assigneePage
  };
};

export const LoadAssigneesFailure = (error: string): Action<string> => {
  return {
    type: AssigneeActionsTypes.LOAD_ASSIGNEES_FAILURE,
    payload: error
  };
};
