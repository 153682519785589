const SvgWeatherRain = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M5.7 13.3C3.1 13.3 1 11.1 1 8.6C1 6 3 3.9 5.5 3.8C6.5 2.1 8.4 1 10.5 1C13.6 1 16.2 3.5 16.2 6.7L16 8.2L14.2 8.1L14.3 6.6C14.3 4.5 12.6 2.8 10.5 2.8C9 2.8 7.6 3.7 7 5.1L6.7 5.8L6 5.7C5.9 5.7 5.9 5.7 5.8 5.7H5.7C4.1 5.7 2.8 7 2.8 8.6C2.8 10.2 4.1 11.5 5.7 11.5V13.3Z'
      fill='#BDC4D1'
    />
    <path
      d='M7.70002 22.1V21.6C7.70002 21.1029 7.29708 20.7 6.80002 20.7C6.30297 20.7 5.90002 21.1029 5.90002 21.6V22.1C5.90002 22.5971 6.30297 23 6.80002 23C7.29708 23 7.70002 22.5971 7.70002 22.1Z'
      fill='#285CC3'
    />
    <path
      d='M12.8 22.1V21.6C12.8 21.1029 12.3971 20.7 11.9 20.7C11.4029 20.7 11 21.1029 11 21.6V22.1C11 22.5971 11.4029 23 11.9 23C12.3971 23 12.8 22.5971 12.8 22.1Z'
      fill='#285CC3'
    />
    <path
      d='M18 22.1V21.6C18 21.1029 17.5971 20.7 17.1 20.7C16.603 20.7 16.2 21.1029 16.2 21.6V22.1C16.2 22.5971 16.603 23 17.1 23C17.5971 23 18 22.5971 18 22.1Z'
      fill='#285CC3'
    />
    <path
      d='M17.4 19.5H5.80002C3.10002 19.5 1.40002 18 1.40002 15.7C1.40002 13.6 2.90002 12 4.90002 11.6C5.30002 8.3 8.20003 5.7 11.6 5.7C14.1 5.7 16.3 7.1 17.5 9.2C20.8 9.2 23 11.1 23 13.9C23 17 20.5 19.5 17.4 19.5ZM5.80002 13.4C4.30002 13.4 3.20002 14.3 3.20002 15.7C3.20002 17.4 5.00002 17.6 5.80002 17.6H17.4C19.5 17.6 21.1 15.9 21.1 13.9C21.1 11.4 18.5 11.1 17.4 11.1C17.4 11.1 17.3 11.1 17.2 11.1C17.1 11.1 17.1 11.1 17 11.1L16.3 11.2L16 10.6C15.2 8.8 13.5 7.6 11.5 7.6C8.80002 7.6 6.60002 9.8 6.60002 12.5V13.4H5.80002Z'
      fill='#828B99'
    />
  </svg>
);

export default SvgWeatherRain;
