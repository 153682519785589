import axiosObservable from 'axios-observable';
import axios from 'axios';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import type { UUID } from 'core/utils/basic.models';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { ReportInfo, RequestReportBody, ReportPayload } from './report.models';

const protectorApiUrl = process.env.VITE_PROTECTOR_API_URL;

export const getPropertyReports = (propertyId: UUID, cursor?: string): Observable<ReportPayload> => {
  let url = `${protectorApiUrl}/v1/properties/${propertyId}/reports`;
  if (cursor) {
    url += `?cursor=${cursor}`;
  }
  return axiosObservable.get<ReportPayload>(url).pipe(map(response => response.data));
};

export const downloadReport = async (reportId: UUID, propertyId: UUID): Promise<string> => {
  const response = await axios.get<string>(`${protectorApiUrl}/v1/properties/${propertyId}/reports/${reportId}/download-url`);
  return response.data;
};

export const requestReport = (body: RequestReportBody): AxiosObservable<ReportInfo> => {
  return axiosObservable.post<ReportInfo>(`${protectorApiUrl}/v1/reports`, body);
};
