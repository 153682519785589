const SvgWeatherStorm = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M16.2 9.90001L15.5 8.20001C16.1 7.90001 16.5 7.30001 16.5 6.60001C16.5 5.70001 15.7 4.90001 14.8 4.90001C14 4.90001 13.2 5.50001 13.1 6.30001C13.1 6.40001 13.1 6.50001 13.1 6.60001H11.3C11.3 6.40001 11.3 6.20001 11.3 6.10001C11.6 4.40001 13.1 3.10001 14.8 3.10001C16.7 3.10001 18.3 4.70001 18.3 6.60001C18.3 8.10001 17.5 9.40001 16.2 9.90001Z'
      fill='#DFA701'
    />
    <path
      d='M12.5431 5.66685C12.3534 6.12749 11.8269 6.34801 11.3656 6.16005L10.3423 5.74317C9.88064 5.55508 9.66474 5.02344 9.86456 4.56671C10.0529 4.1363 10.5442 3.92749 10.9848 4.09066L12.0209 4.47441C12.5012 4.65231 12.7381 5.19322 12.5431 5.66685Z'
      fill='#DFA701'
    />
    <path
      d='M14.2002 3.06031L13.7643 2.16031C13.5476 1.71296 13.0093 1.52596 12.562 1.74262C12.1146 1.95929 11.9276 2.49758 12.1443 2.94493L12.5802 3.84493C12.7968 4.29228 13.3351 4.47929 13.7825 4.26262C14.2298 4.04596 14.4168 3.50767 14.2002 3.06031Z'
      fill='#DFA701'
    />
    <path
      d='M15.3486 2.28377L15.0736 3.14075C14.9217 3.61404 15.1822 4.12087 15.6555 4.27277C16.1288 4.42467 16.6356 4.16412 16.7875 3.69083L17.0626 2.83385C17.2145 2.36055 16.9539 1.85373 16.4806 1.70183C16.0073 1.54993 15.5005 1.81047 15.3486 2.28377Z'
      fill='#DFA701'
    />
    <path
      d='M18.1655 3.70202L17.3033 4.20852C16.8747 4.46028 16.7314 5.01179 16.9831 5.44035C17.2349 5.86891 17.7864 6.01224 18.215 5.76048L19.0772 5.25398C19.5057 5.00222 19.6491 4.45071 19.3973 4.02215C19.1455 3.59359 18.594 3.45026 18.1655 3.70202Z'
      fill='#DFA701'
    />
    <path
      d='M19.2397 6.81745L18.2626 6.60485C17.7769 6.49917 17.2976 6.80722 17.1919 7.2929C17.0862 7.77857 17.3943 8.25795 17.8799 8.36363L18.857 8.57623C19.3427 8.6819 19.8221 8.37385 19.9278 7.88818C20.0334 7.4025 19.7254 6.92312 19.2397 6.81745Z'
      fill='#DFA701'
    />
    <path
      d='M16.1 8.80001C15.1 7.10001 13.4 6.10001 11.4 6.10001C8.69999 6.10001 6.49999 8.10001 6.09999 10.7C4.49999 11.1 3.29999 12.5 3.29999 14.2C3.29999 16.2 4.89999 17.8 6.89999 17.8H7.39999C7.89704 17.8 8.29999 17.3971 8.29999 16.9C8.29999 16.4029 7.89704 16 7.39999 16H6.89999C5.89999 16 5.09999 15.2 5.09999 14.2C5.09999 13.2 5.89999 12.4 6.89999 12.4H7.79999V11.5C7.79999 9.50001 9.39999 7.90001 11.4 7.90001C12.8 7.90001 14.1 8.80001 14.7 10.1L15 10.7L15.7 10.6C15.8 10.6 15.8 10.6 15.8 10.6H15.9C17.4 10.6 18.6 11.8 18.6 13.3C18.6 14.8 17.5 16 16.5 16L16.3 16C15.8029 16 15.4 16.4029 15.4 16.9C15.4 17.3971 15.8029 17.8 16.3 17.8H16.5C18.1904 17.8 20.4 15.8 20.4 13.3C20.4 10.9 18.5 8.90001 16.1 8.80001Z'
      fill='#828B99'
    />
    <path d='M12.9 9.7L9 14.3L11.6 14.9L10.8 18.4L14.7 13.8L12.1 13.2L12.9 9.7Z' fill='#DFA701' />
    <path
      d='M7.39998 22V20.8C7.39998 20.3029 6.99703 19.9 6.49998 19.9C6.00292 19.9 5.59998 20.3029 5.59998 20.8V22C5.59998 22.4971 6.00292 22.9 6.49998 22.9C6.99703 22.9 7.39998 22.4971 7.39998 22Z'
      fill='#285CC3'
    />
    <path
      d='M12.5 22V20.8C12.5 20.3029 12.0971 19.9 11.6 19.9C11.103 19.9 10.7 20.3029 10.7 20.8V22C10.7 22.4971 11.103 22.9 11.6 22.9C12.0971 22.9 12.5 22.4971 12.5 22Z'
      fill='#285CC3'
    />
    <path
      d='M17.7 22V20.8C17.7 20.3029 17.2971 19.9 16.8 19.9C16.303 19.9 15.9 20.3029 15.9 20.8V22C15.9 22.497 16.303 22.9 16.8 22.9C17.2971 22.9 17.7 22.497 17.7 22Z'
      fill='#285CC3'
    />
  </svg>
);

export default SvgWeatherStorm;
