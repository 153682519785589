import { getPermissionFlags, getSystemFlags } from 'core/core.selectors';
import { showDiseaseRiskReport } from 'entities/reports/report.selectors';
import { useGetSugarcaneSeasonPermission } from 'pages/sugarcane/sugarcane.utils';
import { useSelector } from 'react-redux';
import { useMatch, useParams } from 'react-router';
import { ReportsTypes } from './reports-types.enum';

export const useShowOnlyReportsForAllOrg = (): boolean => {
  const systemFlags = useSelector(getSystemFlags);
  const isInAProperty = !!useMatch({
    path: 'company/:companyId/property/:propertyId/*',
    end: true
  });
  return !!systemFlags?.P40_33267_report_button_in_hall_of_properties && !isInAProperty;
};

export const reports = {
  [ReportsTypes.DISEASE_RISK]: false,
  [ReportsTypes.SCOUTING]: false,
  [ReportsTypes.MONITORING_CONSOLIDATED]: false,
  [ReportsTypes.SAMPLE]: false,
  [ReportsTypes.MONITORING_DETAILED]: false,
  [ReportsTypes.PROPERTY]: false,
  [ReportsTypes.TRACKING]: false,
  [ReportsTypes.SAMPLING_IMPORT]: false,
  [ReportsTypes.SAMPLING]: false,
  [ReportsTypes.VISIT]: false,
  [ReportsTypes.CONTROL_STRATEGIES]: false,
  [ReportsTypes.BLOCKED_FIELDS]: false
};

export const useRulesToShowReports = (): typeof reports => {
  const showOnlyReportsForAllOrg = useShowOnlyReportsForAllOrg();
  const hasAccessDiseaseRiskReport = useSelector(showDiseaseRiskReport);
  const permissionFlags = useSelector(getPermissionFlags);
  const systemFlags = useSelector(getSystemFlags);
  const { companyId } = useParams();

  const sugarcanePermission = useGetSugarcaneSeasonPermission();

  const {
    hideReportSampleAndAgricultural,
    hideReportDetailedConsolidated,
    hideFarmScoutingReport,
    hideSamplingReport,
    hideReportTracking,
    hideReportFarm,
    simpleTimeline,
    reportVisit
  } = permissionFlags;

  reports[ReportsTypes.DISEASE_RISK] = hasAccessDiseaseRiskReport && !showOnlyReportsForAllOrg;

  reports[ReportsTypes.SCOUTING] = !hideFarmScoutingReport?.value && !showOnlyReportsForAllOrg;

  reports[ReportsTypes.SAMPLE] = !hideReportSampleAndAgricultural?.value && !showOnlyReportsForAllOrg;

  const newRule = !!systemFlags?.P40_26623_allowing_monitoring_reports_all_companies && !hideReportDetailedConsolidated?.value;

  const oldRule =
    !!systemFlags?.CompaniesWithDetailedConsolidatedFlags &&
    systemFlags?.CompaniesWithDetailedConsolidatedFlags.toString().indexOf(companyId ?? '') >= 0;

  const showDetailedConsolidatedReport = systemFlags?.P40_26623_allowing_monitoring_reports_all_companies ? newRule : oldRule;

  const showMonitoringReport = !!companyId && (showDetailedConsolidatedReport || showOnlyReportsForAllOrg);

  reports[ReportsTypes.MONITORING_CONSOLIDATED] = showMonitoringReport;
  reports[ReportsTypes.MONITORING_DETAILED] = showMonitoringReport;

  reports[ReportsTypes.PROPERTY] = !simpleTimeline?.value && !hideReportFarm?.value && !showOnlyReportsForAllOrg;

  reports[ReportsTypes.TRACKING] = !hideReportTracking?.value && !showOnlyReportsForAllOrg;

  reports[ReportsTypes.SAMPLING_IMPORT] = !hideSamplingReport?.value && !showOnlyReportsForAllOrg;

  reports[ReportsTypes.SAMPLING] = !hideReportSampleAndAgricultural?.value && !showOnlyReportsForAllOrg;

  reports[ReportsTypes.VISIT] = !!systemFlags?.visitReportFlag && reportVisit?.value && !showOnlyReportsForAllOrg;

  reports[ReportsTypes.CONTROL_STRATEGIES] = sugarcanePermission;

  reports[ReportsTypes.BLOCKED_FIELDS] = sugarcanePermission && !!systemFlags?.P40_44078_blocked_fields_report;
  return reports;
};
