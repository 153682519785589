import { COLOR_OPTIONS } from 'config/style';

interface CropWheatProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropSoybeans = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropWheatProps) => (
  <svg width='16px' height={height} viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='St-icons-/-Glyph-/-soybeans-glyph' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        d='M2.8454765,10.5695387 C4.30733821,10.1143532 6.02910737,10.6088672 6.60164985,10.5847493 C7.17419233,10.5606314 7.5496209,9.33208509 8.13659498,8.56886376 C8.72356906,7.80564243 9.89631374,7.73485748 10.222135,7.18210814 C10.5479562,6.62935881 10.2383369,4.69286736 11.8386005,2.72739454 C13.438864,0.761921712 13.9071818,1.3185107 14.0916602,1.02785836 C14.2761385,0.737206025 15.1257229,2.78347769 14.9309009,4.24839256 C14.7360788,5.71330743 13.6267273,7.32895628 13.2451544,8.11259675 C12.8635816,8.89623722 12.3158169,10.6105969 11.1243033,11.4581793 C9.93278974,12.3057617 9.3726805,12.0392991 8.70331254,12.5947682 C8.03394458,13.1502373 5.20542145,15.9594675 2.38042574,14.5 C1.38227539,13.9843291 0.674962605,12.8561022 1.15282444,11.8354286 C1.34642289,11.4219182 1.67303826,11.0846639 2.08042622,10.8776118 C2.3171782,10.7573394 2.57218754,10.6546338 2.8454765,10.5695387 Z M2.30307483,12.5939566 C2.38164033,13.344821 3.34786046,13.9118962 4.46118791,13.8605545 C5.57451536,13.8092127 6.41335566,13.158896 6.33479017,12.4080315 C6.25622467,11.657167 5.29000454,11.0900919 4.17667709,11.1414336 C3.06334964,11.1927754 2.22450934,11.8430921 2.30307483,12.5939566 Z M8.54284231,10.4310088 C8.87998213,11.1170018 9.74131712,11.3622909 10.4666879,10.9788774 C11.1920586,10.5954639 11.506782,9.7285386 11.1696422,9.0425456 C10.8325023,8.3565526 9.97116735,8.11126346 9.2457966,8.49467698 C8.52042585,8.87809049 8.2057025,9.74501577 8.54284231,10.4310088 Z M12.3847755,6.23891755 C13.0645609,6.4771223 13.811161,6.01985103 14.0523535,5.2175736 C14.2935461,4.41529616 13.9379963,3.5718187 13.258211,3.33361396 C12.5784256,3.09540922 11.8318255,3.55268049 11.590633,4.35495792 C11.3494404,5.15723535 11.7049901,6.00071281 12.3847755,6.23891755 Z'
        id='soybeans'
        fill={color}
        fillRule='nonzero'
        transform='translate(7.979866, 7.957829) scale(-1, 1) translate(-7.979866, -7.957829) '></path>
    </g>
  </svg>
);

export default CropSoybeans;
