import axios from 'axios-observable';
import { CHUNK_ANALYTICS_RESULTS, CHUNK_PAGE, PROTECTOR_API_URL } from 'config/constants';
import { getObservables } from 'core/rxjs-utils';
import type { UUID } from 'core/utils/basic.models';
import moment from 'moment';
import type { Observable } from 'rxjs';
import { EMPTY, throwError } from 'rxjs';
import { catchError, concatMap, expand, map, toArray } from 'rxjs/operators';
import type { AnalyticResultsHistogramResponse, Station, StationActionType, StationResponse } from './stations.models';

const CHUNK_PAGES_ANALYTICS_RESULTS_HISTOGRAM = 1;
const CHUNK_ANALYTICS_RESULTS_HISTOGRAM_IDS = 200;
const protectorApiUrl = PROTECTOR_API_URL;
const nanoBaseUrl = `${protectorApiUrl}/api/v1`;
const archeBaseUrl = `${protectorApiUrl}/v1`;
const staticPointUrl = `${nanoBaseUrl}/properties`;
const trapUrl = `${archeBaseUrl}/timeline/trap`;
const trapsUrl = `${archeBaseUrl}/timeline/traps`;
const severityUrl = `${trapsUrl}/monitoring`;
const staticPointsUrl = `${archeBaseUrl}/timeline/static-points`;

export const getStaticPointsByPage = (property_idParam: string, is_last = true, cursor = ''): Observable<StationResponse> => {
  const params = `size=${CHUNK_PAGE}${!is_last && cursor ? `&cursor=${cursor}` : ''}`;
  return axios
    .get<StationResponse>(`${staticPointUrl}/${property_idParam}/stations?${params}`, {
      headers: { 'Content-Type': 'application/json' }
    })
    .pipe(map(response => response.data));
};

export const getAllStations = (property_idParam: string): Observable<Station[]> => {
  return getStaticPointsByPage(property_idParam).pipe(
    expand(({ is_last, cursor }) => (!is_last ? getStaticPointsByPage(property_idParam, is_last, cursor) : EMPTY)),
    concatMap(({ content }) => content),
    toArray()
  );
};

export const getAnalyticResultsHistogram = (
  property_idParam: string,
  start_dateParam: Date,
  end_DateParam: Date,
  template_idParam: string,
  ids: string[],
  all_actions = false
): Observable<AnalyticResultsHistogramResponse[]> => {
  const start = moment(start_dateParam).format('YYYY-MM-DD');
  const end = moment(end_DateParam).format('YYYY-MM-DD');
  const params = `?property_id=${property_idParam}&start_date=${start}&end_date=${end}&template_id=${template_idParam}&all_actions=${all_actions}`;
  return axios.post<AnalyticResultsHistogramResponse[]>(`${severityUrl}${params}`, ids).pipe(
    map(r => r.data),
    catchError(r => throwError(r))
  );
};

export const getAnalyticResultsHistogramIdsPaginated = (
  property_idParam: string,
  start_dateParam: Date,
  end_DateParam: Date,
  template_idParam: string,
  ids: string[],
  all_actions = false
): Observable<AnalyticResultsHistogramResponse[]> => {
  const CHUNK = all_actions ? parseInt(CHUNK_ANALYTICS_RESULTS || '10') : CHUNK_ANALYTICS_RESULTS_HISTOGRAM_IDS;
  const CHUNK_PAGES = CHUNK_PAGES_ANALYTICS_RESULTS_HISTOGRAM;
  const totalChunkedPages = Math.floor(ids.length / CHUNK + 1);
  const pages = [...Array(totalChunkedPages).keys()];

  const observables = pages.map(page => {
    const idsChunked = ids.slice(page * CHUNK, (page + 1) * CHUNK);
    return getAnalyticResultsHistogram(property_idParam, start_dateParam, end_DateParam, template_idParam, idsChunked, all_actions);
  });

  if (totalChunkedPages < CHUNK_PAGES) return getObservables(observables, true);

  const totalChunckObservable = Math.floor(totalChunkedPages / CHUNK_PAGES + 1);
  const observablesPages = [...Array(totalChunckObservable).keys()];
  const observableMap = observablesPages.map(oPage => {
    return getObservables(observables.slice(oPage * CHUNK_PAGES, (oPage + 1) * CHUNK_PAGES), true);
  });

  return getObservables(observableMap, false);
};

export const updateStation = (
  actionType: StationActionType,
  account: { id: UUID; name: string },
  stationId: UUID,
  companyId: UUID,
  propertyId: UUID,
  autoCreateTask = false
): Observable<any> => {
  return axios.put<any>(staticPointsUrl, {
    account,
    payload: {
      action_type: actionType,
      id: stationId,
      company_id: companyId,
      property_id: propertyId,
      auto_create_task: autoCreateTask
    }
  });
};

export const editStation = (account: { id: UUID; name: string }, station: any): Observable<any> => {
  return axios.put<any>(trapUrl, {
    account,
    payload: station
  });
};
