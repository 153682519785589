import type { Season } from 'entities/season/season.models';
import { isEmpty } from 'lodash';
import type { SeasonField } from 'querys/season-field/season-field.models';
import type { Dictionary } from 'config/types';

export const filterCropIdsFromSeasonBySeasonFields = (allSeasons: Dictionary<Season>, selectedSeasonFields?: SeasonField[]) => {
  const cropIds: string[] = [];

  if (!allSeasons || isEmpty(allSeasons) || !selectedSeasonFields?.length) return cropIds;

  selectedSeasonFields.forEach(seasonField => {
    if (!seasonField.season_id) return cropIds;

    const cropId = allSeasons?.[seasonField.season_id].crop.id;

    if (!cropId) return cropIds;

    if (cropIds.includes(cropId)) return cropIds;

    cropIds.push(cropId);
  });

  return cropIds;
};
