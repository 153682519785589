import { SetCurrentSeasons } from 'core/core.actions';
import type { Action, PageableQuery, UUID } from 'core/utils/basic.models';
import type { Property } from 'entities/property/property.models';
import _ from 'lodash';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { of } from 'rxjs';
import { concatMap, map, withLatestFrom } from 'rxjs/operators';
import { getSelectedProperty } from '../property/property.reducer';
import { LoadSeasonsSuccess, SeasonActionsTypes } from './season.actions';
import { isActive } from './season.functions';
import type { Season } from './season.models';
import { selectAllSeasons } from './season.reducer';

export const handleSetSeasonsFromProperties = (action$, state$) =>
  action$.pipe(
    ofType(SeasonActionsTypes.SET_SEASONS_FROM_PROPERTIES),
    map((action: Action<Property[]>) => action.payload),
    concatMap((properties: Property[]) => {
      const activeSeasons = properties.flatMap(p => p.seasons);
      return of(LoadSeasonsSuccess(_.uniqBy(activeSeasons, 'id')));
    })
  );

export const handleSetSeasonsFromProperty = (action$, state$) =>
  action$.pipe(
    ofType(SeasonActionsTypes.SET_SEASONS_FROM_PROPERTY),
    withLatestFrom(state$.pipe(map((state: AppState) => getSelectedProperty(state)))),
    concatMap(([_page, selectedProperty]) => {
      let activeSeasons: Season[] = [];
      if (selectedProperty) {
        activeSeasons = selectedProperty.seasons;
      }
      return of(LoadSeasonsSuccess(activeSeasons));
    })
  );

export const selectActiveSeasonsAsActive = (action$, state$) =>
  action$.pipe(
    ofType(SeasonActionsTypes.LOAD_SEASONS_SUCCESS),
    map((action: Action<PageableQuery>) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => selectAllSeasons(state.entities.season)))),
    concatMap(([_page, seasons]) => {
      let activeSeasons: UUID[] = [];
      if (seasons) {
        activeSeasons = seasons.filter(season => isActive(season)).map(season => season.id);
      }
      return of(SetCurrentSeasons(activeSeasons));
    })
  );
