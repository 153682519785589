import type { Dictionary } from 'config/types';
import type { UUID } from '../../core/utils/basic.models';
import type { ConfigurationParameterDTO, LimitValues, RiskDTO } from '../../pages/borer-risk/borer-risk-map/borer-risk.models';
import type { Region } from '../region/region.models';

export const BorerRiskActionTypes = {
  SET_ACTIVE_PARAMETER: '[Borer Risk] Set Active Paramater',
  SET_EMPTY_PAGE: '[Borer Risk] Set Empty Page',

  LOAD_CONFIGURATION_PARAMETERS: '[Borer Risk] Load Configuration Parameters',
  LOAD_CONFIGURATION_PARAMETERS_SUCCESS: '[Borer Risk] Load Configuration Parameters Success',
  LOAD_CONFIGURATION_PARAMETERS_FAILURE: '[Borer Risk] Load Configuration Parameters Failure',

  LOAD_BORER_RISKS: '[Borer Risk] Load Borer Risks',
  LOAD_BORER_RISKS_SUCCESS: '[Borer Risk] Load Borer Risks Success',
  LOAD_BORER_RISKS_FAILURE: '[Borer Risk] Load Borer Risks Failure',

  RELOAD_BORER_RISKS: '[Borer Risk] Reload Borer Risks'
};

export const setBorerRiskActiveParameter = (parameter: string) => {
  return {
    type: BorerRiskActionTypes.SET_ACTIVE_PARAMETER,
    payload: parameter
  };
};

export const SetEmptyPage = (emptyPage: boolean) => {
  return {
    type: BorerRiskActionTypes.SET_EMPTY_PAGE,
    payload: emptyPage
  };
};

export const LoadBorerRiskParameters = (propertyId: UUID, seasonId: UUID) => {
  return {
    type: BorerRiskActionTypes.LOAD_CONFIGURATION_PARAMETERS,
    payload: {
      propertyId,
      seasonId
    }
  };
};

export const LoadBorerRiskParametersSuccess = (parameters: Dictionary<ConfigurationParameterDTO>) => {
  return {
    type: BorerRiskActionTypes.LOAD_CONFIGURATION_PARAMETERS_SUCCESS,
    payload: parameters
  };
};

export const LoadBorerRiskParametersFailure = (error: string) => {
  return {
    type: BorerRiskActionTypes.LOAD_CONFIGURATION_PARAMETERS_FAILURE,
    payload: error
  };
};

export const LoadBorerRisks = (propertyId: UUID, seasonId: UUID, regions: Dictionary<Region>, cursor: string | null, year?: string) => {
  return {
    type: BorerRiskActionTypes.LOAD_BORER_RISKS,
    payload: {
      propertyId,
      seasonId,
      regions,
      cursor,
      year
    }
  };
};

export const ReloadBorerRisks = (
  propertyId: UUID,
  seasonId: UUID,
  limitValues: LimitValues,
  regions: Dictionary<Region>,
  risks: RiskDTO,
  cursor: string | null
) => {
  return {
    type: BorerRiskActionTypes.RELOAD_BORER_RISKS,
    payload: {
      propertyId,
      seasonId,
      limitValues,
      regions,
      risks,
      cursor
    }
  };
};

export const LoadBorerRisksSuccess = (risks: RiskDTO, limitValues: LimitValues) => {
  return {
    type: BorerRiskActionTypes.LOAD_BORER_RISKS_SUCCESS,
    payload: { risks, limitValues }
  };
};

export const LoadBorerRisksFailure = (error: string) => {
  return {
    type: BorerRiskActionTypes.LOAD_BORER_RISKS_FAILURE,
    payload: error
  };
};
