import { ROLLOUT_KEY } from 'config/constants';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Rox from 'rox-browser';
import { SetSystemFlags } from '../../core.actions';

type FeatureFlags = Record<string, Rox.Flag | Rox.RoxString | Rox.RoxNumber>;

export const featureFlags: FeatureFlags = {
  trapViewInstall: new Rox.Flag(),
  smartSprayPagination: new Rox.Flag(),
  P40_34127_disease_risk_potato: new Rox.Flag(false),
  P40_34223_disease_risk_potato_mock: new Rox.Flag(false),
  P40_34565_disease_risk_pre_season: new Rox.Flag(false),
  P40_33762_growth_stages_edit: new Rox.Flag(false),
  trapTaskTypeList: new Rox.RoxString(''),
  limitOfTasks: new Rox.RoxString('{"able": true, "limit": 300, "minutes": 30}'),
  controlStrategySelectSuggestion: new Rox.Flag(),
  AreasWithoutGeometryFlags: new Rox.RoxString(''),
  CompaniesWithDetailedConsolidatedFlags: new Rox.RoxString(''),
  rainInfoInCottonGrowthPerNode: new Rox.Flag(),
  powerBiReportFilterAndPages: new Rox.Flag(),
  P4017420_scoutingPlanVisualizationPage: new Rox.Flag(),
  P40_20082_enableWholePath: new Rox.Flag(),
  P40_20083_enableWholePath: new Rox.Flag(),
  areas_products_spending: new Rox.Flag(),
  P40_20690_gettingSeasonsInANewLocation: new Rox.Flag(),
  P40_20628_deleteControlStrategy: new Rox.Flag(),
  P40_24453_toggleControlStrategy: new Rox.Flag(),
  P40_19684_indicatorsWithNoThreshold: new Rox.Flag(),
  P40_20876_displayedRegionStatuses: new Rox.Flag(),
  P40_43662_hideRegionSummary: new Rox.Flag(),
  P40_15506_showRiskParameters: new Rox.Flag(),
  P40_18180_editPlan: new Rox.Flag(),
  P40_21430_scoutingScoreXray: new Rox.Flag(),
  P40_20723_scoutingPlanLoadingScreen: new Rox.Flag(),
  P40_21869_customFieldsDisplayOnTimeline: new Rox.Flag(),
  visitReportFlag: new Rox.Flag(),
  P40_22226_hideUnselectedFixedPointType: new Rox.Flag(),
  P40_22961_change_api_imagery: new Rox.RoxString(''),
  P40_22677_controlStrategySimilarProducts: new Rox.Flag(),
  P40_22678_controlStrategySprayHistory: new Rox.Flag(),
  P40_13021_rainGaugeReport: new Rox.Flag(),
  P40_22549_enableFieldClustering: new Rox.Flag(),
  P40_22676_selectStrategiesPerArea: new Rox.Flag(),
  P40_23669_geometriesBySeasonFieldEndDate: new Rox.Flag(),
  P40_22229_improvements_on_rain_map: new Rox.Flag(),
  P40_22123_appliedSpraysOnTheSeverityMap: new Rox.Flag(),
  P40_23673_removeCurrentInfoForUseLoadRegions: new Rox.Flag(),
  P40_22530_companies_with_field_book: new Rox.RoxString(''),
  P40_24272_changeNavigateBackButtonIntegrations: new Rox.Flag(),
  P40_24270_solve_deck_of_app: new Rox.Flag(),
  P40_26623_allowing_monitoring_reports_all_companies: new Rox.Flag(),
  P40_26623_allowing_monitoring_reports_all_companies_end_point: new Rox.Flag(),
  P40_24203_newCoordsIfHasNoGeometry: new Rox.Flag(),
  P40_24443_controlStrategyAllAreas: new Rox.Flag(),
  P40_23746_enableERPIntegrationChanges: new Rox.Flag(),
  P40_24619_enableERPFinancialIntegrationChanges: new Rox.Flag(),
  P40_18674_scouting_score_map: new Rox.Flag(),
  P40_24588_enableMultiplePhotosNotes: new Rox.Flag(),
  P40_24324_scouting_score_hall: new Rox.Flag(),
  P40_24324_scouting_score_setup: new Rox.Flag(),
  scouting_score_phenology_stages: new Rox.Flag(),
  P40_24023_notes_module: new Rox.Flag(),
  P40_24324_scouting_score_delete_edit: new Rox.Flag(),
  P40_24951_render_power_bi: new Rox.RoxString(''),
  P40_25084_splitRequestDaysIndicators: new Rox.Flag(),
  P40_25308_add_company_logo_visit_report: new Rox.Flag(),
  P40_24847_autoCreateMonitoringTrapTask: new Rox.Flag(),
  P40_25127_spain_mvp_custom_field: new Rox.RoxString(''),
  P40_25985_wrong_area_value_financial: new Rox.Flag(),
  P40_26186_post_harvest_configuration: new Rox.Flag(),
  P40_26127_invertLatLng: new Rox.Flag(),
  P40_26299_nemadigital_map: new Rox.Flag(),
  P40_25943_nemadigital_config: new Rox.Flag(),
  P40_26233_notes_midia_viewer: new Rox.Flag(),
  P40_26233_notes_products_available: new Rox.RoxString(''),
  P40_25943_nemadigital_amount_years_season: new Rox.RoxString('6'),
  P40_25943_nemadigital_min_size_field: new Rox.RoxString('0'),
  P40_25943_nemadigital_min_size_season_soy: new Rox.RoxString('4'),
  P40_25943_nemadigital_min_size_season_others: new Rox.RoxString('1'),
  P40_25943_nemadigital_default_month_start_soy: new Rox.RoxString('10'),
  P40_25943_nemadigital_default_month_end_soy: new Rox.RoxString('3'),
  P40_25943_nemadigital_default_month_start_others: new Rox.RoxString('4'),
  P40_25943_nemadigital_default_month_end_others: new Rox.RoxString('6'),
  P40_28455_nemadigital_max_size_range_date: new Rox.RoxString('4'),
  P40_25943_nemadigital_select_field: new Rox.Flag(),
  P40_25943_nemadigital_summary_screen: new Rox.Flag(),
  P40_28450_nemadigital_edit_field_crop: new Rox.Flag(),
  P40_26299_nemadigital_map_single_geojson: new Rox.Flag(),
  P40_26299_nemadigital_medium_damage_threshold: new Rox.RoxString('6'),
  P40_26299_nemadigital_high_damage_threshold: new Rox.RoxString('12'),
  P40_26299_nemadigital_medium_confidence_threshold: new Rox.RoxString('0.6'),
  P40_26299_nemadigital_high_confidence_threshold: new Rox.RoxString('0.8'),
  P40_27515_post_harvest_map: new Rox.Flag(),
  P40_27164_fixing_reentry_period: new Rox.Flag(),
  P40_27418_control_strategy_new_card_actions: new Rox.Flag(),
  P40_27420_show_button_marked_all_visualized: new Rox.Flag(),
  P40_27412_controlStrategyPredecessorProducts: new Rox.Flag(),
  post_harvest_enable_only_admin_strider: new Rox.Flag(),
  leafhopper_enable_only_admin_strider: new Rox.Flag(),
  P40_27679_new_api_trapview_integration: new Rox.Flag(),
  P40_28469_leafhopper_plan: new Rox.Flag(),
  P40_29177_show_modal_layout_edit_heatmap: new Rox.Flag(),
  P40_29700_show_statistic_legend: new Rox.Flag(),
  P40_29585_integrate_web_panel_with_RBAC: new Rox.Flag(),
  P40_28620_enable_field_cluster: new Rox.Flag(false),
  P40_30249_clusteringInputLeafhopper: new Rox.Flag(),
  P40_34903_disease_risk_batch: new Rox.Flag(false),
  P40_31096_trendGraphOnXRay: new Rox.Flag(),
  P40_27952_c_level_report: new Rox.Flag(),
  P40_30940_trap_distribution_step: new Rox.Flag(),
  HOT_FIX_DISABLED_CALENDAR_LOADING: new Rox.Flag(),
  P40_31566_GET_LAST_CONTRACT_HEADER: new Rox.Flag(),
  P40_324292_new_property_methodology_endpoint: new Rox.Flag(),
  P40_33267_report_button_in_hall_of_properties: new Rox.Flag(),
  P40_33256_HIDE_FIELDS_INACTIVE: new Rox.Flag(),
  P40_33943_product_status: new Rox.Flag(),
  P40_33921_FILTER_UNIQUE_PRODUCTS: new Rox.Flag(),
  P40_33938_SMART_PLAN: new Rox.Flag(),
  P40_34609_LEAFHOPPER_PHENOMENA_IDS: new Rox.RoxString('[]'),
  P40_34609_LEAFHOPPER_SCOUTING_MAP_BY_TEAMS: new Rox.Flag(),
  P40_34332_GROUP_CUSTOM_FIELDS: new Rox.Flag(),
  P40_34839_DISABLE_NEW_SEASON_FIELD_TO_METHODOLOGY: new Rox.Flag(),
  P40_34492_EDIT_INFO_MODAL_SEASON_FIELDS: new Rox.Flag(),
  P40_34466_CHANGE_DATE_RANGE_TO_TABLE_MODE: new Rox.Flag(),
  P40_34493_USE_AREA_TABLE_ROWS: new Rox.Flag(false),
  CWS_10071_OKTA_LOGOUT: new Rox.Flag(false),
  P40_35925_not_selected_suggestions_report: new Rox.Flag(false),
  P40_36273_disable_clustering: new Rox.Flag(true),
  P40_36320_trackings_by_segment: new Rox.Flag(false),
  P40_37190_filter_regions_by_current_season_area: new Rox.Flag(false),
  P40_34171_filter_regions_by_current_season_area_in_task_map: new Rox.Flag(false),
  P40_37720_add_pest_risks_in_trap_route_setup: new Rox.Flag(false),
  P40_37779_dr_potato_split_today: new Rox.Flag(false),
  P40_37022_dr_potato_model_update_timestamp: new Rox.Flag(false),
  P40_37413_DISEASE_RISK_WEATHER_STATION: new Rox.Flag(true),
  P40_37906_check_if_product_is_available_in_warehouse_stock: new Rox.Flag(),
  P40_38006_LOAD_PRODUCTS_PARALLEL: new Rox.Flag(false),
  P40_37709_rea_integration_hall_of_properties: new Rox.Flag(false),
  P40_38698_trap_routing_setup_refactor: new Rox.Flag(false),
  P40_38695_centralize_fields_tasks: new Rox.Flag(false),
  P40_38847_download_button_field_book_spain: new Rox.Flag(false),
  P40_39027_dr_field_config_v2: new Rox.Flag(false),
  P40_40181_dr_field_functional_multi_crops: new Rox.Flag(false),
  P40_38920_check_permission_for_edit_field: new Rox.Flag(false),
  P40_39085_field_cluster_selector_modal: new Rox.Flag(false),
  P40_39_39399_filter_stations_by_last_event_date: new Rox.Flag(false),
  P40_39085_task_routing_plan: new Rox.Flag(false),
  P40_32931_yield_assessment_per_ndvi_zones: new Rox.Flag(false),
  P40_41514_split_current_info: new Rox.Flag(false),
  P40_39024_register_indicator_parent_id: new Rox.RoxString(''),
  P40_39836_pest_risk_in_leafhopper_plan: new Rox.Flag(false),
  P40_40442_remove_hashfile: new Rox.Flag(false),
  P40_40736_enable_configure_cif: new Rox.Flag(false),
  P40_27952_c_level_report_by_userID: new Rox.RoxString(''),
  P40_37195_delete_growth_stage_data_on_web: new Rox.Flag(false),
  P40_41474_suggest_points: new Rox.Flag(false),
  P40_41622_edit_sampling: new Rox.Flag(false),
  P40_41276_latest_field_clusters_view: new Rox.Flag(false),
  P40_41327_enable_product_warnings: new Rox.Flag(false),
  P40_41626_enable_product_tasks_details: new Rox.Flag(false),
  P40_40974_enable_product_details_mfe: new Rox.Flag(false),
  P40_27952_c_level_report_by_companyID: new Rox.Flag(false),
  P40_40442_sentry_custom_fingerprint: new Rox.Flag(false),
  P40_41996_web_plot_charts_on_the_timeline: new Rox.Flag(false),
  P40_42449_c_level_report_widget_KPI: new Rox.Flag(false),
  P40_42449_c_level_report_BBCH: new Rox.Flag(false),
  PRODUCT_DETAILS_V2: new Rox.Flag(false),
  overview_report_limit_date: new Rox.RoxNumber(7),
  P40_43985_c_level_page_size: new Rox.RoxNumber(1),
  P40_43121_control_strategies_with_custom_parameters: new Rox.Flag(false),
  c_level_report_page_size_kpis: new Rox.RoxNumber(1),
  c_level_report_page_size_bbch: new Rox.RoxNumber(1),
  P40_43244_filter_by_strategy: new Rox.Flag(false),
  P40_44078_blocked_fields_report: new Rox.Flag(false),
  P40_44205_use_new_yield_estimation_service: new Rox.Flag(false),
  P40_43874_showing_the_evolution_graph_without_normalize_data: new Rox.Flag(false),
  P40_44286_REGION_TREE_SELECT_ANTD_V5: new Rox.Flag(false),
  P40_44838_DATADOG_RUM: new Rox.Flag(false),
  P40_44838_DATADOG_CONFIG: new Rox.RoxString(''),
  control_strategies_orgs_allowlist: new Rox.RoxString('[]')
};

type SystemFlags = Record<string, string | boolean>;

const mapSystemFeatureFlags = () => {
  const systemFlags: SystemFlags = {} as SystemFlags;

  Object.keys(featureFlags).forEach(flag => {
    const roxFlag = featureFlags[flag];

    if (
      (roxFlag as unknown as Record<'_type', string>)._type === 'string' ||
      (roxFlag as unknown as Record<'_type', string>)._type === 'number'
    ) {
      systemFlags[flag] = (roxFlag as Rox.RoxString).getValue();
    } else {
      systemFlags[flag] = (roxFlag as Rox.Flag).isEnabled();
    }
  });

  return systemFlags;
};

const useLoadFeatureFlags = () => {
  const [rolloutLoaded, setRolloutLoaded] = useState(false);
  const { companyId, propertyId } = useParams();
  const dispatch = useDispatch();

  const onRoxReady = () => {
    setRolloutLoaded(true);
  };

  const initRollout = useCallback(async () => {
    if (!Rox.flags.length) {
      Rox.register('', featureFlags);
    }
    await Rox.setup(ROLLOUT_KEY ?? '', {
      configurationFetchedHandler: onRoxReady
    });
  }, []);

  useEffect(() => {
    void initRollout();
  }, [initRollout]);

  useEffect(() => {
    if (!rolloutLoaded) {
      return;
    }
    dispatch(SetSystemFlags(mapSystemFeatureFlags()));
  }, [companyId, dispatch, propertyId, rolloutLoaded]);
};

export { useLoadFeatureFlags };
