import { COLOR_OPTIONS } from 'config/style';

interface CropSugarcaneProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropSugarcane = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropSugarcaneProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.5 1C5.22386 1 5 1.22386 5 1.5V6.5C5 6.77614 5.22386 7 5.5 7H6.5C6.77614 7 7 6.77614 7 6.5V1.5C7 1.22386 6.77614 1 6.5 1H5.5Z'
      fill={color}
    />
    <path
      d='M5.5 8C5.22386 8 5 8.22386 5 8.5V14.5C5 14.7761 5.22386 15 5.5 15H6.5C6.77614 15 7 14.7761 7 14.5V8.5C7 8.22386 6.77614 8 6.5 8H5.5Z'
      fill={color}
    />
    <path
      d='M9 1.5C9 1.22386 9.22386 1 9.5 1H10.5C10.7761 1 11 1.22386 11 1.5V8.5C11 8.77614 10.7761 9 10.5 9H9.5C9.22386 9 9 8.77614 9 8.5V1.5Z'
      fill={color}
    />
    <path
      d='M9.5 10C9.22386 10 9 10.2239 9 10.5V14.5C9 14.7761 9.22386 15 9.5 15H10.5C10.7761 15 11 14.7761 11 14.5V10.5C11 10.2239 10.7761 10 10.5 10H9.5Z'
      fill={color}
    />
    <path
      d='M11.7403 7.23652C11.7106 7.27165 11.6535 7.28596 11.6031 7.27088C11.5439 7.25318 11.5143 7.20152 11.5371 7.15548C12.8696 4.46009 13.8846 3.49149 14.5821 4.24967C15.2838 5.01239 15.1018 8.83361 14.0361 11.0595C14.0179 11.0975 13.969 11.1212 13.9171 11.1172C13.854 11.1123 13.8079 11.0686 13.8142 11.0195C14.0815 8.93448 13.9544 5.64787 13.4329 5.81349C12.9851 5.95571 12.4209 6.43005 11.7403 7.23652Z'
      fill={color}
    />
    <path
      d='M2.56706 2.93724C3.01492 3.08917 3.57914 3.59588 4.25972 4.45739C4.28936 4.49491 4.3465 4.5102 4.39694 4.49409C4.45614 4.47518 4.48568 4.41999 4.46299 4.37079C3.13043 1.49149 2.11539 0.456794 1.41788 1.26671C0.716191 2.08147 0.898189 3.67775 1.96388 6.05553C1.98208 6.09615 2.03098 6.12147 2.08291 6.11717C2.14602 6.11194 2.19209 6.0652 2.18586 6.01277C1.91849 3.7855 2.04555 2.76033 2.56706 2.93724Z'
      fill={color}
    />
  </svg>
);

export default CropSugarcane;
