import _ from 'lodash';
import moment from 'moment';
import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { TasksActionTypes } from './task.actions';
import type { Task, TaskState } from './task.models';

const initialPage = {
  empty: false,
  first: false,
  last: false,
  number: 0,
  number_of_elements: 0,
  size: 0,
  total_elements: 0,
  total_pages: 0
};

function selectTaskUuid(task: Task): string {
  return task.id;
}

function sortByStartAt(a: Task, b: Task): number {
  const parsedA = moment(a.planned_start_date);
  const parsedB = moment(b.planned_start_date);
  return parsedA === parsedB ? 0 : parsedA > parsedB ? 1 : -1;
}

const entity: EntityAdapter<Task> = createEntityAdapter<Task>({
  selectId: selectTaskUuid,
  sortComparer: sortByStartAt
});

const initialState = entity.getInitialState<TaskState>({
  ids: [],
  entities: {},
  page: {
    ...initialPage
  },
  isLoading: false,
  error: null,
  markers: {}
});

export default (state = initialState, action) => {
  switch (action.type) {
    case TasksActionTypes.DELETE_LOCAL_TASK:
      const updatedState: TaskState = {
        ...state,
        page: {
          ...state.page,
          number_of_elements: state.page.number_of_elements - 1,
          size: state.page.size - 1,
          total_elements: state.page.total_elements - 1
        }
      };
      return entity.removeOne(action.payload, updatedState);

    case TasksActionTypes.CREATE_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case TasksActionTypes.CLEAR_MARKERS:
      return {
        ...state,
        markers: {}
      };
    case TasksActionTypes.SET_MARKERS: {
      if (!action.payload.id) {
        return state;
      }
      const { markers } = state;
      if (_.isEqual(markers[action.payload.id] || [], action.payload.markers)) {
        return state;
      }
      const newMarkers = { ...markers };
      newMarkers[action.payload.id] = action.payload.markers;
      return {
        ...state,
        markers: newMarkers
      };
    }

    default:
      return state;
  }
};
