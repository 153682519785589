const SvgWeatherSnow = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M7.50001 11.8H6.20001C4.00001 11.8 2.20001 9.99999 2.20001 7.79999C2.20001 5.69999 3.90001 3.89999 6.00001 3.79999C6.90001 2.29999 8.40001 1.39999 10.1 1.39999C12.7 1.39999 14.9 3.79999 14.9 6.49999H13.1C13.1 4.79999 11.7 3.19999 10.1 3.19999C8.90001 3.19999 7.80001 3.89999 7.30001 4.99999L7.10001 5.59999L6.40001 5.49999H6.30001H6.20001C5.00001 5.49999 4.00001 6.49999 4.00001 7.69999C4.00001 8.89999 5.00001 9.99999 6.20001 9.99999H7.50001V11.8Z'
      fill='#BDC4D1'
    />
    <path
      d='M17.6 18.2H8.00001C5.90001 18.2 4.20001 16.5 4.20001 14.4C4.20001 12.6 5.50001 11.1 7.10001 10.7C7.50001 8 9.90001 5.8 12.8 5.8C14.9 5.8 16.8 6.9 17.8 8.7C20.3 8.8 22.4 10.9 22.4 13.4C22.3 16.1 20.2 18.2 17.6 18.2ZM8.00001 12.4C6.90001 12.4 6.00001 13.3 6.00001 14.4C6.00001 15.5 6.90001 16.4 8.00001 16.4H17.6C19.2 16.4 20.6 15.1 20.6 13.4C20.6 11.8 19.3 10.4 17.6 10.4H17.5C17.4 10.4 17.4 10.4 17.3 10.4L16.7 10.5L16.4 10C15.8 8.5 14.4 7.6 12.8 7.6C10.6 7.6 8.90001 9.4 8.90001 11.5V12.4H8.00001Z'
      fill='#828B99'
    />
    <path
      d='M6.55001 19.45C6.30148 19.2015 5.89854 19.2015 5.65001 19.45C5.40148 19.6985 5.40148 20.1015 5.65001 20.35L6.00001 20.7L5.59798 21.1523C5.37451 21.4037 5.38573 21.7857 5.62359 22.0236C5.88206 22.282 6.30479 22.2696 6.54764 21.9964L6.90001 21.6L7.35001 22.05C7.59854 22.2985 8.00148 22.2985 8.25001 22.05C8.49854 21.8015 8.49854 21.3985 8.25001 21.15L7.80001 20.7L8.19643 20.3476C8.46964 20.1048 8.48206 19.682 8.22359 19.4236C7.98573 19.1857 7.60371 19.1745 7.3523 19.398L6.90001 19.8L6.55001 19.45Z'
      fill='#92B0ED'
    />
    <path
      d='M12.25 19.45C12.0015 19.2015 11.5986 19.2015 11.35 19.45C11.1015 19.6985 11.1015 20.1015 11.35 20.35L11.7 20.7L11.298 21.1523C11.0745 21.4037 11.0857 21.7857 11.3236 22.0236C11.5821 22.282 12.0048 22.2696 12.2477 21.9964L12.6 21.6L13.05 22.05C13.2986 22.2985 13.7015 22.2985 13.95 22.05C14.1986 21.8015 14.1986 21.3985 13.95 21.15L13.5 20.7L13.8964 20.3476C14.1696 20.1048 14.1821 19.682 13.9236 19.4236C13.6857 19.1857 13.3037 19.1745 13.0523 19.398L12.6 19.8L12.25 19.45Z'
      fill='#92B0ED'
    />
    <path
      d='M17.95 19.45C17.7014 19.2015 17.2985 19.2015 17.05 19.45C16.8014 19.6985 16.8014 20.1015 17.05 20.35L17.4 20.7L16.9979 21.1523C16.7745 21.4037 16.7857 21.7857 17.0236 22.0236C17.282 22.282 17.7048 22.2696 17.9476 21.9964L18.3 21.6L18.75 22.05C18.9985 22.2985 19.4014 22.2985 19.65 22.05C19.8985 21.8015 19.8985 21.3985 19.65 21.15L19.2 20.7L19.5964 20.3476C19.8696 20.1048 19.882 19.682 19.6236 19.4236C19.3857 19.1857 19.0037 19.1745 18.7523 19.398L18.3 19.8L17.95 19.45Z'
      fill='#92B0ED'
    />
  </svg>
);

export default SvgWeatherSnow;
