const SvgWeatherDailySunny = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M12.0904 17.0598C9.18141 17.0598 6.81641 14.6938 6.81641 11.7838C6.81641 8.87481 9.18141 6.50781 12.0904 6.50781C14.9994 6.50781 17.3654 8.87481 17.3654 11.7838C17.3654 14.6928 14.9994 17.0598 12.0904 17.0598ZM12.0904 8.33881C10.1904 8.33881 8.64541 9.88381 8.64541 11.7828C8.64541 13.6858 10.1894 15.2328 12.0904 15.2328C13.9914 15.2328 15.5374 13.6858 15.5374 11.7828C15.5374 9.88381 13.9904 8.33881 12.0904 8.33881Z'
      fill='#F0C355'
    />
    <path d='M4.14034 10.8984H1.06934V12.7274H4.14034V10.8984Z' fill='#F0C355' />
    <path d='M22.9377 10.8984H19.7227V12.7274H22.9377V10.8984Z' fill='#F0C355' />
    <path d='M12.9579 1.07031H11.1289V4.28531H12.9579V1.07031Z' fill='#F0C355' />
    <path d='M12.9579 19.7266H11.1289V22.9376H12.9579V19.7266Z' fill='#F0C355' />
    <path d='M3.8693 4.80486L6.1416 7.07812L7.43455 5.78573L5.16225 3.51247L3.8693 4.80486Z' fill='#F0C355' />
    <path d='M17.1173 18.0538L19.3896 20.3281L20.6835 19.0354L18.4111 16.7611L17.1173 18.0538Z' fill='#F0C355' />
    <path d='M16.9541 5.94819L18.248 7.24219L20.52 4.97028L19.226 3.67628L16.9541 5.94819Z' fill='#F0C355' />
    <path d='M3.70475 19.198L5 20.4922L7.27024 18.22L5.97499 16.9259L3.70475 19.198Z' fill='#F0C355' />
  </svg>
);

export default SvgWeatherDailySunny;
