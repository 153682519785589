import type { Action } from 'core/utils/basic.models';
import type { ConfigReportsRequestType, Report, ReportsRequestType } from './powerbi-reports.models';

export const PowerBIReportsActionsTypes = {
  CLEAR_REPORTS: '[Reports] Clear Reports',
  LOAD_REPORTS: '[Reports] Load Reports',
  LOAD_REPORTS_SUCCESS: '[Reports] Load Reports Success',
  LOAD_REPORTS_FAILURE: '[Reports] Load Reports Failure',
  LOAD_CONFIG_REPORTS: '[Reports] Load Config Reports',
  LOAD_CONFIG_REPORTS_SUCCESS: '[Reports] Load Config Reports Success',
  LOAD_CONFIG_REPORTS_FAILURE: '[Reports] Load Config Reports Failure'
};

export const LoadReports = (organizationId, configID): Action<ReportsRequestType> => {
  return {
    type: PowerBIReportsActionsTypes.LOAD_REPORTS,
    payload: { organizationId, configID }
  };
};

export const LoadReportsSuccess = (reports): Action<Report[]> => {
  return {
    type: PowerBIReportsActionsTypes.LOAD_REPORTS_SUCCESS,
    payload: reports
  };
};

export const LoadReportsFailure = (error: string): Action<string> => {
  return {
    type: PowerBIReportsActionsTypes.LOAD_REPORTS_FAILURE,
    payload: error
  };
};

export const LoadConfigReports = (organizationId: string): Action<ConfigReportsRequestType> => {
  return {
    type: PowerBIReportsActionsTypes.LOAD_CONFIG_REPORTS,
    payload: { organizationId }
  };
};

export const LoadConfigReportsSuccess = (reports): Action<Report> => {
  return {
    type: PowerBIReportsActionsTypes.LOAD_CONFIG_REPORTS_SUCCESS,
    payload: reports
  };
};

export const LoadConfigReportsFailure = (error: string): Action<string> => {
  return {
    type: PowerBIReportsActionsTypes.LOAD_CONFIG_REPORTS_FAILURE,
    payload: error
  };
};
