import { Divider } from 'antd';
import { COLOR_OPTIONS } from 'config/style';
import type { Dictionary } from 'config/types';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import { UnitSystem, formatArea, getCurrentUnitSystem, includesName, mapEntitiesByAttr } from 'core/utils/functions';
import type { CurrentSeasonArea } from 'entities/property/property.models';
import type { Region, RegionGeometry } from 'entities/region/region.models';
import { RegionType } from 'entities/region/region.models';
import { compact, get, includes, intersection, isEmpty, isNil, keyBy, map, reduce } from 'lodash';
import { useMemo } from 'react';
import type { RegionTreeItem, RegionTreeNode, RegionTreeType, TreeNodeFilterProp } from './region-tree-select.models';

export const isAreaInActiveSeason = (
  regionId: string,
  currentSeasonAreasDict?: Dictionary<CurrentSeasonArea>,
  selectedSeasonsIds?: string[]
) => {
  const regionSeasonArea = currentSeasonAreasDict?.[regionId];

  return includes(selectedSeasonsIds, regionSeasonArea?.seasonId);
};

export const sumAreasChildren = (children: RegionTreeItem[]): number => reduce(children, (sum, child) => sum + (child?.totalArea ?? 0), 0);

export const parseTitleRegion = (region: Region, childrenTotalArea: number): JSX.Element => {
  const metric = formatArea(childrenTotalArea, 0);
  const currentUnitSystem = getCurrentUnitSystem();
  const metricUnit = currentUnitSystem === UnitSystem.IMPERIAL ? 'ac' : 'ha';
  const isArea = region?.type === RegionType.AREA;
  const currentSeasonArea = region?.seasons[0];
  return (
    <div className='sd-region-tree-select__tree__child'>
      {isArea && (
        <>
          <STTypo type={TypeOptions.H6}>{region?.name}</STTypo>

          <STTypo type={TypeOptions.C1}>
            {`${metric} ${metricUnit} • `}
            <STTypo type={TypeOptions.C1} uppercase>
              {currentSeasonArea?.name}
            </STTypo>
          </STTypo>
          <Divider />
        </>
      )}
      {!isArea && (
        <STTypo color={COLOR_OPTIONS.WHITE} type={TypeOptions.H6}>
          {region?.name}
        </STTypo>
      )}
    </div>
  );
};

const geometryHasNoCoordinates = (isFilterGeometry: boolean | undefined, isArea: boolean, geometry?: RegionGeometry) => {
  return (
    !!isFilterGeometry &&
    isArea &&
    geometry?.type === 'Feature' &&
    geometry.geometry.type !== 'GeometryCollection' &&
    !geometry.geometry.coordinates.length
  );
};

export const selectedSeasonsHasNoValidArea = (
  regionSeasons: string[],
  selectedSeasonsIds: string[],
  isChild: boolean,
  isRoot: boolean
): boolean => {
  return isChild && !isRoot && intersection(regionSeasons, selectedSeasonsIds).length === 0;
};

const isRegionAndHasNoChildren = (region: Region) => region?.type === RegionType.REGION && (region?.children?.length ?? 0) === 0;

const isNotChildAndNotRoot = (isChild: boolean, isRoot: boolean) => !isChild && !isRoot;

const fieldIsWithinActiveSeasonWithCurrentSeason = ({
  isChild,
  isArea,
  filterByCurrentSeasonArea,
  currentSeasonAreasDict,
  region,
  selectedSeasonsIds
}: {
  isChild: boolean;
  isArea: boolean;
  filterByCurrentSeasonArea?: boolean;
  currentSeasonAreasDict?: Dictionary<CurrentSeasonArea>;
  region: Region;
  selectedSeasonsIds: string[];
}) => {
  return (
    isChild &&
    isArea &&
    filterByCurrentSeasonArea &&
    !isNil(currentSeasonAreasDict) &&
    !isAreaInActiveSeason(region?.id, currentSeasonAreasDict, selectedSeasonsIds)
  );
};

const isRegionHasNoChildrenWithActiveSeason = (
  region: Region,
  children: RegionTreeItem[],
  filterByCurrentSeasonArea: boolean | undefined,
  currentSeasonAreasDict?: Dictionary<CurrentSeasonArea>
) => {
  return region?.type === RegionType.REGION && isEmpty(children) && filterByCurrentSeasonArea && !isNil(currentSeasonAreasDict);
};

export const parseRegionsRecursively = ({
  isFilterGeometry,
  regions,
  region,
  selectedSeasonsIds,
  type,
  parentId,
  currentSeasonAreasDict,
  filterByCurrentSeasonArea,
  rootRegionSeasonIds
}: {
  isFilterGeometry: boolean | undefined;
  regions: Dictionary<Region>;
  region: Region;
  selectedSeasonsIds: string[];
  rootRegionSeasonIds: string[];
  type: RegionTreeType;
  parentId?: string | null;
  currentSeasonAreasDict?: Dictionary<CurrentSeasonArea>;
  filterByCurrentSeasonArea?: boolean;
}): RegionTreeItem | undefined => {
  if (!region) {
    return undefined;
  }
  const isChild = region?.parent_id === parentId;
  const isRoot = isNil(region?.parent_id);
  const isArea = region?.type === RegionType.AREA;

  if (
    geometryHasNoCoordinates(isFilterGeometry, isArea, region?.geometry) ||
    selectedSeasonsHasNoValidArea(rootRegionSeasonIds, selectedSeasonsIds, isChild, isRoot) ||
    isRegionAndHasNoChildren(region) ||
    isNotChildAndNotRoot(isChild, isRoot) ||
    fieldIsWithinActiveSeasonWithCurrentSeason({
      isChild,
      isArea,
      filterByCurrentSeasonArea,
      currentSeasonAreasDict,
      region,
      selectedSeasonsIds
    })
  ) {
    return undefined;
  }

  const children =
    region?.children &&
    compact(
      map(region?.children as string[], childId =>
        parseRegionsRecursively({
          isFilterGeometry,
          regions,
          region: get(regions, childId),
          selectedSeasonsIds,
          type,
          parentId: region?.id,
          currentSeasonAreasDict,
          filterByCurrentSeasonArea,
          rootRegionSeasonIds
        })
      )
    );

  if (isRegionHasNoChildrenWithActiveSeason(region, children, filterByCurrentSeasonArea, currentSeasonAreasDict)) {
    return undefined;
  }

  children?.sort((childA, childB) => childA.name?.localeCompare(childB.name ?? '', 'en', { numeric: true }) ?? 0);

  const isLeaf = region?.type === RegionType.AREA;
  const sumTotalArea = isLeaf ? region?.total_area : sumAreasChildren(children ?? []);
  const title = type === 'default' ? region?.name : parseTitleRegion(region, sumTotalArea);

  return {
    key: region.id,
    value: region.id,
    name: region.name,
    isLeaf,
    children,
    title,
    totalArea: sumTotalArea
  };
};

const MAX_AMOUNT_NODES = 10;

export const getTreeDefaultExpandedKeys = (
  defaultExpanded: boolean,
  regions: Dictionary<Region>,
  rootRegionId?: string
): string[] | undefined => {
  if (!defaultExpanded || !rootRegionId || isEmpty(regions)) {
    return undefined;
  }

  const children = regions?.[rootRegionId]?.children;
  const isGreaterMaxNodes = children?.length > MAX_AMOUNT_NODES;

  if (isNil(children)) {
    return undefined;
  }

  if (isEmpty(children) || isGreaterMaxNodes) {
    return [rootRegionId];
  }

  return children as string[];
};

export const isAValidItemForSearch = (
  search: string,
  treeNode: RegionTreeNode,
  treeNodeFilterProp: TreeNodeFilterProp,
  regions: Dictionary<Region>
): boolean => {
  const item = treeNode?.props?.[treeNodeFilterProp]?.toLowerCase();
  const filterByName = includes(item, search?.toLowerCase());

  let filterByParentName = false;

  const parentId = regions?.[treeNode?.key]?.parent_id;

  if (parentId) {
    filterByParentName = includesName(regions?.[parentId]?.name, search);
  }

  return filterByName || filterByParentName;
};

const isRootWithoutChildren = (treeData: RegionTreeItem[]) => {
  const root = treeData?.[0];

  return isEmpty(root?.children) || isNil(root?.children);
};

export const useCreateTreeData = (
  type: 'default' | 'dark',
  regions?: Dictionary<Region>,
  rootRegionId?: string,
  selectedSeasonsIds?: string[],
  isFilterGeometry?: boolean,
  currentSeasonAreas?: CurrentSeasonArea[],
  filterByCurrentSeasonArea?: boolean
): RegionTreeItem[] => {
  const treeData = useMemo(() => {
    if (isNil(rootRegionId) || isNil(regions) || isEmpty(regions) || isNil(selectedSeasonsIds)) return [];

    const currentSeasonAreasDict = keyBy(currentSeasonAreas, 'areaId');
    const rootRegion = get(regions, rootRegionId);
    const rootRegionSeasonIds = mapEntitiesByAttr(rootRegion.seasons, 'id');
    const parsedRegions = parseRegionsRecursively({
      isFilterGeometry,
      regions,
      region: rootRegion,
      selectedSeasonsIds,
      type,
      parentId: rootRegionId,
      currentSeasonAreasDict,
      filterByCurrentSeasonArea,
      rootRegionSeasonIds
    });

    return parsedRegions ? [parsedRegions] : [];
  }, [rootRegionId, regions, selectedSeasonsIds, currentSeasonAreas, isFilterGeometry, type, filterByCurrentSeasonArea]);

  return isRootWithoutChildren(treeData) ? [] : treeData;
};
