import uniqueId from 'lodash/uniqueId';
import Photoswipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import type React from 'react';
import { useEffect, useMemo } from 'react';
import './protector-photoswipe.less';
import type { ProtectorPhotoswipeProps } from './protector-photoswipe.models';
import { setMiddleOrClickedImageIndex } from './protector-photoswipe.utils';

export const GALLERY_PREFIX_ID = 'gallery--getting-started-';

export const ProtectorPhotoswipe: React.FC<ProtectorPhotoswipeProps> = ({
  photos,
  testId,
  imageRef,
  clickedImageIndex,
  showHideAnimationType,
  galleryId
}) => {
  const middleResult = useMemo(() => Math.round((photos.length - 1) / 2), [photos]);
  const middle = useMemo(() => (middleResult >= 3 ? middleResult : 0), [middleResult]);

  const lightbox = new PhotoSwipeLightbox({
    gallery: `#${GALLERY_PREFIX_ID}${galleryId}`,
    children: 'a',
    loop: false,
    bgClickAction: undefined,
    pswpModule: Photoswipe,
    showHideAnimationType
  });

  useEffect(() => {
    lightbox.on('uiRegister', () => {
      lightbox.pswp.ui.registerElement({
        name: 'bulletsIndicator',
        className: 'pswp__bullets-indicator',
        appendTo: 'wrapper',
        onInit: (el, pswp) => {
          const mutableDiv = document.createElement('div');
          mutableDiv.classList.add('pswp__bullet-wrapper');
          const images: HTMLImageElement[] = [];

          let prevIndex = -1;

          for (let i = 0; i < pswp.getNumItems(); i++) {
            const mutableImage = document.createElement('img');
            mutableImage.src = photos[i];
            mutableImage.className = 'pswp__bullet';

            mutableImage.onclick = () => {
              pswp.goTo(i);
            };

            mutableDiv.appendChild(mutableImage);
            images.push(mutableImage);
          }

          pswp.on('change', () => {
            if (prevIndex >= 0) {
              images[prevIndex].classList.remove('pswp__bullet--active');
            }

            images[pswp.currIndex].classList.add('pswp__bullet--active');
            prevIndex = pswp.currIndex;

            const mutableWrapper = document.querySelector('.pswp__bullet-wrapper')!;

            if (mutableWrapper) {
              mutableWrapper.style.transform = `translateX(calc((25vw - (${pswp.currIndex} * 96px)) - 40px))`;
            }
          });

          el.appendChild(mutableDiv);
        }
      });
    });

    lightbox.init();

    return () => {
      lightbox.destroy();
    };
  }, [lightbox, photos]);

  if (!photos.length) return null;

  return (
    <div data-testid='protector-photoswipe-images-container'>
      <div className='gallery-image' />
      {photos.map((photo, index) => {
        const UUID = uniqueId();
        let width: number;
        let height: number;
        const mutableImage = document.createElement('img');
        mutableImage.src = photo;

        mutableImage.onload = () => {
          const galleryImage = document.querySelector('.gallery-image');
          if (!galleryImage) return;

          galleryImage.appendChild(mutableImage);
          mutableImage.style.maxWidth = '100vw';
          mutableImage.style.maxHeight = '80vh';

          width = mutableImage.clientWidth;
          height = mutableImage.clientHeight;

          const galleryTagA = document.getElementById(`gallery-tag-a-${index}-${UUID}`);
          if (galleryTagA) {
            galleryTagA.setAttribute('data-pswp-width', String(width));
            galleryTagA.setAttribute('data-pswp-height', String(height));
          }

          galleryImage.removeChild(mutableImage);
        };

        return (
          <a
            ref={setMiddleOrClickedImageIndex({ clickedImageIndex, middle, photosLength: photos.length }) === index ? imageRef : undefined}
            id={`gallery-tag-a-${index}-${UUID}`}
            data-cropped='true'
            key={index}
            href={photo}
            data-testid={`${testId}-${index}-${UUID}`}
            className='gallery-image-link'
            target='_blank'
            rel='noopener noreferrer'>
            <img className='gallery-image-link-image' src={photo} alt='' />
          </a>
        );
      })}
    </div>
  );
};
