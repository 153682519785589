import type { ArgsProps } from 'antd/lib/notification';
import type { Action, DateISOString, Scouter, UUID } from 'core/utils/basic.models';
import type {
  ExternalIntegrationSource,
  SamplingBehaviour,
  StaticPointCreation,
  StaticPointEventType,
  StaticPointType
} from 'entities/static-points/static-points.models';
import type { Spray } from 'pages/timeline/timeline.models';

interface PayloadStationType {
  propertyId: UUID;
}
export type LoadStationType = (propertyId: UUID) => Action<PayloadStationType>;
export type LoadStationSuccessType = (staticPoint: Station[]) => Action<Station[]>;

export interface LoadSeveritiesRequestType {
  startDate: Date;
  endDate: Date;
  templateId: UUID;
  ids: string[];
  allActions: boolean;
  clearCache?: boolean;
}

export type LoadSeveritiesType = ({
  startDate,
  endDate,
  templateId,
  ids,
  allActions,
  clearCache
}: LoadSeveritiesRequestType) => Action<LoadSeveritiesRequestType>;

interface LoadSeveritiesSuccessParamsType {
  severities: AnalyticResultsHistogramResponse[];
  allActions: boolean;
  allIds: string[];
  clearCache?: boolean;
}

export type LoadSeveritiesSuccessType = ({
  severities,
  allActions,
  allIds,
  clearCache
}: LoadSeveritiesSuccessParamsType) => Action<LoadSeveritiesSuccessParamsType>;

export type NoSeveritiesDataToLoadType = (allActions: LoadSeveritiesSuccessParamsType) => Action<LoadSeveritiesSuccessParamsType>;

export type UpsertStationSuccessType = (
  station: Station,
  callback
) => Action<{
  station: Station;
  callback: any;
}>;

export interface StationPayloadType {
  stationType: StaticPointType;
  stationId: UUID;
  actionType?: StationActionType;
  autoCreateTask?: boolean;
  callback: { notification: { error: ArgsProps } };
  integrationSource: ExternalIntegrationSource;
}

export type DeleteStationType = (
  stationType: StaticPointType,
  stationId: UUID,
  integrationSource: ExternalIntegrationSource,
  callback
) => Action<StationPayloadType>;

export type ToggleStationType = (
  stationType: StaticPointType,
  stationId: UUID,
  actionType: StationActionType,
  autoCreateTask: boolean,
  callback: any
) => Action<StationPayloadType>;

export type EditStationType = (
  stationType: StaticPointType,
  station: StaticPointCreation,
  callback
) => Action<{
  stationType: StaticPointType;
  station: StaticPointCreation;
  callback: any;
}>;

export interface Station {
  id: string;
  type: string;
  template_id: string;
  name: string;
  property_id: string;
  areas_ids: UUID[];
  sampling_behaviour: SamplingBehaviour;
  lat: number;
  lng: number;
  description?: string | null;
  installation_date: DateISOString;
  uninstallation_date?: DateISOString | null;
  deleted_date?: DateISOString | null;
  last_event_type?: StaticPointEventType;
  last_event_date: DateISOString;
  last_renewal?: DateISOString | null;
  expiration_date: DateISOString;
  validity_in_days: number;
  view_id?: string | null;
  analytic_results_histogram?: AnalyticResultsHistogram;
  scouter?: Scouter;
  single_use?: boolean;
  integration_source?: ExternalIntegrationSource;
  partNo?: string;
  pestName?: string;
  installed_area?: UUID;
}

export interface AnalyticResultsHistogramResponse {
  trap_id: string;
  analytic_results_histogram: AnalyticResultsHistogram;
  scouter: Scouter;
}

interface AnalyticResultsHistogram {
  result_histogram: HistogramInstance[];
}

interface HistogramInstance {
  sprays: Spray[];
  indicators: HistogramIndicators[];
  phenological_stage: string;
  local_date: DateISOString;
  sampling_behaviour: SamplingBehaviour | null;
  reset: boolean;
}

export interface HistogramIndicators {
  id: UUID;
  liss_value: number;
  name: string;
  value: number;
}

export interface StationResponse {
  content: Station[];
  size: number;
  cursor: string;
  is_last: boolean;
}

export enum StationActionType {
  CREATE = 'CREATE',
  UNINSTALL = 'UNINSTALL',
  DELETE = 'DELETE',
  REINSTALL = 'REINSTALL',
  EDIT = 'EDIT'
}
