const SvgReportCard = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <g clipPath='url(#clip0_11107_51427)'>
      <rect width='32' height='32' rx='8' fill='white' />
      <rect width='32' height='32' rx='4' fill='#DFFBE8' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.5 8.5H11.5C10.675 8.5 10 9.175 10 10V22C10 22.825 10.6675 23.5 11.4925 23.5H20.5C21.325 23.5 22 22.825 22 22V13L17.5 8.5ZM19 16H13V17.5H19V16ZM19 19H13V20.5H19V19ZM11.5 22H20.5V13.75H16.75V10H11.5V22Z'
        fill='#14803C'
      />
    </g>
    <defs>
      <clipPath id='clip0_11107_51427'>
        <rect width='32' height='32' rx='8' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SvgReportCard;
