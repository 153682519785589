import { DeckOfApps } from '@cw-elements/deck-of-apps';
import type React from 'react';

interface TDeckOfApps {
  handleLogout: () => void;
  selectedPropertyId?: string;
  selectedCompanyId?: string;
  workspaceId: string;
}

const DeckOfAppsComponent: React.FC<TDeckOfApps> = ({ handleLogout, selectedPropertyId, selectedCompanyId, workspaceId }) => (
  <div
    style={{
      margin: '0 12px'
    }}>
    <DeckOfApps
      headerSelector='.sd-header'
      workspaceId={workspaceId}
      orgId={selectedCompanyId}
      propertyId={selectedPropertyId}
      onLogout={handleLogout}
    />
  </div>
);

export default DeckOfAppsComponent;
