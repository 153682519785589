import { getCurrentLanguage } from 'core/utils/functions';
import _, { map } from 'lodash';
import moment from 'moment';
import type { Dictionary, EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { createSelector } from 'reselect';
import { selectSelectedSeasons } from '../../core/core.reducer';
import type { Crop } from '../../core/utils/basic.models';
import type { AppState } from '../../redux/app-state';
import { SeasonActionsTypes } from './season.actions';
import { isActive } from './season.functions';
import type { Season, SeasonState } from './season.models';

function selectSeasonUuid(season: Season): string {
  return season.id;
}

function sortByName(a: Season, b: Season): number {
  return a.name.localeCompare(b.name);
}

const entity: EntityAdapter<Season> = createEntityAdapter<Season>({
  selectId: selectSeasonUuid,
  sortComparer: sortByName
});

export const initialState: SeasonState = entity.getInitialState({
  isLoading: false,
  isLoaded: false,
  error: null
});

export default (state = initialState, action): SeasonState => {
  switch (action.type) {
    case SeasonActionsTypes.LOAD_SEASONS:
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    case SeasonActionsTypes.SET_SEASONS_FROM_PROPERTY:
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    case SeasonActionsTypes.LOAD_SEASONS_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        isLoaded: true,
        error: null
      });
    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectSeasonsIds = selectIds;
export const selectSeasonEntities = selectEntities;
export const selectAllSeasons = selectAll;
export const selectSeasonTotal = selectTotal;

export const selectGroupedByYear = (seasons: Season[]): Dictionary<Season[]> => {
  return _.groupBy(seasons, season => {
    const startYear = new Date(season.start_date).toLocaleDateString(getCurrentLanguage(), { year: '2-digit' });
    const endYear = new Date(season.end_date).toLocaleDateString(getCurrentLanguage(), {
      year: '2-digit'
    });
    return startYear === endYear ? startYear : `${startYear}-${endYear}`;
  });
};

export const selectActives = (seasons: Season[]) =>
  seasons.filter(season => {
    return isActive(season);
  });

export const selectYearAndCropFromGroup = (groupedSeasons: Dictionary<Season[]>): Dictionary<Crop[]> => {
  return Object.keys(groupedSeasons).reduce((yearCrop, year) => {
    const seasons = groupedSeasons[year];
    const crops = Object.values(
      seasons.reduce((resultCrops, season) => {
        return { ...resultCrops, [season.crop?.id]: season.crop };
      }, {})
    );
    return { ...yearCrop, [year]: crops };
  }, {});
};

export const getSelectedSeasons = createSelector(
  (state: AppState) => selectEntities(state.entities.season),
  (state: AppState) => selectSelectedSeasons(state.uiState.global),
  (seasons, selectedSeasons): Season[] =>
    selectedSeasons?.map<Season | undefined>(seasonId => seasons[seasonId]).filter(s => s !== undefined) as Season[]
);

export const getSelectedSeasonsIds = createSelector(getSelectedSeasons, seasons => map(seasons, 'id'));

export const getSelectedSeasonCrops = createSelector(
  (state: AppState) => getSelectedSeasons(state),
  (seasons): Crop[] =>
    _.chain(seasons)
      .map<Crop>(season => season.crop)
      .uniqBy('id')
      .value()
);

export const getSeasonsGroupedByYear = createSelector(
  (state: AppState) => selectAllSeasons(state.entities.season),
  (seasons: Season[]) => selectGroupedByYear(seasons)
);

export const getSelectedSeasonsYearAndCrop = createSelector(
  (state: AppState) => getSelectedSeasons(state),
  seasons => selectYearAndCropFromGroup(selectGroupedByYear(seasons))
);

export const getSelectedInactiveSeasonEndDate = createSelector(
  (state: AppState) => getSelectedSeasons(state),
  (seasons): string | undefined => {
    return (seasons?.length === 1 && !isActive(seasons[0]) && moment(seasons[0]?.end_date).format('YYYY-MM-DD')) || undefined;
  }
);

export const getActiveSeasons = createSelector(
  (state: AppState) => selectSeasonEntities(state.entities.season),
  (allSeasons: Dictionary<Season>) => selectActives(Object.values(allSeasons))
);
