import type { UUID } from 'core/utils/basic.models';
import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import { getSelectedProperty } from '../property/property.reducer';
import { StationsActionsTypes } from './stations.actions';
import type { AnalyticResultsHistogramResponse, Station, StationsState } from './stations.models';

function selectStatiPointUuid(staticPoint: Station): string {
  return staticPoint.id;
}

function sortByName(a: Station, b: Station): number {
  if (!a?.name) return 1;
  if (!b?.name) return -1;

  return a.name.localeCompare(b.name);
}

const entity: EntityAdapter<Station> = createEntityAdapter<Station>({
  selectId: selectStatiPointUuid,
  sortComparer: sortByName
});

export const initialState: StationsState = entity.getInitialState({
  isStationsLoading: false,
  isSeveritiesLoading: false,
  isSeveritiesWithAllActionsLoading: false,
  analyticResultsHistogramIds: [],
  analyticResultsHistogramIdsWithAllActions: [],
  error: null
});

const setStaticPointsSeverities = (analyticResultsHistogram: AnalyticResultsHistogramResponse[]) => {
  return analyticResultsHistogram.map(result => ({
    id: result.trap_id,
    changes: {
      analytic_results_histogram: result.analytic_results_histogram,
      scouter: result.scouter
    }
  }));
};

const mergeIds = (payload: any, severityIds?: string[]) => {
  if (payload.clearCache) return payload.allIds;
  if (severityIds) return [...severityIds, ...payload.allIds];
  return payload.allIds;
};

export default (state = initialState, action): StationsState => {
  switch (action.type) {
    case StationsActionsTypes.LOAD_STATIONS:
      return {
        ...state,
        isStationsLoading: true
      };
    case StationsActionsTypes.LOAD_STATIONS_SUCCESS:
      return entity.addMany(action.payload, {
        ...state,
        isStationsLoading: false,
        error: null
      });
    case StationsActionsTypes.LOAD_STATIONS_FAILURE:
      return {
        ...state,
        isStationsLoading: false,
        error: action.payload
      };

    case StationsActionsTypes.LOAD_SEVERITIES:
      return {
        ...state,
        isSeveritiesLoading: !action.payload.allActions,
        isSeveritiesWithAllActionsLoading: action.payload.allActions
      };
    case StationsActionsTypes.LOAD_SEVERITIES_SUCCESS:
      return entity.updateMany(setStaticPointsSeverities(action.payload.severities), {
        ...state,
        isSeveritiesLoading: false,
        isSeveritiesWithAllActionsLoading: false,

        analyticResultsHistogramIds: !action.payload.allMonitoring
          ? mergeIds(action.payload, state.analyticResultsHistogramIds)
          : state.analyticResultsHistogramIds,

        analyticResultsHistogramIdsWithAllActions: action.payload.allMonitoring
          ? mergeIds(action.payload, state.analyticResultsHistogramIdsWithAllActions)
          : state.analyticResultsHistogramIdsWithAllActions,

        error: null
      });

    case StationsActionsTypes.NO_SEVERITIES_TO_LOAD:
      return {
        ...state,
        isSeveritiesLoading: false,
        isSeveritiesWithAllActionsLoading: false,
        error: null
      };

    case StationsActionsTypes.LOAD_SEVERITIES_FAILURE:
      return {
        ...state,
        isStationsLoading: false,
        isSeveritiesWithAllActionsLoading: false,
        error: action.payload
      };

    case StationsActionsTypes.CREATE_STATION_SUCCESS:
    case StationsActionsTypes.EDIT_STATION_SUCCESS:
      return entity.upsertOne(action.payload.station, {
        ...state,
        isLoading: false,
        error: null
      });

    case StationsActionsTypes.EDIT_STATION:
      return {
        ...state,
        error: null
      };

    case StationsActionsTypes.EDIT_STATION_FAILED:
      return {
        ...state,
        error: 'EDIT_STATION_FAILED'
      };

    case StationsActionsTypes.DELETE_STATION_SUCCESS:
      return entity.removeOne(action.payload.stationId, {
        ...state,
        isLoading: false,
        error: null
      });

    case StationsActionsTypes.TOGGLE_STATION_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });

    default:
      return state;
  }
};

const { selectAll } = entity.getSelectors();

export const selectStation = (stationId: UUID) =>
  createSelector(
    (state: AppState) => selectAll(state.entities.stations),
    stations => stations.find(station => station.id === stationId)
  );

export const selectStations = createSelector(
  (state: AppState) => selectAll(state.entities.stations),
  staticPoints => {
    return staticPoints.filter(staticPoint => !!staticPoint?.template_id);
  }
);

export const getStationsBySelectedProperty = createSelector(
  (state: AppState) => selectStations(state),
  (state: AppState) => getSelectedProperty(state),
  (stations, selectedProperty) => stations.filter(station => station.property_id === selectedProperty?.id)
);
