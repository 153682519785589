const SvgNotesGlyph = props => (
  <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.25 7.48104C2.25 3.76135 5.27432 0.75 9 0.75C12.7257 0.75 15.75 3.76135 15.75 7.48104C15.75 10.4316 13.8473 12.9352 11.2027 13.8453C10.976 14.2271 10.633 14.7334 10.3421 15.1529C10.1481 15.4327 9.96456 15.6917 9.82966 15.8807C9.76215 15.9752 9.70669 16.0524 9.668 16.1061L9.62313 16.1683L9.60726 16.1902L9 15.75L8.39287 16.1903L8.37669 16.168L8.33175 16.1057C8.29301 16.0519 8.23746 15.9745 8.16987 15.8798C8.0348 15.6905 7.85106 15.431 7.65694 15.151C7.3662 14.7316 7.02364 14.2259 6.79748 13.8454C4.15281 12.9353 2.25 10.4316 2.25 7.48104ZM9 15.75L8.39287 16.1903L8.99976 17.027L9.60726 16.1902L9 15.75ZM9.00016 14.4552C9.03604 14.4038 9.07256 14.3514 9.10945 14.2982C9.50244 13.7315 9.89038 13.1471 10.022 12.8846L10.1684 12.5927L10.4819 12.501C12.6618 11.8633 14.25 9.85497 14.25 7.48104C14.25 4.59425 11.9017 2.25 9 2.25C6.09827 2.25 3.75 4.59425 3.75 7.48104C3.75 9.85497 5.33818 11.8633 7.51814 12.501L7.83155 12.5927L7.97796 12.8846C8.10838 13.1446 8.49622 13.7288 8.88969 14.2964C8.92698 14.3502 8.9639 14.4033 9.00016 14.4552Z'
    />
    <path d='M6 9.75H9.75V8.25H6V9.75Z' />
    <path d='M6 6.75H12V5.25H6V6.75Z' />
  </svg>
);

export default SvgNotesGlyph;
