import axios from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import type { UUID, V4Page } from 'core/utils/basic.models';
import type { FileContent, FileModel, UploadFileItem } from './upload-file.models';

const protectorApiUrl = PROTECTOR_API_URL;
const baseLayers = `${protectorApiUrl}/v1/layers`;
const uploadFileUrl = `${baseLayers}/create`;
const getMetadatas = `${baseLayers}/metadatas`;
// const individualFile = (id: string) => `${baseLayers}/${id}`;
const individualFile = (id: string) => `${baseLayers}/${id}?as_data_url=true`;

export const getUploadFileList = (propertyId: UUID) => {
  return axios.get<V4Page<UploadFileItem>>(`${getMetadatas}?property_id=${propertyId}&page=0&size=999`);
};

export const getUploadFileListImages = (layerId: UUID) => {
  return axios.get<FileModel>(individualFile(layerId));
};

export const postFile = (body: FileContent) => {
  const formData = new FormData();
  formData.append('file', body.file);
  formData.append('metadata', JSON.stringify(body.metadata));
  return axios.post<UploadFileItem>(uploadFileUrl, formData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${(formData as any)._boundary}`
    }
  });
};

export const deleteUploadFileListImages = (layerId: UUID) => {
  return axios.delete<object>(individualFile(layerId));
};

export const putUploadFileListImages = (layerId: UUID, body: UploadFileItem) => {
  return axios.put<object>(individualFile(layerId), body);
};
