import * as numeral from 'numeral';
import 'numeral/locales/pt-br';
import 'numeral/locales/es';
import { getCurrentLanguage } from 'core/utils/functions';

let locale = getCurrentLanguage();

switch (locale) {
  case 'pt':
  case 'pt-br':
    locale = 'pt-br';
    break;
  case 'es':
  case 'es-AR':
    locale = 'es';
    break;
  default:
    locale = 'en';
}

numeral.locale(locale);
