import { dateTimeToDate, getDifferenceInDays } from 'core/utils/date';
import type { LicensingStatusAppsContractResponse } from 'entities/company/company.models';

export const handleLastModalCloseVisibility = (
  lastModalClosedDate: string,
  remainingDays: number,
  setClosedModal: (value: boolean) => void,
  setShowModal: (value: boolean) => void
): void => {
  const today = dateTimeToDate(new Date());
  const differenceFromLastClosed = getDifferenceInDays(new Date(lastModalClosedDate), today);
  if ((remainingDays > 3 && differenceFromLastClosed <= 2) || (remainingDays <= 3 && differenceFromLastClosed < 1)) {
    setClosedModal(true);
  } else setShowModal(true);
};

export const handleInitialModalVisibility = (
  remainingDays: number,
  contract: LicensingStatusAppsContractResponse,
  setClosedModal: (value: boolean) => void,
  setShowModal: (value: boolean) => void
): void => {
  if (remainingDays > 15) return;
  const lastModalClosedDate = localStorage.getItem(`last-closed-modal-date-${contract.id}`);
  if (lastModalClosedDate && remainingDays >= 0) {
    handleLastModalCloseVisibility(lastModalClosedDate, remainingDays, setClosedModal, setShowModal);
  } else {
    setShowModal(true);
  }
};

export const getRemainingContractDays = (contract: LicensingStatusAppsContractResponse): number => {
  const today = dateTimeToDate(new Date());
  return getDifferenceInDays(today, dateTimeToDate(new Date(contract.end)));
};
