import { COLOR_OPTIONS } from 'config/style';

export const colorPalette = [
  COLOR_OPTIONS.BLUE_60,
  COLOR_OPTIONS.GREEN_40,
  COLOR_OPTIONS.PURPLE_70,
  COLOR_OPTIONS.TEAL_30,
  COLOR_OPTIONS.ORANGE_40,
  COLOR_OPTIONS.PURPLE_50,
  COLOR_OPTIONS.PINK_30,
  COLOR_OPTIONS.BLUE_70,
  COLOR_OPTIONS.YELLOW_50,
  COLOR_OPTIONS.TEAL_50,
  COLOR_OPTIONS.YELLOW_30,
  COLOR_OPTIONS.BLUE_20,
  COLOR_OPTIONS.GREEN_70,
  COLOR_OPTIONS.PURPLE_90,
  COLOR_OPTIONS.TEAL_20,
  COLOR_OPTIONS.ORANGE_60,
  COLOR_OPTIONS.PURPLE_30,
  COLOR_OPTIONS.PINK_60,
  COLOR_OPTIONS.BLUE_90,
  COLOR_OPTIONS.YELLOW_80,
  COLOR_OPTIONS.TEAL_70,
  COLOR_OPTIONS.YELLOW_60
] as const;

export const teamColorPalette = [
  COLOR_OPTIONS.YELLOW_50,
  COLOR_OPTIONS.PURPLE_70,
  COLOR_OPTIONS.GREEN_40,
  COLOR_OPTIONS.BLUE_60,
  COLOR_OPTIONS.TEAL_30,
  COLOR_OPTIONS.PINK_30,
  COLOR_OPTIONS.ORANGE_40,
  COLOR_OPTIONS.TEAL_50,
  COLOR_OPTIONS.PURPLE_50,
  COLOR_OPTIONS.YELLOW_30,
  COLOR_OPTIONS.BLUE_70,
  COLOR_OPTIONS.GREEN_70,
  COLOR_OPTIONS.RED_70,
  COLOR_OPTIONS.BLUE_20,
  COLOR_OPTIONS.ORANGE_60,
  COLOR_OPTIONS.PURPLE_90,
  COLOR_OPTIONS.TEAL_20,
  COLOR_OPTIONS.YELLOW_80,
  COLOR_OPTIONS.PURPLE_30,
  COLOR_OPTIONS.PINK_60,
  COLOR_OPTIONS.YELLOW_60,
  COLOR_OPTIONS.TEAL_70
] as const;

export const getColor = (indexColor: number, colors = colorPalette): string => {
  return colors[indexColor % colorPalette.length];
};
