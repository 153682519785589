import { COLOR_OPTIONS } from 'config/style';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import { getInitials } from 'core/utils/functions';
import { isEmpty } from 'lodash';
import type React from 'react';
import { CLASS_NAME_PREFIX } from './area-day-info.events-annotations';

interface InfoScouterProps {
  name?: string;
}

export const InfoScouter: React.FC<InfoScouterProps> = ({ name }) => {
  if (!name || isEmpty(name)) return null;

  return (
    <div data-testid='info-scouter-container' className={`${CLASS_NAME_PREFIX}__container__info-annotation__scouter-container`}>
      <div className={`${CLASS_NAME_PREFIX}__container__info-annotation__scouter-container__scouter-initials`}>
        <STTypo data-testid='info-scouter-initials' type={TypeOptions.C2} color={COLOR_OPTIONS.SECONDARY}>
          {getInitials(name)}
        </STTypo>
      </div>
      <STTypo data-testid='info-scouter-name' type={TypeOptions.P1} color={COLOR_OPTIONS.PRIMARY}>
        {name}
      </STTypo>
    </div>
  );
};
