import { initialPage } from 'core/utils/basic.models';
import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { CropActionsTypes } from './crop.actions';
import type { Crop, CropState } from './crop.models';

function selectCropUuid(crop: Crop): string {
  return crop.id;
}

const entity: EntityAdapter<Crop> = createEntityAdapter<Crop>({
  selectId: selectCropUuid
  // sortComparer: sortByName,
});

const initialState = entity.getInitialState<CropState>({
  ids: [],
  entities: {},
  page: {
    ...initialPage
  },
  isLoading: false,
  error: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case CropActionsTypes.LOAD_CROP:
      return {
        ...state,
        isLoading: true
      };
    case CropActionsTypes.LOAD_CROP_SUCCESS:
      return entity.addMany(action.payload.content, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });
    case CropActionsTypes.LOAD_CROP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CropActionsTypes.LOAD_CROP_VARIETIES:
      return {
        ...state,
        isLoading: true
      };
    case CropActionsTypes.LOAD_CROP_VARIETIES_PAGINATED:
      return {
        ...state,
        isLoading: true
      };
    case CropActionsTypes.LOAD_CROP_VARIETIES_PAGINATED_SUCCESS:
      return entity.addMany(action.payload, {
        ...state,
        page: {
          ...state.page,
          mutableContent: action.payload
        },
        isLoading: false,
        error: null
      });
    case CropActionsTypes.LOAD_CROP_VARIETIES_PAGINATED_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CropActionsTypes.LOAD_CROPS_BY_IDS_SUCCESS:
      return entity.addMany(action.payload, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });
    case CropActionsTypes.LOAD_CROPS_BY_IDS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CropActionsTypes.REMOVE_ALL_CROPS:
      return entity.removeAll({
        ...state,
        isLoading: false,
        error: action.payload
      });
    default:
      return state;
  }
};
