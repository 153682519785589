import type {
  LoadCropsByIdsSuccessType,
  LoadCropsByIdsType,
  LoadCropSuccessType,
  LoadCropType,
  LoadCropVarietiesPaginatedSuccessType,
  LoadCropVarietiesType,
  LoadFailureType,
  RemoveAllCrops
} from './crop.models';

export const CropActionsTypes = {
  LOAD_CROP: '[Crop] Load Crop',
  LOAD_CROP_SUCCESS: '[Crop] Load Crop Success',
  LOAD_CROP_FAILURE: '[Crop] Load Crop Failure',

  LOAD_CROP_VARIETIES: '[Crop] Load Crop Varieties',
  LOAD_CROP_VARIETIES_SUCCESS: '[Crop] Load Crop Varieties Success',
  LOAD_CROP_VARIETIES_FAILURE: '[Crop] Load Crop Varieties Failure',

  LOAD_CROP_VARIETIES_PAGINATED: '[Crop] Load Crop Varieties Paginated',
  LOAD_CROP_VARIETIES_PAGINATED_SUCCESS: '[Crop] Load Crop Varieties Paginated Success',
  LOAD_CROP_VARIETIES_PAGINATED_FAILURE: '[Crop] Load Crop Varieties Paginated Failure',

  LOAD_CROPS_BY_IDS: '[Crop] Load Crops by IDs',
  LOAD_CROPS_BY_IDS_SUCCESS: '[Crop] Load Crops by IDs Success',
  LOAD_CROPS_BY_IDS_FAILURE: '[Crop] Load Crops by IDs Failure',

  REMOVE_ALL_CROPS: '[Crop] Remove All Crops'
};

export const LoadCrop: LoadCropType = () => {
  return {
    type: CropActionsTypes.LOAD_CROP
  };
};

export const LoadCropSuccess: LoadCropSuccessType = cropPage => {
  return {
    type: CropActionsTypes.LOAD_CROP_SUCCESS,
    payload: cropPage
  };
};

export const LoadCropFailure: LoadFailureType = error => {
  return {
    type: CropActionsTypes.LOAD_CROP_FAILURE,
    payload: error
  };
};

export const LoadCropVarieties: LoadCropVarietiesType = request => {
  return {
    type: CropActionsTypes.LOAD_CROP_VARIETIES,
    payload: request
  };
};

export const LoadCropVarietiesPaginatedSuccess: LoadCropVarietiesPaginatedSuccessType = crops => {
  return {
    type: CropActionsTypes.LOAD_CROP_VARIETIES_PAGINATED_SUCCESS,
    payload: crops
  };
};

export const LoadCropVarietiesPaginatedFailure: LoadFailureType = (error: any) => {
  return {
    type: CropActionsTypes.LOAD_CROP_VARIETIES_PAGINATED_FAILURE,
    payload: error
  };
};

export const LoadCropsByIds: LoadCropsByIdsType = ids => {
  return {
    type: CropActionsTypes.LOAD_CROPS_BY_IDS,
    payload: ids
  };
};

export const LoadCropsByIdsSuccess: LoadCropsByIdsSuccessType = crops => {
  return {
    type: CropActionsTypes.LOAD_CROPS_BY_IDS_SUCCESS,
    payload: crops
  };
};

export const LoadCropsByIdsFailure: LoadFailureType = error => {
  return {
    type: CropActionsTypes.LOAD_CROPS_BY_IDS_FAILURE,
    payload: error
  };
};

export const LoadRemoveAllCrops: RemoveAllCrops = () => {
  return {
    type: CropActionsTypes.REMOVE_ALL_CROPS
  };
};
