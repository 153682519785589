import type { PieSegment } from 'components/pie-chart/pie-chart.component';
import type { AccordionGroupProps } from 'components/sidebar-accordion/sidebar-accordion.models';
import type { Moment } from 'core/utils/basic.models';
import { getCurrentLanguage, getDateAddedDays, getNameByCurrentLanguage } from 'core/utils/functions';
import type { GeometryProperties, Region } from 'entities/region/region.models';
import type { GetStaticPointResponse, StaticPointCreation, StaticPointTemplate } from 'entities/static-points/static-points.models';
import { StaticPointEventType, StaticPointType } from 'entities/static-points/static-points.models';
import type { HistogramIndicators, Station } from 'entities/stations/stations.models';
import _ from 'lodash';
import moment from 'moment';
import type { FixedPointInfo, Threshold } from 'pages/fixed-points/fixed-points.models';
import { getThresholdsFromAnalyticContextAndIndicatorId } from 'pages/timeline/utils';
import type { Dictionary } from 'config/types';
import type { Nullable } from '../../../core/core.models';
import RegionsUtil from '../../../entities/region/region.utils';
import type { TrapFieldSeverity, TrapsSeverityByField } from '../static-points.models';
import { HUNDRED_PERCENT, StaticPointSeverity, StaticPointSeverityColor, TRAP_PREFIX, TrapIconType } from '../static-points.models';
import type { SelectData } from './components/static-point-map-indicator-filter.models';

/**
 * Get the static points mapbox markers
 *
 * @param templates the static points templates
 * @param staticPoints the static points
 * @param regions Dictionary of the regions
 * @param rootRegion
 * @param selectedRegion Possible selected region
 * @param selectedField the selected field
 * @param showUninstalledTraps flag to show uninstalled traps on map
 * @returns the GeoJSON feature collection of mapbox markers
 */
const mapToFeatureCollection = (
  templates: StaticPointTemplate[],
  staticPoints: Station[],
  regions: Dictionary<Region>,
  rootRegion: Nullable<Region>,
  selectedRegion?: Nullable<Region>,
  selectedField?: Nullable<Region>,
  showUninstalledTraps?: boolean
): GeoJSON.FeatureCollection => {
  const staticPointsInfo = templates.map(t => t.static_point);
  const templatesById = _.mapKeys(staticPointsInfo, 'id');
  const fieldIdsBySelectedRegion = selectedRegion ? RegionsUtil.getFieldsIdsRecursive(selectedRegion, regions) : [];
  let filtered: Station[] | null = null;
  if (staticPoints) {
    filtered = selectedField
      ? staticPoints.filter(staticPoint => selectedField && staticPoint.areas_ids?.includes(selectedField.id))
      : staticPoints.filter(staticPoint => staticPoint.areas_ids?.some(fieldId => fieldIdsBySelectedRegion?.includes(fieldId)));
  }
  if (!showUninstalledTraps && filtered) {
    filtered = filtered.filter(staticPoint => staticPoint.last_event_type !== StaticPointEventType.UNINSTALL);
  }
  return {
    type: 'FeatureCollection',
    features:
      (filtered &&
        filtered
          .filter(station => station.type === StaticPointType.TRAP)
          .map(staticPoint => {
            const template = staticPoint.template_id ? templatesById[staticPoint.template_id] : null;
            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [staticPoint.lng, staticPoint.lat]
              },
              properties: {
                id: staticPoint.id,
                icon: getTrapIcon(template, staticPoint, !selectedField),
                icon_offset: !selectedField ? [0, 0] : [0, -26]
              }
            };
          })) ||
      []
  } as GeoJSON.FeatureCollection;
};

/**
 * Get the trap icon
 *
 * @param template the static point template
 * @param staticPoint the static point
 * @param isCluster the cluster flag
 * @returns the icon name
 */
const getTrapIcon = (template: Nullable<FixedPointInfo>, staticPoint: Station, isCluster: boolean): string => {
  let icon = TRAP_PREFIX;
  icon += isCluster ? TrapIconType.CLUSTER : TrapIconType.PIN;
  icon += getSampleThresholdSeverity(template, staticPoint);
  icon += !isCluster && isTrapExpired(staticPoint) ? TrapIconType.EXPIRED : TrapIconType.NOT_EXPIRED;
  icon += !isCluster && isTrapUninstalled(staticPoint) ? TrapIconType.UNINSTALLED : TrapIconType.NOT_EXPIRED;
  return icon;
};

/**
 * Get the static point severity
 *
 * @param template the static point template
 * @param staticPoint the static point
 * @returns the static point severity
 */
const getSampleThresholdSeverity = (template: Nullable<FixedPointInfo>, staticPoint: Station): StaticPointSeverity => {
  if (!template?.analytic_context) {
    return StaticPointSeverity.ACCEPTANCE;
  }
  if (staticPoint.last_event_type === StaticPointEventType.REINSTALL) {
    return StaticPointSeverity.NO_ACTIVITY;
  }
  const samples = staticPoint?.analytic_results_histogram?.result_histogram;
  const indicatorSamples = samples?.filter(s => s.indicators?.length) || [];
  const customIndicatorsIds = template?.analytic_context.custom_indicator_dtos?.map(ci => ci.indicator_id) || [];
  const orderedSamples = _.sortBy(indicatorSamples, 'local_date').reverse();
  const indicators = orderedSamples && orderedSamples.length ? orderedSamples[0].indicators || [] : [];
  const indicator = getWorstIndicator(indicators.filter(i => customIndicatorsIds.some(id => id === i.id)));
  const threshold = getThresholdsFromAnalyticContextAndIndicatorId(template.analytic_context, indicator?.id);
  return getSeverityByThresholds(indicator, threshold);
};

/**
 * Get worst indicator by liss_value. liss_value is the value of the indicator normalized [0,100].
 * @param indicators indicators to compare
 */
const getWorstIndicator = (indicators: HistogramIndicators[]): HistogramIndicators => {
  return indicators
    .filter(indicator => indicator.liss_value !== null && indicator.liss_value !== undefined)
    .reduce(
      (iterateIndicator, currentIndicator) =>
        !iterateIndicator?.liss_value || iterateIndicator?.liss_value < currentIndicator?.liss_value ? currentIndicator : iterateIndicator,
      {} as any
    );
};

/**
 * Get the severity by thresholds definitions
 *
 * @param indicator the indicator
 * @param thresholds the thresholds
 * @returns the static point severity by thresholds
 */
const getSeverityByThresholds = (indicator: HistogramIndicators, thresholds: Threshold[]): StaticPointSeverity => {
  const thresholdDamage = thresholds?.find(
    threshold => Object.values(threshold.label.localized_strings)[0] === StaticPointSeverity.DAMAGE.toUpperCase()
  );
  if (indicator && thresholds && thresholdDamage && indicator.value >= thresholdDamage.start_value) return StaticPointSeverity.DAMAGE;

  const thresholdControl = thresholds?.find(
    threshold => Object.values(threshold.label.localized_strings)[0] === StaticPointSeverity.CONTROL.toUpperCase()
  );
  if (indicator && thresholds && thresholdControl && indicator.value >= thresholdControl.start_value) return StaticPointSeverity.CONTROL;

  const thresholdAcceptance = thresholds?.find(
    threshold => Object.values(threshold.label.localized_strings)[0] === StaticPointSeverity.ACCEPTANCE.toUpperCase()
  );
  if (
    (indicator && indicator.value === 0) ||
    (thresholds && thresholdAcceptance && indicator && indicator.value < thresholdAcceptance.end_value && indicator.value >= 0)
  )
    return StaticPointSeverity.ACCEPTANCE;

  return StaticPointSeverity.NO_ACTIVITY;
};

/**
 *  Check if a trap is expired
 *
 * @param staticPoint the static point
 * @returns true if trap is expired, false instead.
 */
export const isTrapExpired = (staticPoint: Station): boolean => {
  if (!staticPoint.expiration_date || !!staticPoint.uninstallation_date) {
    return false;
  }
  const expirationDate: Moment = moment(staticPoint.expiration_date);
  return expirationDate.startOf('day').diff(moment().startOf('day'), 'days') < 0;
};

const countBySeverity = (selectedGroup: AccordionGroupProps, severity: StaticPointSeverity): number => {
  return selectedGroup.children.filter(staticPoint => staticPoint.data?.severity === severity).length;
};

const isTrapUninstalled = (trap: Station): boolean => {
  return !!trap.uninstallation_date;
};

/**
 * Calculate the segment value in percentage
 *
 * @param slice the segment slice
 * @param total the total of segments
 * @returns
 */
const getSegmentValue = (slice: number, total: number): number => {
  return (slice / total) * HUNDRED_PERCENT;
};

/**
 * Create pie chart segments from template group
 *
 * @param selectedGroup the selected template group
 * @returns the pie chart segments values and its colors
 */
const createPieSegments = (selectedGroup: AccordionGroupProps, tooltipI18N: Map<StaticPointSeverity, string>): PieSegment[] => {
  const total = selectedGroup.children.length;
  const acceptanceCount = countBySeverity(selectedGroup, StaticPointSeverity.ACCEPTANCE);
  const acceptancePercent = getSegmentValue(acceptanceCount, total);
  const controlCount = countBySeverity(selectedGroup, StaticPointSeverity.CONTROL);
  const controlPercent = getSegmentValue(controlCount, total);
  const damageCount = countBySeverity(selectedGroup, StaticPointSeverity.DAMAGE);
  const damagePercent = getSegmentValue(damageCount, total);
  const noActivityCount = countBySeverity(selectedGroup, StaticPointSeverity.NO_ACTIVITY);
  const noActivityPercent = getSegmentValue(noActivityCount, total);

  return [
    {
      value: acceptancePercent,
      color: StaticPointSeverityColor.ACCEPTANCE,
      tooltip: `${acceptancePercent.toFixed(1)}% ${tooltipI18N.get(StaticPointSeverity.ACCEPTANCE)}` || ''
    },
    {
      value: controlPercent,
      color: StaticPointSeverityColor.CONTROL,
      tooltip: `${controlPercent.toFixed(1)}% ${tooltipI18N.get(StaticPointSeverity.CONTROL)}` || ''
    },
    {
      value: damagePercent,
      color: StaticPointSeverityColor.DAMAGE,
      tooltip: `${damagePercent.toFixed(1)}% ${tooltipI18N.get(StaticPointSeverity.DAMAGE)}` || ''
    },
    {
      value: noActivityPercent,
      color: StaticPointSeverityColor.NO_ACTIVITY,
      tooltip: `${noActivityPercent.toFixed(1)}% ${tooltipI18N.get(StaticPointSeverity.NO_ACTIVITY)}` || ''
    }
  ];
};

/**
 * Map static point to station type
 *
 * @param type static point type
 * @param staticPoint static point used to create a new entry
 * @returns station static point installed
 */
const mapStaticPointToStation = (type: StaticPointType, staticPoint: StaticPointCreation): Station => {
  const {
    id,
    template_id = '',
    name,
    property_id,
    area_ids = [],
    location,
    instaled_at,
    sampling_behaviour,
    time_for_renewal,
    single_use,
    installed_area,
    integration_source
  } = staticPoint;

  return {
    id,
    type,
    template_id,
    name,
    property_id,
    areas_ids: area_ids,
    lat: location.lat,
    lng: location.lng,
    sampling_behaviour,
    installation_date: instaled_at,
    expiration_date: getDateAddedDays(instaled_at, time_for_renewal),
    last_event_date: instaled_at,
    last_event_type: StaticPointEventType.INSTALL,
    uninstallation_date: null,
    validity_in_days: time_for_renewal || 0,
    single_use,
    installed_area,
    integration_source
  };
};

/**
 * Map static point to static point template
 *
 * @param staticPoint static point used to edit an station
 * @param staticPointTemplate static point template with additional infos of static point
 * @returns static point template
 */
const mapStaticPointToStaticPointTemplate = (
  staticPoint: StaticPointCreation,
  staticPointTemplate: GetStaticPointResponse
): GetStaticPointResponse => {
  const language = getCurrentLanguage();
  const languageKey = language === 'pt-BR' ? 'pt' : language;

  const { analytic_context_dto, class_name, clazz, cohese, description, icon_type, inspection_layout_dto, instalation_steps, instaled_at } =
    staticPointTemplate;

  return {
    ...staticPoint,
    analytic_context_dto,
    name: { localized_strings: { [languageKey]: staticPoint.name } },
    class_name,
    clazz,
    cohese,
    description,
    icon_type,
    inspection_layout_dto,
    instalation_steps,
    instaled_at,
    modified_at: moment().format('YYYY-MM-DDTHH:mm:ss.sssZZ'),
    parent_id: staticPoint.template_id
  };
};

/**
 * Map static point template to station type
 *
 * @param staticPointTemplate static point template with additional infos of static point
 * @param station station installed infos used in edition
 * @returns station
 */
const mapStaticPointTemplateToStation = (staticPointTemplate: GetStaticPointResponse, station: StaticPointCreation): Station => {
  const { area_ids = [], location, instaled_at, time_for_renewal } = station;
  const { class_name, modified_at } = staticPointTemplate;

  return _.omit(
    {
      ...station,
      areas_ids: area_ids,
      type: class_name.toUpperCase(),
      lat: location.lat,
      lng: location.lng,
      installation_date: instaled_at,
      expiration_date: getDateAddedDays(instaled_at, time_for_renewal),
      last_event_date: modified_at,
      validity_in_days: time_for_renewal || 0
    },
    ['area_ids', 'location', 'time_for_renewal']
  );
};

const getResultLabel = (trapSeverity: TrapFieldSeverity | undefined) => {
  if (!trapSeverity) {
    return undefined;
  }
  const precision = Number.isInteger(trapSeverity.result) ? 0 : 2;
  const indicatorResult = trapSeverity.result?.toFixed(precision) || 0;
  const unit = getNameByCurrentLanguage(trapSeverity.uom);
  const formatedUnit = unit === '%' ? unit : ` ${unit}`;
  return `${indicatorResult}${formatedUnit}`;
};

const getRegionFeatureCollection = (
  region: Region,
  selectedIndicator: SelectData,
  indicatorsResults: TrapsSeverityByField
): GeoJSON.FeatureCollection<GeoJSON.Polygon, GeometryProperties> => {
  const geometry = region.geometry as GeoJSON.FeatureCollection<GeoJSON.Polygon, GeometryProperties>;
  const features = geometry.features.map(feature => {
    const areaId = feature.properties.id;
    const selectedIndicatorResult = selectedIndicator
      ? indicatorsResults[areaId]?.find(reading => reading.indicator_id === selectedIndicator.id)
      : undefined;
    const severity = selectedIndicatorResult?.severity?.color || undefined;
    const label = getResultLabel(selectedIndicatorResult);
    return {
      ...feature,
      properties: {
        ...feature.properties,
        severityLevelColor: severity,
        indicator_pressure: label
      }
    };
  });
  return {
    type: 'FeatureCollection',
    features
  };
};
const buildRegionsWithSevertityByIndicator = (
  region: Region,
  selectedIndicator: SelectData,
  indicatorsResults: TrapsSeverityByField
): Region => {
  return {
    ...region,
    geometry: getRegionFeatureCollection(region, selectedIndicator, indicatorsResults)
  };
};

/**
 * The StaticPoints mapper class
 */
const StaticPointsMapper = {
  mapToFeatureCollection,
  getSampleThresholdSeverity,
  isTrapExpired,
  createPieSegments,
  mapStaticPointToStation,
  mapStaticPointToStaticPointTemplate,
  mapStaticPointTemplateToStation,
  buildRegionsWithSevertityByIndicator
};

export default StaticPointsMapper;
