import { GALLERY_PREFIX_ID, ProtectorPhotoswipe } from 'components/protector-photoswipe/protector-photoswipe';
import { ShowHideAnimationType } from 'components/protector-photoswipe/protector-photoswipe.models';
import { COLOR_OPTIONS } from 'config/style';
import { selectSystemFlags } from 'core/core.reducer';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnnotationStage } from 'pages/timeline/timeline.models';
import type React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Gallery, Modal } from 'syngenta-digital-react-cropwise-notes';
import { useConfigToNotesModalGallery } from './area-day-info-events-annotations.hooks';
import { CLASS_NAME_PREFIX } from './area-day-info.events-annotations';

export interface InfoPhotosProps {
  photos?: AnnotationStage['medias'];
  image?: AnnotationStage['image'];
  noteId?: string;
  annotation: AnnotationStage;
  fieldName?: string;
}

const Image = styled.div<{ url: string; imageIndex: number; photosLength: number }>`
  width: 64px;
  height: 50px;
  display: flex;
  position: relative;
  border-radius: 4px;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ url, imageIndex, photosLength }) => {
    if (photosLength > 5) {
      if (imageIndex >= 4) return `linear-gradient(0deg, rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.56)), url(${url})`;
    }
    if (imageIndex <= 4) return `url(${url})`;
  }};
`;

const GALLERY_ID = 'info-photos';

export const InfoPhotos: React.FC<InfoPhotosProps> = ({ photos, image, noteId, annotation, fieldName }) => {
  const modal = useRef(null);
  const imageRef = useRef<HTMLAnchorElement>(null);

  const segmentTracking = useSegmentTracking();
  const systemFlags = useSelector(selectSystemFlags);
  const isGalleryV2 = systemFlags?.P40_26233_notes_midia_viewer;

  const { notesMessages, formattedNote, tagsTranslations, galleryEvents, productName } = useConfigToNotesModalGallery({
    productsAvailable: systemFlags?.P40_26233_notes_products_available,
    segmentTracking,
    isGalleryV2,
    annotation
  });

  const [imageClicked, setImageClicked] = useState<number | undefined>();

  const openPhotosModal = (imageIndex: number) => {
    setImageClicked(imageIndex);

    setTimeout(() => {
      imageRef.current?.click();
    }, 0);
  };

  const allPhotos = useMemo(() => {
    const parsedPhotos = photos?.flatMap(photo => photo.url) || [];
    return [...new Set([...(parsedPhotos || []), ...(image ? [image] : [])])];
  }, [image, photos]);

  const hasMoreThanFivePhotos = useMemo(() => !!(allPhotos && allPhotos.length > 5), [allPhotos]);

  if (!allPhotos?.length) return null;

  return (
    <div data-testid='events-annotation-thumbnails-container' className={`${CLASS_NAME_PREFIX}__container__thumbnails-container`}>
      {allPhotos.map((url, imageIndex) => {
        if (hasMoreThanFivePhotos && imageIndex > 4) return null;

        return (
          <Image
            key={imageIndex}
            imageIndex={imageIndex}
            photosLength={allPhotos.length}
            onClick={() => {
              if (isGalleryV2) {
                (modal?.current as any)?.open();
              } else {
                openPhotosModal(imageIndex);
              }
            }}
            onKeyDown={() => {
              if (isGalleryV2) {
                (modal?.current as any)?.open();
              } else {
                openPhotosModal(imageIndex);
              }
            }}
            data-testid={`events-annotation-thumbnail-${url}`}
            className={`${CLASS_NAME_PREFIX}__container__thumbnails-container__thumbnail`}
            url={url}>
            {hasMoreThanFivePhotos && imageIndex === 4 && (
              <STTypo type={TypeOptions.H4} color={COLOR_OPTIONS.WHITE}>{`+${allPhotos.length - 5}`}</STTypo>
            )}
          </Image>
        );
      })}

      {isGalleryV2 && formattedNote ? (
        <Modal ref={modal}>
          <Gallery
            close={() => (modal?.current as any)?.close()}
            tagsTranslations={tagsTranslations}
            galleryEvents={galleryEvents}
            messages={notesMessages}
            fieldName={fieldName}
            product={productName}
            note={formattedNote}
          />
        </Modal>
      ) : (
        <div id={`${GALLERY_PREFIX_ID}${GALLERY_ID}-${noteId}`} data-testid={`${GALLERY_PREFIX_ID}${GALLERY_ID}`}>
          <ProtectorPhotoswipe
            galleryId={`${GALLERY_ID}-${noteId}`}
            clickedImageIndex={imageClicked}
            photos={allPhotos}
            testId='photoswipe'
            imageRef={imageRef}
            showHideAnimationType={ShowHideAnimationType.FADE}
          />
        </div>
      )}
    </div>
  );
};
