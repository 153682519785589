import type { ISTMarkerProps } from 'core/shared/map/map.models';
import type { UUID } from '../../core/utils/basic.models';

export const TasksActionTypes = {
  CREATE_TASK_FAILURE: '[Tasks] Create Task Failure',
  DELETE_LOCAL_TASK: '[Tasks] Delete Local Task',
  SET_MARKERS: '[Tasks] Set Markers',
  CLEAR_MARKERS: '[Tasks] Set Clear Markers'
};

export const DeleteLocalTask = (taskId: UUID) => {
  return {
    type: TasksActionTypes.DELETE_LOCAL_TASK,
    payload: taskId
  };
};

export const SetMarkers = (id: string, markers: ISTMarkerProps[]) => {
  return {
    type: TasksActionTypes.SET_MARKERS,
    payload: { id, markers }
  };
};

export const ClearMarkers = () => {
  return {
    type: TasksActionTypes.CLEAR_MARKERS
  };
};
