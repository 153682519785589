import { getRegionAreasWithParents } from 'core/shared/map/map.functions';
import { hashNumber } from 'core/utils/functions';
import type { ChoroplethLimits, StorageMaxDaysLimit } from 'core/utils/map/choropleth.models';
import type { CurrentSeasonArea } from 'entities/property/property.models';
import { isActive } from 'entities/season/season.functions';
import _ from 'lodash';
import {
  getChoroplethSeverityColor,
  getDaysAfterEmergenceColor,
  getDaysWithoutMonitoringColor,
  getDaysWithoutSprayColor
} from 'pages/timeline/timeline.functions';
import type { Dictionary } from 'redux-ngrx-entity';
import { mapAreaChildren } from './region.epics.utils';
import type { ColorDictionary, CurrentInfo, GeometryProperties, Region } from './region.models';
import { RegionType } from './region.models';
import RegionsUtil from './region.utils';

const flattenArray = <T>(arr: T[], attr = 'children'): T[] => {
  const result: T[] = [];
  const stack: T[] = [...arr];

  while (stack.length) {
    const item = stack.pop()!;
    result.push(item);

    const children = (item as Record<string, unknown>)[attr] as T[];
    if (Array.isArray(children)) {
      stack.push(...children);
    }
  }

  return result;
};

function getRegionsChild(region: Region): Region[] {
  return flattenArray(region.children as Region[]).filter((x: Region) => x.type === RegionType.REGION);
}

export const validateSprayDateTime = (current_info?: CurrentInfo, crop_ended?: boolean): boolean => {
  if (!current_info || crop_ended) return false;

  const sprayDate = current_info?.last_spray?.end_date.slice(0, 10);
  const lastVisit = current_info?.last_visit?.slice(0, 10);
  if (!sprayDate || !lastVisit) return false;
  return sprayDate === lastVisit;
};

export const getRegionFeatureCollection = (
  region: Region,
  areaSeasons?: Dictionary<CurrentSeasonArea[]>,
  selectedSeasons?: string[],
  mapLayersColorDictionary: ColorDictionary = {},
  choroplethLimits?: Dictionary<ChoroplethLimits>,
  storageMaxDays?: StorageMaxDaysLimit,
  optionalMultilayer?: OptionsMultilayer
): GeoJSON.FeatureCollection<GeoJSON.Polygon, GeometryProperties> => {
  const { showMapDAE, showMapSeedsLayer, showMapPhenologicalStageLayer } = optionalMultilayer ?? {};

  const areas = _.uniqBy(getRegionAreasWithParents(region), 'id');

  const isToShowDAE = choroplethLimits && showMapDAE;
  const isToShowSeed = choroplethLimits && showMapSeedsLayer;
  const isToShowPhenologicalStage = choroplethLimits && showMapPhenologicalStageLayer;

  const features = areas.map(area => {
    const crop_ended = areaSeasons ? !areaSeasons[area.id]?.some(as => selectedSeasons?.includes(as.seasonId)) : false;
    const geometry = area.geometry as GeoJSON.Feature<GeoJSON.Polygon, GeometryProperties>;
    const seed = area.current_info?.seeds?.[0];
    const phenologicalStage = area.current_info?.phenological_stage;
    const dae = isToShowDAE ? RegionsUtil.getDAEByEmergenceDay(area.current_info?.emergence_day) : undefined;
    const madeCalculations = area.current_info && choroplethLimits;

    const properties: GeometryProperties = {
      id: area.id,
      name: area.name,
      parent_id: area.parent_id,
      total_area: area.total_area,
      type: area.type,
      parents: area.parents,
      ...(madeCalculations && {
        current_info: {
          ...area.current_info,
          crop_ended
        } as CurrentInfo
      }),
      is_season_active: isActive(area.seasons[0]),
      severityLevelColor: area.current_info && getChoroplethSeverityColor(area, crop_ended),
      hasSprayAfterMonitoring: validateSprayDateTime(area.current_info, crop_ended),
      highlight: false,
      seed,
      ...(isToShowPhenologicalStage && {
        phenologicalStageColor: mapLayersColorDictionary?.phenologicalStageColors?.[phenologicalStage!]?.color,
        phenologicalStage: phenologicalStage !== '' ? phenologicalStage : undefined
      }),
      ...(isToShowSeed && {
        seedColor: mapLayersColorDictionary?.seedsColors?.[seed!]?.color
      }),

      ...(isToShowDAE && {
        daeColor:
          madeCalculations &&
          getDaysAfterEmergenceColor(
            area,
            choroplethLimits,
            crop_ended,
            storageMaxDays?.daysAfterEmergenceMaxColor,
            storageMaxDays?.daysAfterEmergenceMinColor,
            storageMaxDays?.daysAfterEmergenceMin ?? 0
          ),
        dae
      }),
      ...(choroplethLimits && {
        daysWithoutSprayColor:
          madeCalculations && getDaysWithoutSprayColor(area, choroplethLimits, crop_ended, storageMaxDays?.daysWithoutSprayMaxColor),
        daysWithoutMonitoringColor:
          madeCalculations &&
          getDaysWithoutMonitoringColor(area, choroplethLimits, crop_ended, storageMaxDays?.daysWithoutMonitoringMaxColor)
      })
    };
    return { ...geometry, id: hashNumber(area.id), properties };
  });
  return {
    type: 'FeatureCollection',
    features
  };
};

interface OptionsMultilayer {
  showMapSeedsLayer?: boolean;
  showMapPhenologicalStageLayer?: boolean;
  showMapDAE?: boolean;
}

export const buildGeometries = (
  rootRegion: Region,
  areaSeasons: CurrentSeasonArea[],
  selectedSeasons: string[],
  mapLayersColorDictionary: ColorDictionary = {},
  choroplethLimits?: Dictionary<ChoroplethLimits>,
  storageMaxDays?: StorageMaxDaysLimit,
  optionalMultilayer?: OptionsMultilayer
): Region => {
  const dictSeasonAreas = _.groupBy(areaSeasons, 'areaId');

  const allRegions = getRegionsChild(rootRegion).map(child => {
    return {
      ...child,
      children: mapAreaChildren(child.children as Region[], dictSeasonAreas, selectedSeasons),
      geometry: getRegionFeatureCollection(
        child,
        dictSeasonAreas,
        selectedSeasons,
        mapLayersColorDictionary,
        choroplethLimits,
        storageMaxDays,
        optionalMultilayer
      )
    };
  });
  const areas = rootRegion.children?.filter(child => typeof child === 'object' && child?.type === RegionType.AREA) as Region[];

  const areaChildren = mapAreaChildren(areas, dictSeasonAreas, selectedSeasons);
  const result = {
    ...rootRegion,
    children: [...allRegions, ...areaChildren] as Region[],
    geometry: getRegionFeatureCollection(
      rootRegion,
      dictSeasonAreas,
      selectedSeasons,
      mapLayersColorDictionary,
      choroplethLimits,
      storageMaxDays,
      optionalMultilayer
    )
  };
  return result;
};
