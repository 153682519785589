import { selectSelectedProperty } from 'core/core.reducer';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { concat, of } from 'rxjs';
import { catchError, concatMap, flatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  DeleteUploadFileFailure,
  DeleteUploadFileSuccess,
  LoadUploadFileImagesFailure,
  LoadUploadFileImagesSuccess,
  LoadUploadFileList,
  LoadUploadFileListFailure,
  LoadUploadFileListSuccess,
  PostUploadFileFailure,
  PostUploadFileSuccess,
  UpdateUploadFileFailure,
  UpdateUploadFileSuccess,
  UploadFileActionsTypes
} from './upload-file.actions';
import type { UploadFileItem } from './upload-file.models';
import {
  deleteUploadFileListImages,
  getUploadFileList,
  getUploadFileListImages,
  postFile,
  putUploadFileListImages
} from './upload-file.service';

export const handleLoadUploadFileList = (action$, state$) =>
  action$.pipe(
    ofType(UploadFileActionsTypes.LOAD_UPLOADFILE_LIST),
    withLatestFrom(state$.pipe(map((state: AppState) => selectSelectedProperty(state.uiState.global)))),
    concatMap(([request, selectedProperty]) => {
      return getUploadFileList(selectedProperty).pipe(
        map(response => response.data),
        flatMap(list => {
          return concat([LoadUploadFileListSuccess(list.content)]);
        }),
        catchError((error: string) => of(LoadUploadFileListFailure(error)))
      );
    })
  );

export const handleDeleteUploadFile = action$ =>
  action$.pipe(
    ofType(UploadFileActionsTypes.DELETE_UPLOADFILE),
    map((action: any) => action.payload),
    concatMap((request: string) => {
      return deleteUploadFileListImages(request).pipe(
        map(response => response.data),
        flatMap(() => {
          return concat([LoadUploadFileList(), DeleteUploadFileSuccess()]);
        }),
        catchError((error: string) => of(DeleteUploadFileFailure(error)))
      );
    })
  );

export const handleUpdateUploadFile = action$ =>
  action$.pipe(
    ofType(UploadFileActionsTypes.UPDATE_UPLOADFILE),
    map((action: any) => action.payload),
    concatMap((request: UploadFileItem) => {
      return putUploadFileListImages(request.id, request).pipe(
        map(response => response.data),
        flatMap(() => {
          return concat([LoadUploadFileList(), UpdateUploadFileSuccess()]);
        }),
        catchError((error: string) => of(UpdateUploadFileFailure(error)))
      );
    })
  );

export const handleLoadUploadFileListImages = action$ =>
  action$.pipe(
    ofType(UploadFileActionsTypes.LOAD_UPLOADFILE_IMAGES),
    map((action: any) => action.payload),
    mergeMap((payload: any) => {
      return getUploadFileListImages(payload).pipe(
        map(response => response.data),
        flatMap(file => {
          return concat([LoadUploadFileImagesSuccess(file, payload)]);
        }),
        catchError((error: string) => of(LoadUploadFileImagesFailure(error)))
      );
    })
  );

export const handlePostUploadFile = action$ =>
  action$.pipe(
    ofType(UploadFileActionsTypes.POST_UPLOADFILE),
    map((action: any) => action.payload),
    concatMap((payload: any) => {
      return postFile(payload).pipe(
        map(response => response.data),
        flatMap(metadata => {
          return concat([LoadUploadFileList(), PostUploadFileSuccess(metadata)]);
        }),
        catchError((error: string) => of(PostUploadFileFailure(error)))
      );
    })
  );
