import { Input } from 'antd';
import type { TextAreaProps } from 'antd/lib/input';
import { COLOR_OPTIONS } from 'config/style';
import STTypo from 'core/shared/typo';
import type { CssAlignTypo } from 'core/shared/typo/typo.models';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { FC } from 'react';
import './textArea.styles.less';

export interface TextAreaProtectorProps {
  testID: string;
  setValue: (value) => void;
  value: string;
  alignCounter?: CssAlignTypo;
  maxLength: number;
  rows: number;
}

type TextAreaPropsConcat = TextAreaProps & TextAreaProtectorProps;

const STTextArea: FC<TextAreaPropsConcat> = ({ maxLength, value, setValue, testID, rows, alignCounter }) => {
  return (
    <>
      <Input.TextArea onChange={e => setValue(e.target.value)} value={value} data-testid={testID} rows={rows} maxLength={maxLength} />
      <div className='counter'>
        <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60} align={alignCounter}>
          {value.length}/{maxLength}
        </STTypo>
      </div>
    </>
  );
};

export default STTextArea;
