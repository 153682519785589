import { IntegrationKey } from '../../pages/integrations/shared/models';
import type { AreasDiffResponse } from './integrations.models';

export function mapResponseToAreaDiffResponse(response: any[], type: IntegrationKey): AreasDiffResponse[] {
  return response.map<AreasDiffResponse>(r => {
    return {
      atlas_area: r.atlas_area,
      intersection_percentage: r.intersection_percentage,
      integration_area: type === IntegrationKey.JOHN_DEERE ? r.john_deere_area : r.perfect_flight_area
    };
  });
}
