import type { InputDefinitionClassName, InputDefinitionType } from 'entities/methodology-deep/methodology-deep.models';
import type { Dictionary } from 'redux-ngrx-entity';

export const GET_ALL_METHODOLOGIES_BY_SEASON_AREA = 'GET_ALL_METHODOLOGIES_BY_SEASON_AREA';
export const ALL_METHODOLOGIES_IDS = 'ALL_METHODOLOGIES_IDS';
export const ALL_CHARACTERISTICS_BY_METHODOLOGY_ID = 'ALL_CHARACTERISTICS_BY_METHODOLOGY_ID';
export const ALL_PHENOMENA_BY_METHODOLOGY_ID = 'ALL_PHENOMENA_BY_METHODOLOGY_ID';

export interface MethodologiesByPropertyResponse {
  methodologyId: string;
  propertyId: string;
  areaId: string;
  seasonId: string;
}

export interface GetAllCharacteristicsAndPhenomenaParams {
  propertyId?: string;
  seasonId?: string[];
  enabled?: boolean;
}

export interface InputDefinitionValue {
  value: string;
  localized_literal: {
    localized_strings: Dictionary<string>;
  };
}

export interface Characteristic {
  id: string;
  company_id: string;
  parent_id?: string;
  default_indicator_id: string;
  name: {
    localized_strings: Dictionary<string>;
  };
  description: {
    localized_strings: Dictionary<string>;
  };
  tip: {
    localized_strings: Dictionary<string>;
  };
  input_definition: {
    class_name: InputDefinitionClassName;
    id: string;
    localized_unit: {
      localized_strings: Dictionary<string>;
    };
    input_behaviours: string[];
    default_value: InputDefinitionValue | string;
    minimum_value?: string;
    maximum_value?: string;
    step_size?: string;
    decimal_places?: string;
    input_type: InputDefinitionType;
    monitoring_required?: boolean;
    values?: InputDefinitionValue[];
    switch_off_text?: {
      localized_strings: Dictionary<string>;
    };
    switch_on_text?: {
      localized_strings: Dictionary<string>;
    };
    switch_on_value?: string;
    switch_off_value?: string;
  };
  cohese: boolean;
  phenomenon_id: string;
  created_at: string;
  crop_ids?: string[];
  is_canonical?: boolean;
  modified_at: string;
  aliases_ids?: string[];
  last_modified_by_user_id?: string;
}
