import axios from 'axios';
import { CORE_SERVICES_API_URL } from 'config/constants';

const coreServicesApiUrl = CORE_SERVICES_API_URL ?? '';

const getAuthorization = () => {
  const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token') || '';
  return {
    Authorization: `Bearer ${token}`
  };
};

const axiosInstance = axios.create({
  baseURL: coreServicesApiUrl,
  timeout: 10000,
  headers: getAuthorization()
});

export default axiosInstance;
