import { Button, Popover, Skeleton, Tooltip } from 'antd';
import { useDidMount, useInterval } from 'beautiful-react-hooks';
import Bluebird from 'bluebird';
import UnstyledButton from 'components/unstyled-button';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import { LoadAlerts } from 'entities/alerts/alerts.actions';
import type { AlertBody } from 'entities/alerts/alerts.models';
import { getAlertsUser } from 'entities/alerts/alerts.reducer';
import type { Company } from 'entities/company/company.models';
import { getAllCompanies, getSelectedCompany } from 'entities/company/company.reducer';
import { getSeasonsTreeByCompany } from 'entities/company/company.service';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { isEmpty } from 'lodash';
import type React from 'react';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import { map } from 'rxjs/operators';
import sentryService from 'sentry/service';
import NewSvgAlertDialogOutline from 'st-protector-icons/Minor/NewSvgAlertDialogOutline';
import STAlertCrashState from './alert-crash-state.component';
import AlertDrawer from './alert-drawer.component';
import STAlertEmptyState from './alert-empty-state.component';
import STAlertList from './alert-list.component';
import { checkAlertsUnread, verifyAlert } from './alert.functions';
import './alert.styles.less';
import type { OpenDrawer, Properties } from './alerts.types';

const STAlert: React.FC = () => {
  const user = useSelector(selectCurrentUser);
  const company = useSelector(getSelectedCompany);
  const companies = useSelector(getAllCompanies);
  const errorAlerts = useSelector((state: AppState) => state.entities.alerts.error);
  const alerts = useSelector(getAlertsUser);
  const isLoadingAlerts = useSelector((state: AppState) => state.entities.alerts.isLoading);

  const [haveAlertUnread, setHaveAlertUnread] = useState<boolean>(false);
  const [showCreateAlert, setShowCreateAlert] = useState<boolean>(false);
  const [contentAlert, setContentAlert] = useState<ReactNode>(null);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [properties, setProperties] = useState<[Properties[]]>([]);
  const [openDrawer, setOpenDrawer] = useState<OpenDrawer>({ open: null });
  const [hasAlert, setHasAlert] = useState<boolean>(false);
  const [alertsInStorage, setAlertsInStorage] = useState<string>('[]');
  const [companyId, setCompanyId] = useState<string>();
  const [t] = useTranslation();
  const selectedProperty = useSelector((state: AppState) => getSelectedProperty(state));
  const dispatch = useDispatch();

  useInterval(() => {
    dispatch(LoadAlerts());
  }, 300000);

  const loadProperties = (companiesFilter: Company[]): Promise<any[]> => {
    return Bluebird.map(companiesFilter, item => {
      return getSeasonsTreeByCompany(item.id)
        .pipe(map(response => response.data))
        .toPromise()
        .then(response => {
          return response;
        })
        .catch(error => {
          sentryService.captureException(error, {
            fileName: 'alert.component',
            method: 'loadProperties',
            description: 'error on loadProperties'
          });
        });
    });
  };

  useEffect(() => {
    if (openDrawer.open === true) {
      setVisibleAlert(false);
    } else if (openDrawer.open === false) {
      setVisibleAlert(true);
    }
  }, [openDrawer]);

  useDidMount(() => {
    setHaveAlertUnread(false);
    dispatch(LoadAlerts());
  });

  const decisionToShowComponent = useCallback(() => {
    let alertsSaved: string | null;

    const checkLocalAlert = localStorage.getItem(`has-alert-${user!.id}-${company?.id ?? ''}`);
    setHasAlert(checkLocalAlert === 'true');

    const listAlerts: AlertBody[] = company ? alerts.filter(item => item.company_id === company.id) : alerts;
    if (!listAlerts.length) {
      if (isLoadingAlerts) {
        setContentAlert(<Skeleton paragraph={{ rows: 6 }} active />);
        setShowCreateAlert(false);
      } else {
        setShowCreateAlert(false);
        setContentAlert(<STAlertEmptyState openDrawer={setOpenDrawer} />);
      }
    } else {
      alertsSaved = localStorage.getItem(`alerts-user-${user!.id}-${company?.id ?? ''}`)!;
      setShowCreateAlert(true);
      const alertsSavedParsed = JSON.parse(alertsSaved) as AlertBody[] | null;
      const alertUpdate = checkAlertsUnread(alerts, alertsSavedParsed, company?.id ?? '');
      const alertString = JSON.stringify(alertUpdate.filter(item => item.company_id === (company ? company.id : '')));
      let alertsStorage: AlertBody[] = JSON.parse(company && company.id === companyId ? alertsInStorage : alertsSaved);
      setCompanyId(company ? company.id : '');
      let alertFiltered = JSON.parse(alertsSaved);
      if (alertsStorage && !alertsStorage.length && alertFiltered.length) alertsStorage = alertFiltered;
      if (alertFiltered) {
        alertFiltered = alertFiltered.filter(item => item.company_id === (company ? company.id : ''));
      }

      if (JSON.stringify(alertFiltered) !== alertString) {
        const updatedItems = checkAlertsUnread(listAlerts, alertsStorage, company?.id ?? '');
        setAlertsInStorage(JSON.stringify(updatedItems));
        if (alertsStorage && alertsStorage.length && company) {
          alertsStorage = alertsStorage.filter(item => item.company_id === company.id);
        }
        localStorage.setItem(`alerts-user-${user!.id}-${company?.id ?? ''}`, JSON.stringify(updatedItems));
        alertsSaved = alertString;
        if (company && alertsStorage) {
          alertsStorage = alertsStorage.filter(item => item.company_id === company.id);
        }

        const checkNewAlert = verifyAlert(listAlerts, alertsStorage);
        if (checkNewAlert) {
          setHasAlert(true);
          localStorage.setItem(`has-alert-${user!.id}-${company?.id ?? ''}`, 'true');
        }
        setHaveAlertUnread(checkLocalAlert === 'true' || hasAlert || checkNewAlert);
        setContentAlert(<STAlertList listAlerts={updatedItems} listProperties={properties} openDrawer={setOpenDrawer} />);
      } else {
        setHaveAlertUnread(checkLocalAlert === 'true' || hasAlert);
        const updatedItems = alertsStorage;
        setContentAlert(<STAlertList listAlerts={updatedItems} listProperties={properties} openDrawer={setOpenDrawer} />);
      }
    }
    if (errorAlerts) {
      setShowCreateAlert(false);
      setContentAlert(<STAlertCrashState />);
    }
  }, [alerts, errorAlerts, isLoadingAlerts, properties, company, hasAlert, user, alertsInStorage, companyId]);

  useEffect(() => {
    if (properties.flat().length && !isEmpty(company) && !isEmpty(user)) {
      decisionToShowComponent();
    }
  }, [properties, decisionToShowComponent, company, user]);

  useEffect(() => {
    setContentAlert(<Skeleton paragraph={{ rows: 6 }} active />);
  }, []);

  useEffect(() => {
    if (companies.length && company && visibleAlert) {
      setProperties(['']);
      const requestProperties = loadProperties(companies.filter(c => c.id === company.id));
      requestProperties.then(propertie => {
        setProperties(propertie);
      });
    }
  }, [companies, company, visibleAlert]);

  const segmentTracking = useSegmentTracking();

  const openAlerts = () => {
    setVisibleAlert(true);
    setHaveAlertUnread(false);

    const payload: AnalyticsInfo = {
      companyId: company?.id,
      companyName: company?.name,
      propertyId: selectedProperty?.id || '',
      propertyName: selectedProperty?.name || ''
    };
    if (!visibleAlert) {
      segmentTracking.track('Alerts - Opened Alerts', payload);
    } else {
      segmentTracking.track('Alerts - Closed Alerts', payload);
    }
  };

  const closeAlert = () => {
    if (!visibleAlert && hasAlert) {
      localStorage.setItem(`has-alert-${user!.id}-${company ? company.id : ''}`, 'false');
      setHasAlert(false);
    }
    if (visibleAlert) {
      setVisibleAlert(false);
      const alertsSaved = localStorage.getItem(`alerts-user-${user!.id}-${company ? company.id : ''}`);
      let alertsStorage: AlertBody[] = [] as AlertBody[];
      if (alertsSaved) {
        alertsStorage = JSON.parse(alertsSaved);
      }
      alertsStorage = alertsStorage.map(alert => {
        const newAlert = alert.company_id === (company ? company.id : '') ? false : undefined;
        if (newAlert === false) {
          return { ...alert, ...{ hasNewAlert: newAlert } };
        }
        return alert;
      });
      localStorage.setItem(`alerts-user-${user!.id}-${company ? company.id : ''}`, JSON.stringify(alertsStorage));
      setAlertsInStorage(JSON.stringify(alertsStorage));
    }
  };

  const getPopOverClassName = () => {
    return haveAlertUnread ? 'ripple' : '';
  };

  return (
    <div data-testid='st-alert' className='st-alert'>
      {openDrawer.open && <AlertDrawer visible contentAlert={openDrawer.itemEdit} callbackDrawer={setOpenDrawer} />}
      <Popover
        placement='bottomRight'
        title={
          <div className='popover-alert-title'>
            {t('alert.title')}
            {showCreateAlert && (
              <Button data-testid='create-new-alert' onClick={() => setOpenDrawer({ open: true })}>
                {t('alert.create_new')}
              </Button>
            )}
          </div>
        }
        visible={visibleAlert}
        overlayClassName='st-alert-popover'
        onVisibleChange={closeAlert}
        content={contentAlert}
        trigger='click'>
        <UnstyledButton data-testid='alerts-button-open' className={`sd-alert ${getPopOverClassName()}`} onClick={openAlerts}>
          {haveAlertUnread && (
            <Tooltip
              placement='bottomLeft'
              overlayClassName='alert-has-tooltip'
              title={<STTypo type={TypeOptions.C1}>{t('alert.has_alerts')}</STTypo>}>
              <NewSvgAlertDialogOutline fill='#fff' />
            </Tooltip>
          )}
          {!haveAlertUnread && <NewSvgAlertDialogOutline />}
        </UnstyledButton>
      </Popover>
    </div>
  );
};

export default STAlert;
