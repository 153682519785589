import type { LoadAreaDayMonitoringSuccessType, LoadAreaDayMonitoringType } from './monitoring.models';

export const MonitoringActionsTypes = {
  LOAD_AREA_DAY_MONITORING: '[Monitoring] Load Monitoring Info',
  LOAD_AREA_DAY_MONITORING_SUCCESS: '[Monitoring] Load Monitoring Info Success',
  LOAD_AREA_DAY_MONITORING_FAILURE: '[Monitoring] Load Monitoring Info Failure'
};

export const LoadAreaDayMonitoring: LoadAreaDayMonitoringType = (
  propertyId,
  areaId,
  day,
  showRepresentativeness,
  representedPointsIds,
  areaPointsIds
) => {
  return {
    type: MonitoringActionsTypes.LOAD_AREA_DAY_MONITORING,
    payload: { propertyId, areaId, day, showRepresentativeness, representedPointsIds, areaPointsIds }
  };
};

export const LoadAreaDayMonitoringSuccess: LoadAreaDayMonitoringSuccessType = monitoring => {
  return {
    type: MonitoringActionsTypes.LOAD_AREA_DAY_MONITORING_SUCCESS,
    payload: monitoring
  };
};

export const LoadAreaDayMonitoringFailure = (error: string) => {
  return {
    type: MonitoringActionsTypes.LOAD_AREA_DAY_MONITORING_FAILURE,
    payload: error
  };
};
