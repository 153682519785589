import { TreeSelect } from 'antdV5';
import styled from 'styled-components';

export const StyledRegionTreeSelectContainerDefault = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomTreeSelect = styled(TreeSelect)`
  .ant-select-focused {
    border-color: #198746 !important;
  }
`;
