import type { COLOR_OPTIONS as DefaultColorOptions } from 'config/style';

/**
 * @H1: 42px
 * @H2: 30px
 * @H3: 24px
 * @H4: 20px
 * @H5: 16px
 * @P1: 14px
 * @P2: 12px
 * @P3: 11px
 * @C1: 12px
 * @C2: 10px
 */
export enum TypeOptions {
  H1 = 'st-typo--heading-1',
  H2 = 'st-typo--heading-2',
  H3 = 'st-typo--heading-3',
  H4 = 'st-typo--heading-4',
  H5 = 'st-typo--heading-5',
  H6 = 'st-typo--heading-6',
  P1 = 'st-typo--paragraph-1',
  P2 = 'st-typo--paragraph-2',
  P3 = 'st-typo--paragraph-3',
  C1 = 'st-typo--caption-1',
  C2 = 'st-typo--caption-2'
}

export type CssAlignTypo =
  | '-moz-initial'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset'
  | 'center'
  | 'left'
  | 'right'
  | 'end'
  | 'justify'
  | 'match-parent'
  | 'start'
  | undefined;

export type FontFamily = 'Noto Sans' | 'Rubik' | '"Noto Sans", sans-serif';

type FontWeight<T extends FontFamily> = T extends 'Noto Sans' ? 400 | 500 | 600 | 700 : 300 | 400 | 500;

export interface STTypoProps<T extends FontFamily> {
  type?: TypeOptions;
  color?: typeof DefaultColorOptions | string;
  align?: CssAlignTypo;
  /** @deprecated use fontWeight with fontFamily */
  boldness?: boolean;
  /** @deprecated use fontWeight with fontFamily */
  lightWeight?: boolean;
  italic?: boolean;
  maxlength?: number;
  uppercase?: boolean;
  capitalize?: boolean;
  lowercase?: boolean;
  tagged?: boolean;
  nowrap?: boolean;
  forcewrap?: boolean;
  borderedFont?: boolean;
  id?: string;
  lineHeight?: string;
  /** Default is Rubik */
  fontFamily?: T;
  /** Default is 400 */
  fontWeight?: FontWeight<T>;
  shouldHideTooltip?: boolean;
  horizontalPadding?: boolean;
}
