import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { IndicatorsActionsTypes } from './indicators.actions';
import type { IndicatorsState } from './indicators.models';

const entity: EntityAdapter<any> = createEntityAdapter<any>();

const initialState = entity.getInitialState<IndicatorsState>({
  ids: [],
  entities: {},
  content: [],
  isLoading: false,
  error: null,
  indicators: {},
  windows: {}
});

export default (state = initialState, action) => {
  switch (action.type) {
    case IndicatorsActionsTypes.LOAD_DAYS_INDICATORS:
      return {
        ...state,
        isLoading: true
      };
    case IndicatorsActionsTypes.LOAD_DAYS_INDICATORS_SUCCESS:
      return {
        ...state,
        indicators: { ...state.indicators, [action.payload.propertyId]: action.payload.indicator },
        isLoading: false,
        error: null
      };
    case IndicatorsActionsTypes.LOAD_DAYS_INDICATORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case IndicatorsActionsTypes.LOAD_WINDOW_INDICATOR:
      return {
        ...state,
        isLoading: true
      };
    case IndicatorsActionsTypes.LOAD_WINDOW_INDICATOR_SUCCESS:
      return {
        ...state,
        windows: {
          ...state.windows,
          [action.payload.propertyId]: { ...state.windows[action.payload.propertyId], [action.payload.areaId]: action.payload.window }
        },
        isLoading: false,
        error: null
      };
    case IndicatorsActionsTypes.LOAD_WINDOW_INDICATOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
