const SvgWeatherDailyShowersThunderstormsLikely = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M4.18889 13.0429C2.33255 12.3926 1 10.5915 1 8.6C1 6 3 3.9 5.5 3.8C6.5 2.1 8.4 1 10.5 1C13.6 1 16.2 3.5 16.2 6.7L16 8.2L14.2 8.1L14.3 6.6C14.3 4.5 12.6 2.8 10.5 2.8C9 2.8 7.6 3.7 7 5.1L6.7 5.8L6 5.7C5.9 5.7 5.9 5.7 5.8 5.7H5.7C4.1 5.7 2.8 7 2.8 8.6C2.8 10.089 3.92594 11.3182 5.37179 11.4816C6.16766 11.5715 4.39907 13.1165 4.18889 13.0429Z'
      fill='#C2C7D0'
    />
    <path
      d='M17.1918 19.7535H14.6738V17.9645H17.1908C19.1918 17.9645 20.8218 16.3375 20.8218 14.3345C20.8218 12.3305 19.1928 10.7025 17.1908 10.7025C17.1468 10.7025 17.0778 10.7135 17.0078 10.7215C16.9478 10.7295 16.8868 10.7385 16.8258 10.7425L16.1728 10.8055L15.9158 10.2025C15.1638 8.44253 13.4438 7.30653 11.5338 7.30653C8.90782 7.30653 6.77182 9.44353 6.77182 12.0685V12.9645H5.87982C4.49982 12.9645 3.37882 14.0855 3.37882 15.4655C3.37882 16.8445 4.49982 17.9645 5.87982 17.9645H8.99682V19.7535H5.87982C3.51482 19.7535 1.59082 17.8295 1.59082 15.4665C1.59082 13.3895 3.07282 11.6535 5.03382 11.2615C5.43582 8.03053 8.19782 5.51953 11.5348 5.51953C13.9438 5.51953 16.1348 6.83453 17.2768 8.91653C20.2268 8.95953 22.6138 11.3725 22.6138 14.3335C22.6108 17.3235 20.1798 19.7535 17.1918 19.7535Z'
      fill='#868CA2'
    />
    <path d='M7.86471 20.5586H6.05371V22.8156H7.86471V20.5586Z' fill='#0071CD' />
    <path d='M12.9057 20.5586H11.0967V22.8156H12.9057V20.5586Z' fill='#0071CD' />
    <path d='M17.9496 20.5586H16.1396V22.8156H17.9496V20.5586Z' fill='#0071CD' />
    <path
      d='M13.6714 9.63672L9.23242 14.8467L12.2064 15.5797L11.2284 19.5427L15.6664 14.3317L12.6954 13.5987L13.6714 9.63672Z'
      fill='#F0C355'
    />
  </svg>
);

export default SvgWeatherDailyShowersThunderstormsLikely;
