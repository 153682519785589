import { useParseTagsMessages } from 'pages/notes/notes.hooks';
import type { AnnotationStage } from 'pages/timeline/timeline.models';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const generateFormattedNote = (annotation: AnnotationStage): Record<string, any> => {
  const formattedMedias = annotation?.medias?.map(media => ({
    id: media.url + media.type,
    file_type: media.type,
    url: media.url,
    url_thumbnail: media?.thumbnail,
    metadata: `source:${media.source}`
  }));

  return {
    note_tag: annotation.tags,
    note: annotation.comments,
    author: annotation.scouter.name,
    date: annotation.date,
    user_location: {
      type: 'Feature',
      geometry: annotation.point
    },
    ...(annotation?.forced_point && {
      manual_location: {
        type: 'Feature',
        geometry: annotation?.forced_point
      }
    }),
    note_attachment: formattedMedias
  };
};

const getProductName = (productsAvailable?: string | boolean, annotationSource?: string) => {
  const products = productsAvailable ? JSON.parse(productsAvailable as string) : {};

  const noteProductId = annotationSource?.split('/')[0] || '';

  return products?.[noteProductId] || '';
};

interface ConfigToNotesModalGalleryProps {
  productsAvailable?: string | boolean;
  isGalleryV2?: boolean | string;
  annotation: AnnotationStage;
  segmentTracking: any;
}

export const useConfigToNotesModalGallery = ({
  productsAvailable,
  segmentTracking,
  isGalleryV2,
  annotation
}: ConfigToNotesModalGalleryProps): Record<
  'notesMessages' | 'formattedNote' | 'tagsTranslations' | 'galleryEvents' | 'productName',
  any
> => {
  const tagsTranslations = useParseTagsMessages();
  const [t] = useTranslation();

  const formattedNote = isGalleryV2 ? generateFormattedNote(annotation) : null;

  const notesMessages = {
    uploadedFromGallery: t('pages.notes.sidebar.photos.uploadedFromGallery'),
    uploadedFromCamera: t('pages.notes.sidebar.photos.uploadedFromCamera'),
    previousMedia: t('pages.notes.sidebar.photos.previousMedia'),
    createdBy: t('pages.notes.sidebar.photos.createdBy'),
    nextMedia: t('pages.notes.sidebar.photos.nextMedia'),
    goToImage: t('pages.notes.sidebar.photos.goToImage'),
    download: t('pages.notes.sidebar.photos.download'),
    current: t('pages.notes.sidebar.photos.current'),
    in: t('pages.notes.sidebar.photos.in')
  };

  const galleryEvents = {
    photos: {
      downloadMedia: context => segmentTracking.track('Notes - Click to download media in open gallery', context)
    },
    audioPlayer: {
      clickToPauseAudio: context => segmentTracking.track('Notes - Click to pause audio in open gallery', context),
      clickToPlayAudio: context => segmentTracking.track('Notes - Click to play audio in open gallery', context),
      downloadAudio: context => segmentTracking.track('Notes - Click to download audio in open gallery', context)
    }
  };

  const productName = useMemo(() => getProductName(productsAvailable, annotation?.source), [annotation, productsAvailable]);

  return {
    tagsTranslations,
    notesMessages,
    formattedNote,
    galleryEvents,
    productName
  };
};
