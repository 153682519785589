import { DatePicker, Drawer, Form } from 'antd';
import '../report.styles.less';
import { ReportsTypes } from '../reports-types.enum';
import FooterDrawer from './components/footer-drawer.component';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';
import { useReportControlStrategy } from './report-control-strategy.bloc';

const { RangePicker } = DatePicker;

interface ReportBlockedFieldsDrawerProps {
  callbackDrawer: () => void;
  visible?: boolean;
  icon?: JSX.Element;
}

const ReportBlockedFields = ({ callbackDrawer, visible, icon }: ReportBlockedFieldsDrawerProps) => {
  const { handleGenericExportReport, handleTimeRange, disabledDate, timeRangeFrom, timeRangeTo } = useReportControlStrategy({
    onSubmit: callbackDrawer
  });

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible}
      width={400}
      title={<HeaderTitle icon={icon} type={ReportsTypes.BLOCKED_FIELDS} />}
      onClose={callbackDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='report-disease-risk-drawer'>
        <ExplicativeText type={ReportsTypes.BLOCKED_FIELDS} hideCollapse />
        <div className='st-report-drawer__form' data-testid='report-disease-risk-form'>
          <Form layout='vertical'>
            <Form.Item>
              <RangePicker
                data-testid='control-strategy-rangePicker-component'
                value={[timeRangeFrom, timeRangeTo]}
                format='L'
                separator='—'
                onChange={handleTimeRange}
                allowClear={false}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Form>
        </div>
        <FooterDrawer
          onPressConfirm={() => handleGenericExportReport(ReportsTypes.BLOCKED_FIELDS)}
          isLoading={false}
          disableConfirmButton={false}
          onPressCancel={callbackDrawer}
        />
      </div>
    </Drawer>
  );
};

export default ReportBlockedFields;
