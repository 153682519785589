import type { Locale } from 'antd/lib/locale-provider';
import localeDE from 'antd/lib/locale/de_DE';
import localeEN from 'antd/lib/locale/en_US';
import localeES from 'antd/lib/locale/es_ES';
import localeFR from 'antd/lib/locale/fr_FR';
import localeHU from 'antd/lib/locale/hu_HU';
import localeIT from 'antd/lib/locale/it_IT';
import localePL from 'antd/lib/locale/pl_PL';
import localeBR from 'antd/lib/locale/pt_BR';
import { getCurrentLanguage } from 'core/utils/functions';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const getLocaleByLanguage = (language: string): Locale => {
  let newAntdLocale: Locale;

  switch (language) {
    case 'pt-br':
      newAntdLocale = localeBR;
      break;
    case 'de-de':
      newAntdLocale = localeDE;
      break;
    case 'fr-fr':
    case 'fr':
      newAntdLocale = localeFR;
      break;
    case 'pl-pl':
      newAntdLocale = localePL;
      break;
    case 'es':
    case 'es-ar':
    case 'es-AR':
      newAntdLocale = localeES;
      break;
    case 'hu-hu':
      newAntdLocale = localeHU;
      break;
    case 'it':
      newAntdLocale = localeIT;
      break;
    default:
      newAntdLocale = localeEN;
      break;
  }

  return newAntdLocale;
};

const useAntdLocale = () => {
  const [t] = useTranslation();

  const [locale, setLocale] = useState<Locale>(localeEN);

  useEffect(() => {
    const currentLanguage = getCurrentLanguage();

    const originalLocaleByLanguage = getLocaleByLanguage(currentLanguage);

    const localeByLanguage = cloneDeep(originalLocaleByLanguage);

    if (
      typeof localeByLanguage.DatePicker === 'object' &&
      'lang' in localeByLanguage.DatePicker &&
      'timeSelect' in (localeByLanguage.DatePicker.lang as Record<'timeSelect', string>)
    ) {
      (localeByLanguage.DatePicker.lang as Record<'timeSelect', string>).timeSelect = t('antd.date_picker.lang.time_select');
    }

    setLocale(localeByLanguage);
  }, [t]);

  return locale;
};

export default useAntdLocale;
