import { COLOR_OPTIONS } from 'config/style';

interface CropCottonProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropCotton = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropCottonProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 1C10.094 1 11.8953 2.46535 12.3783 4.4828C13.9184 4.96025 15 6.37102 15 8C15 9.87518 13.5622 11.4112 11.7494 11.6441C12.5693 10.7219 12.9344 9.45784 13.0753 8.81751C13.124 8.59626 12.9647 8.3944 12.7382 8.39013C12.1376 8.37881 10.9911 8.42433 10.0368 8.85513C9.8974 6.987 8.76498 5.5851 8.62848 5.42253L8.30668 5.03894C8.14688 4.84847 7.85386 4.84838 7.69395 5.03876L7.37195 5.4221C7.23534 5.58478 6.1026 6.9854 5.96321 8.85513C5.0062 8.42305 3.86045 8.37808 3.26081 8.38982C3.0347 8.39425 2.87571 8.59568 2.92411 8.8166C3.0642 9.45607 3.42837 10.7194 4.25038 11.6441C2.43758 11.4109 1 9.87518 1 8C1 6.37102 2.08157 4.96025 3.62169 4.4828C4.10469 2.46535 5.90596 1 8 1ZM3.97394 9.51031C3.93586 9.37096 4.04399 9.2305 4.1881 9.24049C4.88274 9.2886 5.55614 9.50931 6.15067 9.88618L6.83565 10.3204L6.77957 9.51128C6.70675 8.50932 7.48283 6.91599 7.83342 6.25376C7.90555 6.1175 8.09381 6.11794 8.16551 6.25443C8.51532 6.92036 9.29215 8.5244 9.22043 9.51128L9.16435 10.3204L9.84933 9.88618C10.4356 9.51453 11.0986 9.29474 11.7831 9.24258C11.9401 9.23062 12.0537 9.38451 12.0017 9.53315L11.9887 9.5703C11.957 9.65956 11.9253 9.76302 11.8904 9.87667C11.6147 10.7749 11.1438 12.3091 9 12.4872V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V12.4772C5.34062 12.3148 4.48133 11.1975 4.00026 9.60459C3.99592 9.59022 3.98598 9.5544 3.97394 9.51031Z'
      fill={color}
    />
  </svg>
);

export default CropCotton;
