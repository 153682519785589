import type { Action, UUID } from 'core/utils/basic.models';
import type { EntityState } from 'redux-ngrx-entity';

export interface PayloadAssigneesType {
  company_id: string;
  only_accounts_workers?: boolean;
}

export type LoadAssigneesType = (company_id: string, only_accounts_workers?: boolean) => Action<PayloadAssigneesType>;
export type LoadAssigneesSuccessType = (assigneePage: AssigneePage) => Action<AssigneePage>;

interface PageV1<T> {
  content: T[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}
export type AssigneePage = PageV1<Assignee>;

export interface Assignee {
  type?: string;
  id: UUID;
  assigneeType: string;
  name: string;
  email?: string;
  deleted?: boolean;
}

export interface AssigneeState extends EntityState<Assignee> {
  page: AssigneePage;
  isLoading: boolean;
  error: string | null;
}

export const initialPageV1 = {
  content: [],
  first: false,
  last: false,
  number: 0,
  numberOfElements: 0,
  size: 0,
  totalElements: 0,
  totalPages: 0
};
