const SvgFullscreenArrow = props => (
  <svg width='24' height='24' viewBox='0 0 24 24' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 4C2 2.89543 2.89543 2 4 2H7C7.55228 2 8 2.44772 8 3C8 3.55228 7.55228 4 7 4H5.41429L9.70718 8.29289C10.0977 8.68342 10.0977 9.31658 9.70718 9.70711C9.31666 10.0976 8.68349 10.0976 8.29297 9.70711L4 5.41414V7C4 7.55228 3.55228 8 3 8C2.44772 8 2 7.55228 2 7V4ZM17 4H18.5858L14.2929 8.29289C13.9024 8.68342 13.9024 9.31658 14.2929 9.70711C14.6834 10.0976 15.3166 10.0976 15.7071 9.70711L20 5.41421V7C20 7.55228 20.4477 8 21 8C21.5523 8 22 7.55228 22 7V4C22 2.89543 21.1046 2 20 2H17C16.4477 2 16 2.44772 16 3C16 3.55228 16.4477 4 17 4ZM3 16C3.55228 16 4 16.4477 4 17V18.5859L8.29297 14.2929C8.68349 13.9024 9.31666 13.9024 9.70718 14.2929C10.0977 14.6834 10.0977 15.3166 9.70718 15.7071L5.41429 20H7C7.55228 20 8 20.4477 8 21C8 21.5523 7.55228 22 7 22H4C2.89543 22 2 21.1046 2 20V17C2 16.4477 2.44772 16 3 16ZM20 18.5858V17C20 16.4477 20.4477 16 21 16C21.5523 16 22 16.4477 22 17V20C22 21.1046 21.1046 22 20 22H17C16.4477 22 16 21.5523 16 21C16 20.4477 16.4477 20 17 20H18.5858L14.2929 15.7071C13.9024 15.3166 13.9024 14.6834 14.2929 14.2929C14.6834 13.9024 15.3166 13.9024 15.7071 14.2929L20 18.5858Z'
    />
  </svg>
);

export default SvgFullscreenArrow;
