export enum WeatherMeteoBlueEnum {
  SUNNY = 1,
  SUNNY_FEW_CLOUDS = 2,
  SUNNY_FEW_CLOUDS_2 = 3,
  SUNNY_FEW_CLOUDS_3 = 4,
  SUNNY_FEW_CLOUDS_4 = 5,
  SUNNY_FEW_CLOUDS_5 = 6,
  SUNNY_FEW_CLOUDS_6 = 13,
  SUNNY_FEW_CLOUDS_7 = 14,
  SUNNY_FEW_CLOUDS_8 = 15,
  PARTLY_CLOUD = 7,
  PARTLY_CLOUD_2 = 8,
  PARTLY_CLOUD_3 = 9,
  PARTLY_CLOUD_4 = 10,
  PARTLY_CLOUD_5 = 11,
  PARTLY_CLOUD_6 = 12,
  CLOUDY = 19,
  CLOUDY_2 = 20,
  CLOUDY_3 = 21,
  CLOUDY_4 = 22,
  FOG = 16,
  FOG_2 = 17,
  FOG_3 = 18,
  RAIN = 23,
  RAIN_2 = 25,
  RAIN_SUNNY = 31,
  STORM = 27,
  STORM_2 = 28,
  STORM_3 = 30,
  SNOW = 24,
  SNOW_2 = 26,
  SNOW_3 = 29,
  SNOW_SUNNY = 32,
  CLOUDY_RAIN_SNOW = 35,
  RAIN_LIGHT = 33,
  SNOW_LIGHT = 34,
  CLOUDY_RAIN = 14,
  CLOUDY_SNOW = 15,
  CLOUDY_LIGHT_RAIN = 16,
  CLOUDY_LIGHT_SNOW = 17
}

export enum WeatherMeteoBlueDailyEnum {
  SUNNY = 1,
  SUNNY_FEW_CLOUDS = 2,
  PARTLY_CLOUD = 3,
  CLOUDY = 4,
  FOG = 5,
  RAIN = 6,
  RAIN_SUNNY = 7,
  STORM = 8,
  SNOW = 9,
  SNOW_SUNNY = 10,
  CLOUDY_RAIN_SNOW = 11,
  RAIN_LIGHT = 12,
  SNOW_LIGHT = 13,
  CLOUDY_RAIN = 14,
  CLOUDY_SNOW = 15,
  CLOUDY_LIGHT_RAIN = 16,
  CLOUDY_LIGHT_SNOW = 17
}
