export const SvgBlocked = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_33806_1436)'>
        <rect width='32' height='32' rx='8' fill='white' />
        <rect width='32' height='32' rx='4' fill='#FFF1F3' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 8.5C11.86 8.5 8.5 11.86 8.5 16C8.5 20.14 11.86 23.5 16 23.5C20.14 23.5 23.5 20.14 23.5 16C23.5 11.86 20.14 8.5 16 8.5ZM10 16C10 12.685 12.685 10 16 10C17.3875 10 18.6625 10.4725 19.675 11.2675L11.2675 19.675C10.4725 18.6625 10 17.3875 10 16ZM12.325 20.7325C13.3375 21.5275 14.6125 22 16 22C19.315 22 22 19.315 22 16C22 14.6125 21.5275 13.3375 20.7325 12.325L12.325 20.7325Z'
          fill='#BB485D'
        />
      </g>
      <defs>
        <clipPath id='clip0_33806_1436'>
          <rect width='32' height='32' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
