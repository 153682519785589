import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import ax from 'axios';
import axios from 'axios-observable';
import { GenericRetryStrategy, is5xxStatus } from 'core/rxjs-utils';
import { refreshToken } from 'core/services/auth/auth.service';
import { isTrustedDomain } from 'core/utils/requests';
import { retryWhen } from 'rxjs/operators';
import sentryService from 'sentry/service';
import type { Token } from '../core/services/auth/auth.models';
import { getCurrentLanguage, isUK } from '../core/utils/functions';
import { AUTH_SECRET_ID } from './constants';
import { URLS_TO_IGNORE_ADDITION_HEADERS, URLS_TO_IGNORE_REFRESH_TOKEN } from './constants/interceptor';

interface HeadersParams {
  'X-Mock'?: boolean | string;
  'x-mock-disease-risk'?: boolean | string;
  'X-Auth-Token'?: string;
  'X-Company-Id'?: string | null;
  Authorization?: string;
}

interface DataParams {
  error: string;
}

interface CustomWindow extends Window {
  axios: typeof axios;
}

// Adds access token to every request
axios.interceptors.request.use(
  config => {
    if (!isTrustedDomain(config?.url ?? '')) {
      return config;
    }

    const token = localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token');
    const configHeaders = config.headers as HeadersParams;
    if (token !== null && !configHeaders?.['X-Auth-Token']) {
      const currentLanguage = config.url?.includes('/v2/store/apps') && isUK() ? 'en-gb' : getCurrentLanguage();
      const urlsToIgnore = URLS_TO_IGNORE_ADDITION_HEADERS;
      const validateUrls = config.url && urlsToIgnore.some(url => config.url?.includes(url));

      let xMock: boolean | string | undefined = 'no';
      if ('x-mock-disease-risk' in config.headers) {
        xMock = configHeaders['x-mock-disease-risk'];

        delete configHeaders['x-mock-disease-risk'];
      }
      let request_headers = {
        ...configHeaders,
        ...(!validateUrls
          ? {
              'X-Mock': xMock,
              'Accept-Language': currentLanguage
            }
          : {}),
        Authorization: config.url && config.url.includes('refresh_token') ? `Basic ${AUTH_SECRET_ID}` : `Bearer ${token}`
      };
      if (sessionStorage.getItem('company_id') && sessionStorage.getItem('company_id') !== 'null' && !validateUrls) {
        request_headers = {
          ...request_headers,
          'X-Company-Id': sessionStorage.getItem('company_id')
        };
      }

      return {
        ...config,
        headers: request_headers,
        withCredentials: false
      };
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

const doRefreshTokenCall = (configHeaders: HeadersParams, config: AxiosRequestConfig, err: AxiosError) => {
  if (!sessionStorage.getItem('refreshed')) {
    const refresh_token = localStorage.getItem('refresh_token');
    if (refresh_token) {
      refreshToken(refresh_token)
        .toPromise()
        .then(response => {
          const auth: Token = response.data;
          onRefreshed(auth.access_token);
          localStorage.setItem('access_token', auth.access_token);
          localStorage.setItem('refresh_token', auth.refresh_token);
          subscribers = [];
          sessionStorage.setItem('refreshed', 'true');
          const requestSubscribers = new Promise(resolve => {
            subscribeTokenRefresh((token: string) => {
              configHeaders.Authorization = `Bearer ${token}`;
              const originalRequest = {
                ...config,
                headers: {
                  ...configHeaders,
                  'Accept-Language': 'pt-BR',
                  Authorization: `Bearer ${token}`
                }
              } as AxiosRequestConfig;

              resolve(new axios(originalRequest as unknown as AxiosInstance));
            });
          });
          return requestSubscribers;
        })
        .catch(error => {
          sentryService.captureException(error, {
            fileName: 'interceptors',
            method: 'refreshToken',
            description: 'unlicensed'
          });
          if (!window.location.href.includes('/unlicensed')) window.location.pathname = '/unlicensed';
          sessionStorage.setItem('refreshed', 'true');
          sessionStorage.setItem('unlicensed', 'true');
          return Promise.reject(error);
        });
    }
  } else {
    if (!window.location.href.includes('/unlicensed')) window.location.pathname = '/unlicensed';
    sessionStorage.setItem('unlicensed', 'true');
    sessionStorage.removeItem('refreshed');
    return Promise.reject(err);
  }
};

let subscribers: ((token: string) => void)[] = [];
axios.interceptors.response.use(undefined, (err: AxiosError) => {
  const { config } = err;
  const status = err.response?.status;

  if (status && is5xxStatus(status.toString())) {
    return axios.create({}).request(config).pipe(retryWhen(GenericRetryStrategy())).toPromise();
  }

  if (ax.isCancel(err)) {
    return Promise.reject(err);
  }

  const configHeaders = config.headers as HeadersParams;

  const urlsToIgnore = URLS_TO_IGNORE_REFRESH_TOKEN;
  const validateUrls = config.url && urlsToIgnore.some(url => config.url?.includes(url));

  if (!validateUrls && (status === 401 || status === 403)) {
    const dataResponse: DataParams | undefined = err.response?.data as DataParams;
    if (dataResponse && ['invalid_token', 'invalid_grant', 'Unauthorized'].includes(dataResponse.error)) {
      localStorage.clear();
      sessionStorage.clear();

      sentryService.captureException(err, {
        fileName: 'interceptors.ts',
        method: 'axios.interceptors.response',
        description: 'Invalid token'
      });

      window.location.href = '/';
      return Promise.reject(err);
    }
    return doRefreshTokenCall(configHeaders, config, err);
  }

  return Promise.reject(err);
});

(window as unknown as CustomWindow).axios = axios;

const subscribeTokenRefresh = (cb: (token: string) => void) => {
  subscribers = [...subscribers, cb];
};

const onRefreshed = (token: string) => {
  subscribers.map(cb => cb(token));
};
