const SvgSmartSpray = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <circle cx='17' cy='7' r='5' fill='transparent' strokeWidth='2' />
    <path d='M16.5386 5.61536V7.46151L17.9232 8.38459' strokeWidth='1.5' strokeLinecap='round' fill='transparent' />
    <path d='M7 11V17.4009C7 17.7318 7.26823 18 7.59912 18H14' strokeWidth='2' strokeLinecap='round' fill='transparent' />
    <path
      fill='transparent'
      d='M9 3H7.5H5.07693C3.92987 3 3 3.92987 3 5.07693V19.9231C3 21.0701 3.92987 22 5.07693 22H18.9231C20.0701 22 21 21.0701 21 19.9231V14.4'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);

export default SvgSmartSpray;
