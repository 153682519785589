import styled, { css } from 'styled-components';

export const Container = styled.div<{ hasBoxShadow: boolean; isError: boolean }>`
  display: grid;
  grid-template-rows: max-content auto;
  width: 100%;
  box-shadow: ${({ hasBoxShadow }) => (hasBoxShadow ? '0 4px 12px rgba(0, 0, 0, 0.15)' : 'none')};
  background-color: #fff;
  border-radius: 12px;
  border: ${({ hasBoxShadow }) => (hasBoxShadow ? 'none' : '1px solid #e8e8e8')};
  padding: 16px;
  row-gap: 16px;
  transition: all 0.2s;
  height: inherit;

  ${({ isError }) =>
    isError &&
    css`
      box-shadow: 0 0 0 2px #ff0000;
    `}
`;

export const Header = styled.div`
  height: 20px;
  display: flex;
  grid-gap: 8px;
  align-items: center;
`;
