import { COLOR_OPTIONS } from 'config/style';

interface CropAcerolaProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropAcerola = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropAcerolaProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.7774 1.58397C4.54764 1.43079 4.2372 1.49288 4.08403 1.72264C3.93085 1.95241 3.99294 2.26284 4.2227 2.41602C6.60819 4.00634 7.58302 6.31321 7.89902 8.08198C7.72879 8.02863 7.55024 8 7.36565 8C6.05514 8 5 9.40212 5 11C5 12.5979 6.05514 14 7.36565 14C7.78482 14 8.17279 13.8524 8.50311 13.603C8.86115 13.8584 9.31057 14 9.71943 14C11.0438 14 12 12.5797 12 11C12 9.4203 11.0438 8 9.71943 8C9.4596 8 9.18338 8.05719 8.92465 8.16443C8.7797 7.20293 8.46236 6.08671 7.87143 4.98149C8.3532 5.03232 8.94282 4.99362 9.46464 4.67171C10.3004 4.15611 10.7852 3.06524 10.9786 2.54068C11.0379 2.37961 10.9693 2.19987 10.8304 2.15585C10.3934 2.01739 9.45134 1.82033 8.62261 2.33151C7.99637 2.7179 7.53429 3.42397 7.24801 3.97306C6.63536 3.10937 5.82894 2.28499 4.7774 1.58397Z'
      fill={color}
    />
  </svg>
);

export default CropAcerola;
