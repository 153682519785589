const SvgStaticPointReportCard = ({ fontSize = '24px', fill = 'currentColor', ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <g clipPath='url(#clip0_11107_102417)'>
      <rect width='32' height='32' rx='8' fill='white' />
      <rect width='32' height='32' rx='4' fill='#FFF3DD' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 7.75L22 14.2632L16 24.25L10 14.2632L16 7.75ZM16 9.9102L11.8164 14.4516L16 21.4152L20.1836 14.4516L16 9.9102Z'
        fill='#E99921'
      />
    </g>
    <defs>
      <clipPath id='clip0_11107_102417'>
        <rect width='32' height='32' rx='8' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SvgStaticPointReportCard;
