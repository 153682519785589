import type { Action } from 'core/utils/basic.models';
import type { PayloadDaysIndicators, PayloadWindowIndicator, ResultIndicators, WindowIndicators } from './indicators.models';

export const IndicatorsActionsTypes = {
  LOAD_DAYS_INDICATORS: '[Indicators] Load Days Indicators',
  LOAD_DAYS_INDICATORS_SUCCESS: '[Indicators] Load Days Indicators Success',
  LOAD_DAYS_INDICATORS_FAILURE: '[Indicators] Load Days Indicators Failure',

  LOAD_WINDOW_INDICATOR: '[Indicators] Load Window Indicator',
  LOAD_WINDOW_INDICATOR_SUCCESS: '[Indicators] Load Window Indicator Success',
  LOAD_WINDOW_INDICATOR_FAILURE: '[Indicators] Load Window Indicator Failure'
};

export const LoadDaysIndicators = (data: PayloadDaysIndicators): Action<PayloadDaysIndicators> => {
  return {
    type: IndicatorsActionsTypes.LOAD_DAYS_INDICATORS,
    payload: data
  };
};

export const LoadDaysIndicatorsSuccess = (result: ResultIndicators): Action<ResultIndicators> => {
  return {
    type: IndicatorsActionsTypes.LOAD_DAYS_INDICATORS_SUCCESS,
    payload: result
  };
};

export const LoadDaysIndicatorsFailure = (error: string): Action<string> => {
  return {
    type: IndicatorsActionsTypes.LOAD_DAYS_INDICATORS_FAILURE,
    payload: error
  };
};

export const LoadWindowIndicator = (data: PayloadWindowIndicator): Action<PayloadWindowIndicator> => {
  return {
    type: IndicatorsActionsTypes.LOAD_WINDOW_INDICATOR,
    payload: data
  };
};

export const LoadWindowIndicatorSuccess = (result: WindowIndicators): Action<WindowIndicators> => {
  return {
    type: IndicatorsActionsTypes.LOAD_WINDOW_INDICATOR_SUCCESS,
    payload: result
  };
};

export const LoadWindowIndicatorFailure = (error: string): Action<string> => {
  return {
    type: IndicatorsActionsTypes.LOAD_WINDOW_INDICATOR_FAILURE,
    payload: error
  };
};
