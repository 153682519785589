const URLS_TO_IGNORE_REFRESH_TOKEN = [
  'v2/accounts/me',
  '/api/v1/trapview',
  '/api/v1/john-deere',
  'remote-sensing',
  '.amazonaws.com',
  'v2/catalog/products/ids'
];

const URLS_TO_IGNORE_ADDITION_HEADERS = ['remote-sensing', 'v2/catalog/crops', '.amazonaws.com', 'v2/catalog/products/ids'];

export { URLS_TO_IGNORE_ADDITION_HEADERS, URLS_TO_IGNORE_REFRESH_TOKEN };
