import { OAUTH_CROPWISE_URL, OAUTH_UK_URL } from 'config/constants';
import type { Dictionary } from 'config/types';
import { LogoutRequest } from 'core/services/auth/auth.actions';
import { isUK } from 'core/utils/functions';
import { type SegmentTrackingHookReturn } from 'core/utils/segment/useSegmentTracking';
import { type Dispatch } from 'redux';

const oauthUrl = isUK() ? OAUTH_UK_URL : OAUTH_CROPWISE_URL;

interface HandleLogoutProps {
  segmentTracking: SegmentTrackingHookReturn;
  systemFlags: Dictionary<string | boolean> | null;
  dispatch: Dispatch;
}

export const handleLogout = ({ segmentTracking, systemFlags, dispatch }: HandleLogoutProps) => {
  segmentTracking.track(`User Settings - Logout`);

  if (systemFlags?.CWS_10071_OKTA_LOGOUT) {
    try {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('refresh_token');
      sessionStorage.removeItem('company_id');

      window.location.replace(`${oauthUrl}/exit?continue=${window.location.origin}`);
    } catch (error) {
      console.log('Error on logout', error);
    }
  } else {
    dispatch(LogoutRequest());
  }
};
