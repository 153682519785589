import type { Action } from 'core/utils/basic.models';
import _ from 'lodash';
import { ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { catchError, delay, map, mergeMap } from 'rxjs/operators';
import type { ReloadHeatmapsModel } from './heatmap.actions';
import { HeatmapActionsTypes, LoadHeatmapsFailure, LoadHeatmapsSuccess, ReloadHeatmaps } from './heatmap.actions';
import type { LoadHeatmapParams } from './heatmap.models';
import { defaultRequest } from './heatmap.models';
import { getHeatmaps } from './heatmap.service';

export const handleReloadHeatmaps = action$ =>
  action$.pipe(
    ofType(HeatmapActionsTypes.RELOAD_HEATMAPS),
    map((action: Action<ReloadHeatmapsModel>) => action.payload),
    mergeMap((data: ReloadHeatmapsModel) => {
      const requests = data.request.areas.map((area, index) => {
        const request = {
          ...defaultRequest,
          property_id: data.request.property_id,
          indicators: [...data.request.indicators],
          scouting_windows: [...data.request.scouting_windows],
          area_id: area
        };
        return getHeatmaps(request).pipe(
          map(response => {
            const heatmap = response.data;
            if (heatmap?.url) {
              return { [area]: heatmap.url };
            }
            return { [area]: '' };
          }),
          catchError(() => {
            return of({});
          })
        );
      });
      return forkJoin(requests).pipe(
        map(heatmaps => {
          const success = heatmaps.reduce<boolean>((acc, url) => {
            if (!Object.values(url)[0]) {
              return false;
            }
            return acc;
          }, true);
          if (!success && data.retry <= 4) {
            return ReloadHeatmaps({ request: data.request, retry: data.retry + 1 });
          }
          return LoadHeatmapsSuccess({
            heatmaps: _.merge({}, ...heatmaps),
            currentHeatmap: { phenomenon: data.request.phenomenon, indicator: data.request.indicator }
          });
        })
      );
    }),
    delay(1000),
    catchError(() => {
      return of(LoadHeatmapsFailure());
    })
  );

export const handleLoadHeatmaps = action$ =>
  action$.pipe(
    ofType(HeatmapActionsTypes.LOAD_HEATMAPS),
    map((action: Action<LoadHeatmapParams>) => action.payload),
    mergeMap((params: LoadHeatmapParams) => {
      const requests = params.areas.map(area => {
        const request = {
          ...defaultRequest,
          property_id: params.property_id,
          indicators: [...params.indicators],
          scouting_windows: [...params.scouting_windows],
          area_id: area,
          geometry_date: params.geometry_date
        };
        return getHeatmaps(request).pipe(
          map(response => {
            const heatmap = response.data;
            if (heatmap?.url) {
              return { [area]: heatmap.url };
            }
            return { [area]: '' };
          }),
          catchError(() => {
            return of({});
          })
        );
      });
      return forkJoin(requests).pipe(
        map(heatmaps => {
          const success = heatmaps.reduce<boolean>((acc, url) => {
            if (!Object.values(url)[0]) {
              return false;
            }
            return acc;
          }, true);
          if (!success) {
            return ReloadHeatmaps({ request: params, retry: 1 });
          }
          return LoadHeatmapsSuccess({
            heatmaps: _.merge({}, ...heatmaps),
            currentHeatmap: { phenomenon: params.phenomenon, indicator: params.indicator }
          });
        })
      );
    }),
    delay(1000),
    catchError(() => {
      return of(LoadHeatmapsFailure());
    })
  );
