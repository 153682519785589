import type { LocalizedString, UUID } from 'core/utils/basic.models';
import type { Region } from 'entities/region/region.models';
import type { Coordinate, StaticPointTemplate } from 'entities/static-points/static-points.models';
import type { Station } from 'entities/stations/stations.models';
import type { Moment } from 'moment';
import type { Nullable } from '../../core/core.models';
import type { PhenomenaBundle } from '../../entities/phenomenon/phenomenon.models';
import type { SelectData } from './map/components/static-point-map-indicator-filter.models';
import type { DateFilterOptions, SelectDrawer, TypesStatusFilter } from './static-points.constants';

export interface StaticPointsProps {
  regionsList?: string[];
  fieldsList?: string[];
}

export enum StaticPointSelectType {
  INSTALLED = 'installed',
  REPRESENTED = 'represented'
}

export enum StaticPointSeverity {
  ACCEPTANCE = 'Acceptance',
  CONTROL = 'Control',
  DAMAGE = 'Damage',
  NO_ACTIVITY = 'NoActivity',
  EXPIRED = 'Expired'
}

export enum StaticPointSeverityColor {
  ACCEPTANCE = '#2fb966',
  CONTROL = '#ffca28',
  DAMAGE = '#f74141',
  NO_ACTIVITY = '#707374'
}

export enum TrapIconType {
  PIN = 'Severity',
  CLUSTER = 'Cluster',
  EXPIRED = 'Expired',
  NOT_EXPIRED = '',
  UNINSTALLED = 'Uninstalled'
}

export const TRAP_PREFIX = 'trap';
export const HUNDRED_PERCENT = 100;

export interface SelectedDate {
  start: Moment;
  end: Moment;
}

export enum StaticPointMapType {
  PER_AREA = 'PerArea',
  PER_TRAP = 'PerTrap'
}

interface TrapIndicatorSeverity {
  label: string;
  color: string;
  agronomic_severity: string;
  type: string;
}

export interface TrapFieldSeverity {
  indicator_id: string;
  result: number;
  liss: number;
  severity: TrapIndicatorSeverity;
  uom?: LocalizedString;
}

export interface PayloadGetStaticPointsSeverityByField {
  propertyId: UUID;
  templateId: UUID;
  companyId: UUID;
  startDate: string;
}
export type TrapsSeverityByField = Record<UUID, TrapFieldSeverity[]>;
export interface ContextStaticPoints {
  selectedField: Nullable<Region>;
  setSelectedField: (field: Nullable<Region>) => void;
  selectedRegion: Nullable<Region>;
  setSelectedRegion: (region: Nullable<Region>) => void;
  mapCoordinateClick: Nullable<Coordinate>;
  setMapCoordinateClick: (coordinate: Nullable<Coordinate>) => void;
  phenomenaBundle: PhenomenaBundle;
  selectedStation: Nullable<Station>;
  setSelectedStation: (station: Nullable<Station>) => void;
  stationSideMenuCollapsed: boolean;
  setStationSideMenuCollapsed: (value: boolean) => void;
  currentStations: Station[];
  setCurrentStations: (station: Nullable<Station[]>) => void;
  chartModalVisible: boolean;
  setChartModalVisible: (visible: boolean) => void;
  selectedStationChart: Station;
  setSelectedStationChart: (station: Nullable<Station>) => void;
  selectedTemplateId: string;
  setSelectedTemplateId: (id: Nullable<string>) => void;
  showUninstalledTraps: boolean;
  setShowUninstalledTraps: (show: boolean) => void;
  selectedDateFilter: DateFilterOptions;
  setSelectedDateFilter: (option: DateFilterOptions) => void;
  selectedCustomDate: SelectedDate;
  setSelectedCustomDate: (selectedDate: SelectedDate | undefined) => void;
  selectedFilters: TypesStatusFilter[];
  setSelectedFilters: (filters: TypesStatusFilter[]) => void;
  selectDrawer: SelectDrawer;
  setSelectDrawer: (drawer: SelectDrawer | undefined) => void;
  selectedMapType: StaticPointMapType;
  setSelectedMapType: (type: StaticPointMapType) => void;
  selectedIndicator: SelectData;
  setSelectedIndicator: (indicator: SelectData) => void;
  trapsSeverityByField: TrapsSeverityByField;
  setTrapsSeverityByField: (trapsSeverityByField: TrapsSeverityByField | undefined) => void;
  isLoadingTrapSeverityByField: boolean;
  selectedTemplate: StaticPointTemplate;
  setSelectedTemplate: (template: StaticPointTemplate | undefined) => void;
  isLoadingAnalyticResultsHistogram: boolean;
  setIsLoadingAnalyticResultsHistogram: (loading: boolean) => void;
  isLoadingClusters: boolean;
}
