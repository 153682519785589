import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { PROTECTOR_API_URL } from 'config/constants';
import type {
  FieldCluster,
  IFieldClusteringConfigurationData,
  IFieldClusteringPaginatedResult
} from 'pages/field-clustering/configuration/field-clustering-configuration.models';
import { useFieldClusterConfigurationStore } from 'pages/field-clustering/configuration/field-clustering-configuration.zustand';
import { mergeInsights } from 'pages/field-clustering/map-result/field-clustering-map-result.utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useInfiniteQuery, useMutation, useQuery } from '../../../core/utils/data-store/tools';
import { selectAllAssignees } from '../../../entities/assignee/assignee.reducer';
import type { AppState } from '../../../redux/app-state';
import { useParseLeafhopperStore } from '../leafhopper/leafhopper.hooks';
import type { LeafhopperConfiguration } from '../leafhopper/leafhopper.models';
import { useParsePostHarvestStore } from '../post-harvest/post-harvest.hooks';
import type { PostHarvestConfiguration } from '../post-harvest/post-harvest.models';
import { RoutersTypes } from '../post-harvest/post-harvest.models';
import type { RoutesConfigurationStatus } from '../routes-configuration.constants';
import { useParseTrapRoutingStore } from '../trap-routing/trap-routing.hooks';

const protectorApiUrl = PROTECTOR_API_URL ?? 'http://localhost:8080';

const postScoutingPlanPostHarvestRequest = async (
  object: any,
  property_id: string,
  seasonId: string,
  plan_type: RoutersTypes
): Promise<any> => {
  const response = await axios.post(
    `${protectorApiUrl}/api/v1/properties/${property_id}/seasons/${seasonId}/scouting-plan/configuration?plan_type=${plan_type}`,
    object
  );
  return response.data;
};

export const useHandlePostScoutingPlan = () => {
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();
  const planType = searchParams?.get('plan_type') as RoutersTypes;
  const { propertyId, companyId } = useParams();

  const assignees = useSelector((appState: AppState) => selectAllAssignees(appState.entities.assignee));

  const postHarvestBodyRequest = useParsePostHarvestStore(assignees);
  const leafhopperBodyRequest = useParseLeafhopperStore(assignees);
  const trapRoutingBodyRequest = useParseTrapRoutingStore(assignees);

  const { getConfigPlanData } = useFieldClusterConfigurationStore();

  const requestBody = {
    [RoutersTypes.FIELD_CLUSTER]: getConfigPlanData(companyId),
    [RoutersTypes.LEAFHOPPER]: leafhopperBodyRequest,
    [RoutersTypes.POST_HARVEST]: postHarvestBodyRequest,
    [RoutersTypes.TRAPS]: trapRoutingBodyRequest
  }[planType];

  const currentSeasons = useSelector((state: AppState) => state.uiState.global.selectedSeasons);
  const {
    isLoading,
    mutate: postScoutingPlan,
    isSuccess,
    error,
    reset
  } = useMutation(() => postScoutingPlanPostHarvestRequest(requestBody, propertyId!, currentSeasons[0], planType), {
    onError: () => {
      notification.error({
        message: t('post_harvest.messages.error_title'),
        description: t('post_harvest.messages.error_message')
      });
    }
  });
  return { isLoading, postScoutingPlan, isSuccess, error, reset };
};

const INTERVAL_POOLING_SECONDS = 5000;

const getPostHarvestStatusRequest = async (property_id: string, seasonId: string, planType: RoutersTypes): Promise<any> => {
  const response = await axios.get(
    `${protectorApiUrl}/api/v1/properties/${property_id}/seasons/${seasonId}/scouting-plan/configuration?plan_type=${planType}`
  );
  return response.data;
};

export const useGetStatesRoutesConfiguration = (
  isEnabled: boolean,
  planType: RoutersTypes
): [
  PostHarvestConfiguration | LeafhopperConfiguration | IFieldClusteringConfigurationData | undefined,
  RoutesConfigurationStatus | undefined,
  () => void,
  string | undefined,
  boolean,
  boolean
] => {
  const { propertyId } = useParams();
  const [t] = useTranslation();

  const currentSeasons = useSelector((state: AppState) => state.uiState.global.selectedSeasons);
  const [refetchInterval, setRefetchInterval] = useState(INTERVAL_POOLING_SECONDS);
  const { data, remove, isError, isLoading } = useQuery(
    [planType, propertyId!, currentSeasons[0]],
    () => {
      return getPostHarvestStatusRequest(propertyId!, currentSeasons[0], planType) as Promise<
        PostHarvestConfiguration | LeafhopperConfiguration | IFieldClusteringConfigurationData | undefined
      >;
    },
    {
      refetchInterval,
      enabled: isEnabled && !!propertyId && !!currentSeasons[0],
      retry: (_count, error: AxiosError) => {
        setRefetchInterval(0);
        return error.request.status !== 404;
      },
      onError: (error: AxiosError) => {
        if (error.request.status === 404) return;
        notification.error({
          message: t('post_harvest.messages.error_title'),
          description: t('post_harvest.messages.error_message')
        });
      }
    }
  );

  return [data, data?.status, remove, data?.id, isError, isLoading];
};

const getRoutingPaginatedResult = async ({
  params,
  planId
}: {
  params: { property_id: string; cursor: string; size: number };
  planId: string;
}): Promise<any> => {
  const response = await axios.get(`${protectorApiUrl}/api/v1/scouting-plan/plan/${planId}/trap-routing-result`, { params });

  return response.data;
};

export const useGetRoutingPaginatedResult = (): { insights: Record<string, FieldCluster>; isLoading: boolean; totalFields: number } => {
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();
  const planId = searchParams?.get('plan_id')!;
  const property_id = useParams()?.propertyId!;
  const [insights, setInsights] = useState<Record<string, FieldCluster>>({});
  const [totalFields, setTotalFields] = useState<number>(0);

  const { hasNextPage, fetchNextPage, isFetching, data, isLoading } = useInfiniteQuery({
    queryKey: ['get-routing-paginated-result', property_id, planId],
    queryFn: ({ pageParam = null }) => getRoutingPaginatedResult({ planId, params: { property_id, size: 1000, cursor: pageParam } }),
    getNextPageParam: (lastPage: IFieldClusteringPaginatedResult) => lastPage.cursor,
    enabled: !!property_id && !!planId,
    retry: 3,
    retryDelay: 2000,
    select: dataSelect => ({
      pages: [...dataSelect.pages].reverse(),
      pageParams: [...dataSelect.pageParams].reverse()
    }),
    onError: () => {
      notification.error({
        message: t('post_harvest.map_request_error')
      });
    },
    onSuccess: dataResult => {
      const currentInsights = dataResult.pages?.[0]?.content?.insights;
      setInsights(mergeInsights(insights, currentInsights));
      setTotalFields(prevState => prevState + dataResult.pages[0].size);
    }
  });

  useEffect(() => {
    if (hasNextPage && !isFetching) fetchNextPage();
  }, [hasNextPage, isFetching, data]);

  return {
    insights,
    isLoading,
    totalFields
  };
};
