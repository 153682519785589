const SvgSync2Glyph = ({ width = 16, height = 16, fillColor = '#198746' }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width + 10} ${height + 10}`}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.2355 9.15907C21.2227 9.22588 21.1991 9.29014 21.1656 9.34927C21.1568 9.4048 21.14 9.45875 21.1157 9.50943C21.0746 9.56734 21.0278 9.62099 20.976 9.66959L20.8962 9.7697C20.8354 9.81602 20.7681 9.85312 20.6966 9.87981C20.6671 9.90561 20.6333 9.92595 20.5968 9.93987C20.4884 9.98096 20.3733 10.0013 20.2575 9.99993H20.0978L15.8565 9.2892C15.5 9.23198 15.2009 8.98831 15.0721 8.64998C14.9432 8.31166 15.0041 7.93007 15.2317 7.64896C15.4594 7.36785 15.8193 7.22993 16.1758 7.28715L17.8225 7.55743C16.5376 5.32917 14.1614 3.96175 11.5952 3.97375C8.67748 3.92318 6.02893 5.67708 4.92887 8.38828C4.8293 8.636 4.63515 8.83354 4.38963 8.93694C4.14411 9.04034 3.86757 9.04102 3.62155 8.93884C3.11251 8.73073 2.8671 8.14889 3.07267 7.63751C4.44619 4.37657 7.55816 2.191 11.0828 2.01188C14.6075 1.83275 17.9237 3.69165 19.6188 6.79665L19.988 4.79459C20.0522 4.43696 20.3017 4.14084 20.6426 4.01777C20.9834 3.89471 21.3638 3.96339 21.6405 4.19796C21.9172 4.43252 22.0481 4.79733 21.9839 5.15496L21.2355 9.15907ZM19.596 15.0921C19.8419 14.9867 20.1198 14.9846 20.3673 15.0863C20.8739 15.2865 21.1271 15.8564 20.9367 16.3677C19.5598 19.6278 16.4438 21.8114 12.916 21.9884C9.38818 22.1654 6.07016 20.3046 4.37531 17.1986L4.00573 19.2008C3.91786 19.6824 3.49531 20.0297 3.00685 20.0217H2.82706C2.55512 19.9741 2.31495 19.8159 2.16358 19.5845C2.0122 19.3531 1.96311 19.0693 2.02795 18.8004L2.77711 14.796C2.7882 14.7349 2.81203 14.6769 2.84703 14.6258C2.8626 14.5598 2.88608 14.4959 2.91695 14.4356L3.00685 14.3355C3.05888 14.268 3.1193 14.2074 3.18665 14.1553C3.28546 14.0925 3.39325 14.0452 3.50629 14.0151H3.5862C3.69516 13.995 3.80687 13.995 3.91583 14.0151L8.16105 14.7059C8.71272 14.7501 9.12415 15.2342 9.08002 15.7871C9.03588 16.34 8.5529 16.7523 8.00123 16.7081H7.84141L6.19327 16.4378C7.47934 18.6662 9.85766 20.0337 12.4263 20.0217C15.3224 20.0623 17.9504 18.3288 19.0588 15.6469C19.1565 15.3973 19.35 15.1975 19.596 15.0921Z'
      fill={fillColor}
    />
  </svg>
);

export default SvgSync2Glyph;
