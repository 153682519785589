const SvgPin = props => (
  <svg width={24} height={24} viewBox='6 6 20 20' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5 11.0631C17.5 9.22743 19.017 7.75 20.875 7.75C22.733 7.75 24.25 9.22743 24.25 11.0631C24.25 12.5001 23.3197 13.7177 22.0257 14.1785C21.9142 14.3581 21.7627 14.5772 21.6368 14.7557C21.5435 14.8879 21.4553 15.0103 21.3905 15.0994C21.3581 15.1441 21.3315 15.1805 21.3129 15.2059L21.2912 15.2353L21.2835 15.2458L21.28 15.2506H20.47L20.4665 15.2459L20.4586 15.2352L20.437 15.2057C20.4184 15.1802 20.3917 15.1437 20.3592 15.099C20.2944 15.0097 20.2061 14.8871 20.1128 14.7548C19.987 14.5764 19.8356 14.3576 19.7244 14.1785C18.4304 13.7177 17.5 12.5001 17.5 11.0631ZM20.8751 14.083C21.0383 13.8489 21.1824 13.632 21.2325 13.5337L21.3319 13.3389L21.5433 13.2781C22.5267 12.9954 23.2375 12.107 23.2375 11.0631C23.2375 9.79401 22.1858 8.75453 20.875 8.75453C19.5642 8.75453 18.5125 9.79401 18.5125 11.0631C18.5125 12.107 19.2233 12.9954 20.2067 13.2781L20.4181 13.3389L20.5175 13.5337C20.5672 13.6311 20.7114 13.8483 20.8751 14.083Z'
      fill='#9A6412'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.75 18.1426C7.75 15.4626 9.93825 13.3003 12.625 13.3003C15.3117 13.3003 17.5 15.4626 17.5 18.1426C17.5 20.238 16.1617 22.0166 14.2954 22.6929C14.1351 22.954 13.9188 23.2705 13.7393 23.5281C13.6051 23.7207 13.4783 23.8989 13.3851 24.0287C13.3385 24.0937 13.3002 24.1468 13.2734 24.1838L13.2423 24.2267L13.2312 24.2419L13.2253 24.25H12.0247L12.0189 24.242L12.0075 24.2264L11.9764 24.1834C11.9496 24.1464 11.9112 24.0932 11.8645 24.0281C11.7712 23.898 11.6442 23.7195 11.51 23.5268C11.3307 23.2694 11.1148 22.9533 10.9547 22.693C9.08838 22.0167 7.75 20.2381 7.75 18.1426ZM12.6251 22.5022C12.852 22.1721 13.0492 21.8703 13.1188 21.7323L13.2656 21.4409L13.5789 21.3497C14.9824 20.9411 16 19.656 16 18.1426C16 16.3023 14.4946 14.8003 12.625 14.8003C10.7554 14.8003 9.25 16.3023 9.25 18.1426C9.25 19.656 10.2676 20.9411 11.6711 21.3497L11.9844 21.4409L12.1312 21.7323C12.2001 21.8691 12.3977 22.1712 12.6251 22.5022Z'
      fill='#9A6412'
    />
  </svg>
);

export default SvgPin;
