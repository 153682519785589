import { InputNumber as AntInputNumber } from 'antd';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 4px;

  .ant-input-group {
    width: 100%;
    display: flex;
  }
`;

export const Label = styled.div<{ isRequired: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const NumericAddon = styled.div<{ addAfterBackgroundColor?: string; radiusLeft?: boolean; radiusRight?: boolean }>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: ${({ addAfterBackgroundColor }) => addAfterBackgroundColor ?? css`var(--addon-select)`};
  min-width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ radiusLeft }) =>
    radiusLeft &&
    css`
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    `}

  ${({ radiusRight }) =>
    radiusRight &&
    css`
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
`;

export const InputNumber = styled(AntInputNumber)<{
  backgroundColor?: string;
  borderRadius?: string;
  borderColor?: string;
  hoveredBorderColor?: string;
}>`
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#f3f4f6'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '4px'};
  border: solid 1px;
  border-color: ${({ borderColor }) => `${borderColor ?? '#f3f4f6'}`};

  :hover {
    border-color: ${({ hoveredBorderColor }) => hoveredBorderColor ?? '#349459'};
    border-right-width: 1px !important;
  }

  &.ant-input-number-lg {
    input {
      height: 40px;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export const NumericInputGroup = styled.div<{ hasPreffix: boolean; hasSuffix: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasPreffix, hasSuffix }) => {
    if (hasPreffix && !hasSuffix) return '33% auto';
    if (!hasPreffix && hasSuffix) return 'auto 33%';
    if (hasPreffix && hasSuffix) return '16.5% auto 16.5%';
    return '100%';
  }};

  .ant-input-number {
    ${({ hasSuffix }) => css`
      border-top-right-radius: ${hasSuffix ? '0px' : '4px'};
      border-bottom-right-radius: ${hasSuffix ? '0px' : '4px'};
    `}

    ${({ hasPreffix }) =>
      hasPreffix &&
      css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `}
  }
`;
