import type { Action } from 'core/utils/basic.models';
import type { LoadHeatmapParams, LoadHeatmapsSuccessType } from './heatmap.models';

export const HeatmapActionsTypes = {
  RELOAD_HEATMAPS: '[Analytics/Heatmap] Reload Heatmaps',
  RELOAD_HEATMAPS_SUCCESS: '[Analytics/Heatmap] Reload Heatmaps Success',
  RELOAD_HEATMAPS_FAILURE: '[Analytics/Heatmap] Reload Heatmaps Failure',
  LOAD_HEATMAPS: '[Analytics/Heatmap] Load Heatmaps',
  LOAD_HEATMAPS_SUCCESS: '[Analytics/Heatmap] Load Heatmaps Success',
  LOAD_HEATMAPS_FAILURE: '[Analytics/Heatmap] Load Heatmaps Failure',
  CLEAR_HEATMAPS: '[Analytics/Heatmap] Clear Heatmaps'
};

export interface ReloadHeatmapsModel {
  request: LoadHeatmapParams;
  retry: number;
}

export const LoadHeatmaps = (request: LoadHeatmapParams): Action<LoadHeatmapParams> => ({
  type: HeatmapActionsTypes.LOAD_HEATMAPS,
  payload: request
});

export const ReloadHeatmaps = (data: ReloadHeatmapsModel): Action<ReloadHeatmapsModel> => ({
  type: HeatmapActionsTypes.RELOAD_HEATMAPS,
  payload: data
});

export const LoadHeatmapsSuccess = (heatmaps: LoadHeatmapsSuccessType): Action<LoadHeatmapsSuccessType> => {
  return {
    type: HeatmapActionsTypes.LOAD_HEATMAPS_SUCCESS,
    payload: heatmaps
  };
};

export const LoadHeatmapsFailure = (): Action => {
  return {
    type: HeatmapActionsTypes.LOAD_HEATMAPS_FAILURE
  };
};

export const ClearHeatmaps = (): Action => {
  return {
    type: HeatmapActionsTypes.CLEAR_HEATMAPS
  };
};
