const SvgWeatherDailyMostlyCloudWithRain = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M18.5121 11.0818L17.3851 9.66378C17.9661 9.20178 18.2981 8.51478 18.2981 7.77278C18.2981 6.44178 17.2161 5.36078 15.8841 5.36078C14.7001 5.36078 13.6781 6.24278 13.5001 7.40678C13.4801 7.52878 13.4721 7.65078 13.4721 7.77078H11.6641C11.6641 7.56178 11.6791 7.34478 11.7121 7.13578C12.0231 5.09178 13.8161 3.55078 15.8841 3.55078C18.2131 3.55078 20.1081 5.44378 20.1081 7.77178C20.1071 9.06778 19.5251 10.2718 18.5121 11.0818Z'
      fill='#F0C355'
    />
    <path d='M13.8223 5.1615L12.2383 1.8975L13.8643 1.1875L15.4503 4.3745L13.8223 5.1615Z' fill='#F0C355' />
    <path d='M9.70629 4.94583L9.04785 6.62109L12.3984 7.93797L13.0568 6.26271L9.70629 4.94583Z' fill='#F0C355' />
    <path d='M17.8187 4.9515L16.0977 4.4005L17.1307 1.1875L18.8727 1.6665L17.8187 4.9515Z' fill='#F0C355' />
    <path d='M22.3183 5.12581L21.4033 3.56641L18.2742 5.40254L19.1892 6.96194L22.3183 5.12581Z' fill='#F0C355' />
    <path d='M22.7458 8.37497L19.2002 7.60547L18.8161 9.37536L22.3617 10.1449L22.7458 8.37497Z' fill='#F0C355' />
    <path
      d='M16.9036 19.5382H6.30861C4.05761 19.5382 2.22461 17.7052 2.22461 15.4552C2.22461 13.4962 3.61361 11.8532 5.45661 11.4612C5.85661 8.42722 8.46061 6.07422 11.6046 6.07422C13.8726 6.07422 15.9316 7.30322 17.0216 9.25322C19.8016 9.31722 22.0456 11.5972 22.0456 14.3952C22.0466 17.2322 19.7376 19.5382 16.9036 19.5382ZM6.30861 13.1832C5.05561 13.1832 4.03561 14.2032 4.03561 15.4552C4.03561 16.7102 5.05661 17.7272 6.30861 17.7272H16.9036C18.7406 17.7272 20.2356 16.2332 20.2356 14.3962C20.2356 12.5562 18.7406 11.0652 16.9036 11.0652C16.8666 11.0652 16.8076 11.0722 16.7416 11.0812C16.6816 11.0882 16.6266 11.0962 16.5676 11.1022L15.9086 11.1652L15.6496 10.5542C14.9556 8.93322 13.3696 7.88422 11.6066 7.88422C9.18561 7.88422 7.21461 9.85622 7.21461 12.2792V13.1822L6.30861 13.1832Z'
      fill='#868CA2'
    />
    <path d='M7.86471 20.5586H6.05371V22.8156H7.86471V20.5586Z' fill='#0071CD' />
    <path d='M12.9057 20.5586H11.0967V22.8156H12.9057V20.5586Z' fill='#0071CD' />
    <path d='M17.9496 20.5586H16.1396V22.8156H17.9496V20.5586Z' fill='#0071CD' />
  </svg>
);

export default SvgWeatherDailyMostlyCloudWithRain;
