import { notification } from 'antd';
import type { Dictionary } from 'config/types';
import type { Nullable } from 'core/core.models';
import { CropTypesEnum } from 'core/core.models';
import { getNameByCurrentLanguage, validatePlansAndEntitlements } from 'core/utils/functions';
import type { IndicatorDTO } from 'entities/phenomenon/phenomenon.models';
import type { Region } from 'entities/region/region.models';
import { debounce, groupBy } from 'lodash';
import type {
  ControlStrategyAreasCard,
  ControlStrategyEventDay,
  ControlStrategyRegionCard
} from 'pages/control-strategy/control-strategy-model';
import { useGetRBACAuthorityUser } from 'querys/rbac/rbac.query';
import { RBACActions, RBACLevels, RBACPermissionTypesEnum } from 'querys/rbac/rbac.query.model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import i18n from '../../config/i18n';
import { SetCurrentProperty } from '../../core/core.actions';
import { getSystemFlags } from '../../core/core.selectors';
import { Entitlements } from '../../core/shared/enums/entitlements.enum';
import type { LicensingStatusResponse } from '../../entities/company/company.models';
import { getSelectedCompany } from '../../entities/company/company.reducer';
import type { Season } from '../../entities/season/season.models';
import { getSelectedSeasons } from '../../entities/season/season.reducer';
import type { AppState } from '../../redux/app-state';

const getEventAreasBuilder = (eventsByArea: Nullable<ControlStrategyEventDay>[], indicators: Dictionary<IndicatorDTO>) => {
  const eventAreasCards: ControlStrategyAreasCard[] = [];
  const eventRegionDayState: Record<string, boolean> = {};
  eventsByArea.forEach(event => {
    if (!event) {
      return;
    }
    const indicator = indicators[event.target.indicator_id];
    const eventCard = {
      area_id: event?.target.area_id ?? '',
      indicator_id: event?.target.indicator_id ?? '',
      indicator_name: indicator ? getNameByCurrentLanguage(indicator.name) : '',
      value: event?.value ?? 0,
      unit_of_measure: indicator ? getNameByCurrentLanguage(indicator.uom) : '',
      event_day: event?.event_day ?? '',
      diagnostics: indicator?.diagnostics,
      event_name: event?.event_name ?? ''
    };
    eventRegionDayState[`${event.group_id}_${event.event_day}`] = event.acknowledged;
    eventAreasCards.push(eventCard);
  });
  const eventAreasGroupByDate = groupBy(eventAreasCards, e => e.event_day);
  return { eventAreasGroupByDate, eventRegionDayState };
};

export const buildControlStrategyEventRegion = (
  eventsByRegion: Dictionary<ControlStrategyEventDay[]>,
  fieldsByRegion: Dictionary<Region[]>,
  indicators: Dictionary<IndicatorDTO>,
  regions: Dictionary<Region>
) => {
  const eventsRegionsCards: ControlStrategyRegionCard[] = [];

  Object.entries(fieldsByRegion).forEach(([regionId, fields]) => {
    if (!regions[regionId]) {
      return;
    }

    const fieldsFiltered: ControlStrategyEventDay[] = [];

    fields.forEach(field => {
      if (field) {
        fieldsFiltered.push(...getEventsInArea(eventsByRegion[regionId] || [], field.id));
      }
    });
    const { eventAreasGroupByDate, eventRegionDayState } = getEventAreasBuilder(fieldsFiltered, indicators);
    const regionPath = getRegionPath(regions, regions[regionId]?.parent_id ?? '');
    Object.entries(eventAreasGroupByDate).forEach(([eventDay, eventAreas]) => {
      eventsRegionsCards.push({
        event_areas: eventAreas,
        path: regionPath,
        region_id: regionId,
        name: regions[regionId].name,
        event_date: eventDay,
        acknowledged: eventRegionDayState[`${regionId}_${eventDay}`] ?? false
      });
    });
  });
  return eventsRegionsCards;
};

export const getLastEvent = (eventsByRegion: Nullable<ControlStrategyEventDay>[]): Nullable<ControlStrategyEventDay> => {
  if (!eventsByRegion.length) return null;
  let lastEvent = eventsByRegion[0];
  eventsByRegion
    .filter(event => !!event)
    .forEach(event => {
      if (event && lastEvent && event.event_day > lastEvent.event_day) lastEvent = event;
    });
  return lastEvent;
};

export const getEventsInArea = (eventsByRegion: ControlStrategyEventDay[], fieldId: string): ControlStrategyEventDay[] => {
  return eventsByRegion.filter(event => event.target.area_id === fieldId);
};

export const getIndicatorEvents = (eventsInArea: ControlStrategyEventDay[], indicatorId: string): ControlStrategyEventDay[] => {
  return eventsInArea.filter(event => event.target.indicator_id === indicatorId);
};

export const getRegionPath = (regions: Dictionary<Region>, regionId: string): string => {
  const currentRegion = regions[regionId];

  if (currentRegion?.parent_id) {
    const localCurrentPath = currentRegion.name;
    let parentPath = getRegionPath(regions, currentRegion.parent_id);
    parentPath = parentPath === '' ? parentPath : `${parentPath}/`;
    return `${parentPath}${localCurrentPath}`;
  }
  return '';
};

export const getSugarcaneSeason = (selectedSeasons: Season[]): Season | undefined =>
  selectedSeasons?.find(s => s?.crop?.wk_slug.toLocaleLowerCase() === CropTypesEnum.SUGARCANE.toLocaleLowerCase());

export const useGetSugarcaneSeasonPermission = (): boolean => {
  const selectedSeasons = useSelector<AppState, Season[]>(state => getSelectedSeasons(state));
  const company = useSelector((state: AppState) => getSelectedCompany(state));
  const [isSugarCane, setIsSugarCane] = useState<boolean>(true);
  const sugarcanePermission = useGetRBACAuthorityUser({
    rbacActions: RBACActions.SUGARCANE,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  });

  useEffect(() => {
    const sugarcaneSeason = getSugarcaneSeason(selectedSeasons);
    if (company?.id) {
      setIsSugarCane(!!sugarcanePermission?.length);
    }
    if ((!!selectedSeasons.length && !sugarcaneSeason) || !sugarcanePermission?.length) {
      setIsSugarCane(false);
    }
  }, [company, selectedSeasons, sugarcanePermission]);

  return isSugarCane;
};

export const useValidateSugarcaneSeasonPermission = (additionalFlag = true): void => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSugarCane = useGetSugarcaneSeasonPermission();
  const flags = useSelector<AppState, Dictionary<boolean | string> | null>(state => state.uiState.global.systemFlags);
  const company = useSelector<AppState, string | null>(state => state.uiState.global.selectedCompany);

  const checkUserPermissionByData = useRef<boolean>(false);

  const userUnauthorizedAction = useCallback(() => {
    notification.error({
      message: i18n.t('pages.borer_risk.validation_message'),
      description: i18n.t('pages.borer_risk.validation_description')
    });
    dispatch(SetCurrentProperty(null));
    navigate('/hall-of-companies');
  }, [dispatch, navigate]);

  const verifyUserUnauthorized = useCallback(() => {
    const debounceVerify = debounce(() => {
      if (checkUserPermissionByData.current) {
        userUnauthorizedAction();
      }
    }, 5000);
    debounceVerify();
  }, [checkUserPermissionByData, userUnauthorizedAction]);

  useEffect(() => {
    const orgsAllowlist = JSON.parse(flags?.control_strategies_orgs_allowlist as string) as string[];

    const isOrgInAllowList = orgsAllowlist.includes(company ?? '');
    // If it's the first load, set the flag in session storage to true and reload the page
    const currentSession = sessionStorage.getItem('useValidateSugarcaneSeasonPermission');

    const hasSugarCanePermission = isSugarCane && additionalFlag === true;
    if (currentSession === null) {
      sessionStorage.setItem('useValidateSugarcaneSeasonPermission', 'loaded');
      if (!isOrgInAllowList && !hasSugarCanePermission) {
        userUnauthorizedAction();
      }
    } else {
      checkUserPermissionByData.current = !isOrgInAllowList && !hasSugarCanePermission;
      verifyUserUnauthorized();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalFlag, isSugarCane, dispatch, navigate, flags]);
};

export const useHasPermissionToAccessInternalRoutesServices = (): boolean => {
  const isProtectorRolesAuthority = useGetRBACAuthorityUser({
    rbacActions: RBACActions.ADMIN_DATA_MANAGEMENT,
    rbacLevels: RBACLevels.APP,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  });

  return !!isProtectorRolesAuthority?.length;
};

export const useIsRoutesConfigurationEnable = (): [boolean, boolean, boolean] => {
  const flags = useSelector(getSystemFlags);
  const isSugarCane = useGetSugarcaneSeasonPermission();
  const isEnableProtectorRole = useHasPermissionToAccessInternalRoutesServices();

  const licensingStatus = useSelector<AppState, LicensingStatusResponse | null>(state => state.entities.company.licensingStatus);
  const isPostHarvestEntitlement = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.POST_HARVEST]);

  let isPostHarvestEnabled = true;

  if (!(!!flags?.P40_26186_post_harvest_configuration && isSugarCane && isPostHarvestEntitlement)) isPostHarvestEnabled = false;
  if (flags?.post_harvest_enable_only_admin_strider && !isEnableProtectorRole) isPostHarvestEnabled = false;

  let isLeafhopperEnabled = true;
  if (!(!!flags?.P40_28469_leafhopper_plan && isSugarCane && isPostHarvestEntitlement)) isLeafhopperEnabled = false;
  if (flags?.leafhopper_enable_only_admin_strider && !isEnableProtectorRole) isLeafhopperEnabled = false;

  return [isPostHarvestEnabled, isLeafhopperEnabled];
};
