import type { UUID } from '../../../core/utils/basic.models';
import type { AssigneeGroupConfig, RoutingPlanConfiguration } from '../routing-configuration.model';

export interface PostHarvestConfiguration extends RoutingPlanConfiguration {
  assignee_groups: AssigneeGroupConfig[];
  inspection_groups_ids: UUID[];
  last_cut_days_interval: {
    min: number;
    max: number;
  };
  days_within_next_activity: number;
  prioritize_renovation_areas: boolean;
  sampling_points: {
    type: string;
    points_factor: number;
    minimum_ratio: number;
  };
}

export enum PostHarvestSamplingType {
  Free = 'FREE',
  Grid = 'GRID'
}

export interface PrioritiesState {
  rangeDays: {
    min: number;
    max: number;
  };
  postHarvestDays: number;
  withRenovationArea: boolean;
}

export interface AssigneesGroupState {
  groupId: UUID;
  groupName: string;
  assigneesIds: UUID[];
}

export interface AssigneesGroupProductivityState {
  type: string;
  value: number;
}

export interface SamplingState {
  samplingType: PostHarvestSamplingType;
  quantityByArea: number;
  percentToCompleted: number;
}

export enum RoutingConfigurationSteps {
  InspectionGroups = 'InspectionGroups',
  Priorities = 'Priorities',
  PostHarvestDate = 'PostHarvestDate',
  AssigneesGroup = 'AssigneesGroup',
  Assignees = 'Assignees',
  Sampling = 'Sampling',
  ResidualProducts = 'ResidualProducts',
  LeafhopperPriority = 'LeafhopperPriority',
  PestRiskPriority = 'PestRiskPriority',
  TrapDistribution = 'TrapDistribution'
}

export enum RoutersTypes {
  POST_HARVEST = 'post_harvest',
  LEAFHOPPER = 'leafhopper',
  TRAPS = 'trap_router',
  FIELD_CLUSTER = 'field_cluster'
}

export enum RoutersFileTypes {
  CSV = 'route-url',
  JSON = 'input-route-url'
}

export const TimeoutErrorMsg =
  'Something went wrong in the field_cluster router algorithm: The solution is taking too long to be generated!';
