const PlusFilledGlyph = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM7 4C7 3.44772 7.44772 3 8 3C8.55228 3 9 3.44772 9 4V7H12C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9H9V12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12V9H4C3.44772 9 3 8.55228 3 8C3 7.44772 3.44772 7 4 7H7V4Z'
      fill='#515253'
    />
  </svg>
);

export default PlusFilledGlyph;
