import type { PermissionFlags } from 'core/services/auth/auth.models';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import SvgSprayCard from 'st-protector-icons/Minor/SprayCard';
import SvgWarehouseReportCard from 'st-protector-icons/Minor/WarehouseReportCard';
import { useShowOnlyReportsForAllOrg } from '../report-drawer.component.hook';
import { ReportsTypes } from '../reports-types.enum';
import ReportButton from './report-button.component';

interface ReportDrawerApplicationsAndInventoryButtonsTypes {
  setReportToOpen: (reportType: ReportsTypes) => void;
  permissionFlags: PermissionFlags;
}

export const ReportDrawerApplicationsAndInventoryButtons: React.FC<ReportDrawerApplicationsAndInventoryButtonsTypes> = ({
  setReportToOpen,
  permissionFlags
}) => {
  const showOnlyReportsForAllOrg = useShowOnlyReportsForAllOrg();
  const [t] = useTranslation();

  if (showOnlyReportsForAllOrg) {
    return null;
  }

  const { hideWarehouse, hideInventory, hideReportAplicationStatistics } = permissionFlags;

  const hasAnyReport = !hideReportAplicationStatistics?.value || (!hideWarehouse?.value && !hideInventory?.value);

  return (
    <>
      {hasAnyReport && (
        <div className='st-report-drawer__subtitle-group' data-testid='report-subtitle'>
          <STTypo type={TypeOptions.P2} color='#696F88' uppercase>
            <STTypo>{t('report.title.group.applications')}</STTypo>
          </STTypo>
        </div>
      )}
      {/* Application statistics */}
      {!hideReportAplicationStatistics?.value && (
        <ReportButton
          className='st-report-drawer__item-spray'
          setReportToOpen={setReportToOpen}
          text={t('report.spray.name')}
          type={ReportsTypes.SPRAY}
          icon={<SvgSprayCard />}
        />
      )}
      {/* Inventory statistics */}
      {!hideWarehouse?.value && !hideInventory?.value && (
        <ReportButton
          className='st-report-drawer__item-sample'
          setReportToOpen={setReportToOpen}
          text={t('report.warehouse.name')}
          type={ReportsTypes.WAREHOUSE_INVENTORY}
          icon={<SvgWarehouseReportCard fill='#1A7AA3' />}
        />
      )}
    </>
  );
};
