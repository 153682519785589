import type {
  LoadMethodologyDeepFailureType,
  LoadMethodologyDeepSuccessType,
  LoadMethodologyDeepType,
  UpdateMethodologyDeepFailureType,
  UpdateMethodologyDeepSuccessType,
  UpdateMethodologyDeepType
} from './methodology-deep.models';

export const MethodologyDeepActionTypes = {
  LOAD_METHODOLOGY_DEEP: '[Methodology] Load Methodology Deep',
  LOAD_METHODOLOGY_DEEP_SUCCESS: '[Methodology] Load Methodology Deep Success',
  LOAD_METHODOLOGY_DEEP_FAILURE: '[Methodology] Load Methodology Deep Failure',

  UPDATE_METHODOLOGY_DEEP: '[Methodology] Update Methodology Deep',
  UPDATE_METHODOLOGY_DEEP_SUCCESS: '[Methodology] Update Methodology Deep Success',
  UPDATE_METHODOLOGY_DEEP_FAILURE: '[Methodology] Update Methodology Deep Failure'
};

export const LoadMethodologyDeep: LoadMethodologyDeepType = methodology => {
  return {
    type: MethodologyDeepActionTypes.LOAD_METHODOLOGY_DEEP,
    payload: methodology
  };
};

export const LoadMethodologyDeepSuccess: LoadMethodologyDeepSuccessType = methodologyDeep => {
  return {
    type: MethodologyDeepActionTypes.LOAD_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  };
};

export const LoadMethodologyDeepFailure: LoadMethodologyDeepFailureType = error => {
  return {
    type: MethodologyDeepActionTypes.LOAD_METHODOLOGY_DEEP_FAILURE,
    payload: error
  };
};

export const UpdateMethodologyDeep: UpdateMethodologyDeepType = methodologyPayload => {
  return {
    type: MethodologyDeepActionTypes.UPDATE_METHODOLOGY_DEEP,
    payload: methodologyPayload
  };
};

export const UpdateMethodologyDeepSuccess: UpdateMethodologyDeepSuccessType = methodology => {
  return {
    type: MethodologyDeepActionTypes.UPDATE_METHODOLOGY_DEEP_SUCCESS,
    payload: methodology
  };
};

export const UpdateMethodologyDeepFailure: UpdateMethodologyDeepFailureType = error => {
  return {
    type: MethodologyDeepActionTypes.UPDATE_METHODOLOGY_DEEP_FAILURE,
    payload: error
  };
};
