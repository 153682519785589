import { COLOR_OPTIONS } from 'config/style';

interface CropLemonProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropLemon = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropLemonProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.6038 4.82717C14.032 4.04262 13.9604 3.14619 13.4071 2.59294C12.8538 2.03966 11.9574 1.96804 11.1728 2.39617C10.1496 1.96457 8.93287 1.88219 7.72319 2.16588C6.41759 2.47211 5.19262 3.16879 4.18073 4.18071C3.16884 5.19259 2.47211 6.41756 2.16591 7.72318C1.88216 8.93289 1.96459 10.1496 2.39617 11.1728C1.96804 11.9574 2.03966 12.8538 2.59294 13.4071C3.14624 13.9603 4.04264 14.032 4.82717 13.6038C5.45039 13.8667 6.14517 14 6.86666 14C7.32975 14 7.80391 13.945 8.27679 13.8341C9.58241 13.5279 10.8074 12.8312 11.8193 11.8193C12.8312 10.8074 13.5279 9.58243 13.8341 8.27681C14.1178 7.06708 14.0354 5.85036 13.6038 4.82717Z'
      fill={color}
    />
  </svg>
);

export default CropLemon;
