import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import type { AppState } from 'redux/app-state';
import { UploadFileActionsTypes } from './upload-file.actions';
import type { FileModel, UploadFileState } from './upload-file.models';

const entity: EntityAdapter<FileModel> = createEntityAdapter<FileModel>();

const initialState = entity.getInitialState<UploadFileState>({
  ids: [],
  entities: {},
  metadatas: null,
  isLoadingAssets: false,
  error: null,
  errorPost: null,
  isLoadingDelete: undefined,
  isLoadingPost: undefined,
  isLoadingEditing: undefined
});

export default (state = initialState, action) => {
  switch (action.type) {
    case UploadFileActionsTypes.LOAD_UPLOADFILE_LIST:
      return {
        ...state,
        isLoadingAssets: true,
        error: null
      };

    case UploadFileActionsTypes.LOAD_UPLOADFILE_LIST_SUCCESS:
      return {
        ...state,
        isLoadingAssets: false,
        metadatas: action.payload,
        isLoadingDelete: undefined,
        isLoadingPost: undefined,
        isLoadingEditing: undefined
      };

    case UploadFileActionsTypes.LOAD_UPLOADFILE_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoadingAssets: false,
        isLoadingDelete: undefined,
        isLoadingPost: undefined,
        isLoadingEditing: undefined
      };

    case UploadFileActionsTypes.LOAD_UPLOADFILE_IMAGES:
      return {
        ...state,
        isLoadingAssets: true,
        error: null
      };

    case UploadFileActionsTypes.LOAD_UPLOADFILE_IMAGES_SUCCESS:
      return {
        ...state,
        isLoadingAssets: false,
        error: null,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload.item
        }
      };

    case UploadFileActionsTypes.LOAD_UPLOADFILE_IMAGES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoadingAssets: false
      };

    case UploadFileActionsTypes.POST_UPLOADFILE:
      return {
        ...state,
        isLoadingPost: true,
        errorPost: null
      };
    case UploadFileActionsTypes.POST_UPLOADFILE_FAILURE:
      return {
        ...state,
        isLoadingPost: false,
        errorPost: action.payload
      };
    case UploadFileActionsTypes.POST_UPLOADFILE_SUCCESS:
      return {
        ...state,
        isLoadingPost: false
      };
    case UploadFileActionsTypes.DELETE_UPLOADFILE:
      return {
        ...state,
        isLoadingDelete: true,
        error: null
      };
    case UploadFileActionsTypes.DELETE_UPLOADFILE_FAILURE:
      return {
        ...state,
        isLoadingDelete: false,
        error: action.payload
      };
    case UploadFileActionsTypes.DELETE_UPLOADFILE_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false
      };
    case UploadFileActionsTypes.UPDATE_UPLOADFILE:
      return {
        ...state,
        isLoadingEditing: true,
        error: null
      };
    case UploadFileActionsTypes.UPDATE_UPLOADFILE_FAILURE:
      return {
        ...state,
        isLoadingEditing: false,
        error: action.payload
      };
    case UploadFileActionsTypes.UPDATE_UPLOADFILE_SUCCESS:
      return {
        ...state,
        isLoadingEditing: false
      };
    default:
      return state;
  }
};

const { selectEntities } = entity.getSelectors();
export const selectUploadFileItemEntities = selectEntities;
export const selectIsLoadingUploadFileIList = (state: AppState) => state.analytics.uploadFile.isLoadingAssets;
