import type { Nullable } from 'core/core.models';
import type { Region } from 'entities/region/region.models';

export const RegionsSideMenuActionsTypes = {
  SET_HOVERED_REGION: '[Regions Side Menu] Set Hovered Region'
};

export const SetHoveredRegion = (hoveredRegion: Nullable<Region>): { type: string; payload: Nullable<Region> } => {
  return {
    type: RegionsSideMenuActionsTypes.SET_HOVERED_REGION,
    payload: hoveredRegion
  };
};
