const SvgRenew = props => (
  <svg width={12} height={18} viewBox='0 0 12 18' {...props} fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 6.75V4.5C3.5175 4.5 1.5 6.5175 1.5 9C1.5 9.7575 1.6875 10.4775 2.025 11.1L0.93 12.195C0.345 11.2725 0 10.1775 0 9C0 5.685 2.685 3 6 3V0.75L9 3.75L6 6.75ZM9.975 6.90018L11.07 5.80518C11.655 6.72768 12 7.82268 12 9.00018C12 12.3152 9.315 15.0002 6 15.0002V17.2502L3 14.2502L6 11.2502V13.5002C8.4825 13.5002 10.5 11.4827 10.5 9.00018C10.5 8.24268 10.305 7.53018 9.975 6.90018Z'
      fill='#14803C'
    />
  </svg>
);

export default SvgRenew;
