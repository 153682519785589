const SvgPinSamplePointsCard = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <g clipPath='url(#clip0_11107_102411)'>
      <rect width='32' height='32' rx='8' fill='white' />
      <rect width='32' height='32' rx='4' fill='#E0F9F7' />
      <path
        d='M16.75 15.4975L20.5 20.5H11.5L15.25 15.4975V11.5H16.75V15.4975ZM18.97 10H13.03C12.715 10 12.5425 10.36 12.7375 10.6075L13.75 11.875V15.0025L9.40001 20.8C9.03251 21.295 9.38501 22 10 22H22C22.615 22 22.9675 21.295 22.6 20.8L18.25 15.0025V11.875L19.2625 10.6075C19.4575 10.36 19.285 10 18.97 10Z'
        fill='#217C74'
      />
    </g>
    <defs>
      <clipPath id='clip0_11107_102411'>
        <rect width='32' height='32' rx='8' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPinSamplePointsCard;
