import type { SVGProps } from 'react';

const SvgInfoOutlineGlyph = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width ?? 16} height={props.height ?? 16} {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8ZM0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM8 6.5C8.55229 6.5 9 6.94772 9 7.5V11.5C9 12.0523 8.55229 12.5 8 12.5C7.44772 12.5 7 12.0523 7 11.5V7.5C7 6.94772 7.44772 6.5 8 6.5ZM9 4.5C9 5.05228 8.55229 5.5 8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5Z'
    />
  </svg>
);

export default SvgInfoOutlineGlyph;
