import { ConfigProvider, Select } from 'antd';
import type { SimplePhenomena, UUID } from 'core/utils/basic.models';
import { getNameByCurrentLanguage, textContains } from 'core/utils/functions';
import type { PhenomenonSingle } from 'entities/methodology-deep/methodology-deep.models';
import _ from 'lodash';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Dictionary } from 'config/types';

interface PhenomenaSingleSelectProps {
  selected: UUID[];
  handleSetSelected: (value: UUID[]) => void;
  phenomenons: PhenomenonSingle[];
  disabled?: boolean;
}

const PhenomenaSingleSelect: FC<PhenomenaSingleSelectProps> = ({ selected, phenomenons, handleSetSelected, disabled }) => {
  const [t] = useTranslation();

  const [groupedPhenomena, setGroupedPhenomena] = useState<Dictionary<SimplePhenomena[]>>({});
  const [open, setOpen] = useState(false);

  const phenomena = useMemo(() => {
    return phenomenons.map<SimplePhenomena>((phenomenoun: PhenomenonSingle) => {
      return {
        id: phenomenoun.id,
        name: phenomenoun.name,
        short_description: phenomenoun.short_description,
        category: getNameByCurrentLanguage(phenomenoun.category.name),
        methodologyId: '',
        companyId: phenomenoun.company_id
      };
    });
  }, [phenomenons]);

  const handlePhenomenaSearch = useCallback(
    (value: string) => {
      const filteredPhenomena = phenomena.filter(
        p => textContains(getNameByCurrentLanguage(p.name), value) || textContains(p.category, value)
      );

      setGroupedPhenomena(_.groupBy(filteredPhenomena, 'category'));
    },
    [phenomena]
  );

  useEffect(() => {
    setGroupedPhenomena(_.groupBy(phenomena, 'category'));
  }, [phenomena]);

  const handleSelect = (values: string[]) => {
    setGroupedPhenomena(_.groupBy(phenomena, 'category'));

    if (values.includes('all')) {
      if (values.length === phenomena.length + 1) {
        handleSetSelected([]);
      } else {
        handleSetSelected(phenomena.map(el => el.id));
      }
      setOpen(!open);
    } else {
      handleSetSelected(values);
    }
  };

  const handleOpen = () => {
    setGroupedPhenomena(_.groupBy(phenomena, 'category'));
    setOpen(!open);
  };

  return (
    <ConfigProvider>
      <Select
        data-testid='phenomena-select-input'
        value={selected}
        disabled={disabled}
        onChange={handleSelect}
        onSearch={handlePhenomenaSearch}
        showSearch
        filterOption={false}
        open={open}
        onDropdownVisibleChange={handleOpen}
        mode='multiple'
        size='large'
        placeholder={t('modals.timeline_xray_report.fields.phenomena_placeholder')}>
        {phenomena.length && <Select.Option value='all'>{t('modals.timeline_xray_report.fields.phenomena_all')}</Select.Option>}
        {Object.keys(groupedPhenomena).map(group => (
          <Select.OptGroup key={group} label={group}>
            {groupedPhenomena[group].map(phenomenon => (
              <Select.Option data-testid={`phenomena-select-input-option-${phenomenon.id}`} key={phenomenon.id} value={phenomenon.id}>
                {getNameByCurrentLanguage(phenomenon.name)}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>
    </ConfigProvider>
  );
};

export default PhenomenaSingleSelect;
