import type { Action, UUID } from '../../core/utils/basic.models';
import type { LoadReportsSuccessType, RequestReportBody, RequestReportBodyApplicationStatistics, RequestReportSucess } from './report.models';

export const ReportsActionTypes = {
  LOAD_REPORTS_REQUEST: '[Reports] Load Reports Request',
  LOAD_REPORTS_SUCCESS: '[Reports] Load Reports Success',
  LOAD_REPORTS_FAILURE: '[Reports] Load Reports Failure',
  CREATE_REPORT_REQUEST: '[Reports] Create Task Request',
  CREATE_REPORT_SUCCESS: '[Reports] Create Task Success',
  CREATE_REPORT_FAILURE: '[Reports] Create Task Failure',
  CLEAR_REPORTS: '[Reports] Clear Reports'
};

export const LoadReportsRequest = (propertyId: UUID, cursor?: string) => {
  return {
    type: ReportsActionTypes.LOAD_REPORTS_REQUEST,
    payload: { propertyId, cursor }
  };
};

export const RequestReport = (requestBody: RequestReportBody) => {
  return { type: ReportsActionTypes.CREATE_REPORT_REQUEST, payload: requestBody };
};

export const RequestReportApplicationStatistics = (requestBody: RequestReportBodyApplicationStatistics) => {
  return { type: ReportsActionTypes.CREATE_REPORT_REQUEST, payload: requestBody };
};

export const CreateReportSuccess: RequestReportSucess = report => {
  return {
    type: ReportsActionTypes.CREATE_REPORT_SUCCESS,
    payload: report
  };
};

export const CreateReportFailure = (error: string): Action<string> => {
  return {
    type: ReportsActionTypes.CREATE_REPORT_FAILURE,
    payload: error
  };
};

export const LoadReportsSuccess: LoadReportsSuccessType = reports => {
  return {
    type: ReportsActionTypes.LOAD_REPORTS_SUCCESS,
    payload: reports
  };
};

export const LoadReportFailure = (error: string): Action<string> => {
  return {
    type: ReportsActionTypes.LOAD_REPORTS_FAILURE,
    payload: error
  };
};

export const ClearReports = (): Action => {
  return {
    type: ReportsActionTypes.CLEAR_REPORTS
  };
};
