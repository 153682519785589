import type { FontFamily } from 'core/shared/typo/typo.models';

export const FONT_FAMILY: FontFamily = '"Noto Sans", sans-serif';

export const TYPOGRAPHY_LEVEL = {
  H1: 42,
  H2: 30,
  H3: 24,
  H4: 20,
  H5: 16
};
