import { HeatmapActionsTypes } from './heatmap.actions';
import type { HeatmapState } from './heatmap.models';

const initialState: HeatmapState = {
  loadingHeatmap: false,
  currentHeatmap: undefined,
  heatmaps: undefined
};

export default (state: HeatmapState = initialState, action): HeatmapState => {
  switch (action.type) {
    case HeatmapActionsTypes.LOAD_HEATMAPS:
      return {
        ...state,
        loadingHeatmap: action.payload.indicator ? action.payload.indicator.id : action.payload.phenomenon.id
      };

    case HeatmapActionsTypes.LOAD_HEATMAPS_SUCCESS:
      if (state.loadingHeatmap) {
        return {
          loadingHeatmap: false,
          currentHeatmap: action.payload.currentHeatmap,
          heatmaps: action.payload.heatmaps
        };
      }
      return state;

    case HeatmapActionsTypes.LOAD_HEATMAPS_FAILURE:
    case HeatmapActionsTypes.CLEAR_HEATMAPS:
      return initialState;

    default:
      return state;
  }
};
