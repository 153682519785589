import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  color: white;
  background: #14803c;
  &:hover {
    color: white;
    background: #0c4c24;
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none !important;
  }

  .ant-modal-footer {
    border-top: none !important;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledEmpty = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 10px;
`;
