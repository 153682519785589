import { selectSelectedCompany, selectSelectedProperty } from 'core/core.reducer';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import { WeatherActionsTypes } from './weather.actions';
import type { WeatherState } from './weather.models';

export const initialState: WeatherState = {
  loadingWeather: false,
  data: [],
  dataHourly: [],
  error: null
};

export default (state: WeatherState = initialState, action): WeatherState => {
  switch (action.type) {
    case WeatherActionsTypes.LOAD_WEATHER:
      return {
        ...state,
        loadingWeather: true
      };

    case WeatherActionsTypes.LOAD_WEATHER_SUCCESS:
      return {
        ...state,
        loadingWeather: false,
        data: [...[action.payload], ...state.data],
        error: null
      };

    case WeatherActionsTypes.LOAD_WEATHER_FAILURE:
      return {
        ...state,
        loadingWeather: false,
        error: action.payload
      };

    case WeatherActionsTypes.LOAD_WEATHER_HOURLY:
      return {
        ...state,
        loadingWeather: true
      };

    case WeatherActionsTypes.LOAD_WEATHER_HOURLY_SUCCESS:
      return {
        ...state,
        loadingWeather: false,
        dataHourly: [...[action.payload], ...state.dataHourly],
        error: null
      };

    case WeatherActionsTypes.LOAD_WEATHER_HOURLY_FAILURE:
      return {
        ...state,
        loadingWeather: false,
        error: action.payload
      };

    case WeatherActionsTypes.CLEAR_WEATHER:
      return initialState;

    default:
      return state;
  }
};

export const getSelectedWeatherByCompany = createSelector(
  (state: AppState) => state.analytics.weather.data,
  (state: AppState) => selectSelectedCompany(state.uiState.global),
  (weather, selectedCompany) => weather.filter(data => data.companyId === selectedCompany)
);

export const getSelectedWeatherByProperty = createSelector(
  (state: AppState) => state.analytics.weather.data,
  (state: AppState) => selectSelectedProperty(state.uiState.global),
  (weather, selectedProperty) => weather.filter(data => data.propertyId === selectedProperty)
);

export const getSelectedWeatherHourlyByProperty = createSelector(
  (state: AppState) => state.analytics.weather.dataHourly,
  (state: AppState) => selectSelectedProperty(state.uiState.global),
  (weather, selectedProperty) => weather.filter(data => data.propertyId === selectedProperty)
);
