import { COLOR_OPTIONS } from 'config/style';

interface CropBrachiariaProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropBrachiaria = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropBrachiariaProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.69197 8.76142C8.42133 9.35089 8.20079 9.93416 8.0235 10.4967C7.81888 9.77758 7.55269 9.02232 7.21368 8.26193C7.03033 7.85067 6.82454 7.43546 6.59424 7.02187C7.63883 4.90187 9.25307 2.88003 11.5998 2C10.3913 3.20855 9.17398 5.94012 8.69197 8.76142Z'
      fill={color}
    />
    <path
      d='M1.3999 3.19995C6.04508 6.02457 7.3354 11.5769 7.39992 14C6.19992 13.4 5.59991 13.3769 4.49669 13.3769C5.11604 9.55535 2.69023 4.99996 1.3999 3.19995Z'
      fill={color}
    />
    <path
      d='M14.5999 3.79999C9.95468 6.62461 8.66435 11.5769 8.59984 14C9.79984 13.4 10.3998 13.3769 11.5031 13.3769C10.8837 9.55539 13.3095 5.59999 14.5999 3.79999Z'
      fill={color}
    />
  </svg>
);

export default CropBrachiaria;
