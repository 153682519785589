const SvgWeatherSnowLight = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M7.1 11.8H5.8C3.6 11.8 1.8 10 1.8 7.8C1.8 5.7 3.5 3.9 5.6 3.8C6.5 2.3 8 1.4 9.7 1.4C12.3 1.4 14.5 3.8 14.5 6.5H12.7C12.7 4.8 11.3 3.2 9.7 3.2C8.5 3.2 7.4 3.9 7 5L6.7 5.6L6.1 5.5H6H5.9C4.7 5.5 3.7 6.5 3.7 7.7C3.7 8.9 4.7 9.9 5.9 9.9H7.2V11.8H7.1Z'
      fill='#BDC4D1'
    />
    <path
      d='M17.2 18.2H7.6C5.5 18.2 3.8 16.5 3.8 14.4C3.8 12.6 5.1 11.1 6.7 10.7C7.2 8 9.5 5.8 12.4 5.8C14.5 5.8 16.3 6.9 17.4 8.7C19.9 8.8 22 10.9 22 13.4C21.9 16.1 19.8 18.2 17.2 18.2ZM7.6 12.4C6.5 12.4 5.6 13.3 5.6 14.4C5.6 15.5 6.5 16.4 7.6 16.4H17.2C18.8 16.4 20.2 15.1 20.2 13.4C20.2 11.8 18.9 10.4 17.2 10.4H17.1C17 10.4 17 10.4 16.9 10.4L16.3 10.5L16 10C15.4 8.6 14 7.6 12.4 7.6C10.2 7.6 8.5 9.4 8.5 11.5V12.4H7.6Z'
      fill='#828B99'
    />
    <path
      d='M8.81177 19.1606C8.52488 18.9024 8.08608 18.9139 7.81315 19.1869C7.52975 19.4703 7.52975 19.9297 7.81315 20.2131L8.3 20.7L7.85322 21.1021C7.5525 21.3727 7.54021 21.8402 7.82628 22.1263C8.09184 22.3918 8.51881 22.4031 8.79797 22.1518L9.3 21.7L9.7 22.1C9.97615 22.3761 10.4239 22.3761 10.7 22.1C10.9761 21.8239 10.9761 21.3761 10.7 21.1L10.3 20.7L10.7394 20.2118C10.9976 19.9249 10.9861 19.4861 10.7132 19.2131C10.4297 18.9297 9.97026 18.9297 9.68685 19.2131L9.3 19.6L8.81177 19.1606Z'
      fill='#92B0ED'
    />
    <path
      d='M15.1118 19.1606C14.8249 18.9024 14.3861 18.9139 14.1132 19.1869C13.8298 19.4703 13.8298 19.9297 14.1132 20.2131L14.6 20.7L14.1532 21.1021C13.8525 21.3727 13.8402 21.8402 14.1263 22.1263C14.3918 22.3918 14.8188 22.4031 15.098 22.1518L15.6 21.7L16 22.1C16.2761 22.3761 16.7239 22.3761 17 22.1C17.2761 21.8239 17.2761 21.3761 17 21.1L16.6 20.7L17.0394 20.2118C17.2976 19.9249 17.2861 19.4861 17.0132 19.2131C16.7298 18.9297 16.2703 18.9297 15.9869 19.2131L15.6 19.6L15.1118 19.1606Z'
      fill='#92B0ED'
    />
  </svg>
);

export default SvgWeatherSnowLight;
