import { Input } from 'antd';
import useDebounce from 'core/utils/hooks/custom-hooks';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvgSearch from 'st-protector-icons/Minor/Search';
import './search.styles.less';

interface SearchProps {
  placeholder: string;
  searchValue?: string;
  debounce?: number;
  className?: string;
  blockStyle?: React.CSSProperties;
  prefixIcon?: boolean;
  resetButton?: boolean;
  handleSearch: (search: string) => void;
}

const Search: React.FC<SearchProps> = ({
  placeholder,
  searchValue,
  debounce,
  className,
  blockStyle,
  prefixIcon = true,
  resetButton,
  handleSearch
}) => {
  const [t] = useTranslation();

  const [contentToSearch, setContentToSearch] = useState<string>(searchValue || '');
  const debouncedSearchTerm = useDebounce(contentToSearch, debounce || 500);

  const onSearchChanged = event => {
    setContentToSearch(event.target.value);
  };

  const onClickClearFilter = () => setContentToSearch('');

  useEffect(() => {
    handleSearch(contentToSearch);
  }, [debouncedSearchTerm]);

  return (
    <div key='search' className='search' style={{ ...blockStyle }}>
      <Input
        className={className}
        data-testid='navigator-search-input'
        value={contentToSearch}
        onChange={onSearchChanged}
        size='large'
        prefix={prefixIcon ? <SvgSearch fill='#707374' height={18} width={18} /> : null}
        placeholder={placeholder}
      />
      {resetButton && (
        <button type='button' data-testid='phenomenon-overtime-list-filter-button' onClick={onClickClearFilter}>
          {t('charts.phenomenon_over_time.legend.search_button.title')}
        </button>
      )}
    </div>
  );
};

export default Search;
