const FieldMonitoring = props => (
  <svg width={24} height={24} viewBox='6 6 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M14.125 10.9375C14.95 10.9375 15.625 10.2625 15.625 9.4375C15.625 8.6125 14.95 7.9375 14.125 7.9375C13.3 7.9375 12.625 8.6125 12.625 9.4375C12.625 10.2625 13.3 10.9375 14.125 10.9375Z'
      fill='#9A6412'
    />
    <path
      d='M11.3125 13.4875L9.25 24.0625H10.825L12.1375 18.0625L13.75 19.5625V24.0625H15.25V18.4L13.7125 16.8625L14.1625 14.6125C15.1375 15.8125 16.6 16.5625 18.25 16.5625V15.0625C16.8625 15.0625 15.6625 14.3125 14.9875 13.225L14.275 12.025C14.0125 11.575 13.525 11.3125 13 11.3125C12.8125 11.3125 12.625 11.35 12.4375 11.425L8.5 13.0375V16.5625H10V14.05L11.3125 13.4875Z'
      fill='#9A6412'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.875 20.3091C17.875 18.7572 19.1347 17.5 20.6875 17.5C22.2403 17.5 23.5 18.7572 23.5 20.3091C23.5 21.5288 22.7221 22.5657 21.6361 22.9543C21.5492 23.1153 21.4223 23.3412 21.3086 23.5419C21.2305 23.6798 21.1565 23.8094 21.102 23.9045L21.0125 24.0605L21.0114 24.0625H20.3636L20.3625 24.0605L20.2728 23.9042C20.2183 23.8089 20.1442 23.6791 20.066 23.5411C19.9524 23.3406 19.8256 23.1149 19.7389 22.9543C18.6529 22.5657 17.875 21.5288 17.875 20.3091ZM20.6875 23.1166C20.8353 22.8554 20.9838 22.5889 21.0397 22.4772L21.1129 22.3311L21.2696 22.2852C22.1259 22.0343 22.75 21.2437 22.75 20.3091C22.75 19.1724 21.8271 18.25 20.6875 18.25C19.5479 18.25 18.625 19.1724 18.625 20.3091C18.625 21.2437 19.2491 22.0343 20.1054 22.2852L20.2621 22.3311L20.3353 22.4772C20.3908 22.588 20.5395 22.8549 20.6875 23.1166Z'
      fill='#9A6412'
    />
  </svg>
);

export default FieldMonitoring;
