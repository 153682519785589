import type { Nullable } from 'core/core.models';
import { getSelectedPropertyId, getSystemFlags } from 'core/core.selectors';
import type { UUID } from 'core/utils/basic.models';
import { useQueryClient } from 'core/utils/data-store/tools';
import { getColor } from 'core/utils/generate-colors';
import type { CurrentSeasonArea } from 'entities/property/property.models';
import { isEmpty } from 'lodash';
import type { FixedPointInfo } from 'pages/fixed-points/fixed-points.models';
import type { RegionTree } from 'pages/static-points/static-points-drawer/static-points-drawer-content-inputs.types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type {
  FieldClusterInfo,
  FieldClusterProps,
  GenerateRegionTreeDictProps,
  ValidateAreaProps,
  ValidateFieldsClusteringReturnProps
} from './fields-clustering.query.models';
import { FieldClusterQueryType } from './fields-clustering.query.models';
import { getCurrentAreaSeasons, getSelectedTemplate } from './fields-clustering.selectors';

export const getAllAreasIds = (currentAreaSeasons: CurrentSeasonArea[], selectedFieldClusters: FieldClusterProps): string[] => {
  if (!currentAreaSeasons?.length || !selectedFieldClusters || isEmpty(selectedFieldClusters)) return [];

  const areasIds = new Set<string>();

  currentAreaSeasons.forEach(item => {
    if (selectedFieldClusters.season_area_ids.includes(item.id)) {
      areasIds.add(item.areaId);
    }
  });

  return [...areasIds];
};

export const getAllPhenomenaIds = (selectedTemplate: Nullable<FixedPointInfo>): string[] => {
  if (!selectedTemplate?.inspection_layout?.ordered_inspection_groups?.length) return [];

  const phenomenaList = new Set<string>();
  selectedTemplate.inspection_layout.ordered_inspection_groups.forEach(ordered_inspection_group => {
    ordered_inspection_group.ordered_categories.forEach(ordered_category => {
      ordered_category.ordered_phenomenons.forEach(ordered_phenomenon => {
        phenomenaList.add(ordered_phenomenon.id);
      });
    });
  });

  return [...phenomenaList];
};

export const validateArea = ({
  fieldClusters,
  phenomenaIds,
  selectedSeasonArea,
  currentAreaSeasons
}: ValidateAreaProps): FieldClusterProps | undefined => {
  const selectedCluster = fieldClusters.find(fieldCluster => {
    const hasPhenomenon = fieldCluster.phenomenon_ids.some(phenomenonId => {
      return phenomenaIds.includes(phenomenonId);
    });

    const hasSeasonArea = fieldCluster.season_area_ids.some(seasonAreaId => selectedSeasonArea.id === seasonAreaId);

    return hasPhenomenon && hasSeasonArea && fieldCluster;
  });

  if (!selectedCluster) return undefined;

  const areas_ids = getAllAreasIds(currentAreaSeasons, selectedCluster);

  return { ...selectedCluster, areas_ids };
};

export const useValidateFieldsClusteringBySeasonArea = (fieldId?: string): ValidateFieldsClusteringReturnProps => {
  const queryClient = useQueryClient();

  const enableFieldClustering = !!useSelector(getSystemFlags)?.P40_22549_enableFieldClustering;
  const selectedTemplate = useSelector(getSelectedTemplate);
  const currentAreaSeasons = useSelector(getCurrentAreaSeasons);
  const propertyId = useSelector(getSelectedPropertyId);

  const fieldClusters = queryClient.getQueryData<FieldClusterProps[]>([
    FieldClusterQueryType.GET_ALL_FIELDS_CLUSTERS,
    propertyId,
    enableFieldClustering
  ]);

  const areaIsInsideACluster = useMemo((): FieldClusterProps | undefined => {
    if (!enableFieldClustering || !fieldId || !currentAreaSeasons?.length) return undefined;

    const selectedSeasonArea = currentAreaSeasons.find(currentAreaSeason => currentAreaSeason.areaId === fieldId);

    const phenomenaIds = getAllPhenomenaIds(selectedTemplate);

    if (!selectedSeasonArea || !phenomenaIds.length || !fieldClusters?.length) return undefined;

    return validateArea({ fieldClusters, phenomenaIds, selectedSeasonArea, currentAreaSeasons });
  }, [enableFieldClustering, currentAreaSeasons, selectedTemplate, fieldClusters, fieldId]);

  return { areaIsInsideACluster };
};

export const generateRegionTreeDict = (
  regions: Nullable<RegionTree['children']>,
  parent_id?: string
): Record<string, GenerateRegionTreeDictProps> | undefined => {
  if (!regions?.length) return undefined;

  return regions.reduce((acc, region) => {
    const mutableAcc = acc;
    mutableAcc[region.id] = {
      name: region.name,
      ...(parent_id && { parent_id })
    };

    if (region.children) {
      const child = generateRegionTreeDict(region.children, region.id);
      return {
        ...mutableAcc,
        ...child
      };
    }

    return mutableAcc;
  }, {} as Record<string, GenerateRegionTreeDictProps>);
};

export const generateBreadcrumb = (areas_ids: string[] | undefined, regionDict) => {
  if (!areas_ids?.length || !regionDict || isEmpty(regionDict)) return undefined;
  return areas_ids.reduce((acc, areaId) => {
    const mutableAcc = acc;
    mutableAcc[areaId] = '';

    const recursive = id => {
      if (acc[areaId]) {
        mutableAcc[areaId] = `${regionDict[id].name} / ${acc[areaId]}`;
      } else {
        mutableAcc[areaId] = regionDict[id].name;
      }

      if (regionDict[id].parent_id) {
        recursive(regionDict[id].parent_id);
      }
    };

    recursive(areaId);

    return mutableAcc;
  }, {});
};

export const getClusterByPhenonenaGroup = (phenomenaIds: UUID[], fieldClusters: FieldClusterProps[]): FieldClusterInfo => {
  const phenomenaClusters = fieldClusters.filter(fieldCluster =>
    fieldCluster.phenomenon_ids.find(fieldClusterphenomenaId => phenomenaIds.includes(fieldClusterphenomenaId))
  );
  const fieldClusterInfo: FieldClusterInfo = {};
  let clusterNumber = 1;
  const clusterNumberById: Record<string, number> = {};
  const clusterColorById: Record<string, string> = {};
  phenomenaClusters.forEach(cluster => {
    cluster.areas_ids.forEach(fieldId => {
      if (!clusterColorById[cluster.id]) {
        clusterColorById[cluster.id] = getColor(clusterNumber - 1);
      }
      if (!clusterNumberById[cluster.id]) {
        clusterNumberById[cluster.id] = clusterNumber;
        clusterNumber += 1;
      }

      fieldClusterInfo[fieldId] = {
        clusterColor: clusterColorById[cluster.id],
        clusterId: cluster.id,
        clusterNumber: clusterNumberById[cluster.id]
      };
    });
  });
  return fieldClusterInfo;
};
