import type { Moment } from 'core/utils/basic.models';
import type { MonitoringDetailThreshold } from 'pages/timeline/monitoring.models';
import type { CustomIndicator } from '../../pages/fixed-points/fixed-points.models';
import type { AllIndicators, DatePeriod, IndividualIndicator } from './indicators.models';

const buildThresholdsFromCustomIndicator = (customIndicator: CustomIndicator): MonitoringDetailThreshold[] | undefined => {
  return (
    customIndicator &&
    customIndicator.diagnostics &&
    customIndicator.diagnostics[0]?.thresholds?.map(threshold => ({
      label: threshold.label.localized_strings.en,
      color: threshold.color,
      left: threshold.start_value,
      right: threshold.end_value
    }))
  );
};

const divideDatesIntoPeriods = (startDate: Moment, endDate: Moment, maxDaysInterval = 15): DatePeriod[] => {
  const timeIntervals: DatePeriod[] = [];
  let currentStartDate = startDate.clone();
  let remainingDays = endDate.diff(startDate, 'days');

  while (remainingDays >= 0) {
    const endDay = remainingDays > maxDaysInterval ? maxDaysInterval : remainingDays;
    const currentEndDate = currentStartDate.clone().add(endDay, 'days');

    timeIntervals.push({
      startDate: currentStartDate,
      endDate: currentEndDate
    });

    currentStartDate = currentStartDate.clone().add(endDay + 1, 'days');
    remainingDays -= endDay + 1;
  }

  return timeIntervals;
};

const sortIndividualIndicatorsDays = (individualIndicators: IndividualIndicator[]): IndividualIndicator[] => {
  return individualIndicators.map(indicator => {
    indicator.days = indicator.days.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    return indicator;
  });
};

const sortAllIndicatorsIndividualDays = (allIndicators: AllIndicators[]): AllIndicators[] => {
  return allIndicators.map(indicator => {
    indicator.indicators = sortIndividualIndicatorsDays(indicator.indicators);
    return indicator;
  });
};

const mergeIndividualIndicators = (individualIndicators: IndividualIndicator[]): IndividualIndicator[] => {
  const individualsMap = individualIndicators.reduce((acc, curr) => {
    const individualIndicator = acc.get(curr.id);
    if (individualIndicator) {
      individualIndicator.days = individualIndicator.days.concat(curr.days);
      acc.set(curr.id, individualIndicator);
    } else {
      acc.set(curr.id, curr);
    }
    return acc;
  }, new Map<string, IndividualIndicator>());

  return Array.from(individualsMap.values());
};

const mergeAllIndicators = (allIndicators: AllIndicators[]): AllIndicators[] => {
  const indicatorsMap = allIndicators.reduce((acc, curr) => {
    const indicator = acc.get(curr.id);
    if (indicator) {
      indicator.indicators = mergeIndividualIndicators(indicator.indicators.concat(curr.indicators));
      acc.set(curr.id, indicator);
    } else {
      acc.set(curr.id, curr);
    }
    return acc;
  }, new Map<string, AllIndicators>());

  return Array.from(indicatorsMap.values());
};

const IndicatorsUtil = {
  buildThresholdsFromCustomIndicator,
  divideDatesIntoPeriods,
  mergeIndividualIndicators,
  mergeAllIndicators,
  sortAllIndicatorsIndividualDays
};

export default IndicatorsUtil;
