import { Alert } from 'antd';
import { COLOR_OPTIONS } from 'config/style';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models.ts';
import { useTranslation } from 'react-i18next';
import type { ColumnErrorInterface, LineErrorInterface } from '../../report-property.models.ts';
import { handleSelectColumnErrorText } from './import-error.functions.ts';
import { StyledImportErrorContainer, StyledImportErrorDescription, StyledImportErrorItem } from './import-error.styles.ts';

interface ImportErrorProps {
  errorsList: (LineErrorInterface | ColumnErrorInterface)[];
}

export const ImportError = ({ errorsList }: ImportErrorProps) => {
  const [t] = useTranslation();

  return (
    <StyledImportErrorContainer>
      <Alert
        message={
          <STTypo type={TypeOptions.H5} color={COLOR_OPTIONS.PRIMARY}>
            {t('report.drawer.error_title')}
          </STTypo>
        }
        description={
          <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.SECONDARY}>
            {t('report.drawer.error_description')}
          </STTypo>
        }
        type='error'
        showIcon
      />
      <StyledImportErrorDescription>
        <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.PRIMARY}>
          {t('report.drawer.error_description_2')}
        </STTypo>
      </StyledImportErrorDescription>

      {errorsList?.map(error => {
        const isLineError = 'lineNumber' in error;

        const key = isLineError ? error.lineNumber : error.columnNumber;
        const message = isLineError
          ? `${t('report.drawer.line')} ${error.lineNumber}`
          : `${t('report.drawer.columns.name')} ${error.columnNumber}`;
        const columnErrorText = isLineError
          ? t(`report.drawer.error_type.${error.errorType.toLowerCase()}`)
          : handleSelectColumnErrorText(error, t);

        return (
          <StyledImportErrorItem key={key}>
            <STTypo type={TypeOptions.P1} fontWeight={500} color={COLOR_OPTIONS.PRIMARY}>
              {message}
              {': '}
            </STTypo>
            <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.SECONDARY}>
              {columnErrorText}
            </STTypo>
          </StyledImportErrorItem>
        );
      })}
    </StyledImportErrorContainer>
  );
};
