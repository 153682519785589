import { PRODUCT_PAGE } from 'config/constants';
import type { Action, UUID } from 'core/utils/basic.models';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { of } from 'rxjs/internal/observable/of';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LoadProductsByIdsSuccess, LoadProductsFailure, LoadProductsSuccess, ProductActionsTypes } from './product.actions';
import type { LoadProductPayload } from './product.models';
import { getAllProductsWithMemoization, getProductsByIds } from './product.service';

export const handleLoadProducts = (action$: ActionsObservable<Action<LoadProductPayload>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(ProductActionsTypes.LOAD_PRODUCT),
    map((action: Action<LoadProductPayload>) => action.payload!),
    withLatestFrom(state$.pipe(map((state: AppState) => state.uiState.global.systemFlags))),
    switchMap(([payload, flags]) => {
      return getAllProductsWithMemoization(
        payload.companyId,
        payload.pageSize ?? PRODUCT_PAGE,
        !!flags?.P40_38006_LOAD_PRODUCTS_PARALLEL
      ).pipe(
        map(response => {
          return LoadProductsSuccess(response);
        }),
        catchError((error: string) => of(LoadProductsFailure(error)))
      );
    })
  );

export const handleLoadProductsByIds = (action$: ActionsObservable<Action<UUID[]>>) =>
  action$.pipe(
    ofType(ProductActionsTypes.LOAD_PRODUCT_BY_IDS),
    map((action: Action<UUID[]>) => action.payload!),
    concatMap((ids: UUID[]) =>
      getProductsByIds(ids).pipe(
        map(products => {
          return LoadProductsByIdsSuccess(products);
        })
      )
    )
  );
