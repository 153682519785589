import { Drawer } from 'antd';
import { usePreviousValue } from 'beautiful-react-hooks';
import AlertCardInfo from 'components/SD-alert-card-info/alert-card-info';
import STTrackingExportDrawer from 'core/components/header/components/report/drawers/report-property.tracking-export.component';
import { ToggleMonitoringDrawer } from 'core/core.actions';
import { selectSystemFlags } from 'core/core.reducer';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { selectsTheFirstPropertyIdOfTheEntities } from 'entities/property/property.selectors';
import { LoadReportsRequest, RequestReport } from 'entities/reports/report.actions';
import type { ReportInfo, RequestReportBody } from 'entities/reports/report.models';
import { ReportStatus } from 'entities/reports/report.models';
import { getPropertyReports, isLoadingReports, wasReportRequested } from 'entities/reports/report.reducer';
import { showDiseaseRiskReport } from 'entities/reports/report.selectors';
import { downloadReport } from 'entities/reports/report.service';
import moment from 'moment';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useParams } from 'react-router-dom';
import type { AppState } from 'redux/app-state';
import SvgEmptyFolderGlyph from 'st-protector-icons/Glyph/EmptyFolderGlyph';
import { SvgBlocked } from 'st-protector-icons/Minor/Blocked';
import SvgDiseaseRisk from 'st-protector-icons/Minor/DiseaseRisk';
import SvgMapExport from 'st-protector-icons/Minor/ExportMap';
import FieldMonitoring from 'st-protector-icons/Minor/FieldMonitoring';
import { RainReportSvg } from 'st-protector-icons/Minor/RainReport';
import SvgReport from 'st-protector-icons/Minor/Report';
import SvgReportConsolidated from 'st-protector-icons/Minor/ReportConsolidated';
import SvgReportDetailed from 'st-protector-icons/Minor/ReportDetailed';
import SvgSample from 'st-protector-icons/Minor/Sample';
import SvgSpray from 'st-protector-icons/Minor/Spray';
import SvgStaticPointReport from 'st-protector-icons/Minor/StaticPointReport';
import SvgPinSample from 'st-protector-icons/Minor/SvgPinSample';
import SvgTableChart from 'st-protector-icons/Minor/TableChart';
import SvgWarehouseReport from 'st-protector-icons/Minor/WarehouseReport';
import { ReactComponent as SvgPinSamplePoints } from 'st-protector-icons/Minor/svg-pin-sample-points.svg';
import STImportSampling from './drawers/import-sampling.component';
import ReportBlockedFields from './drawers/report-blocked-fields.component';
import ReportControlStrategy from './drawers/report-control-strategy.component';
import STReportDetailedConsolidatedDrawer from './drawers/report-detailed-consolidated.component';
import { ReportDiseaseRisk } from './drawers/report-disease-risk';
import STReportRainGaugeDrawer from './drawers/report-rain-gauge.component';
import STReportSamplesDrawer from './drawers/report-sample.component';
import STReportScoutingDrawer from './drawers/report-scouting.component';
import STReportSprayDrawer from './drawers/report-spray.component';
import STReportTrapWarehouse from './drawers/report-trap-warehouse.component';
import STReportVisitDrawer from './drawers/report-visit.component';
import ReportDrawerButtonList from './report-drawer-button-list.component';
import STReportDrawerList from './report-drawer-list.component';
import { useShowOnlyReportsForAllOrg } from './report-drawer.component.hook';
import './report.styles.less';
import type { IReportTypes } from './reports-types.enum';
import { ReportsTypes } from './reports-types.enum';
import { getReportTypes } from './utils/reportTypes';

const STReportPropertyDrawer = React.lazy(() => import('core/components/header/components/report/drawers/report-property.component'));

const STReportDrawerRender: React.FC = () => {
  const segmentTracking = useSegmentTracking();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [reportToOpen, setReportToOpen] = useState<ReportsTypes | null>(null);

  const permissionFlags = useSelector((state: AppState) => state.uiState.auth.permissionFlags);
  const firstPropertyId = useSelector(selectsTheFirstPropertyIdOfTheEntities);
  const hasAccessDiseaseRiskReport = useSelector(showDiseaseRiskReport);
  const reportRequested = useSelector(wasReportRequested);
  const reportIsLoading = useSelector(isLoadingReports);
  const reportsInfos = useSelector(getPropertyReports);
  const systemFlags = useSelector(selectSystemFlags);
  const property = useSelector(getSelectedProperty);
  const showOnlyReportsForAllOrg = useShowOnlyReportsForAllOrg();

  const visible = useSelector((state: AppState) => state.uiState.global.layoutState.showMonitoringDrawer);

  const { companyId } = useParams();

  const callbackDrawer = useCallback(() => {
    dispatch(ToggleMonitoringDrawer());
  }, [dispatch]);

  const propertyMatch = !!useMatch({
    path: '/company/:companyId/property/:propertyId',
    end: false
  });

  const previousReportType = usePreviousValue(reportToOpen);

  useEffect(() => {
    if (visible) dispatch(LoadReportsRequest(property?.id ?? firstPropertyId));
  }, [visible, property, dispatch, firstPropertyId]);

  const loadReportListWithinTimer = useCallback(() => {
    dispatch(LoadReportsRequest(property?.id ?? firstPropertyId));
  }, [dispatch, property, firstPropertyId]);

  useEffect(() => {
    if (visible && !reportIsLoading) {
      const haveRunningReports = reportsInfos.some(report => ![ReportStatus.FAILED, ReportStatus.FINISHED].includes(report.status));
      if (haveRunningReports) {
        setTimeout(loadReportListWithinTimer, 10000);
      }
    }
  }, [visible, reportIsLoading, reportsInfos, loadReportListWithinTimer]);

  useEffect(() => {
    if (!reportToOpen || reportToOpen === previousReportType) return;

    if (reportToOpen === ReportsTypes.DISEASE_RISK) {
      segmentTracking.track('Reports - Export Report - Selected disease risk report');
      return;
    }

    segmentTracking.track('Export Report - Selected report type', { reportType: reportToOpen });
  }, [reportToOpen, segmentTracking, previousReportType]);

  const closeDrawer = () => {
    segmentTracking.track('Export Report - Closed Export Report drawer', { reportType: reportToOpen ?? '' });
    callbackDrawer();
  };

  const closeDrawerReport = () => {
    setReportToOpen(null);
  };

  const onClickDownload = useCallback(
    async (id: UUID) => {
      if (property ?? firstPropertyId) {
        const url = await downloadReport(id, property?.id ?? firstPropertyId);
        segmentTracking.track('Export Report - Downloaded Report', { reportType: reportToOpen ?? '' });
        window.open(url, '_blank');
      }
    },
    [firstPropertyId, property, reportToOpen, segmentTracking]
  );

  const onClickReRun = useCallback(
    (report: ReportInfo) => {
      const requestBody: RequestReportBody = {
        id: report.id,
        property_id: report.property_id,
        type: report.type,
        data_request: report.data_request
      };
      dispatch(RequestReport(requestBody));
    },
    [dispatch]
  );

  const reportTypes: IReportTypes = getReportTypes({
    showDiseaseRiskReport: hasAccessDiseaseRiskReport,
    companyId: `${companyId}`,
    setReportToOpen,
    permissionFlags,
    propertyMatch,
    systemFlags,
    t
  });
  useEffect(() => {
    if (visible && reportRequested) {
      dispatch(LoadReportsRequest(property?.id ?? firstPropertyId));
    }
  }, [visible, property, dispatch, reportRequested, firstPropertyId]);

  const reportDrawerComponent = useCallback(() => {
    const reportsInfosFilter = reportsInfos.filter(r => {
      const lastWeekMoment = moment().subtract(7, 'd');
      return moment(r.modified_at).diff(lastWeekMoment, 'ms') > 0;
    });

    return (
      <>
        <div className='st-report-drawer__container-list-reports'>
          <div className='st-report-drawer__subtitle' data-testid='report-subtitle'>
            <STTypo type={TypeOptions.P2} color='#696F88' uppercase>
              <STTypo>{t('report.last')}</STTypo>
            </STTypo>
          </div>
        </div>
        {reportsInfosFilter.length ? (
          <div>
            <STReportDrawerList
              data-testid='report-drawer-list'
              onClickDownload={id => void onClickDownload(id)}
              onClickReRun={onClickReRun}
              isLoading={reportRequested || (reportIsLoading && !reportsInfos.length)}
              reports={reportsInfosFilter}
              reportTypes={reportTypes}
            />
          </div>
        ) : (
          <div className='st-report-drawer__subtitleEmpty'>
            <span className='st-report-drawer__subtitleEmpty__svg'>
              <SvgEmptyFolderGlyph height={128} width={176} fill='#E8EAED' />
            </span>
            <span className='st-report-drawer__subtitleEmpty__title'>
              <STTypo type={TypeOptions.P1} color='#2F3031'>
                {t('report.no_recent_report')}
              </STTypo>
            </span>
            <STTypo type={TypeOptions.P1} color='#696F88'>
              {t('report.no_recent_report_description')}
            </STTypo>
          </div>
        )}
      </>
    );
  }, [reportsInfos, t, onClickReRun, reportRequested, reportIsLoading, reportTypes, onClickDownload]);

  return (
    <>
      <STReportSprayDrawer visible={reportToOpen} callbackDrawer={closeDrawerReport} icon={<SvgSpray fill='#01658d' />} />

      <STReportSamplesDrawer
        visible={reportToOpen === ReportsTypes.SAMPLE || reportToOpen === ReportsTypes.SAMPLING}
        callbackDrawer={closeDrawerReport}
        type={reportToOpen}
        icon={reportToOpen === ReportsTypes.SAMPLE ? <SvgPinSample fill='#705BC1' /> : <SvgPinSamplePoints />}
      />

      <STReportRainGaugeDrawer
        icon={<RainReportSvg />}
        visible={reportToOpen === ReportsTypes.RAIN_GAUGE_REPORT}
        callbackDrawer={closeDrawerReport}
      />

      <STImportSampling
        visible={reportToOpen === ReportsTypes.SAMPLING_IMPORT}
        callbackDrawer={closeDrawerReport}
        type={ReportsTypes.SAMPLING_IMPORT}
        icon={<SvgPinSamplePoints />}
      />

      <STReportTrapWarehouse
        visible={reportToOpen === ReportsTypes.TRAP || reportToOpen === ReportsTypes.WAREHOUSE_INVENTORY}
        callbackDrawer={closeDrawerReport}
        type={reportToOpen}
        icon={reportToOpen === ReportsTypes.TRAP ? <SvgStaticPointReport fill='#D9800A' /> : <SvgWarehouseReport fill='#1A7AA3' />}
      />

      <STReportScoutingDrawer visible={reportToOpen} callbackDrawer={closeDrawerReport} icon={<SvgSample fill='#f74141' />} />
      <Suspense>
        {reportToOpen === ReportsTypes.PROPERTY && (
          <STReportPropertyDrawer visible={reportToOpen} callbackDrawer={closeDrawerReport} icon={<SvgReport fill='#198746' />} />
        )}
      </Suspense>

      <STTrackingExportDrawer visible={reportToOpen === ReportsTypes.TRACKING} callbackDrawer={closeDrawerReport} icon={<SvgMapExport />} />

      {(reportToOpen === ReportsTypes.MONITORING_DETAILED || reportToOpen === ReportsTypes.MONITORING_CONSOLIDATED) && (
        <STReportDetailedConsolidatedDrawer
          reportType={reportToOpen}
          callbackDrawer={closeDrawerReport}
          icon={reportToOpen === ReportsTypes.MONITORING_DETAILED ? <SvgReportDetailed /> : <SvgReportConsolidated />}
        />
      )}

      {reportToOpen === ReportsTypes.VISIT && (
        <STReportVisitDrawer visible={ReportsTypes.VISIT} callbackDrawer={closeDrawerReport} icon={<FieldMonitoring />} />
      )}

      {reportToOpen === ReportsTypes.DISEASE_RISK && (
        <ReportDiseaseRisk
          visible={reportToOpen === ReportsTypes.DISEASE_RISK}
          callbackDrawer={closeDrawerReport}
          icon={<SvgDiseaseRisk fill='#EE5B3A' />}
        />
      )}
      {reportToOpen === ReportsTypes.CONTROL_STRATEGIES && (
        <ReportControlStrategy
          visible={reportToOpen === ReportsTypes.CONTROL_STRATEGIES}
          callbackDrawer={closeDrawerReport}
          icon={<SvgTableChart fill='#0071CD' />}
        />
      )}

      {reportToOpen === ReportsTypes.BLOCKED_FIELDS && (
        <ReportBlockedFields
          visible={reportToOpen === ReportsTypes.BLOCKED_FIELDS}
          callbackDrawer={closeDrawerReport}
          icon={<SvgBlocked />}
        />
      )}
      <Drawer
        className='st-report-drawer'
        data-testid='st-report-drawer'
        placement='right'
        visible={visible}
        width={400}
        title={
          <div className='st-report-drawer'>
            <STTypo type={TypeOptions.H4}>{t('report.title.header')}</STTypo>
          </div>
        }
        onClose={closeDrawer}
        maskClosable>
        <div className='st-report-drawer__content' data-testid='report-drawer'>
          {showOnlyReportsForAllOrg && <AlertCardInfo description={t('report.msg_alert_report_in_the_property_call')} />}
          <ReportDrawerButtonList setReportToOpen={setReportToOpen} permissionFlags={permissionFlags} systemFlags={systemFlags} />
          {reportDrawerComponent()}
        </div>
      </Drawer>
    </>
  );
};

export default STReportDrawerRender;
