const SVGScoutingScoreGlyph = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' {...props}>
      <g clipPath='url(#a)'>
        <mask id='b' width='16' height='16' x='0' y='0' maskUnits='userSpaceOnUse'>
          <path fill='#fff' d='M16 0H0v16h16V0Z' />
        </mask>
        <g mask='url(#b)'>
          <path
            fill='#0071ce'
            fillRule='evenodd'
            d='M12.667 3.333h-1.334V2H4.667v1.333H3.333C2.6 3.333 2 3.933 2 4.667v.666c0 1.7 1.28 3.087 2.927 3.294A3.34 3.34 0 0 0 7.333 10.6v2.067H4.667V14h4.666v-1.333h-.666V10.6a3.34 3.34 0 0 0 2.406-1.973C12.72 8.42 14 7.033 14 5.333v-.666c0-.734-.6-1.334-1.333-1.334Zm-9.334 2v-.666h1.334v2.546a2.007 2.007 0 0 1-1.334-1.88Zm4.667 4c-1.1 0-2-.9-2-2v-4h4v4c0 1.1-.9 2-2 2Zm4.667-4c0 .867-.56 1.6-1.334 1.88V4.667h1.334v.666Zm3.277 8.459-.659-.498c.012-.096.025-.192.025-.294 0-.102-.007-.198-.025-.294l.653-.498a.153.153 0 0 0 .037-.192l-.616-1.038c-.037-.066-.117-.09-.19-.066l-.764.3a2.122 2.122 0 0 0-.524-.294l-.117-.792c-.006-.072-.074-.126-.147-.126h-1.232a.157.157 0 0 0-.154.126l-.117.792a2.478 2.478 0 0 0-.523.294l-.764-.3a.163.163 0 0 0-.19.066l-.616 1.038a.146.146 0 0 0 .037.192l.652.498a2.307 2.307 0 0 0 0 .588l-.652.498a.153.153 0 0 0-.037.192l.615 1.038c.037.066.118.09.191.066l.764-.3c.16.12.332.222.523.294l.117.792a.152.152 0 0 0 .154.126h1.232a.157.157 0 0 0 .153-.126l.117-.792a2.29 2.29 0 0 0 .518-.294l.77.3c.067.024.147 0 .19-.066l.616-1.038a.153.153 0 0 0-.037-.192ZM13 14c-.553 0-1-.447-1-1 0-.553.447-1 1-1 .553 0 1 .447 1 1 0 .553-.447 1-1 1Z'
            clipRule='evenodd'
          />
        </g>
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SVGScoutingScoreGlyph;
