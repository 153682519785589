export const RainReportSvg = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='7 7 18 18' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 12.1762C10.0048 12.6828 10.389 13 10.75 13C11.111 13 11.4952 12.6828 11.5 12.1762C11.4994 12.1708 11.4978 12.1556 11.4932 12.13C11.4852 12.0847 11.4713 12.025 11.4504 11.9561C11.4062 11.8105 11.3487 11.682 11.3016 11.6033C11.1517 11.3526 10.9571 11.0781 10.75 10.8086C10.5429 11.0781 10.3483 11.3526 10.1984 11.6033C10.1513 11.682 10.0938 11.8105 10.0496 11.9561C10.0287 12.025 10.0148 12.0847 10.0068 12.13C10.0022 12.1556 10.0006 12.1708 10 12.1762ZM11.7387 9.63909C12.0378 10.0123 12.35 10.4338 12.589 10.8333C12.8325 11.2406 13 11.8151 13 12.1667C13 13.4553 11.9926 14.5 10.75 14.5C9.50736 14.5 8.5 13.4553 8.5 12.1667C8.5 11.8151 8.66747 11.2406 8.91103 10.8333C9.14998 10.4338 9.46215 10.0123 9.76128 9.63909C10.2749 8.99833 10.75 8.5 10.75 8.5C10.75 8.5 11.2251 8.99833 11.7387 9.63909Z'
        fill='#0071CD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.5 16C19.7648 16 19 15.3316 19 14.2917C19 14.244 19.0179 14.0765 19.0913 13.8268C19.1611 13.5894 19.255 13.3671 19.3456 13.2109C19.6529 12.681 20.0838 12.0913 20.5 11.5662C20.9162 12.0913 21.3471 12.681 21.6544 13.2109C21.745 13.3671 21.8389 13.5894 21.9087 13.8268C21.9821 14.0765 22 14.244 22 14.2917C22 15.3316 21.2352 16 20.5 16ZM21.485 10.3969C21.9726 10.9974 22.5428 11.7528 22.952 12.4583C23.2767 13.0183 23.5 13.8083 23.5 14.2917C23.5 16.0636 22.1569 17.5 20.5 17.5C18.8431 17.5 17.5 16.0636 17.5 14.2917C17.5 13.8083 17.7233 13.0183 18.048 12.4583C18.4572 11.7528 19.0274 10.9974 19.515 10.3969C20.0589 9.72706 20.5 9.25 20.5 9.25C20.5 9.25 20.9411 9.72706 21.485 10.3969Z'
        fill='#0071CD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5 22C13.3751 22 12.25 20.9662 12.25 19.4167C12.25 19.1059 12.4376 18.3539 12.7384 17.8257C13.2089 16.9999 13.8949 16.0762 14.5 15.3218C15.1051 16.0762 15.7911 16.9999 16.2615 17.8257C16.5624 18.3539 16.75 19.1059 16.75 19.4167C16.75 20.9662 15.6249 22 14.5 22ZM15.4828 14.1518C16.1377 14.9542 16.9821 16.06 17.5649 17.0833C17.9709 17.796 18.25 18.8014 18.25 19.4167C18.25 21.6718 16.5711 23.5 14.5 23.5C12.4289 23.5 10.75 21.6718 10.75 19.4167C10.75 18.8014 11.0291 17.796 11.4351 17.0833C12.0179 16.06 12.8623 14.9542 13.5172 14.1518C14.0781 13.4647 14.5 13 14.5 13C14.5 13 14.9219 13.4647 15.4828 14.1518Z'
        fill='#0071CD'
      />
    </svg>
  );
};
