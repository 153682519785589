const SvgWeatherCloudySnow = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M4.69772 19.4568C4.40754 19.166 3.93643 19.166 3.64623 19.4568C3.35662 19.7469 3.35684 20.2168 3.64673 20.5067L4.09799 20.958L3.64699 21.409C3.35713 21.6989 3.35735 22.1689 3.64749 22.4585C3.93725 22.7477 4.40652 22.7475 4.69599 22.458L5.14699 22.007L5.59899 22.4585C5.88856 22.7478 6.35765 22.748 6.64747 22.459C6.9381 22.1692 6.93831 21.6986 6.64796 21.4085L6.19699 20.958L6.64823 20.5072C6.9386 20.2172 6.93883 19.7466 6.64874 19.4563C6.35846 19.1657 5.88753 19.1657 5.59726 19.4563L5.14699 19.907L4.69772 19.4568Z'
      fill='#92B0ED'
    />
    <path
      d='M11.2887 19.4568C10.9985 19.166 10.5274 19.1659 10.237 19.4565C9.94701 19.7468 9.94712 20.2171 10.2372 20.5072L10.688 20.958L10.2375 21.4085C9.9474 21.6986 9.94774 22.1691 10.2383 22.4587C10.5282 22.7479 10.9975 22.7475 11.287 22.458L11.738 22.007L12.1893 22.4583C12.4787 22.7477 12.9478 22.7481 13.2377 22.4593C13.5287 22.1694 13.5289 21.6984 13.2382 21.4083L12.787 20.958L13.2385 20.5075C13.5292 20.2173 13.5294 19.7464 13.239 19.456C12.9486 19.1656 12.4777 19.1658 12.1875 19.4565L11.738 19.907L11.2887 19.4568Z'
      fill='#92B0ED'
    />
    <path
      d='M16.504 9.707L15.5 8C16 8 16.836 7.151 16.836 6.465C16.836 5.53 16.073 4.762 15.136 4.762C14.3 4.762 13.576 5.386 13.454 6.209C13.44 6.294 13.433 6.38 13.433 6.465H11.615C11.615 6.289 11.629 6.11 11.654 5.939C11.912 4.232 13.409 2.948 15.136 2.948C17.074 2.948 18.654 4.526 18.654 6.466C18.654 7.882 17.811 9.157 16.504 9.707Z'
      fill='#DFA701'
    />
    <path
      d='M12.3447 4.21597L11.3775 3.83642C10.9099 3.65293 10.3821 3.88324 10.1986 4.35083C10.0151 4.81843 10.2454 5.34624 10.713 5.52973L11.6802 5.90927C12.1478 6.09277 12.6756 5.86246 12.8591 5.39486C13.0426 4.92727 12.8123 4.39946 12.3447 4.21597Z'
      fill='#DFA701'
    />
    <path
      d='M14.5736 2.88792L14.1218 1.95341C13.9032 1.50144 13.3597 1.3122 12.9077 1.53073C12.4557 1.74926 12.2665 2.29281 12.485 2.74479L12.9369 3.6793C13.1554 4.13127 13.699 4.32052 14.1509 4.10198C14.6029 3.88345 14.7922 3.3399 14.5736 2.88792Z'
      fill='#DFA701'
    />
    <path
      d='M17.1235 3.44113L17.3968 2.58787C17.55 2.10979 17.2866 1.59806 16.8085 1.44489C16.3304 1.29172 15.8187 1.55512 15.6656 2.0332L15.3922 2.88646C15.239 3.36454 15.5024 3.87627 15.9805 4.02943C16.4586 4.1826 16.9703 3.91921 17.1235 3.44113Z'
      fill='#DFA701'
    />
    <path
      d='M18.6115 5.53568L19.5086 5.00965C19.9409 4.75614 20.0859 4.20016 19.8324 3.76783C19.5789 3.33549 19.0229 3.19052 18.5906 3.44403L17.6935 3.97006C17.2611 4.22357 17.1162 4.77955 17.3697 5.21188C17.6232 5.64422 18.1792 5.78919 18.6115 5.53568Z'
      fill='#DFA701'
    />
    <path
      d='M19.687 6.55609L18.6729 6.33479C18.1824 6.22776 17.698 6.5386 17.591 7.02908C17.4839 7.51956 17.7948 8.00394 18.2853 8.11098L19.2994 8.33228C19.7899 8.43931 20.2742 8.12846 20.3813 7.63798C20.4883 7.1475 20.1775 6.66312 19.687 6.55609Z'
      fill='#DFA701'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.025 8.48801C15.048 6.79701 13.243 5.73401 11.254 5.73401C8.499 5.73401 6.208 7.77301 5.811 10.42C4.2 10.809 3 12.264 3 13.99C3 16.016 4.649 17.666 6.674 17.666L15.838 17.668C18.368 17.668 20.428 15.607 20.428 13.077C20.427 10.606 18.468 8.58701 16.025 8.48801ZM15.838 15.814H6.676C5.67 15.814 4.851 14.996 4.851 13.99C4.851 12.986 5.671 12.166 6.676 12.166H7.6V11.243C7.6 9.229 9.241 7.586 11.256 7.586C12.725 7.586 14.043 8.457 14.621 9.808L14.889 10.434L15.838 10.333C17.349 10.333 18.578 11.564 18.578 13.076C18.578 14.586 17.349 15.814 15.838 15.814Z'
      fill='#828B99'
    />
    <path
      d='M17.8795 19.4571C17.589 19.1662 17.1175 19.1667 16.8274 19.4581C16.539 19.7478 16.539 20.2162 16.8274 20.506L17.277 20.958L16.8276 21.4097C16.5393 21.6995 16.5398 22.168 16.8287 22.4572C17.1183 22.747 17.588 22.7473 17.8778 22.4577L18.329 22.007L18.7805 22.458C19.0702 22.7475 19.5398 22.7475 19.8295 22.458C20.1193 22.1685 20.1197 21.699 19.8306 21.409L19.381 20.958L19.8308 20.5068C20.12 20.2167 20.12 19.7473 19.8308 19.4573C19.5406 19.1662 19.0692 19.166 18.7787 19.4568L18.329 19.907L17.8795 19.4571Z'
      fill='#92B0ED'
    />
  </svg>
);

export default SvgWeatherCloudySnow;
