import { Tooltip } from 'antd';
import { COLOR_OPTIONS } from 'config/style';
import type { Dictionary } from 'config/types';
import type { UUID } from 'core/utils/basic.models';
import { compareStrings } from 'core/utils/strings';
import { isActive } from 'entities/season/season.functions';
import type { Season } from 'entities/season/season.models';
import { getSeasonsGroupedByYear, selectYearAndCropFromGroup } from 'entities/season/season.reducer';
import type React from 'react';
import { connect } from 'react-redux';
import type { AppState } from 'redux/app-state';
import STCropIcon from '../crop-icon';
import STTypo from '../typo';
import './season-list.styles.less';

interface ISTSeasonListProps {
  seasons?: Season[];
  currentSelected?: UUID[];
  seasonsGroups: Dictionary<Season[]>;
}

const STSeasonList: React.FC<ISTSeasonListProps> = props => {
  const { seasons, currentSelected, seasonsGroups } = props;
  const ordernedSelectedSeasonsYearAndCrop = Object.keys(selectYearAndCropFromGroup(seasonsGroups)).sort(compareStrings).reverse();
  let cropAndYears;

  if (currentSelected && seasons && seasonsGroups) {
    cropAndYears = currentSelected.map(currentSelectedSeason => {
      const actualSeason = seasons.find(season => currentSelectedSeason === season.id);
      let seasonActive = true;
      let year;

      if (actualSeason) {
        seasonActive = isActive(actualSeason);
        ordernedSelectedSeasonsYearAndCrop.forEach(group => {
          if (seasonsGroups[group].filter(item => item.id === actualSeason.id).length) {
            year = group;
          }
        });
        return { crop: actualSeason.crop, seasonActive, year };
      }

      return {};
    });
  }

  if (cropAndYears) {
    cropAndYears = [...cropAndYears].sort((a, b) => (a.year < b.year ? 1 : -1));
  }

  return (
    <div className='st-season-list'>
      {cropAndYears?.slice(0, 2).map((cropAndYear, idx) => {
        return (
          <div
            data-testid='season-list-item'
            className={`st-season-list__item ${cropAndYear.seasonActive ? 'st-season-list__item_active' : ''}`}
            key={idx}>
            {cropAndYear.crop && (
              <>
                <Tooltip title={cropAndYear.crop.name}>
                  <STCropIcon crop={cropAndYear.crop} background={false} />
                </Tooltip>
                <STTypo nowrap>{cropAndYear.year}</STTypo>
              </>
            )}
          </div>
        );
      })}
      {cropAndYears && cropAndYears.length > 2 && (
        <STTypo nowrap color={COLOR_OPTIONS.SUCCESS}>
          +{cropAndYears.length - 2}
        </STTypo>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  seasonsGroups: getSeasonsGroupedByYear(state)
});

export default connect(mapStateToProps)(STSeasonList);
