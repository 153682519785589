import type { CurrentSeasonArea } from 'entities/property/property.models';

export enum FieldClusterQueryType {
  GET_ALL_FIELDS_CLUSTERS = 'GET_ALL_FIELDS_CLUSTERS',
  GET_PHENOMENA_BY_CLUSTERS = 'GET_PHENOMENA_BY_CLUSTERS',
  GET_CLUSTERS_BY_INSPECTION_GROUP = 'GET_CLUSTERS_BY_INSPECTION_GROUP'
}

export interface FieldClusterProps {
  id: string;
  name: string;
  season_id: string;
  property_id: string;
  areas_ids: string[];
  phenomenon_ids: string[];
  season_area_ids: string[];
}

export interface ClusterInfo {
  clusterColor: string;
  clusterId: string;
  clusterNumber: number;
}
export type FieldClusterInfo = Record<string, ClusterInfo>;

export interface FieldClusterInfoResult {
  allFieldClusters: FieldClusterProps[];
  fieldClusterInfo: FieldClusterInfo;
}

export interface ValidateFieldsClusteringReturnProps {
  areaIsInsideACluster: FieldClusterProps | undefined;
}

export interface ValidateAreaProps {
  phenomenaIds: string[];
  selectedSeasonArea: CurrentSeasonArea;
  fieldClusters: FieldClusterProps[];
  currentAreaSeasons: CurrentSeasonArea[];
}

export interface GenerateRegionTreeDictProps {
  name: string;
  parent_id?: string;
}

export interface BatchDeleteInterface {
  clusterIds: string[];
}
