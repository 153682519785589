import type React from 'react';
import type { SVGProps } from 'react';

interface SvgSprayProps extends SVGProps<SVGSVGElement> {
  size?: number | string;
}
const SvgSpray: React.VFC<SvgSprayProps> = props => (
  <svg width={props.size || 24} height={props.size || 24} viewBox='7 6 18 18' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.875 16H17.125L18.1578 18.735C18.3431 19.2256 17.9806 19.75 17.4562 19.75H14.5446C14.0203 19.75 13.6578 19.2258 13.8429 18.7352L14.875 16ZM15.625 17.5L15.25 18.25H16.75L16.375 17.5H15.625Z'
      fill={props.fill || '#0071CD'}
    />
    <path fillRule='evenodd' clipRule='evenodd' d='M15.25 24.25L15.25 21.25H16.75V24.25H15.25Z' fill={props.fill || '#0071CD'} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.874 22.7881L17.626 20.916L18.874 20.084L20.1221 21.9561L18.874 22.7881Z'
      fill={props.fill || '#0071CD'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.8779 21.9561L13.126 20.084L14.374 20.916L13.126 22.7881L11.8779 21.9561Z'
      fill={props.fill || '#0071CD'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.892 9.68659C10.5367 9.19027 10.8914 8.5 11.5018 8.5H20.498C21.1084 8.5 21.4631 9.19025 21.1078 9.68658L17.125 15.25H14.875L10.892 9.68659ZM12.9573 10L15.6358 13.75H16.3639L19.0425 10H12.9573Z'
      fill={props.fill || '#0071CD'}
    />
  </svg>
);

export default SvgSpray;
