import { isActive } from 'entities/season/season.functions';

export const sortedSeasonsByYear = (sortedGroupKeys, seasonsGroups) => {
  let tempActiveSeasonsGroups;
  let tempInactiveSeasonsGroups;
  if (sortedGroupKeys) {
    tempActiveSeasonsGroups = sortedGroupKeys.map(y => {
      let verifyYear;
      if (seasonsGroups[y]) {
        verifyYear = seasonsGroups[y].find(season => isActive(season));
      }
      if (verifyYear) {
        return y;
      }
      return '';
    });

    tempInactiveSeasonsGroups = sortedGroupKeys.map(y => {
      let verifyYear;
      if (seasonsGroups[y]) {
        verifyYear = seasonsGroups[y].find(season => !isActive(season));
      }
      if (verifyYear) {
        return y;
      }
      return '';
    });
  }

  tempActiveSeasonsGroups = tempActiveSeasonsGroups.filter(item => item.length > 0);
  tempInactiveSeasonsGroups = tempInactiveSeasonsGroups.filter(item => item.length > 0);
  return [tempActiveSeasonsGroups, tempInactiveSeasonsGroups];
};
