import { Button } from 'antd';
import { images } from 'assets';
import STTypo, { STTypoModels } from 'core/shared/typo';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import './alert.styles.less';
import type { OpenDrawer } from './alerts.types';

interface AlertEmptyProps {
  openDrawer?: React.Dispatch<React.SetStateAction<OpenDrawer>>;
}

const STAlertEmptyState: React.FC<AlertEmptyProps> = ({ openDrawer }) => {
  const [t] = useTranslation();

  const openEdit = () => {
    if (openDrawer) openDrawer({ open: true });
  };

  return (
    <div className='st-alert-content st-alert-state__empty'>
      <div className='st-alert-content__icon'>
        <img src={images.widget_empty_state} alt='strider widget empty' />
      </div>
      <div className='st-alert-content__message'>
        <STTypo type={STTypoModels.TypeOptions.H5} fontWeight={500}>
          {t('alert.empty.no_alerts')}
        </STTypo>
        <STTypo type={STTypoModels.TypeOptions.P1}>{t('alert.empty.new_alert')}</STTypo>
      </div>
      <div className='st-alert-content__action'>
        <Button className='st-alert-content__button' type='primary' onClick={openEdit}>
          <STTypo data-testid='create-new-alert-empty' type={STTypoModels.TypeOptions.P1}>
            {t('alert.empty.button_create')}
          </STTypo>
        </Button>
      </div>
    </div>
  );
};

export default STAlertEmptyState;
