import type { ColumnErrorInterface, LineErrorInterface, LineErrorPayloadInterface } from '../../report-property.models.ts';
import { TypeColumnSamplingError } from '../../report-property.models.ts';
import type { TFunction } from 'i18next';
import { useState } from 'react';

export const useSetImportSamplingError = () => {
  const [errorsList, setErrorsList] = useState<(LineErrorInterface | ColumnErrorInterface)[]>([]);

  const handleSetErrors = (err: LineErrorPayloadInterface) => {
    const errorsByLine =
      err?.response?.data?.line_errors?.map(error => {
        return {
          lineNumber: error?.line,
          errorType: error?.error_type
        };
      }) ?? [];

    const errorsByColumn =
      err?.response?.data?.column_errors?.map(error => {
        return {
          columnNumber: error?.column,
          errorType: error?.error_type
        };
      }) ?? [];

    setErrorsList([...errorsByLine, ...errorsByColumn]);
  };

  const handleClearErrors = () => {
    setErrorsList([]);
  };

  return { handleSetErrors, handleClearErrors, errorsList };
};

export const handleSelectColumnErrorText = (error: ColumnErrorInterface, t: TFunction<'translation', undefined>): string => {
  const errorTypeString = `report.drawer.columns.${error.errorType.toLowerCase()}.column_`;

  if (error.errorType === TypeColumnSamplingError.HEADER_OUT_OF_PATTERN) {
    const column = error.columnNumber <= 6 ? error.columnNumber.toString() : 'optional';
    return t(errorTypeString + column);
  }

  if (error.errorType === TypeColumnSamplingError.CONTENT_OUT_OF_PATTERN) {
    const column = error.columnNumber <= 6 ? error.columnNumber.toString() : 'optional';
    return t(errorTypeString + column, { column_number: error.columnNumber });
  }

  if (error.errorType === TypeColumnSamplingError.MISSING_COLUMNS) {
    return t('report.drawer.columns.missing_columns');
  }

  return t('report.drawer.columns.error_default', { column_number: error.columnNumber });
};
