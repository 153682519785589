import convert from 'convert-units';
import { UnitSystem, formatNumber, getCurrentUnitSystem } from 'core/utils/functions';
import { mapboxgl } from 'core/utils/map/mapbox-gl';
import { AreaVariableType } from 'entities/region/enums/areaVariablesTypes.enum';
import type { AreaVariable } from 'entities/region/region.models';
import moment from 'moment';
import type { Dictionary } from 'config/types';
import type { UUID } from '../../core/utils/basic.models';
import type { AnalyticContext } from '../fixed-points/fixed-points.models';

export const onClusterClick = (e, mapbox, mapMarkers, enableMultiplePhotos, isGalleryV2, productsAvailable, segmentTracking, fieldName) => {
  if (!mapbox) return;
  const features = mapbox.queryRenderedFeatures(e.point, { layers: [`clusters`] });
  if (!features.length || !features[0].properties) return;
  const clusterId = features[0].properties.cluster_id;
  const { point_count } = features[0].properties;
  const clusterSource = mapbox.getSource('points');
  // Get all points under a cluster
  clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {
    if (err) throw err;
    const popupCluster = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true,
      offset: 24
    })
      .setLngLat(e.lngLat)
      .setHTML('</ div>')
      .on('open', data => {
        const objectData = data as any;
        mapbox.fire('close.popups');
        mapbox.fire('load.popupCluster', {
          data: mapMarkers.filter(mapPoint => aFeatures.find(cluster => cluster.properties.id === mapPoint.id)),
          popup: objectData.target,
          enableMultiplePhotos,
          isGalleryV2,
          productsAvailable,
          segmentTracking,
          fieldName
        });
        mapbox.flyTo({
          center: e.lngLat
        });
        mapbox.on('close.popups', () => popupCluster.remove());
      })
      .addTo(mapbox);
  });
};

export const instanceClusterSourceOnMap = (
  icons,
  mapbox,
  mapMarkers,
  pointHightLight: string | undefined = undefined,
  activeSeverityPoint = false,
  severityPoints: Dictionary<any> | undefined = undefined
) => {
  let filteredMarkers = mapMarkers;
  if (activeSeverityPoint && severityPoints) {
    const idsPoints = Object.keys(severityPoints);
    filteredMarkers = mapMarkers.filter(item => !(!idsPoints.includes(item.id) && item.markerProps.type === 'MONITORING'));
  }
  mapbox?.getSource('points')?.setData({
    type: 'FeatureCollection',
    features: filteredMarkers.map(marker => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [marker.markerProps.position[0], marker.markerProps.position[1]]
        },
        properties: {
          id: marker.id,
          icon: icons(marker),
          opacity: pointHightLight && marker.id !== pointHightLight ? 0.3 : 1,
          ...marker.markerProps
        }
      };
    })
  });
};

export const getThresholdsFromAnalyticContextAndIndicatorId = (analyticContext: AnalyticContext, indicatorId: UUID) => {
  const customIndicator = analyticContext?.custom_indicator_dtos?.find(ci => ci.indicator_id === indicatorId);
  return (customIndicator?.diagnostics?.length && customIndicator.diagnostics[0].thresholds) || [];
};

export const formatMetricSystem = (value: number): string => {
  const unitSystem = getCurrentUnitSystem();
  if (unitSystem === UnitSystem.IMPERIAL) {
    return `${formatNumber(convert(value).from('ha').to('ac'))} ac`;
  }
  return `${formatNumber(value)} ha`;
};

export const formatAreaVariableValue = (item: AreaVariable, t: i18next.TFunction) => {
  switch (item.class_name) {
    case AreaVariableType.AREA:
      return formatNumber(item.value, 2, 3);
    case AreaVariableType.AREA_DATE:
      return t('units.date', { date: moment(item.value) });
    default:
      return item.value;
  }
};
