import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { AssigneeActionsTypes } from './assignee.actions';
import type { Assignee, AssigneeState } from './assignee.models';
import { initialPageV1 } from './assignee.models';

function selectAssigneeUuid(assignee: Assignee): string {
  return assignee.id;
}

function sortByName(a: Assignee, b: Assignee): number {
  return a.name.localeCompare(b.name);
}

const entity: EntityAdapter<Assignee> = createEntityAdapter<Assignee>({
  selectId: selectAssigneeUuid,
  sortComparer: sortByName
});

const initialState = entity.getInitialState<AssigneeState>({
  ids: [],
  entities: {},
  page: {
    ...initialPageV1
  },
  isLoading: false,
  error: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case AssigneeActionsTypes.LOAD_ASSIGNEES:
      return {
        ...state,
        isLoading: true
      };
    case AssigneeActionsTypes.LOAD_ASSIGNEES_SUCCESS:
      return entity.addAll(action.payload.content, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });
    case AssigneeActionsTypes.LOAD_ASSIGNEES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const { selectAll } = entity.getSelectors();
export const selectAllAssignees = selectAll;
