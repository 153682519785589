import { Tooltip } from 'antd';
import type { InputNumberProps } from 'antd/lib/input-number';
import { COLOR_OPTIONS } from 'config/style';
import STTypo from 'core/shared/typo';
import type React from 'react';
import type { ReactNode } from 'react';
import SvgInfoOutlineGlyph from 'st-protector-icons/Glyph/InfoOutlineGlyph';
import { Container, InputNumber, Label, NumericAddon, NumericInputGroup } from './numeric-input.styles';

export interface NumericInputProps {
  title?: string;
  maxTitleLength?: number;
  description?: string;
  defaultValue?: number;
  addAfter?: ReactNode;
  customAddAfter?: ReactNode;
  maxAddAfterLength?: number;
  addBefore?: ReactNode;
  maxAddBeforeLength?: number;
  max?: number;
  min?: number;
  onChange: (value: number | undefined) => void;
  precision?: number;
  size?: 'large' | 'default' | 'small';
  labelColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  isRequired?: boolean;
  addonAfterProps?: any;
  addAfterBackgroundColor?: string;
  hoveredBorderColor?: string;
  placeholder?: string;
}

const NumericInput: React.FC<NumericInputProps & InputNumberProps> = ({
  title,
  maxTitleLength = 25,
  description,
  precision,
  defaultValue,
  max,
  min,
  addAfter,
  customAddAfter,
  maxAddAfterLength = 5,
  addBefore,
  maxAddBeforeLength = 5,
  size = 'large',
  onChange,
  labelColor,
  isRequired,
  backgroundColor,
  borderColor,
  addonAfterProps,
  addAfterBackgroundColor,
  hoveredBorderColor,
  placeholder,
  ...props
}) => {
  return (
    <Container data-testid='numeric-input'>
      <Label isRequired={!!isRequired}>
        <STTypo maxlength={maxTitleLength} data-testid='numeric-input-title' color={labelColor}>
          {title}
        </STTypo>
        {isRequired && <STTypo color={COLOR_OPTIONS.DANGER}>*</STTypo>}
        {description && (
          <Tooltip title={description}>
            <SvgInfoOutlineGlyph fill={labelColor} data-testid='numeric-input-description-icon' />
          </Tooltip>
        )}
      </Label>
      <NumericInputGroup hasPreffix={!!addBefore} hasSuffix={!!addAfter || !!customAddAfter}>
        {addBefore && (
          <NumericAddon radiusLeft>
            <STTypo maxlength={maxAddBeforeLength}>{addBefore}</STTypo>
          </NumericAddon>
        )}
        <InputNumber
          data-testid='numeric-input-component'
          hoveredBorderColor={hoveredBorderColor}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          defaultValue={defaultValue}
          max={max}
          min={min}
          precision={precision}
          size={size}
          onChange={onChange}
          placeholder={placeholder}
          {...props}
        />
        {addAfter && !customAddAfter && (
          <NumericAddon radiusRight {...addonAfterProps} addAfterBackgroundColor={addAfterBackgroundColor}>
            <STTypo maxlength={maxAddAfterLength}>{addAfter}</STTypo>
          </NumericAddon>
        )}
        {customAddAfter}
      </NumericInputGroup>
    </Container>
  );
};

export default NumericInput;
