const SvgWarehouseReportCard = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <g clipPath='url(#clip0_11107_102423)'>
      <rect width='32' height='32' rx='8' fill='white' />
      <rect width='32' height='32' rx='4' fill='#F6F2FE' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.75 15.55L16 9.25L24.25 15.55L23.3425 16.735L22 15.715V22.75H10V15.715L8.6575 16.7425L7.75 15.55ZM20.5 21.25H19.75V18.25H18.25V15.25H13.75V18.25H12.25V21.25H11.5V14.575L16 11.14L20.5 14.575V21.25ZM13.75 19.75V21.25H15.25V19.75H13.75ZM16.75 19.75V21.25H18.25V19.75H16.75ZM16.75 18.25V16.75H15.25V18.25H16.75Z'
        fill='#8354D6'
      />
    </g>
    <defs>
      <clipPath id='clip0_11107_102423'>
        <rect width='32' height='32' rx='8' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SvgWarehouseReportCard;
