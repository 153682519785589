import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { IntegrationsActionsTypes } from './integrations.actions';
import type { IntegrationsState } from './integrations.models';

const entity: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState = entity.getInitialState<IntegrationsState>({
  ids: [],
  entities: {},
  content: [],
  isLoading: false,
  error: null,
  perfectFlight: {
    isLoading: false,
    areasDiff: [],
    error: null,
    areasLoaded: false,
    propertyList: [],
    authenticated: false,
    applications: [],
    applicationsLoaded: false
  },
  jdoc: {
    checkingAuth: false,
    authentication: null,
    error: null,
    urlAuth: null,
    gettingUrl: false,
    loadPermissions: false,
    permissions: null,
    postingPermissions: false,
    revokingAccess: false,
    loadingOrganizations: null,
    organizations: [],
    loadingFarms: false,
    farms: [],
    loadingAreasDiff: false,
    areasDiff: [],
    postingAreasDiff: false,
    updateDiff: null,
    loadedOrganizations: false,
    gettingUpdateDiff: false
  },
  trapView: {
    devices: [],
    isLoading: true,
    authenticated: false,
    username: null,
    token: null,
    error: null,
    validated: false,
    binding: [],
    account_id: null
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case IntegrationsActionsTypes.GET_AUTHENTICATE_PERFECT_FLIGHT:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.GET_AUTHENTICATE_PERFECT_FLIGHT_SUCCESS:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: false,
          authenticated: action.payload
        }
      };
    case IntegrationsActionsTypes.INTEGRATE_PERFECT_FLIGHT:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.INTEGRATE_PERFECT_FLIGHT_SUCCESS:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: false,
          authenticated: true
        }
      };
    case IntegrationsActionsTypes.LOAD_PERFECT_FLIGHT_PROPERTY_LIST_FAILURE:
    case IntegrationsActionsTypes.INTEGRATE_PERFECT_FLIGHT_FAILURE:
      return {
        ...state,
        error: action.payload,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: false
        }
      };
    case IntegrationsActionsTypes.LOAD_PERFECT_FLIGHT_PROPERTY_LIST:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.LOAD_PERFECT_FLIGHT_PROPERTY_LIST_SUCCESS:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          propertyList: action.payload,
          isLoading: false
        }
      };
    case IntegrationsActionsTypes.GET_AUTHENTICATE_PERFECT_FLIGHT_FAILURE:
      return {
        ...state,
        error: action.payload,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: false
        }
      };
    case IntegrationsActionsTypes.RESET_INTEGRATION_ERRORS:
      return {
        ...state,
        error: null,
        perfectFlight: {
          ...state.perfectFlight
        },
        trapView: {
          ...state.trapView,
          error: null
        }
      };
    case IntegrationsActionsTypes.GET_AUTHENTICATE_JDOC:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          checkingAuth: true,
          authentication: null,
          loadPermissions: false,
          permissions: null
        }
      };

    case IntegrationsActionsTypes.GET_AUTHENTICATE_JDOC_SUCCESS:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          checkingAuth: false,
          authentication: action.payload
        }
      };

    case IntegrationsActionsTypes.GET_AUTHENTICATE_JDOC_FAILURE:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: action.payload,
          checkingAuth: false
        }
      };

    case IntegrationsActionsTypes.AUTHENTICATE_JDOC:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          gettingUrl: true,
          urlAuth: null
        }
      };

    case IntegrationsActionsTypes.AUTHENTICATE_JDOC_SUCCESS:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          gettingUrl: false,
          urlAuth: action.payload
        }
      };

    case IntegrationsActionsTypes.AUTHENTICATE_JDOC_FAILURE:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: action.payload,
          gettingUrl: false
        }
      };

    case IntegrationsActionsTypes.REVOKE_PERFECT_FLIGHT:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.REVOKE_PERFECT_FLIGHT_SUCCESS:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: false,
          authenticated: false
        }
      };
    case IntegrationsActionsTypes.GET_PERMISSIONS_JDOC:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          loadPermissions: true,
          permissions: null
        }
      };

    case IntegrationsActionsTypes.GET_PERMISSIONS_JDOC_SUCCESS:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          loadPermissions: false,
          permissions: action.payload
        }
      };

    case IntegrationsActionsTypes.GET_PERMISSIONS_JDOC_FAILURE:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: action.payload,
          loadPermissions: false
        }
      };

    case IntegrationsActionsTypes.POST_PERMISSIONS_JDOC:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          postingPermissions: true
        }
      };

    case IntegrationsActionsTypes.POST_PERMISSIONS_JDOC_SUCCESS:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          postingPermissions: false
        }
      };

    case IntegrationsActionsTypes.POST_PERMISSIONS_JDOC_FAILURE:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: action.payload,
          postingPermissions: false
        }
      };

    case IntegrationsActionsTypes.REVOKE_ACCESS_JDOC:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          revokingAccess: true
        }
      };

    case IntegrationsActionsTypes.REVOKE_ACCESS_JDOC_SUCCESS:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          revokingAccess: false,
          authentication: null,
          urlAuth: null,
          permissions: null
        }
      };

    case IntegrationsActionsTypes.REVOKE_ACCESS_JDOC_FAILURE:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: action.payload,
          revokingAccess: false
        }
      };

    case IntegrationsActionsTypes.GET_ORGANIZATIONS_JDOC:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          loadingOrganizations: true,
          organizations: [],
          loadedOrganizations: false
        }
      };

    case IntegrationsActionsTypes.GET_ORGANIZATIONS_JDOC_SUCCESS:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          loadingOrganizations: false,
          organizations: action.payload,
          loadedOrganizations: true
        }
      };

    case IntegrationsActionsTypes.GET_ORGANIZATIONS_JDOC_FAILURE:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: action.payload,
          loadingOrganizations: false,
          loadedOrganizations: false
        }
      };

    case IntegrationsActionsTypes.GET_FARMS_BY_ORGANIZATIONS_JDOC:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          loadingFarms: true,
          farms: []
        }
      };

    case IntegrationsActionsTypes.GET_FARMS_BY_ORGANIZATIONS_JDOC_SUCCESS:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          loadingFarms: false,
          farms: action.payload
        }
      };

    case IntegrationsActionsTypes.GET_FARMS_BY_ORGANIZATIONS_JDOC_FAILURE:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: action.payload,
          loadingFarms: false
        }
      };

    case IntegrationsActionsTypes.GET_AREAS_DIFF_JDOC:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          loadingAreasDiff: true,
          areasDiff: []
        }
      };

    case IntegrationsActionsTypes.GET_AREAS_DIFF_JDOC_SUCCESS:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          loadingAreasDiff: false,
          areasDiff: action.payload
        }
      };

    case IntegrationsActionsTypes.GET_AREAS_DIFF_JDOC_FAILURE:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: action.payload,
          loadingAreasDiff: false
        }
      };

    case IntegrationsActionsTypes.POST_AREAS_DIFF_JDOC:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          postingAreasDiff: true
        }
      };

    case IntegrationsActionsTypes.POST_AREAS_DIFF_JDOC_SUCCESS:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: null,
          postingAreasDiff: false
        }
      };

    case IntegrationsActionsTypes.POST_AREAS_DIFF_JDOC_FAILURE:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: action.payload,
          postingAreasDiff: false
        }
      };

    case IntegrationsActionsTypes.GET_UPDATE_DIFF:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          updateDiff: null,
          gettingUpdateDiff: true,
          error: null
        }
      };

    case IntegrationsActionsTypes.GET_UPDATE_DIFF_SUCCESS:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          updateDiff: action.payload,
          gettingUpdateDiff: false
        }
      };

    case IntegrationsActionsTypes.GET_UPDATE_DIFF_FAILURE:
      return {
        ...state,
        jdoc: {
          ...state.jdoc,
          error: action.payload,
          gettingUpdateDiff: false
        }
      };

    case IntegrationsActionsTypes.GET_AREAS_DIFF_PERFECT_FLIGHT:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          areasLoaded: false,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.GET_AREAS_DIFF_PERFECT_FLIGHT_SUCCESS:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: false,
          areasLoaded: true,
          areasDiff: action.payload
        }
      };
    case IntegrationsActionsTypes.GET_AREAS_DIFF_PERFECT_FLIGHT_FAILURE:
      return {
        ...state,
        error: action.payload,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: false
        }
      };
    case IntegrationsActionsTypes.GET_PERFECT_FLIGHT_APPLICATIONS:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          applicationsLoaded: false,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.GET_PERFECT_FLIGHT_APPLICATIONS_SUCCESS:
      return {
        ...state,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: false,
          applicationsLoaded: true,
          applications: action.payload
        }
      };
    case IntegrationsActionsTypes.GET_PERFECT_FLIGHT_APPLICATIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
        perfectFlight: {
          ...state.perfectFlight,
          isLoading: false
        }
      };
    case IntegrationsActionsTypes.GET_AUTHENTICATE_TRAP_VIEW:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.GET_AUTHENTICATE_TRAP_VIEW_SUCCESS:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          username: action.payload.trapview_username,
          token: action.payload.trapview_token,
          validated: true,
          authenticated: true,
          isLoading: false
        }
      };
    case IntegrationsActionsTypes.GET_AUTHENTICATE_TRAP_VIEW_FAILURE:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          authenticated: false,
          validated: true,
          isLoading: false
        }
      };

    case IntegrationsActionsTypes.GET_TRAP_VIEW_ACCESS:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.GET_TRAP_VIEW_ACCESS_SUCCESS:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          username: action.payload.trapview_username,
          token: action.payload.trapview_token,
          validated: true,
          authenticated: !!action.payload.account_id,
          isLoading: false,
          account_id: action.payload.account_id
        }
      };
    case IntegrationsActionsTypes.GET_TRAP_VIEW_ACCESS_FAILURE:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          error: action.payload,
          authenticated: false,
          validated: true,
          isLoading: false
        }
      };

    case IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_EXTERNAL:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          username: action.payload.trapview_username,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_EXTERNAL_SUCCESS:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          username: action.payload.trapview_username,
          token: action.payload.trapview_token,
          authenticated: true,
          isLoading: false
        }
      };
    case IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_EXTERNAL_FAILURE:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          username: null,
          isLoading: false,
          error: action.payload
        }
      };
    case IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          username: action.payload.trapview_username,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_SUCCESS:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          authenticated: true,
          isLoading: false
        }
      };
    case IntegrationsActionsTypes.AUTHENTICATE_TRAP_VIEW_FAILURE:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          username: null,
          isLoading: false,
          error: action.payload
        }
      };
    case IntegrationsActionsTypes.REVOKE_TRAP_VIEW:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.REVOKE_TRAP_VIEW_SUCCESS:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          username: null,
          authenticated: false,
          isLoading: false
        }
      };
    case IntegrationsActionsTypes.REVOKE_TRAP_VIEW_FAILURE:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: false,
          error: action.payload
        }
      };
    case IntegrationsActionsTypes.GET_TRAP_VIEW_DEVICES:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.GET_TRAP_VIEW_DEVICES_SUCCESS:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: false,
          devices: action.payload
        }
      };
    case IntegrationsActionsTypes.GET_TRAP_VIEW_DEVICES_FAILURE:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: false,
          error: action.payload,
          devices: []
        }
      };
    case IntegrationsActionsTypes.GET_TRAP_VIEW_BINDING:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.GET_TRAP_VIEW_BINDING_SUCCESS:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: false,
          binding: action.payload
        }
      };
    case IntegrationsActionsTypes.GET_TRAP_VIEW_BINDING_FAILURE:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: false,
          error: action.payload,
          binding: []
        }
      };
    case IntegrationsActionsTypes.INSTALL_TRAP_VIEW:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: true
        }
      };
    case IntegrationsActionsTypes.INSTALL_TRAP_VIEW_SUCCESS:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: false
        }
      };
    case IntegrationsActionsTypes.INSTALL_TRAP_VIEW_FAILURE:
      return {
        ...state,
        trapView: {
          ...state.trapView,
          isLoading: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
};
