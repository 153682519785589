const SvgWeatherDailyFog = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M21.4 9.69922H21.5C21.5837 9.69922 21.7134 9.74468 21.8464 9.87777C21.9668 9.99809 22.06 10.1626 22.0986 10.3389C22.0772 10.6453 21.8105 10.8992 21.5 10.8992H21.4H2.7H2.6C2.27614 10.8992 2 10.6231 2 10.2992C2 9.97536 2.27614 9.69922 2.6 9.69922H2.7H21.4Z'
      fill='#C2C7D0'
      stroke='#C2C7D0'
      strokeLinecap='square'
    />
    <path
      d='M21.4 14.1016H21.5C21.5837 14.1016 21.7134 14.147 21.8464 14.2801C21.9668 14.4004 22.06 14.5649 22.0986 14.7413C22.0772 15.0477 21.8105 15.3016 21.5 15.3016H21.4H2.7H2.6C2.27614 15.3016 2 15.0254 2 14.7016C2 14.3777 2.27614 14.1016 2.6 14.1016H2.7H21.4Z'
      fill='#C2C7D0'
      stroke='#C2C7D0'
      strokeLinecap='square'
    />
  </svg>
);

export default SvgWeatherDailyFog;
