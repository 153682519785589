import type { User } from 'core/services/auth/auth.models';
import sentryService from 'sentry/service';
import { deburrLowerCase } from './strings';

export const validateUserAgainstAccessToken = (userFromMeEndpoint: User) => {
  const accessTokenData = window.atob(localStorage.getItem('access_token')?.split('.')[1] ?? '');
  const { sub: idFromAccessToken, user_name: userNameFromAccessToken } = JSON.parse(
    accessTokenData.length ? accessTokenData : '{}'
  ) as Partial<Record<'sub' | 'user_name', string>>;

  const { id, login } = userFromMeEndpoint;

  if (idFromAccessToken?.toLowerCase() !== id.toLowerCase() || deburrLowerCase(userNameFromAccessToken ?? '') !== deburrLowerCase(login)) {
    sentryService.captureException(new Error('STRANGE BEHAVIOR: User from /me does not match the user from the access token'), {
      fileName: 'core/utils/user.ts',
      method: 'validateUserAgainstAccessToken',
      idFromAccessToken,
      userNameFromAccessToken,
      idFromMeEndpoint: id,
      loginFromMeEndpoint: login
    });
  }
};
