import type { CustomLayerInterface, Layer, LngLatBoundsLike, MapboxGeoJSONFeature, PaddingOptions, RasterSource } from 'mapbox-gl';
import mapboxgl, { GeoJSONSource, Map, MapMouseEvent, MercatorCoordinate } from 'mapbox-gl';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker?worker';

if (process.env.NODE_ENV !== 'test') {
  // eslint-disable-next-line
  (mapboxgl as any).workerClass = MapboxWorker;
}

export { GeoJSONSource, Map, MapMouseEvent, MercatorCoordinate, mapboxgl };
export type { CustomLayerInterface, Layer, LngLatBoundsLike, MapboxGeoJSONFeature, PaddingOptions, RasterSource };
