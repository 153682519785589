import convert from 'convert-units';
import { formatNumber, getCurrentLanguage, getCurrentUnitSystem, UnitSystem } from 'core/utils/functions';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import type { Moment } from 'moment';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import sentryService from 'sentry/service';

const customDetector = {
  name: 'STLangDetector',
  lookup(): string {
    return getCurrentLanguage().toLowerCase();
  }
};

const langDetector = new LanguageDetector();
langDetector.addDetector(customDetector);

i18n.on('languageChanged', lng => {
  moment.locale(lng);
});

const verifyCurrentLanguages = (currentLanguage: string): boolean => {
  return (
    currentLanguage === 'en' ||
    currentLanguage === 'en-us' ||
    currentLanguage === 'de-de' ||
    currentLanguage === 'pl-pl' ||
    currentLanguage === 'hu-hu' ||
    currentLanguage === 'it'
  );
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(langDetector)
  .init({
    compatibilityJSON: 'v3',
    debug: false,
    react: {
      useSuspense: false
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: (value: string, format) => {
        if (format === 'date_simple') {
          const currentLanguage = getCurrentLanguage();
          if (verifyCurrentLanguages(currentLanguage)) {
            return moment(value).format('MM/DD');
          }
          return moment(value).format('DD/MM');
        }
        if (format === 'date_from_now') {
          const days = moment().startOf('day').diff(moment(value).startOf('day'), 'days');
          const currentLanguage = getCurrentLanguage();
          if (currentLanguage === 'pt-br') {
            if (moment().isAfter(moment(value))) return `Há ${days}${days === 1 ? ' dia' : ' dias'}`;
            return `Em ${days * -1}${days === -1 ? ' dia' : ' dias'}`;
          }
          if (moment().isAfter(moment(value))) return `${days} days ago`;
          return `In ${days * -1} days`;
        }
        if (format === 'convert_area' || format === 'convert_area_no_uom' || format?.match(/convert_area_\d+_decimal_places/)) {
          const unitSystem = getCurrentUnitSystem();
          const decimalPlaces = parseInt(format?.split('_')[2], 10) || 2;
          if (unitSystem === UnitSystem.IMPERIAL) {
            return (
              formatNumber(convert(value).from('ha').to('ac'), decimalPlaces, decimalPlaces) + (format === 'convert_area' ? ' ac' : '')
            );
          }
          return formatNumber(value, decimalPlaces, decimalPlaces) + (format === 'convert_area' ? ' ha' : '');
        }
        if (format === 'area_abreviation') {
          return getCurrentUnitSystem() === UnitSystem.METRIC ? 'ha' : 'ac';
        }
        if (format === 'convert_velocity' || format?.match(/convert_velocity_\d+_decimal_places/)) {
          const unitSystem = getCurrentUnitSystem();
          const decimalPlaces = parseInt(format?.split('_')[2], 10) || 2;
          if (unitSystem === UnitSystem.IMPERIAL) {
            return formatNumber(convert(value).from('km/h').to('m/h'), decimalPlaces, decimalPlaces);
          }
          return formatNumber(value, decimalPlaces, decimalPlaces);
        }
        if (format === 'convert_flow' || format?.match(/convert_flow_\d+_decimal_places/)) {
          const unitSystem = getCurrentUnitSystem();
          const decimalPlaces = parseInt(format?.split('_')[2], 10) || 2;
          if (unitSystem === UnitSystem.IMPERIAL) {
            return formatNumber(value / convert(1).from('ha').to('ac'), decimalPlaces, decimalPlaces);
          }
          return formatNumber(value, decimalPlaces, decimalPlaces);
        }
        if (format === 'velocity_abreviation') {
          return getCurrentUnitSystem() === UnitSystem.METRIC ? 'km/h' : 'mph';
        }
        if (format === 'velocity_simple_abreviation') {
          return getCurrentUnitSystem() === UnitSystem.METRIC ? 'km' : 'm';
        }
        if (format === 'convert_width') {
          const unitSystem = getCurrentUnitSystem();
          if (unitSystem === UnitSystem.IMPERIAL) {
            return formatNumber(convert(value).from('m').to('ft'));
          }
          return value;
        }
        if (format === 'meter_feet') {
          return getCurrentUnitSystem() === UnitSystem.METRIC ? 'm' : 'ft';
        }
        if (format === 'number') {
          const number = parseFloat(value);
          if (!isNaN(number)) {
            return number.toLocaleString(undefined, { maximumFractionDigits: 2 });
          }
        }
        if (format === 'capitalize') {
          if (typeof value === 'string') {
            return value.charAt(0).toUpperCase() + value.slice(1);
          }
        }
        if (format === 'volume') {
          const unitSystem = getCurrentUnitSystem();
          if (unitSystem === UnitSystem.IMPERIAL) {
            return `${formatNumber(convert(value).from('mm').to('in').toFixed(0))} in`;
          }
          return `${value.toFixed(0)} mm`;
        }
        if (format === 'full_date') {
          const currentLanguage = getCurrentLanguage();
          if (currentLanguage === 'en' || currentLanguage === 'en-us' || currentLanguage === 'hu-hu') {
            return moment(value).format('dddd, MMMM Do HH:mm');
          }
          return moment(value).format('dddd, DD [de] MMMM HH:mm');
        }
        if (format === 'day_month') {
          const currentLanguage = getCurrentLanguage();
          if (verifyCurrentLanguages(currentLanguage)) {
            return moment(value).format('MMMM DD');
          }

          return moment(value).format('DD MMMM');
        }
        if (format === 'day_month_year') {
          const currentLanguage = getCurrentLanguage();
          if (verifyCurrentLanguages(currentLanguage)) {
            return moment(value).format('MMMM DD, YYYY');
          }

          return moment(value).format('LL');
        }
        if (format === 'temperature' || format?.match(/temperature_\d+_decimal_places/)) {
          const unitSystem = getCurrentUnitSystem();
          const decimalPlaces = parseInt(format?.split('_')[1], 10) || 2;
          if (unitSystem === UnitSystem.IMPERIAL) {
            return `${formatNumber(convert(value).from('C').to('F'), decimalPlaces, decimalPlaces)}°F`;
          }
          return `${formatNumber(value, decimalPlaces, decimalPlaces)}°C`;
        }
        if (format === 'temperature_simple') {
          const unitSystem = getCurrentUnitSystem();
          if (unitSystem === UnitSystem.IMPERIAL) {
            return `${convert(value).from('C').to('F').toFixed(0)}°`;
          }
          return `${value.toFixed(0)}°`;
        }
        if (format === 'temperature_number') {
          const unitSystem = getCurrentUnitSystem();
          if (unitSystem === UnitSystem.IMPERIAL) {
            return `${convert(value).from('C').to('F').toFixed(0)}`;
          }
          return `${value.toFixed(0)}`;
        }
        if (value instanceof moment) {
          return (value as Moment).format(format);
        }

        return value;
      }
    },
    detection: {
      order: ['STLangDetector']
    },
    backend: {
      loadPath: '/i18n/{{lng}}.json'
    }
  })
  .catch(e => {
    sentryService.captureException(e, {
      fileName: 'i18n',
      method: 'verifyCurrentLanguages',
      description: 'Fail initing i18n'
    });
    console.log('Fail initing i18n');
  });

export default i18n;
