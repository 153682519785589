import { getNameByCurrentLanguage } from 'core/utils/functions';
import { map } from 'lodash';
import { useGetPhenomenaByClusters } from 'querys/fields-clustering/fields-clustering.query';

import { useGetAllPhenomenaByProperty } from 'entities/phenomenon/phenomenon.hooks';

interface IUsePhenomenaAttrByClusters {
  attr: string;
  enabled: boolean;
}

interface IUsePhenomenaAttrByClustersReturn {
  phenomenaByClusters: string[][] | undefined;
  isLoading: boolean;
  isError: boolean;
}

const usePhenomenaAttrByClusters = ({ attr = 'name', enabled = false }: IUsePhenomenaAttrByClusters): IUsePhenomenaAttrByClustersReturn => {
  const { data, isLoading, isError } = useGetPhenomenaByClusters({ enabled });
  const { phenomenaDictionary, isLoading: allPhenomenaByPropertyIsLoading } = useGetAllPhenomenaByProperty({ enabled });

  const phenomenaByClusters = map(data, phenomenaIds =>
    map(phenomenaIds, id => {
      if (attr === 'name') {
        return getNameByCurrentLanguage(phenomenaDictionary?.[id]?.name);
      }

      return phenomenaDictionary?.[id]?.[attr];
    })
  );

  return {
    phenomenaByClusters,
    isLoading: allPhenomenaByPropertyIsLoading || isLoading,
    isError
  };
};

export default usePhenomenaAttrByClusters;
