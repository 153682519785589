import { COLOR_OPTIONS } from 'config/style';

interface CropAvocadoProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropAvocado = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropAvocadoProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.1064 9.36271L13.1166 9.33021C13.2696 8.84225 13.4242 8.3493 13.6203 7.86259C13.7932 7.43342 13.9792 7.03556 14.1536 6.66232L14.1585 6.65201C14.8622 5.14465 15.6597 3.43653 14.1027 1.87714C12.5526 0.324596 10.7598 1.1593 9.17885 1.89565L9.14594 1.91096C8.76239 2.08937 8.36034 2.27638 7.93727 2.44491C7.43401 2.64467 6.94409 2.78104 6.4699 2.91263C5.23895 3.2546 3.96622 3.60821 2.57651 5.00006C0.359519 7.22046 0.495362 10.7049 2.89195 13.1052C5.28853 15.5054 8.76763 15.6415 10.9846 13.4211C12.2231 12.1807 12.6723 10.7481 13.1064 9.36271ZM7 12C8.65685 12 10 10.6569 10 9C10 7.34315 8.65685 6 7 6C5.34315 6 4 7.34315 4 9C4 10.6569 5.34315 12 7 12Z'
      fill={color}
    />
  </svg>
);

export default CropAvocado;
