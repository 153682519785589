import { datadogRum } from '@datadog/browser-rum';
import { getSelectedCompany, getSystemFlags } from 'core/core.selectors';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import useVersion from 'core/shared/version/useVersion.hook';
import { isTrustedDomain } from 'core/utils/requests';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

interface DatadogConfig {
  sessionSampleRate: number;
  sessionReplaySampleRate: number;
  telemetrySampleRate: number;
  traceSampleRate: number;
  trackResources: boolean;
  trackLongTasks: boolean;
  trackUserInteractions: boolean;
  enablePrivacyForActionName: boolean;
  compressIntakeRequests: boolean;
}

export const useDatadogRum = () => {
  const user = useSelector(selectCurrentUser);
  const orgId = useSelector(getSelectedCompany);
  const propertyInfo = useSelector(getSelectedProperty);

  const systemFlags = useSelector(getSystemFlags);
  const tracingEnabled = useMemo(() => systemFlags?.P40_44838_DATADOG_RUM, [systemFlags]);
  const traceConfig = useMemo(() => {
    const defaultConfig: DatadogConfig = {
      sessionReplaySampleRate: 1,
      sessionSampleRate: 5,
      telemetrySampleRate: 5,
      traceSampleRate: 5,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      enablePrivacyForActionName: false,
      compressIntakeRequests: true
    };

    if (isEmpty(systemFlags?.P40_44838_DATADOG_RUM_CONFIG)) {
      return defaultConfig;
    } else {
      return {
        ...defaultConfig,
        ...JSON.parse(systemFlags?.P40_44838_DATADOG_RUM_CONFIG as string)
      } as DatadogConfig;
    }
  }, [systemFlags]);

  const version = useVersion();

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        baseRole: user.role,
        protectorRole: user.job_title
      });
    }
  }, [user]);

  useEffect(() => {
    if (orgId) {
      datadogRum.setGlobalContextProperty('organizationInfo', {
        id: orgId
      });
    }
  }, [orgId]);

  useEffect(() => {
    if (propertyInfo) {
      datadogRum.setGlobalContextProperty('propertyInfo', {
        id: propertyInfo?.id,
        name: propertyInfo?.name
      });
    }
  }, [propertyInfo]);

  useEffect(() => {
    if (tracingEnabled && version) {
      datadogRum.init({
        ...traceConfig,
        applicationId: '74c15d9c-1280-45ab-af92-b011d9e94a1e',
        clientToken: 'pub9de9f41aa1baae5fcdebe8e657d17812',
        site: 'datadoghq.eu',
        version: version,
        service: 'web-react-protector-user-client',
        env: process.env.VITE_ENV,
        defaultPrivacyLevel: 'mask-user-input',
        silentMultipleInit: true,
        beforeSend: event => {
          if (event.type === 'resource') {
            if (!isTrustedDomain(event.resource.url)) {
              return false;
            }
          }
          return true;
        }
      });
    }
  }, [tracingEnabled, traceConfig, version]);
};
