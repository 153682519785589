import Icon from '@ant-design/icons';
import { Spin } from 'antd';
import { useDidMount } from 'beautiful-react-hooks';
import { CLIENT_ID, OAUTH_CROPWISE_URL, OAUTH_UK_URL } from 'config/constants';
import { LoginSuccess } from 'core/services/auth/auth.actions';
import type { Oauth2TokenResponse } from 'core/services/auth/auth.models';
import { isUK } from 'core/utils/functions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UNUSUAL_REDIRECT_LOCAL_STORAGE_KEY } from './contants';

const oauthUrl = isUK() ? OAUTH_UK_URL : OAUTH_CROPWISE_URL;

const LoginChecker = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  useDidMount(() => {
    const token = localStorage.getItem('access_token');

    if (token) {
      dispatch(LoginSuccess(token as unknown as Oauth2TokenResponse));
    } else {
      localStorage.setItem(UNUSUAL_REDIRECT_LOCAL_STORAGE_KEY, window.location.pathname);

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      sessionStorage.removeItem('company_id');
      window.location.replace(
        `${oauthUrl}/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${window.location.origin}/authenticate`
      );
    }
  });

  return (
    <div className='login-oauth2'>
      <Spin
        indicator={<Icon name='loading' style={{ fontSize: 24 }} spin />}
        tip={t('pages.smart_spray.loading_info') !== 'pages.smart_spray.loading_info' ? t('pages.smart_spray.loading_info') : ''}
        spinning
        delay={0}
      />
    </div>
  );
};

export default LoginChecker;
