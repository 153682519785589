import { COLOR_OPTIONS } from 'config/style';

interface CropTomatoProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropTomato = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropTomatoProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.14351 3.72965L6.60145 2.98771C6.66536 2.88418 6.6831 2.75519 6.64977 2.63606L6.50788 2.12896C6.48246 2.03809 6.58327 1.96475 6.66046 2.01796L7.79108 2.7974L7.7961 2.80084C7.85944 2.84338 7.92989 2.86373 8.00046 2.86373C8.07155 2.86373 8.14352 2.84252 8.20789 2.79815L9.33954 2.01797C9.41673 1.96476 9.51754 2.0381 9.49211 2.12897L9.35023 2.63606C9.3169 2.75519 9.33464 2.88415 9.39855 2.98771L9.85649 3.72965C9.90568 3.80935 9.83009 3.90784 9.74148 3.87951L9.10976 3.67751C8.95047 3.62661 8.77902 3.69133 8.68379 3.83821L8.08365 4.76411C8.04417 4.82503 7.95583 4.82503 7.91635 4.76411L7.31621 3.83821C7.24267 3.72475 7.12363 3.66034 6.99986 3.66034C6.96339 3.66034 6.92648 3.66593 6.89024 3.67751L6.25852 3.87951C6.16991 3.90784 6.09432 3.80935 6.14351 3.72965Z'
      fill={color}
    />
    <path
      d='M10.3915 2.87186C11.3338 3.17682 12.1985 3.66318 12.9304 4.31143C14.265 5.49356 15 7.06973 15 8.74951C15 10.4293 14.265 12.0054 12.9304 13.1876C11.6108 14.3563 9.85981 15 8 15C6.14019 15 4.38919 14.3563 3.06964 13.1876C1.73499 12.0054 1 10.4293 1 8.74951C1 7.0697 1.73499 5.49356 3.06964 4.31143C3.80154 3.66315 4.66632 3.17676 5.60864 2.8718L5.61506 2.8931L4.39971 4.72212C4.29935 4.87312 4.30405 5.071 4.41146 5.21705C4.5189 5.36311 4.70601 5.42608 4.8795 5.37452L6.7118 4.83036L7.64788 6.17187C7.72832 6.28715 7.85981 6.35581 8.00009 6.35581C8.14037 6.35581 8.27185 6.28715 8.35229 6.17187L9.28837 4.83036L11.1207 5.37452C11.2943 5.42605 11.4813 5.36311 11.5887 5.21705C11.6961 5.071 11.7008 4.87312 11.6005 4.72212L10.3851 2.8931L10.3915 2.87186Z'
      fill={color}
    />
  </svg>
);

export default CropTomato;
