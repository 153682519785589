import { Divider } from 'antd';
import type { MouseEventHandler, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface SelectMultiDDRenderProps {
  onSelectAll: MouseEventHandler<HTMLButtonElement> | undefined;
  selectAllLabel: string | undefined;
  menu: ReactNode;
}

const SelectMultiDDRender = ({ onSelectAll, selectAllLabel, menu }: SelectMultiDDRenderProps) => {
  const [t] = useTranslation();

  if (!onSelectAll) return;

  return (
    <>
      <StyledSelectAll aria-label='select all' onMouseDown={onSelectAll}>
        {selectAllLabel ?? t('components.select_multi.select_all')}
      </StyledSelectAll>

      <StyledDivider />

      {menu}
    </>
  );
};

export default SelectMultiDDRender;

const StyledSelectAll = styled.button`
  &:hover {
    cursor: pointer;
  }

  padding: 6px 12px 4px;
  border: 0;
  background-color: transparent;
`;

const StyledDivider = styled(Divider)`
  margin: 4px 0;
`;
