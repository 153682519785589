import { Skeleton, TreeSelect } from 'antd';
import i18n from 'config/i18n';
import type { Dictionary } from 'config/types';
import { getSystemFlags } from 'core/core.selectors';
import { isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { Region } from '../../../entities/region/region.models';
import '../region-tree-select.styles.less';
import { useCreateTreeData } from '../region-tree-select.utils';
import { CustomTreeSelect, StyledRegionTreeSelectContainerDefault } from './region-tree-select.styles';

export interface RegionTreeSelectDefaultProps {
  regions: Dictionary<Region>;
  rootRegionId?: string;
  isFilterGeometry?: boolean;
  selectedSeasonsIds: string[];
  value?: string[];
  disabled?: boolean;
  onChange: (ids: string[]) => void;
  placeholder?: string;
  loading?: boolean;
  showSearch?: boolean;
  multiple?: boolean;
  treeNodeFilterProp?: string;
  treeCheckable?: boolean;
  className?: string;
  testId?: string;
  dropdownStyle?: React.CSSProperties;
  dropdownClassName?: string;
}

const RegionTreeSelectDefault = ({
  regions,
  rootRegionId,
  isFilterGeometry,
  selectedSeasonsIds,
  value,
  disabled,
  onChange,
  loading,
  placeholder = i18n.t('components.select_region_tree.placeholder'),
  showSearch = false,
  multiple = false,
  treeNodeFilterProp = 'name',
  treeCheckable = true,
  className,
  testId = 'TreeSelect-default',
  dropdownStyle = {},
  dropdownClassName = ''
}: RegionTreeSelectDefaultProps) => {
  const treeData = useCreateTreeData('default', regions, rootRegionId, selectedSeasonsIds, isFilterGeometry, undefined, false);
  const systemFlags = useSelector(getSystemFlags);
  const RegionTreeComponent: React.ElementType = useMemo(
    () => (systemFlags?.P40_44286_REGION_TREE_SELECT_ANTD_V5 ? CustomTreeSelect : TreeSelect),
    [systemFlags]
  );

  if (loading)
    return (
      <div data-testid={`${testId}-skeleton`}>
        <Skeleton avatar={false} paragraph={false} active />
      </div>
    );

  return (
    <StyledRegionTreeSelectContainerDefault data-testid={testId}>
      <RegionTreeComponent
        allowClear
        showSearch={showSearch}
        multiple={multiple}
        treeData={treeData}
        value={value}
        disabled={disabled}
        onChange={onChange}
        maxTagCount={2}
        size='large'
        placeholder={placeholder}
        className={className}
        treeNodeFilterProp={treeNodeFilterProp}
        treeCheckable={treeCheckable}
        dropdownStyle={dropdownStyle}
        dropdownClassName={dropdownClassName}
      />
    </StyledRegionTreeSelectContainerDefault>
  );
};

export default React.memo(RegionTreeSelectDefault, isEqual);
