/* eslint-disable @typescript-eslint/no-unsafe-return */
import type { Action } from 'core/utils/basic.models';
import type { RegionMonitoringReport } from 'entities/region/region.models';
import { getWindowEvents } from 'pages/timeline/timeline.service';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  IndicatorsActionsTypes,
  LoadDaysIndicatorsFailure,
  LoadDaysIndicatorsSuccess,
  LoadWindowIndicatorFailure,
  LoadWindowIndicatorSuccess
} from './indicators.actions';
import type { PayloadWindowIndicator, ResultIndicators, WindowIndicators } from './indicators.models';
import { getDaysIndicators } from './indicators.service';

export const handleLoadDaysIndicators = (action$, state$) =>
  action$.pipe(
    ofType(IndicatorsActionsTypes.LOAD_DAYS_INDICATORS),
    map((action: Action) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => state.uiState.global.systemFlags))),
    exhaustMap(([payload, flags]) => getDaysIndicators(payload, flags?.P40_25084_splitRequestDaysIndicators)),
    map((result: ResultIndicators) => {
      return LoadDaysIndicatorsSuccess(result);
    }),
    catchError((error: string) => of(LoadDaysIndicatorsFailure(error)))
  );

export const handleLoadWindowIndicator = (action$, state$) =>
  action$.pipe(
    ofType(IndicatorsActionsTypes.LOAD_WINDOW_INDICATOR),
    map((action: Action) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => state.entities.indicators.windows))),
    mergeMap(([payload, windows]: [PayloadWindowIndicator, any]) => {
      if (windows?.[payload.propertyId]?.[payload.areaId]) {
        return of();
      }
      return getWindowEvents(payload.propertyId, payload.areaId, payload.season_ids, payload.start_date, payload.end_date).pipe(
        map(response => {
          if (!response.data) return;
          const dataReturn: WindowIndicators = {
            propertyId: payload.propertyId,
            areaId: payload.areaId,
            window: response.data as RegionMonitoringReport
          };
          return LoadWindowIndicatorSuccess(dataReturn);
        }),
        catchError(() => of(LoadWindowIndicatorFailure('Error loading events')))
      );
    })
  );
