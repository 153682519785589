import { Entitlements } from 'core/shared/enums/entitlements.enum';
import type { Crop } from 'core/utils/basic.models';
import { validatePlansAndEntitlements } from 'core/utils/functions';
import type { LicensingStatusResponse } from 'entities/company/company.models';
import type { Property } from 'entities/property/property.models';
import { filterSelectedCrop } from 'pages/disease-risk/utils/disease-risk.utils';

export const willShowDiseaseRiskIcon = (diseaseRisk: boolean, property: Property | null | undefined): boolean => {
  return diseaseRisk && !!property;
};

export const handleDiseaseRiskActivation = (
  property: Property | null | undefined,
  selectedCrops: Crop[],
  companyId: string | null,
  licensingStatus: LicensingStatusResponse | null,
  setDiseaseRisk: (value: boolean) => void
): void => {
  if (!property || !companyId || !selectedCrops?.length || !licensingStatus) return;
  setDiseaseRisk(false);
  const userPlans = licensingStatus.apps.map(item => item.plan.name);
  const entitlements = [
    Entitlements.DISEASE_RISK,
    Entitlements.DISEASE_RISK_BARLEY,
    Entitlements.DISEASE_RISK_WHEAT,
    Entitlements.DISEASE_RISK_GRAPES
  ];

  const hasDiseaseRisk = validatePlansAndEntitlements(licensingStatus, userPlans, entitlements);
  const hasEligibleCrop = selectedCrops.some(selectedCrop => filterSelectedCrop(selectedCrop, licensingStatus));
  setDiseaseRisk(hasDiseaseRisk && hasEligibleCrop);
};
