import type { Action } from 'core/utils/basic.models';
import { GetAreaDayRepresentativeness } from 'entities/region/region.actions';
import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { LoadAreaDayMonitoringFailure, LoadAreaDayMonitoringSuccess, MonitoringActionsTypes } from './monitoring.actions';
import type { LoadMonitoringPayload } from './monitoring.models';
import MonitoringService from './monitoring.service';

export const handleAreaDayMonitoring = (action$, state$) => {
  return action$.pipe(
    ofType(MonitoringActionsTypes.LOAD_AREA_DAY_MONITORING),
    map((action: Action<LoadMonitoringPayload>) => action.payload),
    concatMap(({ propertyId, areaId, day, showRepresentativeness, representedPointsIds, areaPointsIds }) => {
      const monitoringObservable = showRepresentativeness
        ? MonitoringService.getMonitoring(propertyId, day)
        : MonitoringService.getMonitoringAreaDay(propertyId, areaId, day);
      return monitoringObservable.pipe(
        concatMap(monitoringValues => {
          const actions = showRepresentativeness
            ? [
                LoadAreaDayMonitoringSuccess(monitoringValues),
                GetAreaDayRepresentativeness(monitoringValues, day, areaId, representedPointsIds, areaPointsIds)
              ]
            : [LoadAreaDayMonitoringSuccess(monitoringValues)];
          return concat(actions);
        }),
        catchError(error => of(LoadAreaDayMonitoringFailure(error)))
      );
    })
  );
};
