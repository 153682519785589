import type { EditStationDrawerActionType, SelectTemplateDrawerActionType, ToggleDrawerActionType } from './static-points-drawer.models';

export const StaticPointsDrawerActions = {
  TOGGLE_DRAWER: '[Static Points Drawer] Toggle Visibility',
  SELECT_TEMPLATE: '[Static Points Drawer] Select Template',
  EDIT_STATION: '[Static Points Drawer] Edit Station',
  CLEAR_SELECTED_STATION: '[Static Points Drawer] Clear Selected Station',
  SELECT_TRAPVIEW: '[Static Points Drawer] Select Trapview',
  CLEAR_SELECTED_TRAPVIEW: '[Static Points Drawer] Clear Selected Trapview'
};

export const ToggleDrawer: ToggleDrawerActionType = (visible, type = null) => {
  return {
    type: StaticPointsDrawerActions.TOGGLE_DRAWER,
    payload: { visible, type }
  };
};

export const SelectTemplateDrawer: SelectTemplateDrawerActionType = template => {
  return {
    type: StaticPointsDrawerActions.SELECT_TEMPLATE,
    payload: template
  };
};

export const EditStationDrawer: EditStationDrawerActionType = station => {
  return {
    type: StaticPointsDrawerActions.EDIT_STATION,
    payload: station
  };
};

export const ClearSeletectedStationDrawer = () => {
  return {
    type: StaticPointsDrawerActions.CLEAR_SELECTED_STATION
  };
};

export const SelectTrapviewDrawer = () => {
  return {
    type: StaticPointsDrawerActions.SELECT_TRAPVIEW
  };
};

export const ClearSelectedTrapviewDrawer = () => {
  return {
    type: StaticPointsDrawerActions.CLEAR_SELECTED_TRAPVIEW
  };
};
