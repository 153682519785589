const SvgWeatherHourlyOvercast = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      d='M5.79998 14C3.19998 14 1.09998 11.9 1.09998 9.3C1.09998 6.8 3.09998 4.7 5.59998 4.6C6.59998 2.9 8.49998 1.8 10.5 1.8C13.6 1.8 16.2 4.3 16.2 7.5L16.1 9.4L14.3 9.3L14.4 7.4C14.4 5.3 12.7 3.6 10.6 3.6C8.99998 3.5 7.59998 4.5 6.99998 5.9L6.69998 6.5L6.09998 6.4C5.99998 6.4 5.99998 6.4 5.89998 6.4H5.79998C4.19998 6.4 2.89998 7.7 2.89998 9.3C2.89998 10.9 4.19998 12.2 5.79998 12.2V14Z'
      fill='#BDC4D1'
    />
    <path
      d='M17.3 21.7H5.69999C3.29999 21.7 1.29999 19.7 1.29999 17.3C1.29999 15.2 2.79999 13.4 4.79999 13C5.19999 9.7 8.09999 7.1 11.5 7.1C14 7.1 16.2 8.5 17.4 10.6C20.4 10.6 22.9 13.1 22.9 16.2C22.9 19.2 20.4 21.7 17.3 21.7ZM5.69999 14.7C4.29999 14.7 3.09999 15.9 3.09999 17.3C3.09999 18.7 4.29999 19.9 5.69999 19.9H17.3C19.4 19.9 21 18.2 21 16.2C21 14.1 19.3 12.5 17.3 12.5C17.3 12.5 17.2 12.5 17.1 12.5C17 12.5 17 12.5 16.9 12.5L16.2 12.6L16 11.9C15.2 10.1 13.5 8.9 11.5 8.9C8.79999 8.9 6.59999 11.1 6.59999 13.8V14.7H5.69999Z'
      fill='#828B99'
    />
  </svg>
);

export default SvgWeatherHourlyOvercast;
