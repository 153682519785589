import { getCurrentLanguage } from 'core/utils/functions';
import type { LocaleSpecification } from 'moment';
import moment from 'moment';
import 'moment/dist/locale/de';
import 'moment/dist/locale/es';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/hu';
import 'moment/dist/locale/it';
import 'moment/dist/locale/pl';
import 'moment/dist/locale/pt-br';
import type { Dictionary } from 'config/types';
import relativeDeDe from './momentFiles/relativeTime/de-de.json';
import relativeEn from './momentFiles/relativeTime/en.json';
import relativeEs from './momentFiles/relativeTime/es.json';
import relativeFrFr from './momentFiles/relativeTime/fr-fr.json';
import relativeHuHu from './momentFiles/relativeTime/hu-hu.json';
import relativeIt from './momentFiles/relativeTime/it.json';
import relativePlPl from './momentFiles/relativeTime/pl-pl.json';
import relativePtBr from './momentFiles/relativeTime/pt-br.json';

let locale = '';

export function getRightTranslation(): Dictionary<string> {
  locale = getCurrentLanguage();

  if (locale === 'pt' || locale === 'pt-br') {
    locale = 'pt-br';
    return relativePtBr;
  }

  if (locale === 'de-de') {
    locale = 'de';
    return relativeDeDe;
  }

  if (locale === 'fr-fr') {
    locale = 'fr';
    return relativeFrFr;
  }

  if (locale === 'pl-pl') {
    locale = 'pl';
    return relativePlPl;
  }

  if (locale === 'es' || locale === 'es-AR') {
    locale = 'es';
    return relativeEs;
  }

  if (locale === 'hu-hu') {
    locale = 'hu';
    return relativeHuHu;
  }

  if (locale === 'it') {
    locale = 'it';
    return relativeIt;
  }

  locale = 'en';
  return relativeEn;
}

const specification: LocaleSpecification = {
  relativeTime: getRightTranslation()
};

moment.updateLocale(locale, specification);
