import type { ReactNode } from 'react';

export enum ReportsTypes {
  SCOUTING = 'scouting',
  SPRAY = 'spray',
  PROPERTY = 'property',
  TRACKING = 'tracking',
  SAMPLE = 'SAMPLE_POINT',
  VISIT = 'VISIT',
  SAMPLING = 'SAMPLING',
  TRAP = 'TRAP',
  WAREHOUSE_INVENTORY = 'INVENTORY',
  MONITORING = 'MONITORING',
  SAMPLING_IMPORT = 'SAMPLING_IMPORT',
  APPLICATION_STATISTICS = 'APPLICATION_STATISTICS',
  CONTROL_STRATEGIES = 'CONTROL_STRATEGIES',
  MONITORING_CONSOLIDATED = 'MONITORING_CONSOLIDATED',
  MONITORING_DETAILED = 'MONITORING_DETAILED',
  RAIN_GAUGE_REPORT = 'RAIN_GAUGE',
  DISEASE_RISK = 'DISEASE_RISK',
  BLOCKED_FIELDS = 'BLOCKED_FIELDS'
}

interface IReportType {
  className: string;
  setReportToOpen: (type: ReportsTypes) => void;
  text: string;
  type: ReportsTypes;
  icon: ReactNode;
  isVisible: boolean;
  testId?: string;
}

export type IReportTypes = {
  [key in ReportsTypes]?: IReportType;
};
