import type {
  LoadMethodologiesByCompanyFailureType,
  LoadMethodologiesByCompanySuccessType,
  LoadMethodologiesByCompanyType,
  UpdateMethodologySuccessType,
  UpdateMethodologyType
} from './methodology.models';

export const MethodologyActionTypes = {
  LOAD_METHODOLOGIES_BY_COMPANY: '[Methodology] Load Methodology by Company',
  LOAD_METHODOLOGIES_BY_COMPANY_SUCCESS: '[Methodology] Load Methodology by Company Success',
  LOAD_METHODOLOGIES_BY_COMPANY_FAILURE: '[Methodology] Load Methodology by Company Failure',

  UPDATE_METHODOLOGY: '[Methodology] Update Methodology',
  UPDATE_METHODOLOGY_SUCCESS: '[Methodology] Update Methodology Success',
  UPDATE_METHODOLOGY_FAILURE: '[Methodology] Update Methodology Failure'
};

export const LoadMethodologiesByCompany: LoadMethodologiesByCompanyType = company_id => {
  return {
    type: MethodologyActionTypes.LOAD_METHODOLOGIES_BY_COMPANY,
    payload: company_id
  };
};

export const LoadMethodologiesByCompanySuccess: LoadMethodologiesByCompanySuccessType = methodologies => {
  return {
    type: MethodologyActionTypes.LOAD_METHODOLOGIES_BY_COMPANY_SUCCESS,
    payload: methodologies
  };
};

export const LoadMethodologiesByCompanyFailure: LoadMethodologiesByCompanyFailureType = error => {
  return {
    type: MethodologyActionTypes.LOAD_METHODOLOGIES_BY_COMPANY_FAILURE,
    payload: error
  };
};

export const UpdateMethodology: UpdateMethodologyType = methodology => {
  return {
    type: MethodologyActionTypes.UPDATE_METHODOLOGY,
    payload: methodology
  };
};

export const UpdateMethodologySuccess: UpdateMethodologySuccessType = methodology => {
  return {
    type: MethodologyActionTypes.UPDATE_METHODOLOGY_SUCCESS,
    payload: methodology
  };
};

export const UpdateMethodologyFailure: LoadMethodologiesByCompanyFailureType = error => {
  return {
    type: MethodologyActionTypes.UPDATE_METHODOLOGY_FAILURE,
    payload: error
  };
};
