import type { Action, UUID } from 'core/utils/basic.models';
import type { MethodologyEntity } from 'entities/methodology/methodology.models';
import type { IndicatorDTO, Phenomenon } from 'entities/phenomenon/phenomenon.models';
import type {
  GetCurrentSeasonAreaPayload,
  IndicatorPressureResponse,
  LoadPropertiesSuccessType,
  LoadPropertiesType,
  LoadPropertyDiagnosticBreakdownFailureType,
  LoadPropertyDiagnosticBreakdownSuccessType,
  LoadPropertyPhenomenaPayload,
  LoadPropertySuccessType,
  LoadPropertyType,
  PropertyInfoAddress
} from './property.models';

export const PropertyActionsTypes = {
  LOAD_PROPERTIES: '[Property] Load Properties',
  LOAD_PROPERTIES_SUCCESS: '[Property] Load Properties Success',
  LOAD_PROPERTIES_FAILURE: '[Property] Load Properties Failure',

  LOAD_PROPERTIES_AREA_INFO: '[Property] Load Properties Area Info',
  LOAD_PROPERTIES_AREA_INFO_SUCCESS: '[Property] Load Properties Area Info Success',
  LOAD_PROPERTIES_AREA_INFO_FAILURE: '[Property] Load Properties Area Info Failure',

  LOAD_PROPERTY_DATA: '[Property] Load Property Data',
  LOAD_PROPERTY_DATA_SUCCESS: '[Property] Load Property Data Success',
  LOAD_PROPERTY_DATA_FAILURE: '[Property] Load Property Data Failure',

  LOAD_PROPERTY_DIAGNOSTIC_BREAKDOWN: '[Property] Load Property Diagnostic Breakdown',
  LOAD_PROPERTY_DIAGNOSTIC_BREAKDOWN_SUCCESS: '[Property] Load Property Diagnostic Breakdown Success',
  LOAD_PROPERTY_DIAGNOSTIC_BREAKDOWN_FAILURE: '[Property] Load Property Diagnostic Breakdown Failure',

  LOAD_PROPERTY: '[Property] Load Property',
  LOAD_PROPERTY_SUCCESS: '[Property] Load Property Success',

  LOAD_PROPERTY_PHENOMENA: '[Property] Load Property Phenomena',
  LOAD_PROPERTY_PHENOMENA_SUCCESS: '[Property] Load Property Phenomena Success',
  LOAD_PROPERTY_INDICATORS_SUCCESS: '[Property] Load Property Indicators Success',
  LOAD_PROPERTY_METHODOLOGIES_SUCCESS: '[Property] Load Property Methodologies Success',

  LOAD_PROPERTY_TIME: '[Property] Load Property Time',
  LOAD_PROPERTY_TIME_SUCCESS: '[Property] Load Property Time SUCCESS',

  GET_PROPERTY_COUNTRY: '[Property] Get Property Disease Risk Status',
  GET_PROPERTY_COUNTRY_SUCCESS: '[Property] Get Property Disease Risk Status Success',
  GET_PROPERTY_COUNTRY_FAILURE: '[Property] Get Property Disease Risk Status Failure',

  GET_CURRENT_SEASON_AREAS: '[Property] Get Current Season Areas',
  GET_CURRENT_SEASON_AREAS_SUCCESS: '[Property] Get Current Season Areas Success',
  GET_CURRENT_SEASON_AREAS_FAILURE: '[Property] Get Current Season Areas Failure',

  LOAD_SEVERITY_BY_INDICATOR: '[Property] Load severity by indicator',
  LOAD_SEVERITY_BY_INDICATOR_SUCCESS: '[Property] Load severity by indicator Success',
  CLEAR_INDICATOR_SEVERITY: '[Property] Clear indicator severity',

  GET_SEASON_AREAS_BY_DATE: '[Property] Get Season Areas By Date',
  GET_SEASON_AREAS_BY_DATE_SUCCESS: '[Property] Get Season Areas By Date Success',
  GET_SEASON_AREAS_BY_DATE_FAILURE: '[Property] Get Season Areas By Date Failure',

  SET_USE_NEW_MAP_COLORS_FLAG: '[Property] Set value for use new colors flag'
} as const;

export const LoadProperties: LoadPropertiesType = pageableQuery => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTIES,
    payload: pageableQuery
  };
};

export const LoadPropertiesAreaInfo: LoadPropertiesType = pageableQuery => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTIES_AREA_INFO,
    payload: pageableQuery
  };
};

export const LoadPropertiesSuccess: LoadPropertiesSuccessType = propertyPage => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTIES_SUCCESS,
    payload: propertyPage
  };
};

export const LoadPropertiesFailure = (error: string): Action<string> => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTIES_FAILURE,
    payload: error
  };
};

export const LoadPropertiesAreaInfoSuccess: LoadPropertiesSuccessType = propertyPage => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTIES_AREA_INFO_SUCCESS,
    payload: propertyPage
  };
};

export const LoadPropertyData = (propertyId: UUID): Action<UUID> => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTY_DATA,
    payload: propertyId
  };
};

export const LoadPropertyDataSuccess = (propertyData: PropertyInfoAddress): Action<PropertyInfoAddress> => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTY_DATA_SUCCESS,
    payload: propertyData
  };
};

export const LoadPropertyDataFailure = (error: string): Action<string> => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTY_DATA_FAILURE,
    payload: error
  };
};

export const GetPropertyCountrySuccess = payload => {
  return {
    type: PropertyActionsTypes.GET_PROPERTY_COUNTRY_SUCCESS,
    payload
  };
};

export const GetPropertyCountryFailure = (error: string) => {
  return {
    type: PropertyActionsTypes.GET_PROPERTY_COUNTRY_FAILURE,
    payload: error
  };
};

export const LoadProperty: LoadPropertyType = (propertyId, companyId) => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTY,
    payload: { propertyId, companyId }
  };
};

export const LoadPropertySuccess: LoadPropertySuccessType = property => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTY_SUCCESS,
    payload: property
  };
};

export const LoadPropertyPhenomena = (propertyId: UUID, seasonIds: UUID[], areaIds: UUID[]): Action<LoadPropertyPhenomenaPayload> => ({
  type: PropertyActionsTypes.LOAD_PROPERTY_PHENOMENA,
  payload: { propertyId, seasonIds, areaIds }
});

export const LoadPropertyPhenomenaSuccess = (phenomenons: Phenomenon[]) => ({
  type: PropertyActionsTypes.LOAD_PROPERTY_PHENOMENA_SUCCESS,
  payload: phenomenons
});

export const LoadPropertyIndicatorsSuccess = (indicators: IndicatorDTO[]) => ({
  type: PropertyActionsTypes.LOAD_PROPERTY_INDICATORS_SUCCESS,
  payload: indicators
});

export const LoadPropertyMethodologiesSuccess = (methodologies: MethodologyEntity[]) => ({
  type: PropertyActionsTypes.LOAD_PROPERTY_METHODOLOGIES_SUCCESS,
  payload: methodologies
});

export const LoadPropertyDiagnosticBreakdownSuccess: LoadPropertyDiagnosticBreakdownSuccessType = propertyDiagnosticBreakdown => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTY_DIAGNOSTIC_BREAKDOWN_SUCCESS,
    payload: propertyDiagnosticBreakdown
  };
};

export const LoadPropertyDiagnosticBreakdownFailure: LoadPropertyDiagnosticBreakdownFailureType = propertyDiagnosticBreakdown => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTY_DIAGNOSTIC_BREAKDOWN_FAILURE,
    payload: propertyDiagnosticBreakdown
  };
};

export const LoadPropertyTime = (id: UUID) => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTY_TIME,
    payload: id
  };
};

export const LoadPropertyTimeSuccess = (time: string): Action<string> => {
  return {
    type: PropertyActionsTypes.LOAD_PROPERTY_TIME_SUCCESS,
    payload: time
  };
};

export const GetCurrentSeasonAreas = (propertyId: GetCurrentSeasonAreaPayload) => {
  return {
    type: PropertyActionsTypes.GET_CURRENT_SEASON_AREAS,
    payload: propertyId
  };
};

export const GetCurrentSeasonAreasSuccess = content => {
  return {
    type: PropertyActionsTypes.GET_CURRENT_SEASON_AREAS_SUCCESS,
    payload: content
  };
};

export const GetCurrentSeasonAreasFailure = (error: string): Action<string> => {
  return {
    type: PropertyActionsTypes.GET_CURRENT_SEASON_AREAS_FAILURE,
    payload: error
  };
};

export const LoadSeverityByIndicator = (propertyId: UUID, indicatorIds: UUID[], methodologyIds: UUID[]) => ({
  type: PropertyActionsTypes.LOAD_SEVERITY_BY_INDICATOR,
  payload: { propertyId, indicatorIds, methodologyIds }
});

export const LoadSeverityByIndicatorSuccess = (data: IndicatorPressureResponse[], indicator: UUID) => ({
  type: PropertyActionsTypes.LOAD_SEVERITY_BY_INDICATOR_SUCCESS,
  payload: { data, indicator }
});

export const ClearIndicatorPressure = () => ({
  type: PropertyActionsTypes.CLEAR_INDICATOR_SEVERITY
});

export const SetUseNewMapColors = (value: boolean) => ({
  type: PropertyActionsTypes.SET_USE_NEW_MAP_COLORS_FLAG,
  payload: value
});

export const GetSeasonAreasByDate = (payload: GetCurrentSeasonAreaPayload) => {
  return {
    type: PropertyActionsTypes.GET_SEASON_AREAS_BY_DATE,
    payload
  };
};

export const GetSeasonAreasByDateSuccess = content => {
  return {
    type: PropertyActionsTypes.GET_SEASON_AREAS_BY_DATE_SUCCESS,
    payload: content
  };
};

export const GetSeasonAreasByDateFailure = (error: string): Action<string> => {
  return {
    type: PropertyActionsTypes.GET_SEASON_AREAS_BY_DATE_FAILURE,
    payload: error
  };
};
