import { Skeleton } from 'antd';
import { COLOR_OPTIONS } from 'config/style';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import { drop, map } from 'lodash';
import usePhenomenaAttrByClusters from 'pages/field-clustering/configuration/hooks/usePhenomenaAttrByClusters';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FieldClusterFooter from './field-cluster-modal-footer';
import FieldClusterModalTitle from './field-cluster-modal-title';
import { StyledContent, StyledEmpty, StyledModal } from './field-cluster-modal.styles';

export interface FieldClusterModalProps {
  visible: boolean;
  onClickCreate?: () => void;
  onClickClose?: () => void;
}

const FieldClusterModal = ({ visible, onClickCreate, onClickClose }: FieldClusterModalProps): JSX.Element => {
  const [t] = useTranslation();
  const { phenomenaByClusters, isLoading } = usePhenomenaAttrByClusters({ attr: 'name', enabled: visible });

  const formattedPhenomena = useMemo(
    () =>
      map(phenomenaByClusters, (phenomenaList, index) => {
        const hasMorePhenomena = phenomenaList.length > 1;
        return (
          <span key={index}>
            <STTypo type={TypeOptions.H6}>{phenomenaList?.[0]}</STTypo>
            {hasMorePhenomena && (
              <STTypo color={COLOR_OPTIONS.NEUTRAL_60} type={TypeOptions.P1}>
                {`(${drop(phenomenaList)?.join(', ')}).`}
              </STTypo>
            )}
          </span>
        );
      }),
    [phenomenaByClusters]
  );

  const hasPhenomena = phenomenaByClusters && phenomenaByClusters?.length > 0;

  return (
    <StyledModal
      data-testid='field-cluster-modal'
      title={<FieldClusterModalTitle />}
      visible={visible}
      footer={[<FieldClusterFooter key='field-cluster-button-create' onClickCreate={onClickCreate} />]}
      onCancel={onClickClose}>
      <Skeleton active loading={isLoading} paragraph={{ rows: 5, width: '80px' }} title={false}>
        <StyledContent>
          {hasPhenomena && formattedPhenomena}
          {!hasPhenomena && (
            <StyledEmpty>
              <STTypo fontWeight={500}>{t('modals.organization_settings.field_cluster_card.modal.empty.title')}</STTypo>
              <STTypo color={COLOR_OPTIONS.NEUTRAL_60}>{t('modals.organization_settings.field_cluster_card.modal.empty.subtitle')}</STTypo>
            </StyledEmpty>
          )}
        </StyledContent>
      </Skeleton>
    </StyledModal>
  );
};

export default React.memo(FieldClusterModal);
