import chroma from 'chroma-js';
import type { STMapLegendProps } from 'core/shared/map/map.models';
import type { DevelopmentPhases } from 'entities/crop/crop.models';
import type { CurrentSeasonArea, PropertyInfoAddress } from 'entities/property/property.models';
import type { CurrentInfo, Region } from 'entities/region/region.models';
import { RegionType } from 'entities/region/region.models';
import RegionsUtil from 'entities/region/region.utils';
import moment from 'moment-timezone';

import type { Dictionary } from 'config/types';
import { TabChildrens } from 'pages/timeline/multilayer/components/box-controller/timeline-box-controller.constants';
import { useTranslation } from 'react-i18next';
import SvgHeatmap from 'st-protector-icons/Minor/Heatmap';
import type { DateISOString } from '../basic.models';
import { getNumericValueFromLocalStore } from '../functions';
import { getStringOrDefault } from '../strings';
import type { ChoroplethLimits, StorageMaxDaysLimit } from './choropleth.models';
import {
  ChoroplethTypes,
  DAMAGE_DEFAULT_COLOR,
  DAMAGE_THRESHOLDS,
  HEATMAP_COLORS_256,
  MAP_DAE_COLORS_5,
  MAP_SEVERITY_COLORS,
  MAP_SEVERITY_COLORS_5,
  MAP_SPRAY_COLORS_5
} from './choropleth.models';

const SEEDS_COLORS = [
  '#0071CD',
  '#5EBB7F',
  '#643AAA',
  '#5FD2C8',
  '#FF785A',
  '#9664F0',
  '#FFB2C1',
  '#005693',
  '#C17E19',
  '#2B9C92',
  '#F0C355',
  '#C6E6FF',
  '#0C612C',
  '#2E1954',
  '#A9EFE8',
  '#C1462B',
  '#D2BDF9',
  '#BB485D',
  '#002747',
  '#523305',
  '#165C56',
  '#9A6412'
];

const BASE_SEEDS_COLORS = ['#FDE725', '#98d44a', '#6ACC5B', '#159B8C', '#2d6d8b', '#3A538B', '#440154'];

const BASE_PHENOLOGY_COLORS = [
  '#FDEC51',
  '#FFDC4A',
  '#FFC34C',
  '#FEA358',
  '#F37D66',
  '#D95A73',
  '#B93D79',
  '#98357C',
  '#7D3479',
  '#693476'
];

const SEEDS_COLORS_ITEMS: string[] = [];

export const useLegendChoropleth = (
  choroplethLimits: Dictionary<ChoroplethLimits>,
  choroplethType: ChoroplethTypes | null,
  selectedTab: TabChildrens | undefined,
  icon = true,
  storageMaxDays?: StorageMaxDaysLimit
): STMapLegendProps | null => {
  const [t] = useTranslation();
  const title: string = choroplethType ? t(`general.map.choropleth.${choroplethType}`) : '';
  const defaultGroups: string[] = [
    t('general.map.choropleth.acceptance'),
    t('general.map.choropleth.control'),
    t('general.map.choropleth.damage')
  ];

  if (!choroplethType) return null;

  let colors: string[] = [];
  let groups = defaultGroups;

  const shouldShowSeverityColors =
    choroplethType === ChoroplethTypes.severity_level ||
    choroplethType === ChoroplethTypes.indicator_severity ||
    selectedTab === TabChildrens.severity_level ||
    selectedTab === TabChildrens.severity_per_indicator;
  if (shouldShowSeverityColors) {
    colors = MAP_SEVERITY_COLORS;
  } else if (choroplethType === ChoroplethTypes.heatmap) {
    colors = HEATMAP_COLORS_256;
  }

  let actualChoroplethType = choroplethType;
  if (selectedTab === TabChildrens.days_without_monitoring) {
    actualChoroplethType = ChoroplethTypes.days_without_monitoring;
  }
  if (selectedTab === TabChildrens.days_without_spraying) {
    actualChoroplethType = ChoroplethTypes.days_without_spray;
  }

  if (selectedTab === TabChildrens.days_after_emergence) {
    actualChoroplethType = ChoroplethTypes.days_after_emergence;
  }

  if (selectedTab === TabChildrens.scouting_score) {
    colors = ['#BD5D27', '#A3A9B9', '#fcb300'];
    groups = [
      `0 - 5 ${t('general.map.choropleth.bronze')}`,
      `6 - 7 ${t('general.map.choropleth.silver')}`,
      `8 - 10 ${t('general.map.choropleth.gold')}`
    ];
  }

  const createGroupWithDivisor = (max: number, min: number, countDivisor: number) => {
    const result = ['0'];
    let accumulatedTotal = min;
    if (!isFinite(max)) {
      result.push('>100');
      return result;
    }
    if (min > 0) {
      result.push(min.toString());
    }
    const divisor = (max - min) / countDivisor;
    for (let i = 0; i < countDivisor; i++) {
      accumulatedTotal += divisor;
      result.push(Math.round(accumulatedTotal).toString());
    }
    return result;
  };

  // If this choropleth type has limits
  if (choroplethLimits[actualChoroplethType]) {
    const { min, max } = choroplethLimits[actualChoroplethType];
    const minValue = min > 0 ? min : 1;

    groups = createGroupWithDivisor(max, minValue, 5);
    if (actualChoroplethType === ChoroplethTypes.days_without_spray || selectedTab === TabChildrens.days_without_spraying) {
      colors = [...MAP_SPRAY_COLORS_5, getStringOrDefault(storageMaxDays?.daysWithoutSprayMaxColor, '#000')];
    } else if (actualChoroplethType === ChoroplethTypes.days_without_monitoring || selectedTab === TabChildrens.days_without_monitoring) {
      colors = [...MAP_SEVERITY_COLORS_5, getStringOrDefault(storageMaxDays?.daysWithoutMonitoringMaxColor, '#000')];
    } else if (actualChoroplethType === ChoroplethTypes.days_after_emergence || selectedTab === TabChildrens.days_after_emergence) {
      colors = storageMaxDays?.daysAfterEmergenceMinColor
        ? [
            storageMaxDays?.daysAfterEmergenceMinColor || '#FFF',
            ...MAP_DAE_COLORS_5,
            getStringOrDefault(storageMaxDays?.daysAfterEmergenceMaxColor, '#000')
          ]
        : [...MAP_DAE_COLORS_5, getStringOrDefault(storageMaxDays?.daysAfterEmergenceMaxColor, '#000')];
    }
  }

  return {
    icon: icon ? <SvgHeatmap width={16} height={16} fill='#198746' /> : null,
    title,
    isAreaSelected: false,
    groups,
    colors,
    choroplethType
  };
};

const getColorPosition = (colorPosition: number, maxValue = 255) => {
  if (colorPosition > maxValue) {
    return maxValue;
  }
  return colorPosition;
};

export const getColorGeneralWithMaxValue = (
  dateString: DateISOString | null,
  colorArray: string[],
  maxValue: number,
  minValue = 0,
  minValueColor: string | null | undefined = null
): string => {
  if (dateString === null) {
    return 'transparent';
  }

  const date = moment(dateString);
  const daysDiff = moment().diff(date, 'days');
  if (daysDiff < minValue) {
    return getStringOrDefault(minValueColor, '#FFF');
  }
  const colorPosition = Math.trunc(((daysDiff - minValue) * 5) / (maxValue - minValue));
  return isNaN(colorPosition) ? 'transparent' : colorArray[getColorPosition(colorPosition, colorArray.length - 1)];
};

export const getBorerRiskColor = (value: number | null, colorArray: string[], maxValue: number) => {
  if (value === null) {
    return '#868CA2';
  }
  const colorPosition = Math.round((value * 256) / maxValue);
  return isNaN(colorPosition) ? '#868CA2' : colorArray[colorPosition > 255 ? 255 : colorPosition];
};

export const getStoregeDaysLimit = () => {
  return {
    daysWithoutMonitoringMax: getNumericValueFromLocalStore(`days_without_monitoring_limit_days`),
    daysWithoutMonitoringMaxColor: localStorage.getItem(`days_without_monitoring_limit_days_color`),
    daysWithoutSprayMax: getNumericValueFromLocalStore(`days_without_spray_limit_days`),
    daysWithoutSprayMaxColor: localStorage.getItem(`days_without_spray_limit_days_color`),
    daysAfterEmergenceMax: getNumericValueFromLocalStore(`days_after_emergence_limit_days`),
    daysAfterEmergenceMaxColor: localStorage.getItem(`days_after_emergence_limit_days_color`),
    daysAfterEmergenceMin: getNumericValueFromLocalStore(`days_after_emergence_min_limit_days`),
    daysAfterEmergenceMinColor: localStorage.getItem(`days_after_emergence_min_limit_days_color`)
  };
};

export const extractChildrenAreasFromRegions = (regions: Dictionary<Region> | Region[]): Region[] => {
  if (!Array.isArray(regions)) {
    return Object.values(regions);
  }
  const hasChildren = regions.filter((region: Region) => region.children?.length);
  if (!hasChildren.length) {
    return regions;
  }
  return regions.flatMap(region => {
    return RegionsUtil.getFieldsRecursive(region);
  });
};

export const getLimitsChoropleth = (
  regions: Dictionary<Region> | Region[],
  propertyData: PropertyInfoAddress | null,
  currentAreaSeasons: CurrentSeasonArea[],
  selectedSeasonsIds: string[],
  storageMaxDaysLimit?: StorageMaxDaysLimit
): Dictionary<ChoroplethLimits> => {
  let maxDaysWithoutSpray = -Infinity;
  let maxDaysWithoutMonitoring = -Infinity;
  let maxDaysAfterEmergence = -Infinity;

  const timezone = propertyData?.timeZone;

  const getDate = (data: moment.Moment) => {
    return timezone ? moment.tz(data, timezone) : data;
  };

  const regionsArray = extractChildrenAreasFromRegions(regions);

  const currentStartOfTheDay = getDate(moment()).startOf('day');

  const currentAreaSeasonsIdsSet = new Set(
    currentAreaSeasons.filter(seasonArea => selectedSeasonsIds.includes(seasonArea.seasonId)).map(seasonArea => seasonArea.areaId)
  );
  regionsArray
    .filter(r => r.type === RegionType.AREA && r.current_info && currentAreaSeasonsIdsSet.has(r.id))
    .forEach(region => {
      const { last_spray: lastSpray, last_monitoring: lastMonitoring, emergence_day: emergenceDay } = region.current_info!;
      const dae = RegionsUtil.getDAEByEmergenceDay(emergenceDay);

      if (lastSpray) {
        const lastSprayDate = getDate(moment(lastSpray.end_date || lastSpray.start_date)).startOf('day');
        const daysWithoutSpray = currentStartOfTheDay.diff(lastSprayDate, 'days');
        maxDaysWithoutSpray = Math.max(daysWithoutSpray, maxDaysWithoutSpray);
      }

      if (lastMonitoring) {
        const lastMonitoringDate = getDate(moment(lastMonitoring.date)).startOf('day');
        const daysWithoutMonitoring = currentStartOfTheDay.diff(lastMonitoringDate, 'days');
        maxDaysWithoutMonitoring = Math.max(daysWithoutMonitoring, maxDaysWithoutMonitoring);
      }

      if (dae) {
        maxDaysAfterEmergence = Math.max(dae, maxDaysAfterEmergence);
      }
    });

  const daysWithoutSprayMax = storageMaxDaysLimit?.daysWithoutSprayMax
    ? storageMaxDaysLimit?.daysWithoutSprayMax
    : Math.max(maxDaysWithoutSpray, 10);
  const daysWithoutMonitoringMax = storageMaxDaysLimit?.daysWithoutMonitoringMax
    ? storageMaxDaysLimit?.daysWithoutMonitoringMax
    : Math.max(maxDaysWithoutMonitoring, 10);
  const daysAfterEmergenceMax = storageMaxDaysLimit?.daysAfterEmergenceMax
    ? storageMaxDaysLimit?.daysAfterEmergenceMax
    : Math.max(maxDaysAfterEmergence, 10);

  return {
    days_without_spray: {
      max: daysWithoutSprayMax,
      min: 0
    },
    days_without_monitoring: {
      max: daysWithoutMonitoringMax,
      min: 0
    },
    days_after_emergence: {
      max: daysAfterEmergenceMax,
      min: storageMaxDaysLimit?.daysAfterEmergenceMin ?? 0
    }
  };
};

const seedToHexColor = (seed: string) => {
  const numberSeedHex = `#${(
    101010 *
    seed
      .toLowerCase()
      .split('')
      .map(s => s.charCodeAt(0))
      .reduce((x, y) => x + y, 0) *
    2
  )
    .toString(16)
    .slice(0, 6)
    .toUpperCase()}`;

  const numberSeed = numberSeedHex
    .toLowerCase()
    .split('')
    .map(s => s.charCodeAt(0))
    .reduce((x, y) => x + y, 0);

  const seedColorIndex = numberSeed % 21;
  if (!SEEDS_COLORS_ITEMS.find(seedColorItem => seedColorItem === SEEDS_COLORS[seedColorIndex])) {
    SEEDS_COLORS_ITEMS.push(SEEDS_COLORS[seedColorIndex]);
  } else {
    for (let i = 0; i <= SEEDS_COLORS.length; i++) {
      if (!SEEDS_COLORS_ITEMS.includes(SEEDS_COLORS[i])) {
        SEEDS_COLORS_ITEMS.push(SEEDS_COLORS[i]);
        break;
      }
    }
  }

  return SEEDS_COLORS_ITEMS[SEEDS_COLORS_ITEMS.length - 1];
};

type ColorDictionary = Record<string, { color: string; area: number; count: number; cropId?: string }>;

const insertColorInColorDictionary = (
  colorDictionary: ColorDictionary,
  colorKey: string | undefined,
  cropId: string | undefined,
  otherInfos: Record<'area', number>
) => {
  if (colorKey && !colorDictionary[colorKey]) {
    colorDictionary[colorKey] = { color: seedToHexColor(colorKey), count: 1, area: otherInfos.area, cropId };

    return colorDictionary;
  }

  if (colorKey && colorDictionary[colorKey]) {
    colorDictionary[colorKey] = {
      ...colorDictionary[colorKey],
      count: colorDictionary[colorKey].count + 1,
      area: otherInfos.area + colorDictionary[colorKey].area,
      cropId
    };

    return colorDictionary;
  }

  return colorDictionary;
};

const insertColorInColorDictionaryFromColorsArray = (
  colorDictionary: ColorDictionary,
  colorKey: string | undefined,
  cropId: string | undefined,
  otherInfos: Record<'area', number>,
  colorsList: string[]
) => {
  if (colorKey && !colorDictionary[colorKey]) {
    const color = colorsList.pop() ?? '';

    colorDictionary[colorKey] = { color, count: 1, area: otherInfos.area, cropId };

    return colorDictionary;
  }
  if (colorKey && colorDictionary[colorKey]) {
    colorDictionary[colorKey] = {
      ...colorDictionary[colorKey],
      count: colorDictionary[colorKey].count + 1,
      area: otherInfos.area + colorDictionary[colorKey].area,
      cropId
    };

    return colorDictionary;
  }

  return colorDictionary;
};

export const getSeedsChoropleth = (currentInfoRegions: Record<string, CurrentInfo>): ColorDictionary => {
  SEEDS_COLORS_ITEMS.splice(0);
  return Object.values(currentInfoRegions).reduce((seedColors, currentInfo) => {
    const seed = currentInfo.seeds?.[0];
    const cropId = currentInfo.crops?.[0].id;
    return insertColorInColorDictionary(seedColors, seed, cropId, { area: currentInfo.area_in_hectares });
  }, {});
};

export const getSortedSeedsChoropleth = (currentInfoRegions: Record<string, CurrentInfo>): ColorDictionary => {
  if (!currentInfoRegions) {
    return {};
  }
  const uniqueSeedCrops = Object.values(currentInfoRegions).reduce((seedCrops, currentInfo) => {
    const seed = currentInfo.seeds?.[0];
    const cropId = currentInfo.crops?.[0].id;
    if (!seed || !cropId) {
      return seedCrops;
    }
    return {
      ...seedCrops,
      [seed]: cropId
    };
  }, {});
  const mapSeedColors: string[] = chroma.scale(BASE_SEEDS_COLORS).mode('lch').colors(Object.keys(uniqueSeedCrops).length);
  mapSeedColors.reverse();
  return Object.values(currentInfoRegions)
    .sort((currentInfoA, currentInfoB) => {
      const seedA: string = currentInfoA.seeds?.[0] ?? '';
      const seedB: string = currentInfoB.seeds?.[0] ?? '';
      return seedA.localeCompare(seedB);
    })
    .reduce((seedColors, currentInfo) => {
      const seed = currentInfo.seeds?.[0];
      const cropId = currentInfo.crops?.[0].id;
      return insertColorInColorDictionaryFromColorsArray(seedColors, seed, cropId, { area: currentInfo.area_in_hectares }, mapSeedColors);
    }, {});
};

export const getPhenologicalStageColor = (currentInfoRegions: Record<string, CurrentInfo>): ColorDictionary => {
  SEEDS_COLORS_ITEMS.splice(0);
  return Object.values(currentInfoRegions).reduce((phenologicalStagesColor, currentInfo) => {
    const phenologicalStage = currentInfo.phenological_stage;
    const cropId = currentInfo.crops?.[0].id;
    return insertColorInColorDictionary(phenologicalStagesColor, phenologicalStage, cropId, { area: currentInfo.area_in_hectares });
  }, {});
};

const sortPhenologyByDAE = (
  currentInfoA: CurrentInfo,
  currentInfoB: CurrentInfo,
  phenologyStagesByCropAndName: Record<string, Record<string, DevelopmentPhases>>
) => {
  const cropA = currentInfoA.crops?.[0].id ?? '';
  const cropB = currentInfoB.crops?.[0].id ?? '';
  const phenologicalStageKeyA = currentInfoA.phenological_stage ?? '';
  const phenologicalStageKeyB = currentInfoB.phenological_stage ?? '';

  const phenologicalStageA = phenologyStagesByCropAndName[cropA]?.[phenologicalStageKeyA];
  const phenologicalStageB = phenologyStagesByCropAndName[cropB]?.[phenologicalStageKeyB];
  return (phenologicalStageB?.days_after_emergency || 0) - (phenologicalStageA?.days_after_emergency || 0);
};
export const getSortedPhenologicalStageColor = (
  currentInfoRegions: Record<string, CurrentInfo>,
  phenologyStagesByCropAndName: Record<string, Record<string, DevelopmentPhases>>
): ColorDictionary => {
  if (!currentInfoRegions) {
    return {};
  }
  const uniquePhenologyColors = Object.values(currentInfoRegions).reduce((phenologyCrops, currentInfo) => {
    const phenologicalStage = currentInfo.phenological_stage;
    const cropId = currentInfo.crops?.[0].id;

    if (!phenologicalStage || !cropId) {
      return phenologyCrops;
    }
    return {
      ...phenologyCrops,
      [phenologicalStage]: cropId
    };
  }, {});

  const mapPhenologyColors: string[] = chroma.scale(BASE_PHENOLOGY_COLORS).mode('lch').colors(Object.keys(uniquePhenologyColors).length);

  return Object.values(currentInfoRegions)
    .sort((currentInfoA, currentInfoB) => sortPhenologyByDAE(currentInfoA, currentInfoB, phenologyStagesByCropAndName))
    .reduce((phenologicalStagesColor, currentInfo) => {
      const phenologicalStage = currentInfo.phenological_stage;
      const cropId = currentInfo.crops?.[0].id;
      return insertColorInColorDictionaryFromColorsArray(
        phenologicalStagesColor,
        phenologicalStage,
        cropId,
        { area: currentInfo.area_in_hectares },
        mapPhenologyColors
      );
    }, {});
};

export const getNemadigitalColor = (damage: number): string => {
  const damageThreshold = DAMAGE_THRESHOLDS.find(threshold => damage < threshold.value);
  return damageThreshold ? damageThreshold.color : DAMAGE_DEFAULT_COLOR;
};

export const isDaysMap = (choropleth: ChoroplethTypes) => {
  return (
    choropleth === ChoroplethTypes.days_without_monitoring ||
    choropleth === ChoroplethTypes.days_without_spray ||
    choropleth === ChoroplethTypes.days_after_emergence
  );
};
