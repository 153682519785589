import { Tooltip } from 'antd';
import { ToggleMonitoringDrawer } from 'core/core.actions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import type React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import NewSvgReport from 'st-protector-icons/Minor/NewSvgReport';
import STReportDrawer from './report-drawer.component';
import './report.styles.less';

const STReport: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector((state: AppState) => getSelectedCompany(state));
  const property = useSelector((state: AppState) => getSelectedProperty(state));

  const onHandleChangeDrawerVisibility = useCallback(() => {
    dispatch(ToggleMonitoringDrawer());
  }, [dispatch]);

  const segmentTracking = useSegmentTracking();

  const handleOpen = () => {
    const payload: AnalyticsInfo = {
      companyId: company?.id || '',
      companyName: company?.name || '',
      propertyId: property?.id || '',
      propertyName: property?.name || ''
    };
    segmentTracking.track('Export Report - Tapped to see Available Reports', payload);
    onHandleChangeDrawerVisibility();
  };

  return (
    <div data-testid='report' className='st-report'>
      <STReportDrawer />
      <Tooltip title={t('report.tooltip')}>
        <div className='sd-report' data-testid='report-open' onClick={handleOpen}>
          <NewSvgReport />
        </div>
      </Tooltip>
    </div>
  );
};

export default STReport;
