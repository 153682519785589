import { COLOR_OPTIONS } from 'config/style';

interface CropBananaProps {
  color?: COLOR_OPTIONS | string;
  height?: number;
}

const CropBanana = ({ color = COLOR_OPTIONS.NEUTRAL_60, height = 22 }: CropBananaProps): JSX.Element => (
  <svg width='16' height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.7554 2.93497L13.7524 4.08211C14.1759 5.53786 14.0525 7.20511 13.4029 8.78486C11.6336 13.0881 6.90941 15.2002 3.1082 13.2969C2.61082 13.0542 2.37422 12.4619 2.50193 11.9441C2.62279 11.4538 3.01408 11.096 3.47558 11.0535C7.46892 10.7078 11.0002 7.30337 11.7628 3.88256C11.7734 3.43023 11.7219 2.94995 11.6982 2.75741C11.6355 2.29927 12.0303 1.91319 12.4614 2.01705L13.257 2.20387C13.5799 2.27481 13.7941 2.59845 13.7554 2.93497Z'
      fill={color}
    />
    <path
      d='M9.38432 6.26185C8.05138 8.16002 6.15059 9.54014 3.69691 9.89483C3.52985 9.79632 3.37946 9.71983 3.23873 9.64825C2.9128 9.48247 2.63875 9.34309 2.32977 9.01779C1.95414 8.63002 1.89185 8.00685 2.18187 7.53487C2.43173 7.13044 2.91773 6.90503 3.38502 7.03347C5.51786 7.63196 7.34032 7.23567 9.38432 6.26185Z'
      fill={color}
    />
  </svg>
);

export default CropBanana;
