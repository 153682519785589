import type React from 'react';
import STBreadcrumbCompany from './breadcrumb-company.component';
import STBreadcrumbProperty from './breadcrumb-property.component';
import STBreadcrumbSeasons from './breadcrumb-seasons.component';
import './breadcrumb.styles.less';

export interface STBreadcrumbProps {
  isEmbedded?: boolean;
  hideSeasons?: boolean;
}

const STBreadcrumb: React.FC<STBreadcrumbProps> = ({ isEmbedded, hideSeasons = false }) => {
  return (
    <div data-testid='st-breadcrumb' className='sd-header__breadcrumb'>
      <STBreadcrumbCompany isEmbedded={isEmbedded} />
      <STBreadcrumbProperty isEmbedded={isEmbedded} />
      <STBreadcrumbSeasons forceActiveSeasons={hideSeasons} />
    </div>
  );
};

export default STBreadcrumb;
