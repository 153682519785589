import { useGetSugarcaneSeasonPermission } from 'pages/sugarcane/sugarcane.utils';
import { useParams } from 'react-router-dom';

const useFieldClusteringConfigPermission = () => {
  const { propertyId } = useParams();
  const isSugarCane = useGetSugarcaneSeasonPermission();

  return !!propertyId && isSugarCane;
};

export default useFieldClusteringConfigPermission;
