const PhotoLibrary = props => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.3335 1.33301H13.3335C14.0668 1.33301 14.6668 1.93301 14.6668 2.66634V10.6663C14.6668 11.3997 14.0668 11.9997 13.3335 11.9997H5.3335C4.60016 11.9997 4.00016 11.3997 4.00016 10.6663V2.66634C4.00016 1.93301 4.60016 1.33301 5.3335 1.33301ZM13.3335 10.6663V2.66634H5.3335V10.6663H13.3335ZM7.66683 7.77842L8.7935 9.28509L10.4468 7.21842L12.6668 9.99842H6.00016L7.66683 7.77842ZM1.3335 13.333V3.99967H2.66683V13.333H12.0002V14.6663H2.66683C1.9335 14.6663 1.3335 14.0663 1.3335 13.333Z'
      {...props}
    />
  </svg>
);

export default PhotoLibrary;
