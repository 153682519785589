import type { Dictionary } from 'config/types';
import type { LatLngTuple } from 'core/core.models';
import type { ISTMarkerProps, STTracking } from 'core/shared/map/map.models';
import type { DateISOString, DateOnlyString, IndexedName, Moment, Page, Phenomenon, Scouter, UUID } from 'core/utils/basic.models';
import type { mapboxgl } from 'core/utils/map/mapbox-gl';
import type { CurrentInfo, Region } from 'entities/region/region.models';
import type { Tasks } from './area-day-info/v2/tasks.models';
import type { MonitoringDetailThreshold } from './monitoring.models';
import type { SprayInfo } from './spray.models';

export type WindowEvent = Monitoring | SprayWindow | Phenology | Annotation | TasksWindow;

export interface TimelineWindowEvents {
  startDate?: Moment;
  endDate?: Moment;
  annotation_window?: Annotation;
  monitoring_window?: Monitoring;
  phenology_window?: Phenology;
  spray_window?: SprayWindow;
  harvest_window?: TasksWindow;
  irrigation_window?: TasksWindow;
  areaId?: UUID;
}

export interface SprayWindow {
  type: EventType.APPLICATION;
  sprays: SprayInfo[];
}

export interface TasksWindow {
  type: EventType;
  harvests?: Tasks[];
  irrigations?: Tasks[];
}

export interface TimelineWindow {
  start_date: Moment;
  end_date: Moment;
  events: EventType[];
  phenological_stage: string;
  eventsData?: TimelineWindowEvents;
}

export interface TimelineBarPage {
  window: TimelineWindow[];
}

export enum EventType {
  ANNOTATION = 'ANNOTATION',
  MONITORING = 'MONITORING',
  STATIC_POINT = 'STATIC_POINT',
  PLUVIOMETER = 'PLUVIOMETER',
  TRAP = 'TRAP',
  GENERIC = 'GENERIC',
  PHENOLOGY = 'PHENOLOGY',
  APPLICATION = 'APPLICATION',
  NONE = 'NONE',
  HARVESTING = 'HARVESTING',
  IRRIGATION = 'IRRIGATION'
}

export enum TimelineMode {
  MAP = 'map',
  TABLE = 'table'
}

type SeverityPointsValue = string | MonitoringDetailThreshold;

export interface TimelineAreaDayInfoProps {
  propertyId: UUID | null;
  onClickNavigateBack: (region: Region) => void;
  currentInfo?: CurrentInfo;
  onClickPreviousNext: (previous: boolean) => void;
  area: Region;
  clearHeatmapsAndLayers: () => void;
  togggleShowSprayedAreas: () => void;
  events?: TimelineWindowEvents;
  showSprayedAreas: boolean;
  isLoading: boolean;
  loadingEvents: boolean;
  region: Region | null;
  handleEventClicked?: (clickedRegionId: UUID, event: EventType, startDate: string) => void;
  hasNextEvent?: boolean;
  hasPreviousEvent?: boolean;
  eventType: EventType;
  onDetailsClick: (event: Event) => void;
  timeSpent?: number;
  scoutersDistance?: number;
  selectedArea: Region;
  currentSeasons: UUID[];
  trackings: STTracking[];
  handleSetTrackings: (trackings: STTracking[]) => void;
  handleToggleMarkers: () => void;
  showMarkers: boolean;
  mapMarkers: ISTMarkerProps[];
  removeLastSprayCurrentInfo: (spray: SprayInfo) => void;
  eventDates: Moment[];
  setLayout: React.Dispatch<React.SetStateAction<LayoutTimeline>>;
  setActivateSeverityPoints: (value: boolean) => void;
  setSeverityPoints: (value: Dictionary<SeverityPointsValue> | undefined) => void;
  severityPoints: Dictionary<SeverityPointsValue> | undefined;
  activateSeverityPoints: boolean;
  loadingTrackings?: boolean;
  showTableLayout?: boolean;
  mapbox?: mapboxgl.Map;
  onSelectSample?: (sampleId?: string) => void;
}

export interface LayoutTimeline {
  sideBar: {
    show: boolean;
    type: TimelineSideBarType;
    showAreaInfo?: boolean;
  };
  timelineBar: {
    show: boolean;
  };
  multilayer: {
    show: boolean;
  };
  table: {
    show: boolean;
  };
  legend: {
    show: boolean;
  };
}

export enum TimelineSideBarType {
  REGION_SELECT = 'REGION_SELECT',
  AREA_DAY_INFO = 'AREA_DAY_INFO'
}

export interface MonitoringPoint {
  // inspectionGroups: InspectionGroup[];
  point: GeoJSON.Point;
  forced_point?: GeoJSON.Point;
  represented_by?: UUID;
  id: UUID;
  date: DateISOString;
  image: string;
  scouter: IndexedName;
  photos: UUID[];
  medias: Medias[];
}

export interface Sample {
  phenomenon: Phenomenon;
  count: number;
  image: string;
  date: string;
  scouter: Scouter;
}

export interface Monitoring {
  type: EventType.MONITORING;
  scouters: Scouter[];
  score: number;
  points: MonitoringPoint[];
  total: number;
  timeSpent: number;
  scoutersDistance?: number;
  samples: Sample[];
}

export enum FILE_TYPE {
  VIDEO = 'video',
  AUDIO = 'audio',
  PICTURES = 'pictures',
  DOCUMENTS = 'documents',
  OTHER = 'other'
}

enum STATUS {
  NEW = 'N',
  COMPLETE = 'C',
  DELETED = 'D'
}

export interface Medias {
  id: string;
  key: string;
  created_by: string;
  status: STATUS;
  type: FILE_TYPE;
  url: string;
  source?: string;
  thumbnail?: string;
}

export interface AnnotationStage {
  id?: string;
  point: GeoJSON.Point;
  forced_point?: GeoJSON.Point;
  date: DateISOString;
  thumbnail?: string;
  image: string;
  scouter: Scouter;
  medias?: Medias[];
  name: string;
  tags?: string[];
  comments: string;
  source?: string;
}

export interface Annotation {
  type: EventType.ANNOTATION;
  total?: number;
  annotations: AnnotationStage[];
}

export interface PhenologyStage {
  id: string;
  thumbnail: string;
  name: string;
  short_description: string;
}

export interface PhenologySample {
  stage: PhenologyStage;
  count: number;
  image: string;
  id: UUID;
  point: GeoJSON.Point;
  forced_point?: GeoJSON.Point;
  points: GeoJSON.Point[];
  date: string;
  comments: string;
  scouter: Scouter;
}

interface PhenologyStageCount {
  count: number;
  stage: PhenologyStage;
}

export interface Phenology {
  type: EventType.PHENOLOGY;
  scouters: Scouter[];
  total: number;
  samples: PhenologySample[];
  stages: PhenologyStageCount[];
}

interface Product {
  product_id?: string;
  name?: string;
  type?: string;
  dose?: number;
  dose_unit?: string;
  total?: number;
  activePrinciples?: string[];
}

export interface Spray {
  // scouter?: Scouter;
  assignee_id: string;
  date: DateISOString;
  // endDate?: DateISOString;
  // areas?: Area[];
  products_usages: Product[];
  // details?: SprayDetails;
}

export interface RequestTracking {
  query: {
    property: UUID;
    since: DateISOString;
    until: DateISOString;
    assignees: UUID[];
    regions: UUID[];
    geometry_date?: DateOnlyString;
  };
  selectedArea: Region;
}

export interface RequestTrackingExport {
  query: {
    property: UUID;
    since: string;
    until: string;
    assignees: UUID[];
    regions: UUID[];
    geometry_date?: DateOnlyString;
  };
}

export interface TrackingSegment {
  start_index: number;
  end_index: number;
  start: string;
  end: string;
  total_meters: number;
}

interface TrackingAssignee {
  id: string;
  name: string;
  route: LatLngTuple[];
  segments: TrackingSegment[];
  timestamps: string[];
}

export interface TrackingAssigneeResponse {
  assignees: TrackingAssignee[];
}

export const initialLayout = {
  sideBar: {
    show: true,
    type: TimelineSideBarType.REGION_SELECT
  },
  timelineBar: {
    show: false
  },
  multilayer: {
    show: true
  },
  table: {
    show: false
  },
  legend: {
    show: true
  }
};

export enum EventKeys {
  annotation_window = 'ANNOTATION',
  monitoring_window = 'MONITORING',
  phenology_window = 'PHENOLOGY',
  spray_window = 'APPLICATION'
}

export const MAX_PAGES = 8;

export type ArrayOfPages<T> = Page<T>[];

export interface GetNotesMarkerIconProps {
  marker: ISTMarkerProps;
  hasPhoto: boolean;
  isSingleOrMultiplePhotos: boolean;
  enableMultiplePhotos: boolean;
  hasAudio: boolean;
}

export interface SaveEditAreaInfoResponse {
  seasonPropertyId: string;
  areaId: string;
  seasonId: string;
  cropId: string;
  varieties: string[];
  varietyName: string;
  plantingDate: string;
  emergencyDate: string;
  harvestingDate: string;
  areaInHectares: number;
  methodologyId: string;
  startsAt: string;
  endsAt: string;
  lastModified: string;
  deleted: boolean;
  id: string;
}

export interface IExtendAttrSpain {
  sigpacCode: string;
  dgcCode: string;
}
