const SvgInfoTriangleGlyph = props => (
  <svg width={140} height={140} viewBox='0 0 140 140' {...props}>
    <circle r='70' transform='matrix(-1 0 0 1 70 70)' fill='url(#paint0_linear)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M111.136 90.8676L74.4651 29.5672C72.9477 27.0307 69.7187 26.2398 67.2529 27.8007C66.554 28.2432 65.9658 28.8482 65.5357 29.5672L28.8649 90.8676C27.1789 93.686 28.0331 97.3767 30.7729 99.1111C31.6909 99.6923 32.7477 100 33.8257 100H106.175C109.392 100 112 97.3173 112 94.008C112 92.8991 111.701 91.812 111.136 90.8676ZM70 89.5714C67.1005 89.5714 64.75 87.2369 64.75 84.3571C64.75 81.4774 67.1005 79.1429 70 79.1429C72.8995 79.1429 75.25 81.4774 75.25 84.3571C75.25 87.2369 72.8995 89.5714 70 89.5714ZM70 47.8571C72.8995 47.8571 75.25 50.1917 75.25 53.0714V68.7143C75.25 71.5941 72.8995 73.9286 70 73.9286C67.1005 73.9286 64.75 71.5941 64.75 68.7143V53.0714C64.75 50.1917 67.1005 47.8571 70 47.8571Z'
      fill='#F74141'
    />
    <defs>
      <linearGradient id='paint0_linear' x1='70' y1='0' x2='70' y2='140' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#E8EAED' stopOpacity='0.5' />
        <stop offset='1' stopColor='#E8EAED' />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgInfoTriangleGlyph;
