import _ from 'lodash';
import type { UUID } from '../../core/utils/basic.models';
import type { FixedPointInfo, FixedPointPhenomenon } from '../../pages/fixed-points/fixed-points.models';
import type { IndicatorHistogram } from '../indicators/indicators.models';
import type { StaticPoint } from './static-points.models';

const getPhenomenaIdsFromStaticPoint = (staticPoint: StaticPoint): UUID[] => {
  return getPhenomenaFromStaticPoint(staticPoint.static_point).map(p => p.id);
};

const getPhenomenaFromStaticPoint = (staticPoint: FixedPointInfo): FixedPointPhenomenon[] => {
  return (
    staticPoint.inspection_layout?.ordered_inspection_groups?.flatMap(ig => ig.ordered_categories.flatMap(oc => oc.ordered_phenomenons)) ||
    []
  );
};

const getLastHistogram = (histograms: IndicatorHistogram[]): IndicatorHistogram | null => {
  const result = _.sortBy(histograms, 'date').reverse();
  return result && result.length ? result[0] : null;
};

const StaticPointsUtil = {
  getPhenomenaFromStaticPoint,
  getPhenomenaIdsFromStaticPoint,
  getLastHistogram
};

export default StaticPointsUtil;
