import type { Action, UUID } from 'core/utils/basic.models';
import { LoadMethodologiesSuccess } from 'entities/region/region.actions';
import type { Dictionary } from 'config/types';
import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
  LoadMethodoly,
  LoadMethodolyByAreaFailure,
  LoadMethodolyByAreaSuccess,
  LoadMethodolyFailure,
  LoadMethodolySuccess,
  LoadPhenomenonByMethodology,
  LoadPhenomenonByMethodologyFailure,
  LoadPhenomenonByMethodologySuccess,
  PhenomenonActionsTypes
} from './phenomenon.actions';
import type { CurrentSeasonAreas, Phenomenon } from './phenomenon.models';
import { getMethodologies, getMethodology, getMethodologyByArea, getPhenomenaByMethodology } from './phenomenon.service';

const parsePhenomena = (methodologyPhenomena: Dictionary<Phenomenon[]>): Phenomenon[] => {
  return Object.keys(methodologyPhenomena).reduce<Phenomenon[]>((acc, key) => {
    const phenomena = methodologyPhenomena[key].map(p => {
      return {
        ...p,
        methodologyIds: Object.keys(methodologyPhenomena).filter(m => methodologyPhenomena[m].find(p2 => p2.id === p.id))
      };
    });
    return [...acc, phenomena].flat();
  }, []);
};

interface ParseArea {
  seasonId: UUID;
  methodologyId: UUID;
}

const parseAreas = (seasonAreas: CurrentSeasonAreas[]): Dictionary<ParseArea> => {
  const persedAreas: Dictionary<ParseArea> = {};
  seasonAreas.forEach(seasonArea => {
    persedAreas[seasonArea.areaId] = {
      seasonId: seasonArea.seasonId,
      // TODO: Need analysis in the endpoint current-season-area
      // TODO: Remove methodologyId dependency from season-area
      methodologyId: seasonArea.methodologyId
    };
  });

  return persedAreas;
};

export const handleLoadMethodologies = action$ =>
  action$.pipe(
    ofType(PhenomenonActionsTypes.LOAD_METHODOLOGIES),
    map((action: Action<UUID>) => action.payload),
    concatMap<any, any>((propertyId: UUID) =>
      getMethodologies(propertyId).pipe(
        concatMap((seasonAreas: CurrentSeasonAreas[]) => {
          const uniqueMethodologies = seasonAreas.reduce<UUID[]>((acc, seasonArea) => {
            // TODO: Need analysis in the endpoint current-season-area
            // TODO: Remove methodologyId dependency from season-area
            if (acc.includes(seasonArea.methodologyId) || !seasonArea.methodologyId) return acc;
            return [...acc, seasonArea.methodologyId];
          }, []);
          const parsedAreas = parseAreas(seasonAreas);
          return concat([LoadMethodologiesSuccess(parsedAreas), LoadPhenomenonByMethodology(uniqueMethodologies)]);
        })
      )
    )
  );

export const handleLoadPhenomenaByMethodology = action$ =>
  action$.pipe(
    ofType(PhenomenonActionsTypes.LOAD_PHENOMENON_BY_METHODOLOGY),
    map((action: Action<UUID[]>) => action.payload),
    concatMap((methodologyIds: UUID[]) =>
      getPhenomenaByMethodology(methodologyIds).pipe(
        map(response => {
          const parsedMethodologies = response.reduce((acc, val) => {
            return {
              ...acc,
              [Object.keys(val)[0]]: Object.values(val)[0]
            };
          }, {});
          const parsedPhenomena = parsePhenomena(parsedMethodologies);
          return LoadPhenomenonByMethodologySuccess(parsedPhenomena);
        })
      )
    ),
    catchError(err => of(LoadPhenomenonByMethodologyFailure(err)))
  );

export const handleLoadMethodologyArea = action$ =>
  action$.pipe(
    ofType(PhenomenonActionsTypes.LOAD_METHODOLOGY_BY_AREA),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return getMethodologyByArea(payload).pipe(
        map(response => {
          const metholodyArea = response.find(item => item.areaId === payload);
          if (metholodyArea?.methodologyId) {
            return LoadMethodoly(metholodyArea.methodologyId);
          }
          return LoadMethodolyByAreaSuccess(response);
        }),
        catchError(error => of(LoadMethodolyByAreaFailure(error)))
      );
    })
  );

export const handleLoadMethodology = action$ =>
  action$.pipe(
    ofType(PhenomenonActionsTypes.LOAD_METHODOLOGY),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return getMethodology(payload).pipe(
        map(response => {
          return LoadMethodolySuccess(response);
        }),
        catchError(error => of(LoadMethodolyFailure(error)))
      );
    })
  );
