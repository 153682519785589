import { selectSelectedProperty } from 'core/core.reducer';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { from, of, type Observable } from 'rxjs';
import { catchError, concatMap, map, mergeMap, toArray, withLatestFrom } from 'rxjs/operators';
import type { Action, UUID } from '../../core/utils/basic.models';

import {
  GetEntryWarehouseFailure,
  GetEntryWarehouseSuccess,
  LoadAllTransactionsFromWarehousesFailure,
  LoadAllTransactionsFromWarehousesSuccess,
  LoadCompanyStockWarehouseFailure,
  LoadCompanyStockWarehouseSuccess,
  LoadIndividualStockWarehouseFailure,
  LoadIndividualStockWarehouseSuccess,
  LoadStockWarehouseFailure,
  LoadStockWarehouseSuccess,
  LoadTransactionsWarehouseFailure,
  LoadTransactionsWarehouseSuccess,
  LoadWarehouseFailure,
  LoadWarehouseSuccess,
  NewEntryWarehouseFailure,
  NewEntryWarehouseSuccess,
  NewTransferWarehouseFailure,
  NewTransferWarehouseSuccess,
  NewVendorFailure,
  NewVendorSuccess,
  NewWarehouseFailure,
  NewWarehouseSuccess,
  RemoveWarehouseFailure,
  RemoveWarehouseSuccess,
  UpdateEntryWarehouseFailure,
  UpdateEntryWarehouseSuccess,
  UpdateVendorFailure,
  UpdateVendorSuccess,
  UpdateWarehouseFailure,
  UpdateWarehouseSuccess,
  WarehouseActionsTypes
} from './warehouse.actions';
import type {
  Warehouse,
  WarehouseDTO,
  WarehouseInputDTO,
  WarehouseRemoveDTO,
  WarehouseResponse,
  WarehouseTransactionResponse,
  WarehouseTransferDTO,
  WarehouseVendorResponse
} from './warehouse.models';
import {
  createWarehouses,
  deleteWarehouses,
  getCompanyStockByWarehouses,
  getIndividialInputWarehousesUrl,
  getIndividualStockByWarehouses,
  getStockByWarehouses,
  getTransactions,
  getWarehouses,
  postIndividialInputWarehousesUrl,
  postVendor,
  putIndividialInputWarehousesUrl,
  transferWarehouses,
  updateVendor,
  updateWarehouses
} from './warehouse.service';

export const handleLoadWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.LOAD_WAREHOUSE),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return getWarehouses(payload).pipe(
        map(response => {
          return LoadWarehouseSuccess(payload, response);
        }),
        catchError(error => of(LoadWarehouseFailure(error)))
      );
    })
  );

export const handleLoadTransactionsWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.LOAD_TRANSACTIONS_WAREHOUSE),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return getTransactions(payload).pipe(
        map(response => {
          return LoadTransactionsWarehouseSuccess(payload, response.content);
        }),
        catchError(error => of(LoadTransactionsWarehouseFailure(error)))
      );
    })
  );

export const handleLoadAllTransactionsFromWarehousesId = (action$: Observable<unknown>) =>
  action$.pipe(
    ofType(WarehouseActionsTypes.LOAD_ALL_TRANSACTIONS_FROM_WAREHOUSES),
    map((action: Action) => action.payload),
    mergeMap((uuids: UUID[]) =>
      from(
        Promise.all(
          uuids.map(async uuid => {
            const response = await getTransactions(uuid).toPromise();
            return { warehouseId: uuid, transactions: response.content };
          })
        )
      ).pipe(
        map(results => {
          const responseDict = results.reduce((acc: Record<UUID, WarehouseTransactionResponse[]>, response) => {
            acc[response.warehouseId] = response.transactions;
            return acc;
          }, {});

          return LoadAllTransactionsFromWarehousesSuccess(responseDict);
        }),
        catchError((error: string) => of(LoadAllTransactionsFromWarehousesFailure(error)))
      )
    )
  );

export const handleLoadIndividualStockWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.LOAD_INDIVIDUAL_STOCK_WAREHOUSE),
    map((action: Action) => action.payload),
    mergeMap((payload: UUID) => {
      return getIndividualStockByWarehouses(payload).pipe(
        map(response => {
          return LoadIndividualStockWarehouseSuccess(payload, response);
        }),
        catchError(error => of(LoadIndividualStockWarehouseFailure(error)))
      );
    })
  );

export const handleLoadCompanyStockWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.LOAD_COMPANY_STOCK_WAREHOUSE),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return getCompanyStockByWarehouses(payload).pipe(
        map(response => {
          return LoadCompanyStockWarehouseSuccess(payload, response);
        }),
        catchError(error => of(LoadCompanyStockWarehouseFailure(error)))
      );
    })
  );

export const handleLoadStockWarehouse = (action$, state$) =>
  action$.pipe(
    ofType(WarehouseActionsTypes.LOAD_STOCK_WAREHOUSE),
    map((action: Action<UUID>) => action.payload),
    concatMap((companyId: UUID) => getWarehouses(companyId)),
    withLatestFrom(state$.pipe(map((state: AppState) => selectSelectedProperty(state.uiState.global)))),
    concatMap(([warehouses, propertySelected]: [WarehouseResponse[], string | null]) => {
      const filteredWarehouses = propertySelected
        ? warehouses.filter(warehouse => {
            return !!warehouse.properties.find(prop => prop.id === propertySelected);
          })
        : warehouses;

      const concurrent = 3;
      return from(filteredWarehouses).pipe(
        mergeMap(warehouse => {
          return getStockByWarehouses(warehouse.id).pipe(
            map(stockArray => {
              const warehouseStock: Warehouse = {
                id: warehouse.id,
                name: warehouse.name,
                properties: warehouse.properties.map(prop => prop.id),
                products: {}
              };

              if (!stockArray.length) {
                return warehouseStock;
              }

              return stockArray.reduce((acc, cur) => {
                return {
                  ...warehouseStock,
                  products: { ...acc.products, [cur.product.id]: cur.balance }
                };
              }, warehouseStock);
            }),
            catchError(() => of('error'))
          );
        }, concurrent),
        toArray()
      );
    }),
    map((warehouses: WarehouseResponse[]) => {
      if (warehouses.includes('error')) {
        return LoadStockWarehouseFailure();
      }
      return LoadStockWarehouseSuccess(warehouses);
    })
  );

export const handleCreateWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.NEW_WAREHOUSE),
    map((action: Action) => action.payload),
    concatMap((payload: WarehouseDTO) => {
      return createWarehouses(payload).pipe(
        map(() => {
          return NewWarehouseSuccess();
        }),
        catchError(error => of(NewWarehouseFailure(error)))
      );
    })
  );

export const handleUpdateWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.UPDATE_WAREHOUSE),
    map((action: Action) => action.payload),
    concatMap((payload: WarehouseDTO) => {
      return updateWarehouses(payload).pipe(
        map(() => {
          return UpdateWarehouseSuccess();
        }),
        catchError(error => of(UpdateWarehouseFailure(error)))
      );
    })
  );

export const handleRemoveWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.REMOVE_WAREHOUSE),
    map((action: Action) => action.payload),
    concatMap((payload: WarehouseRemoveDTO) => {
      return deleteWarehouses(payload.warehouseId).pipe(
        map(() => {
          return RemoveWarehouseSuccess(payload);
        }),
        catchError(error => of(RemoveWarehouseFailure(error)))
      );
    })
  );

export const handleTransferWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.NEW_TRANSFER_WAREHOUSE),
    map((action: Action) => action.payload),
    concatMap((payload: WarehouseTransferDTO) => {
      return transferWarehouses(payload).pipe(
        map(() => {
          return NewTransferWarehouseSuccess();
        }),
        catchError(error => of(NewTransferWarehouseFailure(error)))
      );
    })
  );

export const handleNewVendor = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.NEW_VENDOR),
    map((action: Action) => action.payload),
    concatMap((payload: WarehouseVendorResponse) => {
      return postVendor(payload).pipe(
        map(result => {
          return NewVendorSuccess(result.id);
        }),
        catchError(error => of(NewVendorFailure(error)))
      );
    })
  );

export const handleUpdateVendor = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.UPDATE_VENDOR),
    map((action: Action) => action.payload),
    concatMap((payload: WarehouseVendorResponse) => {
      return updateVendor(payload).pipe(
        map(result => {
          return UpdateVendorSuccess(result.id);
        }),
        catchError(error => of(UpdateVendorFailure(error)))
      );
    })
  );

export const handleNewEntryWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.NEW_ENTRY_WAREHOUSE),
    map((action: Action) => action.payload),
    concatMap((payload: WarehouseInputDTO) => {
      return postIndividialInputWarehousesUrl(payload.warehouseId || '', payload).pipe(
        map(() => {
          return NewEntryWarehouseSuccess();
        }),
        catchError(error => of(NewEntryWarehouseFailure(error)))
      );
    })
  );

export const handlUpdateEntryWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.UPDATE_ENTRY_WAREHOUSE),
    map((action: Action) => action.payload),
    concatMap((payload: WarehouseInputDTO) => {
      return putIndividialInputWarehousesUrl(payload.warehouseId || '', payload).pipe(
        map(() => {
          return UpdateEntryWarehouseSuccess();
        }),
        catchError(error => of(UpdateEntryWarehouseFailure(error)))
      );
    })
  );

export const handlGetEntryWarehouse = action$ =>
  action$.pipe(
    ofType(WarehouseActionsTypes.GET_ENTRY_WAREHOUSE),
    map((action: Action) => action.payload),
    concatMap((payload: { inputId: UUID; warehouseId: UUID }) => {
      return getIndividialInputWarehousesUrl(payload.warehouseId, payload.inputId).pipe(
        map(result => {
          return GetEntryWarehouseSuccess(result);
        }),
        catchError(error => of(GetEntryWarehouseFailure(error)))
      );
    })
  );
