import { VITE_ENV } from 'config/constants';

const DB_NAME = `protector-${VITE_ENV}`;
export const FIELDS_STORE_NAME = 'fields';
export const PROPERTY_ID_INDEX = 'property_id';

// Any changes on tables need a upgrade version
const DB_VERSION = 1;

interface IndexedDBIndex {
  name: string;
  unique: boolean;
}
interface IndexedDBStore {
  name: string;
  keyPath: string;
  indexes: IndexedDBIndex[];
}

const initializeIDB = (stores: IndexedDBStore[] = INDEX_DB_STORES, version = DB_VERSION) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, version);
    request.onupgradeneeded = (e: Event) => {
      const db = (e?.target as IDBOpenDBRequest).result;
      stores.forEach(store => {
        if (!db.objectStoreNames.contains(store.name)) {
          const fieldStore = db.createObjectStore(store.name, { keyPath: store.keyPath });
          const indexes = store.indexes || [];
          indexes.forEach(index => {
            fieldStore.createIndex(index.name, index.name, { unique: index.unique });
          });
        }
        resolve(db);
      });
    };
    request.onsuccess = (event: any) => {
      console.info('IndexDB successfully connected!');
      resolve(event.target?.result);
    };
    request.onerror = (event: Event) => reject((event?.target as IDBOpenDBRequest).error);
  });
};

const insertValues = (storeName: string, values: any[]) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);
    request.onsuccess = (e: Event) => {
      try {
        const db = (e?.target as IDBOpenDBRequest).result;
        const writeTransaction = db.transaction(storeName, 'readwrite');
        const store = writeTransaction.objectStore(storeName);
        values.forEach(f => store.put(f));
        resolve(values);
        writeTransaction.onerror = (event: Event) => reject((event?.target as IDBTransaction).error);
      } catch (error) {
        reject(error);
      }
    };
    request.onerror = (event: Event) => reject((event?.target as IDBOpenDBRequest).error);
  });
};

const queryIndex = (storeName: string, index: string, value: string) => {
  return new Promise((resolve, reject) => {
    let result;
    const req = indexedDB.open(DB_NAME, DB_VERSION);
    req.onsuccess = (e: Event) => {
      try {
        const db = (e?.target as IDBOpenDBRequest).result;
        const queryTransaction = db.transaction(storeName);
        const store = queryTransaction.objectStore(storeName);
        const values = store.index(index).getAll(IDBKeyRange.only(value));
        values.onsuccess = _ => (result = values.result);
        queryTransaction.oncomplete = _ => resolve(result);
        queryTransaction.onerror = (event: Event) => reject((event?.target as IDBTransaction).error);
      } catch (error) {
        reject(error);
      }
    };
    req.onerror = (event: Event) => reject((event?.target as IDBOpenDBRequest).error);
  });
};

export const IndexedDBPromiseAPI = {
  initializeIDB,
  insertValues,
  queryIndex
};

export const INDEX_DB_STORES = [
  {
    name: FIELDS_STORE_NAME,
    keyPath: 'id',
    indexes: [{ name: PROPERTY_ID_INDEX, unique: false }]
  }
];
